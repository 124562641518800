import {
  convertForCustomBackend,
  convertReport,
  convertReportData,
  convertReportList,
  convertToOptions,
  prepareReportData,
  REPORT_PAGE_SIZE,
} from 'api/helpers/converters';
import { CustomOptions } from 'api/types';
import {
  CellContent,
  Column,
  ColumnOption,
  LoadCabinetsArgs,
  LoadReportDataArgs,
  LoadReportListArgs,
  ReportFilters,
  ReportListResponse,
  SaveReportArgs,
  ServerReportData,
  UpdateReportArgs,
} from 'types/reportConstructor';
import BaseApi from '../BaseApi';

class ReportConstructor extends BaseApi {
  loadReportLevel = async (filters: ReportFilters, config: CustomOptions = {}) => {
    const response = await this.actions.post<Record<string, string>>({
      url: '/report-builder/report-level',
      data: filters,
      config,
    });

    return this.convertData(response, convertToOptions);
  };

  loadCabinets = async (
    { statisticDateStart: dateStart, statisticDateEnd: dateEnd, reportLevel }: LoadCabinetsArgs,
    config: CustomOptions = {},
  ) => {
    const response = await this.actions.post<Record<string, string>>({
      domainType: 'webApi',
      url: `/cabinet/report-builder/by-level?statisticDateStart=${dateStart}&statisticDateEnd=${dateEnd}`,
      data: reportLevel,
      config,
    });

    return this.convertData(response, convertToOptions);
  };

  loadColumns = async (config: CustomOptions = {}) =>
    this.actions.get<Column[]>({
      domainType: 'webApi',
      url: '/report-builder/columns',
      config,
    });

  loadData = async (data: LoadReportDataArgs, config: CustomOptions = {}) => {
    const preparedData: LoadReportDataArgs = config.withoutComplex
      ? { ...data, structure: { ...data.structure, values: data.structure.values.filter(({ math }) => !math) } }
      : data;
    const reportData = prepareReportData(preparedData);
    const response = await this.actions.post<CellContent[][]>({
      domainType: 'webApi',
      url: '/report-builder/data',
      data: {
        operation: 'FETCH_DATA',
        reportData,
        pageNumber: 1,
        pageSize: REPORT_PAGE_SIZE + 1,
      },
      config,
    });

    const fieldNames = [...reportData.columns, ...reportData.aggregationColumns].filter(({ isVisible }) => isVisible);

    return this.convertData(response, convertReportData, fieldNames);
  };

  loadDataForCustomBackend = async (
    data: LoadReportDataArgs,
    allColumns: ColumnOption[],
    config: CustomOptions = {},
  ) => {
    const reportData = prepareReportData(data);
    const response = await this.actions.post<CellContent[][]>({
      domainType: 'webApi',
      url: '/report-builder/data',
      data: {
        operation: 'FETCH_DATA',
        reportData,
        pageNumber: 1,
        pageSize: REPORT_PAGE_SIZE + 1,
      },
      config,
    });

    return this.convertData(response, convertForCustomBackend, data.structure, allColumns);
  };

  loadReportList = async (data: LoadReportListArgs, config: CustomOptions = {}) => {
    const response = await this.actions.post<ReportListResponse>({
      url: '/report-builder/user-reports/list',
      data,
      config,
    });

    return this.convertData(response, convertReportList);
  };

  loadServerReport = async (id: number, config: CustomOptions = {}) =>
    this.actions.get<ServerReportData>({
      url: '/report-builder/user-reports',
      data: { id },
      config,
    });

  loadReport = async (id: number, config: CustomOptions = {}) => {
    const response = await this.loadServerReport(id, config);

    return this.convertData(response, convertReport);
  };

  save = async (data: SaveReportArgs, config: CustomOptions = {}) => {
    return this.actions.post({
      url: '/report-builder/user-reports/save',
      data,
      config,
    });
  };

  update = async ({ id, ...data }: UpdateReportArgs, config: CustomOptions = {}) => {
    return this.actions.post({
      url: `/report-builder/user-reports/update?id=${id}`,
      data,
      config,
    });
  };

  copy = async (reportData: Omit<ServerReportData, 'id'>, config: CustomOptions = {}) =>
    this.actions.post({
      url: '/report-builder/user-reports/save',
      data: reportData,
      config,
    });

  delete = async (id: number, config: CustomOptions = {}) =>
    this.actions.delete({
      url: '/report-builder/user-reports/delete',
      queryParams: { id },
      config,
    });
}

export default ReportConstructor;
