import { computed, makeObservable } from 'mobx';

import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { BaseStore } from 'shared/stores';
import { TvTerritoryType } from 'types/mediaplan';
import { TemplateType, TemplateTvType } from './types';

export class TemplatesStore extends BaseStore {
  private _templateTypes: TemplateType[] = ['nationalTV', 'regionalTV', 'uploadStatistics'];

  private _tvTypeToTettitoryTypeMap: Record<TemplateTvType, TvTerritoryType> = {
    nationalTV: TvTerritoryType.FEDERAL,
    regionalTV: TvTerritoryType.LOCAL,
  };

  constructor() {
    super();
    makeObservable(this);
  }

  @computed
  get templateTypes(): TemplateType[] {
    return this._templateTypes;
  }

  uploadTemplate = async (type: TemplateType, formData: FormData): Promise<void> => {
    const response =
      type === 'uploadStatistics'
        ? await this.services.api.mediafact.uploadTemplate(formData)
        : await this.services.api.tvMediaplans.uploadPlanTemplate({
            territoryType: this._tvTypeToTettitoryTypeMap[type],
            formData,
          });
    if (response.error) return;

    toast.success(localize('administration.templates.template-uploaded-successfully'));
  };

  downloadTemplate = async (type: TemplateType): Promise<void> => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (!advertiserId) return;

    const response =
      type === 'uploadStatistics'
        ? await this.services.api.mediafact.getTemplate({ customerId: advertiserId })
        : await this.services.api.tvMediaplans.getPlanTemplate({ territoryType: this._tvTypeToTettitoryTypeMap[type] });
    if (response.error) return;

    this.services.api.file.download({
      fileId: response.data.fileS3Id,
      fileType: type === 'uploadStatistics' ? 'MEDIAFACT_WEB' : 'MEDIAFACT_TV_TEMPLATES',
    });
  };
}
