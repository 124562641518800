import React from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { TvTerritoryType } from 'types/mediaplan';
import classes from './TemplateDownloadButtons.module.scss';

type Props = {
  className?: string;
  onClick(type: TvTerritoryType): void;
};

export const TemplateDownloadButtons: React.VFC<Props> = (props) => {
  const { className, onClick } = props;
  return (
    <div className={cx(classes.root, className)}>
      <Button
        label={localize('tv-mediaplan.buttons.download-national-tv-template')}
        size="small"
        onClick={() => onClick(TvTerritoryType.FEDERAL)}
      />
      <Button
        label={localize('tv-mediaplan.buttons.download-regional-tv-template')}
        size="small"
        onClick={() => onClick(TvTerritoryType.LOCAL)}
      />
    </div>
  );
};

export type { Props as TemplateDownloadButtonsProps };
