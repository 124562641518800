import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { parse } from 'node-html-parser';

import showConfirmation from 'helpers/showConfirmation';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { FileValidationRules } from 'shared/components/common/form/FileUploader';
import { BaseStore } from 'shared/stores';
import { Creative, CreativeMediaType, CreativeUploader } from 'types/creative';
import { ImageFormats } from 'types/file';
import { CampaignViewStore } from './CampaignView.store';

type Deps = {
  campaignViewStore: CampaignViewStore;
};

export class CreativesStore extends BaseStore<Deps> {
  @observable
  private _creatives: Creative[] = [];

  @observable
  private _currentCreativeId: number | null = null;

  private _abortController: AbortController | null = null;

  private readonly _fileValidationRules: FileValidationRules = {
    formats: [ImageFormats.PNG, ImageFormats.GIF, ImageFormats.JPG, ImageFormats.JPEG],
    maxSize: 10,
  };

  @computed
  get creatives(): Creative[] {
    return this._creatives;
  }

  @computed
  get currentCreative(): Creative | null {
    return this._creatives.find((creative) => creative.id === this._currentCreativeId) ?? null;
  }

  @computed
  get uploader(): CreativeUploader {
    return {
      fileValidationRules: this._fileValidationRules,
      start: this.cancelDataLoading,
      cancel: this.cancelUpload,
      uploadImage: this.uploadImage,
      uploadVideo: this.uploadVideo,
    };
  }

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
  }

  @action
  cleanUp = (): void => {
    this.cancelDataLoading();

    this._creatives = [];
    this._currentCreativeId = null;
  };

  @action
  select = async (creativeId: number): Promise<void> => {
    this.cancelDataLoading();

    this._currentCreativeId = creativeId;
    this.loadImageSrc();
  };

  load = async (): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null) return;

    const response = await this.services.api.creatives.list({ campaignId });
    if (response.error) return;

    runInAction(() => {
      this._creatives = response.data;

      if (this._currentCreativeId === null && this._creatives[0]?.id) {
        this.select(this._creatives[0].id);
      }
    });
  };

  create = async (mediaType: CreativeMediaType, name: string): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;

    if (!name || campaignId === null) return;

    const response = await this.services.api.creatives.create({ campaignId, name });
    if (response.error) return;

    runInAction(() => {
      this._creatives.push(response.data);
      this._currentCreativeId = response.data.id;
      if (this.currentCreative) {
        this.currentCreative.uploadStatus = {
          type: mediaType === 'image' ? 'uploadImage' : 'uploadVideo',
          progress: null,
        };
      }
    });
  };

  @action
  replace = (mediaType: CreativeMediaType): void => {
    if (this.currentCreative) {
      this.currentCreative.uploadStatus = {
        type: mediaType === 'image' ? 'uploadImage' : 'uploadVideo',
        progress: null,
      };
    }
  };

  rename = async (prevName: string): Promise<void> => {
    const newName = (
      await showConfirmation({
        title: localize('campaign-view.creative.enter-creative-name'),
        input: { value: prevName, maxLength: 1000 },
        confirmTextId: 'button.save',
      })
    )?.trim();
    const creative = this.currentCreative;

    if (!newName || creative === null) return;

    const response = await this.services.api.creatives.rename({ creativeId: creative.id, name: newName });
    if (response.error) return;

    runInAction(() => {
      toast.success(localize('campaign-view.creative.notifications.renamed'));

      creative.name = newName;
    });
  };

  remove = async (): Promise<void> => {
    const isConfirmed = await showConfirmation({
      title: `${localize('campaign-view.creative.actions.remove')}?`,
      type: 'removal',
    });
    const creativeId = this._currentCreativeId;

    if (!isConfirmed || creativeId === null) return;

    const response = await this.services.api.creatives.delete({ creativeId });
    if (response.error) return;

    runInAction(() => {
      toast.success(localize('campaign-view.creative.notifications.removed'));

      this._creatives = this._creatives.filter((creative) => creative.id !== creativeId);
      this._currentCreativeId = null;
    });
  };

  @action
  private loadImageSrc = async (): Promise<void> => {
    const creative = this.currentCreative;

    if (creative === null || creative.externalResource !== null || creative.imageSrc) return;

    creative.uploadStatus = { type: 'inProgress', progress: null };

    this._abortController = new AbortController();
    const response = await this.services.api.creatives.loadFileUrl(
      { fileId: creative.fileS3Id, creativeType: creative.campaignCreativeType },
      { withLoader: false, signal: this._abortController.signal },
    );

    runInAction(() => {
      creative.uploadStatus = { type: 'inactive', progress: null };
      creative.imageSrc = response.data;
    });
  };

  @action
  private uploadImage = async (formData: FormData): Promise<void> => {
    const creative = this.currentCreative;

    if (creative === null) return;

    creative.uploadStatus = { type: 'inProgress', progress: 0 };

    const response = await this.services.api.creatives.upload(
      { creativeId: creative.id, formData },
      { withLoader: false, onUploadProgress: (ev) => this.onUploadProgress(ev, creative.id) },
    );

    if (response.error) {
      creative.uploadStatus = { type: 'inactive', progress: null };
      return;
    }

    runInAction(async () => {
      toast.success(localize('campaign-view.creative.notifications.saved'));

      creative.imageSrc = null;
      creative.fileS3Id = response.data.fileS3Id;
      creative.externalResource = response.data.externalResource;
      creative.campaignCreativeType = response.data.campaignCreativeType;

      if (creative.id === this._currentCreativeId) {
        await this.loadImageSrc();
      }

      creative.uploadStatus = { type: 'inactive', progress: null };
    });
  };

  @action
  private uploadVideo = async (videoHtml: string): Promise<void> => {
    const html = parse(videoHtml);
    const videoUrl = html?.querySelector('iframe')?.getAttribute('src');

    if (!videoUrl) {
      toast.error(localize('campaign-view.creative.video-url-not-found'));
      return;
    }

    const creative = this.currentCreative;

    if (creative === null) return;

    const response = await this.services.api.creatives.uploadExternal({
      creativeId: creative.id,
      externalResource: videoUrl,
    });
    if (response.error) return;

    runInAction(() => {
      toast.success(localize('campaign-view.creative.notifications.saved'));

      creative.uploadStatus = { type: 'inactive', progress: null };
      creative.imageSrc = null;
      creative.fileS3Id = response.data.fileS3Id;
      creative.externalResource = response.data.externalResource;
      creative.campaignCreativeType = response.data.campaignCreativeType;
    });
  };

  @action
  private cancelUpload = (): void => {
    if (this.currentCreative) {
      this.currentCreative.uploadStatus = { type: 'inactive', progress: null };
    }
  };

  @action
  private onUploadProgress = (ev: ProgressEvent, creativeId: number) => {
    const creative = this.creatives.find((c) => c.id === creativeId);
    if (creative === undefined) return;

    const progress = (ev.loaded / ev.total) * 100;
    creative.uploadStatus.progress = Math.floor(progress);
  };

  private cancelDataLoading = (): void => {
    this._abortController?.abort();
  };
}
