import React from 'react';
import { localize } from 'shared/components/other';
import { PaginatorCurrentPageReportOptions } from 'primereact/paginator';
import classes from './CurrentPageReport.module.scss';

type Props = Partial<PaginatorCurrentPageReportOptions>;

export const CurrentPageReport: React.VFC<Props> = (props) => {
  const { currentPage, totalPages } = props;
  return currentPage && totalPages ? (
    <div className={classes.root}>
      <span>{currentPage}</span>
      <span>{localize('of')}</span>
      <span>{totalPages}</span>
    </div>
  ) : null;
};

export type { Props as CurrentPageReportProps };
