import React, { useCallback, useMemo } from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { IOption } from 'types';
import { CampaignStatus, MediaType } from 'types/campaign';
import { localize } from 'shared/components/other';
import { MultiSelect, MultiSelectChangeParams } from 'shared/components/common/form/MultiSelect';
import { SearchInput } from 'shared/components/common/form/SearchInput';
import { Calendar } from 'shared/components/common/form/Calendar';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import { CheckboxLabel, CheckboxLabelChangeParams } from './components/CheckboxLabel';
import classes from './CampaignFilters.module.scss';

type Props = {
  className?: string;
  brandOptions: IOption[];
  filters: Filters;
  onChange(filters: Partial<Filters>): void;
};

type Filters = {
  brands: number[];
  name: string;
  onlyFavorite: boolean;
  selectDate: (Date | null)[];
  statuses: Partial<Record<CampaignStatus, boolean>>;
  types: Partial<Record<MediaType, boolean>>;
};

export const CampaignFilters: React.VFC<Props> = React.memo((props) => {
  const { className, brandOptions, filters, onChange } = props;
  const mediaTypes = [MediaType.WEB, MediaType.TV];
  const statuses = useMemo(() => R.values(CampaignStatus), []);

  const handleNameChange = useCallback((name: string) => onChange({ name }), [onChange]);
  const handleNameClear = useCallback(() => onChange({ name: undefined }), [onChange]);

  const handleBrandsChange = useCallback(
    ({ value }: MultiSelectChangeParams) => {
      onChange({ brands: Array.isArray(value) ? value : [] });
    },
    [onChange],
  );

  const handleCheckboxFilterChange = useCallback(
    (e: CheckboxLabelChangeParams) => {
      onChange({ [e.target.name]: e.checked });
    },
    [onChange],
  );

  const handleMultipleCheckboxFilterChange = useCallback(
    (e: CheckboxLabelChangeParams) => {
      const filter = filters[e.target.name];
      if (!e.value || typeof filter !== 'object') return;
      onChange({ [e.target.name]: { ...filter, [e.value]: e.checked } });
    },
    [filters, onChange],
  );

  const handleMinDateChange = useCallback(
    (value: Date | Date[] | null) => {
      if (Array.isArray(value)) return;
      onChange({ selectDate: R.update(0, value, filters.selectDate) });
    },
    [filters, onChange],
  );

  const handleMaxDateChange = useCallback(
    (value: Date | Date[] | null) => {
      if (Array.isArray(value)) return;
      onChange({ selectDate: R.update(1, value, filters.selectDate) });
    },
    [filters, onChange],
  );

  const handleSelectCurrentMonthButtonClick = useCallback(() => {
    const currentDate = new Date();
    onChange({
      selectDate: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      ],
    });
  }, [onChange]);

  const startDate = filters.selectDate[0] ?? undefined;
  const maxDate = filters.selectDate[1] ?? undefined;

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.filter}>
        <div className={classes.filterName}>{localize('name')}</div>
        <SearchInput
          data-test="search-name-input"
          defaultValue={filters.name}
          maxLength={1000}
          onChange={handleNameChange}
          onClear={handleNameClear}
          tabIndex={0}
          tabular
          theme="grey"
        />
      </div>

      <div className={classes.filter}>
        <div className={classes.filterName}>{localize('brand')}</div>
        <MultiSelect
          appendTo="self"
          className={classes.brandFilter}
          display="chip"
          filter
          maxSelectedLabels={Infinity}
          onChange={handleBrandsChange}
          options={brandOptions}
          placeholder={`+ ${localize('add')}`}
          tabIndex={0}
          theme="grey"
          value={filters.brands}
        />
      </div>

      <div className={classes.filter}>
        <CheckboxLabel
          checked={filters.onlyFavorite}
          data-test="only-favorites-checkbox"
          label={localize('campaigns.only-favorites')}
          name="onlyFavorite"
          onChange={handleCheckboxFilterChange}
        />
      </div>

      <div className={classes.filter}>
        <div className={classes.filterName}>{localize('campaign.media-type')}</div>
        {mediaTypes.map((mediaType) => (
          <CheckboxLabel
            checked={filters.types[mediaType]}
            data-test={`${mediaType}-media-type-checkbox`}
            key={mediaType}
            label={localize(`campaign.type.${mediaType}` as `campaign.type.${typeof mediaType}`)}
            name="types"
            onChange={handleMultipleCheckboxFilterChange}
            value={mediaType}
          />
        ))}
      </div>

      <div className={classes.filter}>
        <div className={classes.filterName}>{localize('campaigns.campaign-status')}</div>
        {statuses.map((status) => {
          const statusId = status.toLowerCase() as Lowercase<typeof status>;
          const labelId = `campaigns.statuses.${statusId}` as `campaigns.statuses.${typeof statusId}`;
          return (
            <CheckboxLabel
              checked={filters.statuses[status]}
              data-test={`${statusId}-status-checkbox`}
              label={localize(labelId)}
              key={status}
              name="statuses"
              onChange={handleMultipleCheckboxFilterChange}
              value={status}
            />
          );
        })}
      </div>

      <div className={classes.filter}>
        <div className={classes.filterName}>{localize('period')}</div>
        <div className={classes.periodFilter}>
          <label htmlFor="from-date-input" className={classes.label}>
            {localize('from')}
          </label>
          <Calendar
            inputClassName={classes.calendarInput}
            inputId="from-date-input" // instead of data-test
            maxDate={maxDate}
            onChange={handleMinDateChange}
            value={startDate ?? null}
          />
          <label htmlFor="to-date-input" className={classes.label}>
            {localize('to')}
          </label>
          <Calendar
            inputClassName={classes.calendarInput}
            inputId="to-date-input" // instead of data-test
            minDate={startDate}
            onChange={handleMaxDateChange}
            value={maxDate ?? null}
          />
        </div>
      </div>

      <div className={classes.filter}>
        <div className={classes.filterName}>
          {localize('or')}
          <TextButton
            className={classes.selectCurrentMonthButton}
            data-test="current-month-button"
            label={localize('campaigns.select-current-month')}
            onClick={handleSelectCurrentMonthButtonClick}
          />
        </div>
      </div>
    </div>
  );
});

export type { Props as CampaignFiltersProps, Filters as CampaignFiltersType };
