import { RouterStore } from 'mobx-react-router';

import { AuthStore } from 'routes/Auth';
import { ConfirmDialogStore } from 'features/ConfirmDialog';
import { SidebarStore } from 'features/Sidebar';
import { AdvertiserStore } from './Advertiser.store';
import { LocaleStore } from './Locale.store';
import { GlobalLoaderStore } from './GlobalLoader.store';

function initSharedStores() {
  const authStore = new AuthStore();
  const advertiserStore = new AdvertiserStore();
  const localeStore = new LocaleStore();
  const globalLoaderStore = new GlobalLoaderStore();
  const confirmDialogStore = new ConfirmDialogStore();
  const sidebarStore = new SidebarStore();
  const routingStore = new RouterStore();

  return {
    authStore,
    advertiserStore,
    localeStore,
    globalLoaderStore,
    confirmDialogStore,
    sidebarStore,
    routingStore,
  };
}

export const sharedStores = initSharedStores();
