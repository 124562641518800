import { action, makeObservable, observable, reaction, computed } from 'mobx';
import { EmptyObject } from 'types';
import { BaseStore } from './Base.store';

export abstract class PaginationStore<
  Data,
  Deps extends Record<string, unknown> = EmptyObject,
  ChildrenStores extends Record<string, unknown> = EmptyObject
> extends BaseStore<Deps, ChildrenStores> {
  @observable
  page = 0;

  @observable
  pageSize = 25;

  @observable
  protected abstract _data: Data;

  @computed
  get data(): Data {
    return this._data;
  }

  @observable
  protected _totalPages = 1;

  @observable
  protected _totalElements = 0;

  protected constructor(deps?: Deps) {
    super(deps);
    makeObservable(this);
  }

  get totalPages(): number {
    return this._totalPages;
  }

  @computed
  get totalElements(): number {
    return this._totalElements;
  }

  @action
  setPage = (page: number): void => {
    this.page = page;
  };

  @action
  setPageSize = (pageSize: number): void => {
    if (pageSize === this.pageSize) return;
    this.pageSize = pageSize;
    this.page = 0;
  };

  /**
   * Setup must be called in subclass constructor - here invalidationScope() is not defined yet
   */
  protected setup(): void {
    // When something in invalidation scope changed
    // We load data
    reaction(
      () => this.invalidationScope(),
      (values, prevValues) => this.revalidate(values, prevValues),
    );
  }

  /**
   * Return array of values, which are tracked and will cause data load
   */
  protected abstract invalidationScope: () => unknown[];

  /**
   * Abstract data loading
   */
  protected abstract revalidate(values: unknown[], prevValues: unknown[]): void | Promise<void>;
}
