import HT from 'handsontable';
import { BaseRenderer } from 'handsontable/renderers';

import { resetRowRenderer } from './resetRowRenderer';
import { columnTypeToHTCellTypeMap } from '../columnTypeMaps/columnTypeToHTCellTypeMap';
import { columnTypeToHTRendererMap } from '../columnTypeMaps/columnTypeToHTRendererMap';
import { applyCellStyleForErrorRows } from '../applyCellStyleForErrorRows';
import { apllyCellStyleForChangedRows } from '../apllyCellStyleForChangedRows';
import { Column } from '../../types';

export function makeRenderer(column: Column): BaseRenderer {
  return (
    instance: HT,
    TD: HTMLTableCellElement,
    rowIndex: number,
    colIndex: number,
    prop: string | number,
    value: HT.CellValue,
    cellProperties: HT.CellProperties,
  ): HTMLTableCellElement | void => {
    const columnType = columnTypeToHTCellTypeMap[column.type];
    const columnName = String(prop);

    if (['date', 'checkbox'].includes(columnType)) {
      HT.dom.addClass(TD, 'htCenter');
    }
    if (!column.readOnly && columnName !== 'isSelected') {
      HT.dom.addClass(TD, 'editable-cell');
    }
    if (columnName === 'resetRowContent') {
      HT.dom.addClass(TD, 'reset-cell');
    }

    if (columnName === 'errors') {
      applyCellStyleForErrorRows(TD, value);
      // eslint-disable-next-line no-param-reassign
      value = '';
    }

    apllyCellStyleForChangedRows(instance, TD, rowIndex, columnName);

    if (column.renderer) {
      column.renderer.call(this, instance, TD, rowIndex, colIndex, prop, value, cellProperties);
    } else if (columnName === 'resetRowContent') {
      resetRowRenderer.call(this, instance, TD, rowIndex);
    } else {
      const renderer = columnTypeToHTRendererMap[columnType] ?? HT.renderers.BaseRenderer;
      renderer.call(this, instance, TD, rowIndex, colIndex, prop, value, cellProperties);
    }
  };
}
