import { ApolloQueryResult } from '@apollo/client';
import { CheckSettingsDictionary } from 'types/programmatic';
import { ServerModels as SM } from 'types/server';

export function toClientCheckSettingsMetadata(
  response: ApolloQueryResult<{ excessCheckSettingsMetadata: SM.ExcessCheckSettingsMetadataDto[] }>,
): CheckSettingsDictionary | null {
  return (
    response.data?.excessCheckSettingsMetadata?.reduce(
      (acc, row) => ({
        ...acc,
        [row.id]: {
          name: row.displayName,
          readOnly: row.privilege === SM.Privilege.Read,
          expirationLimit: row.expirationLimitMs ?? null,
        },
      }),
      {},
    ) ?? null
  );
}
