import React, { FC, PureComponent } from 'react';
import { PieChart, Pie, Label, Cell, Tooltip, Sector, SectorProps, TooltipProps, RechartsFunction } from 'recharts';
import cx from 'classnames';

import { Locale } from 'types';
import { IBrandStatistics } from 'types/brand';

import classes from './BudgetChart.module.scss';

interface IProps {
  year: number;
  brandsStatistics: IBrandStatistics[];
  size: number;
  locale: Locale;
}

interface IState {
  activeSector: number;
}

class BudgetChart extends PureComponent<IProps, IState> {
  state: IState = {
    activeSector: -1,
  };

  handlePieMouseEnter: RechartsFunction = (data: Record<string, unknown>, index: number) => {
    this.setState({
      activeSector: index,
    });
  };

  handlePieMouseLeave: RechartsFunction = () => {
    this.setState({
      activeSector: -1,
    });
  };

  renderTooltip: FC<TooltipProps> = (props: TooltipProps) => {
    const { locale } = this.props;
    const { active, payload } = props;

    if (active === false || payload === undefined || payload[0].payload === undefined) {
      return null;
    }

    const { name, value, color } = payload[0].payload;

    return (
      <div className={classes.Tooltip} style={{ color }}>
        <header className={classes.TooltipHeader}>
          <span className={classes.TooltipBrand}>{name}</span>
        </header>
        <p className={classes.TooltipSum}>{value.toLocaleString(locale)}</p>
      </div>
    );
  };

  renderActiveSector: FC<SectorProps> = (props: SectorProps) => {
    const { cx: centerX, cy: centerY, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

    return (
      <g>
        <Sector
          cx={centerX}
          cy={centerY}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={centerX}
          cy={centerY}
          innerRadius={outerRadius}
          outerRadius={outerRadius ? outerRadius * 1.1 : outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          opacity={0.25}
        />
      </g>
    );
  };

  render() {
    const { year, brandsStatistics, size } = this.props;
    const { activeSector } = this.state;

    const chartData = [...brandsStatistics]
      .sort((a, b) => b.budgetPlan - a.budgetPlan)
      .map((brand) => ({
        name: brand.brandName,
        color: brand.brandColor,
        value: brand.budgetPlan,
      }));

    if (chartData.length === 0) {
      return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          <circle cx="50%" cy="50%" r="45%" className={classes.Pie} />
          <text x="50%" y="50%" dy="0.355em" textAnchor="middle" className={classes.YearLabel}>
            {year}
          </text>
        </svg>
      );
    }

    return (
      <PieChart width={size} height={size} className={classes.BudgetChart}>
        <Pie
          data={chartData}
          innerRadius="70%"
          outerRadius="90%"
          fill="#8884d8"
          startAngle={90}
          endAngle={-270}
          minAngle={5}
          paddingAngle={0.5}
          dataKey="value"
          activeIndex={activeSector}
          activeShape={this.renderActiveSector}
          onMouseEnter={this.handlePieMouseEnter}
          onMouseLeave={this.handlePieMouseLeave}
          className={cx(classes.Pie, {
            [classes.PieActive]: activeSector !== -1,
          })}
        >
          <Label className={classes.YearLabel} value={year} position="center" />
          {chartData.map((entry, index) => (
            <Cell key={index} fill={entry.color} className={classes.Cell} />
          ))}
        </Pie>
        <Tooltip content={this.renderTooltip} />
      </PieChart>
    );
  }
}

export default BudgetChart;
