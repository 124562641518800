import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import { MenuButton } from 'shared/components/common/buttons/MenuButton';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import cx from 'classnames';
import classes from './User.module.scss';

type Props = {
  adminPage: {
    access: boolean;
    label: string;
    link: string;
  };
  logoutButton: {
    label: string;
    onClick(): void;
  };
  userName: string;
  role?: string;
};

export const User: React.VFC<Props> = React.memo((props) => {
  const { userName = '', role, logoutButton, adminPage } = props;

  const userInfo = useMemo(
    () => (
      <div className={classes.userInfo}>
        <span
          className={classes.name}
          data-global-tooltip
          data-pr-tooltip={userName}
          data-pr-position="left"
          data-pr-adjustable={false}
          data-pr-mouse-track-static={false}
        >
          {userName.split(' ').map((x) => (
            <div key={x}>{x}</div>
          ))}
        </span>
        {role && <span>{role}</span>}
      </div>
    ),
    [role, userName],
  );

  const menuItems = useMemo(
    () => [
      {
        template: (item: MenuItem, options: MenuItemOptions) => (
          <NavLink
            className={classes.linkToAdministration}
            data-test="user-settings-administration"
            onClick={() => {
              options.onClick(new Event('click') as never); // to close dropdown menu
            }}
            to={adminPage.link}
          >
            <span className={cx(classes.linkToAdministrationIcon, 'pi pi-cog')} />
            {adminPage.label}
          </NavLink>
        ),
      },
      { command: logoutButton.onClick, icon: 'pi pi-sign-out', label: logoutButton.label },
    ],
    [adminPage.label, adminPage.link, logoutButton.label, logoutButton.onClick],
  );

  return adminPage.access ? (
    <MenuButton
      className={classes.root}
      menuClassName={classes.menu}
      label={userInfo}
      model={menuItems}
      tooltip={userName}
      tooltipOptions={{ position: 'left', showDelay: 300 }}
    />
  ) : (
    <div className={classes.root}>
      {userInfo}
      <TextButton
        className={classes.logoutButton}
        icon="pi pi-sign-out"
        onClick={logoutButton.onClick}
        data-test="logout-button"
      />
    </div>
  );
});

export type { Props as UserProps };
