import React, { useCallback, useState } from 'react';
import { useUpdateEffect, usePrevious } from 'ahooks';
import cx from 'classnames';
import { Calendar, CalendarChangeParams, CalendarDateTemplateParams } from 'primereact/calendar';
import { RadioButton, RadioButtonChangeParams } from 'primereact/radiobutton';
import { Button } from 'shared/components/common/buttons/Button';
import { startOfToday } from 'date-fns';

import { monthNavigatorTemplate, yearNavigatorTemplate, datePeriods } from './DateFilter.helpers';
import { useDataGrid } from '../../../context';

import classes from './DateFilter.module.scss';

type Props = {
  name: string;
  hasTime?: boolean;
};

export const DateFilter: React.VFC<Props> = (props) => {
  const { name, hasTime = false } = props;
  const { filters, setFilters, closeModal } = useDataGrid();
  const filter = filters[name] ?? null;

  const [date, setDate] = useState<Date | Date[] | string | undefined>(
    filter?.type === 'date' ? filter.value.date : undefined,
  );
  const [period, setPeriod] = useState(filter?.type === 'date' ? filter.value.period : datePeriods[0].value);

  const prevPeriod = usePrevious(period);
  useUpdateEffect(() => {
    if (period === 'between' || prevPeriod === 'between') {
      setDate(undefined);
    }
  }, [period]);

  const handleCalendarChange = useCallback(({ value }: CalendarChangeParams) => setDate(value ?? undefined), []);

  const handlePeriodRadioButtonClick = ({ value }: RadioButtonChangeParams) => {
    setPeriod(value);
  };

  const handleCleanButtonClick = () => {
    setDate(undefined);
  };

  const handleApplyButtonClick = useCallback(() => {
    if (Array.isArray(date) && period === 'between') {
      setFilters({
        [name]: { type: 'date', value: { date, period }, hasTime },
      });
    } else if (date instanceof Date && !Array.isArray(date) && period !== 'between') {
      setFilters({
        [name]: { type: 'date', value: { date, period }, hasTime },
      });
    } else {
      setFilters({ [name]: null });
    }
    closeModal();
  }, [closeModal, date, period, name, hasTime, setFilters]);

  const footerTemplate = useCallback(() => {
    return (
      <div className={classes.buttons}>
        <Button
          label="Очистить"
          onClick={handleCleanButtonClick}
          className="p-button-text"
          disabled={!date || (Array.isArray(date) && !date?.length)}
        />
        <Button
          label="Применить"
          onClick={handleApplyButtonClick}
          autoFocus
          disabled={filter?.value === date || (Array.isArray(date) && !date.every(Boolean))}
        />
      </div>
    );
  }, [date, handleApplyButtonClick, filter?.value]);

  const dateTemplate = useCallback(
    ({ year, month, day }: CalendarDateTemplateParams) => {
      if (!(date instanceof Date) || ['between', 'equal'].includes(period)) {
        return day;
      }
      const currentDate = Number(new Date(year, month, day));
      const selectedDate = Number(date);
      if ((period === 'before' && currentDate < selectedDate) || (period === 'after' && currentDate > selectedDate)) {
        return <div className={classes.periodCell}>{day}</div>;
      }

      return day;
    },
    [date, period],
  );

  return (
    <div className={classes.root}>
      <div className={classes.selectModes}>
        {datePeriods.map((x) => {
          return (
            <div key={x.value} className={cx('p-field-radiobutton', classes.mode)}>
              <RadioButton
                inputId={x.value}
                name="select-mode"
                value={x.value}
                onChange={handlePeriodRadioButtonClick}
                checked={period === x.value}
              />
              <label htmlFor={x.value}>{x.label}</label>
            </div>
          );
        })}
      </div>
      <Calendar
        value={date ?? startOfToday()}
        onChange={handleCalendarChange}
        inline
        monthNavigator
        yearNavigator
        yearRange="2010:2030"
        monthNavigatorTemplate={monthNavigatorTemplate}
        yearNavigatorTemplate={yearNavigatorTemplate}
        footerTemplate={footerTemplate}
        showTime={hasTime && period !== 'between'}
        locale="ru"
        selectionMode={period === 'between' ? 'range' : 'single'}
        dateTemplate={dateTemplate}
        showMinMaxRange
      />
    </div>
  );
};
