import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { convertToDatePeriod } from 'helpers';
import { localize } from 'shared/components/other';
import { ICreative } from 'types/mediaplan';
import { Calendar } from 'shared/components/common/form/Calendar';
import classes from './RetroperiodDatepicker.module.scss';

type Props = {
  className?: string;
  creative: ICreative;
  onChange(value: Date[] | null, productId: number): void;
};

export const RetroperiodDatepicker: React.VFC<Props> = (props) => {
  const { className, creative, onChange } = props;

  const handleChange = useCallback(
    (dates: Date | Date[] | null) => {
      if (Array.isArray(dates)) {
        onChange(dates, creative.id);
      }
    },
    [creative.id, onChange],
  );

  const retroPeriod = useMemo(() => convertToDatePeriod({ startDate: creative.retroFrom, endDate: creative.retroTo }), [
    creative,
  ]);

  return (
    <div className={cx(classes.root, className)}>
      <label htmlFor="retroperiod" className={classes.label}>
        {localize('campaign-view.mediaplan.retroperiod')}
      </label>
      <Calendar
        className={classes.calendar}
        onChange={handleChange}
        mode="range"
        inputId="retroperiod"
        value={retroPeriod}
        showClearButton={false}
      />
    </div>
  );
};

export type { Props as RetroperiodDatepickerProps };
