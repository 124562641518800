import { captureException } from '@sentry/react';

import { errorLocales, errorParamLocales, errorsWithNotify, LocaleIdType } from 'locales';
import { IError, IUserErrorMessage } from 'types';
import { MAX_ERROR_MSG_COUNT } from 'helpers/constants';
import { localize, localizeMessage } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';

export const getErrorMessage = (error: IError, alternateMsg: string | null = null): string | null =>
  getUserErrorMessage(error) || getAuthErrorMessage(error) || error.message || alternateMsg;

const getUserErrorMessage = (error: IError) => {
  const { userErrorMessage, userErrorMessageList } = error || {};
  return userErrorMessageList?.length
    ? formatErrorMessageList(userErrorMessageList.map(localizeUserErrorMessage))
    : localizeUserErrorMessage(userErrorMessage);
};

const localizeUserErrorMessage = (userErrorMessage?: IUserErrorMessage): string | null => {
  const { errorMessage, errorParameters } = userErrorMessage || {};
  if (!errorMessage) {
    return null;
  }

  const params: Record<string, string> = errorParameters
    ? Object.entries(errorParameters).reduce((acc, [key, val]) => {
        const param = val && (`template-errors.parameters.${val.toLowerCase()}` as LocaleIdType);
        return { ...acc, [key]: param && errorParamLocales.includes(param) ? localize(param) : val };
      }, {})
    : {};

  return errorLocales.includes(errorMessage) ? localizeMessage({ id: errorMessage }, params) : null;
};

const formatErrorMessageList = (errors: (string | null)[]): string | null => {
  const nonEmptyErrors: string[] = errors.filter((e) => e) as string[];
  const isNeedCrop: boolean = nonEmptyErrors.length > MAX_ERROR_MSG_COUNT;
  const croppedErrors: string[] = isNeedCrop ? nonEmptyErrors.slice(0, MAX_ERROR_MSG_COUNT) : nonEmptyErrors;
  const msg: string = croppedErrors.join('\n\n');

  if (!msg) {
    return null;
  }

  const exceededErrorCount: number = errors.length - Math.min(MAX_ERROR_MSG_COUNT, croppedErrors.length);

  const postfix: string =
    exceededErrorCount > 0
      ? `\n\n... (${localizeMessage({ id: 'errors.more-errors' }, { count: String(exceededErrorCount) })})`
      : '';

  return `${msg}${postfix}`;
};

const getAuthErrorMessage = (error: IError) => {
  if (['invalid_grant', 'invalid_request'].includes(error.message) && error.description) {
    const errorMessageToLocaleIdMap: Record<string, LocaleIdType> = {
      'invalid user credentials': 'auth.errors.wrong-fields',
      'invalid refresh token': 'errors.invalid-refresh-token',
      'no refresh token': 'errors.no-refresh-token',
      'token was not recognised': 'errors.token-was-not-recognised',
    };
    const msgId = errorMessageToLocaleIdMap[error.description.toLowerCase()];
    if (msgId) {
      return localize(msgId);
    }
  }
  return null;
};

const handleError = (error: IError, alternateMsg?: string | null, introMsg?: string | null): void => {
  console.error(error);

  try {
    const msg = getErrorMessage(error, alternateMsg);
    const errorMessage = error.userErrorMessage?.errorMessage;

    if (!msg) {
      throw new Error(`${localize('errors.unknown-error')}. ${localize('errors.support')}.`);
    }

    const message = introMsg ? `${introMsg}\n\n${msg}` : msg;
    const errorForSentry = errorMessage && errorsWithNotify.includes(errorMessage) ? new Error() : undefined;
    toast.error(message, { data: { errorForSentry } });
  } catch (er) {
    const msg = er.message;

    captureException(msg, { extra: error as Record<keyof IError, unknown> });
    toast.error(msg, { data: { errorForSentry: er } });
  }
};

export default handleError;
