import Sweetalert from '@sweetalert/with-react';
import { SwalOptions } from 'sweetalert/typings/modules/options';
import cx from 'classnames';
import { LocaleIdType } from 'locales';
import { localize } from 'shared/components/other';
import { buttonClasses } from 'shared/components/common/buttons/Button';

type Options = Partial<SwalOptions> & {
  type?: 'invalid' | 'removal';
  input?: Partial<HTMLInputElement>;
  cancelTextId?: LocaleIdType;
  confirmTextId?: LocaleIdType;
};

const showConfirmation = async ({
  type,
  title,
  text,
  input,
  cancelTextId = 'button.cancel',
  confirmTextId = type === 'removal' ? 'button.remove' : 'ok',
}: Options): Promise<string | null> =>
  Sweetalert({
    title,
    text,
    content: input && { element: 'input', attributes: { ...input, autocomplete: 'off' } },
    buttons: {
      cancel: {
        text: localize(cancelTextId),
        visible: true,
        className: cx('p-component', 'p-button', buttonClasses.root, buttonClasses.lightTheme),
      },
      confirm: {
        text: localize(confirmTextId),
        className: cx('p-component', 'p-button', buttonClasses.root, {
          'swal-button--confirm_removal': type === 'removal',
        }),
      },
    },
    className: type === 'invalid' ? 'swal-modal_invalid' : undefined,
    closeOnClickOutside: false,
  });

export default showConfirmation;
