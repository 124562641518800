import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import { toNumberString } from 'helpers';
import classes from './Summary.module.scss';

export const Summary = observer(() => {
  const {
    adFoxBannersStore: {
      isSummaryLoading,
      summary: {
        maxImpressions,
        maxImpressionsPerDay,
        bannersSummaryMaxImpressions,
        bannersSummaryMaxImpressionsPerDay,
      },
    },
  } = useStores();

  const positions: [string, unknown][] = [
    ['Общий лимит показов по кампании', maxImpressions],
    ['Общий суточный лимит по кампании', maxImpressionsPerDay],
    ['Сумма лимитов показов по баннерам', bannersSummaryMaxImpressions],
    ['Сумма суточных лимитов по баннерам', bannersSummaryMaxImpressionsPerDay],
  ];

  return (
    <div className={classes.root}>
      {positions.map(([title, value]) => (
        <React.Fragment key={title}>
          <span className={classes.title}>{title}:</span>
          <span className={cx(classes.value)}>
            {isSummaryLoading ? <i className="pi pi-spin pi-spinner" /> : toNumberString(value) || '—'}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
});
