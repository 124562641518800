import { makeObservable, observable, computed, action, runInAction, reaction } from 'mobx';

import Config from 'config';
import { BaseStore } from 'shared/stores';
import { EmptyObject, IOption } from 'types';
import { IStatisticFile, IFilters, ISortSettings, IFileProp } from 'types/mediafact';
import { FileS3Id } from 'types/file';
import { sortByOrder } from 'helpers/utils';
import handleError from 'helpers/handleError';
import { UploadStatisticsViewStore } from './UploadStatisticsView.store';

type ChildrenStores = {
  view: UploadStatisticsViewStore;
};

export class UploadStatisticsStore extends BaseStore<EmptyObject, ChildrenStores> {
  private readonly initialSortSettings: ISortSettings = {
    sortBy: 'timeCreate',
    orderIsAsc: false,
    count: 0,
  };

  private readonly initialFilters: IFilters = {
    name: '',
    cabinets: [],
    selectDate: [null, null],
  };

  readonly fileProps: IFileProp[] = [
    { name: 'statisticsSource', isSortable: true },
    { name: 'fileName', isSortable: true },
    { name: 'timeCreate', isSortable: true },
    { name: 'userCreateName', isSortable: true },
    { name: 'status', isSortable: false },
  ];

  eventSource: EventSource | null = null;

  templateFileS3Id: FileS3Id = null;

  @observable
  files: IStatisticFile[] = [];

  @observable
  filters: IFilters = this.initialFilters;

  @observable
  sortSettings: ISortSettings = this.initialSortSettings;

  @observable
  cabinetOptions: IOption[] = [];

  @computed
  get filteredFiles(): IStatisticFile[] {
    const filteredFiles = this.filterFiles();
    const sortedFiles = this.sortFiles(filteredFiles);

    return sortedFiles;
  }

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      view: new UploadStatisticsViewStore({ uploadStatisticsStore: this }),
    };

    reaction(
      () => this.deps.advertiserStore.currentAdvertiserId,
      () => {
        this.cleanUp();
        this.loadData();
      },
    );
  }

  @action
  cleanUp = (): void => {
    this.files = [];
    this.filters = this.initialFilters;
    this.sortSettings = this.initialSortSettings;
    this.cabinetOptions = [];
    this.templateFileS3Id = null;
    this.unsubscribeFromServerEvents();
  };

  @action
  private changeFileStatus = (event: Event): void => {
    const data = JSON.parse((event as MessageEvent).data);
    const { mediafactManualFileId, newStatus } = data;

    this.files = this.files.map((f) =>
      f.mediafactManualFileId === mediafactManualFileId ? { ...f, status: newStatus } : f,
    );
  };

  @action
  updateFilters = (filters: Partial<IFilters>): void => {
    this.filters = {
      ...this.filters,
      ...filters,
    };
  };

  @action
  updateSortData = (sortBy: keyof IStatisticFile | null): void => {
    if (sortBy === null) return;

    const isSameSortBy = sortBy === this.sortSettings.sortBy;
    this.sortSettings.count = isSameSortBy && sortBy !== 'timeCreate' ? this.sortSettings.count + 1 : 0;

    if (this.sortSettings.count === 2) {
      this.sortSettings = this.initialSortSettings;
    } else {
      this.sortSettings.sortBy = sortBy;
      this.sortSettings.orderIsAsc = isSameSortBy ? !this.sortSettings.orderIsAsc : true;
    }
  };

  @action
  updateFiles = (fileId: IStatisticFile['mediafactManualFileId']): void => {
    this.files = this.files.filter((file) => file.mediafactManualFileId !== fileId);
  };

  private filterFiles = (): IStatisticFile[] => {
    const {
      files,
      filters: {
        cabinets,
        name,
        selectDate: [startDate, endDate],
      },
    } = this;

    const filterByCabinets = (f: IStatisticFile) => !cabinets.length || cabinets.find((c) => c === f.statisticsSource);
    const filterByName = (f: IStatisticFile) => f.fileName.toLowerCase().includes(name.toLowerCase());
    const filterByStartDate = (f: IStatisticFile) =>
      !startDate || new Date(f.timeCreate).getTime() >= new Date(startDate).getTime();
    const filterByEndDate = (f: IStatisticFile) =>
      !endDate || new Date(f.timeCreate).getTime() <= new Date(endDate).setHours(23, 59, 59, 999);

    return files.filter((f) => filterByCabinets(f) && filterByName(f) && filterByStartDate(f) && filterByEndDate(f));
  };

  private sortFiles = (files: IStatisticFile[]): IStatisticFile[] => {
    const {
      sortSettings: { sortBy, orderIsAsc },
    } = this;

    return files.sort((a: IStatisticFile, b: IStatisticFile) => {
      const valA = sortBy === 'timeCreate' ? new Date(a.timeCreate) : a[sortBy];
      const valB = sortBy === 'timeCreate' ? new Date(b.timeCreate) : b[sortBy];

      return orderIsAsc ? sortByOrder(valA, valB) : sortByOrder(valA, valB, 'DESC');
    });
  };

  loadData = (): void => {
    this.loadFiles();
    this.reloadServerEvents();
  };

  loadFiles = async (): Promise<void> => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (advertiserId === null) return;

    const response = await this.services.api.mediafact.list({ customerId: advertiserId });
    if (response.error) {
      throw response.error;
    }

    const cabinetOptions: IOption[] = response.data
      .map((f) => f.statisticsSource)
      .filter((option, index, arr) => option && arr.indexOf(option) === index)
      .map((option, index) => ({
        value: index,
        label: option,
      }));

    runInAction(() => {
      this.files = response.data;
      this.cabinetOptions = cabinetOptions;
    });
  };

  loadTemplateFileId = async (): Promise<void> => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (advertiserId === null) return;

    const response = await this.services.api.mediafact.getTemplate({ customerId: advertiserId });
    if (response.error) return;

    runInAction(() => {
      this.templateFileS3Id = response.data.fileS3Id;
    });
  };

  private subscribeToServerEvents = (): void => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (advertiserId === null) return;

    try {
      const eventSource = new EventSource(
        `${Config.api.cswUrl}/mediafact-manual/xlsx/sse/status-changed?customerId=${advertiserId}`,
        {
          withCredentials: true,
        },
      );

      eventSource.addEventListener('mediafact-manual-file-status-changed', this.changeFileStatus);

      this.eventSource = eventSource;
    } catch (error) {
      handleError(error);
    }
  };

  private unsubscribeFromServerEvents = (): void => {
    if (this.eventSource) {
      this.eventSource.close();
    }
  };

  private reloadServerEvents = (): void => {
    this.unsubscribeFromServerEvents();
    this.subscribeToServerEvents();
  };
}
