import { SortingOptions } from 'types';

export const defaultSorting: SortingOptions = {
  field: 'timeCreate',
  direction: 'DESC',
};

export const PAGE_SIZE = 20;

export const FILTER_ROW_HEIGHT = 40;
