import React from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { FileStatusSelect, FileStatusSelectProps } from '../../../../../../../../../FileStatusSelect';
import classes from './FileStatusSelector.module.scss';

type Props = {
  className?: string;
} & Omit<FileStatusSelectProps, 'size' | 'theme'>;

export const FileStatusSelector: React.VFC<Props> = (props) => {
  const { className, ...fileStatusSelectProps } = props;
  return (
    <div className={cx(classes.root, className)}>
      <label className={classes.label}>{localize('campaign-view.mediaplan.version-status')}</label>
      <FileStatusSelect {...fileStatusSelectProps} size="large" theme="outlined" />
    </div>
  );
};

export type { Props as FileStatusSelectorProps };
