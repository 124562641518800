import React from 'react';
import classes from './TitleCell.module.scss';

type Props = {
  title: React.ReactNode | React.ReactNode[];
};

export const TitleCell: React.VFC<Props> = ({ title }) => {
  return (
    <div className={classes.root}>
      {Array.isArray(title)
        ? title.map((x, i) => (
            <React.Fragment key={i}>
              <div>{x}</div>
              {i !== title.length - 1 && '/'}
            </React.Fragment>
          ))
        : title}
    </div>
  );
};
