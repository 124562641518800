import React from 'react';
import cx from 'classnames';
import { Button } from 'shared/components/common/buttons/Button';
import classes from './ExpandButton.module.scss';
import { GridDataRow } from '../../types';
import { useDataGrid } from '../../context';

type Props = Omit<GridDataRow, 'children'>;

export const ExpandButton: React.VFC<Props> = (props) => {
  const { toggleRowExpanding } = useDataGrid();
  const { depth, isExpanded, rowId, isLoading, hasChildren } = props;
  const icon = isExpanded ? 'pi pi-minus-circle' : 'pi pi-plus-circle';
  if (depth === undefined) return null;
  const isHidden = depth > 1 || hasChildren === false;

  const handleClick = async () => {
    await toggleRowExpanding(rowId);
  };

  return (
    <Button
      icon={icon}
      className={cx('p-button-rounded p-button-text p-button-plain', classes.root, {
        [classes.isHidden]: isHidden,
      })}
      onClick={handleClick}
      loading={isLoading}
      disabled={isHidden}
    />
  );
};
