import React from 'react';
import { CalendarMonthNavigatorTemplateParams, CalendarYearNavigatorTemplateParams } from 'primereact/calendar';
import { Dropdown } from 'shared/components/common/form/Dropdown';

export const monthNavigatorTemplate = (e: CalendarMonthNavigatorTemplateParams): React.ReactNode => {
  return (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={(event) => {
        // TODO разобраться почему в доке и  здесь работает, но ругается
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.onChange(event.originalEvent, event.value);
      }}
      style={{ lineHeight: 1 }}
    />
  );
};

export const yearNavigatorTemplate = (e: CalendarYearNavigatorTemplateParams): React.ReactNode => {
  return (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={(event) => {
        // TODO разобраться почему в доке и  здесь работает, но ругается
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.onChange(event.originalEvent, event.value);
      }}
      style={{ lineHeight: 1 }}
    />
  );
};

export const datePeriods = [
  { label: 'Равна', value: 'equal' },
  { label: 'До', value: 'before' },
  { label: 'После', value: 'after' },
  { label: 'Между', value: 'between' },
] as const;
