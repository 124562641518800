import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { Tooltip } from 'primereact/tooltip';

import classes from './InfoIcon.module.scss';

type Props = {
  tooltip: string;
  color?: CSSProperties['color'];
  className?: string;
  iconClassName?: string;
};

export const InfoIcon: React.FC<Props> = ({ tooltip, color, className, iconClassName = 'pi pi-info-circle' }) => (
  <div className={cx(classes.root, className)}>
    <Tooltip className={classes.tooltip} target={`.${classes.icon}`} />
    <i
      className={cx(classes.icon, iconClassName)}
      style={{ color }}
      data-pr-tooltip={tooltip}
      data-pr-showdelay="500"
      data-pr-at="center bottom"
      data-pr-my="right top"
    />
  </div>
);
