import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import config from 'config';
import hasAccess, { programmaticRoles } from 'helpers/hasAccess';
import { useStores } from 'core';
import { localize } from 'shared/components/other';
import telegramIconSrc from 'assets/telegram-icon.png';
import { Header as HeaderComponent, HeaderProps } from 'shared/components/layout/Header';
import { CabinetStatus } from 'shared/components/domain/CabinetStatus';
import { InstructionIconLink } from 'shared/components/domain/InstructionIconLink';
import { toNavMenuItems } from './Header.helpers';
import classes from './Header.module.scss';

export const Header: React.VFC = observer(() => {
  const location = useLocation();
  const isProgrammaticPage = location.pathname === '/programmatic';
  const { advertiserStore, accountsStore, authStore, powerBiStore, excessStore } = useStores();
  const { advertisers, getAdvertiserLogoUrl, currentAdvertiserId, selectAdvertiser } = advertiserStore;
  const { isAuthorized, profile, logOut } = authStore;

  useEffect(() => {
    accountsStore.loadCabinetStatus();
  }, [accountsStore]);

  useEffect(() => {
    if (!currentAdvertiserId || !isAuthorized) return;
    if (hasAccess('competitorAnalysis', 'pages')) {
      powerBiStore.loadPowerBIReport({
        pageName: 'COMPETITOR_ANALYSIS',
        advertiserId: currentAdvertiserId,
        withLoader: false,
      });
    }
    if (hasAccess('cabinetStatus', 'pages')) {
      powerBiStore.loadPowerBIReport({
        pageName: 'CABINET_STATUS',
        advertiserId: currentAdvertiserId,
        withLoader: false,
      });
    }
    if (hasAccess('prgBenchmarks', 'pages')) {
      powerBiStore.loadPowerBIReport({
        pageName: 'PRG_BENCHMARK',
        advertiserId: currentAdvertiserId,
        withLoader: false,
      });
    }
  }, [currentAdvertiserId, isAuthorized, powerBiStore]);

  const advertiserSelectOptions = useMemo(
    () =>
      advertisers.map((advertiser) => ({
        label: advertiser.name,
        value: advertiser.id,
        logo: getAdvertiserLogoUrl(advertiser.logoFileId),
      })),
    [advertisers, getAdvertiserLogoUrl],
  );

  const info: HeaderProps['info'] = useMemo(() => {
    if (isProgrammaticPage) {
      return excessStore.lastUpdateTime ? (
        <div className={classes.lastUpdateTime}>
          <span>{localize('header.info.last-update')}</span>
          <span>{excessStore.lastUpdateTime}</span>
        </div>
      ) : null;
    }
    return {
      type: 'selector',
      value: currentAdvertiserId,
      onChange: selectAdvertiser,
      options: advertiserSelectOptions,
    };
  }, [advertiserSelectOptions, currentAdvertiserId, excessStore.lastUpdateTime, isProgrammaticPage, selectAdvertiser]);

  const navMenu: HeaderProps['navMenu'] = useMemo(() => {
    type AccessModel<T> = Record<string, T>;
    const accessModel: AccessModel<boolean | AccessModel<boolean>> = {
      brands: hasAccess('brands', 'pages'),
      campaigns: hasAccess('campaigns', 'pages'),
      tools: {
        competitorAnalysis:
          powerBiStore.pageNames.includes('COMPETITOR_ANALYSIS') && hasAccess('competitorAnalysis', 'pages'),
        uploadStatistics: !!config.isShowUploadStatistics && hasAccess('uploadStatistics', 'pages'),
        prgBenchmarks: powerBiStore.pageNames.includes('PRG_BENCHMARK') && hasAccess('prgBenchmarks', 'pages'),
        programmatic: hasAccess('programmatic', 'pages'),
        accounts: hasAccess('accounts', 'pages'),
        reportConstructor: hasAccess('reportConstructor', 'pages'),
        cabinetCopying: hasAccess('cabinetCopying', 'pages'),
      },
    };
    return { model: toNavMenuItems(accessModel) };
  }, [powerBiStore.pageNames]);

  const instructionLinkUrl = useMemo(() => {
    if (profile?.roles.includes('client')) {
      return null; // no link for clients yet
    }
    return 'https://aizek-cs.notion.site/aizek-cs/Aizek-ClientSpace-575fd260fdf447c889c044d8db5e7ad7';
  }, [profile?.roles]);

  const linkIcons: HeaderProps['linkIcons'] = useMemo(() => {
    const model = [];
    if (hasAccess('cabinetStatus', 'pages')) {
      model.push({ url: '/cabinet-status', icon: <CabinetStatus value={accountsStore.cabinetStatus} /> });
    }
    if (hasAccess('instructionLink', 'header') && instructionLinkUrl) {
      model.push({
        icon: (
          <InstructionIconLink
            popupMessage={localize('header.instruction-link-confirm-message')}
            url={instructionLinkUrl}
          />
        ),
      });
    }
    if (hasAccess('telegramLink', 'header')) {
      model.push({ url: 'https://t.me/joinchat/V-ZmhDSjS-5BtbNP', icon: telegramIconSrc, target: '_blank' });
    }
    return { model };
  }, [accountsStore.cabinetStatus, instructionLinkUrl]);

  const programmaticRole = useMemo((): string | undefined => {
    if (!isProgrammaticPage || profile === null) return undefined;
    const role = profile.additionalRoles.find((adRole) => Object.keys(programmaticRoles).includes(adRole));
    return role && programmaticRoles[role];
  }, [isProgrammaticPage, profile]);

  const user: HeaderProps['user'] = {
    adminPage: {
      access: hasAccess('administration', 'pages'),
      label: localize('header.settings.administration'),
      link: '/administration/advertiser',
    },
    logoutButton: { label: localize('header.settings.logout'), onClick: logOut },
    role: programmaticRole,
    userName: profile?.name || profile?.email || profile?.login || '',
  };

  return <HeaderComponent className={classes.root} info={info} navMenu={navMenu} linkIcons={linkIcons} user={user} />;
});
