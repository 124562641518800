import React, { useCallback } from 'react';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';
import { GridDataRow } from '../../types';
import { useDataGrid } from '../../context';

type Props = Omit<GridDataRow, 'children'>;

const options = [
  { value: true, icon: 'pi pi-check' },
  { value: 'intermediate', icon: 'pi pi-minus' },
];

export const SelectionButton: React.VFC<Props> = (props) => {
  const { toggleRowSelection } = useDataGrid();
  const { rowId, selectionState } = props;

  const handleChange = useCallback(() => {
    toggleRowSelection(rowId);
  }, [rowId, toggleRowSelection]);

  return <MultiStateCheckbox value={selectionState} options={options} optionValue="value" onChange={handleChange} />;
};
