/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useStores } from 'core';
import { observer } from 'mobx-react';
import { localize } from 'shared/components/other';
import hasAccess from 'helpers/hasAccess';
import { PaginatorProps } from 'shared/components/common/misc/Paginator';
import { Campaigns as CampaignsComponent } from './components/Campaigns';
import classes from './Campaigns.module.scss';

const Campaigns = observer(() => {
  const { campaignsStore: store, appearanceStore } = useStores();

  useEffect(() => {
    store.activate();
    return () => store.reset();
  }, []);

  const isAddButtonVisible = hasAccess('editCampaign', 'pages');

  const filter = useMemo(
    () => ({
      brandOptions: store.brandOptions,
      filters: store.filters,
      onChange: store.setFilters,
    }),
    [store.brandOptions, store.filters],
  );

  const sorter = useMemo(
    () => ({
      value: store.sortValue,
      onChange: store.setSortValue,
      options: [
        { value: 'name', label: localize('campaigns.sort-by-name') },
        { value: 'dateStart', label: localize('campaigns.sort-by-start-date') },
      ],
    }),
    [store.setSortValue, store.sortValue],
  );

  const paginator: PaginatorProps = useMemo(
    () => ({
      page: store.page,
      rows: store.pageSize,
      onPageChange: ({ page, rows }) => {
        store.setPage(page);
        store.setPageSize(rows);
      },
    }),
    [store.page, store.pageSize],
  );

  return (
    <CampaignsComponent
      brands={store.brands}
      cardsTheme={appearanceStore.campaignTheme}
      className={classes.root}
      data={store.data}
      filter={filter}
      isAddButtonVisible={isAddButtonVisible}
      locale="ru"
      onFavoriteButtonClick={store.toggleCampaignFavorite}
      paginator={paginator}
      sorter={sorter}
      totalRecords={store.totalElements}
    />
  );
});

export { Campaigns };
