import React from 'react';
import { MultiSelect, MultiSelectChangeParams } from 'shared/components/common/form/MultiSelect';
import { IOption } from 'types';
import { useDataGrid } from '../../../context';
import classes from './MultiSelectFilter.module.scss';

type Props = {
  name: string;
  options: IOption<string | number>[];
  placeholder?: string;
};

export const MultiSelectFilter: React.FC<Props> = ({ name, options, placeholder }) => {
  const { filters, setFilters } = useDataGrid();
  const filter = filters[name] ?? null;
  const values = filter?.type === 'multiselect' ? filter.value : null;

  const getTooltip = (): string | undefined =>
    values?.map((value) => options.find((option) => option.value === value)?.label ?? '').join('; ');

  const onChange = (e: MultiSelectChangeParams) => {
    setFilters({
      [name]: e.value?.length ? { type: 'multiselect', value: e.value } : null,
    });
  };

  return (
    <MultiSelect
      className={classes.root}
      value={values}
      tooltip={getTooltip()}
      tooltipOptions={{ className: classes.tooltip }}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      filter
      size="small"
    />
  );
};
