import { computed } from 'mobx';

import Config from 'config';
import { IError } from 'types';
import { ServerModels as SM } from 'types/server';
import { AdfoxRowInfo, RowStatus, RowStatuses } from 'types/tradingDesk/adfox';
import { AdFoxStore } from './AdFox.store';
import { ADFOX_API_ACCOUNT_ID, ADFOX_CAMPAIGNS_PAGE_ID, convertToServerRow } from '../helpers';

const accessibleCampaignIDs = [1812854, 1815559, 1815562]; // TODO: remove it

export class AdFoxCampaignsStore extends AdFoxStore {
  @computed
  // eslint-disable-next-line class-methods-use-this
  protected get _requiredFilters(): SM.AdfoxCampaignFilter {
    return { apiAccountId: ADFOX_API_ACCOUNT_ID };
  }

  protected _dataType = 'campaigns' as const;

  protected _pageId = ADFOX_CAMPAIGNS_PAGE_ID;

  protected getLoadedRowsData = async (): Promise<SM.AdfoxCampaignView[] | null> => {
    const response = await this.services.api.tradingDesk.adfox.campaigns({
      filter: this.filters as SM.AdfoxCampaignFilter,
    });
    return response?.data?.adfoxCampaigns ?? null;
  };

  protected getRefreshedRowsData = async (rows: AdfoxRowInfo[]): Promise<RowStatus[] | null> => {
    const response = await this.services.api.tradingDesk.adfox.refreshCampaigns({
      campaigns: rows.map(({ row }) => ({ id: row.id })),
    });
    return response.error ? null : response.data.statuses;
  };

  protected getUpdatedRowsData = async (rows: AdfoxRowInfo[]): Promise<RowStatuses | IError | null> => {
    const response = await this.services.api.tradingDesk.adfox.updateCampaigns({
      campaigns: rows.map(
        ({ row }) => convertToServerRow(row, this._defaultRows, this._columns) as Partial<SM.AdfoxCampaignView>,
      ),
    });
    return response.error ? null : response.data;
  };

  protected filterRowsBeforeSaving = (rows: AdfoxRowInfo[]): AdfoxRowInfo[] =>
    Config.appOptions.standType === 'production'
      ? rows
      : rows.filter(({ row }) => accessibleCampaignIDs.includes(row.id));
}
