/* eslint-disable class-methods-use-this */
import { AggregatedTableData, AggregatedTreeData, PivotStructure } from 'types/reportConstructor';
import { ReportConstructorStore } from '../../ReportConstructor.store';
import { emptyPivotData } from './config';

export const createCustomBackend = (store: ReportConstructorStore): typeof pivot.services.Backend => {
  return class CustomBackend extends pivot.services.Backend {
    lastStructure = {} as PivotStructure;

    lastData = { ...emptyPivotData };

    async data(): Promise<AggregatedTableData | AggregatedTreeData> {
      const structure: PivotStructure = $$('pivot').getStructure();
      const { mode }: { mode: 'tree' | 'table' } = $$('pivot').getState();

      const hasChange = JSON.stringify(structure) !== JSON.stringify(this.lastStructure);
      if (hasChange) {
        this.lastStructure = { ...structure };
      }

      if (structure.rows.length === 0 && structure.columns.length === 0) {
        return webix.promise.resolve(
          webix.copy(mode === 'tree' ? { ...emptyPivotData.tree } : { ...emptyPivotData.table }),
        );
      }

      if (hasChange) {
        const data = await store.loadDataForCustomBackend(structure);
        this.lastData = { ...data };
      }

      return webix.promise.resolve(webix.copy(mode === 'tree' ? this.lastData.tree : this.lastData.table));
    }
  };
};
