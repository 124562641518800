import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';
import { localize } from 'shared/components/other';
import classes from './Sorter.module.scss';

type SortOrder = 'ASC' | 'DESC';

type SortValue<T extends string = string> = {
  by: T;
  order: SortOrder;
};

type SortOptionValue = SortValue & {
  label: string;
  icon: string;
};

type Props = {
  /** Additional className */
  className?: string;
  /** Callback to invoke on value change. */
  onChange(value: SortValue): void;
  /** An array of sorter options */
  options: Array<{ value: string; label: string }>;
  /** Sorter value */
  value: SortValue;
  /** Title on the left side. Default `${localize('campaigns.sort')}: ` */
  title?: string;
};

export const Sorter: React.VFC<Props> = (props) => {
  const { className, onChange, options, value, title = `${localize('campaigns.sort')}: ` } = props;

  const optionsMap: Record<string, SortOptionValue[]> = useMemo(() => {
    return options.reduce(
      (acc, option) => ({
        ...acc,
        [option.value]: [
          { by: option.value, order: 'ASC', label: option.label, icon: 'pi pi-sort-up' },
          { by: option.value, order: 'DESC', label: option.label, icon: 'pi pi-sort-down' },
        ],
      }),
      {},
    );
  }, [options]);

  const sortOptionValue: SortOptionValue = R.pipe(
    R.values,
    R.flatten,
    R.find((x) => R.whereEq(value, x)),
  )(optionsMap);

  const invertedSortOrder = useMemo(() => (sortOptionValue.order === 'ASC' ? 'DESC' : 'ASC'), [sortOptionValue]);

  const handleChange = useCallback(
    (by: string) => {
      const order = sortOptionValue.by === by ? invertedSortOrder : 'ASC';
      onChange({ by, order });
    },
    [sortOptionValue, invertedSortOrder, onChange],
  );

  return (
    <div className={cx(classes.root, className)}>
      <span>{title}</span>
      <div className={classes.options}>
        {options.map((x) => {
          const id = `sort-by-${x.value}`;
          return (
            <div className={cx(classes.option, { [classes.active]: x.value === sortOptionValue.by })} key={id}>
              <span className={classes.label} data-test={id} onClick={() => handleChange(x.value)}>
                {x.label}
              </span>
              <MultiStateCheckbox
                empty={false}
                onChange={() => handleChange(x.value)}
                options={optionsMap[x.value]}
                value={sortOptionValue.order}
                optionValue="order"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export type { Props as SorterProps, SortValue };
