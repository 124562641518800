import React from 'react';
import { ColorButton } from '../../buttons/ColorButton';
import classes from './ColorPalette.module.scss';

type Props = {
  /** An array of css colors */
  colors: string[];
  /** Label */
  label?: string;
  /** On color pick event handler */
  onPick(color: string | null): void;
};

export const ColorPalette: React.FC<Props> = (props) => {
  const { colors, label, onPick } = props;

  return colors.length ? (
    <div className={classes.root}>
      {label && <p className={classes.label}>{label}</p>}
      <div className={classes.buttons}>
        {colors.map((color) => (
          <ColorButton className={classes.colorButton} color={color} key={color} onClick={onPick} />
        ))}
      </div>
    </div>
  ) : null;
};
