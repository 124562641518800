import { merge } from 'lodash/fp';

import Api from './Api';
import { graphqlAPI } from './graphql';
import { makeReauthorize, showNotification, retryRequest } from './helpers/interceptors';

const api = Api.getInstance();

api.setErrorInterceptors({
  reauthorize: makeReauthorize(api.auth),
  showNotification,
  retryRequest,
});

export { apiMock } from './mocks/apiMock';

export default merge(api, graphqlAPI);
