/* eslint-disable no-param-reassign */
import { makeObservable, action, observable, computed, runInAction } from 'mobx';
import * as R from 'ramda';

import handleError from 'helpers/handleError';
import { toast } from 'shared/components/common/misc/Toast';
import { localize } from 'shared/components/other';
import { BaseStore } from 'shared/stores';
import { EmptyObject, IOption } from 'types';
import {
  AdvertisementProps,
  FileData,
  FileTypes,
  MediaData,
  MediaTypes,
  OptionData,
  OptionName,
  Options,
  TemplateData,
  TextFieldProps,
} from 'types/cabinetCopying';

import { LocaleIdType } from 'locales';
import { isValidUrl } from 'helpers/isValidUrl';
import { encodeUrlParams } from 'helpers/encodeUrlParams';
import { getFileType } from '../helpers/getFileType';
import { initialFileData, initialOptionConfig, optionNamesByPriority } from '../helpers/config';
import { CabinetCopyingLogStore } from './CabinetCopyingLog.store';

type ChildrenStores = {
  log: CabinetCopyingLogStore;
};

export class CabinetCopyingStore extends BaseStore<EmptyObject, ChildrenStores> {
  @observable
  isPanelActive = true;

  @observable
  options: Options = initialOptionConfig;

  @observable
  optionNames: OptionName[] = optionNamesByPriority;

  @observable
  textFields: TextFieldProps[] = [];

  @observable
  private fileData: FileData = initialFileData;

  @observable
  private mediaData: MediaData = {};

  @observable
  private templateData: TemplateData[] = [];

  private abortController: AbortController | null = null;

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      log: new CabinetCopyingLogStore(),
    };
  }

  @computed
  get isClientOptional(): boolean {
    return (
      this.options.account.items.find((item) => item.value === this.options.account.value)?.accountType === 'general'
    );
  }

  @computed
  get isValid(): boolean {
    const areOptionValuesValid = Object.entries(this.options).every(([optionName, option]) => {
      const isRedundantOption =
        (optionName === 'client' && this.isClientOptional) || !this.optionNames.includes(optionName as OptionName);
      return option.value !== null || isRedundantOption;
    });

    const areTextValuesValid = this.textFields.every(({ invalid }) => !invalid);

    const isExcelValid = this.fileData.EXCEL !== null;
    const isMediaValid = this.needMediaFile ? this.fileData.MEDIA !== null : true;
    const isLogoValid = this.needLogoFile ? this.fileData.LOGO !== null : true;

    return areOptionValuesValid && areTextValuesValid && isExcelValid && isMediaValid && isLogoValid;
  }

  @computed
  get advertisement(): (IOption & AdvertisementProps) | undefined {
    return this.options.ad.items.find((item) => item.value === this.options.ad.value);
  }

  @computed
  get needMediaFile(): boolean {
    return !!this.advertisement?.allowedMediaTypes.length;
  }

  @computed
  get needLogoFile(): boolean {
    return !!this.advertisement?.requiredLogo;
  }

  @computed
  get templateFileId(): string | null {
    const cabinetId = this.options.cabinet.value;
    return this.templateData.find((item) => item.cabinetId === cabinetId)?.fileS3Id ?? null;
  }

  @computed
  private get optionValues(): OptionData {
    return R.mapObjIndexed((option) => option.value ?? undefined, this.options);
  }

  @action
  cleanUp = (): void => {
    this.options = initialOptionConfig;
    this.optionNames = optionNamesByPriority;
    this.textFields = [];
    this.fileData = initialFileData;
    this.mediaData = {};
    this.abortController = null;
  };

  @action
  setIsPanelActive = (isActive: boolean): void => {
    this.isPanelActive = isActive;
  };

  @action
  setOption = async (optionName: OptionName, value: number | null): Promise<void> => {
    if (optionName === 'cabinet') {
      this.options = { ...initialOptionConfig, cabinet: this.options.cabinet };
      this.options.cabinet.value = value;
      await this.loadMetadata();
    } else {
      this.options[optionName].value = value;
    }

    if (value === null) return;

    const optionIndex = this.optionNames.findIndex((name) => name === optionName);
    const nextOptionName = this.optionNames[optionIndex + 1];

    if (nextOptionName) {
      await this.loadOptionItems(nextOptionName);
    }
  };

  @action
  setTextField = (fieldName: string, value: string): void => {
    this.textFields.forEach((textField) => {
      if (textField.name === fieldName) {
        textField.value = value;

        if (textField.isOptional && value === '') {
          textField.invalid = false;
        } else if (textField.validationType === 'url') {
          textField.invalid = !isValidUrl(value);
        }
      }
    });
  };

  @action
  setFileData = (file: File | undefined, fileType: FileTypes): void => {
    this.fileData[fileType] = file ?? null;

    if (fileType === FileTypes.MEDIA) {
      this.mediaData.images = undefined;
      this.mediaData.video = undefined;
    } else if (fileType === FileTypes.LOGO) {
      this.mediaData.logo = undefined;
    }
  };

  loadMetadata = async (): Promise<void> => {
    const cabinetId = this.options.cabinet.value;

    if (cabinetId === null) return;

    const response = await this.services.api.cabinetCopying.loadMetadata(cabinetId);

    runInAction(() => {
      if (response.data) {
        this.optionNames = optionNamesByPriority.filter((option) => response.data.find(({ name }) => name === option));
        this.textFields = response.data
          .filter(({ format }) => format !== 'list')
          .map(({ name, format, isOptional }) => ({
            name,
            value: '',
            isOptional,
            invalid: false,
            validationType: format !== 'list' ? format : undefined, // NOTE: other validation types may be added
          }));
      }
    });
  };

  @action
  loadOptionItems = async (optionName: OptionName): Promise<void> => {
    const optionIndex = this.optionNames.findIndex((name) => name === optionName);
    const restOptionNames: OptionName[] = this.optionNames.slice(optionIndex);
    const optionToBeLoaded =
      restOptionNames[0] === 'client' && this.isClientOptional ? restOptionNames[1] : restOptionNames[0];

    restOptionNames.forEach(this.resetOption);

    if (optionToBeLoaded) {
      await this.loadOption(optionToBeLoaded);
    }
  };

  loadTemplateData = async (): Promise<void> => {
    const response = await this.services.api.cabinetCopying.loadTemplateData();

    runInAction(() => {
      this.templateData = response.data ?? [];
    });
  };

  downloadTemplate = async (): Promise<void> => {
    if (this.templateFileId === null) return;

    this.services.api.file.download({ fileId: this.templateFileId, fileType: 'ACCOUNT_COPYING' });
  };

  save = async (): Promise<void> => {
    if (this.fileData.EXCEL === null) return;

    try {
      await this.updateMediaData();

      const optionValues = {
        cabinetId: this.optionValues.cabinet,
        accountId: this.optionValues.account,
        clientId: this.optionValues.client,
        campaignId: this.optionValues.campaign,
        groupId: this.optionValues.adGroup,
        adsId: this.optionValues.ad,
      };
      const textValues = this.textFields.reduce(
        (acc, { name, value, validationType }) => ({
          ...acc,
          [name]: validationType === 'url' && value ? new URL(encodeUrlParams(value)).href : value,
        }),
        {},
      );
      const data = { ...optionValues, ...textValues, mediaData: this.mediaData };

      const response = await this.services.api.cabinetCopying.uploadExcel(this.fileData.EXCEL, data);

      runInAction(() => {
        if (response.error) return;

        toast.success(localize('messages.file-uploaded-successfully'));
        this.setIsPanelActive(false);
      });
    } catch (error) {
      const label =
        localize(`cabinet-copying.uploader.${error.description?.toLowerCase()}` as LocaleIdType) ??
        localize('cabinet-copying.uploader.media-file');
      const introMsg = `${localize('cabinet-copying.uploader.failed-to-upload')} ${label}`;
      handleError(error, null, introMsg);
    }
  };

  @action
  private loadOption = async (optionName: OptionName): Promise<void> => {
    this.abortController?.abort();
    this.abortController = ['campaign', 'adGroup', 'ad'].includes(optionName) ? new AbortController() : null;

    const { cabinetCopying } = this.services.api;
    const optionNameToLoadingFnMap = {
      cabinet: cabinetCopying.loadCabinets,
      account: cabinetCopying.loadAccounts,
      client: cabinetCopying.loadClients,
      campaign: cabinetCopying.loadCampaigns,
      adGroup: cabinetCopying.loadAdGroups,
      ad: cabinetCopying.loadAds,
    };

    const option = this.options[optionName];
    option.requestCount += 1;

    const response = await optionNameToLoadingFnMap[optionName](this.optionValues, {
      withLoader: false,
      signal: this.abortController?.signal,
    });

    runInAction(() => {
      option.items = response.data ?? [];
      option.requestCount -= 1;

      if (option.items.length === 1) {
        this.setOption(optionName, option.items[0].value);
      }

      if (response.error?.message !== 'canceled') {
        option.disabled = false;
      }
    });
  };

  @action
  private resetOption = (optionName: OptionName): void => {
    const option = this.options[optionName];
    option.items = [];
    option.value = null;
    option.disabled = true;
  };

  private updateMediaData = async (): Promise<void> => {
    if (this.needMediaFile && this.fileData.MEDIA) {
      const fileType = getFileType(this.fileData.MEDIA);
      if (fileType === MediaTypes.IMAGE && !this.mediaData.images) {
        this.mediaData.images = await this.getUploadedImageData(this.fileData.MEDIA);
      } else if (fileType === MediaTypes.VIDEO && !this.mediaData.video) {
        this.mediaData.video = await this.getUploadedVideoData(this.fileData.MEDIA);
      }
    }
    if (this.needLogoFile && this.fileData.LOGO && !this.mediaData.logo) {
      this.mediaData.logo = await this.getUploadedLogoData(this.fileData.LOGO);
    }
  };

  private getUploadedImageData = async (file: File): Promise<string[]> => {
    const response = await this.services.api.cabinetCopying.uploadPhoto(file, this.advertisement?.adFormat, {
      withErrorNotification: false,
    });

    return runInAction(() => {
      if (response.error) {
        response.error.description = MediaTypes.IMAGE;
        throw response.error;
      }
      return [response.data.url];
    });
  };

  private getUploadedVideoData = async (file: File): Promise<string> => {
    const response = await this.services.api.cabinetCopying.uploadVideo(file, { withErrorNotification: false });

    return runInAction(() => {
      if (response.error) {
        response.error.description = MediaTypes.VIDEO;
        throw response.error;
      }
      return response.data.url;
    });
  };

  private getUploadedLogoData = async (file: File): Promise<string> => {
    const response = await this.services.api.cabinetCopying.uploadLogo(file, { withErrorNotification: false });

    return runInAction(() => {
      if (response.error) {
        response.error.description = MediaTypes.LOGO;
        throw response.error;
      }
      return response.data.url;
    });
  };
}
