import React from 'react';
import { Column, GridRow } from '../types';
import { isGridTitleRow } from '../typeGuards';
import { getRowTitle } from './getRowTitle';

type Args<DR> = {
  gridRows: GridRow<DR>[];
  scrollTop: number;
  dataRowHeight: number;
  titleRowHeight: number;
  rowGap: number;
  titleRowTemplates: Column<DR>['titleRowTemplates'];
};

export function getPinnedRowData<DR>(
  args: Args<DR>,
): { title: React.ReactNode[] | null; stickyDistance: number } | null {
  const { gridRows, scrollTop, dataRowHeight, titleRowHeight, rowGap, titleRowTemplates } = args;
  let top = 0;
  for (let i = 0; i < gridRows.length; i += 1) {
    const row = gridRows[i];
    const nextRow = gridRows[i + 1];
    if (isGridTitleRow(row)) {
      top += titleRowHeight + rowGap;
    } else {
      top += dataRowHeight;
    }
    if (top >= scrollTop && (!nextRow || isGridTitleRow(nextRow))) {
      return {
        title: getRowTitle(row, titleRowTemplates),
        stickyDistance: Math.min(top - scrollTop - rowGap - titleRowHeight, 0),
      };
    }
  }

  return null;
}
