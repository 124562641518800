/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomOptions, Response } from 'api/types';
import { KeyCloakResponse, BaseServerProfile } from 'types/profile';
import { serialize } from 'helpers';
import { convertTokenRequest, decodeToken } from '../helpers/converters';
import BaseApi from '../BaseApi';

type ReLogInResult = {
  status: boolean;
  response: any;
};

let hasReLogInRequest = false;
let reLogInSendCallbacksTimer: any;
let reLogInResult: ReLogInResult | null;
let additionalReLogInRequests: {
  resolve: (response: any) => void;
  reject: (response: any) => void;
}[] = [];

const reLogInSendCallbacks = () => {
  if (!reLogInResult) {
    return;
  }

  clearTimeout(reLogInSendCallbacksTimer);

  reLogInSendCallbacksTimer = setTimeout(() => {
    additionalReLogInRequests = additionalReLogInRequests.filter((request) => {
      if (reLogInResult) {
        request[reLogInResult.status ? 'resolve' : 'reject'](reLogInResult.response);
      }

      return false;
    });

    hasReLogInRequest = false;
    reLogInResult = null;
  }, 100);
};

class Auth extends BaseApi {
  logIn = async ({ username, password }: LogInData, config: CustomOptions = {}) => {
    const response = await this.actions.post<KeyCloakResponse>({
      url: '/auth/realms/ADV/protocol/openid-connect/token',
      config: {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withToken: false,
        ...config,
      },
      data: serialize({
        grant_type: 'password',
        username,
        password,
        client_id: this.actions.clientId,
      }),
      domainType: 'keyсloackApi',
    });

    return this.convertData(response, convertTokenRequest);
  };

  reLogIn = async (logInByRefreshToken: () => Promise<Response<KeyCloakResponse>>): Promise<void> => {
    if (!hasReLogInRequest) {
      hasReLogInRequest = true;

      try {
        const response = await logInByRefreshToken();
        if (response.error) {
          throw response.error;
        }

        reLogInResult = {
          status: true,
          response,
        };
        reLogInSendCallbacks();
      } catch (error) {
        reLogInResult = {
          status: false,
          response: error,
        };
        reLogInSendCallbacks();
      }
    }

    return new Promise((resolve, reject) => {
      additionalReLogInRequests.push({
        resolve,
        reject,
      });

      if (reLogInResult) {
        reLogInSendCallbacks();
      }
    });
  };

  logInByRefreshToken = (refreshToken: string | null, config: CustomOptions = {}) =>
    this.actions.post<KeyCloakResponse>({
      url: '/auth/realms/ADV/protocol/openid-connect/token',
      config: {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withToken: false,
        ...config,
      },
      data: serialize({
        grant_type: 'refresh_token',
        client_id: this.actions.clientId,
        refresh_token: refreshToken,
      }),
      domainType: 'keyсloackApi',
    });

  validateToken = async (accessToken: string | null, config: CustomOptions = {}): Promise<BaseServerProfile | null> => {
    await this.actions.get({
      url: '/customer/',
      data: { pageSize: 10000 },
      config,
    });

    return decodeToken(accessToken);
  };

  logOut = (refreshToken: string | null, config: CustomOptions = {}) => {
    this.actions.post({
      url: '/auth/realms/ADV/protocol/openid-connect/logout',
      config: {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withToken: false,
        ...config,
      },
      data: serialize({
        realm: 'ADV',
        client_id: this.actions.clientId,
        refresh_token: refreshToken,
      }),
      domainType: 'keyсloackApi',
    });
  };
}

type LogInData = {
  username: string;
  password: string;
};

export default Auth;
