import { formatDate } from 'helpers/utils';

const convertDate = (date: string) => formatDate({ date });

type Args = { dateStart: string; dateEnd: string };

export function toDatePeriod({ dateStart, dateEnd }: Args): string {
  // eslint-disable-next-line no-irregular-whitespace
  return `${convertDate(dateStart)} - ${convertDate(dateEnd)}`;
}
