import React from 'react';
import cx from 'classnames';
import { ProgressSpinner, ProgressSpinnerProps } from 'primereact/progressspinner';
import classes from './Loader.module.scss';

export type Props = ProgressSpinnerProps & {
  /** Additional className */
  className?: string;
  /** Specifies if the component should stretch to fullscreen. Default: false */
  fullscreen?: boolean;
  /** Size of the component. Default: 'medium' */
  size?: 'small' | 'medium' | 'large';
  /** Specifies the visibility of a component. Default: true */
  visible?: boolean;
  /** Specifies if component background should be visible. Default: false */
  withBackground?: boolean;
};

export const Loader: React.FC<Props> = (props: Props) => {
  const {
    className,
    fullscreen = false,
    size = 'medium',
    visible = true,
    withBackground = false,
    ...progressSpinnerProps
  } = props;

  const strokeWidth = size === 'small' ? '6px' : undefined;

  return (
    <div
      className={cx(classes.root, className, {
        [classes.fullscreen]: fullscreen,
        [classes.visible]: visible,
        [classes.withBackground]: withBackground,
        [classes[`${size}Size`]]: size,
      })}
    >
      <ProgressSpinner {...progressSpinnerProps} strokeWidth={strokeWidth} />
    </div>
  );
};

export type { Props as LoaderProps };
