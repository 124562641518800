import * as R from 'ramda';
import { TooltipPositionType } from 'primereact/tooltip/tooltipoptions';

export function isOverflowed(element: HTMLElement): boolean {
  const { offsetWidth, offsetHeight, scrollWidth, scrollHeight } = element;
  return offsetWidth < scrollWidth || offsetHeight < scrollHeight;
}

export function getAvailablePosition(
  element: HTMLElement,
  minDistance = { horizontal: 150, vertical: 40 },
): TooltipPositionType | null {
  const clientRect = element.getBoundingClientRect();

  type PositionDistance = { position: TooltipPositionType; distance: number };
  const { savedPosition } = element.dataset;
  const positionDistances = R.sort<PositionDistance>(
    (a, b) => Number(b.position === savedPosition) - Number(a.position === savedPosition),
  )([
    {
      position: 'right',
      distance: window.innerWidth - clientRect.right,
    },
    {
      position: 'top',
      distance: clientRect.top,
    },
    {
      position: 'left',
      distance: clientRect.left,
    },
    {
      position: 'bottom',
      distance: window.innerHeight - clientRect.bottom,
    },
  ]);

  const availablePosition = R.find(({ position, distance }) => {
    const direction = ['left', 'right'].includes(position) ? 'horizontal' : 'vertical';
    return distance >= minDistance[direction];
  })(positionDistances)?.position;

  return availablePosition ?? null;
}
