import React from 'react';
import { observer } from 'mobx-react';

import { IAccountProperties } from 'types/account';
import { useStores } from 'core';
import { Input, LocalizedMessage } from 'shared/components/other';

import classes from 'routes/Account/Account.module.scss';

export const AccountField: React.FC<IAccountProperties> = observer(
  ({ propertyName, propertyDescription, propertyType }: IAccountProperties) => {
    const { accountStore } = useStores();
    const { mapFieldToValue, mapFieldToErrors } = accountStore;
    const { view } = accountStore.childrenStores;
    const value = mapFieldToValue[propertyName];
    const errors = mapFieldToErrors[propertyName] || [];

    if (propertyName === 'userStatus') {
      return null;
    }

    return (
      <div className={classes.Field} key={propertyName}>
        <label>
          <span className={classes.Label}>{propertyDescription}</span>
          <div className={classes.FieldInput}>
            <Input
              type={propertyType === 'STRING' ? 'text' : 'number'}
              name={propertyName}
              value={value}
              onChange={view.handleInputChange}
              onBlur={view.handleInputBlur}
              hasError={errors.length !== 0}
              data-test={`${propertyName.toLowerCase()}-input`}
              maxLength={1000}
            />
          </div>
        </label>
        {errors.map((id) => (
          <span className={classes.WarningMessage} key={id}>
            <LocalizedMessage id={id} />
          </span>
        ))}
      </div>
    );
  },
);
