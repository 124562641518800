import decode from 'jwt-decode';
import { KeyCloakResponse, BaseLoginResponse, KeyCloakDecoded, BaseServerProfile } from 'types/profile';

function convertTokenRequest(response: KeyCloakResponse): BaseLoginResponse {
  return {
    ...decodeToken(response.access_token),
    access_token: response.access_token,
    refresh_token: response.refresh_token,
  };
}

function decodeToken(accessToken: string | null): BaseServerProfile {
  const decodedToken = getDecodedToken(accessToken);

  return {
    id: decodedToken?.sub ?? '',
    name: decodedToken?.name ?? '',
    email: decodedToken?.email ?? '',
    login: decodedToken?.preferred_username ?? '',
    projects: decodedToken?.projects ?? [],
    medias: decodedToken?.medias ?? [],
    roles: decodedToken?.realm_access?.roles ?? [],
  };
}

function getDecodedToken(accessToken: string | null): KeyCloakDecoded | null {
  if (!accessToken) {
    return null;
  }

  try {
    return decode(accessToken);
  } catch {
    return null;
  }
}

export { convertTokenRequest, decodeToken };
