const customEn = {
  // DateFilter
  Period: 'Period',

  // ColorButton
  ButtonColor: 'Button color',
  ChangeColor: 'Change color',
  NoColor: 'No color',
  SelectColor: 'Select color',

  // Toast
  ReportAnError: 'Report an error',

  // common
  Apply: 'Apply',
  Select: 'Select',
};

export { customEn as en };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type En = typeof customEn & { [K in string]: any };
