import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { Column, Table } from 'shared/components/common/misc/Table';
import { IFactFile, IFileStatus } from 'types/mediaplan';
import { FileS3Id } from 'types/file';
import { IOption } from 'types';
import { localize } from 'shared/components/other';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import { FileStatusSelect } from '../../../../../../../../../FileStatusSelect';
import classes from './FactFilesTable.module.scss';

type Props = {
  className?: string;
  data: IFactFile[];
  onDownload(fileS3Id: FileS3Id): void;
  onRemove(fileId: IFactFile['id']): void;
  onStatusChange(newStatus: IOption<IFileStatus>, fileId: number): void;
};

export const FactFilesTable: React.VFC<Props> = (props) => {
  const { className, data, onDownload, onRemove, onStatusChange } = props;

  const columns: Column[] = useMemo(
    () => [
      { field: 'fileName', header: localize('tv-mediaplan.fact-file.name') },
      {
        field: 'status',
        header: localize('tv-mediaplan.fact-file.status'),
        style: { width: '140px' },
        template: (rowData, options) => {
          return (
            <div data-test={`file-status-select-${rowData.id}`}>
              <FileStatusSelect
                id={rowData.id}
                onChange={(value) => onStatusChange(value, rowData.id)}
                value={rowData[options.field]}
              />
            </div>
          );
        },
      },
      { field: 'createUsername', header: localize('tv-mediaplan.fact-file.author') },
      {
        field: 'creationDate',
        header: localize('tv-mediaplan.fact-file.creation-date'),
        template: 'date',
        options: { hasTime: true },
      },
      {
        field: 'lastModifyDate',
        header: localize('tv-mediaplan.fact-file.modify-date'),
        template: 'date',
        options: { hasTime: true },
      },
      {
        template: (rowData) => {
          const handleDownload = () => onDownload(rowData.s3FileId);
          const handleRemove = () => onRemove(rowData.id);
          return (
            <div className={classes.actions}>
              <TextButton
                data-test={`download-file-${rowData.id}`}
                icon="pi pi-download"
                onClick={handleDownload}
                tooltip={localize('upload-statistics.actions.download-file')}
              />
              <TextButton
                data-test={`delete-file-${rowData.id}`}
                icon="pi pi-times"
                onClick={handleRemove}
                tooltip={localize('upload-statistics.actions.delete-file')}
              />
            </div>
          );
        },
      },
    ],
    [onDownload, onRemove, onStatusChange],
  );

  const handleCellEditComplete = useCallback(
    (e) => {
      if (R.equals(e.value, e.newValue)) return;
      if (e.fileId === 'status') {
        onStatusChange(e.newValue, e.newRowData.id);
      }
    },
    [onStatusChange],
  );

  return data.length ? (
    <Table
      className={cx(classes.root, className)}
      columns={columns}
      data={data}
      onCellEditComplete={handleCellEditComplete}
    />
  ) : null;
};

export type { Props as FactFilesTableProps };
