import { CustomOptions, PageData } from 'api/types';
import { AxiosResponse } from 'axios';
import { localizeMessage } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast/Toast';
import { IPagingServerResponse } from 'types';
import { ICampaign, IShortCampaign, ISavedCampaign, IYearPeriod, CampaignStatus, IServerFilters } from 'types/campaign';
import BaseApi from '../BaseApi';

class Campaigns extends BaseApi {
  list = ({ serverFilters, page = 0, pageSize = 100000 }: GetCampaignListData, config: CustomOptions = {}) =>
    this.actions.post<IPagingServerResponse<ICampaign[]>>({
      url: '/campaign/',
      queryParams: { page, pageSize },
      data: serverFilters,
      config,
    });

  nameList = ({ customerId, page = 0, pageSize = 100000 }: GetNameListData, config: CustomOptions = {}) =>
    this.actions.get<IPagingServerResponse<IShortCampaign[]>>({
      url: '/campaign/find-names',
      data: { customerId, page, pageSize },
      config,
    });

  get = ({ campaignId }: CampaignData, config: CustomOptions = {}) =>
    this.actions.post<ICampaign>({
      url: '/campaign/find-by-id',
      queryParams: { campaignId },
      config,
    });

  save = (data: ISavedCampaign, config: CustomOptions = {}) =>
    this.actions.post<ICampaign>({
      url: '/campaign/save',
      data,
      config,
    });

  remove = ({ campaignId }: CampaignData, config: CustomOptions = {}) =>
    this.actions.post<ICampaign>({
      url: '/campaign/remove',
      data: campaignId,
      config,
    });

  getYearPeriod = ({ customerId }: GetYearPeriodData, config: CustomOptions = {}) =>
    this.actions.get<IYearPeriod>({
      url: '/campaign/get-year-period',
      data: { customerId },
      config,
    });

  setFavorite = async ({ campaignId, campaignName, favorite }: SetFavoriteData, config: CustomOptions = {}) => {
    const response = await this.actions.put({
      url: '/campaign/set-favorite-value',
      queryParams: { campaignId, favorite },
      config,
    });
    if (!response.error) {
      const messageId = favorite
        ? 'campaigns.campaign-has-been-added-to-favorites'
        : 'campaigns.campaign-was-removed-from-favorites';
      toast.success(localizeMessage({ id: messageId }, { campaignName }));
    }
    return response;
  };

  downloadPivot = async ({ campaignId, fileName }: DownloadPivotData, config: CustomOptions = {}) => {
    const response = await this.actions.get<AxiosResponse<Blob>>({
      url: `/reports/web/excel_plan_fact_campaign/${campaignId}`,
      config: { responseType: 'blob', withErrorNotification: false, ...config },
      domainType: 'webApi',
    });

    this.downloadFile(response, fileName);
  };

  statuses = (config: CustomOptions = {}) =>
    this.actions.get<CampaignStatus[]>({
      url: '/campaign/statuses',
      config,
    });
}

type CampaignData = { campaignId: number };

type GetCampaignListData = {
  serverFilters: Partial<IServerFilters>;
  page: number;
  pageSize: number;
};

type GetNameListData = PageData & { customerId: number };

type GetYearPeriodData = { customerId: number };

type SetFavoriteData = {
  campaignId: number;
  campaignName: string;
  favorite: boolean;
};

type DownloadPivotData = {
  campaignId: number;
  fileName?: string;
};

export default Campaigns;
