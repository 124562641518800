import React, { useRef, useMemo } from 'react';
import cx from 'classnames';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { Column as PrimeColumn, ColumnProps } from 'primereact/column';
import { Column } from './Table.columns';
import { getEditor } from './Table.editors';
import { getTemplate } from './Table.templates';
import classes from './Table.module.scss';

type Props = {
  className?: string;
  columns: Column[];
  data: DataTableProps['value'];
  equalColumnWidths?: boolean;
  readOnly?: boolean;
} & Pick<ColumnProps, 'onCellEditComplete'>;

const cellClassName = () => classes.cell;

export const Table: React.VFC<Props> = React.memo((props) => {
  const { className, columns, data, equalColumnWidths, onCellEditComplete, readOnly = false } = props;
  const tableRef = useRef<DataTable>(null);
  const columnStyle = useMemo(() => (equalColumnWidths ? { width: `${100 / columns.length}%` } : undefined), [
    columns.length,
    equalColumnWidths,
  ]);

  return (
    <DataTable
      cellClassName={cellClassName}
      className={cx(classes.root, className)}
      editMode={readOnly ? 'readOnly' : undefined} // 'readOnly' - custom value to forward 'readOnly' value
      ref={tableRef}
      tableClassName={classes.table}
      value={data}
    >
      {columns.map((column, i) => {
        const { editor, params, template, ...restProps } = column;
        return (
          <PrimeColumn
            body={getTemplate(column.template)}
            editor={getEditor(editor, readOnly, tableRef)}
            key={column.field ?? `${i}`}
            onCellEditComplete={onCellEditComplete}
            style={columnStyle}
            {...restProps}
          />
        );
      })}
    </DataTable>
  );
});

export type { Props as TableProps };
