import React from 'react';
import { CurrentPageReport as Component, CurrentPageReportProps } from '../../components/CurrentPageReport';
import { usePaginator } from '../../Paginator.provider';

type Props = CurrentPageReportProps;

export const CurrentPageReport: React.VFC<Props> = (props) => {
  const { options } = usePaginator();
  return <Component {...options.currentPageReport} {...props} />;
};

export type { Props as CurrentPageReportProps };
