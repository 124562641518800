import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import { MAX_PLACEMENT_IDS_COUNT } from 'helpers/constants';
import { localize, PowerBIReport } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { ActiveTab } from 'types/programmatic';

import classes from './Statistics.module.scss';

const Statistics = observer(() => {
  const {
    excessStore,
    excessStore: { activeTab },
  } = useStores();
  const { csIdListForSelectedRows } = excessStore.childrenStores.statistics;

  if (!csIdListForSelectedRows || activeTab !== ActiveTab.STATISTICS) {
    return null;
  }

  if (csIdListForSelectedRows.length > MAX_PLACEMENT_IDS_COUNT) {
    toast.error(localize('programmatic.warnings.too-many-placements-selected'));
  }

  return (
    <PowerBIReport
      pageName="PRG_EXCESS_CONTROL"
      advertiserId={null}
      csIds={csIdListForSelectedRows}
      className={classes.powerBi}
    />
  );
});

export { Statistics };
