import { BadgeSeverityType } from 'primereact/badge';
import { IOption } from 'types';

export type LogItem = {
  timeCreate: string;
  cabinetName: string;
  accountName: string;
  clientName: string;
  campaignName: string;
  adName: string;
  userName: string;
  status: Status;
  errorMessage: string;
};

export type LogFilters = {
  cabinetId?: number[];
  accountName?: string;
  clientName?: string;
  campaignName?: string;
  adName?: string;
  user?: string;
  logStatus?: Status;
  errorMessage?: string;
};

export type LogPageSettings = {
  number: number;
  size: number;
};

export enum Status {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export type StatusOption = IOption<Status> & { badge: BadgeSeverityType };
