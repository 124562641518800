import {
  AccountSystemStatus,
  ErrorLocaleId,
  IAccountProperties,
  ICabinet,
  ICustomPropertiesHolder,
  IErrors,
  IGetTokenOptions,
} from 'types/account';
import handleError from 'helpers/handleError';
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { EmptyObject, IOption } from 'types';
import { openPopup } from 'helpers/utils';
import { BaseStore } from 'shared/stores';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { AccountViewStore } from './AccountView.store';

type ChildrenStores = {
  view: AccountViewStore;
};

export class AccountStore extends BaseStore<EmptyObject, ChildrenStores> {
  @observable
  advertiserId: number | null = null;

  @observable
  isEditing = false;

  @observable
  isActivatedToken = false;

  @observable
  mapFieldToValue: Record<string, string | undefined> = {};

  @observable
  mapFieldToErrors: IErrors = {};

  @observable
  loadedFields: IAccountProperties[] = [];

  @observable
  accountId?: number;

  @observable
  cabinetId: number | null = null;

  @observable
  cabinets: ICabinet[] = [];

  @observable
  cabinetOptions: IOption[] = [];

  @computed
  get selectedCabinet(): ICabinet | undefined {
    return this.cabinets.find(({ id }) => id === this.cabinetId);
  }

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      view: new AccountViewStore({ accountStore: this }),
    };

    reaction(
      () => this.cabinetId,
      (cabinetId: number | null) => {
        if (cabinetId !== null) {
          this.loadData();
        }
      },
    );
  }

  submit = async (): Promise<boolean> => {
    const { advertiserId } = this;
    if (advertiserId === null) {
      return false;
    }

    const mapFieldToErrors = this.validate();
    const hasErrors = Object.values(mapFieldToErrors).some((errors) => errors && errors.length);

    if (hasErrors) {
      runInAction(() => {
        this.mapFieldToErrors = mapFieldToErrors;
      });
    } else {
      try {
        if (this.accountId) {
          await this.updateAccount(this.accountId);
        } else {
          await this.createAccount(advertiserId);
        }

        return true;
      } catch {
        return false;
      }
    }

    return false;
  };

  handleTokenWindowClose = async ({
    accountId,
    cabinetId,
    isEditing = false,
  }: Omit<IGetTokenOptions, 'url'>): Promise<void> => {
    if (!isEditing) {
      toast.success(localize('account.notify.account-added'));
      return;
    }

    const response = await this.services.api.accounts.loadAccount(
      { accountId, cabinetId },
      { withErrorNotification: false },
    );
    if (response.error || response.data.systemStatus === AccountSystemStatus.NOT_READY) {
      const error = new Error(localize('errors.account.token-not-received'));
      handleError(error);
    } else {
      toast.success(localize('account.notify.get-token-success'));
    }
  };

  private updateAccount = async (accountId: number) => {
    const { mapFieldToValue, cabinetId } = this;
    if (!cabinetId) return;

    const response = await this.services.api.accounts.updateAccount({ accountId, cabinetId, data: mapFieldToValue });
    if (response.error) {
      throw response.error;
    }

    toast.success(localize('account.notify.account-updated'));
  };

  private createAccount = async (advertiserId: number): Promise<void> => {
    const { mapFieldToValue, cabinetId } = this;
    if (!cabinetId) return;

    // create account
    const response = await this.services.api.accounts.createAccount({
      cabinetId,
      customerId: advertiserId,
      data: mapFieldToValue,
    });
    if (response.error) {
      throw response.error;
    }

    const { tokenLink, id } = response.data;
    // open url to get the token
    await this.getTokenByLink({
      cabinetId,
      accountId: id,
      url: tokenLink,
    });
  };

  getToken = async (accountId: number): Promise<void> => {
    const { cabinetId } = this;
    if (!cabinetId) return;

    const response = await this.services.api.accounts.getTokenLink({ accountId, cabinetId });
    if (response.error) return;

    await this.getTokenByLink({
      accountId,
      cabinetId,
      isEditing: true,
      url: response.data.token_link,
    });
  };

  validateField = (fieldName: string): ErrorLocaleId[] => {
    const { mapFieldToValue, loadedFields } = this;
    const field = loadedFields.find((f) => f.propertyName === fieldName);
    const value = mapFieldToValue[fieldName] || '';
    const errors: ErrorLocaleId[] = [];

    if (!field) return [];

    if (field.required && value.length === 0) {
      errors.push('account.warning.not-empty');
    }

    return errors;
  };

  validate = (): IErrors => {
    const { loadedFields } = this;

    return loadedFields.reduce(
      (acc, { propertyName }) => ({
        ...acc,
        [propertyName]: this.validateField(propertyName),
      }),
      {},
    );
  };

  // Clean store, so next mount it is pure
  cleanup = (): void => {
    runInAction(() => {
      this.advertiserId = null;
      this.cabinetId = null;
      this.accountId = undefined;
      this.cabinets = [];
      this.isEditing = false;
      this.loadedFields = [];
      this.mapFieldToErrors = {};
      this.mapFieldToValue = {};
      this.cabinetOptions = [];
      this.isActivatedToken = false;
    });
  };

  static cabinetToOption = (cab: ICabinet): IOption => ({
    label: cab.displayName,
    value: cab.id,
  });

  static convertCustomProperties = (
    data: ICustomPropertiesHolder,
    fields: IAccountProperties[],
  ): Record<string, string | undefined> => {
    const customProperties: Record<string, string | undefined> = {};
    fields.forEach(({ propertyName }) => {
      if (propertyName) {
        customProperties[propertyName] = data[propertyName] || data.customProperties?.[propertyName] || undefined;
      }
    });

    return customProperties;
  };

  loadData = async (): Promise<void> => {
    if (!this.cabinets.length) await this.loadCabinets();

    if (this.accountId) {
      await this.loadExistingAccount();
    } else {
      await this.loadNewAccountProperties();
    }
  };

  private loadNewAccountProperties = async () => {
    if (!this.cabinetId) {
      if (!this.cabinetOptions.length) return;
      this.cabinetId = this.cabinetOptions[0].value;
    }

    const response = await this.services.api.accounts.getProperties({ cabinetId: this.cabinetId });
    if (response.error) return;

    runInAction(() => {
      this.loadedFields = response.data;
    });
  };

  private loadExistingAccount = async () => {
    const cabinetId = Number(this.cabinetId);
    const accountId = Number(this.accountId);
    const [accountData, loadedFields] = await Promise.all([
      this.services.api.accounts.loadAccount({ accountId, cabinetId }),
      this.services.api.accounts.getProperties({ cabinetId }),
    ]);
    if (accountData.error || loadedFields.error) {
      return;
    }

    const mapFieldToValue: Record<string, string | undefined> = AccountStore.convertCustomProperties(
      accountData.data,
      loadedFields.data,
    );

    runInAction(() => {
      this.loadedFields = loadedFields.data;
      this.mapFieldToValue = mapFieldToValue;
      this.cabinetId = cabinetId;
      this.isEditing = true;
      this.isActivatedToken = accountData.data.systemStatus === AccountSystemStatus.ACTIVE;
    });
  };

  private loadCabinets = async () => {
    const response = await this.services.api.accounts.cabinetsList();
    if (response.error) return;

    runInAction(() => {
      this.cabinets = response.data;
      this.cabinetOptions = response.data
        .filter((cabinet) => cabinet.canUserCreateAccount)
        .map(AccountStore.cabinetToOption);
    });
  };

  private getTokenByLink = ({ url, ...options }: IGetTokenOptions): Promise<void> =>
    openPopup(url, () => this.handleTokenWindowClose(options));
}
