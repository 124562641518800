export type FileS3Id = string | null;

export type FileS3IdData = {
  fileS3Id: FileS3Id;
};

export type FileType =
  | 'MEDIAPLAN_TV'
  | 'MEDIAFACT_TV'
  | 'MEDIAFACT_TV_TEMPLATES'
  | 'MEDIAPLAN_WEB'
  | 'MEDIAFACT_WEB'
  | 'MEDIAFACT_WEB_TEMPLATES'
  | 'ACCOUNT_COPYING';

export enum ExcelFormats {
  XLS = 'xls',
  XLSX = 'xlsx',
  XLSM = 'xlsm',
}

export enum ImageFormats {
  PNG = 'png',
  GIF = 'gif',
  JPG = 'jpg',
  JPEG = 'jpeg',
  TIF = 'tif',
  BMP = 'bmp',
}

export enum VideoFormats {
  AVI = 'avi',
  MP4 = 'mp4',
  '3GP' = '3gp',
  MPEG = 'mpeg',
  MOV = 'mov',
  FLV = 'flv',
  F4V = 'f4v',
  WMV = 'wmv',
  MKV = 'mkv',
  WEBM = 'webm',
  VOB = 'vob',
  RM = 'rm',
  RMVB = 'rmvb',
  M4V = 'm4v',
  MPG = 'mpg',
  OGV = 'ogv',
  TS = 'ts',
  M2TS = 'm2ts',
  MTS = 'mts',
}
