import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import { Button } from 'shared/components/common/buttons/Button';
import { AdFoxLog } from '../../../../../AdFoxLog';

import classes from './Actions.module.scss';

export const Actions = observer(() => {
  const {
    adFoxCampaignsStore: { isTableChanged, resetTableContent, saveRows, refreshRows },
    sidebarStore,
  } = useStores();

  const handleLogButtonClick = () => {
    sidebarStore.show({ content: <AdFoxLog /> });
  };

  return (
    <div className={classes.root}>
      <Button
        className={cx('p-button-secondary', { [classes.hidden]: !isTableChanged })}
        label="Отменить"
        onClick={resetTableContent}
      />
      <Button
        className={cx('p-button-secondary', { [classes.hidden]: !isTableChanged })}
        label="Применить"
        onClick={saveRows}
      />
      <Button className="p-button-secondary" icon="pi pi-sync" label="Обновить" onClick={refreshRows} />
      <Button className="p-button-secondary" icon="pi pi-list" onClick={handleLogButtonClick} label="Лог" />
    </div>
  );
});
