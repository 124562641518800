import handleError from 'helpers/handleError';
import { ErrorInterceptor } from '../../types';

export const showNotification: ErrorInterceptor = ({ config, error }) => {
  const { withErrorNotification = true } = config;
  if (withErrorNotification) {
    handleError(error);
  }

  return { data: null, error };
};
