import { AxiosResponse } from 'axios';
import { Response } from 'api/types';
import { IError } from 'types';

export function convertResponse<Data>(response: AxiosResponse<Data>, error?: IError): Response<Data> {
  if (error) {
    return { data: null, error };
  }
  return {
    data: (response.config.responseType === 'blob' ? response : response.data) as Data,
    error: null,
  };
}
