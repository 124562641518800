import React, { useCallback, useEffect, useRef } from 'react';
import { usePrevious, useUpdateEffect } from 'ahooks';
import { observer } from 'mobx-react';
import { useStores } from 'core';
import { DataGrid, DataGridFilters, GridRef } from 'shared/components/other';
import { columns } from './ExcessTable.columns';
import { defaultProps } from './ExcessTable.defaultProps';
import { confirmFilters, convertGridFiltersToApiFilters } from '../../helpers';

export const ExcessTable = observer(() => {
  const {
    excessStore: {
      toggleRowExpanding,
      toggleRowSelection,
      setPage,
      data,
      page,
      selectedRowKeys,
      hasSelectedRows,
      isLoading,
      isLoadedAll,
      campaignFilters,
      setCampaignFilters,
      campaignFiltersKeys,
    },
  } = useStores();

  const handleLoadMore = useCallback(() => setPage(page + 1), [page, setPage]);

  const handleDataGridFilterChange = useCallback(
    (dataGridFilters: DataGridFilters) => {
      setCampaignFilters(convertGridFiltersToApiFilters(dataGridFilters));
    },
    [setCampaignFilters],
  );

  const applyFilters = (onApply: () => void, target: HTMLElement) => {
    if (hasSelectedRows) {
      confirmFilters({ target, onAccept: onApply });
    } else {
      onApply();
    }
  };

  const prevFilterKeys = Object.keys(usePrevious(campaignFilters) ?? {});

  const gridRef = useRef<GridRef['current']>(null);

  useEffect(() => {
    if (!gridRef.current) {
      return;
    }
    const gridFiltersKeys = Object.keys(gridRef.current.filters);
    const wereFiltersClear =
      gridFiltersKeys.some((x) => prevFilterKeys.includes(x)) &&
      gridFiltersKeys.every((x) => !campaignFiltersKeys.includes(x));
    if (wereFiltersClear) {
      gridRef.current.clearFilters();
    }
  }, [campaignFiltersKeys, prevFilterKeys]);

  useUpdateEffect(() => {
    if (!data.length) return;
    gridRef.current?.nativeGridRef.current?.scrollToRow?.(0);
  }, [campaignFilters]);

  return (
    <DataGrid
      {...defaultProps}
      columns={columns}
      rows={data}
      selectedRowKeys={selectedRowKeys}
      isLoading={isLoading}
      isLoadedAll={isLoadedAll}
      loadMore={handleLoadMore}
      toggleRowSelection={toggleRowSelection}
      toggleRowExpanding={toggleRowExpanding}
      onFilterChange={handleDataGridFilterChange}
      applyFilters={applyFilters}
      gridRef={gridRef}
      autoScrollTop={false}
    />
  );
});
