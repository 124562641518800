import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { formatDate } from 'helpers/utils';
import { localize } from 'shared/components/other';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import { FileActions, IFile, IFileStatus, WebMediaplanActions } from 'types/mediaplan';
import { FileS3Id } from 'types/file';
import { IOption } from 'types';
import { FileStatusSelect } from '../../../../../../../../../FileStatusSelect';
import { LinkToActionWithWebMediaplan } from './components/LinkToActionWithWebMediaplan';
import classes from './WebMediaplanFile.module.scss';

type Props = {
  accessibleActions: WebMediaplanActions[];
  className?: string;
  file: IFile;
  mediaplanId: number;
} & Handlers;

type Handlers = {
  beforeLinkToActionOpen(): void;
  onDownload(fileS3Id: FileS3Id, fileName: string): void;
  onRemove(versionId: number): void;
  onStatusChange(status: IOption<IFileStatus>, mediaplanVersionId: number): void;
};

export const WebMediaplanFile: React.VFC<Props> = (props) => {
  const {
    accessibleActions,
    beforeLinkToActionOpen,
    className,
    file,
    mediaplanId,
    onDownload,
    onRemove,
    onStatusChange,
  } = props;

  const groupedActions = useMemo(
    () =>
      R.pipe(
        R.groupBy((x: WebMediaplanActions) =>
          [
            WebMediaplanActions.EXTRACT_DATA,
            WebMediaplanActions.LINK_CABINETS,
            WebMediaplanActions.EXPORT_CABINET,
          ].includes(x)
            ? 'true'
            : 'false',
        ),
        R.values,
      )(accessibleActions),
    [accessibleActions],
  );

  const hasAction = (action: FileActions | WebMediaplanActions) => !!file.actions.find((x) => x.key === action);

  const handleFileStatusChange = useCallback(
    (value: IOption<IFileStatus>) => onStatusChange(value, file.mediaplanVersionId),
    [file.mediaplanVersionId, onStatusChange],
  );

  return (
    <tbody className={cx(classes.root, { [classes.isCurrent]: file.status === IFileStatus.CURRENT })}>
      <tr className={cx(classes.row, className)}>
        <td className={classes.nameCell}>
          <div data-global-tooltip data-pr-tooltip={file.fileName}>
            {file.fileName}
          </div>
          {file.status !== IFileStatus.CURRENT && (
            <div className={classes.links}>
              {accessibleActions.filter(hasAction).map((action) => (
                <LinkToActionWithWebMediaplan
                  action={action}
                  beforeLinkOpen={beforeLinkToActionOpen}
                  fileId={file.fileId}
                  key={`${file.fileId}_${action}`}
                  mediaplanId={mediaplanId}
                  mediaplanVersionId={file.mediaplanVersionId}
                />
              ))}
            </div>
          )}
        </td>
        <td className={classes.statusCell}>
          <div data-test={`file-status-select-${file.fileId}`}>
            <FileStatusSelect
              className={classes.statusSelect}
              id={file.fileId}
              onChange={handleFileStatusChange}
              value={file.status}
            />
          </div>
        </td>
        <td>{formatDate({ date: file.dateCreated, isFullYear: true, hasTime: true })}</td>
        <td>
          <div className={classes.fileActions}>
            <TextButton
              className={classes.fileAction}
              data-test={`download-file-link-${file.fileId}`}
              disabled={!hasAction(FileActions.DOWNLOAD)}
              icon="icon-download"
              onClick={() => onDownload(file.fileS3Id, file.fileName)}
              tooltip={localize('campaign-view.mediaplan.actions.download-version')}
            />
            <TextButton
              className={classes.fileAction}
              data-test={`remove-file-link-${file.fileId}`}
              disabled={!hasAction(FileActions.DELETE)}
              icon="icon-delete"
              onClick={() => onRemove(file.mediaplanVersionId)}
              tooltip={localize('campaign-view.mediaplan.actions.delete-version')}
            />
          </div>
        </td>
      </tr>

      {file.status === IFileStatus.CURRENT && (
        <tr className={classes.row}>
          <td colSpan={4}>
            <div className={cx(classes.links, classes.wrapped)}>
              {groupedActions.map((actionsGroup, i) => (
                <div className={classes.linksGroup} key={`${file.fileId}_${i}`}>
                  {actionsGroup.filter(hasAction).map((action) => (
                    <LinkToActionWithWebMediaplan
                      action={action}
                      beforeLinkOpen={beforeLinkToActionOpen}
                      fileId={file.fileId}
                      key={`${file.fileId}_${action}`}
                      mediaplanId={mediaplanId}
                      mediaplanVersionId={file.mediaplanVersionId}
                    />
                  ))}
                </div>
              ))}
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export type { Props as WebMediaplanFileProps, Handlers as WebMediaplanFileHandlers };
