import React, { Component, FC } from 'react';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import throttle from 'lodash/throttle';
import classes from './Scrollbar.module.scss';

interface IProps {
  className?: string;
  viewClassName?: string;
  autoWidth?: true;
  autoHeightMax?: number;
  viewProps?: Record<string, unknown>;
  hideHorizontalScrollbar?: true;
  hideVerticalScrollbar?: true;
  hasHeader?: boolean;
  hasMainMenuTheme?: boolean;
  isScrollCampaign?: boolean;
  onScroll?: () => void;
  onScrollStart?: () => void;
  onWheel?: (event: React.WheelEvent<Scrollbars>) => void;
  enableDragging?: boolean;
}

export interface IScrollbars extends Scrollbars {
  container: HTMLElement | null;
  view: HTMLElement | null;
}

type TrackRenderProps = Record<string, unknown> & { style: React.CSSProperties };

const FPS60_SINGLE_FRAME_THROTTLE = 16;

class Scrollbar extends Component<IProps> {
  scrollbars: IScrollbars | null = null;

  private content: HTMLDivElement | null = null;

  isDragScrolling = false;

  pos?: {
    // The current scroll
    left: number;
    top: number;
    // Get the current mouse position
    x: number;
    y: number;
  } = undefined;

  componentDidMount() {
    this.recalculateSize();
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  componentDidUpdate() {
    this.recalculateSize();
  }

  private onMouseDown = (e: React.MouseEvent<HTMLDivElement>): void => {
    const ele = this.getView();
    if (!ele) return;

    this.isDragScrolling = true;

    ele.classList.add(classes.DraggableViewDragged);

    this.pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
  };

  private throttledMouseMove = throttle((e: MouseEvent): void => {
    if (this.isDragScrolling) {
      if (!this.pos) return;

      const ele = this.getView();
      if (!ele) return;
      const dx = e.clientX - this.pos.x;
      const dy = e.clientY - this.pos.y;

      // Scroll the element
      ele.scrollTop = this.pos.top - dy;
      ele.scrollLeft = this.pos.left - dx;
    }
  }, FPS60_SINGLE_FRAME_THROTTLE);

  private onMouseMove = (e: MouseEvent) => {
    this.throttledMouseMove(e);
  };

  private onMouseUp = (): void => {
    this.isDragScrolling = false;
    const ele = this.getView();
    if (!ele) return;
    ele.classList.remove(classes.DraggableViewDragged);
  };

  setScrollbarsRef = (ref: IScrollbars): void => {
    this.scrollbars = ref;
  };

  setContentRef = (ref: HTMLDivElement): void => {
    this.content = ref;
  };

  getContainer(): HTMLElement | null {
    if (!this.scrollbars) {
      return null;
    }

    return this.scrollbars.container;
  }

  getView(): HTMLElement | null {
    if (!this.scrollbars) {
      return null;
    }

    return this.scrollbars.view;
  }

  getScrollLeft(): number {
    if (!this.scrollbars) {
      return 0;
    }

    return this.scrollbars.getScrollLeft();
  }

  getScrollTop(): number {
    if (!this.scrollbars) {
      return 0;
    }

    return this.scrollbars.getScrollTop();
  }

  scrollLeft(left: number): void {
    if (!this.scrollbars) {
      return;
    }

    this.scrollbars.scrollLeft(left);
  }

  scrollTop(top: number): void {
    if (!this.scrollbars) {
      return;
    }

    this.scrollbars.scrollTop(top);
  }

  recalculateSize(): void {
    const { autoWidth, autoHeightMax } = this.props;
    if (this.scrollbars && this.scrollbars.container && this.content) {
      if (autoWidth) {
        this.scrollbars.container.style.width = `${this.content.offsetWidth}px`;
      }
      if (autoHeightMax) {
        this.scrollbars.container.style.height = `${Math.min(this.content.offsetHeight, autoHeightMax)}px`;
      }
    }
  }

  renderView: FC<
    Record<string, unknown> & {
      style: React.CSSProperties;
      className?: string;
    }
  > = (
    props: Record<string, unknown> & {
      style: React.CSSProperties;
      className?: string;
    },
  ) => {
    const { viewProps, viewClassName } = this.props;
    const { style, className, ...otherProps } = props;

    const viewStyle = {
      ...style,
    };

    const dragProps = this.props.enableDragging
      ? {
          onMouseDown: this.onMouseDown,
        }
      : {};

    return (
      <div
        {...otherProps}
        className={cx(className, viewClassName, this.props.enableDragging && classes.DraggableView)}
        style={viewStyle}
        {...viewProps}
        {...dragProps}
      />
    );
  };

  renderMainMenuThumb: FC<TrackRenderProps> = ({ style, ...props }: TrackRenderProps) => {
    const customStyle = {
      cursor: 'pointer',
      borderRadius: 'inherit',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    };

    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderMainMenuTrack: FC<TrackRenderProps> = ({ style, ...props }: TrackRenderProps) => {
    const customStyle = {
      width: '8px',
      right: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '6px',
    };

    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderCampaignsTrack: FC = () => (
    <div
      style={{
        position: 'fixed',
        top: '194px',
        right: '20px',
        bottom: '30px',
        width: '6px',
        borderRadius: '6px',
      }}
    />
  );

  renderCommonTrack: FC<TrackRenderProps> = (props: TrackRenderProps) => (
    <div
      {...props}
      className={cx(classes.Track, {
        [classes.TrackWithHeaderOffset]: Boolean(this.props.hasHeader),
      })}
    />
  );

  render() {
    const {
      children,
      className,
      autoWidth,
      hideHorizontalScrollbar,
      hideVerticalScrollbar,
      hasMainMenuTheme,
      isScrollCampaign,
      onScroll,
      onScrollStart,
      onWheel,
    } = this.props;

    return (
      <Scrollbars
        ref={this.setScrollbarsRef}
        hideTracksWhenNotNeeded
        renderTrackHorizontal={hideHorizontalScrollbar ? () => <div className={classes.TrackHidden} /> : undefined}
        renderTrackVertical={
          hideVerticalScrollbar
            ? () => <div className={classes.TrackHidden} />
            : hasMainMenuTheme
            ? this.renderMainMenuTrack
            : isScrollCampaign
            ? this.renderCampaignsTrack
            : this.renderCommonTrack
        }
        renderThumbVertical={hasMainMenuTheme ? this.renderMainMenuThumb : undefined}
        renderView={this.renderView}
        onScroll={onScroll}
        onScrollStart={onScrollStart}
        onWheel={onWheel}
      >
        <div ref={this.setContentRef} className={cx({ [classes.ContentAutoWidth]: autoWidth }, className)}>
          {children}
        </div>
      </Scrollbars>
    );
  }
}

export default Scrollbar;
