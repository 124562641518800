import React from 'react';
import cx from 'classnames';

import { LocalizedMessage } from 'shared/components/other';
import { ISubbrand } from 'types/subbrand';

import classes from './SubbrandsList.module.scss';

type Props = {
  subbrands: ISubbrand[];
  onEditSubbrand(subbrand: ISubbrand): void;
  onDeleteSubbrand(subbrand: ISubbrand): void;
};

export const SubbrandsList: React.FC<Props> = (props) => {
  const { subbrands, onEditSubbrand, onDeleteSubbrand } = props;

  return (
    <div className={classes.Subbrands}>
      <h2 className={classes.SubTitle}>
        <LocalizedMessage id="administration.brands.edit-subbrands" />
      </h2>
      <ul className={classes.SubbrandsList}>
        {subbrands.length ? (
          subbrands.map((subbrand, index) => (
            <li className={classes.Subbrand} key={index}>
              <div className={classes.RowNumber}>{index + 1}</div>
              <div className={classes.SubbrandName}>{subbrand.name}</div>
              <div className={classes.Icons}>
                <i
                  className={cx(classes.Icon, 'icon icon-edit')}
                  onClick={() => onEditSubbrand(subbrand)}
                  data-test={`edit-subbrand-${subbrand.id}`}
                />
                <i
                  className={cx(classes.Icon, classes.IconDanger, 'icon icon-cross')}
                  onClick={() => onDeleteSubbrand(subbrand)}
                  data-test={`delete-subbrand-${subbrand.id}`}
                />
              </div>
            </li>
          ))
        ) : (
          <LocalizedMessage id="administration.brands.subbrands-not-found" />
        )}
      </ul>
    </div>
  );
};
