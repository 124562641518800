import React from 'react';
import cx from 'classnames';
import * as A from 'types/account';
import { localize } from 'shared/components/other';
import classes from './CabinetStatus.module.scss';

const statuses = {
  RED: { icon: 'pi icon-error', color: 'var(--color-red)' },
  GREEN: { icon: 'pi icon-success', color: 'var(--color-green)' },
  YELLOW: { icon: 'pi pi icon-warning', color: 'var(--color-yellow)' },
  UNKNOWN: { icon: 'pi icon-success', color: 'var(--color-text-grey)' },
};

type Props = {
  value: `${A.CabinetStatus}` | null;
};

export const CabinetStatus: React.VFC<Props> = (props) => {
  const { value } = props;
  const status = value ?? 'UNKNOWN';

  return (
    <i
      className={cx(classes.root, statuses[status].icon)}
      style={{ color: statuses[status].color }}
      data-global-tooltip
      data-pr-tooltip={localize('site.title.cabinet-status')}
      data-pr-adjustable={false}
      data-pr-mouse-track-static={false}
      data-pr-my="right top"
      data-pr-at="center bottom"
    />
  );
};
