/* eslint-disable @typescript-eslint/lines-between-class-members */
import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

import Config from 'config';
import { sharedStores } from 'shared/stores';
import { IError } from 'types';

import { convertResponse, isError, isNetworkError, isErrorWithUserMessage } from './helpers';
import { convertError } from './helpers/converters';
import { RequestConfig, ErrorInterceptor, HttpActionParams, DomainType, Response, Interceptors } from './types';

class HttpActions {
  private _axiosInstance: AxiosInstance;
  private _axiosConfig: RequestConfig;
  private _baseUrl = Config.api.host;
  private _keycloackUrl = Config.api.authHost;
  private _webUrl = Config.api.cswUrl;
  private _realm = 'ADV';
  private _clientId = 'clientspace';
  private _interceptors: Interceptors = {};

  get realm(): string {
    return this._realm;
  }

  get clientId(): string {
    return this._clientId;
  }

  set interceptors(interceptors: Interceptors) {
    this._interceptors = interceptors;
  }

  constructor() {
    if (!this._baseUrl) {
      console.error('Api not configured!');
    }

    this._axiosConfig = {
      validateStatus: (status: number) => status < 526,
      timeout: 120_000,
      withCredentials: false,
      reRequest: true,
      headers: {
        Realm: this._realm,
        'Content-Type': 'application/json',
      },
    };

    this._axiosInstance = axios.create(this._axiosConfig);

    this._axiosInstance.interceptors.request.use(
      (config: RequestConfig): RequestConfig => {
        if (config.withLoader !== false) {
          sharedStores.globalLoaderStore.startLoading();
        }

        return config;
      },
    );

    this._axiosInstance.interceptors.response.use(
      async <Data>(response: CustomAxiosResponse): Promise<Response<Data>> => {
        const { config } = response;
        if (config.withLoader !== false) {
          sharedStores.globalLoaderStore.stopLoading();
        }

        if (isError(response)) {
          const error = await convertError(response);
          const interceptor = this.getErrorInterceptor(error);
          if (interceptor) {
            return interceptor({ config, error, instance: this._axiosInstance }) as Promise<Response<Data>>;
          }
          return convertResponse(response, error);
        }

        return convertResponse(response);
      },
      (error: CustomAxiosError) => {
        const { config } = error;
        if (config?.withLoader !== false) {
          sharedStores.globalLoaderStore.stopLoading();
        }

        if (isNetworkError(error) && this._interceptors.retryRequest) {
          return this._interceptors.retryRequest({ config, error: error as IError, instance: this._axiosInstance });
        }

        return { data: null, error };
      },
    );
  }

  get<T>(params: HttpActionParams): Promise<Response<T>> {
    const { url, data, domainType, config } = params;
    const axiosInstance = this.getAxiosInstance(domainType, config?.withToken);

    return axiosInstance.get(url, { ...config, params: data } as AxiosRequestConfig);
  }

  post<T>(params: HttpActionParams): Promise<Response<T>> {
    const { url, data = {}, queryParams, domainType, config } = params;
    const axiosInstance = this.getAxiosInstance(domainType, config?.withToken);

    return axiosInstance.post(url, data, { ...config, params: queryParams } as AxiosRequestConfig);
  }

  delete<T>(params: HttpActionParams): Promise<Response<T>> {
    const { url, data = {}, queryParams, domainType, config } = params;
    const axiosInstance = this.getAxiosInstance(domainType, config?.withToken);

    return axiosInstance.delete(url, {
      ...config,
      data,
      params: queryParams,
    } as AxiosRequestConfig);
  }

  put<T>(params: HttpActionParams): Promise<Response<T>> {
    const { url, data = {}, queryParams, domainType, config } = params;
    const axiosInstance = this.getAxiosInstance(domainType, config?.withToken);

    return axiosInstance.put(url, data, { ...config, params: queryParams } as AxiosRequestConfig);
  }

  patch<T>(params: HttpActionParams): Promise<Response<T>> {
    const { url, data = {}, queryParams, domainType, config } = params;
    const axiosInstance = this.getAxiosInstance(domainType, config?.withToken);

    return axiosInstance.patch(url, data, { ...config, params: queryParams } as AxiosRequestConfig);
  }

  // eslint-disable-next-line consistent-return
  private getErrorInterceptor(error: IError): ErrorInterceptor | undefined {
    const statusCode = Number(error.statusCode);
    if (statusCode === 401) {
      return this._interceptors.reauthorize;
    }
    if (statusCode >= 400 && statusCode <= 499) {
      return this._interceptors.showNotification;
    }
    if (statusCode >= 500 && statusCode <= 599) {
      return isErrorWithUserMessage(error) ? this._interceptors.showNotification : this._interceptors.retryRequest;
    }
  }

  private getAxiosInstance(domain: DomainType = 'baseApi', withToken = true) {
    const instance = this._axiosInstance;
    instance.defaults.baseURL = this.getBaseUrl(domain);

    const accessToken = sharedStores.authStore.getAccessToken();
    if (withToken && accessToken) {
      instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      delete instance.defaults.headers.common.Authorization;
    }

    return instance;
  }

  private getBaseUrl(domain: DomainType) {
    const urls: Record<DomainType, string> = {
      baseApi: this._baseUrl,
      keyсloackApi: this._keycloackUrl,
      webApi: this._webUrl,
    };
    return urls[domain];
  }
}

type CustomAxiosResponse = Omit<AxiosResponse, 'config'> & { config: RequestConfig };

type CustomAxiosError = Omit<AxiosError, 'config'> & { config: RequestConfig };

export default HttpActions;
