import React from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import { localize } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { toast } from 'shared/components/common/misc/Toast';
import { AdFoxLog } from '../../../../../AdFoxLog';

import classes from './Actions.module.scss';

export const Actions = observer(() => {
  const {
    adFoxBannersStore: { isTableChanged, resetTableContent, saveRows, refreshRows },
    sidebarStore,
  } = useStores();

  const params = useParams<{ campaignId: string }>();

  const handleLogButtonClick = () => {
    const campaignId = Number(params.campaignId);
    if (!params.campaignId || Number.isNaN(campaignId)) {
      toast.error(localize('adfox.wrong-campaign-id'));
      return;
    }
    sidebarStore.show({ content: <AdFoxLog campaignId={campaignId} /> });
  };

  return (
    <div className={classes.root}>
      <Button
        className={cx('p-button-secondary', { [classes.hidden]: !isTableChanged })}
        label="Отменить"
        onClick={resetTableContent}
      />
      <Button
        className={cx('p-button-secondary', { [classes.hidden]: !isTableChanged })}
        label="Применить"
        onClick={saveRows}
      />
      <Button className="p-button-secondary" icon="pi pi-sync" label="Обновить" onClick={refreshRows} />
      <Button className="p-button-secondary" icon="pi pi-list" onClick={handleLogButtonClick} label="Лог" />
    </div>
  );
});
