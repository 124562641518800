import { compose, filter, map, negate, pipe } from 'lodash/fp';
import { CustomField, Field, FieldDescription, IFieldForSave, ISavedServerField } from 'types/powerBI';
import { convertCustomFieldToServer, convertFieldToServer, convertServerField, createInitialField } from './fields';

type FieldType = 'internal' | 'client';

const isCustomUrlField = (field: ISavedServerField): boolean =>
  field.biUrlPage.pageName === 'BRAND_LIST' && field.biUrlPage.positionName === 'ADVERTISER_CUSTOM';

const isNotCustomUrlField = negate(isCustomUrlField);

const convertToCustomUrlFields = map(convertServerField);
const convertToCustomFields = compose(
  filter(
    (field) => field.brand !== null || field.campaign !== null || field.category !== null || field.subbrand !== null,
  ),
  convertToCustomUrlFields,
);

// eslint-disable-next-line
const makeConverterFields = (advertiserId: number, type: FieldType) =>
  pipe(
    filter<Field>((f) => f.isChanged && !(f.url === '' && !f.serverId)),
    map((f) => convertFieldToServer(f, advertiserId, type)),
  );

// eslint-disable-next-line
const makeConverterCustomFields = (advertiserId: number, type: FieldType) =>
  pipe(
    filter<CustomField>((f) => f.isChanged),
    map((f) => convertCustomFieldToServer(f, advertiserId, type)),
  );

const getFieldsIDs = pipe(
  filter<IFieldForSave>((f) => f.url === ''),
  map((f) => f.id),
);

const isEmptyErrorsField = (field: CustomField): boolean => !field.errors.length;

const convertToFields = (fields: ISavedServerField[], descriptions: FieldDescription[]): Record<number, Field> => {
  return descriptions.reduce((acc, d) => {
    const saved = fields.find(
      (f) =>
        f.biUrlPage.id === d.id &&
        f.brand === null &&
        f.campaign === null &&
        f.category === null &&
        f.subbrand === null,
    );
    const field: Field = saved
      ? {
          url: saved.url,
          isEnabled: saved.isEnabled,
          page: d.id,
          serverId: saved.id,
          isChanged: false,
        }
      : createInitialField(d.id);

    return { ...acc, [d.id]: field };
  }, {} as Record<number, Field>);
};

export {
  isCustomUrlField,
  convertToFields,
  isEmptyErrorsField,
  getFieldsIDs,
  makeConverterCustomFields,
  makeConverterFields,
  convertToCustomFields,
  convertToCustomUrlFields,
  isNotCustomUrlField,
};
