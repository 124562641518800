import React, { FC, PureComponent } from 'react';
import cx from 'classnames';
import * as R from 'ramda';

import { IOption } from 'types';
import api from 'api';
import { IPowerBIFilters, IPowerBiComparePeriodType, IPowerBiFilterItem } from 'types/powerBI';
import { localize, LocalizedMessage } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { Select } from 'shared/components/common/form/Select';
import { MultiSelect, MultiSelectChangeParams } from 'shared/components/common/form/MultiSelect';
import { toast } from 'shared/components/common/misc/Toast';

import classes from './Filters.module.scss';
import logoHyundai from './hyundai_logo.png';

type OptionNames = 'communication' | 'months' | 'years' | 'period' | 'detailedBy';
type ValueNames = Omit<OptionNames, 'period'> & ('period1' | 'period2');

type FilterOption = IOption<string>;

type Props = {
  onApplyFilters(filters: IPowerBIFilters): void;
};

type State = {
  values: {
    communication: string[];
    months: string[];
    years: string[];
    detailedBy: string;
    period1: string[];
    period2: string[];
  };
  options: Record<OptionNames, FilterOption[]>;
  invalidOptions: ValueNames[];
};

type Filter = {
  options: FilterOption[];
  value: string;
  onChange(value: string): void;
  label: string;
  isMulti: false;
  isInvalid?: boolean;
};

type MultiFilter = Omit<Filter, 'value' | 'onChange' | 'isMulti'> & {
  value: string[];
  onChange(e: MultiSelectChangeParams): void;
  isMulti: true;
};

class Filters extends PureComponent<Props, State> {
  state: State = {
    values: {
      communication: [],
      months: [],
      years: [],
      detailedBy: 'By Weeks',
      period1: [],
      period2: [],
    },
    options: {
      communication: [],
      months: [],
      years: [],
      detailedBy: [],
      period: [],
    },
    invalidOptions: [],
  };

  private periodType: IPowerBiComparePeriodType[] = [];

  async componentDidMount() {
    const { onApplyFilters } = this.props;
    const communicationPromise = api.powerbi.getHyundaiFiltersByType({ filterType: 'communication' });
    const monthPromise = api.powerbi.getHyundaiFiltersByType({ filterType: 'month' });
    const yearPromise = api.powerbi.getHyundaiFiltersByType({ filterType: 'year' });
    const detailedByPromise = api.powerbi.getHyundaiFiltersByType({ filterType: 'detailed-by' });
    const [communicationResponse, monthsResponse, yearsResponse, detailedByResponse] = await Promise.all([
      communicationPromise,
      monthPromise,
      yearPromise,
      detailedByPromise,
    ]);
    if (communicationResponse.error || monthsResponse.error || yearsResponse.error || detailedByResponse.error) {
      return;
    }

    const options = {
      communication: communicationResponse.data.map(this.convertToOption),
      months: monthsResponse.data.map((x) => ({
        label: x.value,
        value: x.key !== null ? x.key : 'All',
      })),
      years: yearsResponse.data.map(this.convertToOption),
      detailedBy: detailedByResponse.data.map(this.convertToOption),
    };

    const detailedBy = options.detailedBy[0]?.value ?? 'By Weeks';
    const communication = options.communication[0]?.value ? [options.communication[0].value] : [];
    const years = options.years[0]?.value ? [options.years[0].value] : [];

    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        ...options,
      },
      values: {
        ...prevState.values,
        detailedBy,
        communication,
        years,
      },
    }));

    onApplyFilters({ detailedBy, years, communication });
    this.getComparePeriods({ detailedBy, years: years.map(Number) });

    const periodTypeResponse = await api.powerbi.getHyundaiFiltersByType({ filterType: 'compare-period-type' });
    if (periodTypeResponse.error) return;

    this.periodType = periodTypeResponse.data;
  }

  async getComparePeriods(filters: { detailedBy: string; years?: number[]; months?: string[] }): Promise<void> {
    const hasMonthsAll = filters.months?.some((x) => x === 'All');
    const response = await api.powerbi.getHyundaiComparePeriods({
      ...filters,
      months: hasMonthsAll ? [] : filters.months,
    });
    if (response.error) return;

    const periodOptions = response.data.map((x) => ({
      label: x.value,
      value: x.key !== null ? x.key : '-',
    }));
    this.setState((prevState) => ({ options: { ...prevState.options, period: periodOptions } }));
  }

  handleChange = (name: keyof State['values'], values: string | string[]): void => {
    const needToResetPeriod = ['months', 'years', 'detailedBy'].includes(name);

    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          period1: needToResetPeriod ? [] : prevState.values.period1,
          period2: needToResetPeriod ? [] : prevState.values.period2,
          [name]: this.filterValues(name, values),
        },
        invalidOptions: prevState.invalidOptions.filter((x) => !x.includes(name)),
      }),
      () => {
        const { detailedBy, months, years } = this.state.values;
        this.getComparePeriods({ detailedBy, months, years: years.map(Number) });
      },
    );
  };

  filterValues = <T extends keyof State['values']>(name: T, values: string | string[]): string | string[] => {
    if (Array.isArray(values) && ['months', 'period1', 'period2', 'communication'].includes(name)) {
      const value = name === 'months' ? 'All' : name === 'communication' ? 'Total' : '-';
      const hasAllInNewValue = values.some((x) => x === value);
      const hasAllInOldValue = this.state.values[name as Exclude<T, 'detailedBy'>].some((x) => x === value);

      if (hasAllInOldValue) {
        return values.filter((x) => x !== value);
      } else if (hasAllInNewValue) {
        return values.filter((x) => x === value);
      }
    }

    return values;
  };

  handleApplyFilters = async (): Promise<void> => {
    const { values } = this.state;

    const isPeriodEmpty = (period: string[]) => !period.length || period.some((x) => x === '-');
    const periodValidityMap = {
      period1: isPeriodEmpty(values.period1),
      period2: isPeriodEmpty(values.period2),
    };
    const invalidPeriods = R.pipe(R.filter(Boolean), R.keys)(periodValidityMap);

    if (invalidPeriods.length === 1) {
      this.setState((prevState) => ({
        invalidOptions: [...prevState.invalidOptions, ...(invalidPeriods as ValueNames[])],
      }));

      toast.error(localize('powerbi.error.not-selected-period'));

      return;
    }

    this.props.onApplyFilters({
      ...values,
      periodType: this.periodType.find((x) => x.detailed_by === values.detailedBy)?.compare_period_type_bi,
    });
  };

  convertToOption = ({ value, key }: IPowerBiFilterItem): FilterOption => ({
    value,
    label: key !== null ? key : 'All',
  });

  renderFilter: FC<Filter | MultiFilter> = (f: Filter | MultiFilter) => {
    const isMultiFilter = (filter: Filter | MultiFilter): filter is MultiFilter => filter.isMulti;
    return (
      <div key={f.label} className={classes.Select}>
        <div className={classes.Label}>{f.label}</div>
        {isMultiFilter(f) ? (
          <MultiSelect
            options={f.options}
            value={f.value}
            onChange={f.onChange}
            filter
            showClear={false}
            invalid={f.isInvalid}
          />
        ) : (
          <Select
            className={classes.Filter}
            options={f.options}
            value={f.value}
            onChange={f.onChange}
            appendTo={document.body}
            invalid={f.isInvalid}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      options,
      values: { communication, detailedBy, years, months, period1, period2 },
      invalidOptions,
    } = this.state;

    const basicFilters: (Filter | MultiFilter)[] = [
      {
        options: options.communication,
        value: communication,
        onChange: (e: MultiSelectChangeParams) => this.handleChange('communication', e.value),
        label: 'Communication',
        isMulti: true,
      },
      {
        options: options.months,
        value: months,
        onChange: (e: MultiSelectChangeParams) => this.handleChange('months', e.value),
        label: 'Month',
        isMulti: true,
      },
      {
        options: options.years,
        value: years,
        onChange: (e: MultiSelectChangeParams) => this.handleChange('years', e.value),
        label: 'Year',
        isMulti: true,
      },
      {
        options: options.detailedBy,
        value: detailedBy,
        onChange: (value: string) => this.handleChange('detailedBy', value),
        label: 'Detailed By',
        isMulti: false,
      },
    ];

    const periodFilters: MultiFilter[] = [
      {
        options: options.period,
        value: period1,
        onChange: (e: MultiSelectChangeParams) => this.handleChange('period1', e.value),
        label: 'Comparsion. Period 1',
        isMulti: true,
        isInvalid: invalidOptions.includes('period1'),
      },
      {
        options: options.period,
        value: period2,
        onChange: (e: MultiSelectChangeParams) => this.handleChange('period2', e.value),
        label: 'Comparsion. Period 2',
        isMulti: true,
        isInvalid: invalidOptions.includes('period2'),
      },
    ];

    return (
      <div className={classes.Filters}>
        <div className={classes.LogoWrapper}>
          <img className={classes.Logo} src={logoHyundai} alt="" />
        </div>
        <div className={classes.FiltersWrapper}>
          <div className={classes.DashboardName}>
            <div>ATL real-time-dashboard</div>
          </div>
          <div className={classes.SelectsWrapper}>
            <div className={classes.SelectsList}>
              <div className={classes.SelectsGroup}>{basicFilters.map((filter) => this.renderFilter(filter))}</div>
              <div className={cx(classes.SelectsGroup, classes.SelectsGroupPeriods)}>
                {periodFilters.map((filter) => this.renderFilter(filter))}
              </div>
            </div>
            <Button onClick={this.handleApplyFilters} theme="success" className={classes.ApplyButton}>
              <LocalizedMessage id="button.apply" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Filters;
