import { CustomField, CustomUrlField, Error } from 'types/powerBI';

export function validateCustomUrlField(field: CustomUrlField): CustomUrlField {
  const { page, url, userUrlTitle } = field;
  const errors: Error[] = [];
  if (!field.isEnabled) return field;

  if (page === null) {
    errors.push('page');
  }

  if (userUrlTitle == null || userUrlTitle === '') {
    errors.push('name');
  }

  if (url === '') {
    errors.push('url');
  }

  return {
    ...field,
    errors,
  };
}

export function validateField(field: CustomField): CustomField {
  const { page, brand, campaign, type, url, isEnabled, subbrand, category } = field;
  const errors: Error[] = [];
  if (!field.isEnabled) return field;

  if (page === null) {
    errors.push('page');
  }
  if (['brand', 'subbrand'].includes(type) && brand === null) {
    errors.push('brand');
  }
  if (type === 'campaign' && campaign === null) {
    errors.push('campaign');
  }
  if (type === 'subbrand' && subbrand === null) {
    errors.push('subbrand');
  }
  if (type === 'category' && category === null) {
    errors.push('category');
  }
  if (isEnabled && url === '') {
    errors.push('url');
  }

  return { ...field, errors };
}
