import React, { PureComponent } from 'react';
import cx from 'classnames';

import { LocalizedMessage } from 'shared/components/other';
import classes from './PaginationButtons.module.scss';

interface IProps {
  page: number; // нумерация страниц начинается с 0
  totalPages: number;
  onUpdatePage: (newPage: number) => void;
}

class PaginationButtons extends PureComponent<IProps> {
  handlePrevPageButtonClick = (): void => {
    const { page, onUpdatePage } = this.props;

    if (page > 0) {
      onUpdatePage(page - 1);
    }
  };

  handleNextPageButtonClick = (): void => {
    const { page, totalPages, onUpdatePage } = this.props;

    if (page < totalPages - 1) {
      onUpdatePage(page + 1);
    }
  };

  handleFirstPageButtonClick = (): void => {
    const { page, onUpdatePage } = this.props;

    if (page > 0) {
      onUpdatePage(0);
    }
  };

  handleLastPageButtonClick = (): void => {
    const { page, totalPages, onUpdatePage } = this.props;

    if (page < totalPages - 1) {
      onUpdatePage(totalPages - 1);
    }
  };

  render() {
    const { page, totalPages } = this.props;

    return (
      totalPages > 1 && (
        <div className={classes.PaginationButtons}>
          <span
            className={cx(classes.PaginationButton, {
              [classes.PaginationButtonDisabled]: page === 0,
            })}
            onClick={this.handleFirstPageButtonClick}
            data-test="first-page-button"
          >
            <i className={cx(classes.PaginationButtonIcon, 'icon icon-arrow-to-first')} />
          </span>
          <span
            className={cx(classes.PaginationButton, {
              [classes.PaginationButtonDisabled]: page === 0,
            })}
            onClick={this.handlePrevPageButtonClick}
            data-test="prev-page-button"
          >
            <i className={cx(classes.PaginationButtonIcon, 'icon icon-arrow-left')} />
          </span>

          <span className={classes.PaginationValue}>
            {page + 1}
            <span className={classes.PaginationOf}>
              <LocalizedMessage id="of" />
            </span>
            {totalPages}
          </span>

          <span
            className={cx(classes.PaginationButton, {
              [classes.PaginationButtonDisabled]: page === totalPages - 1 || totalPages === 0,
            })}
            onClick={this.handleNextPageButtonClick}
            data-test="next-page-button"
          >
            <i className={cx(classes.PaginationButtonIcon, 'icon icon-arrow-right')} />
          </span>

          <span
            className={cx(classes.PaginationButton, {
              [classes.PaginationButtonDisabled]: page === totalPages - 1 || totalPages === 0,
            })}
            onClick={this.handleLastPageButtonClick}
            data-test="last-page-button"
          >
            <i className={cx(classes.PaginationButtonIcon, 'icon icon-arrow-to-last')} />
          </span>
        </div>
      )
    );
  }
}

export default PaginationButtons;
