import React from 'react';
import { usePaginator } from '../../Paginator.provider';
import { PageLinkButton, PageLinkButtonProps } from '../../components/PageLinkButton';

type Props = PageLinkButtonProps;

export const NextPageLink: React.VFC<Props> = (props) => {
  const { options } = usePaginator();
  return (
    <PageLinkButton
      {...options.nextPageLink}
      {...props}
      data-test="next-page-button"
      iconClassName="pi icon-arrow-right"
    />
  );
};

export type { Props as NextPageLinkProps };
