/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-param-reassign: 0 */
import * as R from 'ramda';
import { action, computed, makeObservable, observable, runInAction, reaction, override } from 'mobx';
import uniq from 'lodash/uniq';
import { isEqual } from 'date-fns';

import hasAccess from 'helpers/hasAccess';
import showConfirmation from 'helpers/showConfirmation';
import { convertToOptions, sortByFileExtension } from 'helpers/utils';
import { IOption } from 'types';
import {
  IClip,
  ICreative,
  IFileStatus,
  ITvMediaplan,
  IServerCreative,
  IVersion,
  TvTerritoryType,
  IFactFile,
  ICreativeForSaving,
  DefaultCommonData,
} from 'types/mediaplan';
import { FileS3Id } from 'types/file';
import { LocaleIdType } from 'locales';
import { localize, localizeMessage } from 'shared/components/other';
import { convertToDatePeriod } from 'helpers';
import { MediaplansStore } from './Mediaplans.store';
import { CampaignViewStore } from './CampaignView.store';
import {
  formatDate,
  convertStringToArray,
  isInvalidClipId,
  formatDateToStr,
  areUploaderValuesInvalid,
  showNotificationIfRetroDateIsExceeded,
} from '../helpers';
import { toast } from '../../../shared/components/common/misc/Toast';

type Deps = {
  campaignViewStore: CampaignViewStore;
};

export class TvMediaplansStore extends MediaplansStore<ITvMediaplan> {
  @observable
  private _audience: IOption | null = null;

  @observable
  private _territoryType: IOption<TvTerritoryType> | null = null;

  @observable
  private _retroperiod: Date[] | null = null;

  @observable
  private _creatives: ICreative[] = [];

  @observable
  private _factFiles: IFactFile[] = [];

  @observable
  private _isUploadingFactFiles = false;

  @observable
  private _currentVersionId: number | null = null;

  @observable
  private tvTypeToDefaultDataMap: Record<TvTerritoryType, DefaultCommonData> | null = null;

  @computed
  get audience(): IOption | null {
    return this._audience;
  }

  @computed
  get territoryType(): IOption<TvTerritoryType> | null {
    return this._territoryType;
  }

  @computed
  get retroperiod(): Date[] | null {
    return this._retroperiod;
  }

  @computed
  get creatives(): ICreative[] {
    return this._creatives;
  }

  @computed
  get factFiles(): IFactFile[] {
    return this._factFiles;
  }

  @computed
  get isUploadingFactFiles(): boolean {
    return this._isUploadingFactFiles;
  }

  @computed
  get currentVersionId(): number | null {
    return this._currentVersionId;
  }

  @computed
  get currentMediaplan(): ITvMediaplan | null {
    return this._mediaplans.find((mediaplan) => mediaplan.id === this._currentMediaplanId) ?? null;
  }

  @computed
  get currentVersion(): IVersion | undefined {
    return this.currentMediaplan?.versions.find((version: IVersion) => version.id === this.currentVersionId);
  }

  @computed
  get currentTvType(): TvTerritoryType | undefined {
    return this.territoryType?.value ?? this.currentMediaplan?.mediaType;
  }

  @computed
  get defaultDataForCurrentTvType(): DefaultCommonData | null {
    if (!this.tvTypeToDefaultDataMap || !this.currentTvType) {
      return null;
    }

    return this.tvTypeToDefaultDataMap[this.currentTvType];
  }

  @computed
  get audienceOptions(): IOption[] {
    return this.defaultDataForCurrentTvType?.audienceOptions ?? [];
  }

  @computed
  get defaultRetroperiod(): Date[] | null {
    return this.defaultDataForCurrentTvType?.retroperiod ?? null;
  }

  @computed
  get isRetroperiodDefault(): boolean {
    return (
      this.retroperiod !== null &&
      this.defaultRetroperiod !== null &&
      isEqual(this.retroperiod[0], this.defaultRetroperiod[0]) &&
      isEqual(this.retroperiod[1], this.defaultRetroperiod[1])
    );
  }

  @computed
  get lastAvailableRetroDate(): string | null {
    return this.defaultDataForCurrentTvType?.lastAvailableDate ?? null;
  }

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);

    reaction(() => this.currentVersionId, this.loadCreatives);

    reaction(
      () => this.deps.campaignViewStore.currentMediaplanTypeTab,
      (currentMediaplanTypeTab) => {
        if (currentMediaplanTypeTab === 'tv') {
          this.loadCommonData().then(() => this.setRetroperiod(this.defaultRetroperiod));
        }
      },
    );

    reaction(
      () => this.deps.campaignViewStore.uploadedStatus,
      () => {
        if (this.deps.campaignViewStore.currentMediaplanTypeTab === 'tv') {
          this.resetUploaderValues();
        }
      },
    );

    reaction(
      () => [this.currentTvType, this.deps.campaignViewStore.uploadedStatus],
      () => {
        if (this.deps.campaignViewStore.currentMediaplanTypeTab === 'tv') {
          this.setRetroperiod(this.defaultRetroperiod);
        }
      },
    );
  }

  @action
  setAudience = (audience: IOption | null): void => {
    this._audience = audience;
  };

  @action
  setTerritoryType = (territoryType: IOption<TvTerritoryType> | null): void => {
    this._territoryType = territoryType;
    this.setAudience(null);
  };

  @action
  setRetroperiod = (date: Date[] | null): void => {
    this._retroperiod = date;
  };

  @action
  setIsUploadingFactFiles = (isUploading: boolean): void => {
    this._isUploadingFactFiles = isUploading;
  };

  @action
  resetUploaderValues = (): void => {
    this.setAudience(null);
    this.setTerritoryType(null);
    this.setRetroperiod(null);
  };

  @override
  cleanUp(): void {
    super.cleanUp();
    this.resetUploaderValues();
    this.setIsUploadingFactFiles(false);
    this._factFiles = [];
    this._creatives = [];
    this._currentVersionId = null;
  }

  @action
  private loadCommonData = async (): Promise<void> => {
    const { api } = this.services;
    const {
      campaignViewStore: { campaignId },
      advertiserStore: { currentAdvertiserId: advertiserId },
    } = this.deps;

    if (campaignId === null || advertiserId === null) return;

    const [
      audiencesForFederalTV,
      audiencesForLocalTV,
      defaultRetroperiodForFederalTV,
      defaultRetroperiodForLocalTV,
      lastAvailableRetroDateForFederalTV,
      lastAvailableRetroDateForLocalTV,
    ] = await Promise.all([
      api.targetAudiences.list({ customerId: advertiserId, territoryType: TvTerritoryType.FEDERAL }),
      api.targetAudiences.list({ customerId: advertiserId, territoryType: TvTerritoryType.LOCAL }),
      api.tvMediaplans.getDefaultRetroperiod({ campaignId, territoryType: TvTerritoryType.FEDERAL }),
      api.tvMediaplans.getDefaultRetroperiod({ campaignId, territoryType: TvTerritoryType.LOCAL }),
      api.tvMediaplans.getLastAvailableRetroDate({ territoryType: TvTerritoryType.FEDERAL }),
      api.tvMediaplans.getLastAvailableRetroDate({ territoryType: TvTerritoryType.LOCAL }),
    ]);

    if (
      audiencesForFederalTV.error ||
      audiencesForLocalTV.error ||
      defaultRetroperiodForFederalTV.error ||
      defaultRetroperiodForLocalTV.error ||
      lastAvailableRetroDateForFederalTV.error ||
      lastAvailableRetroDateForLocalTV.error
    ) {
      return;
    }

    runInAction(() => {
      this.tvTypeToDefaultDataMap = {
        [TvTerritoryType.FEDERAL]: {
          audienceOptions: convertToOptions(audiencesForFederalTV.data.content),
          retroperiod: convertToDatePeriod(defaultRetroperiodForFederalTV.data),
          lastAvailableDate: lastAvailableRetroDateForFederalTV.data,
        },
        [TvTerritoryType.LOCAL]: {
          audienceOptions: convertToOptions(audiencesForLocalTV.data.content),
          retroperiod: convertToDatePeriod(defaultRetroperiodForLocalTV.data),
          lastAvailableDate: lastAvailableRetroDateForLocalTV.data,
        },
      };
    });
  };

  loadMediaplansCount = async (): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null) return;

    const response = await this.services.api.tvMediaplans.getCount({ campaignId });
    if (response.error) return;

    runInAction(() => {
      this._hasCurrentMediaplans = response.data.countCurrent > 0;
    });
  };

  loadMediaplans = async (): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;

    if (!hasAccess('tvMediaplansTab', 'campaignPage') || campaignId === null) return;

    const response = await this.services.api.tvMediaplans.list({ campaignId });
    if (response.error) return;

    const sortedMediaplans = response.data.map((tvMediaplan) => ({
      ...tvMediaplan,
      versions: sortByFileExtension(tvMediaplan.versions),
    }));

    runInAction(() => {
      this._mediaplans = sortedMediaplans;
      if (this._currentMediaplanId === null && sortedMediaplans.length) {
        const mediaplan = sortedMediaplans[0];
        this._currentMediaplanId = mediaplan.id;

        if (mediaplan.versions.length) {
          this._currentVersionId = mediaplan.versions[0].id;
        }
      } else if (this.currentMediaplan?.versions.length && this.currentVersionId === null) {
        this._currentVersionId = this.currentMediaplan.versions[0].id;
      } else if (
        this.currentVersionId &&
        this.currentMediaplan?.versions &&
        !this.currentMediaplan.versions.some((x) => x.id === this.currentVersionId)
      ) {
        this._currentVersionId = this.currentMediaplan.versions[0].id;
      }
    });
  };

  private loadCreatives = async (): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || this.currentVersionId === null) return;

    const response = await this.services.api.tvMediaplans.getCreatives({
      campaignId,
      mediaplanVersionId: this.currentVersionId,
    });
    if (response.error) return;

    runInAction(() => {
      this._creatives = response.data.map(this.convertServerCreative);
    });
  };

  @action
  private convertServerCreative = (creative: IServerCreative): ICreative => ({
    ...creative,
    clips: creative.clips.map((clip) => ({
      id: clip.id,
      name: clip.name,
      duration: clip.duration,
      clipDateStart: clip.clipDateStart,
      clipDateEnd: clip.clipDateEnd,
      tnsid: clip.mediascopeClips.map((tnsid) => tnsid.mediascopeClipId).join(', '),
      vimbid: clip.vimbClips.map((vimbid) => vimbid.vimbClipId).join(', '),
      invalidFields: [],
    })),
  });

  private convertCreativeForSaving = (creative: ICreative): ICreativeForSaving => ({
    id: creative.id,
    clips: creative.clips.map((clip) => ({
      id: clip.id,
      clipDateStart: formatDate(clip.clipDateStart),
      clipDateEnd: formatDate(clip.clipDateEnd),
      mediascopeClips: convertStringToArray(clip.tnsid).map((tnsId) => ({
        mediascopeClipId: Number(tnsId),
      })),
      vimbClips: convertStringToArray(clip.vimbid).map((vimbId) => ({
        vimbClipId: Number(vimbId),
      })),
    })),
  });

  renameMediaplan = async (newName: string): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || mediaplanId === null) return;

    const response = await services.api.tvMediaplans.rename({
      campaignId,
      mediaplanId,
      newName,
    });
    if (response.error) return;

    toast.success(localize('campaign-view.mediaplan.renamed'));

    runInAction(() => {
      this._mediaplans = this.mediaplans.map((mediaplan) =>
        mediaplan.id === this._currentMediaplanId ? { ...mediaplan, name: response.data.name } : mediaplan,
      );
    });
  };

  removeMediaplan = async (versionId: number | null): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || mediaplanId === null) return;

    const response =
      versionId !== null
        ? await services.api.tvMediaplans.deleteVersion({ campaignId, mediaplanVersionId: versionId })
        : await services.api.tvMediaplans.delete({ campaignId, mediaplanId });
    if (response.error) return;

    toast.success(localize('campaign-view.mediaplan.removed'));

    runInAction(() => {
      this._currentVersionId = null;
      if (versionId === null || this.currentMediaplan?.versions.length === 1) {
        this._currentMediaplanId = null;
      }
    });

    this.loadMediaplans();
    this.loadMediaplansCount();
  };

  uploadMediaplan = async (file: FormData, name: string): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, territoryType, audience, retroperiod, services } = this;
    const { campaignId, uploadedStatus } = this.deps.campaignViewStore;

    if (campaignId === null) return;

    if (areUploaderValuesInvalid({ audience, territoryType, retroperiod, uploadedStatus })) {
      toast.error(localize('campaign-view.errors.required-fields-are-not-filled'));
      return;
    }

    const [retroFrom, retroTo] = retroperiod!.map(formatDateToStr);

    const response =
      uploadedStatus === 'version'
        ? await services.api.tvMediaplans.uploadVersion({
            campaignId,
            retroFrom,
            retroTo,
            file,
            audienceId: audience!.value,
            mediaplanId: mediaplanId!,
          })
        : await services.api.tvMediaplans.upload({
            campaignId,
            retroFrom,
            retroTo,
            file,
            audienceId: audience!.value,
            mediaType: territoryType!.value,
          });
    if (response.error) return;

    toast.success(localizeMessage({ id: 'messages.file-added' }, { fileName: name }));

    runInAction(() => {
      this._currentMediaplanId = response.data.id;
      this.deps.campaignViewStore.setUploadedStatus(null);
    });

    this.loadMediaplans();
  };

  downloadMediaplan = async (): Promise<void> => {
    const { currentMediaplan, currentVersionId, services } = this;
    const version = currentMediaplan?.versions.find((v: IVersion) => v.id === currentVersionId);
    if (version === undefined) return;

    const { fileS3Id } = version;
    if (fileS3Id === null) {
      toast.error(localize('errors.file-id-not-found'));
      return;
    }

    services.api.file.download({ fileId: fileS3Id, fileType: 'MEDIAPLAN_TV' });
  };

  @override
  changeCurrentMediaplan(id: number): void {
    super.changeCurrentMediaplan(id);
    this._currentVersionId = this.currentMediaplan?.versions[0]?.id ?? null;
  }

  @action
  changeCurrentMediaplanVersion = (newVersion: IOption): void => {
    this._currentVersionId = newVersion.value;
  };

  changeMediaplanStatus = async (newStatus: IOption<IFileStatus>): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, currentVersionId, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || mediaplanId === null || currentVersionId === null) return;

    const response = await services.api.tvMediaplans.changeStatus({
      campaignId,
      mediaplanId,
      mediaplanVersionId: currentVersionId,
      newStatus: newStatus.value,
    });
    if (response.error) return;

    toast.success(localize('campaign-view.mediaplan.status-changed'));

    this.loadMediaplans();
    this.loadMediaplansCount();
  };

  handleMediaplanAudienceChange = async (newAudience: IOption, productId: number): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, currentVersionId, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || mediaplanId === null || currentVersionId === null) {
      return;
    }

    const response = await services.api.tvMediaplans.changeAudience({
      campaignId,
      productId,
      mediaplanId,
      mediaplanVersionId: currentVersionId,
      audienceId: newAudience.value,
    });
    if (response.error) return;

    runInAction(() => {
      this._creatives = this.creatives.map((creative) =>
        creative.id === response.data.id ? this.convertServerCreative(response.data) : creative,
      );
    });
  };

  handleMediaplanRetroperiodChange = async (newRetroperiod: Date[] | null, productId: number): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, currentVersionId, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || mediaplanId === null || currentVersionId === null || newRetroperiod === null) {
      return;
    }
    const [retroFrom, retroTo] = newRetroperiod.map(formatDateToStr);

    showNotificationIfRetroDateIsExceeded(newRetroperiod, this.lastAvailableRetroDate);

    const response = await services.api.tvMediaplans.changeRetroperiod({
      campaignId,
      productId,
      mediaplanId,
      mediaplanVersionId: currentVersionId,
      retroFrom,
      retroTo,
    });
    if (response.error) return;

    runInAction(() => {
      this._creatives = this.creatives.map((creative) =>
        creative.id === response.data.id ? this.convertServerCreative(response.data) : creative,
      );
    });
  };

  @action
  handleClipDateChange = ([dateStart, dateEnd]: (Date | null)[], clipId: number): void => {
    this.creatives.forEach((creative) => {
      creative.clips.forEach((clip) => {
        if (clip.id === clipId) {
          clip.clipDateStart = dateStart;
          clip.clipDateEnd = dateEnd;
          clip.invalidFields = clip.invalidFields.filter((field) => field !== 'date');
        }
      });
    });
  };

  @action
  handleCreativeFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    optionName: 'tnsid' | 'vimbid',
    clipId: number,
  ): void => {
    this.creatives.forEach((creative) => {
      creative.clips.forEach((clip) => {
        if (clip.id === clipId) {
          clip[optionName] = e.target.value;
          clip.invalidFields = clip.invalidFields.filter((field) => field !== optionName);
        }
      });
    });
  };

  @action
  handleCreativeChange = (creativeId: number, nextClips: IClip[]): void => {
    const index = this.creatives.findIndex((x) => x.id === creativeId);
    if (index !== -1) {
      const nextCreative: ICreative = { ...this.creatives[index], clips: nextClips };
      this._creatives = R.update(index, nextCreative)(this.creatives);
    }
  };

  @action
  handleCreativesSave = async (): Promise<void> => {
    const { creatives, _currentMediaplanId: mediaplanId, currentVersionId, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (
      campaignId === null ||
      creatives.every((creative) => !creative.clips.length) ||
      mediaplanId === null ||
      currentVersionId === null
    ) {
      return;
    }

    const areCreativesValid = this.validateCreatives();
    if (!areCreativesValid) return;

    const response = await services.api.tvMediaplans.updateCreatives({
      campaignId,
      mediaplanId,
      mediaplanVersionId: currentVersionId,
      data: creatives.map(this.convertCreativeForSaving),
    });
    if (response.error) return;

    toast.success(localize('tv-mediaplan.creatives.saved'));

    runInAction(() => {
      this._creatives = response.data.map(this.convertServerCreative);
    });
  };

  private validateCreatives = (): boolean => {
    let isValid = true;

    this.creatives.forEach((creative) => {
      creative.clips.forEach((clip: IClip) => {
        clip.invalidFields = [];
        const errorMessageIds: LocaleIdType[] = [];

        if (convertStringToArray(clip.tnsid).some(isInvalidClipId)) {
          clip.invalidFields.push('tnsid');
          errorMessageIds.push('campaign-view.errors.wrong-clip-id');
        }
        if (convertStringToArray(clip.vimbid).some(isInvalidClipId)) {
          clip.invalidFields.push('vimbid');
          errorMessageIds.push('campaign-view.errors.wrong-clip-id');
        }
        if ((clip.clipDateStart && !clip.clipDateEnd) || (clip.clipDateEnd && !clip.clipDateStart)) {
          clip.invalidFields.push('date');
          errorMessageIds.push('campaign-view.errors.wrong-clip-date');
        }

        if (errorMessageIds.length) {
          toast.error(
            uniq(errorMessageIds)
              .map((id) => localizeMessage({ id }, { lineNumber: String(clip.id) }))
              .join('\n\n'),
          );
          isValid = false;
        }
      });
    });

    runInAction(() => {
      this._creatives = R.clone(this.creatives);
    });

    return isValid;
  };

  /* Fact files */

  loadFactFiles = async (): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;
    if (campaignId === null) return;

    const response = await this.services.api.tvMediaplans.getFactFiles({ campaignId });
    if (response.error) return;

    const factFiles = response.data.filter((file: IFactFile) => !file.isDeleted);

    runInAction(() => {
      this._factFiles = factFiles;
    });
  };

  handleFactFileUpload = async (formData: FormData, name: string): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;
    if (campaignId === null) return;

    const response = await this.services.api.tvMediaplans.uploadFactFile({ campaignId, formData });
    if (response.error) return;

    runInAction(() => {
      this._isUploadingFactFiles = false;
    });

    toast.success(localizeMessage({ id: 'messages.file-added' }, { fileName: name }));
    await this.loadFactFiles();
  };

  handleFactDataDownload = async (isChangedData = false): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;
    if (campaignId === null) return;

    const response = isChangedData
      ? await this.services.api.tvMediaplans.downloadUserChangedData({ campaignId })
      : await this.services.api.tvMediaplans.downloadFactData({ campaignId });
    if (response.error) return;

    this.handleFactFileDownload(response.data.fileS3Id);
  };

  handleFactFileDownload = async (fileS3Id: FileS3Id): Promise<void> => {
    this.services.api.file.download({ fileId: fileS3Id, fileType: 'MEDIAFACT_TV' });
  };

  handleFactFileStatusChange = async (newStatus: IOption<IFileStatus>, fileId: number): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;
    if (campaignId === null) return;

    const response = await this.services.api.tvMediaplans.changeStatusFactFile({
      versionId: fileId,
      campaignId,
      newStatus: newStatus.value,
    });
    if (response.error) return;

    toast.success(localize('campaign-view.mediaplan.status-changed'));

    runInAction(() => {
      this._factFiles = this.factFiles.map((file) =>
        file.id === fileId ? { ...file, status: newStatus.value } : file,
      );
    });
  };

  handleFactFileRemove = async (fileId: IFactFile['id']): Promise<void> => {
    const isConfirmed = await showConfirmation({
      title: `${localize('tv-mediaplan.fact-file.actions.remove-confirm')}?`,
      text: localize('tv-mediaplan.fact-file.actions.remove-description'),
      type: 'removal',
    });

    if (!isConfirmed) return;

    const response = await this.services.api.tvMediaplans.deleteFactFile({ fileId });
    if (response.error) return;

    runInAction(() => {
      this._factFiles = this.factFiles.filter((file) => file.id !== fileId);
    });

    toast.success(localize('campaign-view.mediaplan.removed'));
  };

  downloadTemplate = async (territoryType: TvTerritoryType): Promise<void> => {
    const response = await this.services.api.tvMediaplans.getPlanTemplate({ territoryType });
    if (response.error) return;

    this.services.api.file.download({ fileId: response.data.fileS3Id, fileType: 'MEDIAFACT_TV_TEMPLATES' });
  };
}
