import React, { FC, PureComponent } from 'react';
import { Switch } from 'react-router-dom';

import { Page } from 'helpers/hasAccess';
import { LocalizedTitle } from 'shared/components/other';
import { PrivateRoute } from 'shared/components/domain/PrivateRoute';
import { Footer } from 'shared/components/layout/Footer';
import Sidebar from './components/Sidebar';
import { EditAdvertiser as AdvertiserSettings } from './EditAdvertiser';
import { EditBrands as BrandSettings } from './EditBrands';
import { Appearance } from './Appearance';
import { Templates } from './Templates';
import { PowerBiDefaultSettings, PowerBiAdvertiserSettings } from './PowerBiSettings';

import classes from './Administration.module.scss';

class Administration extends PureComponent {
  render() {
    return (
      <div className={classes.Layout}>
        <LocalizedTitle id="header.settings.administration" />
        <Sidebar />
        <section className={classes.Administration}>
          <div className={classes.Content}>
            <AdministrationRoutes />
            <Footer />
          </div>
        </section>
      </div>
    );
  }
}

const AdministrationRoutes: FC = () => (
  <Switch>
    <PrivateRoute
      exact
      path="/administration/advertiser"
      component={AdvertiserSettings}
      checkableFeature={adminTabToAccessFeatureMap.get('advertiser')}
    />
    <PrivateRoute
      exact
      path="/administration/brands/:id?"
      component={BrandSettings}
      checkableFeature={adminTabToAccessFeatureMap.get('brands')}
    />
    <PrivateRoute
      exact
      path="/administration/appearance"
      component={Appearance}
      checkableFeature={adminTabToAccessFeatureMap.get('appearance')}
    />
    <PrivateRoute
      exact
      path="/administration/templates"
      component={Templates}
      checkableFeature={adminTabToAccessFeatureMap.get('templates')}
    />
    <PrivateRoute
      exact
      path="/administration/powerbi-default-settings"
      component={PowerBiDefaultSettings}
      checkableFeature={adminTabToAccessFeatureMap.get('powerbi-default-settings')}
    />
    <PrivateRoute
      exact
      path="/administration/powerbi-advertiser-settings"
      component={PowerBiAdvertiserSettings}
      checkableFeature={adminTabToAccessFeatureMap.get('powerbi-advertiser-settings')}
    />
  </Switch>
);

export const adminTabToAccessFeatureMap = new Map<string, Page>([
  ['advertiser', 'administration'],
  ['brands', 'administration'],
  ['appearance', 'administration'],
  ['templates', 'templates'],
  ['powerbi-default-settings', 'powerbiDefaultSettings'],
  ['powerbi-advertiser-settings', 'administration'],
]);

export { Administration };
