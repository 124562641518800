import React, { useState } from 'react';
import cx from 'classnames';
import { CountdownButton } from 'shared/components/common/buttons/CountdownButton';
import { Icon } from 'shared/components/common/misc/Icon';
import classes from './InstructionIconLink.module.scss';

type Props = {
  /** Instruction link url */
  url: string;
  /** Additional className */
  className?: string;
  /** Icon class name (icon font) or url (src). Default: 'icon-question' */
  icon?: string;
  /** Additional class name of icon element */
  iconClassName?: string;
  /** Popup message */
  popupMessage?: string;
  /** Popup message ID. If it's definite, the message will be shown once. Default: 'showInstructionLinkTip' */
  popupId?: string;
  /** Popup direction. Default: 'left' */
  popupDirection?: 'right' | 'left';
};

export const InstructionIconLink: React.VFC<Props> = (props) => {
  const {
    url,
    icon = 'icon-question',
    iconClassName,
    className,
    popupMessage,
    popupId = 'showInstructionLinkTip',
    popupDirection = 'left',
  } = props;

  const [isInstructionPopupVisible, setIsInstructionPopupVisible] = useState(
    popupId ? localStorage.getItem(popupId) !== 'false' : true,
  );

  const handleCountdownComplete = () => {
    if (popupId) {
      localStorage.setItem(popupId, 'false');
    }
    setIsInstructionPopupVisible(false);
  };

  return (
    <div className={cx(classes.root, className)}>
      <a className={classes.link} href={url} target="_blank" rel="noopener noreferrer">
        <Icon icon={icon} className={cx(classes.icon, iconClassName)} />
      </a>
      {popupMessage && isInstructionPopupVisible && (
        <div className={cx(classes.popup, { [classes[`${popupDirection}Direction`]]: !!popupDirection })}>
          <span className={cx(classes.infoIcon, 'pi pi-info-circle')} />
          <div className={classes.message}>{popupMessage}</div>
          <CountdownButton className={classes.countdown} onComplete={handleCountdownComplete} />
        </div>
      )}
    </div>
  );
};
