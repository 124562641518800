import { CustomOptions } from 'api/types';
import { IWebMediaplan, IUploadedFile, ICountMediaplan, Sheets, CampaignInfo } from 'types/mediaplan';
import BaseApi from '../BaseApi';

class WebMediaplans extends BaseApi {
  list = ({ campaignId }: Pick<MediaplanData, 'campaignId'>, config: CustomOptions = {}) =>
    this.actions.get<IWebMediaplan[]>({
      url: '/mediaplan/web/campaign-mediaplan',
      data: { campaignId },
      config,
    });

  getCount = ({ campaignId }: Pick<MediaplanData, 'campaignId'>, config: CustomOptions = {}) =>
    this.actions.get<ICountMediaplan>({
      url: '/mediaplan/web/get-number-of-mediaplans',
      data: { campaignId },
      config,
    });

  rename = ({ campaignId, mediaplanId, newName }: RenameMediaplanData, config: CustomOptions = {}) =>
    this.actions.post<IWebMediaplan>({
      url: '/mediaplan/web/rename-mediaplan',
      queryParams: { campaignId, mediaplanId },
      data: { newName },
      config,
    });

  delete = ({ campaignId, mediaplanId }: MediaplanData, config: CustomOptions = {}) =>
    this.actions.delete<IWebMediaplan>({
      url: '/mediaplan/web/delete-mediaplan',
      queryParams: { campaignId, mediaplanId },
      config,
    });

  deleteVersion = ({ campaignId, mediaplanId, mediaplanVersionId }: MediaplanVersionData, config: CustomOptions = {}) =>
    this.actions.delete<IWebMediaplan>({
      url: '/mediaplan/web/delete-mediaplan-version',
      queryParams: { campaignId, mediaplanId, mediaplanVersionId },
      config,
    });

  changeStatus = ({ campaignId, mediaplanId, data }: ChangeStatusData, config: CustomOptions = {}) =>
    this.actions.post<IWebMediaplan>({
      url: '/mediaplan/web/set-mediaplan-status',
      queryParams: { campaignId, mediaplanId },
      data,
      config,
    });

  upload = ({ campaignId, mediaplanName, formData }: UploadMediaplanData, config: CustomOptions = {}) =>
    this.actions.post<IUploadedFile>({
      url: '/mediaplan/web/upload-mediaplan',
      queryParams: { campaignId, mediaplanName },
      data: formData,
      config,
    });

  uploadVersion = ({ campaignId, mediaplanId, formData }: UploadMediaplanVersionData, config: CustomOptions = {}) =>
    this.actions.post<IUploadedFile>({
      url: '/mediaplan/web/upload-new-version',
      queryParams: { campaignId, mediaplanId },
      data: formData,
      config,
    });

  uploadExistingMediaplan = (
    { campaignId, fileS3Id, mediaplanName }: UploadExistingMediaplanData,
    config: CustomOptions = {},
  ) =>
    this.actions.post<IUploadedFile>({
      url: '/mediaplan/web/upload-existing-mediaplan',
      queryParams: { campaignId, fileS3Id, mediaplanName },
      config,
    });

  getExcelSheets = ({ customerId, formData }: GetExcelSheetsData, config: CustomOptions = {}) =>
    this.actions.post<Sheets>({
      url: '/mediaplan/web/excel/sheets-s3',
      queryParams: { customerId },
      data: formData,
      config,
    });

  getCampaignInfo = ({ customerId, fileS3Id, sheetId }: GetCampaignInfoData, config: CustomOptions = {}) =>
    this.actions.get<CampaignInfo>({
      url: '/mediaplan/web/campaign/info',
      data: { customerId, fileS3Id, sheetId },
      config,
    });
}

type MediaplanData = {
  campaignId: number;
  mediaplanId: number;
};

type MediaplanVersionData = MediaplanData & {
  mediaplanVersionId: number;
};

type RenameMediaplanData = MediaplanData & { newName: string };

type ChangeStatusData = MediaplanData & { data: Record<string, unknown> };

type UploadMediaplanData = {
  campaignId: number;
  mediaplanName: string;
  formData: FormData;
};

type UploadMediaplanVersionData = MediaplanData & { formData: FormData };

type UploadExistingMediaplanData = {
  campaignId: number;
  fileS3Id: string;
  mediaplanName: string;
};

type GetExcelSheetsData = {
  customerId: number;
  formData: FormData;
};

type GetCampaignInfoData = {
  customerId: number;
  fileS3Id: string;
  sheetId: number;
};

export default WebMediaplans;
