import { roundCampaignBudget } from 'helpers/utils';
import { localize } from 'shared/components/other';

type ProgressBudget = {
  correctly: number;
  exceeding: number;
  percent: number | null;
};

export const getProgressBudget = (planBudget: number, factBudget: number): ProgressBudget => {
  let correctly: number;
  let exceeding = 0;
  const plan = Math.round(planBudget / 1000);
  const fact = Math.round(factBudget / 1000);

  if (!plan && !fact) {
    correctly = 0;
  } else if (fact < plan) {
    correctly = (fact / plan) * 100;
  } else if (fact === plan) {
    correctly = 100;
  } else {
    correctly = (plan / fact) * 100;
    exceeding = 100 - correctly;
  }

  const percent = plan ? Math.round((fact / plan) * 100) : null;

  return {
    correctly,
    exceeding,
    percent,
  };
};

export const getBudgetTooltip = (sum: number, lang: string, unit: string): string =>
  `${Math.round(sum).toLocaleString(lang)} ${unit}`;

export const getBudgetValue = (budget: number): string => {
  if (!budget) {
    return '-';
  }
  const { value, unitId } = roundCampaignBudget(budget);

  return `${value} ${localize(unitId)}`;
};

export const getPercentValue = (percent: number | null): string => {
  if (percent === null) {
    return '-';
  }
  if (percent > 1_000_000) {
    return '> 1 млн%';
  }

  return `${percent}%`;
};
