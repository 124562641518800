import React, { FC } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Checkbox } from 'shared/components/other';

type Props = {
  name: 'accessibleToClient' | 'hideBudgetScale';
  defaultValue: boolean;
  control: Control;
};

const ControlledCheckbox: FC<Props> = ({ name, defaultValue, control }: Props) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ value, onChange }) => (
      <Checkbox size="large" name={name} checked={value} onChange={() => onChange(!value)} />
    )}
  />
);

export { ControlledCheckbox };
