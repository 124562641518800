import React from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import { UploadedStatus } from 'types/campaign';
import { ICreative, IVersion } from 'types/mediaplan';
import { FileVersionSelector, FileVersionSelectorProps } from './components/FileVersionSelector';
import { FileStatusSelector, FileStatusSelectorProps } from './components/FileStatusSelector';
import { AudienceSelector, AudienceSelectorProps } from './components/AudienceSelector';
import { RetroperiodDatepicker, RetroperiodDatepickerProps } from './components/RetroperiodDatepicker';
import { TvMediaplansTable, TvMediaplansTableProps } from './components/TvMediaplansTable';
import { RegionalCreatives } from './components/RegionalCreatives';
import { AddFileButton } from '../../../../../../../AddFileButton';
import classes from './Creatives.module.scss';

type Props = {
  audienceSelector: AudienceSelectorProps;
  className?: string;
  creatives: ICreative[];
  currentTvMediaplanId?: number;
  currentTvVersion?: IVersion;
  fileStatusSelector: FileStatusSelectorProps;
  fileVersionSelector: FileVersionSelectorProps;
  isCurrentStatus: boolean;
  isFederalProduct: boolean;
  onCreativesSave(): void;
  onVersionAdd(value: UploadedStatus | null): void;
  onVersionDownload(): void;
  onVersionRemove(): void;
  retroperiodDatepicker: RetroperiodDatepickerProps;
  tvMediaplansTable: Pick<TvMediaplansTableProps, 'onChange'>;
};

export const Creatives: React.VFC<Props> = React.memo((props) => {
  const {
    audienceSelector,
    className,
    creatives,
    currentTvMediaplanId,
    fileStatusSelector,
    fileVersionSelector,
    isCurrentStatus,
    isFederalProduct,
    onCreativesSave,
    onVersionAdd,
    onVersionDownload,
    onVersionRemove,
    retroperiodDatepicker,
    tvMediaplansTable,
  } = props;

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.buttons}>
        <AddFileButton onClick={onVersionAdd} type="version" />
        <TextButton
          className={classes.button}
          data-test={`download-file-link-${currentTvMediaplanId}`}
          icon="pi pi-download"
          onClick={onVersionDownload}
          tooltip={localize('campaign-view.mediaplan.actions.download-version')}
        />
        <TextButton
          className={classes.button}
          data-test="remove-tv-version-button"
          icon="pi pi-times"
          onClick={onVersionRemove}
          tooltip={localize('campaign-view.mediaplan.actions.delete-version')}
        />
      </div>
      <div className={classes.controls}>
        <FileVersionSelector {...fileVersionSelector} />
        <FileStatusSelector {...fileStatusSelector} />
        {isFederalProduct && <AudienceSelector {...audienceSelector} />}
        {isFederalProduct && <RetroperiodDatepicker {...retroperiodDatepicker} />}
      </div>
      {isCurrentStatus && (
        <TextButton
          className={classes.saveButton}
          icon="icon-save"
          onClick={onCreativesSave}
          tooltip={localize('campaign-view.mediaplan.actions.save-creatives')}
        />
      )}
      {isFederalProduct ? (
        <TvMediaplansTable
          {...tvMediaplansTable}
          creativeId={creatives[0].id}
          data={creatives[0].clips}
          readOnly={!isCurrentStatus}
        />
      ) : (
        creatives.map((creative) => (
          <RegionalCreatives
            audienceSelector={audienceSelector}
            className={classes.regionalCreativesItem}
            key={creative.id}
            product={creative}
            retroperiodDatepicker={retroperiodDatepicker}
            tvMediaplansTable={{
              creativeId: creative.id,
              data: creative.clips,
              onChange: tvMediaplansTable.onChange,
              readOnly: !isCurrentStatus,
            }}
          />
        ))
      )}
    </div>
  );
});

export type { Props as CreativesProps };
