import { action, makeObservable, observable, runInAction } from 'mobx';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';

import handleError from 'helpers/handleError';
import { isNotNull } from 'helpers/isNotNull';
import { CustomField, CustomUrlField, Field, FieldDescription, IFieldForSave, LogoStatus } from 'types/powerBI';
import { IBrand } from 'types/brand';
import { IShortCampaign } from 'types/campaign';
import { ICategory } from 'types/category';
import { ISubbrand } from 'types/subbrand';
import { PSR } from 'types';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { BaseStore } from 'shared/stores';
import { PowerBiStore } from './PowerBi.store';
import {
  convertToCustomFields,
  getFieldsIDs,
  makeConverterFields,
  convertToFields,
  isEmptyErrorsField,
  makeConverterCustomFields,
  isCustomUrlField,
  isNotCustomUrlField,
  convertToCustomUrlFields,
} from '../helpers/converters/advertiserSettings';
import { validateCustomUrlField, validateField } from '../helpers/validate';

type Deps = {
  powerBiStore: PowerBiStore;
};

export class PowerBiAdvertiserSettingsStore extends BaseStore<Deps> {
  @observable
  internalCustomFields: CustomField[] = [];

  @observable
  clientCustomFields: CustomField[] = [];

  @observable
  internalCustomUrlFields: CustomUrlField[] = [];

  @observable
  clientCustomUrlFields: CustomUrlField[] = [];

  @observable
  internalFields: Record<number, Field> = {};

  @observable
  clientFields: Record<number, Field> = {};

  @observable
  description: FieldDescription[] = [];

  @observable
  logoStatus: LogoStatus = 'loading';

  @observable
  brands: IBrand[] = [];

  @observable
  categories: ICategory[] = [];

  @observable
  campaigns: IShortCampaign[] = [];

  @observable
  removableFields: number[] = [];

  @observable
  subbrandsByBrandId: Record<number, ISubbrand[]> = {};

  initFields = async (advertiserId: number | null): Promise<void> => {
    if (advertiserId === null) return;

    const { api } = this.services;

    const [description, fields, brands, campaigns, categories] = await Promise.all([
      api.powerbi.getFieldDescriptions(),
      api.powerbi.getAdvertiserUrls({ customerId: advertiserId }),
      api.brands.list({ customerId: advertiserId }),
      api.campaigns.nameList({ customerId: advertiserId }),
      api.categories.list({ customerId: advertiserId }),
    ]);
    if (description.error || fields.error || brands.error || campaigns.error || categories.error) {
      return;
    }

    const internalFields = fields.data.filter((f) => f.biUrlRole === null);
    const clientFields = fields.data.filter((f) => f.biUrlRole !== null);

    const internalNonUrlFields = internalFields.filter(isNotCustomUrlField);
    const internalCustomUrlFields = internalFields.filter(isCustomUrlField);
    const clientCustomUrlFields = clientFields.filter(isCustomUrlField);

    const requiredBrandsToLoad: number[] = uniq(fields.data.map((f) => f.brand).filter(isNotNull));
    await this.loadSubbrands(requiredBrandsToLoad);

    runInAction(() => {
      this.internalFields = convertToFields(internalFields, description.data);
      this.clientFields = convertToFields(clientFields, description.data);
      this.internalCustomFields = convertToCustomFields(internalNonUrlFields);
      this.clientCustomFields = convertToCustomFields(clientFields);
      this.internalCustomUrlFields = convertToCustomUrlFields(internalCustomUrlFields);
      this.clientCustomUrlFields = convertToCustomUrlFields(clientCustomUrlFields);
      this.description = description.data;
      this.brands = brands.data.content;
      this.campaigns = campaigns.data.content;
      this.categories = categories.data.content;
      this.removableFields = [];
    });
  };

  @action
  setInternalCustomUrls = (fields: CustomUrlField[]): void => {
    this.internalCustomUrlFields = fields;
  };

  @action
  setClientCustomUrls = (fields: CustomUrlField[]): void => {
    this.clientCustomUrlFields = fields;
  };

  @action
  setLogoStatus = (logoStatus: LogoStatus): void => {
    this.logoStatus = logoStatus;
  };

  @action
  setInternalCustomSettings = (fields: CustomField[]): void => {
    this.internalCustomFields = fields;
  };

  @action
  setClientCustomSettings = (fields: CustomField[]): void => {
    this.clientCustomFields = fields;
  };

  @action
  addRemovableFields = (id: number): void => {
    this.removableFields = [...this.removableFields, id];
  };

  @action
  changeInternalFields = (newField: Field): void => {
    this.internalFields = {
      ...this.internalFields,
      [newField.page]: newField,
    };
  };

  @action
  changeClientFields = (newField: Field): void => {
    this.clientFields = {
      ...this.clientFields,
      [newField.page]: newField,
    };
  };

  prepareSaveSettings = async (advertiserId: number | null): Promise<void> => {
    if (advertiserId === null) {
      return;
    }

    const allFields = [
      ...Object.values(this.internalFields),
      ...Object.values(this.clientFields),
      ...Object.values(this.internalCustomUrlFields),
    ];
    const isValidFields = allFields.every(({ url, isEnabled }) => !(isEnabled && url === ''));

    const checkedInternalCustomFields = this.internalCustomFields.map(validateField);
    const checkedClientCustomFields = this.clientCustomFields.map(validateField);

    const checkedInternalCustomUrlFields = this.internalCustomUrlFields.map(validateCustomUrlField);
    const checkedClientCustomUrlFields = this.clientCustomUrlFields.map(validateCustomUrlField);

    const isValidInternalCustomFields = checkedInternalCustomFields.every(isEmptyErrorsField);
    const isValidClientCustomFields = checkedClientCustomFields.every(isEmptyErrorsField);

    const isValidInternalCustomUrlFields = checkedInternalCustomUrlFields.every(isEmptyErrorsField);
    const isValidClientCustomUrlFields = checkedClientCustomUrlFields.every(isEmptyErrorsField);

    const isValidCustomFields =
      isValidInternalCustomFields &&
      isValidClientCustomFields &&
      isValidInternalCustomUrlFields &&
      isValidClientCustomUrlFields;

    if (!isValidFields || !isValidCustomFields) {
      if (!isValidInternalCustomFields) {
        this.setInternalCustomSettings(checkedInternalCustomFields);
      }
      if (!isValidClientCustomFields) {
        this.setClientCustomSettings(checkedClientCustomFields);
      }

      if (!isValidInternalCustomUrlFields) {
        this.setInternalCustomUrls(checkedInternalCustomUrlFields);
      }

      if (!isValidClientCustomUrlFields) {
        this.setClientCustomUrls(checkedClientCustomUrlFields);
      }

      toast.error(localize('powerbi.error.validation-fail'));

      return;
    }

    const internalFieldsConverter = makeConverterFields(advertiserId, 'internal');
    const internalCustomFieldsConverter = makeConverterCustomFields(advertiserId, 'internal');

    const clientFieldsConverter = makeConverterFields(advertiserId, 'client');
    const clientCustomFieldsConverter = makeConverterCustomFields(advertiserId, 'client');

    try {
      const internal = internalFieldsConverter(this.internalFields);
      const client = clientFieldsConverter(this.clientFields);

      const internalCustom = internalCustomFieldsConverter(this.internalCustomFields);
      const clientCustom = clientCustomFieldsConverter(this.clientCustomFields);

      const internalUrlCustom = internalCustomFieldsConverter(this.internalCustomUrlFields);
      const clientUrlCustom = clientCustomFieldsConverter(this.clientCustomUrlFields);

      const removableInternal = getFieldsIDs(internal);
      const removableClient = getFieldsIDs(client);

      const request = [
        ...internal.filter((f) => !removableInternal.includes(f.id)),
        ...client.filter((f) => !removableClient.includes(f.id)),
        ...internalCustom,
        ...clientCustom,
        ...internalUrlCustom,
        ...clientUrlCustom,
      ];

      const removableFields = [
        ...this.removableFields,
        ...(removableInternal as number[]),
        ...(removableClient as number[]),
      ];

      await this.saveSettings(request, removableFields);
      this.initFields(advertiserId);
    } catch (error) {
      handleError(error);
    }
  };

  loadSubbrands = async (brandsIds: number[]): Promise<void> => {
    const promises = brandsIds
      .filter((brandId) => !this.subbrandsByBrandId[brandId])
      .map((brandId) => this.services.api.subbrands.list({ brandId }));

    const responses = await Promise.all(promises);
    if (responses.some((response) => response.error)) return;

    const subbrandsByBrandId = groupBy(
      responses.flatMap((x) => (x.data as PSR<ISubbrand[]>).content),
      'brand',
    ) as Record<number, ISubbrand[]>;
    runInAction(() => {
      this.subbrandsByBrandId = {
        ...this.subbrandsByBrandId,
        ...subbrandsByBrandId,
      };
    });
  };

  saveSettings = async (fields: IFieldForSave[], removableFieldIds: number[]): Promise<void> => {
    if (removableFieldIds.length) {
      const removingFieldsResponse = await this.services.api.powerbi.deleteCustomFields({
        fieldIds: removableFieldIds,
      });
      if (removingFieldsResponse.error) return;
    }

    const savingResponse = await this.services.api.powerbi.saveDefaultUrls({ fields });
    if (savingResponse.error) return;

    toast.success(localize('powerbi.saved'));
  };

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
  }
}
