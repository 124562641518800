import { LocaleIdType } from 'locales';
import serverErrorTemplates from './ru/serverErrorTemplates.json';
import serverErrorTemplateParameters from './ru/serverErrorTemplateParameters.json';

const errorsWithNotify: LocaleIdType[] = ['csp.access-denied'];

const errorLocales = Object.keys(serverErrorTemplates) as LocaleIdType[];

const errorParamLocales = Object.keys(serverErrorTemplateParameters) as LocaleIdType[];

export { errorLocales, errorParamLocales, errorsWithNotify };
