import { action, makeObservable, observable, runInAction } from 'mobx';
import chroma from 'chroma-js';
import ColorThief from 'colorthief';

import { Response } from 'api/types';
import { IBrand } from 'types/brand';
import { ISubbrand } from 'types/subbrand';

import getLogoSrc from 'helpers/getLogoSrc';
import { PromiseAll } from 'helpers/utils';
import handleError from 'helpers/handleError';

import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { BaseStore } from 'shared/stores';

const colorThief = new ColorThief();

export class EditBrandsStore extends BaseStore {
  @observable
  brands: IBrand[] = [];

  @observable
  subbrands: ISubbrand[] = [];

  @observable
  selectedBrand: IBrand | null = null;

  @observable
  logoFile: File | null = null;

  @observable
  logoPreviewPath: string | null = null;

  @observable
  pickedColor: string | null = null;

  @observable
  colorPalette: string[] = [];

  @action
  cleanUp = (): void => {
    this.brands = [];
    this.subbrands = [];
    this.selectedBrand = null;
    this.logoFile = null;
    this.logoPreviewPath = null;
    this.pickedColor = null;
    this.colorPalette = [];
  };

  @action
  setPickedColor = (color: string | null): void => {
    if (color === null) {
      return;
    }

    this.pickedColor = color;
  };

  @action
  setLogo = (file: File | null, path: string | null): void => {
    this.logoFile = file;
    this.logoPreviewPath = path;
  };

  initBrands = async (advertiserId: number | null): Promise<void> => {
    if (advertiserId === null) return;
    this.cleanUp();
    const response = await this.services.api.brands.list({ customerId: advertiserId });
    if (response.error) return;
    runInAction(() => {
      this.brands = response.data.content;
    });
  };

  selectBrand = async (brandId: number | null, replaceUrl: (path: string) => void): Promise<void> => {
    if (!this.brands.length) return;
    const selectedBrand = this.getBrandById(brandId);

    const response =
      selectedBrand !== null
        ? await this.services.api.subbrands.list({ brandId: selectedBrand.id })
        : { data: { content: [] }, error: null };
    if (response.error) return;

    runInAction(() => {
      this.selectedBrand = selectedBrand;
      this.subbrands = response.data.content;
      this.logoFile = null;
      this.colorPalette = [];
      this.logoPreviewPath = selectedBrand?.logoFileId ? getLogoSrc(selectedBrand.logoFileId) : null;
      this.pickedColor = selectedBrand?.color || null;
    });

    replaceUrl(`/administration/brands/${selectedBrand ? selectedBrand.id : ''}`);
  };

  getBrandById = (brandId: number | null): IBrand | null => {
    if (brandId === null) {
      const [selectedBrand] = this.brands;

      return selectedBrand;
    }

    return this.brands.find((brand) => brand.id === brandId) ?? null;
  };

  loadPalette = async (image: HTMLImageElement): Promise<void> => {
    try {
      const colorPalette = await colorThief.getPalette(image, 5);
      runInAction(() => {
        this.colorPalette = colorPalette.map((color: [number, number, number]) => chroma(color).hex());
      });
    } catch (error) {
      handleError(error, localize('errors.administration.brands.failed-getting-image-color'));
    }
  };

  renameSubbrand = async (name: string, subbrandId: number): Promise<void> => {
    if (this.selectedBrand === null) return;

    const response = await this.services.api.subbrands.updateName({ name, subbrandId });
    if (response.error) return;

    const subbrandListResponse = await this.services.api.subbrands.list({ brandId: this.selectedBrand.id });
    if (subbrandListResponse.error) return;

    runInAction(() => {
      this.subbrands = subbrandListResponse.data.content;
    });

    toast.success(localize('administration.brands.notify.subbrand-renamed'));
  };

  deleteSubbrand = async (subbrandId: number): Promise<void> => {
    if (this.selectedBrand === null) return;

    const response = await this.services.api.subbrands.delete({ subbrandId });
    if (response.error) return;

    const subbrandListResponse = await this.services.api.subbrands.list({ brandId: this.selectedBrand.id });
    if (subbrandListResponse.error) return;

    runInAction(() => {
      this.subbrands = subbrandListResponse.data.content;
    });

    toast.success(localize('administration.brands.notify.subbrand-deleted'));
  };

  @action
  resetBrand = (): void => {
    if (this.selectedBrand === null) return;

    const { logoFileId, color } = this.selectedBrand;
    const logoPreviewPath = logoFileId !== null ? getLogoSrc(logoFileId) : null;
    this.logoPreviewPath = logoPreviewPath;
    this.pickedColor = color;
    this.logoFile = null;
    this.colorPalette = [];
  };

  saveBrand = async (advertiserId: number | null): Promise<void> => {
    if (this.selectedBrand === null || advertiserId === null) {
      return;
    }

    const { id: brandId, color, logoFileId } = this.selectedBrand;
    const promises: { [key: string]: Promise<Response<IBrand>> } = {};

    if (this.pickedColor !== color && this.pickedColor !== null) {
      promises.brandWithNewColor = this.services.api.brands.updateColor({
        brandId,
        color: this.pickedColor,
      });
    }

    if (this.logoPreviewPath === null && logoFileId !== null) {
      promises.brandWithDeletedLogo = this.services.api.brands.removeLogo({ brandId });
    } else if (this.logoFile !== null) {
      const formData = new FormData();
      formData.append('file', this.logoFile);
      promises.brandWithNewLogo = this.services.api.brands.updateLogo({ brandId, formData });
    }

    if (Object.keys(promises).length === 0) {
      return;
    }

    const { brandWithNewColor, brandWithNewLogo, brandWithDeletedLogo } = await PromiseAll(promises);

    if (brandWithNewColor?.error || brandWithNewLogo?.error || brandWithDeletedLogo?.error) {
      return;
    }

    if (brandWithNewColor?.data) {
      toast.success(localize('administration.brands.notify.color-changed'));
    }

    if (brandWithNewLogo?.data) {
      toast.success(localize('administration.notify.logo-uploaded'));
    }

    if (brandWithDeletedLogo?.data) {
      toast.success(localize('administration.brands.notify.logo-deleted'));
    }

    const updatedLogoBrand = brandWithNewLogo?.data || brandWithDeletedLogo?.data;
    const updatedBrand: IBrand = {
      ...this.selectedBrand,
      color: brandWithNewColor?.data ? brandWithNewColor.data.color : this.selectedBrand.color,
      logoFileId: updatedLogoBrand ? updatedLogoBrand.logoFileId : this.selectedBrand.logoFileId,
    };

    const brandListResponse = await this.services.api.brands.list({ customerId: advertiserId });
    if (brandListResponse.error) return;

    runInAction(() => {
      this.brands = brandListResponse.data.content;
      this.selectedBrand = updatedBrand;
      this.logoFile = null;
      this.colorPalette = [];
    });
  };

  constructor() {
    super();
    makeObservable(this);
  }
}
