import * as R from 'ramda';
import { Row, SelectionState } from 'shared/components/other';
import { DataRow } from 'types/programmatic/excess';
import { setValueToAllTreeElements, calculateValueFromChildren } from '../helpers';

export function convertServerRowsToDataTree(serverRows: DataRow[], parentRow?: Row<DataRow>): Row<DataRow>[] {
  return serverRows.map((serverRow) => new TreeDataRow(serverRow, parentRow));
}

class TreeDataRow implements Row<DataRow> {
  data: DataRow;

  rowId: string;

  parent: Row<DataRow> | null;

  isLoading: boolean;

  isExpanded: boolean;

  children: Row<DataRow>[] | null;

  private selectionStateValue: SelectionState;

  constructor(serverRow: DataRow, parentRow?: Row<DataRow>) {
    const { children, ...data } = serverRow;
    this.data = parentRow ? R.mergeRight(parentRow.data, data) : data;
    this.rowId = !parentRow ? String(serverRow.id) : `${parentRow?.rowId}.${serverRow.id}`;
    this.parent = parentRow ?? null;
    this.isLoading = false;
    this.isExpanded = false;
    this.selectionStateValue = parentRow?.selectionState ?? false;
    this.children = serverRow.children ? convertServerRowsToDataTree(serverRow.children, this) : null;
  }

  set selectionState(value: SelectionState) {
    if (value === 'intermediate') return;
    if (this.children?.length) {
      setValueToAllTreeElements(this.children, 'selectionState', value);
    } else {
      this.selectionStateValue = value;
    }
  }

  get selectionState(): SelectionState {
    return this.children?.length
      ? calculateValueFromChildren(this.children, 'selectionState')
      : this.selectionStateValue;
  }

  get depth(): number {
    let depth = 0;
    const up = (row: Row<DataRow> | null) => {
      if (row?.parent) {
        depth += 1;
        up(row.parent);
      }
    };
    up(this);

    return depth;
  }
}
