import { CustomOptions, PageData } from 'api/types';
import { IPagingServerResponse } from 'types';
import { IAgency } from 'types/agency';
import BaseApi from '../BaseApi';

class Agencies extends BaseApi {
  list = ({ pageSize = 10000 }: PageData = {}, config: CustomOptions = {}) =>
    this.actions.get<IPagingServerResponse<IAgency[]>>({
      url: '/agency/',
      data: { pageSize },
      config,
    });
}

export default Agencies;
