import { CustomOptions } from 'api/types';
import { IStatisticFile, ITemplateFile } from 'types/mediafact';
import { IUploadedFile } from 'types/mediaplan';
import BaseApi from '../BaseApi';

class Mediafact extends BaseApi {
  list = ({ customerId }: CustomerData, config: CustomOptions = {}) =>
    this.actions.get<IStatisticFile[]>({
      url: '/mediafact/xlsx/all',
      data: { customerId },
      config,
    });

  getTemplate = ({ customerId }: CustomerData, config: CustomOptions = {}) =>
    this.actions.get<ITemplateFile>({
      url: '/mediafact/xlsx/template',
      data: { customerId },
      config,
    });

  uploadTemplate = (formData: FormData, config: CustomOptions = {}) =>
    this.actions.post({
      url: '/mediafact/xlsx/template/upload',
      data: formData,
      config,
    });

  upload = ({ customerId, formData }: UploadMediafactData, config: CustomOptions = {}) =>
    this.actions.post<IUploadedFile>({
      url: '/mediafact/xlsx/upload',
      queryParams: { customerId },
      data: formData,
      config,
    });

  delete = ({ customerId, mediafactManualFileId }: DeleteMediafactData, config: CustomOptions = {}) =>
    this.actions.delete({
      url: '/mediafact/xlsx/delete',
      queryParams: { customerId, mediafactManualFileId },
      config,
    });

  fixFact = ({ fixationPeriod, mediaplanId, campaignId }: FixFactData, config: CustomOptions = {}) =>
    this.actions.post({
      url: '/mediafact/fix-fact',
      queryParams: { fixationPeriod, mediaplanId, campaignId },
      config,
    });

  unfixFact = ({ mediaplanId }: Pick<FixFactData, 'mediaplanId'>, config: CustomOptions = {}) =>
    this.actions.post({
      url: '/mediafact/unfix-fact',
      queryParams: { mediaplanId },
      config,
    });
}

type CustomerData = { customerId: number };

type UploadMediafactData = {
  customerId: number;
  formData: FormData;
};

type DeleteMediafactData = {
  customerId: number;
  mediafactManualFileId: IStatisticFile['mediafactManualFileId'];
};

type FixFactData = {
  fixationPeriod: string;
  mediaplanId: number;
  campaignId: number;
};

export default Mediafact;
