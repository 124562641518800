import HT from 'handsontable';
import { Column } from '../../types';

export function makeBeforeChange(columns: Column[]) {
  return function beforeChange(tableChanges: HT.CellChange[] | null): void {
    if (!tableChanges) return;
    const hot = this as HT;

    tableChanges
      .filter(([, , prevValue, newValue]) => prevValue !== newValue)
      .forEach(([rowIndex, colName, prevValue, newValue], index) => {
        if (isRequiredNumericColumn(columns, colName) && isEmptyValue(newValue)) {
          const cellsMeta = hot.getCellMetaAtRow(hot.toPhysicalRow(rowIndex));
          const defaultValue = cellsMeta?.find((cell) => cell.prop === colName)?.defaultValue;
          if (defaultValue !== undefined) {
            // eslint-disable-next-line no-param-reassign
            tableChanges[index] = [rowIndex, colName, prevValue, defaultValue];
          }
        }
      });
  };
}

function isRequiredNumericColumn(columns: Column[], colName: string | number) {
  const column = columns.find((col) => col.name === String(colName));

  return column?.type === 'INTEGER' && column.isRequired;
}

function isEmptyValue(value: HT.CellValue) {
  return value === null || value === '';
}
