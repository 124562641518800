import { AggregatedTableData, AggregatedTreeData } from 'types/reportConstructor';

export const emptyPivotData: { tree: AggregatedTreeData; table: AggregatedTableData } = {
  tree: {
    result: {
      tree: [],
      data: [],
      rowData: [],
      columnData: [],
      marks: [],
    },
    header: {
      data: [],
      nonEmpty: [],
      meta: [],
    },
  },
  table: {
    result: {
      data: [],
      rowData: [],
      columnData: [],
      marks: [],
    },
    header: {
      data: [],
      nonEmpty: [],
      meta: [],
    },
  },
};
