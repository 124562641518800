import commonTemplates from './ru/commonTemplates.json';
import serverTemplates from './ru/serverTemplates.json';
import serverErrorTemplates from './ru/serverErrorTemplates.json';
import serverErrorTemplateParameters from './ru/serverErrorTemplateParameters.json';

const ru = Object.assign(commonTemplates, serverTemplates, serverErrorTemplates, serverErrorTemplateParameters);

const locales: { [key: string]: Partial<Record<string, string>> } = {
  ru,
};

export { errorLocales, errorParamLocales, errorsWithNotify } from './errorLocales';

export default locales;

export type LocaleIdType = keyof typeof ru;

export type LocaleStatusId = Extract<
  LocaleIdType,
  | 'campaigns.statuses.unconfirmed'
  | 'campaigns.statuses.confirmed'
  | 'campaigns.statuses.active'
  | 'campaigns.statuses.paused'
  | 'campaigns.statuses.completed'
  | 'campaigns.statuses.archived'
>;

export type LocaleFileStatusId = Extract<
  LocaleIdType,
  'campaign.file.statuses.current' | 'campaign.file.statuses.draft' | 'campaign.file.statuses.archive'
>;

export type LocaleMediaTypeId = Extract<
  LocaleIdType,
  'mediaTypes.web' | 'mediaTypes.tv' | 'mediaTypes.outdoor' | 'mediaTypes.radio' | 'mediaTypes.press'
>;

export type LocaleCurrencyId = Extract<LocaleIdType, 'currency.unit' | 'currency.thousand' | 'currency.million'>;

export type LocaleAdministrationId = Extract<
  LocaleIdType,
  | 'administration.advertiser'
  | 'administration.brands'
  | 'administration.appearance'
  | 'administration.powerbi-default-settings'
  | 'administration.powerbi-advertiser-settings'
>;

export type LocaleColorButtonId = Extract<
  LocaleIdType,
  | 'administration.brands.brand-color-label'
  | 'administration.advertiser.main-color'
  | 'administration.advertiser.background-color'
  | 'administration.appearance.background-color'
  | 'administration.appearance.main-text-color'
  | 'administration.appearance.additional-text-color'
>;
