import { Helmet } from 'react-helmet';
import React, { FC } from 'react';
import { LocaleIdType } from 'locales';
import { LocalizedMessage } from 'shared/components/other';

interface IProps {
  id: LocaleIdType;
}
const LocalizedTitle: FC<IProps> = ({ id }: IProps) => {
  return (
    <LocalizedMessage id={id}>
      {(localizedMessage: string) => <Helmet title={localizedMessage} defer={false} />}
    </LocalizedMessage>
  );
};

export default LocalizedTitle;
