import React from 'react';
import { HeaderRendererProps } from 'react-data-grid';
import { Column, GridRow } from '../types';
import { HeaderCell } from '../components';

export function headerRenderer<DR>(props: HeaderRendererProps<GridRow<DR>>): React.ReactElement | null {
  const column = props.column as Column<DR>;
  return (
    <HeaderCell value={column.name} columnKey={column.key} filter={column.filter} isSortable={column.isSortable} />
  );
}
