import React, { ComponentType, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react';

import hasAccess, { Page } from 'helpers/hasAccess';
import withSentryErrorBoundary from 'helpers/withSentryErrorBoundary';
import { useStores } from 'core';

type OwnProps = {
  checkableFeature?: Page;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<any>;
};

type Props = OwnProps & RouteProps;

const PrivateRouteContainer = observer(({ component: Component, checkableFeature, ...rest }: Props) => {
  const { authStore } = useStores();
  const { isAuthorized, isAuthorizing, checkAuth } = authStore;
  useEffect(() => {
    if (!isAuthorizing && !isAuthorized) {
      checkAuth();
    }
  }, [checkAuth, isAuthorized, isAuthorizing]);

  const renderRoute = (props: RouteComponentProps) => {
    const access = checkableFeature ? hasAccess(checkableFeature, 'pages') : true;
    if (isAuthorized && access) {
      return <Component {...props} />;
    }
    return (
      <Redirect
        to={{
          pathname: isAuthorized ? '/' : '/auth',
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={renderRoute} />;
});

export const PrivateRoute = withSentryErrorBoundary(PrivateRouteContainer);
