import React, { useCallback, useMemo } from 'react';
import { HotTable, HotTableProps } from '@handsontable/react';

import { useStores } from 'core';
import { NoDataFallback } from 'shared/components/other';
import {
  getColumnTitles,
  getColSettings,
  afterGetColHeader,
  afterLoadData,
  makeBeforeChange,
  makeAfterChange,
  makeAfterUpdateData,
} from './helpers';
import { Row, Column } from './types';
import { defaultProps } from './ReactHotTable.defaultProps';

import './style.scss';

type Props<R> = Omit<Partial<HotTableProps>, 'columns' | 'data' | 'className'> & {
  rows: Row<R>[];
  defaultRows: Row<R>[];
  columns: Column[];
  onChange: (data: Row<R>[]) => void;
  initTable: (hot: HotTable | null) => void;
};

const Table = <T,>(props: Props<T>) => {
  const { columns, rows, defaultRows, onChange, initTable, ...restProps } = props;
  const {
    globalLoaderStore: { isLoading },
  } = useStores();
  const noData = useMemo(() => rows.length === 0 && !isLoading, [rows, isLoading]);

  const colHeaders = useMemo(() => getColumnTitles(columns), [columns]);
  const hotColumns = useMemo(() => getColSettings(columns), [columns]);
  const beforeChange = useMemo(() => makeBeforeChange(columns), [columns]);
  const afterChange = useMemo(() => makeAfterChange(onChange), [onChange]);
  const afterUpdateData = useMemo(() => makeAfterUpdateData(onChange), [onChange]);
  const cells = useCallback(
    (row: number, _, colName) => ({
      defaultValue: defaultRows[row]?.[colName as keyof Row<T>],
    }),
    [defaultRows],
  );

  if (columns.length === 0) {
    return null;
  }

  return (
    <>
      <HotTable
        {...defaultProps}
        ref={initTable}
        data={rows}
        cells={cells}
        columns={hotColumns}
        colHeaders={colHeaders}
        afterGetColHeader={afterGetColHeader}
        afterLoadData={afterLoadData}
        beforeChange={beforeChange}
        afterChange={afterChange}
        afterUpdateData={afterUpdateData}
        height={noData ? defaultProps.columnHeaderHeight + 10 : defaultProps.height}
        {...restProps}
      />
      {noData && <NoDataFallback />}
    </>
  );
};

export const Component = React.memo(Table);

export * from './types';
