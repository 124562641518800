// eslint-disable-next-line import/prefer-default-export
export const KEY_CODES = {
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ENTER: 13,
  MINUS: 45,
  DASH: 189,
  ZERO: 47,
  NINE: 58,
  DOT: 46,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  BACKSPACE: 8,
  DELETE: 46,
};

export const MAX_NOTIFY_COUNT = 4;
export const MAX_ERROR_MSG_COUNT = 5;

export const DAY_TIMESTAMP = 86400000;
export const FIVE_MINUTES_TIMESTAMP = 300000;

export const MAX_CLIP_ID_LENGTH = 15;

export const MAX_PLACEMENT_IDS_COUNT = 100;

export const ONE_MB = 1024 * 1024;

export const nbsp = '\u00A0';
