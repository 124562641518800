import React, { ReactElement } from 'react';
import cx from 'classnames';
import { KEY_CODES } from 'helpers/constants';
import classes from './Checkbox.module.scss';
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

interface IOwnProps {
  name: string;
  onChange: (name: string, checked: boolean) => void;
  size?: 'large' | '';
  theme?: 'green' | 'blue';
}

export type IProps = IOwnProps & Pick<React.HTMLProps<HTMLInputElement>, 'checked' | 'disabled' | 'tabIndex'>;

const Checkbox: React.FC<IProps> = ({
  checked,
  tabIndex,
  name,
  onChange,
  size = '',
  theme = 'green',
  ...props
}): ReactElement | null => (
  <label
    className={cx(classes.Checkbox, {
      [classes.CheckboxLarge]: size === 'large',
    })}
    tabIndex={tabIndex}
    onKeyDown={(e: React.KeyboardEvent<HTMLLabelElement>) => {
      if (e.keyCode === KEY_CODES.ENTER) {
        onChange(name, Boolean(checked));
      }
    }}
  >
    <input
      className={classes.RealInput}
      name={name}
      type="checkbox"
      checked={Boolean(checked)}
      onChange={() => {
        onChange(name, Boolean(checked));
      }}
      {...props}
    />
    <div
      className={cx(classes.Box, {
        [classes.BoxChecked]: checked,
        [classes.BoxCheckedBlue]: checked && theme === 'blue',
      })}
    >
      <i className={cx(classes.CheckIcon, 'icon icon-check')} />
    </div>
  </label>
);

export default Checkbox;
