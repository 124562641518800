import cx from 'classnames';
import { confirmPopup } from 'primereact/confirmpopup';
import { localize } from 'shared/components/other';
import { buttonClasses } from 'shared/components/common/buttons/Button';

type Args = {
  target: HTMLElement;
  onAccept(): void;
};

export function confirmFilters({ target, onAccept }: Args): void {
  confirmPopup({
    target,
    accept: onAccept,
    icon: 'pi pi-info-circle',
    message: localize('programmatic.control-panel.row-selection-will-be-reset-after-filtering'),
    acceptLabel: localize('ok'),
    rejectLabel: localize('cancel'),
    acceptClassName: buttonClasses.root,
    rejectClassName: cx(buttonClasses.root, buttonClasses.lightTheme),
  });
}
