import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { SaveButtons } from 'shared/components/common/buttons/SaveButtons';
import { Select } from 'shared/components/common/form/Select';
import { Input, LocalizedMessage } from 'shared/components/other';
import { IOption } from 'types';
import { CreativeMediaType } from 'types/creative';
import classes from './CreativeModal.module.scss';

type Props = {
  isVisible: boolean;
  type: 'creating' | 'replacing' | null;
  defaultMediaType: CreativeMediaType;
  onSave(type: CreativeMediaType, name?: string): void;
  onClose(): void;
};

const mediaOptions: IOption<CreativeMediaType>[] = [
  { value: 'image', label: 'изображение' },
  { value: 'video', label: 'видео' },
];

const CreativeModal: React.FC<Props> = ({ isVisible, type, defaultMediaType, onSave, onClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState('');
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const [mediaType, setMediaType] = useState(defaultMediaType);
  const handleSelectChange = (value: CreativeMediaType) => {
    setMediaType(value);
  };

  const handleOpen = () => {
    inputRef.current?.focus();
  };

  const handleClose = () => {
    onClose();
    setName('');
    setMediaType(defaultMediaType);
  };

  const handleSave = () => {
    if (type === 'creating' && !name) return;

    onSave(mediaType, name.trim());
    handleClose();
  };

  useEffect(() => {
    setMediaType(defaultMediaType);
  }, [defaultMediaType]);

  return (
    <Dialog
      className={classes.root}
      visible={isVisible}
      draggable={false}
      closable={false}
      footer={<SaveButtons className={classes.footer} onSave={handleSave} onCancel={handleClose} />}
      onShow={handleOpen}
      onHide={handleClose}
    >
      {type === 'creating' && (
        <label className={classes.label}>
          <LocalizedMessage id="campaign-view.creative.enter-creative-name" />
          <Input inputRef={inputRef} value={name} maxLength={1000} onChange={handleInputChange} />
        </label>
      )}
      <label className={classes.label}>
        <LocalizedMessage id="campaign-view.creative.select-creative-type" />
        <Select
          options={mediaOptions}
          value={mediaType}
          onChange={handleSelectChange}
          optionValue="value"
          filter={false}
          appendTo={document.body}
        />
      </label>
    </Dialog>
  );
};

export { CreativeModal };
