import React, { FC } from 'react';
import { IBrandMediaTypeBudget } from 'types/brand';
import { MediaType } from 'types/campaign';
import cx from 'classnames';
import { formatCampaignSum } from 'helpers/utils';
import { LocalizedMessage } from 'shared/components/other';
import { LocaleMediaTypeId } from 'locales';

import classes from './MediaCard.module.scss';

interface IProps {
  type: string;
  mediaTypeBudget: IBrandMediaTypeBudget | undefined;
}

const MediaCard: FC<IProps> = (props: IProps) => {
  const { type, mediaTypeBudget } = props;

  const budgetPlan = mediaTypeBudget !== undefined ? mediaTypeBudget.budgetPlan : 0;
  const { value: budgetValue, unitId } = formatCampaignSum(budgetPlan);

  const localizeMediaTypeId: LocaleMediaTypeId = `mediaTypes.${type.toLocaleLowerCase()}` as LocaleMediaTypeId;

  return (
    <div
      className={cx(
        classes.MediaCard,
        {
          [classes.MediaCardBlank]: budgetPlan === 0,
        },
        {
          [classes.MediaCardWeb]: type === MediaType.WEB,
          [classes.MediaCardTV]: type === MediaType.TV,
          [classes.MediaCardOutdoor]: type === MediaType.OUTDOOR,
          [classes.MediaCardRadio]: type === MediaType.RADIO,
          [classes.MediaCardPress]: type === MediaType.PRESS,
        },
      )}
    >
      <div
        className={cx(classes.Icon, 'icon', {
          'icon-globe': type === MediaType.WEB,
          'icon-tv': type === MediaType.TV,
          'icon-tv2': type === MediaType.OUTDOOR,
          'icon-radio': type === MediaType.RADIO,
          'icon-paper': type === MediaType.PRESS,
        })}
      />
      <div className={classes.Value}>{budgetValue.toLocaleString()}</div>
      <div className={classes.Currency}>
        <LocalizedMessage id={unitId} />
      </div>
      <div className={classes.ActiveCampaignsCount}>
        <div>
          <LocalizedMessage id="mediaTypes.activeCampaignsCount" />
        </div>
        <div className={classes.NumberCount}>
          {mediaTypeBudget ? `${mediaTypeBudget.activeCampaigns}/${mediaTypeBudget.totalCampaigns}` : '0/0'}
        </div>
      </div>
      <div className={classes.MediaType}>
        <LocalizedMessage id={localizeMediaTypeId} />
      </div>
    </div>
  );
};

export default MediaCard;
