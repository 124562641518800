import { omit } from 'lodash';

import { ClientRow, ServerRow } from 'types/tradingDesk/adfox';
import { ReactHotTable } from 'shared/components/other';
import { convertDictionaryValue } from './convertDictionaryValue';

export function convertToClientRow(row: ServerRow, columns: ReactHotTable.Column[]): ClientRow {
  return {
    ...convertRowValues(omit(row, '__typename'), columns),
    isSelected: false,
    errors: [],
  };
}

function convertRowValues(row: ServerRow, columns: ReactHotTable.Column[]): ClientRow {
  return Object.entries(row).reduce((acc, [columnName, val]) => {
    const value = getCellValue(val, columnName, columns);

    return { ...acc, [columnName]: value };
  }, {} as ClientRow);
}

export function convertToServerRow<T extends ClientRow>(
  row: T,
  defaultRows: T[],
  columns: ReactHotTable.Column[],
): Partial<ServerRow> {
  const defaultCampaignRow = defaultRows.find((r) => r.id === row.id);

  return Object.entries(row).reduce((acc, [columnName, value]) => {
    const cell = row[columnName as keyof T];
    const defaultCell = defaultCampaignRow?.[columnName as keyof T];
    if (['errors', 'isSelected'].includes(columnName) || (cell === defaultCell && columnName !== 'id')) {
      return acc;
    }

    return { ...acc, [columnName]: getCellValue(value, columnName, columns) };
  }, {} as Partial<ServerRow>);
}

function getCellValue(value: unknown, columnName: string, columns: ReactHotTable.Column[]) {
  const column = columns.find((c) => c.name === columnName);

  if (column?.name === 'id') {
    return Number(value);
  }

  if (ReactHotTable.isDictionaryColumn(column) && ReactHotTable.isDictionaryValue(column, value)) {
    return convertDictionaryValue(column.source, value) ?? value;
  }

  return value;
}
