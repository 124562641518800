import { ApolloLink } from '@apollo/client';
import { sharedStores } from 'shared/stores';

export const trackingLoadingLink = new ApolloLink((operation, forward) => {
  sharedStores.globalLoaderStore.startLoading();

  return forward(operation).map((data) => {
    sharedStores.globalLoaderStore.stopLoading();

    return data;
  });
});
