import React, { useCallback } from 'react';
import { ChromePicker, RGBColor, ColorResult } from 'react-color';
import classes from './ColorPicker.module.scss';

type Props = {
  /** Picked color. Default: '#fff' */
  color?: RGBColor;
  /** On color change event handler */
  onChange(color: RGBColor): void;
};

const styles = {
  default: {
    picker: {
      boxShadow: 'none',
      fontFamily: 'inherit',
    },
  },
};

export const ColorPicker: React.VFC<Props> = (props) => {
  const { color = '#fff', onChange } = props;

  const handleColorPickerChange = useCallback(
    (value: ColorResult) => {
      onChange(value.rgb);
    },
    [onChange],
  );

  return (
    <div className={classes.root}>
      <ChromePicker color={color} onChange={handleColorPickerChange} styles={styles} />
    </div>
  );
};

export type { Props as ColorPickerProps };
