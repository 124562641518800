import { ApolloClient, InMemoryCache, from } from '@apollo/client';

import { authLink, errorLink, retryLink, trackingLoadingLink } from './links';

export const client = new ApolloClient({
  link: from([trackingLoadingLink, errorLink, retryLink, authLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  queryDeduplication: false,
});
