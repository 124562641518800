import cx from 'classnames';
import React from 'react';

import { Input, localize, LocalizedMessage } from 'shared/components/other';
import { Tooltip } from 'primereact/tooltip';

import classes from './ReportNameInput.module.scss';

type Props = {
  value: string;
  disabled: boolean;
  invalid: boolean;
  className?: string;
  onChange(newValue: string): void;
};

export const ReportNameInput: React.FC<Props> = ({ value, disabled, invalid, className, onChange }) => {
  return (
    <div className={cx(classes.field, className)}>
      <label htmlFor="report-name-input" className={cx(classes.label, { [classes.labelDisabled]: disabled })}>
        <LocalizedMessage id="report-constructor.modal.report-name" />
      </label>
      {invalid && <Tooltip target="#report-name-input" position="top" />}
      <Input
        id="report-name-input"
        containerClassName={classes.input}
        value={value}
        disabled={disabled}
        hasError={invalid}
        data-pr-tooltip={localize('report-constructor.modal.tooltips.report-name-incorrect-length')}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
