import { action, makeObservable, observable, runInAction, reaction, computed } from 'mobx';

import { BaseStore } from 'shared/stores';
import { localize } from 'shared/components/other';
import hasAccess from 'helpers/hasAccess';
import showConfirmation from 'helpers/showConfirmation';
import { EmptyObject } from 'types';
import { IBrand } from 'types/brand';
import { ICampaign, UploadedStatus } from 'types/campaign';
import { ISubbrand } from 'types/subbrand';
import { MediaplanType } from 'types/mediaplan';
import { WebMediaplansStore } from './WebMediaplans.store';
import { TvMediaplansStore } from './TvMediaplans.store';
import { CreativesStore } from './Creatives.store';

type ChildrenStores = {
  web: WebMediaplansStore;
  tv: TvMediaplansStore;
  creatives: CreativesStore;
};

export class CampaignViewStore extends BaseStore<EmptyObject, ChildrenStores> {
  private _campaignId: number | null = null;

  @observable
  private _campaign: ICampaign | null = null;

  @observable
  private _uploadedStatus: UploadedStatus | null = null;

  @observable
  private _areMediaplansLoaded = false;

  @observable
  private _currentMediaplanTypeTab: MediaplanType | null = null;

  @observable
  private brands: IBrand[] = [];

  @observable
  private subbrands: ISubbrand[] = [];

  @observable
  private isActive = false;

  @observable
  private hasAccess?: boolean;

  @computed
  get campaignId(): number | null {
    return this._campaignId;
  }

  @computed
  get campaign(): ICampaign | null {
    return this._campaign;
  }

  @computed
  get uploadedStatus(): UploadedStatus | null {
    return this._uploadedStatus;
  }

  @computed
  get areMediaplansLoaded(): boolean {
    return this._areMediaplansLoaded;
  }

  @computed
  get currentMediaplanTypeTab(): MediaplanType | null {
    return this._currentMediaplanTypeTab;
  }

  @computed
  private get mediaplanStore(): TvMediaplansStore | WebMediaplansStore | null {
    return this._currentMediaplanTypeTab && this.childrenStores[this._currentMediaplanTypeTab];
  }

  @computed
  get brand(): IBrand | null {
    return this.brands.find((b) => b.id === this.campaign?.brands[0]) ?? null;
  }

  @computed
  get subbrand(): ISubbrand | null {
    return this.subbrands.find((s) => s.id === this.campaign?.subbrands[0]) ?? null;
  }

  @computed
  get advertiserId(): number | null {
    if (!this.isActive) {
      return null;
    }

    return this.deps.advertiserStore.currentAdvertiserId;
  }

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      web: new WebMediaplansStore({ campaignViewStore: this }),
      tv: new TvMediaplansStore({ campaignViewStore: this }),
      creatives: new CreativesStore({ campaignViewStore: this }),
    };

    reaction(
      () => [this.advertiserId, this.hasAccess],
      () => {
        if (this.hasAccess !== undefined && this.advertiserId !== this.campaign?.advertiser) {
          this.deps.routingStore.history.push('/campaigns');
        }
      },
    );
  }

  @action
  setCurrentMediaplanTypeTab = (type: MediaplanType): void => {
    this._currentMediaplanTypeTab = type;
  };

  @action
  setUploadedStatus = (status: UploadedStatus | null): void => {
    this._uploadedStatus = status;
  };

  @action
  activate(campaignId: number | null): void {
    this.isActive = true;
    this._campaignId = campaignId;
  }

  @action
  cleanUp = (): void => {
    this._campaign = null;
    this._campaignId = null;
    this._areMediaplansLoaded = false;
    this._currentMediaplanTypeTab = null;
    this.isActive = false;
    this.hasAccess = undefined;
    this.brands = [];
    this.subbrands = [];
    this.childrenStores.web.cleanUp();
    this.childrenStores.tv.cleanUp();
  };

  @action
  loadData = async (): Promise<void> => {
    await this.loadCampaign();

    runInAction(() => {
      this.hasAccess = this.checkCampaignAccess();
    });

    if (!this.hasAccess) return;

    await this.loadGeneralData();

    if (hasAccess('webTab', 'campaignPage')) {
      await this.childrenStores.web.loadMediaplansCount();
    }
    if (hasAccess('tvTab', 'campaignPage')) {
      await this.childrenStores.tv.loadMediaplansCount();
    }

    const hasAccessToWebMediaplans = hasAccess('webMediaplansTab', 'campaignPage');
    const hasAccessToTvMediaplans = hasAccess('tvMediaplansTab', 'campaignPage');

    if (hasAccessToWebMediaplans) {
      await this.childrenStores.web.loadMediaplans();
    }
    if (hasAccessToTvMediaplans) {
      await this.childrenStores.tv.loadMediaplans();
    }

    runInAction(() => {
      this._areMediaplansLoaded = true;
      if (!hasAccessToWebMediaplans && !hasAccessToTvMediaplans) {
        this._currentMediaplanTypeTab = null;
      } else {
        this._currentMediaplanTypeTab = hasAccessToWebMediaplans ? 'web' : 'tv';
      }
    });
  };

  downloadExcelPivot = async (): Promise<void> => {
    if (this.campaign === null) return;

    this.services.api.campaigns.downloadPivot({
      campaignId: this.campaign.id,
      fileName: `${this.campaign?.name ?? 'download'}.zip`,
    });
  };

  toggleCampaignFavorite = async (): Promise<void> => {
    if (this.campaign === null) return;

    const { id: campaignId, name: campaignName, favorite } = this.campaign;
    const response = await this.services.api.campaigns.setFavorite({ campaignId, campaignName, favorite: !favorite });
    if (response.error) return;

    runInAction(() => {
      if (this._campaign) {
        this._campaign = { ...this._campaign, favorite: !favorite };
      }
    });
  };

  renameMediaplan = async (prevName: string): Promise<void> => {
    const newName = await showConfirmation({
      title: localize('campaign-view.mediaplan.rename-file'),
      input: { value: prevName, maxLength: 1000 },
      confirmTextId: 'button.save',
    });

    if (newName && this.mediaplanStore) {
      this.mediaplanStore.renameMediaplan(newName);
    }
  };

  removeMediaplan = async (versionId: number | null): Promise<void> => {
    const localizedTitleId = versionId
      ? 'campaign-view.mediaplan.remove-file'
      : 'campaign-view.mediaplan.remove-campaign';

    const isConfirmed = await showConfirmation({
      title: `${localize(localizedTitleId)}?`,
      type: 'removal',
    });

    if (isConfirmed && this.mediaplanStore) {
      this.mediaplanStore.removeMediaplan(versionId);
    }
  };

  private loadCampaign = async (): Promise<void> => {
    if (this.campaignId === null) return;

    const response = await this.services.api.campaigns.get({ campaignId: this.campaignId });
    runInAction(() => {
      this._campaign = response.error ? null : response.data;
    });

    if (response.error) {
      this.deps.routingStore.history.push('/campaigns');
    }
  };

  private loadGeneralData = async (): Promise<void> => {
    if (this.advertiserId === null || this.campaign === null) return;

    const [brands, subbrands] = await Promise.all([
      this.services.api.brands.list({ customerId: this.advertiserId }),
      this.services.api.subbrands.list({ brandId: this.campaign.brands[0] }),
    ]);
    if (brands.error || subbrands.error) return;

    runInAction(() => {
      this.brands = brands.data.content;
      this.subbrands = subbrands.data.content;
    });
  };

  private checkCampaignAccess = (): boolean => {
    const {
      campaign,
      deps: {
        advertiserStore: { advertisers, selectAdvertiser },
      },
    } = this;

    if (campaign === null) {
      return false;
    }

    if (this.advertiserId !== campaign.advertiser) {
      const foundAdvertiser = advertisers.find((a) => a.id === campaign.advertiser);

      if (foundAdvertiser === undefined || !selectAdvertiser) {
        return false;
      }

      selectAdvertiser(foundAdvertiser.id, true);
    }

    return true;
  };
}
