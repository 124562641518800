import { DictionaryIdMap } from 'types/dictionary';

export const ADFOX_CAMPAIGNS_PAGE_ID = 'campaign-control-adfox';
export const ADFOX_BANNERS_PAGE_ID = 'banner-control-adfox';
export const ADFOX_CABINET_ID = 4;
export const ADFOX_API_ACCOUNT_ID = 267;
export const ERROR_DICTIONARY_ID = 123;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const CAMPAIGN_LOG_DICTIONARIES_MAP: DictionaryIdMap = {
  fieldName: { dictionaryId: 124 },
  oldValue: { dictionaryId: 122, replaceIf: (x) => x.fieldName === 'status' },
  newValue: { dictionaryId: 122, replaceIf: (x) => x.fieldName === 'status' },
};
export const BANNER_LOG_DICTIONARIES_MAP: DictionaryIdMap = {
  fieldName: { dictionaryId: 125 },
  oldValue: { dictionaryId: 122, replaceIf: (x) => x.fieldName === 'status' },
  newValue: { dictionaryId: 122, replaceIf: (x) => x.fieldName === 'status' },
};
