import React from 'react';
import shallowequal from 'shallowequal';
import { HotTable } from '@handsontable/react';
import { omit } from 'lodash';

import { ReactHotTable } from 'shared/components/other';
import { AdfoxBanner } from 'types/tradingDesk/adfox';
import { DATE_FORMAT } from '../../../helpers';

type Props = {
  columns: ReactHotTable.Column[];
  banners: AdfoxBanner[];
  defaultBanners: AdfoxBanner[];
  onChange: (data: AdfoxBanner[]) => void;
  initTable: (hot: HotTable | null) => void;
};

const Component: React.FC<Props> = (props) => {
  const { columns, banners, defaultBanners, onChange, initTable } = props;

  return (
    <ReactHotTable.Component
      rows={banners}
      defaultRows={defaultBanners}
      columns={columns}
      onChange={onChange as never}
      initTable={initTable}
      dateFormat={DATE_FORMAT}
      columnSorting={{
        sortEmptyCells: true,
        initialConfig: {
          column: columns.findIndex((c) => c.name === 'name'),
          sortOrder: 'asc',
        },
      }}
      rowHeights={46}
      columnHeaderHeight={60}
    />
  );
};

export const Table = React.memo(Component, (prevProps, nextProps) => {
  return shallowequal(omit(prevProps, ['banners']), omit(nextProps, ['banners']));
});
