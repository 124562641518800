import React from 'react';
import cx from 'classnames';
import { LocalizedMessage } from 'shared/components/other';
import { IFile } from 'types/mediaplan';
import { WebMediaplanFile, WebMediaplanFileProps } from './components/WebMediaplanFile';
import classes from './WebMediaplanFilesTable.module.scss';

type Props = {
  className?: string;
  files: IFile[];
} & Omit<WebMediaplanFileProps, 'file'>;

export const WebMediaplanFilesTable: React.VFC<Props> = (props) => {
  const { className, files, ...restProps } = props;

  return (
    <table className={cx(classes.root, className)}>
      <thead className={classes.header}>
        <tr>
          <td>
            <LocalizedMessage id="campaign.versions.name" />
          </td>
          <td>
            <LocalizedMessage id="campaign.versions.status" />
          </td>
          <td>
            <LocalizedMessage id="campaign.versions.created-date" />
          </td>
          <td />
        </tr>
      </thead>
      {files.map((file) => (
        <React.Fragment key={file.fileId}>
          <WebMediaplanFile file={file} {...restProps} />
          <tbody>
            <tr>
              <td className={classes.gap} />
            </tr>
          </tbody>
        </React.Fragment>
      ))}
    </table>
  );
};

export type { Props as WebMediaplansTableProps };
