import { action, makeObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { convertToDate } from 'helpers/utils';
import handleError from 'helpers/handleError';
import showConfirmation from 'helpers/showConfirmation';
import { localize, localizeMessage } from 'shared/components/other';
import { BaseStore } from 'shared/stores';
import { LocaleIdType } from 'locales';
import { IError, IUserErrorMessage } from 'types';
import { MediaplanData, MediaType } from 'types/campaign';
import { CampaignEditStore, FormValues } from './CampaignEdit.store';

type Deps = {
  campaignEditStore: CampaignEditStore;
};

export class CampaignAutoCreateStore extends BaseStore<Deps> {
  @observable
  autoCreatedValues: Partial<FormValues> | null = null;

  @observable
  mediaplanData: MediaplanData = {
    s3FileId: null,
    name: null,
    sheetOptions: [],
    sheetValue: null,
  };

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
  }

  @action
  cleanUp = (): void => {
    this.autoCreatedValues = null;
    this.resetMediaplanData();
  };

  @action
  updateMediaplanData = (data: Partial<MediaplanData>): void => {
    this.mediaplanData = { ...this.mediaplanData, ...data };
  };

  @action
  resetMediaplanData = (): void => {
    this.mediaplanData = {
      s3FileId: null,
      name: null,
      sheetOptions: [],
      sheetValue: null,
    };
  };

  handleMediafileUpload = async (formData: FormData, mediaplanName: string): Promise<void> => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (!advertiserId) return;

    const response = await this.services.api.webMediaplans.getExcelSheets({
      customerId: advertiserId,
      formData,
    });
    if (response.error) return;

    const [[s3FileId, sheets]] = Object.entries(response.data);
    const [sheetList] = Object.values(sheets);
    const sheetOptions = sheetList.map((s) => ({
      value: s.sheetId,
      label: s.sheetName,
    }));

    runInAction(() => {
      this.updateMediaplanData({
        s3FileId,
        name: mediaplanName,
        sheetOptions,
        sheetValue: null,
      });
    });
  };

  @action
  handleSheetValueChange = (value: number): void => {
    this.updateMediaplanData({ sheetValue: value });
  };

  autoCreateCampaign = async (): Promise<void> => {
    const { s3FileId, name, sheetValue } = this.mediaplanData;

    if (s3FileId === null || name === null) {
      toast.error(localize('campaign-edit.auto-creating.warnings.file-not-loaded'));
      return;
    }
    if (sheetValue === null) {
      toast.error(localize('campaign-edit.auto-creating.warnings.sheet-not-selected'));
      return;
    }

    await this.setAutoCreatedValues();
    this.resetMediaplanData();
    this.deps.campaignEditStore.setSavedMediaplanData({ name, s3FileId });
  };

  private setAutoCreatedValues = async (): Promise<void> => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    const { brands, mediaTypes, agencies, categories, loadSubbrandList } = this.deps.campaignEditStore;
    const { s3FileId, sheetValue } = this.mediaplanData;

    if (advertiserId === null || s3FileId === null || sheetValue === null) {
      return;
    }

    const response = await this.services.api.webMediaplans.getCampaignInfo({
      customerId: advertiserId,
      fileS3Id: s3FileId,
      sheetId: sheetValue,
    });
    if (response.error) return;

    const {
      agencyId,
      brandId,
      subBrand,
      subBrandId,
      category,
      categoryId,
      campaign,
      dateStart,
      dateEnd,
      totalMediaBudget,
      userErrorMessageList,
    } = response.data;

    this.validateCampaignInfo(userErrorMessageList);

    const brand = brands.find((b) => brandId && b.value === Number(brandId)) || null;

    if (brand) {
      await loadSubbrandList(brand.value);
    }

    const { subbrands, setMetrics } = this.deps.campaignEditStore;

    const currentSubbrandId =
      subbrands.find((s) => subBrandId && s.value === Number(subBrandId))?.value ??
      (await this.addOption('subbrand', subBrand, brand?.value));
    const currentCategoryId =
      categories.find((c) => categoryId && c.value === Number(categoryId))?.value ??
      (await this.addOption('category', category));

    const metric = mediaTypes.find((m) => m.type === MediaType.WEB);
    const convertedMetric = metric && totalMediaBudget ? [{ ...metric, budgetValue: totalMediaBudget }] : [];

    runInAction(() => {
      setMetrics(convertedMetric);
      this.autoCreatedValues = {
        name: campaign,
        agency: agencies.find((a) => agencyId && a.value === Number(agencyId))?.value || null,
        brand: brand?.value || null,
        subbrand: currentSubbrandId,
        category: currentCategoryId,
        startDate: dateStart ? convertToDate(dateStart) : null,
        endDate: dateEnd ? convertToDate(dateEnd) : null,
      };
    });
  };

  private addOption = async (
    name: 'subbrand' | 'category',
    value: string | null,
    brandId?: number,
  ): Promise<number | null> => {
    if (value !== null) {
      const isConfirmed = await showConfirmation({
        title: `${localizeMessage({ id: `campaign-edit.modal.add-new-${name}` as LocaleIdType }, { name: value })}?`,
        confirmTextId: 'button.save',
      });

      if (isConfirmed) {
        const { addSubbrand, addCategory } = this.deps.campaignEditStore;
        const id = await (name === 'subbrand' ? addSubbrand(value, brandId ?? null) : addCategory(value));
        return id;
      }
    }

    return null;
  };

  private validateCampaignInfo = (userErrorMessageList: IUserErrorMessage[] | null): void => {
    if (!userErrorMessageList || !userErrorMessageList.length) {
      return;
    }

    const hasFewErrors = userErrorMessageList.length > 1;
    const introMsg = localize('errors.card-autofill-is-incomplete');
    const error: IError = new Error(introMsg);
    error.userErrorMessageList = userErrorMessageList;

    handleError(error, null, hasFewErrors ? introMsg : null);
  };
}
