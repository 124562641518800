import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import zipObject from 'lodash/zipObject';

import { LocaleCurrencyId, LocaleStatusId, LocaleFileStatusId, LocaleIdType } from 'locales';
import { CampaignStatus, MediaType } from 'types/campaign';
import { IFileStatus, IFile, IVersion } from 'types/mediaplan';

import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';

interface IFormatDate {
  date: Date | string | null;
  isFullYear?: boolean;
  isReverseOrder?: boolean;
  hasTime?: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IPromises {
  [key: string]: Promise<any> | IPromises;
}

type PromisedResult<T> = T extends Promise<infer B> ? B : unknown;

type IPromisesResult<T extends IPromises> = {
  [key in keyof T]: PromisedResult<T[key]>;
};

export * from './convertToOptions';
export * from './isNotNull';

export const wait = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const PromiseAll = <T extends IPromises>(obj: T): Promise<IPromisesResult<IPromises>> => {
  const keys = Object.keys(obj);

  return Promise.all(
    keys.map((key) => {
      const value = obj[key];

      if (typeof value === 'object' && !value.then) {
        return PromiseAll(value as IPromises);
      }

      return value;
    }),
  ).then((result) => zipObject(keys, result));
};

export const formatDate = ({
  date: originalDate,
  isFullYear = false,
  hasTime = false,
  isReverseOrder = false,
}: IFormatDate): string => {
  if (originalDate === null) {
    return '';
  }
  const date = new Date(originalDate);
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yy = isFullYear ? date.getFullYear() : date.getFullYear() % 100;
  const hh = date.getHours();
  const min = date.getMinutes();
  const format = (val: number): number | string => (val < 10 ? `0${val}` : val);

  const time = hasTime ? ` ${format(hh)}:${format(min)}` : '';

  return isReverseOrder
    ? `${format(yy)}-${format(mm)}-${format(dd)}`
    : `${format(dd)}.${format(mm)}.${format(yy)}${time}`;
};

export const getStartDate = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const getEndDate = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

export const convertDateToStr = (date: Date | string | number | null): string => {
  if (date === null) {
    return '';
  }

  const d = new Date(date);
  const dd = d.getDate();
  const mm = d.getMonth() + 1;
  const yy = d.getFullYear();
  const format = (val: number): number | string => (val < 10 ? `0${val}` : val);

  return `${format(yy)}-${format(mm)}-${format(dd)}`;
};

export const convertToDate = (date: string | Date | null): Date | null => (date ? new Date(date) : null);

export const formatCampaignSum = (sum: number): { value: number; unitId: LocaleCurrencyId } => {
  if (sum >= 1000000) {
    return {
      value: Math.round((sum / 1000000) * 10) / 10,
      unitId: 'currency.million',
    };
  }

  if (sum < 1000) {
    return {
      value: Math.round(sum),
      unitId: 'currency.unit',
    };
  }

  return {
    value: Math.round((sum / 1000) * 10) / 10,
    unitId: 'currency.thousand',
  };
};

export const roundCampaignBudget = (num: number): { value: number; unitId: LocaleCurrencyId } => {
  if (num >= 10000000) {
    return {
      value: Math.round(num / 1000000),
      unitId: 'currency.million',
    };
  }

  return {
    value: Math.round(num / 1000),
    unitId: 'currency.thousand',
  };
};

export const capitalizeFirstLetter = (sourceString: string): string =>
  sourceString.charAt(0).toUpperCase() + sourceString.slice(1).toLowerCase();

export const normalizeStr = (value: string): string => value.trim().toLowerCase();

export const getWeekDayNormalized = (date: Date): number => {
  const dayOfWeek = date.getDay();

  return dayOfWeek > 0 ? dayOfWeek - 1 : 6;
};

/**
 * getWeekOfYear() returns the week number of the date in the date year.
 * Years always start with week 1. Number 8.64e7 refers to milliseconds per day.
 */
export const getWeekOfYear = (date: Date): number => {
  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0);
  normalizedDate.setDate(date.getDate() + 4 - getWeekDayNormalized(date));

  const januaryFirst = new Date(date.getFullYear(), 0, 1);
  const yearStartsAfterFriday = getWeekDayNormalized(januaryFirst) > 3;
  const dateNumber = (normalizedDate.valueOf() - januaryFirst.valueOf()) / 8.64e7;

  return Math.ceil(dateNumber / 7) + Number(yearStartsAfterFriday);
};

export const checkChanges = <T, K extends keyof T>(first: T, second: T, props: K[]): boolean =>
  props.some((prop) => first[prop] !== second[prop]);

type SortInput = string | number | Date | null;

export const sortByOrder = (a: SortInput, b: SortInput, order: 'ASC' | 'DESC' = 'ASC'): number => {
  if (typeof a === 'string' && typeof b === 'string') {
    return order === 'ASC' ? a.trim().localeCompare(b.trim()) : b.trim().localeCompare(a.trim());
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return order === 'ASC' ? a - b : b - a;
  }

  if (a instanceof Date && b instanceof Date) {
    return order === 'ASC' ? a.getTime() - b.getTime() : b.getTime() - a.getTime();
  }

  return 0;
};

export const sortByFileExtension = <T extends IFile | IVersion>(files: T[]): T[] =>
  files.sort((a: T, b: T) => {
    const splitA: string[] = a.fileName.split('.');
    const splitB: string[] = b.fileName.split('.');
    const extensionA = splitA[splitA.length - 1];
    const extensionB = splitB[splitB.length - 1];

    if (a.status === IFileStatus.CURRENT) {
      return -1;
    }
    if (b.status === IFileStatus.CURRENT) {
      return 1;
    }

    return sortByOrder(extensionA, extensionB);
  });

export const getStatusLocaleId = <T extends CampaignStatus | IFileStatus>(
  status: T,
  localeName = 'campaigns',
): LocaleIdType =>
  `${localeName}.statuses.${status?.toLowerCase()}` as T extends CampaignStatus ? LocaleStatusId : LocaleFileStatusId;

export const getLocalizedMediaType = (mediaType: MediaType): string =>
  localize(`campaign.type.${mediaType}` as LocaleIdType);

export const openPopup = (url: string, callback: () => Promise<void>): Promise<void> =>
  new Promise((res, rej) => {
    const popup = window.open(url, '_blank');

    if (popup === null) {
      rej();

      return;
    }

    popup.focus();

    const popupTick = setInterval(async () => {
      if (popup.closed) {
        clearInterval(popupTick);
        try {
          await callback();
          res();
        } catch (e) {
          rej(e);
        }
      }
    }, 500);
  });

export const validateEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email);

export const downloadFile = (response: AxiosResponse<Blob>, filename?: string): void => {
  const blob = response.data;
  const contentDisposition = response.headers['content-disposition'];
  const fileNameData = contentDisposition?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  const fileName = fileNameData
    ? decodeURIComponent(fileNameData[1].replace(/['"]+/g, '').replace(/\+/g, ' '))
    : filename;

  saveAs(blob, fileName);
  toast.success(localize('messages.file-downloaded-successfully'));
};
