import cx from 'classnames';
import { observer } from 'mobx-react';
import { ListBox } from 'primereact/listbox';
import React, { useMemo } from 'react';

import { useStores } from 'core';

import { ReportNameInput } from '../ReportNameInput';
import classes from './Columns.module.scss';

export const Columns: React.FC = observer(() => {
  const { reportConstructorStore } = useStores();
  const {
    reportName,
    isReportNameValid,
    mode,
    columns,
    selectedAttributes,
    selectedMetrics,
    selectColumns,
    setReportName,
  } = reportConstructorStore;
  const readOnly = useMemo(() => mode === 'view', [mode]);

  return (
    <div className={classes.root}>
      <ReportNameInput
        value={reportName}
        disabled={readOnly}
        invalid={!isReportNameValid}
        className={classes.input}
        onChange={setReportName}
      />
      <div className={classes.columns}>
        <div className={classes.column}>
          <ListBox
            className={cx(classes.listbox, { [classes.listboxDisabled]: readOnly })}
            multiple
            filter
            value={selectedAttributes.map(({ value }) => value)}
            options={columns.attributes}
            onChange={(ids) => selectColumns(ids.value, 'attributes')}
          />
        </div>
        <div className={classes.column}>
          <ListBox
            className={cx(classes.listbox, { [classes.listboxDisabled]: readOnly })}
            multiple
            filter
            value={selectedMetrics.map(({ value }) => value)}
            options={columns.metrics}
            onChange={(ids) => selectColumns(ids.value, 'metrics')}
          />
        </div>
      </div>
    </div>
  );
});
