import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'core';
import { SearchInput } from 'shared/components/common/form/SearchInput';
import { MultiSelect, MultiSelectChangeParams } from 'shared/components/common/form/MultiSelect';
import { ClearButton } from 'shared/components/common/buttons/ClearButton';
import { ServerModels as SM } from 'types/server';
import { confirmFilters } from '../../../../helpers';

import classes from './Filters.module.scss';

export const Filters = observer(() => {
  const {
    excessStore: {
      clients,
      cabinets,
      campaignStatuses,
      hasSelectedRows,
      campaignFilters,
      campaignFilters: { advertiserIds, cabinetIds, campaignStatus, name, csId },
      setCampaignFilters,
      clearCampaignFilters,
    },
  } = useStores();

  const applyFilters = (value: SM.CampaignFilters, target?: HTMLElement) => {
    if (hasSelectedRows && target) {
      confirmFilters({
        target,
        onAccept: () => setCampaignFilters(value),
      });
    } else {
      setCampaignFilters(value);
    }
  };

  const clearFilters = (target: HTMLElement) => {
    if (hasSelectedRows) {
      confirmFilters({
        target,
        onAccept: clearCampaignFilters,
      });
    } else {
      clearCampaignFilters();
    }
  };

  const handleClientChange = (e: MultiSelectChangeParams) => {
    applyFilters({ advertiserIds: e.value?.length ? e.value : null }, e.originalEvent.currentTarget as HTMLElement);
  };

  const applyCampaignNameFilter = (
    value: string | null,
    e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    applyFilters({ name: value }, e?.currentTarget);
  };

  const handleCampaignNameClear = (e: React.MouseEvent<HTMLElement>) => {
    applyCampaignNameFilter(null, e);
  };

  const handleCampaignStatusChange = (e: MultiSelectChangeParams) => {
    applyFilters({ campaignStatus: e.value?.length ? e.value : null }, e.originalEvent.currentTarget as HTMLElement);
  };

  const handleCabinetChange = (e: MultiSelectChangeParams) => {
    applyFilters({ cabinetIds: e.value?.length ? e.value : null }, e.originalEvent.currentTarget as HTMLElement);
  };

  const applyCSIDFilter = (
    value: string | null,
    e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    applyFilters({ csId: value }, e?.currentTarget);
  };

  const handleCSIDClear = (e: React.MouseEvent<HTMLElement>) => {
    applyCSIDFilter(null, e);
  };

  const clientOptions = useMemo(
    () =>
      clients
        ?.filter((x) => x !== null)
        .map((x) => ({
          label: x.name,
          value: x.id,
        })),
    [clients],
  );

  const campaignStatusOptions = useMemo(() => {
    const options: Array<{
      label: string;
      value: SM.CampaignStatus;
    }> = [
      { label: 'Неподтвержденная', value: SM.CampaignStatus.Unconfirmed },
      { label: 'Подтвержденная', value: SM.CampaignStatus.Confirmed },
      { label: 'Активная', value: SM.CampaignStatus.Active },
      { label: 'Приостановлена', value: SM.CampaignStatus.Paused },
      { label: 'Завершена', value: SM.CampaignStatus.Completed },
      { label: 'Архив', value: SM.CampaignStatus.Archived },
    ];

    return options.filter((x) => campaignStatuses.includes(x.value) || campaignStatus?.includes(x.value));
  }, [campaignStatuses, campaignStatus]);

  const cabinetOptions = useMemo(
    () =>
      cabinets
        ?.filter((x) => x !== null)
        .map((x) => ({
          label: x.name,
          value: x.id,
        })),
    [cabinets],
  );

  return (
    <div className={classes.root}>
      <MultiSelect
        value={advertiserIds}
        options={clientOptions}
        onChange={handleClientChange}
        placeholder="Клиент"
        filter
      />
      <SearchInput
        defaultValue={name ?? undefined}
        onChange={applyCampaignNameFilter}
        onClear={handleCampaignNameClear}
        placeholder="Кампания"
      />
      <MultiSelect
        value={campaignStatus}
        options={campaignStatusOptions}
        onChange={handleCampaignStatusChange}
        placeholder="Статус кампании"
      />
      <MultiSelect
        value={cabinetIds}
        options={cabinetOptions}
        onChange={handleCabinetChange}
        placeholder="Рекламный кабинет"
        filter
      />
      <SearchInput
        defaultValue={csId ?? undefined}
        onChange={applyCSIDFilter}
        onClear={handleCSIDClear}
        placeholder="CSID"
      />
      <ClearButton disabled={!Object.keys(campaignFilters).length} onClick={(e) => clearFilters(e.currentTarget)} />
    </div>
  );
});
