import { isEqual, omit } from 'lodash';

import { AdfoxAbstractRow, ClientRow, RowStatus } from 'types/tradingDesk/adfox';
import { DictionaryValue } from 'types/dictionary';

export function isRowChanged<T extends ClientRow>(row: T, defaultRows: T[]): boolean {
  const defaultRow = defaultRows.find((r) => r.id === row.id);
  if (defaultRow === undefined) return false;

  return !isEqual(getServerRowFromClientRow(row), getServerRowFromClientRow(defaultRow));
}

export function getServerRowFromClientRow<T extends ClientRow>(row: T): Omit<T, keyof AdfoxAbstractRow> {
  return omit(row, ['errors', 'isSelected']);
}

export function getRowStatusErrors(status: RowStatus, errorDictionary: DictionaryValue[]): string[] {
  const errors = status.errors
    ? status.errors.map((id) => errorDictionary.find((error) => error.externalId === id)?.value ?? '')
    : [];

  return [...errors, ...(status.serviceErrors ?? [])];
}

export function areValueExceeded(cell: unknown, defaultCell: unknown): boolean {
  const value = Number(cell);
  const defaultValue = Number(defaultCell);

  return value > defaultValue * 10 || value < defaultValue / 10;
}
