import { CellType } from 'handsontable/cellTypes';
import { ValueType } from 'types/dictionary';

export const columnTypeToHTCellTypeMap: Record<ValueType, CellType> = {
  STRING: 'text',
  DICTIONARY: 'dropdown',
  DATE: 'date',
  INTEGER: 'numeric',
  BOOLEAN: 'checkbox',
};
