import { WebMediaplanActions } from 'types/mediaplan';

type Action = {
  title: 'extract' | 'cabinets' | 'export-cabinet' | 'export' | 'export-fact' | 'edit' | 'fact-template';
  icon: string;
  url: { path: string; hasFileIdParam?: boolean };
};

export const actions: Record<WebMediaplanActions, Action> = {
  [WebMediaplanActions.EXTRACT_DATA]: {
    title: 'extract',
    icon: 'flash',
    url: { path: 'markup-mediaplan', hasFileIdParam: true },
  },
  [WebMediaplanActions.LINK_CABINETS]: {
    title: 'cabinets',
    icon: 'compare',
    url: { path: 'link-cabinets' },
  },
  [WebMediaplanActions.EXPORT_CABINET]: {
    title: 'export-cabinet',
    icon: 'compare',
    url: { path: 'export-cabinet/select-rows' },
  },
  [WebMediaplanActions.EXPORT_AMP]: {
    title: 'export',
    icon: 'sent',
    url: { path: 'export-amp' },
  },
  [WebMediaplanActions.EXPORT_FACT_AMP]: {
    title: 'export-fact',
    icon: 'sent',
    url: { path: 'export-fact-amp' },
  },
  [WebMediaplanActions.EDIT_DATA]: {
    title: 'edit',
    icon: 'edit',
    url: { path: 'edit-metrics' },
  },
  [WebMediaplanActions.DOWNLOAD_FACT_TEMPLATE]: {
    title: 'fact-template',
    icon: 'template',
    url: { path: 'fact-template' },
  },
};

type Args = {
  backendUrl: string;
  path: string;
  mediaplanId: number;
  mediaplanVersionId: number;
  fileId: number | null;
};

export function getWebMediaplanActionUrl({ backendUrl, path, mediaplanId, mediaplanVersionId, fileId }: Args): string {
  const extraParam = fileId !== null ? `&fileId=${fileId}` : '';
  return `${backendUrl}/${path}?mediaplanId=${mediaplanId}&mediaplanVersionId=${mediaplanVersionId}${extraParam}`;
}
