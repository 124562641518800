/* eslint-disable @typescript-eslint/no-explicit-any  */
import React from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { ColumnBodyOptions, ColumnBodyType } from 'primereact/column';
import { localize } from 'shared/components/other';
import { formatDate } from 'helpers/utils';
import classes from './Table.module.scss';

type Template = (rowData: any, options: ColumnBodyOptions, params: Record<string, any>) => React.ReactNode;

const date: Template = (rowData, options) => {
  const value = rowData[options.field];
  const invalid = rowData?.invalidFields?.includes('date') && !value;
  const columnOptions: Record<string, any> | undefined = (options.column.props as any).options;
  const getValue = () => {
    if (value) return formatDate({ date: value, isFullYear: true, hasTime: columnOptions?.hasTime });
    if (options.props.editMode === 'readOnly') return null;
    return `${localize('select')} ...`;
  };

  return <span className={cx(classes.textCell, { [classes.invalid]: invalid })}>{getValue()}</span>;
};

const text: ColumnBodyType = (rowData, options) => {
  const value = rowData[options.field];
  const invalid = rowData?.invalidFields?.includes(options.field);
  return <span className={cx(classes.textCell, { [classes.invalid]: invalid })}>{value}</span>;
};

export const templates = {
  date,
  text,
} as const;

export type TemplateName = keyof typeof templates;

function isTemplateName(value: unknown): value is TemplateName {
  const templateNames: string[] = R.keys(templates);
  return typeof value === 'string' && templateNames.includes(value);
}

export function getTemplate(value: TemplateName | ColumnBodyType = 'text'): ColumnBodyType {
  if (isTemplateName(value)) return templates[value];
  return value;
}
