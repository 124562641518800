import React, { VFC } from 'react';
import cx from 'classnames';
import { Button, ButtonProps } from '../Button';
import classes from './TextButton.module.scss';

type Props = ButtonProps;

export const TextButton: VFC<Props> = (props) => {
  const { className, icon, label, ...restProps } = props;

  return (
    <Button
      className={cx(classes.root, 'p-button-text', className, {
        [classes.iconOnly]: icon && !label,
      })}
      icon={icon}
      label={label}
      padding={false}
      {...restProps}
    />
  );
};

export type { Props as TextButtonProps };
