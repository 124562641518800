import { action, makeObservable, observable, reaction, runInAction, computed } from 'mobx';
import * as R from 'ramda';
import { IOption } from 'types';
import { IBrand } from 'types/brand';
import { ICampaign, CampaignStatus, MediaType, IServerFilters } from 'types/campaign';
import { convertDateToStr } from 'helpers/utils';
import { PaginationStore } from 'shared/stores';
import { SortValue } from 'shared/components/common/misc/Sorter';
import { CampaignFiltersType } from './components/CampaignFilters';

const CAMPAIGN_STATUSES = R.keys(CampaignStatus) as CampaignStatus[];
const MEDIA_TYPES = R.keys(MediaType) as MediaType[];

type CampaignsSortValue = SortValue<'name' | 'dateStart'>;

const defaultFilters: CampaignFiltersType = {
  brands: [],
  statuses: {},
  name: '',
  selectDate: [null, null],
  types: {},
  onlyFavorite: false,
};

const defaultSortValue: CampaignsSortValue = {
  by: 'dateStart',
  order: 'DESC',
};

const sortingMap = { name: 'NAME', dateStart: 'DATE_START' } as const;

export class CampaignsStore extends PaginationStore<ICampaign[]> {
  protected _data: ICampaign[] = [];

  @observable
  private _brands: IBrand[] = [];

  @observable
  private _filters: CampaignFiltersType = defaultFilters;

  @observable
  private _sortValue: CampaignsSortValue = defaultSortValue;

  @observable
  private _isActive = false;

  constructor() {
    super();
    makeObservable(this);
    reaction(
      () => this.deps.advertiserStore.currentAdvertiserId,
      () => {
        if (!this.isActive) return;
        this.resetBrandsFilter();
        this.resetSortValue();
      },
    );
    reaction(
      () => [this.deps.advertiserStore.currentAdvertiserId, this.deps.advertiserStore.advertisers, this.isActive],
      () => {
        if (!this.isActive) return;
        this.loadBrands();
      },
    );
    reaction(
      () => [this.sortValue, this.filters],
      () => this.setPage(0),
    );
    this.setup();
  }

  @computed
  get isActive(): boolean {
    return this._isActive;
  }

  @computed
  get brands(): IBrand[] {
    return this._brands;
  }

  @computed
  get brandOptions(): IOption[] {
    return this._brands.map((x: IBrand) => ({ label: x.name, value: x.id }));
  }

  @computed
  get filters(): CampaignFiltersType {
    return this._filters;
  }

  @computed
  get sortValue(): CampaignsSortValue {
    return this._sortValue;
  }

  getCampaign = (id: number): ICampaign | null => {
    return this.data.find((x) => x.id === id) ?? null;
  };

  invalidationScope = (): unknown[] => [
    this.isActive,
    this.deps.advertiserStore.currentAdvertiserId,
    this.deps.advertiserStore.advertisers,
    this.pageSize,
    this.page,
    this.filters,
    this.sortValue,
  ];

  @action
  activate(): void {
    this._isActive = true;
  }

  @action
  setFilters = (value: Partial<CampaignFiltersType>): void => {
    this._filters = { ...this._filters, ...value };
  };

  @action
  setSortValue = (value: CampaignsSortValue): void => {
    this._sortValue = value;
  };

  toggleCampaignFavorite = async (value: ICampaign | null): Promise<void> => {
    if (!value) return;
    const { id: campaignId, name: campaignName, favorite } = value;
    const response = await this.services.api.campaigns.setFavorite({ campaignId, campaignName, favorite: !favorite });
    if (response.error) return;
    await this.loadCampaigns();
  };

  @action
  reset = (): void => {
    this._isActive = false;
    this._data = [];
    this._brands = [];
    this._filters = defaultFilters;
    this.resetSortValue();
  };

  revalidate(): void {
    if (!this.isActive) return;
    this.loadCampaigns();
  }

  private get serverFilters(): Partial<IServerFilters> {
    const { filters, sortValue } = this;
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (advertiserId === null) return {};

    const [startDate, endDate] = filters.selectDate;
    const selectedStatuses = R.keys(R.filter(Boolean, filters.statuses));
    const defaultShownStatuses = R.reject((x) => x === CampaignStatus.ARCHIVED)(CAMPAIGN_STATUSES);
    const mediaTypes = R.filter((x) => !!filters.types[x], MEDIA_TYPES);

    return R.mergeRight(
      {
        advertiserId,
        mediaTypes: mediaTypes.length > 0 ? mediaTypes : null,
        onlyFavorite: filters.onlyFavorite,
        sortingField: sortingMap[sortValue.by],
        sortingType: sortValue.order,
        statuses: selectedStatuses.length ? selectedStatuses : defaultShownStatuses,
      },
      R.reject(R.anyPass([R.isEmpty, R.isNil]))({
        brandIds: filters.brands,
        endDate: convertDateToStr(endDate),
        startDate: convertDateToStr(startDate),
        subName: filters.name,
      }),
    );
  }

  @action
  private resetBrandsFilter = () => {
    this._filters.brands = [];
  };

  @action
  private resetSortValue = () => {
    this.page = 0;
    this._sortValue = defaultSortValue;
  };

  private loadCampaigns = async (): Promise<void> => {
    const { page, pageSize, serverFilters } = this;
    const { currentAdvertiserId: advertiserId, advertisers } = this.deps.advertiserStore;
    if (advertisers.length === 0 || !advertisers.find((x) => x.id === advertiserId)) return;
    const response = await this.services.api.campaigns.list({ serverFilters, page, pageSize });
    if (response.error) return;
    const { content, totalPages, totalElements } = response.data;
    runInAction(() => {
      this._data = content;
      this._totalPages = totalPages;
      this._totalElements = totalElements;
    });
  };

  private loadBrands = async (): Promise<void> => {
    const { currentAdvertiserId: advertiserId, advertisers } = this.deps.advertiserStore;
    if (advertiserId === null || !advertisers.find((x) => x.id === advertiserId)) return;
    const response = await this.services.api.brands.list({ customerId: advertiserId });
    if (response.error) return;
    runInAction(() => {
      this._brands = response.data.content;
    });
  };
}
