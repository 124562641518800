import React from 'react';
import cx from 'classnames';
import { Button, ButtonProps } from '../Button';
import classes from './CloseButton.module.scss';

type Props = Omit<ButtonProps, 'padding' | 'size'> & {
  /** Size of the component. Default: 'medium' */
  size?: 'xsmall' | 'small' | 'medium';
};

export const CloseButton: React.VFC<Props> = (props) => {
  const { className, size = 'medium', icon = 'pi pi-times', ...restProps } = props;

  return (
    <Button
      className={cx(classes.root, 'p-button-icon-only p-button-text p-button-rounded', className, {
        [classes[`${size}Size`]]: !!size,
      })}
      icon={icon}
      padding={false}
      {...restProps}
    />
  );
};

export type { Props as CloseButtonProps };
