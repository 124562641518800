import get from 'lodash/get';
import flatten from 'lodash/flatten';

import { sharedStores } from 'shared/stores';
import { BasicRole, IProfile, Media } from 'types/profile';
import { MediaType } from 'types/campaign';
import { WebMediaplanActions } from 'types/mediaplan';
import { toast } from 'shared/components/common/misc/Toast';

export type Page =
  | 'brands'
  | 'campaigns'
  | 'editCampaign'
  | 'uploadStatistics'
  | 'accounts'
  | 'account'
  | 'cabinetStatus'
  | 'competitorAnalysis'
  | 'prgBenchmarks'
  | 'programmatic'
  | 'tradingDesk'
  | 'reportConstructor'
  | 'cabinetCopying'
  | 'administration'
  | 'templates'
  | 'powerbiDefaultSettings';

type BrandPageFeature = 'editBrand' | 'addCampaignToBrand';

type CampaignPageFeature =
  | 'deleteCampaign'
  | 'autoCreateCampaign'
  | 'downloadExcelPivot'
  | 'webReport'
  | 'webMediaplansTab'
  | 'tvMediaplansTab'
  | 'tvTab'
  | 'webTab'
  | 'creativesTab'
  | 'editCreatives';

type ProgrammaticPageFeature = 'startStopCampaign';

type ReportConstructorPageFeature = 'editReportLevelType';

export type HeaderFeature = 'selectAdvertiser' | 'instructionLink' | 'telegramLink';

export type Feature =
  | Page
  | BrandPageFeature
  | CampaignPageFeature
  | ProgrammaticPageFeature
  | MediaFeature
  | WebMediaplanActions
  | HeaderFeature;

type MediaFeature =
  | MediaType
  | 'programmatic'
  | 'reportConstructor'
  | 'downloadExcelPivot'
  | 'webReport'
  | 'webTab'
  | 'tvTab'
  | 'webMediaplansTab'
  | 'tvMediaplansTab'
  | 'uploadStatistics'
  | 'autoCreateCampaign';

export type FeatureTable = {
  pages: Record<Page, BasicRole[]>;
  medias: Record<MediaType, BasicRole[]>;
  brandPage: Record<BrandPageFeature, BasicRole[]>;
  campaignPage: Record<CampaignPageFeature, BasicRole[]>;
  programmaticPage: Record<ProgrammaticPageFeature, BasicRole[]>;
  reportConstructorPage: Record<ReportConstructorPageFeature, BasicRole[]>;
  webMediaplans: Record<WebMediaplanActions, BasicRole[]>;
  header: Record<HeaderFeature, BasicRole[]>;
};

export const programmaticRoles: Record<string, string> = {
  programmatic_department_head: 'Department Head',
  programmatic_group_head: 'Group Head',
  programmatic_manager: 'Manager',
};

// TODO: add priority for programmatic roles

// в таблице не описаны элементы, доступные всем ролям и медиа ролям
const featureTable: FeatureTable = {
  pages: {
    brands: ['admin', 'user', 'external_user', 'client'],
    campaigns: ['admin', 'user', 'external_user', 'client'],
    editCampaign: ['admin', 'user', 'external_user'],
    accounts: ['admin', 'user', 'external_user'],
    account: ['admin', 'user', 'external_user'],
    cabinetStatus: ['admin', 'user', 'external_user'],
    uploadStatistics: ['admin', 'user', 'external_user'],
    competitorAnalysis: ['admin', 'user', 'external_user', 'client'],
    prgBenchmarks: ['admin', 'user'],
    administration: ['admin', 'external_user'],
    templates: [],
    powerbiDefaultSettings: [],
    programmatic: ['admin', 'user'],
    tradingDesk: ['admin', 'trading_desk_user'],
    reportConstructor: ['admin', 'user', 'external_user'],
    cabinetCopying: ['admin', 'user'],
  },

  medias: {
    [MediaType.TV]: ['admin', 'user', 'external_user', 'client'],
    [MediaType.WEB]: ['admin', 'user', 'external_user', 'client'],
    [MediaType.RADIO]: ['admin', 'user', 'external_user', 'client'],
    [MediaType.PRESS]: ['admin', 'user', 'external_user', 'client'],
    [MediaType.OUTDOOR]: ['admin', 'user', 'external_user', 'client'],
  },

  brandPage: {
    editBrand: ['admin', 'user', 'external_user'],
    addCampaignToBrand: ['admin', 'user', 'external_user'],
  },

  campaignPage: {
    deleteCampaign: ['admin'],
    autoCreateCampaign: ['admin', 'user', 'external_user'],
    downloadExcelPivot: ['admin', 'user', 'external_user'],
    webReport: ['admin', 'user', 'external_user'],
    webMediaplansTab: ['admin', 'user', 'external_user'],
    tvMediaplansTab: ['admin', 'user', 'external_user'],
    tvTab: ['admin', 'user', 'external_user', 'client'],
    webTab: ['admin', 'user', 'external_user', 'client'],
    creativesTab: ['admin', 'user', 'external_user', 'client'],
    editCreatives: ['admin', 'user', 'external_user'],
  },

  programmaticPage: {
    startStopCampaign: ['admin', 'user'],
  },

  reportConstructorPage: {
    editReportLevelType: ['admin'],
  },

  webMediaplans: {
    [WebMediaplanActions.EXTRACT_DATA]: ['admin', 'user', 'external_user'],
    [WebMediaplanActions.LINK_CABINETS]: ['admin', 'user', 'external_user'],
    [WebMediaplanActions.EXPORT_CABINET]: ['admin', 'user'],
    [WebMediaplanActions.EXPORT_AMP]: ['admin', 'user'],
    [WebMediaplanActions.EXPORT_FACT_AMP]: ['admin', 'user'],
    [WebMediaplanActions.EDIT_DATA]: ['admin', 'user', 'external_user'],
    [WebMediaplanActions.DOWNLOAD_FACT_TEMPLATE]: ['admin', 'user', 'external_user'],
  },

  header: {
    selectAdvertiser: ['admin', 'user', 'external_user', 'client'],
    instructionLink: ['admin', 'user', 'external_user', 'client'],
    telegramLink: ['admin', 'user'],
  },
};

const mapMediaToFeatures: Record<Media, MediaFeature[]> = {
  TV: [MediaType.TV, 'tvMediaplansTab', 'tvTab'],
  WEB: [
    MediaType.WEB,
    'programmatic',
    'reportConstructor',
    'webMediaplansTab',
    'webTab',
    'webReport',
    'downloadExcelPivot',
    'uploadStatistics',
    'autoCreateCampaign',
  ],
  RADIO: [MediaType.RADIO],
  PRESS: [MediaType.PRESS],
  OUTDOOR: [MediaType.OUTDOOR],
};

const mediaFeatures = flatten(Object.values(mapMediaToFeatures));
// подробности в вики https://wiki.aizek.io/pages/viewpage.action?pageId=55051037

export const featureToPredicateMap: Partial<Record<Feature, (user: IProfile) => boolean>> = {
  programmatic: (user: IProfile): boolean => isAdmin(user) || isProgrammatic(user),
  startStopCampaign: (user: IProfile): boolean =>
    isAdmin(user) || isProgrammatic(user, ['programmatic_department_head', 'programmatic_group_head']),
  cabinetCopying: (user: IProfile): boolean => isProgrammatic(user),
};

const isAdmin = (user: IProfile) => user.roles.includes('admin');

const isProgrammatic = (
  user: IProfile,
  availableRoles: (keyof typeof programmaticRoles)[] = Object.keys(programmaticRoles),
) => user.additionalRoles.some((role) => availableRoles.includes(role));

// NOTE: роль alpha_tester полезна только на проде в случаях,
// когда мы выкатываем сырой функционал ограниченному кругу пользователей
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isAlphaTester = (user: IProfile) => user.additionalRoles.includes('alpha_tester');

function hasAccess<T extends keyof FeatureTable>(
  feature: keyof FeatureTable[T],
  featureType: T,
  user: IProfile | null = sharedStores.authStore.profile,
): boolean {
  if (!user) {
    return false;
  }
  if (user.roles.includes('superadmin')) {
    return true;
  }

  const featureName = `${featureType}.${feature}`;
  const currentFeature: BasicRole[] | undefined = get(featureTable, featureName);

  if (!currentFeature) {
    toast.error('Access denied');
    return false;
  }

  const hasValidBasicRole = currentFeature.some((role) => user.roles.includes(role));
  const hasValidSpecificPredicate = featureToPredicateMap[feature as Feature]?.(user) ?? true;
  const hasValidMediaType =
    isAdmin(user) ||
    !mediaFeatures.includes(feature as MediaFeature) ||
    user.medias.some((media) => mapMediaToFeatures[media].includes(feature as MediaFeature));

  return hasValidBasicRole && hasValidSpecificPredicate && hasValidMediaType;
}

export default hasAccess;
