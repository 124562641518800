import React, { useMemo, useRef, useEffect } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { TabPanel, TabView } from 'primereact/tabview';
import { localize, PowerBIReport, PowerBIReportProps } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { MediaType } from 'types/campaign';
import { Mediaplans, MediaplansProps } from './components/Mediaplans';
import { Creatives, CreativesProps } from './components/Creatives';
import classes from './CampaignTabView.module.scss';

type Props = {
  accessibleTabs: CampaignViewTab[];
  className?: string;
  isPivotDownloadAccessible: boolean;
  mediaplans: MediaplansProps;
  creatives: CreativesProps;
  onPivotDownload(): void;
  powerBIReport: Pick<
    PowerBIReportProps,
    'campaignId' | 'brandId' | 'subbrandId' | 'categoryId' | 'startDate' | 'endDate'
  >;
};

export const CampaignTabView: React.VFC<Props> = React.memo((props) => {
  const {
    accessibleTabs,
    className,
    isPivotDownloadAccessible,
    mediaplans,
    creatives,
    powerBIReport,
    onPivotDownload,
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tabLinks = ref.current?.querySelectorAll('.p-tabview-nav-link');
    if (!tabLinks) return;
    tabLinks.forEach((tabLink) => {
      const classList = Array.from(tabLink.closest('li[class$="-tab"]')?.classList ?? []);
      const test = classList.find((x) => x.match(/-tab$/));
      if (tabLink instanceof HTMLElement) {
        tabLink.dataset.test = test; // eslint-disable-line no-param-reassign
      }
    });
  }, []);

  const tabPanels = useMemo(() => {
    return R.pipe(
      R.pick(accessibleTabs),
      R.values,
    )({
      mediaplans: (
        <TabPanel className="mediaplans-tab" key="mediaplans" header={localize('campaign.mediaplans')}>
          <Mediaplans {...mediaplans} />
        </TabPanel>
      ),
      tv: (
        <TabPanel className="tv-tab" key="tv" header={localize('campaign.tv')}>
          <h3 className={classes.title}>{localize('campaign.tv')}</h3>
          <PowerBIReport pageName="CAMPAIGN" mediaType={MediaType.TV} {...powerBIReport} />
        </TabPanel>
      ),
      web: (
        <TabPanel className="internet-tab" key="web" header={localize('campaign.web')}>
          <h3 className={classes.title}>{localize('campaign.web')}</h3>
          <PowerBIReport pageName="CAMPAIGN" mediaType={MediaType.WEB} {...powerBIReport} />
        </TabPanel>
      ),
      webReport: (
        <TabPanel className="web-report-tab" key="webReport" header={localize('campaign.download-web-report')}>
          <h3 className={classes.title}>
            {localize('campaign.web-report')}
            {isPivotDownloadAccessible && (
              <Button
                className={classes.downloadReportButton}
                data-test="download-pivot-button"
                label={localize('campaign.download-pivot')}
                onClick={onPivotDownload}
              />
            )}
          </h3>
          <PowerBIReport pageName="CAMPAIGN" mediaType={MediaType.WEB} positionName="WEB_REPORT" {...powerBIReport} />
        </TabPanel>
      ),
      creatives: (
        <TabPanel className="creatives-tab" key="creatives" header={localize('campaign.creatives')}>
          <Creatives {...creatives} />
        </TabPanel>
      ),
    });
  }, [accessibleTabs, isPivotDownloadAccessible, mediaplans, creatives, onPivotDownload, powerBIReport]);

  return accessibleTabs.length ? (
    <div className={cx(classes.root, className)} ref={ref}>
      <TabView>{tabPanels}</TabView>
    </div>
  ) : null;
});

export type CampaignViewTab = 'mediaplans' | 'tv' | 'web' | 'webReport' | 'creatives';

export type { Props as CampaignTabViewProps };
