import React from 'react';
import { PaginatorRowsPerPageDropdownOptions } from 'primereact/paginator';
import { SelectButton } from 'primereact/selectbutton';
import classes from './RowsPerPageSelector.module.scss';

type Props = Partial<PaginatorRowsPerPageDropdownOptions> & {
  title?: string;
};

export const RowsPerPageSelector: React.VFC<Props> = (props) => {
  const { onChange, options, title, value } = props;

  return options?.length ? (
    <div className={classes.root}>
      {title && <span className={classes.title}>{title}</span>}
      <SelectButton
        className={classes.selectButton}
        itemTemplate={({ label }) => label}
        onChange={onChange}
        options={options}
        value={value}
      />
    </div>
  ) : null;
};

export type { Props as RowsPerPageSelectorProps };
