import * as R from 'ramda';
import { DataGridFilter, DataGridFilters } from 'shared/components/other';
import { LogFilters } from 'types/cabinetCopying';

export function convertGridFiltersToApiFilters(
  filters: DataGridFilters<keyof LogFilters>,
): Record<string, string | (string | number)[] | null> {
  return R.pipe(
    R.mapObjIndexed((filter: DataGridFilter<string | number> | null) => {
      switch (filter?.type) {
        case 'text':
        case 'multiselect':
          return filter.value;
        default:
          return null;
      }
    }),
    R.filter((filter) => filter !== null),
  )(filters);
}
