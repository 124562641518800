import { Link } from 'react-router-dom';
import React, { FC } from 'react';

import { BrandCard } from 'shared/components/domain/BrandCard';
import { IColorTheme } from 'types';
import { IBrand, IBrandStatistics } from 'types/brand';

import classes from '../../Brands.module.scss';

interface IProps {
  brand: IBrand;
  activeYear: number;
  statistics: IBrandStatistics | null;
  color: string;
  brandTheme: IColorTheme;
}
export const BrandCardContainer: FC<IProps> = (props: IProps) => {
  const { brand, activeYear, statistics, color, brandTheme } = props;

  return (
    <div key={brand.id} className={classes.CardWrapper}>
      <Link
        key={brand.id}
        className={classes.Card}
        to={`/brands/${brand.id}?year=${activeYear}`}
        data-test={`brand-card-${brand.id}`}
      >
        <BrandCard brand={brand} statistics={statistics} labelColor={color} theme={brandTheme} />
      </Link>
    </div>
  );
};
