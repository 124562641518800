import { observable, computed, makeObservable, action, reaction, runInAction } from 'mobx';

import Config from 'config';
import { IError } from 'types';
import { ServerModels as SM } from 'types/server';
import { AdfoxRowInfo, RowStatus, RowStatuses } from 'types/tradingDesk/adfox';

import { AdFoxStore } from './AdFox.store';
import { ADFOX_API_ACCOUNT_ID, ADFOX_BANNERS_PAGE_ID, convertToServerRow } from '../helpers';

const accessibleCampaignIDs = [1812854, 1815559, 1815562]; // TODO: remove it

export class AdFoxBannersStore extends AdFoxStore {
  @computed
  protected get _requiredFilters(): SM.AdfoxBannerFilter {
    return { campaignId: this.campaignId };
  }

  protected _dataType = 'banners' as const;

  protected _pageId = ADFOX_BANNERS_PAGE_ID;

  @observable
  private _campaignId = 0;

  @observable
  private _summary: SM.adfox.Summary = {};

  @observable
  private _isSummaryLoading = false;

  @computed
  get campaignId(): number {
    return this._campaignId;
  }

  set campaignId(value: number) {
    this._campaignId = value;
  }

  @computed
  get summary(): SM.adfox.Summary {
    return this._summary;
  }

  @computed
  get isSummaryLoading(): boolean {
    return this._isSummaryLoading;
  }

  constructor() {
    super();
    makeObservable(this);
    reaction(() => this._campaignId, this.loadData);
    reaction(() => this._defaultRows, this.loadSummary);
  }

  protected getLoadedRowsData = async (): Promise<SM.AdfoxBannerView[] | null> => {
    const response = await this.services.api.tradingDesk.adfox.banners({
      filter: this.filters as SM.AdfoxBannerFilter,
    });
    return response?.data?.adfoxBanners ?? null;
  };

  protected getRefreshedRowsData = async (rows: AdfoxRowInfo[]): Promise<RowStatus[] | null> => {
    const response = await this.services.api.tradingDesk.adfox.refreshCampaignWithBanners({
      campaignId: this.campaignId,
      bannerIds: rows.map(({ row }) => row.id),
    });

    return response.error ? null : response.data.bannerStatuses;
  };

  protected getUpdatedRowsData = async (rows: AdfoxRowInfo[]): Promise<RowStatuses | IError | null> => {
    const response = await this.services.api.tradingDesk.adfox.updateBanners({
      banners: rows.map(({ row }) => ({
        ...(convertToServerRow(row, this._defaultRows, this._columns) as Partial<SM.AdfoxBannerView>),
        campaignId: this._campaignId,
      })),
    });

    return response.error ? null : response.data;
  };

  protected filterRowsBeforeSaving = (rows: AdfoxRowInfo[]): AdfoxRowInfo[] =>
    Config.appOptions.standType === 'production' || accessibleCampaignIDs.includes(this.campaignId) ? rows : [];

  @action
  private loadSummary = async (): Promise<void> => {
    this._isSummaryLoading = true;
    const response = await this.services.api.tradingDesk.adfox.summary({
      filter: {
        campaignIds: [this.campaignId],
        apiAccountId: ADFOX_API_ACCOUNT_ID,
      },
    });
    runInAction(() => {
      this._summary = response?.data?.adfoxCampaigns?.[0] ?? {};
      this._isSummaryLoading = false;
    });
  };
}
