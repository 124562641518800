import React from 'react';
import * as R from 'ramda';
import shallowequal from 'shallowequal';

import { CustomField, FieldDescription } from 'types/powerBI';
import { IOption } from 'types';
import { IBrand } from 'types/brand';
import { IShortCampaign } from 'types/campaign';
import { ICategory } from 'types/category';
import { Checkbox, Input, LocalizedMessage } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { Select } from 'shared/components/common/form/Select';
import { ISubbrand } from 'types/subbrand';
import { convertLabel } from '../../helpers';
import classes from './CustomSettings.module.scss';
import { SelectLevel } from './SelectLevel/SelectLevel';

interface IProps {
  brands: IBrand[];
  categories: ICategory[];
  campaigns: IShortCampaign[];
  customFields: CustomField[];
  subbrandsByBrandId: Record<number, ISubbrand[]>;
  loadSubbrands: (brandIDs: number[]) => void;
  description: FieldDescription[];
  type: 'internal' | 'client';
  onChange: (fields: CustomField[]) => void;
  onDelete: (id: number, callback: () => void) => void;
}

type LevelType = 'brand' | 'campaign' | 'category' | 'subbrand';

interface IState {
  pageOptions: (IOption & { isCampaignOption: boolean })[];
  mediaOptions: IOption[];
  brandOptions: IOption[];
  campaignOptions: IOption[];
  categoriesOptions: IOption[];
  lastSelected: {
    pages: (LevelType | null)[];
    brands: (number | null)[];
    campaigns: (number | null)[];
    categories: (number | null)[];
    subbrands: (number | null)[];
    types: LevelType[];
  };
}

const getMediaLabel = (d: FieldDescription) =>
  d.mediaType ? (d.positionName ? `${convertLabel(d.positionName)}` : `${convertLabel(d.mediaType)}`) : '';

const filterOptions = <T extends IOption>(options: T[]): T[] =>
  options.reduce(
    (acc: { map: Record<string, boolean>; options: T[] }, option: T) => {
      if (acc.map[option.label]) {
        return acc;
      }

      acc.map[option.label] = true;
      acc.options.push(option);

      return acc;
    },
    {
      map: {},
      options: [],
    },
  ).options;

const getSubbrandsByField = (field: CustomField, subbrandsByBrandId: Record<number, ISubbrand[]>) => {
  if (field.brand !== null) {
    const subbrands = subbrandsByBrandId[field.brand] ?? [];

    return subbrands.map((x) => ({
      label: x.name,
      value: x.id,
    }));
  }

  return [];
};

class CustomSettings extends React.Component<IProps, IState> {
  state: IState = {
    pageOptions: [],
    mediaOptions: [],
    brandOptions: [],
    campaignOptions: [],
    categoriesOptions: [],
    lastSelected: {
      subbrands: [],
      pages: [],
      brands: [],
      campaigns: [],
      categories: [],
      types: [],
    },
  };

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
    const getComparedState = R.pick(['lastSelected']);
    return (
      !shallowequal(nextProps, this.props) || !shallowequal(getComparedState(nextState), getComparedState(this.state))
    );
  }

  componentDidMount() {
    const { customFields } = this.props;
    const { pageOptions } = this.state;

    const pages = customFields.map((field) => {
      const pageValue = pageOptions.find((o) => o.value === field.page) || pageOptions.find((o) => o.isCampaignOption);

      return pageValue?.isCampaignOption === undefined ? null : pageValue?.isCampaignOption ? 'campaign' : 'brand';
    });
    const brands = customFields.map((field) => field.brand);
    const campaigns = customFields.map((field) => field.campaign);
    const categories = customFields.map((field) => field.category);
    const subbrands = customFields.map((field) => field.subbrand);
    const types = customFields.map((field) => field.type);

    this.setState({
      lastSelected: { pages, brands, campaigns, types, categories, subbrands },
    });
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
    const { brands, campaigns, description, categories } = nextProps;

    const brandOptions: IOption[] = brands.map((b: IBrand) => ({
      label: b.name,
      value: b.id,
    }));
    const campaignOptions: IOption[] = campaigns.map((c: IShortCampaign) => ({
      label: c.name,
      value: c.id,
    }));
    const categoriesOptions: IOption[] = categories.map((c: IShortCampaign) => ({
      label: c.name,
      value: c.id,
    }));

    const pageOptions: (IOption & {
      isCampaignOption: boolean;
    })[] = filterOptions(
      description.map((d) => ({
        label: d.pageName === 'BRAND' || d.pageName === 'CAMPAIGN' ? convertLabel(d.pageName) : '',
        value: d.id,
        isCampaignOption: d.pageName === 'CAMPAIGN',
      })),
    ).filter((option: IOption) => option.label);

    const mediaOptions: IOption[] = filterOptions(
      description.map((d) => ({
        label: getMediaLabel(d),
        value: d.id,
      })),
    ).filter((option: IOption) => option.label);

    return {
      ...prevState,
      pageOptions,
      mediaOptions,
      brandOptions,
      campaignOptions,
      categoriesOptions,
    };
  }

  handleButtonClick = (): void => {
    const { onChange, customFields } = this.props;
    const {
      lastSelected: { pages, brands, campaigns, types, categories, subbrands },
    } = this.state;
    const newField: CustomField = {
      page: null,
      brand: null,
      campaign: null,
      category: null,
      subbrand: null,
      type: 'brand',
      url: '',
      serverId: null,
      isEnabled: true,
      isChanged: true,
      errors: [],
      userUrlTitle: null,
    };
    onChange([...customFields, newField]);

    this.setState({
      lastSelected: {
        pages: [...pages, null],
        brands: [...brands, null],
        campaigns: [...campaigns, null],
        categories: [...categories, null],
        subbrands: [...subbrands, null],
        types: [...types, 'brand'],
      },
    });
  };

  makeHandleConditionChange = (
    index: number,
    type: 'page' | 'brand' | 'campaign' | 'category' | 'subbrand',
    isSubbrand?: boolean,
  ) => (val: number, option?: IOption & { isCampaignOption?: boolean }): void => {
    if (!option) return;

    const { value, isCampaignOption } = option;
    const { onChange, customFields } = this.props;
    const {
      lastSelected,
      lastSelected: { pages, brands, campaigns, types, categories, subbrands },
    } = this.state;

    if (type === 'brand') {
      this.props.loadSubbrands([value]);
    }
    const fields = customFields.map((f, i) => {
      if (i === index) {
        const isPageChanged = isCampaignOption !== undefined;
        const isBrand =
          types[i] === 'brand' || (!isPageChanged && pages[i] === 'brand') || (isPageChanged && !isCampaignOption);

        return {
          ...f,
          brand: isBrand || isSubbrand ? brands[i] : null,
          // campaign: !isBrand ? campaigns[i] : null,
          category: type === 'category' ? value : null,
          subbrand: type !== 'subbrand' ? null : value,
          campaign: type !== 'campaign' ? null : value,
          type: (() => {
            if (isSubbrand) {
              return 'subbrand';
            }
            if (isBrand) {
              return 'brand';
            }

            return type !== 'page' ? type : 'category';
          })(),
          [type]: value,
          isChanged: true,
          errors: f.errors.filter((error) => error !== type),
        };
      } else {
        return f;
      }
    });
    onChange(fields);
    if (type === 'brand') {
      const newBrands = brands.map((item, i) => (i === index ? value : item));
      this.setState({ lastSelected: { ...lastSelected, brands: newBrands } });
    } else if (type === 'campaign') {
      const newCampaigns = campaigns.map((item, i) => (i === index ? value : item));
      this.setState({
        lastSelected: { ...lastSelected, campaigns: newCampaigns },
      });
    } else if (type === 'page' && isCampaignOption !== undefined) {
      const newPages = pages.map((item, i) => (i === index ? (value === 2 ? 'brand' : 'campaign') : item));
      this.setState({ lastSelected: { ...lastSelected, pages: newPages } });
    } else if (type === 'category') {
      const newCategories = categories.map((item, i) => (i === index ? value : item));
      this.setState({
        lastSelected: { ...lastSelected, categories: newCategories },
      });
    } else if (type === 'subbrand') {
      const newSubbrands = subbrands.map((item, i) => (i === index ? value : item));
      this.setState({
        lastSelected: { ...lastSelected, subbrands: newSubbrands },
      });
    }
  };

  makeHandleTypeFieldChange = (type: 'brand' | 'campaign' | 'category' | 'subbrand', index: number): void => {
    const { onChange, customFields } = this.props;
    const {
      lastSelected,
      lastSelected: { brands, campaigns, types, categories, subbrands },
    } = this.state;
    onChange(
      customFields.map((f, i) =>
        i === index
          ? {
              ...f,
              type,
              isChanged: true,
              brand: type === 'brand' || type === 'subbrand' ? brands[i] : null,
              campaign: type === 'campaign' ? campaigns[i] : null,
              category: type === 'category' ? categories[i] : null,
              subbrand: type === 'subbrand' ? subbrands[i] : null,
            }
          : f,
      ),
    );

    const newTypes = types.map((item, i) => (i === index ? type : item));
    this.setState({ lastSelected: { ...lastSelected, types: newTypes } });
  };

  makeHandleUrlChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange, customFields } = this.props;
    const { value: url } = e.target;
    onChange(
      customFields.map((f, i) => {
        const errors = f.errors.filter((error) => error !== 'url');

        return i === index
          ? {
              ...f,
              url,
              isChanged: true,
              errors,
            }
          : {
              ...f,
              errors,
            };
      }),
    );
  };

  makeHandleDelete = (index: number) => (): void => {
    const { onChange, onDelete, customFields } = this.props;
    const {
      lastSelected: { pages, brands, campaigns, types, categories, subbrands },
    } = this.state;
    const field = customFields[index];
    if (field.serverId === null) {
      onChange(customFields.filter((f, i) => i !== index));
    } else {
      onDelete(field.serverId, () => {
        onChange(customFields.filter((f, i) => i !== index));
      });
    }

    this.setState({
      lastSelected: {
        pages: pages.filter((item, i) => i !== index),
        brands: brands.filter((item, i) => i !== index),
        campaigns: campaigns.filter((item, i) => i !== index),
        categories: categories.filter((item, i) => i !== index),
        subbrands: subbrands.filter((item, i) => i !== index),
        types: types.filter((item, i) => i !== index),
      },
    });
  };

  handleCheckboxChange = (name: string, checked: boolean): void => {
    const { onChange, customFields } = this.props;
    onChange(customFields.map((f, i) => (i === Number(name) ? { ...f, isEnabled: !checked, isChanged: true } : f)));
  };

  render() {
    const { customFields, subbrandsByBrandId, type } = this.props;
    const { pageOptions, mediaOptions, brandOptions, campaignOptions, categoriesOptions } = this.state;

    return (
      <div>
        <table className={classes.Table}>
          <tbody>
            <LocalizedMessage
              id={[
                'powerbi.headers.page',
                'powerbi.headers.media',
                'powerbi.headers.level',
                'powerbi.headers.url',
                'powerbi.headers.enable',
              ]}
            >
              {(pageLabel: string, mediaLabel: string, levelLabel: string, urlLabel: string, enableLabel: string) => (
                <tr>
                  <th>{pageLabel}</th>
                  <th>{mediaLabel}</th>
                  <th>{levelLabel}</th>
                  <th>{urlLabel}</th>
                  <th>{enableLabel}</th>
                  <th> </th>
                </tr>
              )}
            </LocalizedMessage>
            {customFields.map((f, index) => {
              const pageValue =
                f.page === null
                  ? null
                  : pageOptions.find((o) => o.value === f.page) || pageOptions.find((o) => o.isCampaignOption);

              return (
                <tr key={index}>
                  <td>
                    <Select
                      options={pageOptions}
                      value={pageValue?.value}
                      onChange={this.makeHandleConditionChange(index, 'page')}
                      invalid={f.errors.includes('page')}
                      mode="inline"
                    />
                  </td>
                  <td>
                    <Select
                      options={mediaOptions}
                      value={mediaOptions.find((o) => o.value === f.page)?.value}
                      onChange={this.makeHandleConditionChange(index, 'page')}
                      disabled={!pageValue?.isCampaignOption}
                      mode="inline"
                    />
                  </td>
                  <td>
                    <SelectLevel
                      typeField={type}
                      brandOptions={brandOptions}
                      campaignOptions={campaignOptions}
                      categoriesOptions={categoriesOptions}
                      isCampaignOption={Boolean(pageValue?.isCampaignOption)}
                      subbrandsOptions={getSubbrandsByField(f, subbrandsByBrandId)}
                      field={f}
                      index={index}
                      onChangeLevel={this.makeHandleTypeFieldChange}
                      onChangeSelect={this.makeHandleConditionChange}
                    />
                  </td>
                  <td>
                    <Input
                      value={f.url}
                      disabled={!f.isEnabled}
                      onChange={this.makeHandleUrlChange(index)}
                      hasError={f.isEnabled && f.errors.includes('url')}
                      maxLength={1000}
                    />
                  </td>
                  <td>
                    <label className={classes.Check}>
                      <Checkbox checked={f.isEnabled} name={String(index)} onChange={this.handleCheckboxChange} />
                    </label>
                  </td>
                  <td>
                    <Button
                      theme="light"
                      className={classes.RemoveButton}
                      icon="icon-delete"
                      iconColor="var(--color-red)"
                      onClick={this.makeHandleDelete(index)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Button onClick={this.handleButtonClick}>+</Button>
      </div>
    );
  }
}

export default CustomSettings;
