import { useMemo } from 'react';
import { Nullable } from 'types';
import { ReportFilters } from 'types/reportConstructor';

type UseGetDateBoundariesHook = (
  filters: ReportFilters,
  endDateRangeDisabled: boolean,
) => {
  maxDateForStart: Date | undefined;
  minDateForEnd: Date | undefined;
};

export const useGetMemoizedDateBoundaries: UseGetDateBoundariesHook = (
  { statisticDateEnd, statisticDateStart },
  endDateRangeDisabled,
) =>
  useMemo(() => {
    const maxDateForStart = !endDateRangeDisabled && statisticDateEnd ? new Date(statisticDateEnd) : undefined;
    const minDateForEnd = !endDateRangeDisabled && statisticDateStart ? new Date(statisticDateStart) : undefined;

    return { maxDateForStart, minDateForEnd };
  }, [endDateRangeDisabled, statisticDateEnd, statisticDateStart]);

type UseGetMemoizedDateHook = (statisticDate: Nullable<string>, endDateRangeDisabled?: boolean) => Nullable<Date>;

export const useGetMemoizedDateValue: UseGetMemoizedDateHook = (statisticDate, endDateRangeDisabled) =>
  useMemo(() => {
    if (typeof endDateRangeDisabled === 'boolean') {
      return typeof statisticDate === 'string' && !endDateRangeDisabled ? new Date(statisticDate) : null;
    }

    return typeof statisticDate === 'string' ? new Date(statisticDate) : null;
  }, [statisticDate, endDateRangeDisabled]);
