import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { SchemaLink } from '@apollo/client/link/schema';
import { makeExecutableSchema } from '@graphql-tools/schema';
import { addMocksToSchema, MockList } from '@graphql-tools/mock';
import casual from 'casual-browserify';

import { errorLink, trackingLoadingLink } from './links';
import { typeDefs } from '../schema';

const schemaWithMocksLink = new SchemaLink({
  schema: addMocksToSchema({
    schema: makeExecutableSchema({ typeDefs }),
    mocks: {
      Query: () => ({
        adfoxBanners: () => new MockList(100),
        adfoxCampaigns: () => new MockList(100),
        excessCampaigns: () => new MockList(50),
      }),
      Short: () => Math.abs(casual.integer(0, 100)),
      Int: () => Math.abs(casual.integer(0, 100)),
      Long: () => Math.abs(casual.integer(0, 100)),
      ID: () => Math.abs(casual.integer(0, 100)),
      String: () => casual.title,
      Date: () => casual.date('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    },
  }),
});

export const fakeClient = new ApolloClient({
  link: from([trackingLoadingLink, errorLink, schemaWithMocksLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
