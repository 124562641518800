export const setValueToAllTreeElements = <
  T extends {
    children?: T[] | null;
  }
>(
  tree: T[],
  key: keyof T,
  value: T[keyof T],
): void => {
  for (const x of tree) {
    x[key] = value;
    if (x.children && x.children.length) {
      setValueToAllTreeElements(x.children, key, value);
    }
  }
};
