import React from 'react';
import * as P from 'primereact/paginator';
import * as R from 'ramda';

export const optionNames = [
  'rowsPerPageDropdown',
  'firstPageLink',
  'prevPageLink',
  'currentPageReport',
  'nextPageLink',
  'lastPageLink',
  'pageLinks',
  'jumpToPageInput',
] as const;

export const defaultOptions = R.zipObj(
  optionNames,
  R.range(0, optionNames.length).map(() => ({} as never)),
);

export const PaginatorContext = React.createContext<ContextType>({
  options: defaultOptions,
  setOptions: () => {},
});

export type ContextType = {
  options: Options;
  setOptions(options: Options): void;
};

export type Options = {
  rowsPerPageDropdown: P.PaginatorRowsPerPageDropdownOptions;
  firstPageLink: P.PaginatorFirstPageLinkOptions;
  prevPageLink: P.PaginatorPrevPageLinkOptions;
  currentPageReport: P.PaginatorCurrentPageReportOptions;
  nextPageLink: P.PaginatorNextPageLinkOptions;
  lastPageLink: P.PaginatorLastPageLinkOptions;
  pageLinks: P.PaginatorPageLinksOptions;
  jumpToPageInput: P.PaginatorJumpToPageInputOptions;
};
