import { Creative, ServerCreative, ServerCreativeType } from 'types/creative';
import { FileS3Id } from 'types/file';
import { CustomOptions, RequestConfig, Response } from '../types';
import { convertCreative, convertCreatives, convertFileToUrl } from '../helpers/converters/creatives';
import BaseApi from '../BaseApi';

class Creatives extends BaseApi {
  list = async (
    { campaignId }: Pick<CreativeData, 'campaignId'>,
    config: CustomOptions = {},
  ): Promise<Response<Creative[]>> => {
    const response = await this.actions.get<ServerCreative[]>({
      url: '/campaign-creatives/',
      data: { campaignId },
      config,
    });

    return this.convertData(response, convertCreatives);
  };

  create = async (
    { campaignId, name }: Omit<CreativeData, 'creativeId'>,
    config: CustomOptions = {},
  ): Promise<Response<Creative>> => {
    const response = await this.actions.post<ServerCreative>({
      url: '/campaign-creatives/save',
      queryParams: { campaignId, name },
      config,
    });

    return this.convertData(response, convertCreative);
  };

  rename = async (
    { creativeId, name }: Omit<CreativeData, 'campaignId'>,
    config: CustomOptions = {},
  ): Promise<Response<ServerCreative>> =>
    this.actions.post<ServerCreative>({
      url: '/campaign-creatives/update',
      queryParams: { creativeId, name },
      config,
    });

  delete = async (
    { creativeId }: Pick<CreativeData, 'creativeId'>,
    config: CustomOptions = {},
  ): Promise<Response<ServerCreative>> =>
    this.actions.post<ServerCreative>({
      url: '/campaign-creatives/delete',
      queryParams: { creativeId },
      config,
    });

  upload = async (
    { creativeId, formData }: Pick<CreativeData, 'creativeId'> & { formData: FormData },
    config: RequestConfig = {},
  ): Promise<Response<Creative>> => {
    const response = await this.actions.post<ServerCreative>({
      url: '/campaign-creatives/upload',
      queryParams: { creativeId },
      data: formData,
      config,
    });

    return this.convertData(response, convertCreative);
  };

  uploadExternal = async (
    { creativeId, externalResource }: Pick<CreativeData, 'creativeId'> & { externalResource: string },
    config: CustomOptions = {},
  ): Promise<Response<Creative>> => {
    const response = await this.actions.post<ServerCreative>({
      url: '/campaign-creatives/upload-external',
      queryParams: { creativeId, externalResource },
      config,
    });

    return this.convertData(response, convertCreative);
  };

  loadFileUrl = async (
    data: { fileId: FileS3Id; creativeType: ServerCreativeType | null },
    config: RequestConfig = {},
  ): Promise<Response<string | null>> => {
    const response = await this.download(data, config);

    return this.convertData(response, convertFileToUrl);
  };

  private download = async (
    data: { fileId: FileS3Id; creativeType: ServerCreativeType | null },
    config: RequestConfig = {},
  ): Promise<Response<Blob | null>> => {
    const { fileId, creativeType } = data;

    if (fileId && creativeType) {
      const response = await this.actions.get<{ data: Blob }>({
        url: '/campaign-creatives/download',
        data: { fileId, creativeType },
        config: { ...config, responseType: 'blob' },
      });

      if (response.data?.data instanceof Blob) {
        return { data: response.data.data, error: null };
      } else {
        return { data: null, error: response.error };
      }
    }

    return { data: null, error: null };
  };
}

type CreativeData = {
  campaignId: number;
  creativeId: number;
  name: string;
};

export default Creatives;
