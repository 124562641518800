import React, { VFC } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import { useStores } from 'core';
import { ServerModels as SM } from 'types/server';
import { localize } from 'shared/components/other';
import { DialogContent } from './components/DialogContent';

import classes from './CheckSettingsCell.module.scss';

type Props = {
  status: SM.ExcessSettingsStatus | null;
  rowId: string;
};

export const CheckSettingsCell: VFC<Props> = observer(({ status, rowId }) => {
  const { confirmDialogStore, excessStore } = useStores();
  const { loadRowCheckSettings, saveRowCheckSettings } = excessStore.childrenStores.checkSettings;
  const isSettingsDisabled = status === SM.ExcessSettingsStatus.None;
  const isSettingsNonVisible = status === SM.ExcessSettingsStatus.NonVisible || status === null;

  if (isSettingsNonVisible) {
    return null;
  }

  const showDialog = async () => {
    await loadRowCheckSettings(rowId);
    const { currentRowCheckSettings } = excessStore.childrenStores.checkSettings;
    const isSettingsReadOnly = currentRowCheckSettings.every((setting) => setting.readOnly);
    if (!currentRowCheckSettings.length) return;

    confirmDialogStore.show({
      message: <DialogContent />,
      showHeader: false,
      className: classes.dialog,
      acceptLabel: localize(isSettingsReadOnly ? 'ok' : 'button.save'),
      rejectLabel: localize('button.cancel'),
      rejectClassName: isSettingsReadOnly ? classes.nonVisible : undefined,
      accept: () => saveRowCheckSettings(rowId),
    });
  };

  return (
    <i
      className={cx('pi pi-cog', classes.root, { [classes.rootActive]: !isSettingsDisabled })}
      data-pr-tooltip={localize('programmatic.excess-table.settings.change')}
      data-pr-showdelay="300"
      onClick={showDialog}
    />
  );
});
