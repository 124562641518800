import { action, makeObservable, observable, runInAction, reaction } from 'mobx';

import { BaseStore } from 'shared/stores';
import { ActiveTab } from 'types/programmatic';
import { ServerModels as SM } from 'types/server';
import { ExcessStore } from './Excess.store';

type Deps = {
  excessStore: ExcessStore;
};

export class ExcessStatisticsStore extends BaseStore<Deps> {
  @observable
  private _csIdListForSelectedRows: string[] | null = null;

  private needToLoadData = true;

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);

    reaction(() => this.deps.excessStore.activeTab, this.init);
  }

  get csIdListForSelectedRows(): string[] | null {
    return this._csIdListForSelectedRows;
  }

  @action
  private init = async (): Promise<void> => {
    const { activeTab } = this.deps.excessStore;
    if (activeTab === ActiveTab.STATISTICS && this.needToLoadData) {
      await this.loadCsIdListForSelectedRows();

      runInAction(() => {
        this.needToLoadData = false;
      });
    } else if (activeTab === null) {
      this.needToLoadData = true;
      this._csIdListForSelectedRows = null;
    }
  };

  private loadCsIdListForSelectedRows = async (): Promise<void> => {
    const { mediaplanRowsFilters, selectedRowsFilters, selectedMediaplanRowIDs } = this.deps.excessStore;
    const { campaignIds } = selectedRowsFilters;
    const filters: SM.CampaignCsidFilters = { ...mediaplanRowsFilters, campaignIds };
    let selectedCampaignCsIds: string[] = [];
    if (filters.campaignIds) {
      const response = await this.services.api.programmatic.excess.campaignCsIds({ filters });
      const list = response?.data?.excessCampaignCSID?.map((x) => x.csId) ?? [];
      selectedCampaignCsIds = list?.filter(Boolean) as string[];
    }
    runInAction(() => {
      this._csIdListForSelectedRows = [...selectedCampaignCsIds, ...selectedMediaplanRowIDs];
    });
  };
}
