function round<T extends number | null>(value: T, precision?: number): T extends number ? number : null;
function round(value: number | null, precision = 0): number | null {
  if (value === null) {
    return null;
  }

  if (precision === Number.POSITIVE_INFINITY) {
    return value;
  }

  if (!Number.isInteger(precision)) {
    throw new TypeError('Expected precision to be an integer');
  }

  const sign = value < 0 ? -1 : 1;

  const power = 10 ** precision;

  return (sign * Math.round(Number((Math.abs(value) * power).toPrecision(15)))) / power;
}

export { round };
