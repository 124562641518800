/* eslint-disable class-methods-use-this */
import { CellContent, PivotStructure } from 'types/reportConstructor';
import { ReportConstructorStore } from '../../ReportConstructor.store';

export const createCustomLocalData = (store: ReportConstructorStore): typeof pivot.services.LocalData => {
  return class CustomLocalData extends pivot.services.LocalData {
    async collectFieldValues(fieldName: string): Promise<(CellContent | Record<string, CellContent>)[]> {
      const structure: PivotStructure = $$('pivot').getStructure();
      const items = await store.loadColumnItems(structure, fieldName);
      const fieldType = store.selectedColumns.find(({ value }) => value === fieldName)?.valueType;

      return webix.promise.resolve(
        fieldType === 'DATE'
          ? []
          : items.map((value) => ({ id: value, value: value === null && fieldType === 'STRING' ? '' : value })),
      );
    }
  };
};
