// eslint-disable-next-line no-restricted-globals
const { origin } = location;

const DEV = origin.includes('localhost');
const isPointsGroupProd = origin.includes('clientspace.pointsgroup.io');
export const isOnPointsGroup = origin.includes('.pointsgroup.');

const replace = (url: string) => {
  if (isPointsGroupProd) return url.replace('.aizek.io', '.pointsgroup.io');
  return isOnPointsGroup && !DEV ? url.replace('.advcloud.ru', '.pointsgroup.io') : url;
};

const Config = {
  api: {
    apiType: window.env.apiType,
    authType: window.env.authType,
    host: replace(window.env.host),
    authHost: replace(window.env.authHost),
    // TODO: remove cswUrl after api is ready
    cswUrl: replace(window.env.cswUrl),
    cswFrontUrl: replace(window.env.cswFrontUrl),
    authClientId: window.env.authClientId,
    authClientSecret: window.env.authClientSecret,
    headers: {
      // 'Pragma': 'no-cache',
      // 'Cache-control': 'no-cache'
    },
  },
  appOptions: {
    version: window.env.version,
    standType: window.env.standType,
  },
  services: {
    sentryDsn: window.env.sentryDsn,
    yandexMetrika: {
      account: window.env.yandexMetricAccount,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    },
  },
  isShowUploadStatistics: window.env.uploadStatisitcs,
};

export default Config;
