import { Row, GridRow, isGridDataRow, isGridTitleRow } from '..';

export function convertDataTreeToGridRows<DR>(dataTree: Row<DR>[]): GridRow<DR>[] {
  const array: Array<GridRow<DR>> = [];
  const add = (tree: Row<DR>[]) => {
    tree.forEach((dataRow) => {
      const { rowId, selectionState, depth, children, ...rest } = dataRow;
      if (depth === 0) {
        const dataGridTitleRow: GridRow<DR> = {
          ...rest,
          type: 'title',
          rowId: `${rowId}-title`,
        };
        array.push(dataGridTitleRow);
      }
      const dataGridRow: GridRow<DR> = {
        ...rest,
        type: 'data',
        rowId,
        selectionState,
        depth,
        isLastExpandedChild: false,
        hasChildren: children ? Boolean(children.length) : undefined,
      };
      array.push(dataGridRow);
      if (children && children.length && dataRow.isExpanded) {
        add(children);
      }
    });
  };

  add(dataTree);

  array.forEach((x, i) => {
    const prev = array[i - 1];
    if (i > 0 && isGridDataRow(prev) && isGridTitleRow(x)) {
      prev.isLastExpandedChild = true;
    }

    return x;
  });

  return array;
}
