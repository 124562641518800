import React, { CSSProperties, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { toDatePeriod } from 'helpers';
import { getLocalizedMediaType } from 'helpers/utils';
import { localize } from 'shared/components/other';
import { IColorTheme } from 'types';
import { IBrand } from 'types/brand';
import { ICampaign } from 'types/campaign';
import { FavoritesButton } from 'shared/components/common/buttons/FavoritesButton';
import { CampaignStatusLabel } from '../CampaignStatusLabel';
import { BudgetScale } from './components/BudgetScale';
import { InfoItem } from './components/InfoItem';
import classes from './CampaignCard.module.scss';

type Props = {
  brands: IBrand[];
  campaign: ICampaign;
  className?: string;
  locale: string;
  onFavoriteButtonClick?(): void;
  theme: IColorTheme;
};

export const CampaignCard: React.FC<Props> = (props) => {
  const { className, campaign, brands, theme, onFavoriteButtonClick, locale } = props;
  const currentBrand: IBrand | undefined = brands.find((brand) => brand.id === campaign.brands[0]);
  const mediaType = campaign.mediaTypes.map(getLocalizedMediaType).join(' + ');
  const period = toDatePeriod(campaign);

  const styles = useMemo(
    () =>
      ({
        '--color-background': theme.background,
        '--color-main-text': theme.mainText,
        '--color-additional-text': theme.additionalText,
      } as CSSProperties),
    [theme],
  );

  const handleFavoriteButtonChange = useCallback(() => {
    onFavoriteButtonClick?.();
  }, [onFavoriteButtonClick]);

  return (
    <div className={cx(classes.root, className)} style={styles}>
      <div className={classes.name} data-global-tooltip data-pr-tooltip={campaign.name}>
        {campaign.name}
      </div>
      <FavoritesButton
        className={classes.favoriteButton}
        checked={campaign.favorite}
        onChange={handleFavoriteButtonChange}
      />
      <div className={classes.status}>
        <CampaignStatusLabel value={campaign.campaignStatus} />
      </div>
      <div className={classes.info}>
        <InfoItem title={localize('campaign.brand')} tooltip={currentBrand?.name} value={currentBrand?.name} />
        <InfoItem title={localize('campaign.media-type')} tooltip={mediaType} value={mediaType} />
        <InfoItem value={period} />
      </div>
      {!campaign.hideBudgetScale && (
        <BudgetScale
          className={classes.budgetScale}
          budgetFact={campaign.totalBudgetFact}
          budgetPlan={campaign.totalBudgetPlan}
          lang={locale}
        />
      )}
    </div>
  );
};
