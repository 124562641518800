import React, { MouseEventHandler } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { ActionStatus as Status } from 'types';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import classes from './StatusIndicators.module.scss';

type Props = {
  className?: string;
  onUpdate?: MouseEventHandler<HTMLElement>;
  status: Status | null;
  tooltip?: string;
};

export const StatusIndicators: React.VFC<Props> = (props) => {
  const { className, onUpdate, status, tooltip } = props;
  const statusClass = `${status?.toLowerCase().replace(/_/g, '')}Status`;

  return (
    <div className={cx(classes.root, className, { [classes.updatable]: !!onUpdate })}>
      <i className={cx(classes.status, { [classes[statusClass]]: !!status })} title={tooltip} />
      {status && status !== Status.SUCCESS && onUpdate && (
        <TextButton
          className={classes.updateButton}
          icon="icon icon-spinner"
          onClick={onUpdate}
          tooltip={localize('update-status')}
        />
      )}
    </div>
  );
};

export type { Props as StatusIndicatorsProps };
