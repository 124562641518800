export const createCustomMode = (): typeof pivot.views.mode => {
  return class CustomMode extends pivot.views.mode {
    config() {
      const config = super.config();
      const modeCol = config.cols[0];

      if (Object.keys(modeCol).length !== 0) {
        modeCol.options = modeCol.options.filter(({ id }: { id: string }) => id !== 'chart');
        modeCol.width = modeCol.optionWidth * modeCol.options.length;
      }

      return config;
    }
  };
};
