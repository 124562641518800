import { localize } from 'shared/components/other';
import { MediaTypes } from 'types/cabinetCopying';
import { ImageFormats, VideoFormats } from 'types/file';

import { getStrFileExtensions } from './getStrFileExtensions';

export const mediaTypeToFormatMap = {
  [MediaTypes.IMAGE]: getStrFileExtensions(Object.values(ImageFormats)),
  [MediaTypes.VIDEO]: getStrFileExtensions(Object.values(VideoFormats)),
};

export const mediaTypeToLabelMap = {
  [MediaTypes.IMAGE]: localize('cabinet-copying.uploader.image'),
  [MediaTypes.VIDEO]: localize('cabinet-copying.uploader.video'),
};
