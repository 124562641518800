import { CustomOptions } from 'api/types';
import {
  FieldDescription,
  ISavedServerField,
  IFieldForSave,
  IPowerBIFilters,
  IPowerBIResponse,
  ICommonPowerBIProps,
  PowerBIUrlList,
  IPowerBiFilterItem,
  IPowerBiComparePeriodType,
  IHyundaiAuthCookie,
  FilterType,
} from 'types/powerBI';
import { serialize } from 'helpers';
import BaseApi from '../BaseApi';

class Powerbi extends BaseApi {
  getFieldDescriptions = (config: CustomOptions = {}) =>
    this.actions.get<FieldDescription[]>({
      url: '/power-bi/find-bi-url-pages',
      config,
    });

  getDefaultUrls = (config: CustomOptions = {}) =>
    this.actions.get<ISavedServerField[]>({
      url: '/power-bi/find-bi-urls',
      config,
    });

  saveDefaultUrls = ({ fields }: SaveDefaultUrlsData, config: CustomOptions = {}) =>
    this.actions.post<ISavedServerField>({
      url: '/power-bi/save-bi-urls',
      data: fields,
      config,
    });

  getAdvertiserUrls = ({ customerId }: GetAdvertiserUrlsData, config: CustomOptions = {}) =>
    this.actions.get<ISavedServerField[]>({
      url: '/power-bi/find-custom-bi-urls',
      data: { customerId },
      config,
    });

  getCustomReportUrls = (request: IPowerBIRequest, config: CustomOptions = {}) => {
    const query = serialize(request as Partial<IPowerBIRequest>);
    return this.actions.post<PowerBIUrlList>({
      url: `/power-bi/get-report-url-list?${query}`,
      config,
    });
  };

  deleteCustomField = ({ biUrlId }: DeleteCustomFieldData, config: CustomOptions = {}) =>
    this.actions.delete({
      url: '/power-bi/remove-bi-url',
      queryParams: { biUrlId },
      config,
    });

  deleteCustomFields = ({ fieldIds }: DeleteCustomFieldsData, config: CustomOptions = {}) =>
    this.actions.delete({
      url: '/power-bi/remove-bi-urls',
      data: fieldIds,
      config,
    });

  getReportUrl = ({ csIds, startDate, endDate, ...request }: IPowerBIRequest, config: CustomOptions = {}) => {
    const query = serialize(request as Partial<IPowerBIRequest>);
    const hasCustomUrlParameters = csIds?.length || startDate || endDate;
    const body: Partial<IPowerBIRequestBody> = hasCustomUrlParameters
      ? {
          customUrlParameters: {
            'ec-csid': csIds?.length ? csIds : undefined,
            'start-date': startDate ? [startDate] : undefined,
            'end-date': endDate ? [endDate] : undefined,
          },
        }
      : {};

    return this.actions.post<IPowerBIResponse>({
      url: `/power-bi/get-report-url?${query}`,
      data: body,
      config,
    });
  };

  getHyundaiReportUrl = (request: IPowerBIFilters, config: CustomOptions = {}) => {
    const body: Partial<IPowerBIRequestBody> = {
      customUrlParameters: {
        fCommunication: request.communication?.length ? request.communication : undefined,
        fMonth: request.months?.length ? request.months : undefined,
        fYear: request.years?.length ? request.years : undefined,
        fDetailedBy: request.detailedBy ? [request.detailedBy] : undefined,
        fComparePeriod1: request.period1?.length ? request.period1 : undefined,
        fComparePeriod2: request.period2?.length ? request.period2 : undefined,
        fComparePeriodType: request.periodType ? [request.periodType] : undefined,
      },
    };

    return this.actions.post<IPowerBIResponse>({
      url: '/power-bi/custom/hyundai/get-report-url',
      data: body,
      config: { withToken: false, ...config },
    });
  };

  getHyundaiFiltersByType = <T extends FilterType>(
    { filterType }: GetHyundaiFiltersByTypeData<T>,
    config: CustomOptions = {},
  ) =>
    this.actions.get<FilterItem<T>[]>({
      url: `/power-bi/custom/hyundai/filter/${filterType}`,
      config: { withToken: false, ...config },
    });

  getHyundaiComparePeriods = (filters: GetHyundaiComparePeriodsData, config: CustomOptions = {}) => {
    const data = {
      fDetailedBy: filters.detailedBy,
      fYears: filters.years?.length ? String(filters.years) : undefined,
      fMonths: filters.months?.length ? String(filters.months) : undefined,
    };

    return this.actions.get<IPowerBiFilterItem[]>({
      url: '/power-bi/custom/hyundai/filter/get-compare-periods',
      data,
      config: { withToken: false, ...config },
    });
  };

  getHyundaiAuthCookie = ({ reportUrl }: GetHyundaiAuthCookieData, config: CustomOptions = {}) =>
    this.actions.post<IHyundaiAuthCookie>({
      url: '/power-bi/custom/hyundai/authorize',
      data: { reportUrl: encodeURIComponent(reportUrl) },
      config,
    });
}

type SaveDefaultUrlsData = {
  fields: IFieldForSave[];
};

type GetAdvertiserUrlsData = { customerId: number };

type DeleteCustomFieldData = { biUrlId: number };

type DeleteCustomFieldsData = { fieldIds: number[] };

type GetHyundaiFiltersByTypeData<T> = { filterType: T };

type GetHyundaiComparePeriodsData = {
  detailedBy: string;
  years?: number[];
  months?: string[];
};

type GetHyundaiAuthCookieData = { reportUrl: string };

type FilterItem<T extends FilterType> = T extends 'compare-period-type'
  ? IPowerBiComparePeriodType
  : IPowerBiFilterItem;

interface IPowerBIRequestBody {
  customUrlParameters: {
    fCommunication?: string[];
    fMonth?: string[];
    fYear?: string[];
    fDetailedBy?: string[];
    fComparePeriod1?: string[];
    fComparePeriod2?: string[];
    fComparePeriodType?: string[];
    'ec-csid'?: string[];
    'start-date'?: string[];
    'end-date'?: string[];
  };
}

interface IPowerBIRequest extends Omit<ICommonPowerBIProps, 'advertiserId'> {
  customerId?: number;
}

export default Powerbi;
