import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from 'core';
import { ConfirmDialog as PrimeConfirmDialog } from 'primereact/confirmdialog';

export const ConfirmDialog = observer(() => {
  const {
    confirmDialogStore: { isVisible, settings, hide },
  } = useStores();
  const { accept, reject, ...props } = settings;

  return <PrimeConfirmDialog visible={isVisible} onHide={hide} {...props} accept={accept} reject={reject} />;
});
