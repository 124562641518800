import React from 'react';
import { Link } from 'react-router-dom';

import { IOption } from 'types';
import { ServerModels as SM } from 'types/server';
import { Column, BaseCell } from 'shared/components/other';
import { FilterButtonCell } from './components';
import { CheckStatusCell } from '../../../../../ExcessTable/components';
import { recordTypeOptions } from '../../../../../../helpers/selectionOptions';

import classes from '../../Log.module.scss';

export const getColumns = (advertisersOptions: IOption[]): Column<SM.OperationLogEntry>[] => [
  {
    key: 'timeCreate',
    name: 'Время',
    type: 'datetime',
    resizable: true,
    isSortable: true,
    filter: { type: 'date', hasTime: true },
    width: 95,
  },
  {
    key: 'status',
    name: 'Тип записи',
    resizable: true,
    isSortable: true,
    filter: {
      type: 'multiselect',
      options: recordTypeOptions,
    },
    formatter: ({ row }) => <CheckStatusCell {...recordTypeOptions.find((o) => o.id === row.data.status)} />,
    width: 130,
  },
  {
    key: 'advertiserId',
    name: 'Клиент',
    resizable: true,
    isSortable: true,
    filter: {
      name: 'advertiserIds',
      type: 'multiselect',
      options: advertisersOptions,
    },
    width: 200,
    textAlign: 'left',
    formatter: ({ row }) => {
      const advertiserName = advertisersOptions.find((o) => o.value === row.data.advertiserId)?.label;

      return <BaseCell value={advertiserName ?? null} />;
    },
  },
  {
    key: 'campaignName',
    name: 'Кампания',
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    width: 200,
    textAlign: 'left',
    formatter: ({ row }) => (
      <Link to={`/campaigns/${row.data.campaignId}`} className={classes.link}>
        <BaseCell value={row.data.campaignName ?? null} />
      </Link>
    ),
  },
  {
    key: 'message',
    name: 'Событие',
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    minWidth: 300,
    textAlign: 'left',
  },
  {
    key: 'cause',
    name: 'Причины',
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    minWidth: 450,
    textAlign: 'left',
  },
  {
    key: 'userName',
    name: 'Пользователь',
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    width: 180,
    textAlign: 'left',
  },
  {
    key: 'csId',
    name: 'CSID',
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    width: 110,
    formatter: ({ row }) => <FilterButtonCell label={row.data.csId || null} filters={{ csId: row.data.csId }} />,
  },
];
