import { CustomOptions, PageData } from 'api/types';
import { IPagingServerResponse } from 'types';
import { ICategory } from 'types/category';
import BaseApi from '../BaseApi';

class Categories extends BaseApi {
  list = ({ customerId, pageSize = 10000 }: GetCategoryListData, config: CustomOptions = {}) =>
    this.actions.get<IPagingServerResponse<ICategory[]>>({
      url: '/category/',
      data: { customerId, pageSize },
      config,
    });

  save = (data: Omit<ICategory, 'id'>, config: CustomOptions = {}) =>
    this.actions.post<ICategory>({
      url: '/category/save',
      data,
      config,
    });
}

type GetCategoryListData = PageData & { customerId: number };

export default Categories;
