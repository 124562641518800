import chroma from 'chroma-js';
import { RGBColor } from 'react-color';

export function toRGBAColor(color: string): RGBColor {
  const [r, g, b, a] = chroma(color).rgba();
  return { r, g, b, a };
}

export const toHexColor = (rgbaColor: RGBColor): string => {
  const { r, g, b, a = 1 } = rgbaColor;

  return chroma([r, g, b, a]).hex();
};
