import React, { useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { ToggleButton } from 'primereact/togglebutton';

import { useStores } from 'core';
import { GridRef, DataGridFilters, DataGridSorting, ManagedPaginationButtons } from 'shared/components/other';
import { defaultTooltipOptions } from 'shared/components/common/defaultProps';
import { ClearButton } from 'shared/components/common/buttons/ClearButton';
import { toServerSorting } from 'helpers';
import { LogTable } from './components/LogTable';

import classes from './Log.module.scss';

const Log = observer(() => {
  const {
    advertiserStore: { advertisers },
    excessStore,
  } = useStores();
  const {
    data,
    totalItems,
    dataGridFilters,
    setFilters,
    clearAllFilters,
    setSorting,
    hasFilters: hasLogFilters,
    hasExcessCampaignFilters,
    hasExcessSelectedRowsFilters,
    isExcessCampaignFiltersEnabled,
    isExcessSelectedRowsFiltersEnabled,
    toggleExcessCampaignFilters,
    toggleExcessSelectedRowsFilters,
  } = excessStore.childrenStores.log;
  const gridRef = useRef<GridRef['current']>(null);

  const handleDataGridFilterChange = useCallback(
    (gridFilters: DataGridFilters) => {
      setFilters(gridFilters);
    },
    [setFilters],
  );

  const clearFilters = () => {
    clearAllFilters();
    gridRef.current?.clearFilters();
  };

  const handleDataGridSortingChange = useCallback(
    (sortingOptions: DataGridSorting) => {
      setSorting(toServerSorting(sortingOptions));
    },
    [setSorting],
  );

  return (
    <div className={classes.log}>
      <div className={classes.header}>
        <div className={classes.buttonGroup}>
          <ClearButton
            className={classes.clearButton}
            disabled={!hasLogFilters}
            onClick={clearFilters}
            tooltip="Сбросить фильтры лога"
            tooltipOptions={{ ...defaultTooltipOptions, position: 'right' }}
          />
          {hasExcessCampaignFilters && (
            <ToggleButton
              checked={isExcessCampaignFiltersEnabled}
              onChange={toggleExcessCampaignFilters}
              onLabel="Фильтры основной таблицы"
              offLabel="Фильтры основной таблицы"
              tooltip={`${isExcessCampaignFiltersEnabled ? 'Отключить' : 'Включить'} фильтры основной таблицы`}
              tooltipOptions={{
                ...defaultTooltipOptions,
                className: classes.toggleButtonTooltip,
              }}
              className={cx(classes.toggleButton, {
                [classes.toggleButtonActive]: !isExcessCampaignFiltersEnabled,
              })}
            />
          )}
          {hasExcessSelectedRowsFilters && (
            <ToggleButton
              checked={isExcessSelectedRowsFiltersEnabled}
              onChange={toggleExcessSelectedRowsFilters}
              onLabel="Фильтр по выбранным CSID"
              offLabel="Фильтр по выбранным CSID"
              tooltip={`${isExcessSelectedRowsFiltersEnabled ? 'Отключить' : 'Включить'} фильтр по выбранным CSID`}
              tooltipOptions={{
                ...defaultTooltipOptions,
                className: classes.toggleButtonTooltip,
              }}
              className={cx(classes.toggleButton, {
                [classes.toggleButtonActive]: !isExcessSelectedRowsFiltersEnabled,
              })}
            />
          )}
        </div>
      </div>
      <LogTable
        gridRef={gridRef}
        data={data}
        dataGridFilters={dataGridFilters}
        advertisers={advertisers}
        onDataGridFilterChange={handleDataGridFilterChange}
        onDataGridSortingChange={handleDataGridSortingChange}
      />
      <div className={classes.footer}>
        <span className={classes.info}>Общее количество записей: {totalItems}</span>
        <ManagedPaginationButtons pagination={excessStore.childrenStores.log} />
      </div>
    </div>
  );
});

export { Log };
