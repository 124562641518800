import { createContext, useContext } from 'react';

import { EditAdvertiserStore } from 'routes/Administration/EditAdvertiser';
import { EditBrandsStore } from 'routes/Administration/EditBrands';
import { AppearanceStore } from 'routes/Administration/Appearance';
import { TemplatesStore } from 'routes/Administration/Templates';
import { PowerBiStore } from 'routes/Administration/PowerBiSettings';
import { CampaignsStore } from 'routes/Campaigns';
import { CampaignViewStore } from 'routes/CampaignView';
import { CampaignEditStore } from 'routes/CampaignEdit';
import { BrandsStore } from 'routes/Brands';
import { BrandViewStore } from 'routes/BrandView';
import { AccountsStore } from 'routes/Accounts';
import { AccountStore } from 'routes/Account';
import { UploadStatisticsStore } from 'routes/UploadStatistics';
import { ExcessStore } from 'routes/Programmatic';
import { AdFoxCampaignsStore, AdFoxBannersStore, AdFoxLogStore } from 'routes/tradingDesk';
import { ReportConstructorStore, ReportListStore } from 'routes/reportConstructor';
import { CabinetCopyingStore } from 'routes/CabinetCopying';
import { sharedStores } from 'shared/stores';

function initFeatureStores() {
  const editAdvertiserStore = new EditAdvertiserStore();
  const editBrandsStore = new EditBrandsStore();
  const appearanceStore = new AppearanceStore();
  const templatesStore = new TemplatesStore();
  const powerBiStore = new PowerBiStore();

  const campaignsStore = new CampaignsStore();
  const campaignViewStore = new CampaignViewStore();
  const campaignEditStore = new CampaignEditStore();

  const brandsStore = new BrandsStore();
  const brandViewStore = new BrandViewStore();

  const accountsStore = new AccountsStore();
  const accountStore = new AccountStore();

  const uploadStatisticsStore = new UploadStatisticsStore();

  const excessStore = new ExcessStore();

  const adFoxCampaignsStore = new AdFoxCampaignsStore();
  const adFoxBannersStore = new AdFoxBannersStore();
  const adFoxLogStore = new AdFoxLogStore({
    dictionaries: {
      campaigns: () => adFoxCampaignsStore.dictionaries,
      banners: () => adFoxBannersStore.dictionaries,
    },
  });

  const reportConstructorStore = new ReportConstructorStore();
  const reportListStore = new ReportListStore({ reportConstructorStore });

  const cabinetCopyingStore = new CabinetCopyingStore();

  return {
    editAdvertiserStore,
    editBrandsStore,
    appearanceStore,
    templatesStore,
    powerBiStore,
    campaignsStore,
    campaignViewStore,
    campaignEditStore,
    brandsStore,
    brandViewStore,
    accountsStore,
    accountStore,
    uploadStatisticsStore,
    excessStore,
    adFoxCampaignsStore,
    adFoxBannersStore,
    adFoxLogStore,
    reportConstructorStore,
    reportListStore,
    cabinetCopyingStore,
  };
}

const featureStores = initFeatureStores();

export const stores = Object.assign(sharedStores, featureStores);

export const StoreContext = createContext(stores);

export const useStores = (): typeof stores => useContext(StoreContext);
