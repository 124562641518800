import React from 'react';

import { sharedStores } from 'shared/stores';
import { LocaleIdType } from 'locales';
import { useStores } from 'core';

export const localizeMessage = (
  { id }: { id: LocaleIdType },
  values: Record<string, string> = {},
  messages: Partial<Record<LocaleIdType, string>> = sharedStores.localeStore.messages,
): string => {
  if (typeof messages[id] === 'undefined') {
    console.warn(new Error(`Localized string '${id}' not found.`));

    return '';
  }

  let resultMessage = messages[id] as string;

  Object.entries(values).forEach(([key, value]) => {
    const re = new RegExp(`{${key}}`, 'g');
    resultMessage = resultMessage.replace(re, value);
  });

  return resultMessage;
};

export const localize = (id: LocaleIdType): string => localizeMessage({ id });

interface IProps {
  id: LocaleIdType | LocaleIdType[];
  values?: Record<string, string>;
  children?: React.ReactNode;
}

const LocalizedMessage: React.FC<IProps> = ({ children, id, values = {} }: IProps) => {
  const {
    localeStore: { messages },
  } = useStores();
  if (typeof children === 'function') {
    return children.apply(
      children,
      Array.isArray(id)
        ? id.map((localId) => localizeMessage({ id: localId }, values, messages))
        : [localizeMessage({ id }, values, messages)],
    );
  }

  if (Array.isArray(id)) {
    return id.map((localId) => localizeMessage({ id: localId }, values, messages)).join(' ');
  }

  return localizeMessage({ id }, values, messages);
};

export default LocalizedMessage;
