import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import classes from './ColorButton.module.scss';
import { Button, ButtonProps } from '../Button';

type Props = Omit<ButtonProps, 'onClick' | 'color' | 'padding' | 'iconColor'> & {
  /** Additional className */
  className?: string;
  /** Component color. Default: null */
  color?: string | null;
  /** On click event handler */
  onClick?(color: string | null): void;
};

export const ColorButton: React.VFC<Props> = (props) => {
  const { className, color = null, onClick, ...restProps } = props;

  const handleClick = useCallback(() => {
    onClick?.(color);
  }, [color, onClick]);

  const style = useMemo(() => (color ? { background: color } : undefined), [color]);

  return (
    <Button
      className={cx(classes.root, className, { [classes.noColor]: !color })}
      onClick={handleClick}
      style={style}
      {...restProps}
    />
  );
};

export type { Props as ColorButtonProps };
