import React from 'react';

import { SearchInput } from 'shared/components/common/form/SearchInput';
import { useDataGrid } from '../../../context';

import classes from './TextFilter.module.scss';

type Props = {
  name: string;
  placeholder?: string;
};

export const TextFilter: React.FC<Props> = ({ name, placeholder }) => {
  const { filters, setFilters } = useDataGrid();
  const filter = filters[name] ?? null;
  const value = filter ? String(filter.value) : undefined;
  const iconClass = filter ? 'pi-filter-fill' : 'pi-filter';

  const onSearch = (newValue: string) => {
    setFilters({ [name]: newValue ? { type: 'text', value: newValue } : null });
  };

  const onClear = () => {
    setFilters({ [name]: null });
  };

  return (
    <SearchInput
      className={classes.root}
      defaultValue={value}
      tooltip={value}
      tooltipOptions={{ className: classes.tooltip }}
      placeholder={placeholder}
      searchIcon={`pi ${iconClass}`}
      onChange={onSearch}
      onClear={onClear}
      size="small"
    />
  );
};
