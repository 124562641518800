import { DependencyList, EffectCallback, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';

export const useDidMount = (callback: EffectCallback): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);
};

export function usePrevious<T>(value: T): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

type SearchParamsAction = 'push' | 'replace';

export function useSearchParams(): {
  searchParams: URLSearchParams;
  addSearchParams: (value: Record<string, string>, action?: SearchParamsAction) => void;
  removeSearchParam: (value: string, action?: SearchParamsAction) => void;
} {
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const addSearchParams = useCallback(
    (value: Record<string, string>, action: SearchParamsAction = 'push') => {
      const nextSearchParams = new URLSearchParams(
        R.pipe(Object.fromEntries, R.mergeLeft(value))(searchParams.entries()),
      );
      if (nextSearchParams.toString() !== searchParams.toString()) {
        history[action]({ ...history.location, search: nextSearchParams.toString() });
      }
    },
    [history, searchParams],
  );
  const removeSearchParam = useCallback(
    (value: string, action: SearchParamsAction = 'push') => {
      if (!searchParams.has(value)) return;
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(value);
      history[action]({ ...history.location, search: newSearchParams.toString() });
    },
    [history, searchParams],
  );
  return { searchParams, addSearchParams, removeSearchParam };
}

export function useEffectOnce<T extends DependencyList>(
  effect: () => void,
  deps: T,
  condition: (deps: T) => boolean,
): void {
  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current || !condition(deps)) return;
    effect();
    calledOnce.current = true;
  }, [effect, condition, deps]);
}
