/* eslint-disable no-param-reassign */
import { format } from 'date-fns';
import hasAccess from 'helpers/hasAccess';
import showConfirmation from 'helpers/showConfirmation';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import * as R from 'ramda';
import { toast } from 'shared/components/common/misc/Toast';
import { localize } from 'shared/components/other';

import { BaseStore } from 'shared/stores';
import { EmptyObject, Nullable } from 'types';
import {
  Column,
  ColumnOption,
  Columns,
  DataRow,
  DateFilter,
  LevelFilters,
  Option,
  OptionFilters,
  ReportFilters,
  ReportLevelType,
  PivotStructure,
  CellContent,
  ReportData,
  ReportMode,
  ReportType,
  ReportLevel,
  UpdateReportArgs,
  SaveReportArgs,
  AggregatedTreeData,
  AggregatedTableData,
} from 'types/reportConstructor';
import { parseComplexMath } from 'api/helpers/converters/customBackend/helpers';
import { PivotStore } from './Pivot.store';
import { emptyPivotData } from './helpers/overridePivot/config';

type FilterOption = {
  options?: Option[];
  disabled?: boolean;
};

type FilterOptions = Record<keyof Required<OptionFilters>, FilterOption>;

type ModalState = 'step1' | 'step2' | null;

type ChildrenStores = {
  pivot: PivotStore;
};

export class ReportConstructorStore extends BaseStore<EmptyObject, ChildrenStores> {
  private readonly _initialFilterOptions: FilterOptions = {
    reportLevelType: {
      options: [
        { value: 'ADVERTISER', label: 'Рекламодатель', selected: true },
        { value: 'BRAND', label: 'Бренд' },
        { value: 'CAMPAIGN', label: 'Кампания' },
        { value: 'CATEGORY', label: 'Категория' },
        { value: 'SUBBRAND', label: 'Саббренд' },
      ],
      disabled: true,
    },
    advertisers: {
      disabled: true,
    },
    brands: {
      disabled: true,
    },
    campaigns: {
      disabled: true,
    },
    categories: {
      disabled: true,
    },
    subbrands: {
      disabled: true,
    },
    reportType: {
      options: [
        { value: 'PLAN', label: 'План', selected: true },
        { value: 'FACT', label: 'Факт' },
        { value: 'PLANFACT', label: 'План-факт' },
      ],
      disabled: true,
    },
    cabinetIds: {
      disabled: true,
    },
  };

  @observable
  filterOptions: FilterOptions = this._initialFilterOptions;

  @observable
  reportName = '';

  @observable
  isCabinetsFilterActive = false;

  @observable
  modalState: ModalState = null;

  @observable
  mode: ReportMode = 'new';

  @observable
  private _dateFilter: DateFilter = {
    statisticDateStart: null,
    statisticDateEnd: null,
  };

  @observable
  private _columns: ColumnOption[] = [];

  @observable
  private _reportData: ReportData | null = null;

  private readonly _levelTypeToFilterNameMap: Record<ReportLevelType, keyof LevelFilters> = {
    ADVERTISER: 'advertisers',
    BRAND: 'brands',
    CAMPAIGN: 'campaigns',
    CATEGORY: 'categories',
    SUBBRAND: 'subbrands',
  };

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      pivot: new PivotStore({ reportConstructorStore: this }),
    };
  }

  @computed
  get filters(): ReportFilters {
    const optionFilters = R.mapObjIndexed((filterOption, filterName) => {
      const selectedValues = filterOption?.options?.filter((option) => option.selected).map((option) => option.value);
      return ['reportType', 'reportLevelType'].includes(filterName) ? selectedValues?.[0] : selectedValues;
    }, this.filterOptions) as OptionFilters;

    return { ...optionFilters, ...this._dateFilter };
  }

  @computed
  get columns(): Columns {
    const sortColumns = (column1: ColumnOption, column2: ColumnOption) => {
      if (column1.selected && !column2.selected) {
        return -1;
      } else if (!column1.selected && column2.selected) {
        return 1;
      }
      return column1.label > column2.label ? 1 : -1;
    };

    return {
      attributes: this._columns.filter(({ columnType }) => columnType === 'ATTRIBUTE').sort(sortColumns),
      metrics: this._columns.filter(({ columnType }) => columnType === 'METRIC').sort(sortColumns),
    };
  }

  @computed
  get selectedAttributes(): ColumnOption[] {
    return this.columns.attributes.filter(({ selected }) => selected);
  }

  @computed
  get selectedMetrics(): ColumnOption[] {
    return this.columns.metrics.filter(({ selected }) => selected);
  }

  @computed
  get selectedColumns(): ColumnOption[] {
    return [...this.selectedAttributes, ...this.selectedMetrics];
  }

  @computed
  get reportLevel(): ReportLevel {
    const levelType = this.filters.reportLevelType;
    const levelFilterName = this._levelTypeToFilterNameMap[levelType];

    return {
      level: levelType,
      values: this.filters[levelFilterName] ?? [],
    };
  }

  @computed
  get isFirstStepValid(): boolean {
    const isLevelSelected = !!this.reportLevel.values.length;
    const isReportTypeSelected = !!this.filters.reportType;
    const isCabinetsValid = !this.isCabinetsFilterActive || !!this.filters.cabinetIds?.length;
    const isReportNameValid = !this.reportName || this.isReportNameValid;

    return isReportNameValid && !this.isDateFilterEmpty && isLevelSelected && isReportTypeSelected && isCabinetsValid;
  }

  @computed
  get isSecondStepValid(): boolean {
    return this.isReportNameValid && this.selectedAttributes.length > 0 && this.selectedMetrics.length > 0;
  }

  @computed
  get isReportNameValid(): boolean {
    return this.reportName.length >= 5 && this.reportName.length <= 255;
  }

  @computed
  private get isDateFilterEmpty(): boolean {
    return this._dateFilter.statisticDateStart === null || this._dateFilter.statisticDateEnd === null;
  }

  @computed
  get isLimitedAccess(): boolean {
    const isUserCreator = this._reportData?.authorKeycloakId === this.deps.authStore.profile?.id;
    return this.mode === 'edit' && !isUserCreator && !hasAccess('editReportLevelType', 'reportConstructorPage');
  }

  @computed
  get reportStructure(): PivotStructure | null {
    return this._reportData?.structure ?? null;
  }

  @action
  cleanUp = (): void => {
    this.filterOptions = this._initialFilterOptions;
    this._dateFilter = {
      statisticDateStart: null,
      statisticDateEnd: null,
    };
    this.isCabinetsFilterActive = false;
    this._columns = [];
    this.setReportName('');
    this.setModalState(null);
    this._reportData = null;
    this.mode = 'new';
    this.childrenStores.pivot.cleanUp();
  };

  loadReportLevel = async (reportLevelType: ReportLevelType): Promise<void> => {
    const levelFilterName = this._levelTypeToFilterNameMap[reportLevelType];
    const { data } = await this.services.api.reportConstructor.loadReportLevel({ ...this.filters, reportLevelType });

    runInAction(() => {
      if (levelFilterName) {
        this.filterOptions[levelFilterName] = { options: data ?? [] };
      }
    });
  };

  loadCabinets = async (selectedIds?: number[]): Promise<void> => {
    const { statisticDateStart, statisticDateEnd } = this._dateFilter;

    if (!statisticDateStart || !statisticDateEnd || !this.reportLevel.values.length) {
      return;
    }

    const { data } = await this.services.api.reportConstructor.loadCabinets({
      statisticDateStart,
      statisticDateEnd,
      reportLevel: this.reportLevel,
    });

    runInAction(() => {
      this.filterOptions.cabinetIds = {
        options: data?.map((option) => ({ ...option, selected: selectedIds?.includes(option.value) })) ?? [],
      };
    });
  };

  loadColumns = async (selectedIds: string[] = []): Promise<void> => {
    const { data } = await this.services.api.reportConstructor.loadColumns();

    runInAction(() => {
      this._columns =
        data
          ?.sort((a, b) => (a.localizedColumnName > b.localizedColumnName ? -1 : 1))
          .map((column: Column) => ({
            value: column.columnName,
            label: column.localizedColumnName,
            columnType: column.columnType,
            valueType: column.valueType,
            usedInAggregation: column.usedInAggregation,
            selected: selectedIds.includes(column.columnName),
          })) ?? [];
    });
  };

  loadData = async (structure: PivotStructure, emptyDataNotification = true): Promise<DataRow[]> => {
    const response = await this.services.api.reportConstructor.loadData({
      structure,
      reportFilters: this.filters,
      reportLevel: this.reportLevel,
      selectedColumns: this.selectedColumns,
    });

    return runInAction(() => {
      if (response.error) {
        return [];
      }

      if (response.data.data.length === 0 && emptyDataNotification) {
        toast.info(localize('report-constructor.errors.missing-data'));
      } else if (response.data.hasNextPage) {
        toast.info(localize('report-constructor.errors.report-cannot-be-fully-displayed'));
      }

      return response.data.data;
    });
  };

  loadDataForCustomBackend = async (
    structure: PivotStructure,
    emptyDataNotification = true,
  ): Promise<{ tree: AggregatedTreeData; table: AggregatedTableData }> => {
    const response = await this.services.api.reportConstructor.loadDataForCustomBackend(
      {
        structure,
        reportFilters: this.filters,
        reportLevel: this.reportLevel,
        selectedColumns: this.selectedColumns,
      },
      this._columns,
    );
    return runInAction(() => {
      const hasError = Boolean(response.error);

      if (response.data && !hasError) {
        const { tree, table } = response.data.data;
        return { tree, table };
      }

      if (!hasError && !response.data && emptyDataNotification) {
        toast.info(localize('report-constructor.errors.missing-data'));
      } else if (!hasError && response.data?.hasNextPage) {
        toast.info(localize('report-constructor.errors.report-cannot-be-fully-displayed'));
      }

      return { ...emptyPivotData, hasNextPage: false };
    });
  };

  loadColumnItems = async (
    structure: PivotStructure,
    fieldName: string,
  ): Promise<(CellContent | Record<string, CellContent>)[]> => {
    const response = await this.services.api.reportConstructor.loadData(
      {
        structure,
        reportFilters: this.filters,
        reportLevel: this.reportLevel,
        selectedColumns: this.selectedColumns,
        fieldName,
      },
      { withoutComplex: true },
    );

    return runInAction(() => {
      if (response.error) {
        return [];
      }

      return response.data.data.map((row) => row[fieldName]);
    });
  };

  goBack = async (): Promise<void> => {
    const isConfirmed = await showConfirmation({
      title: localize('report-constructor.modal.confirm-return-to-report-list.title'),
      text: this.mode === 'new' ? localize('report-constructor.modal.confirm-return.text.will-be-reset') : undefined,
    });

    if (isConfirmed) {
      this.deps.routingStore.history.push('/report-constructor');
    }
  };

  openParamsModal = async (): Promise<void> => {
    const isConfirmed =
      this.mode === 'view'
        ? true
        : await showConfirmation({
            title: localize('report-constructor.modal.confirm-return-to-params.title'),
            text: localize(
              this.mode === 'edit'
                ? 'report-constructor.modal.confirm-return.text.will-be-changed'
                : 'report-constructor.modal.confirm-return.text.will-be-reset',
            ),
          });

    if (isConfirmed) {
      if (this.mode === 'new') {
        this.deps.routingStore.history.push('/report-constructor', { keepState: true });
      }
      this.setModalState('step1');
    }
  };

  applyParamsModal = (): void => {
    this.setModalState(null);

    if (this.mode === 'edit') {
      this.childrenStores.pivot.createPivot('pivot-container');
    }
  };

  save = async (): Promise<void> => {
    if (this.childrenStores.pivot.structure === null) return;

    const { reportLevelType, reportType, cabinetIds = [], ...filters } = this.filters;

    const data: SaveReportArgs = {
      ...filters,
      name: this.reportName,
      reportLevel: reportLevelType,
      jsonData: {
        reportType,
        cabinetIds,
        columnIds: this.selectedColumns.map(({ value }) => value),
        structure: this.childrenStores.pivot.structure,
      },
    };

    const response = await this.services.api.reportConstructor.save(data);

    runInAction(() => {
      if (!response.error) {
        toast.success(localize('report-constructor.report-successfully-saved'));
        this.deps.routingStore.history.push('/report-constructor');
      }
    });
  };

  update = async (): Promise<void> => {
    if (this._reportData === null || this.childrenStores.pivot.structure === null) return;

    const {
      reportLevelType,
      reportType,
      cabinetIds = [],
      statisticDateStart,
      statisticDateEnd,
      advertisers = [],
      brands = [],
      campaigns = [],
      categories = [],
      subbrands = [],
    } = this.filters;

    const data: UpdateReportArgs = {
      id: this._reportData.id,
      name: this.reportName,
      jsonData: {
        reportType,
        cabinetIds,
        columnIds: this.selectedColumns.map(({ value }) => value),
        structure: this.childrenStores.pivot.structure,
      },
    };

    if (!this.isLimitedAccess) {
      data.reportLevel = reportLevelType;
      data.statisticDateStart = statisticDateStart;
      data.statisticDateEnd = statisticDateEnd;
      data.advertisers = advertisers;
      data.brands = brands;
      data.campaigns = campaigns;
      data.categories = categories;
      data.subbrands = subbrands;
    }

    const response = await this.services.api.reportConstructor.update(data);

    runInAction(() => {
      if (!response.error) {
        toast.success(localize('report-constructor.report-successfully-updated'));
      }
    });
  };

  @action
  setReportName = (name: string): void => {
    this.reportName = name;
  };

  @action
  setOptionFilter = async <T extends keyof OptionFilters = keyof OptionFilters>(
    name: T,
    value: OptionFilters[T],
  ): Promise<void> => {
    this.filterOptions[name].options?.forEach((option) => {
      option.selected = (Array.isArray(value) && value.includes(option.value as number)) || value === option.value;
    });

    this.updateFilterOptions(name);
  };

  @action
  setDateFilter = async ([dateStart, dateEnd]: Nullable<Date | string>[]): Promise<void> => {
    this._dateFilter = {
      statisticDateStart: dateStart instanceof Date ? format(dateStart, 'yyyy-MM-dd') : dateStart,
      statisticDateEnd: dateEnd instanceof Date ? format(dateEnd, 'yyyy-MM-dd') : dateEnd,
    };

    this.updateFilterOptions('statisticDateStart');
  };

  @action
  setReportTypeFilter = async (reportType: ReportType): Promise<void> => {
    this.setOptionFilter('reportType', reportType);

    if (reportType === 'PLAN' && this.isCabinetsFilterActive) {
      this.setCabinetsFilterActive(false);
    }
  };

  @action
  setCabinetsFilterActive = async (isActive: boolean): Promise<void> => {
    this.isCabinetsFilterActive = isActive;

    if (isActive) {
      await this.loadCabinets();
    }

    this.updateFilterOptions('cabinetIds');
  };

  @action
  selectColumns = (ids: string[], type: keyof Columns): void => {
    const columns = type === 'attributes' ? this.columns.attributes : this.columns.metrics;

    columns.forEach((column) => {
      if (this.mode === 'edit') {
        column.selected = this._reportData?.columnIds?.includes(column.value) ? true : ids.includes(column.value);
      } else if (this.mode === 'new') {
        column.selected = ids.includes(column.value);
      }
    });
  };

  @action
  setModalState = (modalState: ModalState): void => {
    this.modalState = modalState;
  };

  initReportData = async (reportId: number, mode: Exclude<ReportMode, 'new'>): Promise<void> => {
    const response = await this.services.api.reportConstructor.loadReport(reportId);

    await runInAction(async () => {
      if (response.data) {
        const {
          name,
          reportLevel,
          reportType,
          statisticDateStart,
          statisticDateEnd,
          advertisers,
          brands,
          campaigns,
          categories,
          subbrands,
          cabinetIds,
          columnIds,
        } = response.data;

        this.setReportName(name);
        this.isCabinetsFilterActive = !!cabinetIds?.length;

        this._dateFilter = {
          statisticDateStart: statisticDateStart && format(statisticDateStart, 'yyyy-MM-dd'),
          statisticDateEnd: statisticDateEnd && format(statisticDateEnd, 'yyyy-MM-dd'),
        };

        this.filterOptions = {
          ...this._initialFilterOptions,
          reportType: {
            options: this._initialFilterOptions.reportType.options?.map((option) => ({
              ...option,
              selected: option.value === reportType,
            })),
          },
          reportLevelType: {
            options: this._initialFilterOptions.reportLevelType.options?.map((option) => ({
              ...option,
              selected: option.value === reportLevel,
            })),
          },
        };

        const filters: ReportFilters = {
          ...this._dateFilter,
          reportLevelType: reportLevel,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          reportType: reportType!,
          advertisers,
          brands,
          campaigns,
          categories,
          subbrands,
          cabinetIds,
        };

        const [, filterOptions] = await Promise.all([this.loadColumns(columnIds), this.loadFilterOptions(filters)]);

        await runInAction(async () => {
          this.mode = mode;
          this._reportData = response.data;

          this.filterOptions = { ...this.filterOptions, ...filterOptions };

          if (this.filters.reportType !== 'PLAN' && this.isCabinetsFilterActive) {
            await this.loadCabinets(cabinetIds);
          }

          R.mapObjIndexed((filterOption, filterName) => {
            filterOption.disabled = this.getFilterDisabledStatus(filterName);
          }, this.filterOptions);
        });
      } else {
        this.deps.routingStore.history.push('/report-constructor');
      }
    });
  };

  private convertStructure = (structure: PivotStructure) => {
    const columnNames = this._columns.map(({ value }) => value);
    const isAvailableColumn = (columnName: string | string[]) => {
      if (Array.isArray(columnName)) return columnName.every((name) => columnNames.includes(name));
      return columnNames.includes(columnName);
    };

    const columns = structure.columns?.filter(isAvailableColumn);
    const rows = structure.rows?.filter(isAvailableColumn);
    const values = structure.values?.filter(({ name, math }) =>
      math ? isAvailableColumn(parseComplexMath(math)) : isAvailableColumn(name),
    );
    const filters = structure.filters?.filter(({ name }) => isAvailableColumn(name));
    const groupBy = isAvailableColumn(structure.groupBy) ? structure.groupBy : columns?.[0];

    return {
      columns,
      rows,
      values,
      filters,
      groupBy,
    };
  };

  private loadFilterOptions = async (filters: ReportFilters) => {
    const levelFilters = Object.entries(this._levelTypeToFilterNameMap) as [ReportLevelType, keyof LevelFilters][];

    const optionResponses = await Promise.all(
      levelFilters.map(([reportLevelType, filterName]) =>
        filters[filterName]?.length === 0
          ? null
          : this.services.api.reportConstructor.loadReportLevel({
              ...R.filter((opt) => opt?.length !== 0, filters),
              reportLevelType,
            }),
      ),
    );

    const filterNames = levelFilters.map(([, filterName]) => filterName);

    return optionResponses
      .map((response, index) =>
        response?.data?.map((option) => ({
          ...option,
          selected: !!filters[filterNames[index]]?.includes(option.value),
        })),
      )
      .reduce(
        (acc, options, index) => ({
          ...acc,
          [filterNames[index]]: { options },
        }),
        {},
      );
  };

  @action
  private updateFilterOptions = async (changedFilterName: keyof ReportFilters): Promise<void> => {
    let levelTypeToBeLoaded: ReportLevelType | null = null;

    R.mapObjIndexed((filterOption, filterName) => {
      filterOption.disabled = this.getFilterDisabledStatus(filterName);

      if (levelTypeToBeLoaded === null) {
        levelTypeToBeLoaded = this.itNeedsToBeLoaded(filterOption, filterName, changedFilterName)
          ? (R.invertObj(this._levelTypeToFilterNameMap)[filterName] as ReportLevelType)
          : null;
      }

      if (this.isLevelFilter(filterName)) {
        filterOption.disabled = filterOption.disabled || !!levelTypeToBeLoaded;
        if (filterOption.disabled && !this.isLimitedAccess) {
          filterOption.options = undefined;
        }
      }

      if (filterName === 'cabinetIds') {
        if (filterOption.disabled && !this.isLimitedAccess) {
          filterOption.options = undefined;
        }
      }
    }, this.filterOptions);

    if (levelTypeToBeLoaded) {
      await this.loadReportLevel(levelTypeToBeLoaded);
    } else if (
      this.filters.reportType !== 'PLAN' &&
      (changedFilterName === 'reportLevelType' || this.isLevelFilter(changedFilterName))
    ) {
      await this.loadCabinets();
    }
  };

  @action
  private getFilterDisabledStatus = (filterName: keyof OptionFilters): boolean => {
    if (this.mode === 'view' || this.isLimitedAccess) {
      return true;
    }

    const filterNameToDisabledMap: Record<keyof OptionFilters, boolean> = {
      reportLevelType: this.isDateFilterEmpty,
      advertisers: this.isDateFilterEmpty,
      brands: ['ADVERTISER', 'CATEGORY'].includes(this.filters.reportLevelType) || !this.filters.advertisers?.length,
      campaigns:
        this.filters.reportLevelType !== 'CAMPAIGN' ||
        !this.filters.advertisers?.length ||
        !this.filters.brands?.length,
      categories: this.filters.reportLevelType !== 'CATEGORY' || !this.filters.advertisers?.length,
      subbrands:
        this.filters.reportLevelType !== 'SUBBRAND' ||
        !this.filters.advertisers?.length ||
        !this.filters.brands?.length,
      reportType: this.isDateFilterEmpty || !this.reportLevel.values.length,
      cabinetIds: !this.isCabinetsFilterActive || !this.reportLevel.values.length,
    };

    return filterNameToDisabledMap[filterName];
  };

  private itNeedsToBeLoaded = (
    currentFilterOption: FilterOption,
    currentFilterName: keyof FilterOptions,
    changedFilterName: keyof ReportFilters,
  ): boolean => {
    if (currentFilterOption.disabled) {
      return false;
    }

    if (['statisticDateStart', 'statisticDateEnd'].includes(changedFilterName) && currentFilterName === 'advertisers') {
      return true;
    }

    if (changedFilterName === 'reportLevelType') {
      return !currentFilterOption.options;
    }

    if (this.isLevelFilter(currentFilterName) && this.isLevelFilter(changedFilterName)) {
      return this.getLevelFilterPriority(currentFilterName) > this.getLevelFilterPriority(changedFilterName);
    }

    return false;
  };

  private isLevelFilter = (filterName: string): filterName is keyof LevelFilters =>
    R.values(this._levelTypeToFilterNameMap).includes(filterName as keyof LevelFilters);

  private getLevelFilterPriority = (filterName: keyof LevelFilters): number =>
    R.values(this._levelTypeToFilterNameMap).findIndex((name) => name === filterName);
}
