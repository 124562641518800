import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import {
  FileUpload,
  FileUploadHeaderTemplateType,
  FileUploadRemoveParams,
  FileUploadSelectParams,
} from 'primereact/fileupload';

import classes from './AdvancedFileUploader.module.scss';

type Props = {
  className?: string;
  accept?: string;
  label?: string;
  multiple?: boolean;
  disabled?: boolean;
  onSelect(files: File[]): void;
};

export const AdvancedFileUploader: React.FC<Props> = ({
  className,
  accept,
  label,
  multiple = false,
  disabled = false,
  onSelect,
}) => {
  const uploadRef = useRef<FileUpload>(null);

  const headerTemplate: FileUploadHeaderTemplateType = ({ chooseButton }) => (
    <div className={classes.header}>{chooseButton}</div>
  );

  const handleSelect = (e: FileUploadSelectParams) => {
    onSelect(e.files);
  };

  const handleRemove = (e: FileUploadRemoveParams) => {
    const files = uploadRef.current?.getFiles();
    if (files) {
      onSelect(files.filter((file) => file !== e.file));
    }
  };

  const handleClear = () => {
    onSelect([]);
  };

  useEffect(() => {
    uploadRef.current?.clear();
  }, [multiple]);

  useEffect(() => {
    if (disabled) {
      uploadRef.current?.clear();
    }
  }, [disabled]);

  return (
    <div className={classes.root}>
      <FileUpload
        ref={uploadRef}
        className={cx(classes.uploader, className, {
          [classes.uploaderDisabled]: disabled,
        })}
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        headerTemplate={headerTemplate}
        emptyTemplate={<p className={classes.label}>{label}</p>}
        chooseOptions={{ className: classes.uploadButton, iconOnly: true, icon: 'icon icon-download' }}
        contentClassName={classes.content}
        onSelect={handleSelect}
        onRemove={handleRemove}
        onClear={handleClear}
      />
    </div>
  );
};
