import React from 'react';
import { FormatterProps } from 'react-data-grid';
import { GridRow, isGridDataRow } from 'shared/components/other';
import { DataRow } from 'types/programmatic';
import { FactBudgetLimitCell } from '../components';

const ACCURACY = 100;

export const factBudgetLimitFormatter = ({ row }: FormatterProps<GridRow<DataRow>>): React.ReactElement | null => {
  if (!isGridDataRow(row)) return null;

  const { planBudget, factBudgetLimit } = row.data;

  const calcSeverity = () => {
    if ((row.depth && row.depth > 1) || planBudget === null || factBudgetLimit === null) {
      return null;
    }

    return factBudgetLimit > planBudget + ACCURACY ? 'danger' : 'success';
  };

  return <FactBudgetLimitCell severity={calcSeverity()} value={factBudgetLimit} />;
};
