import * as R from 'ramda';
import { action, computed, makeObservable, observable, runInAction, reaction } from 'mobx';

import { IError } from 'types';
import { IYearPeriod } from 'types/campaign';
import { IBrand, IBrandStatistics } from 'types/brand';
import { IPowerBIResponse, PowerBIUrlList } from 'types/powerBI';
import handleError from 'helpers/handleError';
import { PaginationStore } from 'shared/stores';
import { brandColorIterator, getPagedBrandsData } from './Brands.util';

const YEARS_COUNT = 3;

function isError<T>(value: T | IError): value is IError {
  return 'userErrorMessage' in value;
}
function isInvalidUrlError<T>(value: T | IError): value is IError {
  return getInvalidUrlError(value)?.userErrorMessage?.errorMessage === 'bi-url.invalid';
}
function getInvalidUrlError<T>(value: T | IError): IError | undefined {
  if ('userErrorMessage' in value) {
    return value;
  }

  return undefined;
}

type Filters = {
  brandName?: string;
};

export interface IBrandData {
  brand: IBrand;
  statistics: IBrandStatistics | null;
  color: string;
}

const currentYear = new Date().getFullYear();

export class BrandsStore extends PaginationStore<IBrandData[]> {
  protected _data: IBrandData[] = [];

  @observable
  activeYear: number = currentYear;

  @observable
  yearPeriod: IYearPeriod = {
    minYear: this.activeYear - 1,
    maxYear: this.activeYear + 1,
  };

  @observable
  _filters: Filters = {};

  @observable
  brands: IBrand[] = [];

  @observable
  brandsStatistics: IBrandStatistics[] = [];

  @computed
  get isShowDashboard(): boolean {
    return this.selectedDashboardUrl !== null;
  }

  @computed
  get advertiserId(): number | null {
    if (!this._isActive) {
      return null;
    }

    return this.deps.advertiserStore.currentAdvertiserId;
  }

  @observable
  powerBIResponse: IPowerBIResponse | null = null;

  @observable
  selectedDashboardUrl: string | null = null;

  // todo: cleanup
  @observable
  customPowerBIUrls?: PowerBIUrlList | null;

  @observable
  private _isActive = false;

  isPowerBILoading = false;

  @computed
  private get startYear(): number {
    const {
      activeYear,
      yearPeriod: { minYear, maxYear },
    } = this;

    switch (activeYear) {
      case minYear: {
        return minYear;
      }
      case maxYear: {
        return maxYear - YEARS_COUNT + 1;
      }
      default: {
        return activeYear - 1;
      }
    }
  }

  @computed
  get yearList(): number[] {
    return Array.from(Array(YEARS_COUNT), (item, index) => this.startYear + index);
  }

  @computed
  get filters(): Filters {
    return this._filters;
  }

  constructor() {
    super();
    makeObservable(this);
    reaction(
      () => this.advertiserId,
      () => {
        this.page = 0;
        this.closeDashboard();
        this.loadPowerBIReports();
      },
    );
    reaction(
      () => this.filters,
      () => {
        this.page = 0;
      },
    );
    this.setup();
  }

  invalidationScope = (): unknown[] => [this.page, this.pageSize, this.activeYear, this.advertiserId, this.filters];

  @action
  activate(): void {
    this._isActive = true;
  }

  revalidate = async (): Promise<void> => {
    if (this.advertiserId === null) {
      return;
    }
    const getNextColor = brandColorIterator();
    const API = this.services.api;
    const [brandList, statistics, yearPeriod] = await Promise.all([
      API.brands.list({ customerId: this.advertiserId, page: this.page, pageSize: this.pageSize, ...this.filters }),
      API.brands.getBrandsStatistics({ customerId: this.advertiserId, year: this.activeYear, ...this.filters }),
      API.campaigns.getYearPeriod({ customerId: this.advertiserId }),
    ]);
    if (brandList.error || statistics.error || yearPeriod.error) {
      return;
    }

    const { content: brands, totalPages, totalElements } = brandList.data;
    const { minYear, maxYear } = yearPeriod.data;

    const brandsStatistics: IBrandStatistics[] = statistics.data.map((brand) => ({
      ...brand,
      brandColor: brand.brandColor ?? getNextColor(),
    }));

    const maxPeriod = {
      minYear: Math.min(minYear, currentYear - 1),
      maxYear: Math.max(maxYear, currentYear + 1),
    };

    runInAction(() => {
      this.brandsStatistics = brandsStatistics;
      this.brands = brands;
      this._totalPages = totalPages;
      this._totalElements = totalElements;
      this.yearPeriod = maxPeriod;
      this._data = getPagedBrandsData({
        brands,
        brandsStatistics,
      });
    });
  };

  loadPowerBIReports = async (): Promise<void> => {
    if (this.advertiserId === null) {
      return;
    }

    runInAction(() => {
      this.isPowerBILoading = true;
    });

    const API = this.services.api;
    const [customUrlsResponse, powerbiResponse] = await Promise.all([
      API.powerbi.getCustomReportUrls(
        {
          customerId: this.advertiserId,
          pageName: 'BRAND_LIST',
          positionName: 'ADVERTISER_CUSTOM',
        },
        { withLoader: false, withErrorNotification: false },
      ),
      API.powerbi.getReportUrl(
        {
          customerId: this.advertiserId,
          pageName: 'BRAND_LIST',
          positionName: 'ADVERTISER',
        },
        { withLoader: false, withErrorNotification: false },
      ),
    ]);

    const powerbi = powerbiResponse.data ?? powerbiResponse.error;
    const customUrls = customUrlsResponse.data ?? customUrlsResponse.error;

    runInAction(() => {
      let errorToThrow: IError | null = null;

      if (isError(customUrls)) {
        if (isInvalidUrlError(customUrls)) {
          errorToThrow = getInvalidUrlError(customUrls) || errorToThrow;
        }
        this.customPowerBIUrls = null;
      } else {
        this.customPowerBIUrls = customUrls;
      }

      if (isError(powerbi)) {
        if (isInvalidUrlError(powerbi)) {
          errorToThrow = getInvalidUrlError(powerbi) || errorToThrow;
        }
        this.powerBIResponse = null;
      } else {
        this.powerBIResponse = powerbi;
      }

      if (errorToThrow) {
        if (errorToThrow.userErrorMessage) {
          errorToThrow.userErrorMessage.errorMessage = 'bi-url.dashboard-url-invalid';
        }
        handleError(errorToThrow);
      }

      this.isPowerBILoading = false;
    });
  };

  @action
  changeYear = (newActiveYear: number): void => {
    this.activeYear = newActiveYear;
  };

  @action
  openDashboard = (url: string | null): void => {
    if (url !== null) {
      this.selectedDashboardUrl = url;
    } else if (this.powerBIResponse) {
      this.selectedDashboardUrl = this.powerBIResponse.powerBIURL;
    }
  };

  @action
  closeDashboard = (): void => {
    this.selectedDashboardUrl = null;
  };

  @action
  cleanUp = (): void => {
    this._isActive = false;
    this.selectedDashboardUrl = null;
  };

  @action
  setFilters = (filters: Filters): void => {
    if (R.equals(filters, this.filters)) return;
    this._filters = filters;
  };

  @action
  addFilters = (filters: Filters): void => {
    this.setFilters(R.filter((x) => x != null)(R.mergeRight(this.filters, filters)));
  };

  @action
  removeFilters = (filterNames: (keyof Filters)[]): void => {
    this.setFilters(R.omit(filterNames, this.filters));
  };
}
