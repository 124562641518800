import React, { FC } from 'react';

import { LocalizedMessage } from 'shared/components/other';
import { CampaignStatusLabel } from 'shared/components/domain/CampaignStatusLabel';
import { ICampaign } from 'types/campaign';
import { formatDate, formatCampaignSum } from 'helpers/utils';

import classes from './CampaignTooltip.module.scss';

interface IProps {
  campaign: ICampaign | null;
  position: {
    top: number;
    left: number;
  };
}

const CampaignTooltip: FC<IProps> = (props: IProps) => {
  const {
    campaign,
    position: { top, left },
  } = props;

  if (!campaign) {
    return null;
  }

  const { name, campaignStatus, dateStart, dateEnd, totalBudgetPlan } = campaign;

  const { value: budgetValue, unitId } = formatCampaignSum(totalBudgetPlan);

  return (
    <div className={classes.CampaignTooltip} style={{ top, left }}>
      <div className={classes.Header}>{name}</div>
      <div className={classes.Status}>
        <CampaignStatusLabel value={campaignStatus} />
      </div>
      <div className={classes.InfoLabel}>
        {dateStart &&
          formatDate({
            date: dateStart,
          })}
        &nbsp;-&nbsp;
        {dateEnd &&
          formatDate({
            date: dateEnd,
          })}
      </div>
      <div className={classes.BudgetLabel}>
        {budgetValue}
        &nbsp;
        <LocalizedMessage id={unitId} />
      </div>
    </div>
  );
};

export default CampaignTooltip;
