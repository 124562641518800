import { action, makeObservable, observable, computed, reaction, runInAction } from 'mobx';
import debounce from 'lodash/debounce';

export class GlobalLoaderStore {
  @observable
  private counter = 0;

  @observable
  private _isLoading = false;

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @action
  startLoading(): void {
    this.counter += 1;
  }

  @action
  stopLoading(): void {
    if (this.counter > 0) {
      this.counter -= 1;
    }
  }

  private changeIsLoading = debounce(() => {
    runInAction(() => {
      this._isLoading = this.counter > 0;
    });
  }, 100);

  constructor() {
    makeObservable(this);
    reaction(() => this.counter, this.changeIsLoading);
  }
}
