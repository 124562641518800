import uniq from 'lodash/uniq';

import { formatDate as format } from 'helpers/utils';

export const formatDateToStr = (date: string | Date | null): string =>
  format({
    date,
    isFullYear: true,
    isReverseOrder: true,
  });

export const formatDate = (date: string | Date | null): Date => new Date(formatDateToStr(date));

export const convertStringToArray = (str: string): string[] => {
  if (!str) return [];

  return uniq(
    str
      .split(',')
      .map((x) => x.trim())
      .filter((x) => x !== ''),
  );
};
