import React from 'react';
import { Dialog } from 'primereact/dialog';
import { useDataGrid } from '../../context';
import classes from './Modal.module.scss';

export const Modal: React.VFC = () => {
  const { modalHeader, isModalVisible, closeModal, modalContent } = useDataGrid();

  return (
    <Dialog
      className={classes.root}
      header={modalHeader}
      visible={isModalVisible}
      onHide={closeModal}
      draggable={false}
    >
      {modalContent}
    </Dialog>
  );
};
