import React from 'react';
import classes from './InfoItem.module.scss';

type Props = {
  title: string;
  value: React.ReactNode;
};

export const InfoItem: React.FC<Props> = (props) => {
  const { title, value } = props;
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.value}>{value ?? '-'}</div>
    </div>
  );
};

export type { Props as InfoItemProps };
