import React, { useCallback, useState, VFC } from 'react';

import { IPowerBIFilters } from 'types/powerBI';
import { PowerBIReport } from 'shared/components/other';
import Filters from './Filters/Filters';

import classes from './Hyundai.module.scss';

type FiltersType = Omit<IPowerBIFilters, 'detailedBy' | 'periodType'> & {
  detailedBy?: string | null;
  periodType?: string | null;
};

export const Hyundai: VFC = () => {
  const [filters, setFilters] = useState<FiltersType>({
    communication: [],
    months: [],
    years: [],
    detailedBy: null,
    period1: [],
    period2: [],
    periodType: null,
  });
  const [isFiltersLoaded, setIsFiltersLoaded] = useState(false);

  const applyFilters = useCallback(
    (applyingFilters: IPowerBIFilters): void => {
      if (!isFiltersLoaded) {
        setIsFiltersLoaded(true);
      }

      const hasMonthsAll = applyingFilters.months?.some((x) => x === 'All');
      const withPeriods =
        applyingFilters.period1?.length &&
        applyingFilters.period2?.length &&
        !applyingFilters.period1.some((x) => x === '-') &&
        !applyingFilters.period2.some((x) => x === '-');

      setFilters({
        ...applyingFilters,
        months: hasMonthsAll || withPeriods ? [] : applyingFilters.months,
        years: withPeriods ? [] : applyingFilters.years,
        period1: withPeriods ? applyingFilters.period1 : [],
        period2: withPeriods ? applyingFilters.period2 : [],
        periodType: withPeriods ? applyingFilters.periodType : undefined,
      });
    },
    [isFiltersLoaded, setIsFiltersLoaded, setFilters],
  );

  return (
    <div>
      <Filters onApplyFilters={applyFilters} />
      <div className={classes.ReportContainer}>
        {isFiltersLoaded && Boolean(filters.detailedBy) && (
          <PowerBIReport pageName="CLIENT_DASHBOARD" advertiserId={null} filters={filters} className={classes.Report} />
        )}
      </div>
    </div>
  );
};
