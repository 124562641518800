import React from 'react';
import { Link } from 'react-router-dom';
import { Column, GridRow, isGridDataRow, Row } from 'shared/components/other';
import { DataRow } from 'types/programmatic/excess';
import { checkStatusOptions } from '../../helpers/selectionOptions';
import classes from './ExcessTable.module.scss';

import { CheckStatusCell, CabinetStatusCell, BudgetPctCell, BudgetPctTodayCell, UnitPriceCell } from './components';
import { factBudgetLimitFormatter, settingsFormatter } from './formatters';

export const columns: Column<DataRow>[] = [
  {
    key: 'status',
    name: 'Статус\nАктивных / Всего',
    width: 165,
    minWidth: 165,
    resizable: true,
    isSelectColumn: true,
    isExpandColumn: true,
    titleRowTemplates: [
      (row: GridRow<DataRow> | Row<DataRow>): React.ReactNode => (
        <Link className={classes.titleLink} to={`/campaigns/${row.data.id}`}>
          {row.data.advertiserName}
          <span className={classes.slash}>/</span>
          {row.data.campaignName}
        </Link>
      ),
    ],
    hasIndent: true,
    formatter: ({ row }) =>
      isGridDataRow(row) ? (
        <CabinetStatusCell active={row.data.active} total={row.data.total} cabinetStatus={row.data.cabinetStatus} />
      ) : null,
  },
  {
    key: '',
    name: 'Наст-\nройки',
    minWidth: 60,
    width: 60,
    resizable: true,
    formatter: settingsFormatter,
  },
  {
    key: 'rowNumber',
    name: 'Номер строки',
    minWidth: 70,
    width: 70,
    resizable: true,
  },
  {
    key: 'sellerOrStatisticsSource',
    name: 'Ресурс /\nКабинет',
    resizable: true,
    textAlign: 'left',
  },
  {
    key: 'format',
    name: 'Формат',
    resizable: true,
    textAlign: 'left',
  },
  {
    key: 'checkStatus',
    name: 'Состояние',
    minWidth: 90,
    width: 100,
    resizable: true,
    formatter: ({ row }) =>
      isGridDataRow(row) ? (
        <CheckStatusCell
          {...checkStatusOptions.find((o) => o.id === row.data.checkStatus)}
          tooltip={row.data.excessCauses?.map((x) => `● ${x}`).join('\n\n')}
        />
      ) : null,
  },
  {
    key: 'startDate',
    name: 'Дата старта\n(план)',
    minWidth: 100,
    resizable: true,
    type: 'date',
    filter: { type: 'date' },
  },
  {
    key: 'endDate',
    name: 'Дата конца\n(план)',
    minWidth: 100,
    resizable: true,
    type: 'date',
    filter: { type: 'date' },
  },
  {
    key: 'planBudget',
    name: 'Бюджет\n(план)',
    minWidth: 105,
    resizable: true,
    type: 'currency',
  },
  {
    key: 'factBudgetLimit',
    name: 'Бюджет\n(лимит)',
    minWidth: 115,
    resizable: true,
    formatter: factBudgetLimitFormatter,
  },
  {
    key: 'factBudget',
    name: 'Бюджет\n(факт)',
    minWidth: 105,
    resizable: true,
    type: 'currency',
  },
  {
    key: 'budgetPct',
    name: 'Бюджет\n(общий)',
    resizable: true,
    formatter: ({ row }) => (isGridDataRow(row) ? <BudgetPctCell value={row.data.budgetPct} /> : null),
  },
  {
    key: 'budgetPctToday',
    name: 'Бюджет\n(на дату)',
    resizable: true,
    formatter: ({ row }) => (isGridDataRow(row) ? <BudgetPctTodayCell value={row.data.budgetPctToday} /> : null),
  },
  {
    key: 'unitPrice',
    name: 'Unit Price\n(план)',
    minWidth: 80,
    resizable: true,
    type: 'currency',
  },
  {
    key: 'factUnitPrice',
    name: 'Unit Price\n(факт)',
    minWidth: 80,
    resizable: true,
    type: 'currency',
  },
  {
    key: 'unitPricePct',
    name: 'Unit Price\n%',
    minWidth: 80,
    resizable: true,
    formatter: ({ row }) => (isGridDataRow(row) ? <UnitPriceCell value={row.data.unitPricePct} /> : null),
  },
  {
    key: 'id',
    name: 'ID позиции',
    resizable: true,
  },
];
