const primeReactRu = {
  // Calendar
  firstDayOfWeek: 1,
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  dayNamesShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  monthNamesShort: ['янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сен', 'окт', 'ноя', 'дек'],
  today: 'Сегодня',

  // common
  cancel: 'Отмена',
  clear: 'Очистить',
  emptyFilterMessage: 'Ничего не найдено',
  emptyMessage: 'Ничего не найдено',
} as const;

const customRu = {
  // DateFilter
  Period: 'Период',

  // Toast
  ReportAnError: 'Сообщить об ошибке',

  // ColorButton
  ButtonColor: 'Цвет кнопки',
  ChangeColor: 'Изменить цвет',
  NoColor: 'Нет цвета',
  SelectColor: 'Выбрать цвет',

  // common
  Apply: 'Применить',
  Select: 'Выбрать',
} as const;

export const ru = { ...primeReactRu, ...customRu };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Ru = typeof ru & { [K in string]: any };
