import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useForm, Controller } from 'react-hook-form';

import hasAccess from 'helpers/hasAccess';
import { useDidMount } from 'helpers/hooks';
import showConfirmation from 'helpers/showConfirmation';
import { getStatusLocaleId } from 'helpers/utils';
import { Input, localize, localizeMessage, LocalizedMessage, LocalizedTitle } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { SaveButtons } from 'shared/components/common/buttons/SaveButtons';
import { Select } from 'shared/components/common/form/Select';
import { Footer } from 'shared/components/layout/Footer';
import { LocaleIdType } from 'locales';
import { CampaignStatus, MediaOption } from 'types/campaign';
import { toast } from 'shared/components/common/misc/Toast';
import { useStores } from 'core';

import { FormData } from './stores/CampaignEdit.store';
import { autoCreatedRequiredFields, maxBudgetValue, requiredFields, statuses } from './helpers';
import { ControlledCheckbox } from './components/ControlledCheckbox';
import { ControlledDatePicker } from './components/ControlledDatePicker';
import { DisabledFieldIcon } from './components/DisabledFieldIcon';
import { AutoCreateModal } from './components/AutoCreateModal';

import classes from './CampaignEdit.module.scss';

export const CampaignEdit = observer(() => {
  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<FormData>();
  const { startDate, endDate } = watch(['startDate', 'endDate']);

  const { advertiserStore, campaignEditStore: store } = useStores();
  const { currentAdvertiserId: advertiserId } = advertiserStore;
  const {
    autoCreatedValues,
    mediaplanData,
    autoCreateCampaign,
    handleMediafileUpload,
    handleSheetValueChange,
    resetMediaplanData,
  } = store.childrenStores.autoCreate;

  const history = useHistory();
  const params = useParams<{ id?: string }>();

  useDidMount(() => {
    const campaignId = params.id ? Number(params.id) : null;
    runInAction(() => {
      store.campaignId = campaignId;
      store.loadData();
    });
  });

  useEffect(() => {
    return store.cleanUp;
  }, [store.cleanUp]);

  useEffect(() => {
    const campaignId = params.id ? Number(params.id) : null;
    runInAction(() => {
      store.campaignId = campaignId;
    });
  }, [store, params]);

  useEffect(() => {
    if (!store.campaignId) {
      store.setCurrentBrand(null);
      store.loadGeneralData();
      setValue('agency', null);
      setValue('brand', null);
      setValue('subbrand', null);
      setValue('category', null);
    } else if (store.formValues?.advertiser && advertiserId !== store.formValues.advertiser) {
      history.push('/campaigns');
    }
  }, [advertiserId, store, history, setValue]);

  useEffect(() => {
    if (store.formValues !== null) {
      Object.entries(store.formValues).forEach(([key, val]) => {
        setValue(key, val);
      });
    }
  }, [store.formValues, setValue]);

  useEffect(() => {
    if (autoCreatedValues !== null) {
      Object.entries(autoCreatedValues).forEach(([key, val]) => {
        setValue(key, val);

        if (key === 'brand' && val) {
          store.setCurrentBrand(Number(val));
        }
      });
      trigger(autoCreatedRequiredFields);
    }
  }, [autoCreatedValues, setValue, trigger, store]);

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [store.metrics.length, isSubmitted, trigger]);

  const {
    campaignId,
    agencies,
    brands,
    subbrands,
    categories,
    formValues,
    metrics,
    remainingMediaTypes,
    currentBrand,
    savedMediaplanName,
    hasCurrentMediaplans,
    setCurrentBrand,
    setMetrics,
    addSubbrand,
    addCategory,
    removeCampaign,
  } = store;

  const isRequired = (fieldName: keyof FormData) => requiredFields.includes(fieldName);
  const isDateDisabled = hasCurrentMediaplans();

  const handleAddOptionButtonClick = async (
    _: React.MouseEvent<HTMLDivElement>,
    name: 'subbrand' | 'category',
  ): Promise<void> => {
    const modal = async (isInvalid = false) => {
      const inputValue = await showConfirmation({
        title: localize(name === 'subbrand' ? 'campaign-edit.modal.new-subbrand' : 'campaign-edit.modal.new-category'),
        type: isInvalid ? 'invalid' : undefined,
        input: {
          type: 'text',
          name: name === 'subbrand' ? 'newSubbrandValue' : 'newCategoryValue',
          maxLength: 1000,
        },
        confirmTextId: 'button.save',
      });
      const value = inputValue?.trim();

      if (value) {
        const options = name === 'subbrand' ? subbrands : categories;
        const isExist = options.find((o) => o.label === value);
        if (isExist) {
          toast.error(
            localizeMessage({ id: `campaign-edit.errors.${name}-already-exists` as LocaleIdType }, { name: value }),
          );

          return;
        }

        const addedOptionId =
          name === 'subbrand' ? await addSubbrand(value, store.currentBrand) : await addCategory(value);
        if (addedOptionId) {
          toast.success(localize(`campaign-edit.modal.${name}-added-successfully` as LocaleIdType));
          setValue(name, addedOptionId);
        }
      } else if (value === '') {
        modal(true);
      }
    };

    modal();
  };

  const handleAddMediaButtonClick = (): void => {
    setMetrics([...metrics, remainingMediaTypes[0]]);
  };

  const handleClearMediaButtonClick = (mediaType: string): void => {
    setMetrics(metrics.filter((m) => m.label !== mediaType));
  };

  const handleMediaTypeChange = (index: number, metric?: MediaOption) => {
    setMetrics(metrics.map((m, i) => (i === index && metric ? metric : m)));
  };

  const handleClearSubbrandsButtonClick = (): void => {
    setValue('subbrand', null);
  };

  const handleRemoveButtonClick = async (): Promise<void> => {
    const isConfirmed = await showConfirmation({
      text: `${localize('campaign-edit.modal.remove-campaign-confirm')}?`,
      type: 'removal',
    });
    if (!isConfirmed) return;

    const isSuccessful = await removeCampaign();
    if (isSuccessful) {
      toast.success(localize('campaign-edit.modal.remove-campaign-success'));
      redirectPage();
    }
  };

  const handleCancelButtonClick = (): void => {
    redirectPage(campaignId);
  };

  const onSubmit = async (data: FormData) => {
    const savedCampaignId = await store.handleSubmit(data);
    if (savedCampaignId !== null) {
      redirectPage(savedCampaignId);
    }
  };

  const redirectPage = (id: number | null = null): void => {
    const path = id ? `/campaigns/${id}` : '/campaigns';
    history.push(path);
  };

  const [isAutoCreateModalVisible, setIsAutoCreateModalVisible] = useState(false);

  return (
    <div className={classes.Layout}>
      <LocalizedTitle id={campaignId ? 'site.title.edit-campaign' : 'site.title.create-campaign'} />

      <section className={classes.Content}>
        <header className={classes.Header}>
          <h1 className={classes.Title}>
            <LocalizedMessage id="campaign.campaign" />
          </h1>
          {campaignId && hasAccess('deleteCampaign', 'campaignPage') && (
            <i
              className={cx(classes.RemoveButton, 'icon icon-delete')}
              onClick={handleRemoveButtonClick}
              data-test="remove-campaign-button"
            />
          )}
        </header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.Form}>
            <fieldset className={classes.Fieldset}>
              <div className={classes.FormGroup}>
                <div className={classes.Field}>
                  <label>
                    <span className={classes.FieldLabel}>
                      <LocalizedMessage id="name" />
                    </span>
                    <div>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue={formValues?.name || ''}
                        rules={{
                          required: isRequired('name'),
                          maxLength: 1000,
                        }}
                        render={({ value, onChange }) => (
                          <Input value={value} onChange={onChange} hasError={!!errors.name} data-test="name-input" />
                        )}
                      />
                    </div>
                  </label>
                  {errors.name && (
                    <span className={classes.WarningMessage}>
                      <LocalizedMessage id="campaign-edit.warnings.not-empty" />
                    </span>
                  )}
                </div>
                <div className={classes.Field}>
                  <label>
                    <span className={classes.FieldLabel}>
                      <LocalizedMessage id="campaign.agency" />
                    </span>
                    <div>
                      <Controller
                        name="agency"
                        control={control}
                        defaultValue={formValues?.agency || ''}
                        rules={{ required: isRequired('agency') }}
                        render={({ value, onChange }) => (
                          <Select
                            options={agencies}
                            value={value}
                            onChange={onChange}
                            invalid={!!errors.agency}
                            mode="inline"
                          />
                        )}
                      />
                    </div>
                  </label>
                  {errors.agency && (
                    <span className={classes.WarningMessage}>
                      <LocalizedMessage id="campaign-edit.warnings.not-empty" />
                    </span>
                  )}
                </div>
              </div>
              <div className={classes.FormGroup}>
                <div className={classes.Field}>
                  <label>
                    <span className={classes.FieldLabel}>
                      <LocalizedMessage id="campaign.brand" />
                    </span>
                    <div>
                      <Controller
                        name="brand"
                        control={control}
                        defaultValue={formValues?.brand || ''}
                        rules={{ required: isRequired('brand') }}
                        render={({ value, onChange }) => (
                          <Select
                            options={brands}
                            value={value}
                            onChange={(val: number) => {
                              onChange(val);
                              setValue('subbrand', null);
                              setCurrentBrand(val);
                            }}
                            invalid={!!errors.brand}
                            mode="inline"
                          />
                        )}
                      />
                    </div>
                  </label>
                  {errors.brand && (
                    <span className={classes.WarningMessage}>
                      <LocalizedMessage id="campaign-edit.warnings.not-empty" />
                    </span>
                  )}
                </div>
                <div className={classes.Field}>
                  <label htmlFor="subbrand">
                    <span className={classes.FieldLabel}>
                      <LocalizedMessage id="campaign.subbrand" />
                    </span>
                    {currentBrand !== null && (
                      <div
                        className={classes.AddOption}
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => handleAddOptionButtonClick(e, 'subbrand')}
                        data-test="add-subbrand-button"
                      >
                        +
                      </div>
                    )}
                  </label>
                  <Controller
                    name="subbrand"
                    control={control}
                    defaultValue={formValues?.subbrand || ''}
                    rules={{ required: isRequired('subbrand') }}
                    render={({ value, onChange }) => (
                      <div>
                        <Select
                          inputId="subbrand"
                          options={subbrands}
                          value={value}
                          onChange={onChange}
                          invalid={!!errors.subbrand}
                          disabled={currentBrand === null}
                          mode="inline"
                        />
                        {Boolean(value) && (
                          <i
                            className={cx('icon icon-reset', classes.ClearButton)}
                            onClick={handleClearSubbrandsButtonClick}
                            data-test="clear-subbrand-button"
                          />
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className={classes.FormGroup}>
                <div className={classes.Field}>
                  <label htmlFor="category">
                    <span className={classes.FieldLabel}>
                      <LocalizedMessage id="campaign.category" />
                    </span>
                    <div
                      className={classes.AddOption}
                      onClick={(e) => handleAddOptionButtonClick(e, 'category')}
                      data-test="add-category-button"
                    >
                      +
                    </div>
                  </label>
                  <div>
                    <Controller
                      name="category"
                      control={control}
                      defaultValue={formValues?.category || ''}
                      rules={{ required: isRequired('category') }}
                      render={({ value, onChange }) => (
                        <Select
                          inputId="category"
                          options={categories}
                          value={value}
                          onChange={onChange}
                          invalid={!!errors.category}
                          mode="inline"
                        />
                      )}
                    />
                  </div>
                  {errors.category && (
                    <span className={classes.WarningMessage}>
                      <LocalizedMessage id="campaign-edit.warnings.not-empty" />
                    </span>
                  )}
                </div>
                <div className={classes.Field}>
                  <label>
                    <span className={classes.FieldLabel}>
                      <LocalizedMessage id="campaign.status" />
                    </span>
                    <div>
                      <Controller
                        name="status"
                        control={control}
                        defaultValue={formValues?.status || ''}
                        rules={{ required: isRequired('status') }}
                        render={({ value, onChange }) => (
                          <Select
                            options={statuses.map((status: CampaignStatus) => ({
                              value: status,
                              label: localize(getStatusLocaleId(status)),
                            }))}
                            value={value}
                            onChange={onChange}
                            invalid={!!errors.status}
                            mode="inline"
                          />
                        )}
                      />
                    </div>
                  </label>
                  {errors.status && (
                    <span className={classes.WarningMessage}>
                      <LocalizedMessage id="campaign-edit.warnings.not-empty" />
                    </span>
                  )}
                </div>
              </div>
              <div className={classes.FormGroup}>
                <div className={classes.Field}>
                  <label htmlFor="startDate" className={classes.FieldLabel}>
                    <LocalizedMessage id="campaign.label.start" />
                  </label>
                  <ControlledDatePicker
                    name="startDate"
                    defaultValue={formValues?.startDate ?? null}
                    control={control}
                    required={isRequired('startDate')}
                    invalid={!!errors.startDate}
                    disabled={isDateDisabled}
                    maxDate={endDate ?? undefined}
                  />
                  {errors.startDate && (
                    <span className={classes.WarningMessage}>
                      <LocalizedMessage id="campaign-edit.warnings.not-empty" />
                    </span>
                  )}
                </div>
                <div className={classes.Field}>
                  <label htmlFor="endDate" className={classes.FieldLabel}>
                    <LocalizedMessage id="campaign.label.finish" />
                  </label>
                  <ControlledDatePicker
                    name="endDate"
                    defaultValue={formValues?.endDate ?? null}
                    control={control}
                    required={isRequired('endDate')}
                    invalid={!!errors.endDate}
                    disabled={isDateDisabled}
                    minDate={startDate ?? undefined}
                  />
                  {errors.endDate && (
                    <span className={classes.WarningMessage}>
                      <LocalizedMessage id="campaign-edit.warnings.not-empty" />
                    </span>
                  )}
                </div>
              </div>
              <div className={classes.FormGroup}>
                <div className={cx(classes.Field, classes.FieldForTextarea)}>
                  <label>
                    <span>
                      <LocalizedMessage id="campaign.description" />
                    </span>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue={formValues?.description || ''}
                      rules={{ required: isRequired('description') }}
                      render={({ value, onChange }) => (
                        <textarea data-test="description-area" value={value || ''} onChange={onChange} />
                      )}
                    />
                  </label>
                </div>
              </div>
            </fieldset>

            <fieldset className={classes.Fieldset}>
              <div
                className={cx(classes.BudgetGroup, {
                  [classes.BudgetGroupEmpty]: errors.budget,
                })}
              >
                <Controller
                  name="budget"
                  control={control}
                  rules={{ validate: () => metrics.length > 0 }}
                  defaultValue={null}
                  as={
                    <legend>
                      <LocalizedMessage id="campaign.budget" />
                    </legend>
                  }
                />
                {metrics.map((metric: MediaOption, index: number) => {
                  const isDisabled = hasCurrentMediaplans(metric.type);

                  return (
                    <div key={index} className={classes.FormGroup}>
                      <div className={classes.Field}>
                        <span className={classes.FieldLabel}>
                          <LocalizedMessage id="campaign.media" />
                        </span>
                        <div>
                          <Select
                            options={[metric, ...remainingMediaTypes]}
                            value={metric.value}
                            onChange={(value: number, option?: MediaOption) => handleMediaTypeChange(index, option)}
                            mode="inline"
                          />
                        </div>
                      </div>
                      <div className={classes.Field}>
                        <span className={classes.FieldLabel}>
                          <LocalizedMessage id="campaign.plan" />
                        </span>
                        <Controller
                          name={`metrics.${metric.label}`}
                          control={control}
                          defaultValue={metric.budgetValue || ''}
                          rules={{ validate: (value) => value > 0 }}
                          render={({ value, onChange }) => (
                            <Input
                              type="number"
                              max={maxBudgetValue}
                              value={value}
                              onChange={onChange}
                              hasError={!!errors.metrics?.[metric.label]}
                              disabled={isDisabled}
                              icon={isDisabled ? <DisabledFieldIcon /> : undefined}
                              data-test={`${metric.label.toLowerCase()}-budget-input`}
                            />
                          )}
                        />
                        {errors.metrics?.[metric.label] && (
                          <span className={classes.WarningMessage}>
                            <LocalizedMessage id="campaign-edit.warnings.must-be-number" />
                          </span>
                        )}
                      </div>
                      {!isDisabled ? (
                        <i
                          className={cx('icon icon-reset', classes.ClearButton, classes.ClearButtonRight)}
                          onClick={() => handleClearMediaButtonClick(metric.label)}
                          data-test={`${metric.label.toLowerCase()}-budget-delete`}
                        />
                      ) : null}
                    </div>
                  );
                })}
                {remainingMediaTypes.length > 0 && (
                  <div className={classes.AddOptionGroup}>
                    <div className={classes.AddOption} onClick={handleAddMediaButtonClick} data-test="add-media-button">
                      +
                    </div>
                    <span className={classes.AddOptionLabel}>
                      <LocalizedMessage id="campaign.media" />
                    </span>
                  </div>
                )}
                {errors.budget && (
                  <span className={classes.WarningMessage}>
                    <LocalizedMessage id="campaign-edit.warnings.not-empty" />
                  </span>
                )}
              </div>
            </fieldset>
          </div>

          <div className={classes.ButtonGroup}>
            <div className={classes.ButtonGroupItem}>
              <span>
                <LocalizedMessage id="campaign-edit.accessible-to-client" />
              </span>
              <ControlledCheckbox
                name="accessibleToClient"
                defaultValue={Boolean(formValues?.accessibleToClient)}
                control={control}
              />
            </div>
            <div className={classes.ButtonGroupItem}>
              <span>
                <LocalizedMessage id="campaign-edit.hide-budget-scale" />
              </span>
              <ControlledCheckbox
                name="hideBudgetScale"
                defaultValue={Boolean(formValues?.hideBudgetScale)}
                control={control}
              />
            </div>
            <SaveButtons className={classes.SaveButtons} onCancel={handleCancelButtonClick} />
          </div>

          {!campaignId && hasAccess('autoCreateCampaign', 'campaignPage') && (
            <div>
              <Button onClick={() => setIsAutoCreateModalVisible(true)} data-test="autocreate-campaign-button">
                <LocalizedMessage id="button.autocreate-campaign" />
              </Button>
              {savedMediaplanName && (
                <p>
                  <span className={classes.Field}>
                    <LocalizedMessage id="campaign-edit.auto-creating.uploaded-mediafile" />
                  </span>
                  {savedMediaplanName}
                </p>
              )}
              <AutoCreateModal
                isVisible={isAutoCreateModalVisible}
                mediaplanData={mediaplanData}
                onAccept={() => {
                  setIsAutoCreateModalVisible(false);
                  autoCreateCampaign();
                }}
                onCancel={() => {
                  setIsAutoCreateModalVisible(false);
                  resetMediaplanData();
                }}
                onMediafileUpload={handleMediafileUpload}
                onSheetValueChange={handleSheetValueChange}
              />
            </div>
          )}
        </form>
      </section>
      <Footer />
    </div>
  );
});
