/* eslint-disable class-methods-use-this */
import { FieldData } from 'types/reportConstructor';

export const createCustomValuesProperty = (): typeof pivot.views['config/properties/values'] => {
  return class CustomValuesProperty extends pivot.views['config/properties/values'] {
    FilterSuggest(...args: unknown[]) {
      const [, , field] = args;
      return (field as FieldData).usedInAggregation;
    }
  };
};
