import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useStores } from 'core';
import { LocalizedMessage } from 'shared/components/other';

import { Button } from 'shared/components/common/buttons/Button';
import { ReportMode } from 'types/reportConstructor';
import classes from './ReportConstructor.module.scss';
import { ParamsModal } from '../components/ParamsModal';

export const ReportConstructor: React.FC = observer(() => {
  const { reportConstructorStore } = useStores();
  const {
    isFirstStepValid,
    isSecondStepValid,
    goBack,
    openParamsModal,
    applyParamsModal,
    save,
    update,
    initReportData,
    reportName,
    childrenStores: { pivot: pivotStore },
  } = reportConstructorStore;
  const { isStructureEmpty, createPivot, exportToExcel } = pivotStore;
  const history = useHistory();
  const params = useParams<{ id?: string; mode?: Exclude<ReportMode, 'new'> }>();

  const initReport = async (id: number, mode: Exclude<ReportMode, 'new'>) => {
    await initReportData(id, mode);
    await createPivot('pivot-container');
  };

  const handleSaveButtonClick = () => {
    if (params.mode === 'edit') {
      update();
    } else {
      save();
    }
  };

  useEffect(() => {
    if (params.id) {
      if (Number.isNaN(+params.id) || (params.mode !== 'view' && params.mode !== 'edit')) {
        history.push('/report-constructor');
      } else {
        initReport(+params.id, params.mode);
      }
    } else if (!isFirstStepValid || !isSecondStepValid) {
      history.push('/report-constructor');
    } else {
      createPivot('pivot-container');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.mode]);

  return (
    <div className={classes.root}>
      <div className={classes.pivotContainer} id="pivot-container" />
      <div className={classes.footer}>
        <div className={classes.buttonGroup}>
          <Button onClick={goBack}>
            <LocalizedMessage id="report-constructor.button.return-to-report-list" />
          </Button>
          <Button onClick={openParamsModal}>
            <LocalizedMessage
              id={
                params.mode === 'view'
                  ? 'report-constructor.button.view-params'
                  : 'report-constructor.button.return-to-params'
              }
            />
          </Button>
        </div>
        <div className={classes.buttonGroup}>
          <Button disabled={isStructureEmpty} onClick={() => exportToExcel(reportName)}>
            <LocalizedMessage id="report-constructor.button.export-to-excel" />
          </Button>
          {params.mode !== 'view' && (
            <Button disabled={isStructureEmpty} onClick={handleSaveButtonClick}>
              <LocalizedMessage id="report-constructor.button.save" />
            </Button>
          )}
        </div>
      </div>
      {params.mode && <ParamsModal onApply={applyParamsModal} />}
    </div>
  );
});
