import { encodeUrlParams } from './encodeUrlParams';

export function isValidUrl(url: string): boolean {
  try {
    const urlWithEncodedParams = encodeUrlParams(url);
    const res = new URL(urlWithEncodedParams);
    return ['https:', 'http:'].includes(res.protocol);
  } catch {
    return false;
  }
}
