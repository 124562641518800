import { isNotNull } from 'helpers/isNotNull';
import { ICampaignMetric, MediaOption } from 'types/campaign';

export function convertMetrics(mediaTypes: MediaOption[], metrics: ICampaignMetric[]): MediaOption[] {
  return metrics
    .map((metric) => {
      const mediaType = mediaTypes.find((m) => m.value === metric.metricMediaType);

      return mediaType ? { ...mediaType, budgetValue: metric.value } : null;
    })
    .filter(isNotNull);
}
