import { BaseServerProfile, IProfile, Role, BasicRole, AdditionalRole } from 'types/profile';
import { baseRoles, additionalRoles, baseRolesByPriority } from './roles';

export function convertToProfile(authResponse: BaseServerProfile): IProfile {
  return {
    id: authResponse.id,
    name: authResponse.name,
    email: authResponse.email,
    login: authResponse.login,
    roles: getBaseRoles(authResponse.roles),
    additionalRoles: authResponse.roles.filter(isAdditionalRole),
    medias: authResponse.medias,
  };
}

function getBaseRoles(responseRoles: Role[]): BasicRole[] {
  return baseRolesByPriority.flatMap((roles) => roles.find((role) => responseRoles.includes(role)) ?? []);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAdditionalRole(role: any): role is AdditionalRole {
  return !baseRoles.includes(role) && additionalRoles.includes(role);
}
