import { AxiosRequestConfig } from 'axios';
import Config from 'config';
import { sharedStores } from 'shared/stores';
import handleError from 'helpers/handleError';
import { ErrorInterceptor } from '../../types';
import { Auth } from '../../release';

export function makeReauthorize(auth: Auth): ErrorInterceptor {
  return async ({ instance, config, error }) => {
    try {
      if (!config.reRequest) {
        handleError(error);
        return { data: null, error };
      }

      if (config.baseURL === Config.api.authHost) {
        throw error;
      }

      const { getAccessToken, logInByRefreshToken } = sharedStores.authStore;
      await auth.reLogIn(logInByRefreshToken);

      const accessToken = getAccessToken();
      const { headers, withToken = true } = config;
      if (accessToken && withToken && headers) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      return await instance({ ...config, reRequest: false } as AxiosRequestConfig);
    } catch (err) {
      await sharedStores.authStore.logOut();
      handleError(err);
      return { data: null, error: err };
    }
  };
}
