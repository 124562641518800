import React from 'react';
import { usePaginator } from '../../Paginator.provider';
import { PageLinkButton, PageLinkButtonProps } from '../../components/PageLinkButton';

type Props = PageLinkButtonProps;

export const FirstPageLink: React.VFC<Props> = (props) => {
  const { options } = usePaginator();
  return (
    <PageLinkButton
      {...options.firstPageLink}
      {...props}
      data-test="first-page-button"
      iconClassName="pi icon-arrow-to-first"
    />
  );
};

export type { Props as FirstPageLinkProps };
