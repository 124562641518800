import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import { LocalizedMessage } from 'shared/components/other';
import { SaveButtons } from 'shared/components/common/buttons/SaveButtons';
import LogoPreview from '../components/LogoPreview';

import classes from './EditAdvertiser.module.scss';

export const EditAdvertiser = observer(() => {
  const { editAdvertiserStore, advertiserStore } = useStores();
  const { initAdvertiser, editAdvertiser, logoPreviewPath, setLogo } = editAdvertiserStore;
  const { advertiser } = advertiserStore;

  const setInitialAdvertiser = useCallback(() => {
    if (advertiser !== null) {
      initAdvertiser(advertiser);
    }
  }, [initAdvertiser, advertiser]);

  const handleSaveButtonsSave = () => {
    if (advertiser !== null) {
      editAdvertiser(advertiser);
    }
  };

  useEffect(() => {
    setInitialAdvertiser();
  }, [advertiser, setInitialAdvertiser]);

  return (
    <div>
      <header className={classes.Header}>
        <h1 className={classes.Title}>
          <LocalizedMessage id="administration.advertiser.title" />
        </h1>
        <SaveButtons onCancel={setInitialAdvertiser} onSave={handleSaveButtonsSave} />
      </header>

      <div className={classes.Content}>
        <LogoPreview
          titleId="administration.advertiser.upload-logo"
          imageAltTextId="administration.advertiser.logo-alt-text"
          path={logoPreviewPath}
          onChange={setLogo}
        />
      </div>
    </div>
  );
});
