import React, { useCallback } from 'react';

import { LocalizedMessage } from 'shared/components/other';
import { Select } from 'shared/components/common/form/Select';
import { CustomField } from 'types/powerBI';
import { IOption } from 'types';

import { RadioButton } from '../RadioButton';

import classes from './SelectLevel.module.scss';

type SelectType = 'page' | 'brand' | 'campaign' | 'category' | 'subbrand';

type Option = IOption & { isCampaignOption?: boolean };

type Props = {
  index: number;
  field: CustomField;
  campaignOptions: IOption[];
  brandOptions: IOption[];
  subbrandsOptions: IOption[];
  categoriesOptions: IOption[];
  typeField: 'internal' | 'client';
  isCampaignOption: boolean;
  onChangeSelect: (index: number, type: SelectType, isSubbrand?: boolean) => (value: number, option?: Option) => void;
  onChangeLevel: (type: CustomField['type'], index: number) => void;
};

export const SelectLevel: React.FC<Props> = (props: Props) => {
  const {
    field,
    index,
    onChangeLevel,
    onChangeSelect,
    brandOptions,
    subbrandsOptions,
    campaignOptions,
    categoriesOptions,
    typeField,
    isCampaignOption,
  } = props;

  const onChangeRadio = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const type = event.target.value as CustomField['type'];
      onChangeLevel(type, index);
    },
    [onChangeLevel, index],
  );

  const makeChangeSelect = (i: number, type: SelectType) => {
    if (type === 'subbrand') {
      return (val: number, option?: Option) => {
        if (val) {
          onChangeSelect(i, 'brand', true)(val, option);
        }
      };
    }

    return onChangeSelect(i, type);
  };

  const getOptionsByFieldType = () => {
    const map: Record<CustomField['type'], IOption[]> = {
      campaign: campaignOptions,
      brand: brandOptions,
      category: categoriesOptions,
      subbrand: brandOptions,
    };

    return map[field.type];
  };

  const getValueByFieldType = () => {
    if (field.type === 'campaign') {
      return campaignOptions.find((o) => o.value === field.campaign) || null;
    }
    if (field.type === 'brand') {
      return brandOptions.find((o) => o.value === field.brand) || null;
    }
    if (field.type === 'category') {
      return categoriesOptions.find((o) => o.value === field.category) || null;
    }
    if (field.type === 'subbrand') {
      return brandOptions.find((o) => o.value === field.brand) || null;
    }

    return null;
  };

  return (
    <div>
      <div className={classes.RadioButtons}>
        {isCampaignOption && (
          <RadioButton
            name={`type_${typeField}_${index}`}
            value="category"
            checked={field.type === 'category'}
            onChange={onChangeRadio}
          >
            <LocalizedMessage id="powerbi.options.category" />
          </RadioButton>
        )}
        {isCampaignOption && (
          <RadioButton
            name={`type_${typeField}_${index}`}
            value="brand"
            checked={field.type === 'brand'}
            onChange={onChangeRadio}
          >
            <LocalizedMessage id="powerbi.options.brand" />
          </RadioButton>
        )}
        {isCampaignOption && (
          <RadioButton
            name={`type_${typeField}_${index}`}
            value="subbrand"
            checked={field.type === 'subbrand'}
            onChange={onChangeRadio}
          >
            <LocalizedMessage id="powerbi.options.subbrand" />
          </RadioButton>
        )}
        {isCampaignOption && (
          <RadioButton
            name={`type_${typeField}_${index}`}
            value="campaign"
            checked={field.type === 'campaign'}
            onChange={onChangeRadio}
          >
            <LocalizedMessage id="powerbi.options.campaign" />
          </RadioButton>
        )}
      </div>
      {!isCampaignOption && <LocalizedMessage id="powerbi.options.brand" />}
      <div className={classes.Select}>
        <div className={classes.SelectWrapper}>
          <Select
            options={getOptionsByFieldType()}
            value={getValueByFieldType()?.value}
            onChange={makeChangeSelect(index, field.type)}
            invalid={field.errors.includes(field.type === 'subbrand' ? 'brand' : field.type)}
            mode="inline"
          />
        </div>
        {field.type === 'subbrand' ? (
          <div className={classes.SelectWrapper}>
            <Select
              options={subbrandsOptions}
              value={subbrandsOptions.find((option) => option.value === field.subbrand)?.value}
              onChange={onChangeSelect(index, 'subbrand', true)}
              invalid={field.errors.includes('subbrand')}
              mode="inline"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
