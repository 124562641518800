import React, { useState, useContext } from 'react';
import { PaginatorContext, ContextType, defaultOptions, Options } from './Paginator.context';

type Props = {
  children: React.ReactNode;
};

export const PaginatorProvider = ({ children }: Props): React.ReactElement => {
  const [options, setOptions] = useState<Options>(defaultOptions);
  return <PaginatorContext.Provider value={{ options, setOptions }}>{children}</PaginatorContext.Provider>;
};

export const usePaginator = (): ContextType => useContext(PaginatorContext);

export type { ContextType, Options };
