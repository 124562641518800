import React, { useMemo, VFC } from 'react';
import cx from 'classnames';
import { Button as PrimeButton, ButtonProps } from 'primereact/button';
import { defaultTooltipOptions } from '../../defaultProps';
import classes from './Button.module.scss';

type Props = ButtonProps & {
  /** Color of the icons */
  iconColor?: string;
  /** Color of the text */
  textColor?: string;
  /** If false, it specifies that the component should not have padding. Default: true */
  padding?: boolean;
  /** Size of the component */
  size?: 'small' | 'medium' | 'big';
  /** Component color theme. Default: 'dark' */
  theme?: 'dark' | 'light' | 'transparent' | 'success';
};

export const Button: VFC<Props> = (props) => {
  const {
    className,
    disabled,
    iconColor,
    textColor,
    size,
    tooltip,
    tooltipOptions,
    padding = true,
    theme = 'dark',
    type = 'button',
    ...restProps
  } = props;
  const style = useMemo(() => ({ '--icon-color': iconColor, '--button-text': textColor } as React.CSSProperties), [
    iconColor,
    textColor,
  ]);

  return (
    <PrimeButton
      className={cx(classes.root, className, classes[`${theme}Theme`], {
        [classes.hasNoPadding]: !padding,
        [classes[`${size}Size`]]: !!size,
      })}
      type={type}
      disabled={disabled}
      style={style}
      tooltip={disabled ? undefined : tooltip}
      tooltipOptions={{ ...defaultTooltipOptions, ...tooltipOptions }}
      {...restProps}
    />
  );
};

export type { Props as ButtonProps };
