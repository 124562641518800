import React, { VFC } from 'react';
import { localize, LocalizedTitle, PowerBIReport } from 'shared/components/other';

import classes from './PRGBenchmarks.module.scss';

export const PRGBenchmarks: VFC = () => {
  return (
    <>
      <LocalizedTitle id="site.title.prg-benchmarks" />
      <div className={classes.Dashboard}>
        <PowerBIReport pageName="PRG_BENCHMARK" isFullHeight fallbackMessage={localize('powerbi.nodata')} />
      </div>
    </>
  );
};
