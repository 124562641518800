import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from 'core';
import { Button } from 'shared/components/common/buttons/Button';
import { ServerModels as SM } from 'types/server';
import classes from './FilterButtonCell.module.scss';

type Props = {
  filters: SM.CampaignFilters;
  label: string | null;
};

export const FilterButtonCell: React.VFC<Props> = observer(({ filters, label }) => {
  const {
    excessStore: { setCampaignFilters, setActiveTab },
    sidebarStore,
  } = useStores();

  if (label === null) {
    return <span className={classes.dash}>-</span>;
  }

  const handleButtonClick = () => {
    setActiveTab(null);
    sidebarStore.hide();
    setCampaignFilters(filters);
  };

  return <Button className={cx(classes.root, 'p-button-text')} onClick={handleButtonClick} label={label} />;
});
