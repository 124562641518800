import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import { IPowerBIFilters, ICommonPowerBIProps } from 'types/powerBI';
import { Loader, LoaderProps } from 'shared/components/common/misc/Loader';
import { localize } from 'shared/components/other';
import { useStores } from 'core';

import classes from './PowerBIReport.module.scss';

type OwnProps = {
  filters?: IPowerBIFilters;
  isFullHeight?: boolean;
  onError?: () => void;
  url?: string;
  className?: string;
  withGlobalLoader?: boolean;
  fallbackMessage?: string;
  loaderProps?: LoaderProps;
} & ICommonPowerBIProps;

type Props = OwnProps;

const PowerBIReport: React.FC<Props> = (props: Props) => {
  const {
    powerBiStore: { getPowerBIResponse, loadPowerBIReport, getIsLoading, cleanUp },
    advertiserStore: { currentAdvertiserId },
  } = useStores();

  const {
    isFullHeight,
    onError,
    filters,
    pageName,
    brandId,
    subbrandId,
    categoryId,
    campaignId,
    csIds,
    startDate,
    endDate,
    mediaType,
    positionName,
    url,
    className,
    loaderProps,
    advertiserId = currentAdvertiserId,
    fallbackMessage = localize('powerbi.error.failed-loading'),
    withGlobalLoader = true,
  } = props;

  const isLoading = getIsLoading(pageName);

  useEffect(() => {
    loadPowerBIReport({
      pageName,
      brandId,
      subbrandId,
      categoryId,
      campaignId,
      csIds,
      startDate,
      endDate,
      mediaType,
      positionName,
      advertiserId,
      filters,
      onError,
      withLoader: withGlobalLoader,
    });
  }, [
    loadPowerBIReport,
    pageName,
    brandId,
    subbrandId,
    categoryId,
    campaignId,
    csIds,
    startDate,
    endDate,
    mediaType,
    positionName,
    advertiserId,
    filters,
    onError,
    withGlobalLoader,
  ]);

  useEffect(() => {
    return () => cleanUp(pageName);
  }, [cleanUp, pageName]);

  const powerBI = getPowerBIResponse(pageName);

  if (isLoading) {
    return <Loader size="large" visible={false} {...loaderProps} />;
  }

  if (powerBI === null) {
    return (
      <div
        className={cx(classes.NoContent, {
          [classes.NoContentFullHeight]: isFullHeight,
        })}
      >
        <span>{fallbackMessage}</span>
      </div>
    );
  }

  return (
    <div
      className={cx(className, classes.IframeContainer, {
        [classes.IframeContainerFullHeight]: isFullHeight,
      })}
    >
      <iframe className={classes.Iframe} title="webReport" src={url ?? powerBI?.powerBIURL} />
    </div>
  );
};

export type { Props as PowerBIReportProps };

export default observer(PowerBIReport);
