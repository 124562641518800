export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: any;
  LocalDateTime: any;
  /** A 64-bit signed integer */
  Long: any;
  /** A 16-bit signed integer */
  Short: any;
};

export type AdfoxBannerFilter = {
  campaignId: Scalars['Int'];
  date?: InputMaybe<DatePeriodFilter>;
  idOrName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type AdfoxBannerView = {
  __typename?: 'AdfoxBannerView';
  campaignId?: Maybe<Scalars['Int']>;
  dateEnd?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idAndName?: Maybe<Scalars['String']>;
  impressions?: Maybe<Scalars['Long']>;
  impressionsPlanFact?: Maybe<Scalars['Int']>;
  impressionsPlanFactYesterday?: Maybe<Scalars['Int']>;
  impressionsToday?: Maybe<Scalars['Int']>;
  maxImpressions?: Maybe<Scalars['Long']>;
  maxImpressionsPerDay?: Maybe<Scalars['Int']>;
  maxImpressionsPerHour?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Short']>;
};

export type AdfoxBannerViewConnection = {
  __typename?: 'AdfoxBannerViewConnection';
  edges?: Maybe<Array<Maybe<AdfoxBannerViewEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AdfoxBannerViewEdge = {
  __typename?: 'AdfoxBannerViewEdge';
  cursor: Scalars['String'];
  node: AdfoxBannerViewNode;
};

export type AdfoxBannerViewInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  dateEnd?: InputMaybe<Scalars['String']>;
  dateStart?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  idAndName?: InputMaybe<Scalars['String']>;
  impressions?: InputMaybe<Scalars['Long']>;
  impressionsPlanFact?: InputMaybe<Scalars['Int']>;
  impressionsPlanFactYesterday?: InputMaybe<Scalars['Int']>;
  impressionsToday?: InputMaybe<Scalars['Int']>;
  maxImpressions?: InputMaybe<Scalars['Long']>;
  maxImpressionsPerDay?: InputMaybe<Scalars['Int']>;
  maxImpressionsPerHour?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Short']>;
};

export type AdfoxBannerViewNode = {
  __typename?: 'AdfoxBannerViewNode';
  campaignId?: Maybe<Scalars['Int']>;
  dateEnd?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idAndName?: Maybe<Scalars['String']>;
  impressions?: Maybe<Scalars['Long']>;
  impressionsPlanFact?: Maybe<Scalars['Int']>;
  impressionsPlanFactYesterday?: Maybe<Scalars['Int']>;
  impressionsToday?: Maybe<Scalars['Int']>;
  maxImpressions?: Maybe<Scalars['Long']>;
  maxImpressionsPerDay?: Maybe<Scalars['Int']>;
  maxImpressionsPerHour?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Short']>;
};

export type AdfoxCampaignFilter = {
  apiAccountId: Scalars['Int'];
  campaignIds?: InputMaybe<Array<Scalars['Int']>>;
  date?: InputMaybe<DatePeriodFilter>;
  idOrName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type AdfoxCampaignView = {
  __typename?: 'AdfoxCampaignView';
  apiAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  bannersCount: Scalars['Long'];
  bannersSummaryMaxImpressions?: Maybe<Scalars['Long']>;
  bannersSummaryMaxImpressionsPerDay?: Maybe<Scalars['Long']>;
  dateEnd?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idAndName?: Maybe<Scalars['String']>;
  impressionsAll?: Maybe<Scalars['Long']>;
  impressionsToday?: Maybe<Scalars['Int']>;
  maxImpressions?: Maybe<Scalars['Long']>;
  maxImpressionsPerDay?: Maybe<Scalars['Int']>;
  maxImpressionsPerHour?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Short']>;
};

export type AdfoxCampaignViewConnection = {
  __typename?: 'AdfoxCampaignViewConnection';
  edges?: Maybe<Array<Maybe<AdfoxCampaignViewEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AdfoxCampaignViewEdge = {
  __typename?: 'AdfoxCampaignViewEdge';
  cursor: Scalars['String'];
  node: AdfoxCampaignViewNode;
};

export type AdfoxCampaignViewInput = {
  apiAccountIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  bannersCount: Scalars['Long'];
  bannersSummaryMaxImpressions?: InputMaybe<Scalars['Long']>;
  bannersSummaryMaxImpressionsPerDay?: InputMaybe<Scalars['Long']>;
  dateEnd?: InputMaybe<Scalars['String']>;
  dateStart?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  idAndName?: InputMaybe<Scalars['String']>;
  impressionsAll?: InputMaybe<Scalars['Long']>;
  impressionsToday?: InputMaybe<Scalars['Int']>;
  maxImpressions?: InputMaybe<Scalars['Long']>;
  maxImpressionsPerDay?: InputMaybe<Scalars['Int']>;
  maxImpressionsPerHour?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Short']>;
};

export type AdfoxCampaignViewNode = {
  __typename?: 'AdfoxCampaignViewNode';
  apiAccountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  bannersCount: Scalars['Long'];
  bannersSummaryMaxImpressions?: Maybe<Scalars['Long']>;
  bannersSummaryMaxImpressionsPerDay?: Maybe<Scalars['Long']>;
  dateEnd?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idAndName?: Maybe<Scalars['String']>;
  impressionsAll?: Maybe<Scalars['Long']>;
  impressionsToday?: Maybe<Scalars['Int']>;
  maxImpressions?: Maybe<Scalars['Long']>;
  maxImpressionsPerDay?: Maybe<Scalars['Int']>;
  maxImpressionsPerHour?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Short']>;
};

export type AdfoxLogFilter = {
  campaignId?: InputMaybe<Scalars['Int']>;
  idOrName?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<TimePeriodFilter>;
  userName?: InputMaybe<Scalars['String']>;
};

export type AdfoxLogItemView = {
  __typename?: 'AdfoxLogItemView';
  bannerIdAndName?: Maybe<Scalars['String']>;
  bannerName?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Int']>;
  campaignIdAndName?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  userCreateInfo: UserCreateInfoView;
  userName?: Maybe<Scalars['String']>;
};

export type AdfoxLogItemViewConnection = {
  __typename?: 'AdfoxLogItemViewConnection';
  edges?: Maybe<Array<Maybe<AdfoxLogItemViewEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AdfoxLogItemViewEdge = {
  __typename?: 'AdfoxLogItemViewEdge';
  cursor: Scalars['String'];
  node: AdfoxLogItemViewNode;
};

export type AdfoxLogItemViewInput = {
  bannerIdAndName?: InputMaybe<Scalars['String']>;
  bannerName?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  campaignIdAndName?: InputMaybe<Scalars['String']>;
  campaignName?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  newValue?: InputMaybe<Scalars['String']>;
  oldValue?: InputMaybe<Scalars['String']>;
  userCreateInfo: UserCreateInfoViewInput;
  userName?: InputMaybe<Scalars['String']>;
};

export type AdfoxLogItemViewNode = {
  __typename?: 'AdfoxLogItemViewNode';
  bannerIdAndName?: Maybe<Scalars['String']>;
  bannerName?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Int']>;
  campaignIdAndName?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  userCreateInfo: UserCreateInfoView;
  userName?: Maybe<Scalars['String']>;
};

export type AdfoxLogResponse = {
  __typename?: 'AdfoxLogResponse';
  data?: Maybe<Array<AdfoxLogItemView>>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Long']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type CsidFilter = {
  operator?: InputMaybe<FilterOperator>;
  value?: InputMaybe<Array<Scalars['String']>>;
};

export enum CabinetStatus {
  Started = 'STARTED',
  Stopped = 'STOPPED',
}

export type CabinetStatusSummary = {
  __typename?: 'CabinetStatusSummary';
  started: Scalars['Int'];
  stopped: Scalars['Int'];
};

export type CampaignCsid = {
  __typename?: 'CampaignCSID';
  csId?: Maybe<Scalars['String']>;
};

export type CampaignCsidFilters = {
  cabinetIds?: InputMaybe<Array<Scalars['Int']>>;
  campaignIds?: InputMaybe<Array<Scalars['Int']>>;
  csId?: InputMaybe<Scalars['String']>;
};

export type CampaignFilters = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']>>;
  cabinetIds?: InputMaybe<Array<Scalars['Int']>>;
  cabinetStatus?: InputMaybe<CabinetStatus>;
  campaignStatus?: InputMaybe<Array<CampaignStatus>>;
  checkStatus?: InputMaybe<CheckStatus>;
  csId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<DatePeriodFilter>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<DatePeriodFilter>;
};

export enum CampaignStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Paused = 'PAUSED',
  Unconfirmed = 'UNCONFIRMED',
}

export type CampaignStatusSummaryFilters = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']>>;
  cabinetIds?: InputMaybe<Array<Scalars['Int']>>;
  campaignName?: InputMaybe<Scalars['String']>;
  campaignStatus?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<DatePeriodFilter>;
  startDate?: InputMaybe<DatePeriodFilter>;
};

export type ChangeCabinetStatus = {
  __typename?: 'ChangeCabinetStatus';
  campaignId: Scalars['Int'];
  inProgress?: Maybe<Scalars['Boolean']>;
  itemRowId: Scalars['Int'];
  mediaplanId: Scalars['Int'];
  positionId: Scalars['String'];
  positionTypeId: Scalars['Int'];
};

export enum CheckStatus {
  Error = 'ERROR',
  Ok = 'OK',
  Warning = 'WARNING',
}

export type CheckStatusSummary = {
  __typename?: 'CheckStatusSummary';
  error: Scalars['Int'];
  ok: Scalars['Int'];
  warning: Scalars['Int'];
};

export type DatePeriodFilter = {
  periodEnd?: InputMaybe<Scalars['Date']>;
  periodStart?: InputMaybe<Scalars['Date']>;
  /**  period */
  type?: InputMaybe<Scalars['String']>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN',
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN',
}

export type ExcessCabinetPosition = {
  __typename?: 'ExcessCabinetPosition';
  cabinetStatus?: Maybe<Scalars['Int']>;
  causeIdList?: Maybe<Array<Scalars['Int']>>;
  changeCabinetStatusState?: Maybe<Scalars['String']>;
  checkStatus?: Maybe<Scalars['Int']>;
  factBudget?: Maybe<Scalars['Float']>;
  factBudgetLimit?: Maybe<Scalars['Float']>;
  factUnitPrice?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  positionTypeId?: Maybe<Scalars['Int']>;
  statisticsSource?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPricePct?: Maybe<Scalars['Float']>;
  unitType?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Float']>;
};

export type ExcessCampaign = {
  __typename?: 'ExcessCampaign';
  active?: Maybe<Scalars['Int']>;
  advertiserId?: Maybe<Scalars['Int']>;
  advertiserName?: Maybe<Scalars['String']>;
  budgetPct?: Maybe<Scalars['Float']>;
  budgetPctToday?: Maybe<Scalars['Float']>;
  cabinetActiveTotal?: Maybe<Scalars['Int']>;
  cabinetStatus?: Maybe<Scalars['Int']>;
  cabinetStoppedTotal?: Maybe<Scalars['Int']>;
  campaignId: Scalars['Int'];
  campaignName?: Maybe<Scalars['String']>;
  campaignStatus?: Maybe<Scalars['String']>;
  campaignsTotal?: Maybe<Scalars['Int']>;
  checkStatus?: Maybe<Scalars['Int']>;
  checkStatusErrorTotal?: Maybe<Scalars['Int']>;
  checkStatusOkTotal?: Maybe<Scalars['Int']>;
  checkStatusWarningTotal?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  factBudget?: Maybe<Scalars['Float']>;
  factBudgetLimit?: Maybe<Scalars['Float']>;
  planBudget?: Maybe<Scalars['Float']>;
  planBudgetToday?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  total?: Maybe<Scalars['Int']>;
  unreadLogMessages?: Maybe<Scalars['Int']>;
};

export type ExcessCampaignStatusSummary = {
  __typename?: 'ExcessCampaignStatusSummary';
  cabinetStatuses?: Maybe<CabinetStatusSummary>;
  checkStatuses?: Maybe<CheckStatusSummary>;
};

export type ExcessCause = {
  __typename?: 'ExcessCause';
  displayMessageFull?: Maybe<Scalars['String']>;
  displayMessageFullPure?: Maybe<Scalars['String']>;
  displayMessageShort?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  severity?: Maybe<Scalars['Int']>;
};

export type ExcessChangeCabinetStatusDto = {
  advertiserId: Scalars['Int'];
  campaignId: Scalars['Int'];
  csId: Scalars['String'];
  mediaplanId: Scalars['Int'];
  newStatus: CabinetStatus;
  positionId: Scalars['String'];
  positionTypeId: Scalars['Int'];
  rowNumber: Scalars['Int'];
};

export type ExcessChangeCabinetStatusRequest = {
  statusRequests: Array<ExcessChangeCabinetStatusDto>;
};

export type ExcessChangeCabinetStatusResponse = {
  __typename?: 'ExcessChangeCabinetStatusResponse';
  changeStatuses: Array<ChangeCabinetStatus>;
};

export type ExcessCheckSettingDto = {
  expires?: InputMaybe<Scalars['Date']>;
  id: Scalars['Long'];
  value: Scalars['String'];
};

export type ExcessCheckSettingResponse = {
  __typename?: 'ExcessCheckSettingResponse';
  expires?: Maybe<Scalars['Date']>;
  id: Scalars['Long'];
  value: Scalars['String'];
};

export type ExcessCheckSettingsMetadataDto = {
  __typename?: 'ExcessCheckSettingsMetadataDTO';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expirable?: Maybe<Scalars['Boolean']>;
  expirationLimitMs?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  privilege?: Maybe<Privilege>;
  valueType?: Maybe<ValueType>;
};

export type ExcessCheckSettingsRequest = {
  campaignId: Scalars['Int'];
  csId: Scalars['String'];
  mediaplanId: Scalars['Int'];
  rowNumber: Scalars['Int'];
  settings: Array<ExcessCheckSettingDto>;
};

export type ExcessCheckSettingsResponse = {
  __typename?: 'ExcessCheckSettingsResponse';
  settings?: Maybe<Array<ExcessCheckSettingResponse>>;
  status?: Maybe<ExcessSettingsStatus>;
};

export type ExcessLogEntriesResponse = {
  __typename?: 'ExcessLogEntriesResponse';
  data?: Maybe<Array<OperationLogEntry>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ExcessMediaplanRow = {
  __typename?: 'ExcessMediaplanRow';
  active?: Maybe<Scalars['Int']>;
  budgetPct?: Maybe<Scalars['Float']>;
  budgetPctToday?: Maybe<Scalars['Float']>;
  cabinetStatus?: Maybe<Scalars['Int']>;
  causeIdList?: Maybe<Array<Scalars['Int']>>;
  checkStatus?: Maybe<Scalars['Int']>;
  csId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  factBudget?: Maybe<Scalars['Float']>;
  factBudgetLimit?: Maybe<Scalars['Float']>;
  factUnitPrice?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  mediaplanId?: Maybe<Scalars['Int']>;
  planBudget?: Maybe<Scalars['Float']>;
  planBudgetToday?: Maybe<Scalars['Float']>;
  positions?: Maybe<Array<ExcessCabinetPosition>>;
  rowNumber?: Maybe<Scalars['Int']>;
  seller?: Maybe<Scalars['String']>;
  settingsStatus?: Maybe<ExcessSettingsStatus>;
  startDate?: Maybe<Scalars['Date']>;
  total?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPricePct?: Maybe<Scalars['Float']>;
  unitType?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Float']>;
};

export enum ExcessSettingsStatus {
  Any = 'ANY',
  None = 'NONE',
  NonVisible = 'NON_VISIBLE',
}

export type ExcessUpdateSettingsResponse = {
  __typename?: 'ExcessUpdateSettingsResponse';
  status?: Maybe<ExcessSettingsStatus>;
};

export enum FilterOperator {
  Between = 'BETWEEN',
  Eq = 'EQ',
  Geq = 'GEQ',
  Gt = 'GT',
  In = 'IN',
  Leq = 'LEQ',
  Like = 'LIKE',
  Lt = 'LT',
}

export type LogEntriesExcessFilters = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']>>;
  cabinetIds?: InputMaybe<Array<Scalars['Int']>>;
  campaignIds?: InputMaybe<Array<Scalars['Int']>>;
  campaignStatus?: InputMaybe<Array<CampaignStatus>>;
  checkStatus?: InputMaybe<CheckStatus>;
  csId?: InputMaybe<CsidFilter>;
  endDate?: InputMaybe<DatePeriodFilter>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<DatePeriodFilter>;
};

export type LogEntriesFilters = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']>>;
  campaignName?: InputMaybe<Scalars['String']>;
  cause?: InputMaybe<Scalars['String']>;
  csId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<CheckStatus>>;
  timeCreate?: InputMaybe<TimePeriodFilter>;
  userName?: InputMaybe<Scalars['String']>;
};

export type MediaplanRowsFilters = {
  cabinetIds?: InputMaybe<Array<Scalars['Int']>>;
  campaignIds?: InputMaybe<Array<Scalars['Int']>>;
  csId?: InputMaybe<Scalars['String']>;
};

export type MockPlanFactView1 = {
  __typename?: 'MockPlanFactView1';
  advertiserId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: Maybe<Scalars['Float']>;
  factClicks?: Maybe<Scalars['Float']>;
  factImpressions?: Maybe<Scalars['Float']>;
  factReach?: Maybe<Scalars['Float']>;
  factViewability?: Maybe<Scalars['Float']>;
  factViews?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  planBudget?: Maybe<Scalars['Float']>;
  planClicks?: Maybe<Scalars['Float']>;
  planImpression?: Maybe<Scalars['Float']>;
  planReach?: Maybe<Scalars['Float']>;
  planUnitPrice?: Maybe<Scalars['Float']>;
  planViewability?: Maybe<Scalars['Float']>;
  planViews?: Maybe<Scalars['Float']>;
  targetAudience?: Maybe<Scalars['String']>;
  targeting?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type MockPlanFactView1Connection = {
  __typename?: 'MockPlanFactView1Connection';
  edges?: Maybe<Array<Maybe<MockPlanFactView1Edge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MockPlanFactView1Edge = {
  __typename?: 'MockPlanFactView1Edge';
  cursor: Scalars['String'];
  node: MockPlanFactView1Node;
};

export type MockPlanFactView1Input = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  brand?: InputMaybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: InputMaybe<Scalars['Float']>;
  factClicks?: InputMaybe<Scalars['Float']>;
  factImpressions?: InputMaybe<Scalars['Float']>;
  factReach?: InputMaybe<Scalars['Float']>;
  factViewability?: InputMaybe<Scalars['Float']>;
  factViews?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  planBudget?: InputMaybe<Scalars['Float']>;
  planClicks?: InputMaybe<Scalars['Float']>;
  planImpression?: InputMaybe<Scalars['Float']>;
  planReach?: InputMaybe<Scalars['Float']>;
  planUnitPrice?: InputMaybe<Scalars['Float']>;
  planViewability?: InputMaybe<Scalars['Float']>;
  planViews?: InputMaybe<Scalars['Float']>;
  targetAudience?: InputMaybe<Scalars['String']>;
  targeting?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MockPlanFactView1Node = {
  __typename?: 'MockPlanFactView1Node';
  advertiserId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: Maybe<Scalars['Float']>;
  factClicks?: Maybe<Scalars['Float']>;
  factImpressions?: Maybe<Scalars['Float']>;
  factReach?: Maybe<Scalars['Float']>;
  factViewability?: Maybe<Scalars['Float']>;
  factViews?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  planBudget?: Maybe<Scalars['Float']>;
  planClicks?: Maybe<Scalars['Float']>;
  planImpression?: Maybe<Scalars['Float']>;
  planReach?: Maybe<Scalars['Float']>;
  planUnitPrice?: Maybe<Scalars['Float']>;
  planViewability?: Maybe<Scalars['Float']>;
  planViews?: Maybe<Scalars['Float']>;
  targetAudience?: Maybe<Scalars['String']>;
  targeting?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type MockPlanFactView2 = {
  __typename?: 'MockPlanFactView2';
  advertiserId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: Maybe<Scalars['Float']>;
  factClicks?: Maybe<Scalars['Float']>;
  factImpressions?: Maybe<Scalars['Float']>;
  factReach?: Maybe<Scalars['Float']>;
  factViewability?: Maybe<Scalars['Float']>;
  factViews?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  planBudget?: Maybe<Scalars['Float']>;
  planClicks?: Maybe<Scalars['Float']>;
  planImpression?: Maybe<Scalars['Float']>;
  planReach?: Maybe<Scalars['Float']>;
  planUnitPrice?: Maybe<Scalars['Float']>;
  planViewability?: Maybe<Scalars['Float']>;
  planViews?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

export type MockPlanFactView2Connection = {
  __typename?: 'MockPlanFactView2Connection';
  edges?: Maybe<Array<Maybe<MockPlanFactView2Edge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MockPlanFactView2Edge = {
  __typename?: 'MockPlanFactView2Edge';
  cursor: Scalars['String'];
  node: MockPlanFactView2Node;
};

export type MockPlanFactView2Input = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  brand?: InputMaybe<Scalars['String']>;
  campaign?: InputMaybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: InputMaybe<Scalars['Float']>;
  factClicks?: InputMaybe<Scalars['Float']>;
  factImpressions?: InputMaybe<Scalars['Float']>;
  factReach?: InputMaybe<Scalars['Float']>;
  factViewability?: InputMaybe<Scalars['Float']>;
  factViews?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  planBudget?: InputMaybe<Scalars['Float']>;
  planClicks?: InputMaybe<Scalars['Float']>;
  planImpression?: InputMaybe<Scalars['Float']>;
  planReach?: InputMaybe<Scalars['Float']>;
  planUnitPrice?: InputMaybe<Scalars['Float']>;
  planViewability?: InputMaybe<Scalars['Float']>;
  planViews?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MockPlanFactView2Node = {
  __typename?: 'MockPlanFactView2Node';
  advertiserId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: Maybe<Scalars['Float']>;
  factClicks?: Maybe<Scalars['Float']>;
  factImpressions?: Maybe<Scalars['Float']>;
  factReach?: Maybe<Scalars['Float']>;
  factViewability?: Maybe<Scalars['Float']>;
  factViews?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  planBudget?: Maybe<Scalars['Float']>;
  planClicks?: Maybe<Scalars['Float']>;
  planImpression?: Maybe<Scalars['Float']>;
  planReach?: Maybe<Scalars['Float']>;
  planUnitPrice?: Maybe<Scalars['Float']>;
  planViewability?: Maybe<Scalars['Float']>;
  planViews?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

export type MockPlanFactView3 = {
  __typename?: 'MockPlanFactView3';
  advertiserId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: Maybe<Scalars['Float']>;
  factClicks?: Maybe<Scalars['Float']>;
  factImpressions?: Maybe<Scalars['Float']>;
  factReach?: Maybe<Scalars['Float']>;
  factViewability?: Maybe<Scalars['Float']>;
  factViews?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  month?: Maybe<Scalars['Int']>;
  planBudget?: Maybe<Scalars['Float']>;
  planClicks?: Maybe<Scalars['Float']>;
  planImpression?: Maybe<Scalars['Float']>;
  planReach?: Maybe<Scalars['Float']>;
  planUnitPrice?: Maybe<Scalars['Float']>;
  planViewability?: Maybe<Scalars['Float']>;
  planViews?: Maybe<Scalars['Float']>;
  seller?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type MockPlanFactView3Connection = {
  __typename?: 'MockPlanFactView3Connection';
  edges?: Maybe<Array<Maybe<MockPlanFactView3Edge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MockPlanFactView3Edge = {
  __typename?: 'MockPlanFactView3Edge';
  cursor: Scalars['String'];
  node: MockPlanFactView3Node;
};

export type MockPlanFactView3Input = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  brand?: InputMaybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: InputMaybe<Scalars['Float']>;
  factClicks?: InputMaybe<Scalars['Float']>;
  factImpressions?: InputMaybe<Scalars['Float']>;
  factReach?: InputMaybe<Scalars['Float']>;
  factViewability?: InputMaybe<Scalars['Float']>;
  factViews?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  month?: InputMaybe<Scalars['Int']>;
  planBudget?: InputMaybe<Scalars['Float']>;
  planClicks?: InputMaybe<Scalars['Float']>;
  planImpression?: InputMaybe<Scalars['Float']>;
  planReach?: InputMaybe<Scalars['Float']>;
  planUnitPrice?: InputMaybe<Scalars['Float']>;
  planViewability?: InputMaybe<Scalars['Float']>;
  planViews?: InputMaybe<Scalars['Float']>;
  seller?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  week?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MockPlanFactView3Node = {
  __typename?: 'MockPlanFactView3Node';
  advertiserId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: Maybe<Scalars['Float']>;
  factClicks?: Maybe<Scalars['Float']>;
  factImpressions?: Maybe<Scalars['Float']>;
  factReach?: Maybe<Scalars['Float']>;
  factViewability?: Maybe<Scalars['Float']>;
  factViews?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  month?: Maybe<Scalars['Int']>;
  planBudget?: Maybe<Scalars['Float']>;
  planClicks?: Maybe<Scalars['Float']>;
  planImpression?: Maybe<Scalars['Float']>;
  planReach?: Maybe<Scalars['Float']>;
  planUnitPrice?: Maybe<Scalars['Float']>;
  planViewability?: Maybe<Scalars['Float']>;
  planViews?: Maybe<Scalars['Float']>;
  seller?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type MockPlanFactView4 = {
  __typename?: 'MockPlanFactView4';
  advertiserId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: Maybe<Scalars['Float']>;
  factClicks?: Maybe<Scalars['Float']>;
  factImpressions?: Maybe<Scalars['Float']>;
  factReach?: Maybe<Scalars['Float']>;
  factViewability?: Maybe<Scalars['Float']>;
  factViews?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  planBudget?: Maybe<Scalars['Float']>;
  planClicks?: Maybe<Scalars['Float']>;
  planImpression?: Maybe<Scalars['Float']>;
  planReach?: Maybe<Scalars['Float']>;
  planUnitPrice?: Maybe<Scalars['Float']>;
  planViewability?: Maybe<Scalars['Float']>;
  planViews?: Maybe<Scalars['Float']>;
  seller?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type MockPlanFactView4Connection = {
  __typename?: 'MockPlanFactView4Connection';
  edges?: Maybe<Array<Maybe<MockPlanFactView4Edge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MockPlanFactView4Edge = {
  __typename?: 'MockPlanFactView4Edge';
  cursor: Scalars['String'];
  node: MockPlanFactView4Node;
};

export type MockPlanFactView4Input = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  brand?: InputMaybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: InputMaybe<Scalars['Float']>;
  factClicks?: InputMaybe<Scalars['Float']>;
  factImpressions?: InputMaybe<Scalars['Float']>;
  factReach?: InputMaybe<Scalars['Float']>;
  factViewability?: InputMaybe<Scalars['Float']>;
  factViews?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  planBudget?: InputMaybe<Scalars['Float']>;
  planClicks?: InputMaybe<Scalars['Float']>;
  planImpression?: InputMaybe<Scalars['Float']>;
  planReach?: InputMaybe<Scalars['Float']>;
  planUnitPrice?: InputMaybe<Scalars['Float']>;
  planViewability?: InputMaybe<Scalars['Float']>;
  planViews?: InputMaybe<Scalars['Float']>;
  seller?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  unitType?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type MockPlanFactView4Node = {
  __typename?: 'MockPlanFactView4Node';
  advertiserId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  count: Scalars['Long'];
  factBudget?: Maybe<Scalars['Float']>;
  factClicks?: Maybe<Scalars['Float']>;
  factImpressions?: Maybe<Scalars['Float']>;
  factReach?: Maybe<Scalars['Float']>;
  factViewability?: Maybe<Scalars['Float']>;
  factViews?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  planBudget?: Maybe<Scalars['Float']>;
  planClicks?: Maybe<Scalars['Float']>;
  planImpression?: Maybe<Scalars['Float']>;
  planReach?: Maybe<Scalars['Float']>;
  planUnitPrice?: Maybe<Scalars['Float']>;
  planViewability?: Maybe<Scalars['Float']>;
  planViews?: Maybe<Scalars['Float']>;
  seller?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  excessChangeCabinetStatus?: Maybe<ExcessChangeCabinetStatusResponse>;
  excessSetCheckSettings?: Maybe<ExcessUpdateSettingsResponse>;
};

export type MutationExcessChangeCabinetStatusArgs = {
  request?: InputMaybe<ExcessChangeCabinetStatusRequest>;
};

export type MutationExcessSetCheckSettingsArgs = {
  request?: InputMaybe<ExcessCheckSettingsRequest>;
};

export type OperationLogEntry = {
  __typename?: 'OperationLogEntry';
  advertiserId?: Maybe<Scalars['Int']>;
  advertiserName?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Int']>;
  campaignName?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  csId?: Maybe<Scalars['String']>;
  itemRowId?: Maybe<Scalars['Int']>;
  mediaplanId?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  timeCreate?: Maybe<Scalars['LocalDateTime']>;
  userName?: Maybe<Scalars['String']>;
};

export type PageDto = {
  number: Scalars['Int'];
  size: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum Privilege {
  Read = 'READ',
  ReadWrite = 'READ_WRITE',
}

export type Query = {
  __typename?: 'Query';
  adfoxBanners?: Maybe<Array<AdfoxBannerView>>;
  adfoxCampaigns?: Maybe<Array<AdfoxCampaignView>>;
  adfoxLog?: Maybe<AdfoxLogResponse>;
  excessCampaignCSID?: Maybe<Array<CampaignCsid>>;
  excessCampaigns?: Maybe<Array<ExcessCampaign>>;
  excessCampaignsStatusSummary: ExcessCampaignStatusSummary;
  excessCauses?: Maybe<Array<ExcessCause>>;
  excessCheckSettings?: Maybe<ExcessCheckSettingsResponse>;
  excessCheckSettingsMetadata?: Maybe<Array<ExcessCheckSettingsMetadataDto>>;
  excessLogEntries: ExcessLogEntriesResponse;
  excessRowsAndPositions?: Maybe<Array<ExcessMediaplanRow>>;
  pivotTest1?: Maybe<Array<MockPlanFactView1>>;
  pivotTest2?: Maybe<Array<MockPlanFactView2>>;
  pivotTest3?: Maybe<Array<MockPlanFactView3>>;
  pivotTest4?: Maybe<Array<MockPlanFactView4>>;
};

export type QueryAdfoxBannersArgs = {
  filter: AdfoxBannerFilter;
  page?: InputMaybe<PageDto>;
};

export type QueryAdfoxCampaignsArgs = {
  filter: AdfoxCampaignFilter;
  page?: InputMaybe<PageDto>;
};

export type QueryAdfoxLogArgs = {
  filter: AdfoxLogFilter;
  page?: InputMaybe<PageDto>;
  sorting?: InputMaybe<Array<SortingField>>;
};

export type QueryExcessCampaignCsidArgs = {
  filters: CampaignCsidFilters;
};

export type QueryExcessCampaignsArgs = {
  filters: CampaignFilters;
  page: PageDto;
};

export type QueryExcessCampaignsStatusSummaryArgs = {
  filters: CampaignStatusSummaryFilters;
};

export type QueryExcessCheckSettingsArgs = {
  campaignId: Scalars['Int'];
  mediaplanId: Scalars['Int'];
  rowNumber: Scalars['Int'];
};

export type QueryExcessLogEntriesArgs = {
  filters: LogEntriesFilters;
  filtersExcessData: LogEntriesExcessFilters;
  page: PageDto;
  sorting?: InputMaybe<Array<SortingField>>;
};

export type QueryExcessRowsAndPositionsArgs = {
  filters: MediaplanRowsFilters;
};

export type QueryPivotTest1Args = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type QueryPivotTest2Args = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type QueryPivotTest3Args = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type QueryPivotTest4Args = {
  advertiserId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export enum SortingDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortingField = {
  direction: SortingDirection;
  field: Scalars['String'];
};

export type TimePeriodFilter = {
  periodEnd?: InputMaybe<Scalars['LocalDateTime']>;
  periodStart?: InputMaybe<Scalars['LocalDateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UserCreateInfoView = {
  __typename?: 'UserCreateInfoView';
  timeCreate: Scalars['String'];
};

export type UserCreateInfoViewConnection = {
  __typename?: 'UserCreateInfoViewConnection';
  edges?: Maybe<Array<Maybe<UserCreateInfoViewEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserCreateInfoViewEdge = {
  __typename?: 'UserCreateInfoViewEdge';
  cursor: Scalars['String'];
  node: UserCreateInfoViewNode;
};

export type UserCreateInfoViewInput = {
  timeCreate: Scalars['String'];
};

export type UserCreateInfoViewNode = {
  __typename?: 'UserCreateInfoViewNode';
  timeCreate: Scalars['String'];
};

export enum ValueType {
  Bool = 'BOOL',
  Date = 'DATE',
  Dictionary = 'DICTIONARY',
  Double = 'DOUBLE',
  Integer = 'INTEGER',
  Percent = 'PERCENT',
  String = 'STRING',
}

export type ExcessChangeCabinetStatusMutationVariables = Exact<{
  request?: InputMaybe<ExcessChangeCabinetStatusRequest>;
}>;

export type ExcessChangeCabinetStatusMutation = {
  __typename?: 'Mutation';
  excessChangeCabinetStatus?: {
    __typename?: 'ExcessChangeCabinetStatusResponse';
    changeStatuses: Array<{
      __typename?: 'ChangeCabinetStatus';
      campaignId: number;
      mediaplanId: number;
      itemRowId: number;
      positionTypeId: number;
      positionId: string;
      inProgress?: boolean | null;
    }>;
  } | null;
};

export type ExcessSetCheckSettingsMutationVariables = Exact<{
  request?: InputMaybe<ExcessCheckSettingsRequest>;
}>;

export type ExcessSetCheckSettingsMutation = {
  __typename?: 'Mutation';
  excessSetCheckSettings?: { __typename?: 'ExcessUpdateSettingsResponse'; status?: ExcessSettingsStatus | null } | null;
};

export type ExcessCampaignCsIdsQueryVariables = Exact<{
  filters: CampaignCsidFilters;
}>;

export type ExcessCampaignCsIdsQuery = {
  __typename?: 'Query';
  excessCampaignCSID?: Array<{ __typename?: 'CampaignCSID'; csId?: string | null }> | null;
};

export type ExcessCampaignsDataQueryVariables = Exact<{
  campaignFilters: CampaignFilters;
  page: PageDto;
}>;

export type ExcessCampaignsDataQuery = {
  __typename?: 'Query';
  excessCampaigns?: Array<{
    __typename?: 'ExcessCampaign';
    active?: number | null;
    advertiserId?: number | null;
    advertiserName?: string | null;
    budgetPct?: number | null;
    budgetPctToday?: number | null;
    cabinetStatus?: number | null;
    campaignId: number;
    campaignName?: string | null;
    campaignStatus?: string | null;
    checkStatus?: number | null;
    endDate?: any | null;
    factBudget?: number | null;
    factBudgetLimit?: number | null;
    planBudget?: number | null;
    planBudgetToday?: number | null;
    startDate?: any | null;
    total?: number | null;
    unreadLogMessages?: number | null;
    cabinetActiveTotal?: number | null;
    cabinetStoppedTotal?: number | null;
    checkStatusOkTotal?: number | null;
    checkStatusWarningTotal?: number | null;
    checkStatusErrorTotal?: number | null;
    campaignsTotal?: number | null;
  }> | null;
};

export type ExcessCheckSettingsQueryVariables = Exact<{
  campaignId: Scalars['Int'];
  mediaplanId: Scalars['Int'];
  rowNumber: Scalars['Int'];
}>;

export type ExcessCheckSettingsQuery = {
  __typename?: 'Query';
  excessCheckSettings?: {
    __typename?: 'ExcessCheckSettingsResponse';
    status?: ExcessSettingsStatus | null;
    settings?: Array<{
      __typename?: 'ExcessCheckSettingResponse';
      id: any;
      value: string;
      expires?: any | null;
    }> | null;
  } | null;
};

export type ExcessCheckSettingsMetadataQueryVariables = Exact<{ [key: string]: never }>;

export type ExcessCheckSettingsMetadataQuery = {
  __typename?: 'Query';
  excessCheckSettingsMetadata?: Array<{
    __typename?: 'ExcessCheckSettingsMetadataDTO';
    id?: any | null;
    displayName?: string | null;
    privilege?: Privilege | null;
    expirationLimitMs?: number | null;
  }> | null;
};

export type ExcessLogEntriesQueryVariables = Exact<{
  filters: LogEntriesFilters;
  filtersExcessData: LogEntriesExcessFilters;
  page: PageDto;
  sorting?: InputMaybe<Array<SortingField> | SortingField>;
}>;

export type ExcessLogEntriesQuery = {
  __typename?: 'Query';
  excessLogEntries: {
    __typename?: 'ExcessLogEntriesResponse';
    pageNumber: number;
    pageSize: number;
    total: number;
    totalPages: number;
    data?: Array<{
      __typename?: 'OperationLogEntry';
      advertiserId?: number | null;
      advertiserName?: string | null;
      campaignId?: number | null;
      campaignName?: string | null;
      cause?: string | null;
      csId?: string | null;
      itemRowId?: number | null;
      mediaplanId?: number | null;
      message?: string | null;
      positionId?: string | null;
      status?: number | null;
      timeCreate?: any | null;
      userName?: string | null;
    }> | null;
  };
};

export type ExcessRowsAndPositionsQueryVariables = Exact<{
  filters: MediaplanRowsFilters;
}>;

export type ExcessRowsAndPositionsQuery = {
  __typename?: 'Query';
  excessRowsAndPositions?: Array<{
    __typename?: 'ExcessMediaplanRow';
    active?: number | null;
    budgetPct?: number | null;
    budgetPctToday?: number | null;
    cabinetStatus?: number | null;
    causeIdList?: Array<number> | null;
    checkStatus?: number | null;
    csId?: string | null;
    endDate?: any | null;
    factBudget?: number | null;
    factBudgetLimit?: number | null;
    factUnitPrice?: number | null;
    format?: string | null;
    mediaplanId?: number | null;
    planBudget?: number | null;
    planBudgetToday?: number | null;
    rowNumber?: number | null;
    seller?: string | null;
    startDate?: any | null;
    total?: number | null;
    unitPrice?: number | null;
    unitPricePct?: number | null;
    unitType?: string | null;
    units?: number | null;
    settingsStatus?: ExcessSettingsStatus | null;
    positions?: Array<{
      __typename?: 'ExcessCabinetPosition';
      cabinetStatus?: number | null;
      causeIdList?: Array<number> | null;
      checkStatus?: number | null;
      factBudget?: number | null;
      factBudgetLimit?: number | null;
      factUnitPrice?: number | null;
      positionId?: string | null;
      positionTypeId?: number | null;
      statisticsSource?: string | null;
      unitPrice?: number | null;
      unitPricePct?: number | null;
      unitType?: string | null;
      units?: number | null;
    }> | null;
  }> | null;
};

export type PivotTest1QueryVariables = Exact<{
  advertiserId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
}>;

export type PivotTest1Query = {
  __typename?: 'Query';
  pivotTest1?: Array<{
    __typename?: 'MockPlanFactView1';
    advertiserId?: number | null;
    brand?: string | null;
    count: any;
    factBudget?: number | null;
    factClicks?: number | null;
    factImpressions?: number | null;
    factReach?: number | null;
    factViewability?: number | null;
    factViews?: number | null;
    id: string;
    planBudget?: number | null;
    planClicks?: number | null;
    planImpression?: number | null;
    planReach?: number | null;
    planUnitPrice?: number | null;
    planViewability?: number | null;
    planViews?: number | null;
    targetAudience?: string | null;
    targeting?: string | null;
    year?: number | null;
  }> | null;
};

export type PivotTest2QueryVariables = Exact<{
  advertiserId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
}>;

export type PivotTest2Query = {
  __typename?: 'Query';
  pivotTest2?: Array<{
    __typename?: 'MockPlanFactView2';
    advertiserId?: number | null;
    brand?: string | null;
    campaign?: string | null;
    count: any;
    factBudget?: number | null;
    factClicks?: number | null;
    factImpressions?: number | null;
    factReach?: number | null;
    factViewability?: number | null;
    factViews?: number | null;
    id: string;
    planBudget?: number | null;
    planClicks?: number | null;
    planImpression?: number | null;
    planReach?: number | null;
    planUnitPrice?: number | null;
    planViewability?: number | null;
    planViews?: number | null;
    year?: number | null;
  }> | null;
};

export type PivotTest3QueryVariables = Exact<{
  advertiserId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
}>;

export type PivotTest3Query = {
  __typename?: 'Query';
  pivotTest3?: Array<{
    __typename?: 'MockPlanFactView3';
    advertiserId?: number | null;
    brand?: string | null;
    count: any;
    factBudget?: number | null;
    factClicks?: number | null;
    factImpressions?: number | null;
    factReach?: number | null;
    factViewability?: number | null;
    factViews?: number | null;
    id: string;
    month?: number | null;
    planBudget?: number | null;
    planClicks?: number | null;
    planImpression?: number | null;
    planReach?: number | null;
    planUnitPrice?: number | null;
    planViewability?: number | null;
    planViews?: number | null;
    seller?: string | null;
    site?: string | null;
    week?: number | null;
    year?: number | null;
  }> | null;
};

export type PivotTest4QueryVariables = Exact<{
  advertiserId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
}>;

export type PivotTest4Query = {
  __typename?: 'Query';
  pivotTest4?: Array<{
    __typename?: 'MockPlanFactView4';
    advertiserId?: number | null;
    brand?: string | null;
    count: any;
    factBudget?: number | null;
    factClicks?: number | null;
    factImpressions?: number | null;
    factReach?: number | null;
    factViewability?: number | null;
    factViews?: number | null;
    id: string;
    planBudget?: number | null;
    planClicks?: number | null;
    planImpression?: number | null;
    planReach?: number | null;
    planUnitPrice?: number | null;
    planViewability?: number | null;
    planViews?: number | null;
    seller?: string | null;
    site?: string | null;
    unitType?: string | null;
    year?: number | null;
  }> | null;
};

export type AdFoxBannerLogQueryVariables = Exact<{
  filter: AdfoxLogFilter;
  sorting?: InputMaybe<Array<SortingField> | SortingField>;
  page?: InputMaybe<PageDto>;
}>;

export type AdFoxBannerLogQuery = {
  __typename?: 'Query';
  adfoxLog?: {
    __typename?: 'AdfoxLogResponse';
    total?: any | null;
    totalPages?: number | null;
    data?: Array<{
      __typename?: 'AdfoxLogItemView';
      id: string;
      campaignName?: string | null;
      bannerName?: string | null;
      userName?: string | null;
      fieldName?: string | null;
      oldValue?: string | null;
      newValue?: string | null;
      userCreateInfo: { __typename?: 'UserCreateInfoView'; timeCreate: string };
    }> | null;
  } | null;
};

export type AdFoxBannersQueryVariables = Exact<{
  filter: AdfoxBannerFilter;
  page?: InputMaybe<PageDto>;
}>;

export type AdFoxBannersQuery = {
  __typename?: 'Query';
  adfoxBanners?: Array<{
    __typename?: 'AdfoxBannerView';
    id: string;
    name?: string | null;
    status?: any | null;
    campaignId?: number | null;
    dateEnd?: string | null;
    dateStart?: string | null;
    idAndName?: string | null;
    impressions?: any | null;
    impressionsToday?: number | null;
    impressionsPlanFact?: number | null;
    impressionsPlanFactYesterday?: number | null;
    maxImpressions?: any | null;
    maxImpressionsPerDay?: number | null;
    maxImpressionsPerHour?: number | null;
  }> | null;
};

export type AdFoxCampaignLogQueryVariables = Exact<{
  filter: AdfoxLogFilter;
  sorting?: InputMaybe<Array<SortingField> | SortingField>;
  page?: InputMaybe<PageDto>;
}>;

export type AdFoxCampaignLogQuery = {
  __typename?: 'Query';
  adfoxLog?: {
    __typename?: 'AdfoxLogResponse';
    total?: any | null;
    totalPages?: number | null;
    data?: Array<{
      __typename?: 'AdfoxLogItemView';
      id: string;
      campaignName?: string | null;
      userName?: string | null;
      fieldName?: string | null;
      oldValue?: string | null;
      newValue?: string | null;
      userCreateInfo: { __typename?: 'UserCreateInfoView'; timeCreate: string };
    }> | null;
  } | null;
};

export type AdFoxCampaignsQueryVariables = Exact<{
  filter: AdfoxCampaignFilter;
  page?: InputMaybe<PageDto>;
}>;

export type AdFoxCampaignsQuery = {
  __typename?: 'Query';
  adfoxCampaigns?: Array<{
    __typename?: 'AdfoxCampaignView';
    name?: string | null;
    id: string;
    bannersCount: any;
    status?: any | null;
    dateStart?: string | null;
    dateEnd?: string | null;
    impressionsAll?: any | null;
    impressionsToday?: number | null;
    maxImpressions?: any | null;
    maxImpressionsPerDay?: number | null;
    maxImpressionsPerHour?: number | null;
  }> | null;
};

export type AdFoxSummaryQueryVariables = Exact<{
  filter: AdfoxCampaignFilter;
}>;

export type AdFoxSummaryQuery = {
  __typename?: 'Query';
  adfoxCampaigns?: Array<{
    __typename?: 'AdfoxCampaignView';
    maxImpressions?: any | null;
    maxImpressionsPerDay?: number | null;
    bannersSummaryMaxImpressions?: any | null;
    bannersSummaryMaxImpressionsPerDay?: any | null;
  }> | null;
};
