import * as R from 'ramda';
import { format } from 'date-fns';
import { DataGridFilters } from 'shared/components/other';
import { ServerModels as SM } from 'types/server';

const toDateString = (date: Date, hasTime: boolean) =>
  format(date, hasTime ? "yyyy-MM-dd'T'HH:mm:ss.000" : 'yyyy-MM-dd');

type Filter = SM.DatePeriodFilter | string | number | number[] | null;

export function convertGridFiltersToApiFilters(
  gridFilters: DataGridFilters,
): Record<string, string | SM.DatePeriodFilter | number[] | null> {
  return R.mapObjIndexed((dataGridFilter) => {
    switch (dataGridFilter?.type) {
      case 'date': {
        const { value, hasTime } = dataGridFilter;
        const filter: Filter = { type: hasTime ? 'timePeriod' : 'period' };
        if (value.period === 'equal') {
          filter.periodStart = toDateString(value.date, hasTime);
          filter.periodEnd = filter.periodStart;
        } else if (value.period === 'before') {
          filter.periodEnd = toDateString(value.date, hasTime);
        } else if (value.period === 'after') {
          filter.periodStart = toDateString(value.date, hasTime);
        } else if (value.period === 'between') {
          filter.periodStart = toDateString(value.date[0], hasTime);
          filter.periodEnd = toDateString(value.date[1], hasTime);
        }
        return filter;
      }
      case 'text':
      case 'multiselect':
        return dataGridFilter.value;
      default:
        return null;
    }
  }, gridFilters);
}
