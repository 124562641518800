import HT from 'handsontable';
import { isAfter, isMatch, parseISO } from 'date-fns';

import { DATE_FORMAT } from '../constants';

export function dateValidator(...args: Parameters<typeof HT.validators.DateValidator>): void {
  const [value, resolve] = args;
  const hot: HT = this.instance;

  const { columns, dateFormat = DATE_FORMAT } = hot.getSettings();
  const dateFnsFormat = convertToDateFnsFormat(dateFormat);
  const column = Array.isArray(columns) ? columns[this.col] : columns?.(this.col);
  const row = hot.toVisualRow(this.row);

  const date = String(value);
  const isValid = isMatch(date, dateFnsFormat);

  if (column?.data !== 'dateStart' && column?.data !== 'dateEnd') {
    resolve(isValid);
    return;
  }

  const relatedColName = column.data === 'dateStart' ? 'dateEnd' : 'dateStart';
  const relatedDate = String(hot.getDataAtRowProp(row, relatedColName));
  const [dateStart, dateEnd] = column.data === 'dateStart' ? [date, relatedDate] : [relatedDate, date];

  const isRangeValid = !isAfter(parseISO(dateStart), parseISO(dateEnd));

  resolve(isValid && isRangeValid);
}

function convertToDateFnsFormat(dateFormat: string) {
  return dateFormat.replace(/[D|Y]/g, (match) => match.toLowerCase());
}
