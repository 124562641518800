import React, { FC, useCallback } from 'react';
import cx from 'classnames';
import { Dialog } from 'primereact/dialog';

import { LocalizedMessage } from 'shared/components/other';
import { FileUploader } from 'shared/components/common/form/FileUploader';
import { Select } from 'shared/components/common/form/Select';
import { SaveButtons } from 'shared/components/common/buttons/SaveButtons';
import { MediaplanData } from 'types/campaign';

import classes from './AutoCreateModal.module.scss';

type Props = {
  isVisible: boolean;
  mediaplanData: MediaplanData;
  onAccept(): void;
  onCancel(): void;
  onMediafileUpload(formData: FormData, mediaplanName: string): void;
  onSheetValueChange(sheetValue: number): void;
};

const AutoCreateModal: FC<Props> = (props) => {
  const { isVisible, mediaplanData, onAccept, onCancel, onMediafileUpload, onSheetValueChange } = props;
  const { name, sheetValue, sheetOptions } = mediaplanData;

  const renderHeader = useCallback(
    () => (
      <h3 className={classes.title}>
        <LocalizedMessage id="campaign-edit.auto-creating.upload-web-mediaplan" />
      </h3>
    ),
    [],
  );

  const renderFooter = useCallback(
    () => <SaveButtons className={classes.footer} onCancel={onCancel} onSave={onAccept} />,
    [onAccept, onCancel],
  );

  return (
    <Dialog
      visible={isVisible}
      className={classes.root}
      draggable={false}
      closable={false}
      header={renderHeader}
      footer={renderFooter}
      onHide={onCancel}
    >
      <LocalizedMessage id="file-uploader.select-file">
        {(label: string) => (
          <FileUploader label={name ?? label} size="small" onUpload={onMediafileUpload} onCancelUpload={null} />
        )}
      </LocalizedMessage>
      <label htmlFor="campaignSheetValue" className={cx(classes.label, { [classes.labelDisabled]: !name })}>
        <LocalizedMessage id="campaign-edit.auto-creating.select-sheet" />
      </label>
      <Select
        inputId="campaignSheetValue"
        options={sheetOptions}
        value={sheetValue}
        onChange={onSheetValueChange}
        disabled={!sheetOptions.length}
        appendTo={document.body}
      />
    </Dialog>
  );
};

export { AutoCreateModal };
