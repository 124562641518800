import React, { PureComponent } from 'react';
import cx from 'classnames';

import { LocaleIdType } from 'locales';
import { localize, LocalizedMessage } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { toast } from 'shared/components/common/misc/Toast';

import classes from './LogoPreview.module.scss';

interface IProps {
  titleId: LocaleIdType;
  imageAltTextId: LocaleIdType;
  path?: string | null;
  children?: React.ReactNode;
  onChange: (file: File | null, path: string | null) => void;
  onLoad?: (image: HTMLImageElement) => void;
}

class LogoPreview extends PureComponent<IProps> {
  private logoImage: HTMLImageElement | null = null;

  private uploadLogoInput: HTMLInputElement | null = null;

  componentDidUpdate(prevProps: IProps) {
    const { path } = this.props;
    if (prevProps.path !== path && this.uploadLogoInput !== null) {
      this.uploadLogoInput.value = '';
    }
  }

  handleUploadLogoButtonClick = (): void => {
    if (this.uploadLogoInput !== null) {
      this.uploadLogoInput.click();
    }
  };

  handleUploadLogoInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.currentTarget;

    if (files === null || files.length === 0) {
      return;
    }

    const ext = files[0].name.split('.').pop() || '';

    if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
      const { onChange, onLoad } = this.props;

      onChange(files[0], URL.createObjectURL(files[0]));

      if (this.logoImage !== null && onLoad) {
        this.logoImage.addEventListener('load', this.handleLogoImageLoad);
      }
    } else {
      toast.error(localize('administration.notify.image-file-format-error'));
    }
  };

  handleLogoImageLoad = (): void => {
    const { onLoad } = this.props;

    if (this.logoImage !== null && onLoad) {
      this.logoImage.removeEventListener('load', this.handleLogoImageLoad);

      onLoad(this.logoImage);
    }
  };

  handleDeleteLogoButtonClick = (): void => {
    const { onChange } = this.props;
    onChange(null, null);

    if (this.uploadLogoInput !== null) {
      this.uploadLogoInput.value = '';
    }
  };

  setUploadLogoInputRef = (ref: HTMLInputElement): void => {
    this.uploadLogoInput = ref;
  };

  setLogoImageRef = (ref: HTMLImageElement): void => {
    this.logoImage = ref;
  };

  render() {
    const { titleId, imageAltTextId, path, children } = this.props;

    return (
      <div className={classes.Layout}>
        <div className={classes.Content}>
          <h2 className={classes.SubTitle}>
            <LocalizedMessage id={titleId} />
          </h2>
          <div className={classes.LogoEditor}>
            <Button
              className={classes.UploadButton}
              theme="light"
              onClick={this.handleUploadLogoButtonClick}
              data-test="upload-logo-button"
            >
              <i className={cx(classes.UploadLogoIcon, 'icon icon-download')} />
              <LocalizedMessage id="select-file" />
              <input
                className={classes.UploadLogoInput}
                type="file"
                accept="image/*"
                onChange={this.handleUploadLogoInputChange}
                ref={this.setUploadLogoInputRef}
              />
            </Button>

            <Button
              theme="light"
              textColor="var(--color-red)"
              onClick={this.handleDeleteLogoButtonClick}
              data-test="delete-logo-button"
            >
              <LocalizedMessage id="button.remove" />
            </Button>
          </div>

          {children}
        </div>

        <div className={classes.Preview}>
          <h2 className={classes.SubTitle}>
            <LocalizedMessage id="administration.preview" />
          </h2>
          <div className={classes.LogoContainer}>
            <LocalizedMessage id={imageAltTextId}>
              {(logoText: string) => (
                <img
                  src={path || undefined}
                  crossOrigin="anonymous"
                  className={cx(classes.LogoImage, {
                    [classes.LogoImageHidden]: path === null,
                  })}
                  ref={this.setLogoImageRef}
                  alt={logoText}
                />
              )}
            </LocalizedMessage>
            {path === null && <LocalizedMessage id="administration.logo-not-found" />}
          </div>
        </div>
      </div>
    );
  }
}

export default LogoPreview;
