import React from 'react';
import { DataGridFilters, DataGridSorting } from '../types';

export type ContextValue = {
  toggleRowSelection(rowId: string): void;
  toggleRowExpanding(rowId: string): void;
  isModalVisible: boolean;
  modalHeader: React.ReactNode;
  modalContent: React.ReactNode;
  openModal(content: React.ReactNode, header?: React.ReactNode): void;
  closeModal(): void;
  filters: DataGridFilters;
  setFilters(filterValue: DataGridFilters): void;
  clearFilters(): void;
  sorting: DataGridSorting;
  setSorting(sortingOptions: DataGridSorting): void;
  applyFilters?(onApply: () => void, target: HTMLElement): void;
};

export const defaultContextValue: ContextValue = {
  toggleRowSelection: () => null,
  toggleRowExpanding: () => null,
  isModalVisible: false,
  modalHeader: null,
  modalContent: null,
  openModal: () => null,
  closeModal: () => null,
  filters: {},
  setFilters: () => null,
  clearFilters: () => null,
  sorting: null,
  setSorting: () => null,
};

const DataGridContext = React.createContext<ContextValue>(defaultContextValue);

export { DataGridContext };
