import { Field, FieldDescription, ISavedServerField } from 'types/powerBI';
import { createInitialField } from './fields';

export function convertToFields(fields: ISavedServerField[], descriptions: FieldDescription[]): Record<number, Field> {
  const defaultFields = fields.filter((f) => f.biUrlRole === null);

  return descriptions.reduce((acc, description) => {
    const saved = defaultFields.find(
      (f) => f.biUrlPage.id === description.id && f.brand === null && f.campaign === null,
    );
    const field: Field = saved
      ? {
          url: saved.url,
          isEnabled: saved.isEnabled,
          page: description.id,
          serverId: saved.id,
          isChanged: false,
        }
      : createInitialField(description.id);

    return { ...acc, [description.id]: field };
  }, {} as Record<number, Field>);
}
