import { ConfirmDialogProps } from 'primereact/confirmdialog';
import cx from 'classnames';
import { buttonClasses } from 'shared/components/common/buttons/Button';

export const defaultSettings: Omit<ConfirmDialogProps, 'accept' | 'reject'> = {
  showHeader: true,
  closeOnEscape: false,
  acceptLabel: 'Да',
  rejectLabel: 'Нет',
  acceptClassName: buttonClasses.root,
  rejectClassName: cx(buttonClasses.root, buttonClasses.lightTheme),
};
