import { SelectionState } from 'shared/components/other';

export const calculateValueFromChildren = <
  T extends {
    children?: T[] | null;
  }
>(
  tree: T[],
  key: keyof T,
): SelectionState => {
  const valueArray: Array<SelectionState> = [];

  tree.forEach((x) => {
    const value = x[key];
    if (x.children && x.children.length) {
      valueArray.push(calculateValueFromChildren(x.children, key));
    } else if (typeof value === 'boolean') {
      valueArray.push(value);
    }
  });

  if (valueArray.every((x) => x === true)) {
    return true;
  } else if (valueArray.every((x) => x === false)) {
    return false;
  }

  return 'intermediate';
};
