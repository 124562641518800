import React, { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import { SearchInput } from 'shared/components/common/form/SearchInput';
import { ClearButton } from 'shared/components/common/buttons/ClearButton';
import { DateFilter } from 'shared/components/common/form/DateFilter';
import classes from './Filters.module.scss';
import { convertToServerDatetime } from '../../../helpers';

export const Filters = observer(() => {
  const {
    adFoxLogStore: { filters, hasActiveFilters, setFilters, resetFilters },
  } = useStores();

  const applyCampaignIdOrNameFilter = (value: string | null) => setFilters({ idOrName: value });

  const applyUserNameFilter = (value: string | null) => setFilters({ userName: value });

  const handleCampaignIdOrNameFilterClear = () => applyCampaignIdOrNameFilter(null);

  const handleUserNameFilterClear = () => applyUserNameFilter(null);

  const handleDateFilterChange = (date: Date[]) => {
    setFilters({
      time:
        date.length === 2
          ? {
              type: 'period',
              periodStart: convertToServerDatetime(date[0]),
              periodEnd: convertToServerDatetime(date[1]),
            }
          : null,
    });
  };

  const datePeriod = useMemo(
    () => (filters.time ? [parseISO(filters.time.periodStart), parseISO(filters.time.periodEnd)] : null),
    [filters.time],
  );

  return (
    <div className={classes.root}>
      <SearchInput
        defaultValue={filters.idOrName ?? undefined}
        onChange={applyCampaignIdOrNameFilter}
        onClear={handleCampaignIdOrNameFilterClear}
        placeholder="Название/ID"
      />
      <SearchInput
        defaultValue={filters.userName ?? undefined}
        onChange={applyUserNameFilter}
        onClear={handleUserNameFilterClear}
        placeholder="Пользователь"
      />
      <DateFilter filterValue={datePeriod} onChange={handleDateFilterChange} />
      <ClearButton disabled={!hasActiveFilters} onClick={resetFilters} />
    </div>
  );
});
