import React from 'react';
import { usePaginator } from '../../Paginator.provider';
import { PageLinkButton, PageLinkButtonProps } from '../../components/PageLinkButton';

type Props = PageLinkButtonProps;

export const LastPageLink: React.VFC<Props> = (props) => {
  const { options } = usePaginator();
  return (
    <PageLinkButton
      {...options.lastPageLink}
      {...props}
      data-test="last-page-button"
      iconClassName="pi icon-arrow-to-last"
    />
  );
};

export type { Props as LastPageLinkProps };
