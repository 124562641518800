import React from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { parseISO } from 'date-fns';
import { ColumnEditorOptions, ColumnEditorType } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'shared/components/common/form/Calendar';
import classes from './Table.module.scss';

const date: Editor = (options, table) => {
  const value = toDate(options.value);
  const maxDate = options.field === 'clipDateStart' ? toDate(options.rowData.clipDateEnd) : parseISO('2100-12-31');
  const minDate = options.field === 'clipDateStart' ? parseISO('1950-01-01') : toDate(options.rowData.clipDateStart);
  const viewDate = value ?? (options.field === 'clipDateStart' ? maxDate : minDate);
  const invalid = options.rowData.invalidFields?.includes('date') && !value;
  return (
    <Calendar
      inputClassName={cx(classes.inputText, { [classes.invalid]: invalid })}
      inputId={options.field + options.rowIndex}
      maxDate={maxDate}
      minDate={minDate}
      onChange={(val) => options.editorCallback?.(val)}
      onHide={() => table?.closeEditingCell()}
      value={value ?? null}
      viewDate={viewDate}
    />
  );
};

const text: Editor = (options) => {
  const invalid = options.rowData.invalidFields?.includes(options.field);
  return (
    <InputText
      className={cx(classes.inputText, { [classes.invalid]: invalid })}
      onChange={(e) => options.editorCallback?.(e.target.value)}
      type="text"
      value={options.value}
    />
  );
};

function toDate(value?: string | Date | null) {
  if (!value) return undefined;
  return value instanceof Date ? value : new Date(value);
}

export function getEditor(
  value: EditorName | Editor | undefined,
  readOnly: boolean,
  tableRef: React.RefObject<DataTable>,
): ColumnEditorType {
  if (!value || readOnly) return undefined;
  if (typeof value === 'string' && R.keys(editors).includes(value)) {
    return (options: ColumnEditorOptions) => editors[value](options, tableRef.current) as Editor;
  }
  if (typeof value === 'function') {
    return (options: ColumnEditorOptions) => value(options, tableRef.current) as Editor;
  }
  return undefined;
}

export const editors = {
  date,
  text,
} as const;

export type Editor = (options: ColumnEditorOptions, table: DataTable | null) => React.ReactNode;

export type EditorName = keyof typeof editors;
