import React, { useMemo, useRef } from 'react';
import { useMount } from 'ahooks';
import cx from 'classnames';
import { Paginator as PrimePaginator, PaginatorProps, PaginatorTemplate } from 'primereact/paginator';
import { usePaginator, Options } from '../../Paginator.provider';
import classes from './Paginatoin.module.scss';

type Props = PaginatorProps;

export const Pagination: React.VFC<Props> = React.memo((props) => {
  const { className, children, ...restProps } = props;
  const paginator = usePaginator();
  const contextRef = useRef<Options>({} as Options);

  useMount(() => {
    paginator.setOptions(contextRef.current);
  });

  const template: PaginatorTemplate = useMemo(() => {
    const elementTemplate = (name: keyof Options, opts: never) => {
      contextRef.current[name] = opts;
      return null;
    };
    return {
      layout: '',
      RowsPerPageDropdown: elementTemplate.bind(null, 'rowsPerPageDropdown'),
      FirstPageLink: elementTemplate.bind(null, 'firstPageLink'),
      PrevPageLink: elementTemplate.bind(null, 'prevPageLink'),
      CurrentPageReport: elementTemplate.bind(null, 'currentPageReport'),
      NextPageLink: elementTemplate.bind(null, 'nextPageLink'),
      LastPageLink: elementTemplate.bind(null, 'lastPageLink'),
      PageLinks: elementTemplate.bind(null, 'pageLinks'),
      JumpToPageInput: elementTemplate.bind(null, 'jumpToPageInput'),
    };
  }, []);

  return <PrimePaginator className={cx(classes.root, className)} template={template} {...restProps} />;
});

export type { Props as PaginationProps };
