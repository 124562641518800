import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { localizeMessage } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { IAdvertiser } from 'types/advertiser';
import { ServicesStore } from './Services.store';

export class AdvertiserStore extends ServicesStore {
  @observable
  private _advertisers: IAdvertiser[] = [];

  @observable
  private _logoUrls: Record<string, string> = {};

  @observable
  private advertiserId: number | null = localStorage.getItem('advertiserId')
    ? Number(localStorage.getItem('advertiserId'))
    : null;

  @observable
  private _isLoading = false;

  @computed
  get currentAdvertiserId(): number | null {
    return this.advertiserId;
  }

  @computed
  get advertisers(): IAdvertiser[] {
    return this._advertisers;
  }

  @computed
  get advertiser(): IAdvertiser | null {
    return this.advertisers.find(({ id }) => id === this.advertiserId) ?? null;
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  getAdvertiserLogoUrl = (fileId: string | null): string | null => {
    if (!fileId) return null;
    return this._logoUrls[fileId] ?? null;
  };

  @action
  selectAdvertiser = (id: number, showNotification = false): void => {
    this.advertiserId = id;
    try {
      localStorage.setItem('advertiserId', String(id));
      if (showNotification && this.advertiser) {
        toast.success(localizeMessage({ id: 'messages.advertiser-changed' }, { advertiserName: this.advertiser.name }));
      }
    } catch {
      console.error('failed set to local storage');
    }
  };

  @action
  resetAdvertiser = (): void => {
    this.advertiserId = null;
  };

  loadAdvertisers = async (): Promise<void> => {
    this._isLoading = true;
    const response = await this.services.api.advertisers.list();
    if (response.error) return;

    await this.loadLogos(response.data.content.map((x) => x.logoFileId).filter(Boolean) as string[]);

    runInAction(() => {
      const sorter = (a: IAdvertiser, b: IAdvertiser) => a.name.localeCompare(b.name, 'en');
      this.setAdvertisers(response.data.content.sort(sorter));
      this._isLoading = false;
    });
  };

  @action
  resetAdvertisers = (): void => {
    this._advertisers = [];
  };

  @action
  updateAdvertiser = async (advertiser: IAdvertiser): Promise<void> => {
    if (advertiser.logoFileId) {
      await this.loadLogos([advertiser.logoFileId]);
    }
    this._advertisers = this._advertisers.map((x) => (x.id === advertiser.id ? advertiser : x));
  };

  hasAdvertiser = (id: number): boolean => {
    return this.advertisers.some((x) => x.id === id);
  };

  @action
  private setAdvertisers = (advertisers: IAdvertiser[]): void => {
    this._advertisers = advertisers;
  };

  private loadLogos = async (fileIds: string[]): Promise<void> => {
    const responses = await Promise.all(
      fileIds.map((fileId) => this.services.api.file.logo({ fileId }, { withErrorNotification: false })),
    );
    runInAction(() => {
      responses.forEach((res, index) => {
        if (!res.error) {
          const blob = new Blob([res.data]);
          this._logoUrls[fileIds[index]] = URL.createObjectURL(blob);
        }
      });
    });
  };

  constructor() {
    super();
    makeObservable(this);
  }
}
