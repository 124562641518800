import React from 'react';
import cx from 'classnames';
import classes from './FilterRow.module.scss';
import { FilterCell } from '../../cells';
import { Column } from '../../../types';

type Props<DR> = {
  className?: string;
  gridColumns: Column<DR>[];
};

function FilterRowComponent<DR>(props: Props<DR>): React.ReactElement {
  const { className, gridColumns } = props;

  return (
    <div className={cx(classes.root, className)}>
      {gridColumns.map((column, i) => (
        <FilterCell key={i} columnName={column.name} columnKey={column.key} filter={column.filter} />
      ))}
    </div>
  );
}

export const FilterRow = React.memo(FilterRowComponent) as typeof FilterRowComponent;
