export function makeSelectedItemsLabel(
  options: Array<{ label?: string; value?: unknown }>,
  value?: string | string[] | number | number[],
): string {
  return options
    .filter((option) => {
      if (!Array.isArray(value)) {
        return value === option.value;
      } else if (isStringArray(value) && typeof option.value === 'string') {
        return value.includes(option.value);
      } else if (isNumberArray(value) && typeof option.value === 'number') {
        return value.includes(option.value);
      }

      return false;
    })
    .map((x) => x.label)
    .join('; ');
}

function isStringArray(arr: unknown[]): arr is string[] {
  return arr.every((x) => typeof x === 'string');
}

function isNumberArray(arr: unknown[]): arr is number[] {
  return arr.every((x) => typeof x === 'number');
}
