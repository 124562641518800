import React, { useCallback } from 'react';
import cx from 'classnames';
import { SelectButton, SelectButtonChangeParams } from 'primereact/selectbutton';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import classes from './EditableListSelector.module.scss';

type Option = {
  id: number;
  name: string;
};

type Props = {
  className?: string;
  options: Option[];
  onRemove?(): void;
  onRename?(name: string): void;
  onSelect(id: number): void;
  testPrefix?: string;
  value?: number;
};

export const EditableListSelector: React.FC<Props> = React.memo((props) => {
  const { className, options, testPrefix, onSelect, onRename, onRemove, value } = props;

  const makeTestId = useCallback(
    (type: string, id: number) => {
      return testPrefix ? `${type}-${testPrefix}-button-${id}` : `rename-button-${id}`;
    },
    [testPrefix],
  );

  const optionTemplate: (x: Option) => React.ReactNode = useCallback(
    ({ id, name }) => (
      <div className={classes.option}>
        <span className={classes.name} data-pr-tooltip={name} data-global-tooltip>
          {name}
        </span>
        {onRename && (
          <TextButton
            className={classes.button}
            icon="icon icon-edit"
            onClick={() => onRename(name)}
            data-test={makeTestId('rename', id)}
          />
        )}
        {onRemove && (
          <TextButton
            className={classes.button}
            icon="icon icon-delete"
            iconColor="var(--color-red)"
            onClick={onRemove}
            data-test={makeTestId('remove', id)}
          />
        )}
      </div>
    ),
    [makeTestId, onRemove, onRename],
  );

  const handleChange = useCallback((e: SelectButtonChangeParams) => onSelect(e.value), [onSelect]);

  return (
    <div className={cx(classes.root, className)}>
      <SelectButton
        className={classes.options}
        itemTemplate={optionTemplate}
        onChange={handleChange}
        optionValue="id"
        options={options}
        unselectable={false}
        value={value}
      />
    </div>
  );
});

export type { Props as EditableListSelectorProps, Option as EditableListSelectorOption };
