import React, { VFC } from 'react';
import cx from 'classnames';
import { Button, ButtonProps } from '../Button';

type Props = ButtonProps;

export const ClearButton: VFC<Props> = (props) => {
  const { className, icon = 'pi pi-filter-slash', tooltip = 'Сбросить все фильтры', ...restProps } = props;

  return (
    <Button
      className={cx('p-button-rounded p-button-danger p-button-icon-only', className)}
      icon={icon}
      tooltip={tooltip}
      {...restProps}
    />
  );
};

export type { Props as ClearButtonProps };
