import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';

export class SidebarStore {
  @observable
  private _isVisible = false;

  @observable
  private _content: React.ReactNode = null;

  @observable
  private _onShow?: () => void;

  @observable
  private _onHide?: () => void;

  constructor() {
    makeObservable(this);
  }

  @computed
  get isVisible(): boolean {
    return this._isVisible;
  }

  @computed
  get content(): React.ReactNode {
    return this._content;
  }

  @computed
  get onShow(): (() => void) | undefined {
    return this._onShow;
  }

  @computed
  get onHide(): (() => void) | undefined {
    return this._onHide;
  }

  @action
  show = (args: ShowArgs): void => {
    this._isVisible = true;
    this._content = args.content;
    this._onShow = args.onShow;
    this._onHide = args.onHide;
  };

  @action
  hide = (): void => {
    if (!this._isVisible) return;
    this.onHide?.();
    this._isVisible = false;
  };
}

type ShowArgs = {
  content: React.ReactNode;
  onShow?: () => void;
  onHide?: () => void;
};
