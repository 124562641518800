import { action, observable, reaction, makeObservable, runInAction, computed } from 'mobx';
import { cloneDeep, omitBy } from 'lodash';
import HT from 'handsontable';
import HotTable from '@handsontable/react';

import { isErrorWithUserMessage } from 'api/helpers';
import { BaseStore } from 'shared/stores';
import { localize, ReactHotTable } from 'shared/components/other';
import { IError } from 'types';
import { ClientRow, ServerRow, AdfoxRowInfo, AdfoxFilter, RowStatus, RowStatuses } from 'types/tradingDesk/adfox';
import { DictionaryValue, Dictionary } from 'types/dictionary';
import handleError from 'helpers/handleError';
import showConfirmation from 'helpers/showConfirmation';
import { isNotNullable } from 'helpers/utils';
import { toast } from 'shared/components/common/misc/Toast';

import {
  convertToClientRow,
  convertServerDataToColumns,
  isRowChanged,
  getRowStatusErrors,
  areValueExceeded,
  ADFOX_CABINET_ID,
  ERROR_DICTIONARY_ID,
} from '../helpers';

export abstract class AdFoxStore extends BaseStore {
  protected abstract get _requiredFilters(): AdfoxFilter;

  protected abstract _dataType: 'campaigns' | 'banners';

  protected abstract _pageId: string;

  protected abstract getLoadedRowsData: () => Promise<ServerRow[] | null>;

  protected abstract getRefreshedRowsData: (rows: AdfoxRowInfo[]) => Promise<RowStatus[] | null>;

  protected abstract getUpdatedRowsData: (rows: AdfoxRowInfo[]) => Promise<RowStatuses | IError | null>;

  protected abstract filterRowsBeforeSaving: (rows: AdfoxRowInfo[]) => AdfoxRowInfo[];

  @observable
  protected _columns: ReactHotTable.Column[] = [];

  @observable
  protected _filters: Partial<AdfoxFilter> = {
    status: 0,
  };

  @observable
  protected _defaultRows: ClientRow[] = [];

  @observable
  private _rows: ClientRow[] = [];

  @observable
  private _rowStatusesDictionary: DictionaryValue[] = [];

  @observable
  private _prevFilters: Partial<AdfoxFilter> = this._filters;

  private _dictionaries: Dictionary[] = [];

  private _hot: HT | null = null;

  constructor() {
    super();
    makeObservable(this);
    reaction(() => this._filters, this.applyFilters);
  }

  @computed
  get filters(): AdfoxFilter {
    return { ...this._filters, ...this._requiredFilters };
  }

  @computed
  get isTableChanged(): boolean {
    return this._rows.some((row) => isRowChanged(row, this._defaultRows));
  }

  @computed
  private get _isTablePartialSelected(): boolean {
    return this._rows.some((row) => row.isSelected) && !this._rows.every((row) => row.isSelected);
  }

  @computed
  private get _activeRows(): AdfoxRowInfo[] {
    return this._rows
      .map((row, rowIndex) => (!this._isTablePartialSelected || row.isSelected ? { rowIndex, row } : null))
      .filter(isNotNullable);
  }

  @computed
  private get _numericColumns(): ReactHotTable.Column[] {
    return this._columns.filter((column) => column.type === 'INTEGER');
  }

  @computed
  private get _errorDictionary(): DictionaryValue[] {
    return this._dictionaries.find((dict) => dict.dictionaryId === ERROR_DICTIONARY_ID)?.dictionaryValues ?? [];
  }

  get defaultRows(): ClientRow[] {
    return this._defaultRows;
  }

  get rows(): ClientRow[] {
    return this._rows;
  }

  get columns(): ReactHotTable.Column[] {
    return this._columns;
  }

  get rowStatusesDictionary(): DictionaryValue[] {
    return this._rowStatusesDictionary;
  }

  get dictionaries(): Dictionary[] {
    return this._dictionaries;
  }

  loadData = async (): Promise<void> => {
    const API = this.services.api;
    const [rows, columnsMetadata, attributes, metrics, dictionaries] = await Promise.all([
      this.getLoadedRowsData(),
      API.dictionaries.getColumnsMetadata({ pageId: this._pageId }),
      API.dictionaries.getAttributes({ cabinetId: ADFOX_CABINET_ID }),
      API.dictionaries.getMetrics({ cabinetId: ADFOX_CABINET_ID }),
      API.dictionaries.getDictionaries({ cabinetId: ADFOX_CABINET_ID }),
    ]);
    if (rows === null || columnsMetadata.error || attributes.error || metrics.error || dictionaries.error) {
      return;
    }

    runInAction(() => {
      const convertedColumns = convertServerDataToColumns(
        columnsMetadata.data,
        attributes.data,
        metrics.data,
        dictionaries.data,
        this._dataType,
      );
      const convertedRows = rows.map((row) => convertToClientRow(row, convertedColumns));
      this._defaultRows = convertedRows;
      this._rows = cloneDeep(convertedRows);
      this._columns = convertedColumns;
      this._dictionaries = dictionaries.data;

      const statusesColumn = convertedColumns.find((column) => column.name === 'status');
      if (ReactHotTable.isDictionaryColumn(statusesColumn)) {
        this._rowStatusesDictionary = statusesColumn.source;
      }
    });
  };

  @action
  setFilters = (filters: Partial<AdfoxFilter>): void => {
    this._filters = omitBy({ ...this._filters, ...filters }, (x) => x == null);
  };

  @action
  clearAllFilters = (): void => {
    this._filters = {};
  };

  @action
  initTable = (hot: HotTable | null): void => {
    if (!hot) return;
    this._hot = hot.hotInstance;
  };

  @action
  updateRows = (rows: ClientRow[]): void => {
    this._rows = rows;
  };

  resetTableContent = (): void => {
    const rows = this._rows.map((row, rowIndex) =>
      this._activeRows.find((x) => x.row.id === row.id) ? this.resetRowContent(row, rowIndex) : row,
    );
    this._hot?.updateData(rows);
  };

  refreshRows = async (): Promise<void> => {
    const hasSelectedRows = this._activeRows.some(({ row }) => row.isSelected);
    if (hasSelectedRows && !(await this.isPartialRowsSelectedConfirmed())) return;

    const hasChangedRows = this._activeRows.some(({ row }) => isRowChanged(row, this._defaultRows));
    if (hasChangedRows && !(await this.isUnsavedChangesConfirmed())) return;

    if (hasSelectedRows) {
      this.partiallyRefresh(this._activeRows);
    } else {
      this.fullyRefresh();
    }
  };

  saveRows = async (): Promise<void> => {
    const activeChangedRows = this._activeRows.filter(({ row }) => isRowChanged(row, this._defaultRows));
    if (!activeChangedRows.length) return;

    const areRowsValid = this.validateTable(activeChangedRows);
    if (!areRowsValid) {
      toast.error(localize('adfox.saved-rows-contain-errors'));

      return;
    }

    if (this._isTablePartialSelected && !(await this.isPartialRowsSelectedConfirmed())) return;

    const areLimitsExceeded = this.areLimitsExceeded(activeChangedRows);
    if (areLimitsExceeded && !(await this.areLimitsExceededConfirmed())) return;

    await this.saveChanges(activeChangedRows);
  };

  private saveChanges = async (rows: AdfoxRowInfo[]) => {
    try {
      const filteredRows = this.filterRowsBeforeSaving(rows);
      if (!filteredRows.length) return;

      const response = await this.getUpdatedRowsData(filteredRows);
      if (response === null) return;

      if (isErrorWithUserMessage(response)) {
        const error: IError = new Error(localize('adfox.changes-failure-applied'));
        error.userErrorMessage = response.userErrorMessage;
        error.userErrorMessageList = response.userErrorMessageList;
        throw error;
      }

      const { statuses } = response;
      const hasErrors = statuses.some((status) => !status.success);
      if (hasErrors) {
        toast.error(localize('adfox.changes-failure-applied'));
      } else {
        toast.success(localize('adfox.changes-successfully-applied'));
      }

      runInAction(() => {
        const rowsWithStatus = this.getRowsWithStatus(
          filteredRows.map(({ row }) => row),
          statuses,
        );
        this._rows = this._rows.map((row) => rowsWithStatus.find((r) => r.id === row.id) ?? row);
        this._defaultRows = this._defaultRows.map(
          (defaultRow) => rowsWithStatus.find((r) => r.id === defaultRow.id && !r.errors.length) ?? defaultRow,
        );
      });
    } catch (error) {
      handleError(error);
    }
  };

  private getRowsWithStatus = (rows: ClientRow[], statuses: RowStatus[]) =>
    rows.map((row) => {
      const status = statuses.find((s) => s.id === row.id);

      return {
        ...row,
        errors: !status || status.success ? [] : getRowStatusErrors(status, this._errorDictionary),
      };
    });

  private validateTable = (rows: AdfoxRowInfo[]): boolean => {
    if (!this._hot) return false;
    let areRowsValid = true;

    rows.forEach(({ rowIndex }) => {
      const rowMeta = this._hot?.getCellMetaAtRow(rowIndex);
      if (rowMeta?.some((cell) => !cell.valid && !cell.readOnly)) {
        areRowsValid = false;
      }
    });

    return areRowsValid;
  };

  private areLimitsExceeded = (rows: AdfoxRowInfo[]): boolean => {
    return rows.some(({ row }) => {
      return this._numericColumns.some((c) => {
        const defaultRow = this._defaultRows.find((r) => r.id === row.id);
        const cell = row[c.name as keyof ClientRow];
        const defaultCell = defaultRow?.[c.name as keyof ClientRow];

        return defaultCell && areValueExceeded(cell, defaultCell);
      });
    });
  };

  private partiallyRefresh = async (rows: AdfoxRowInfo[]): Promise<void> => {
    const statuses = await this.getRefreshedRowsData(rows);
    if (statuses === null) return;

    const loadedRows = await this.getLoadedRowsData();
    if (loadedRows === null) return;

    const convertedRows = loadedRows
      .filter((r) => rows.find(({ row }) => row.id === Number(r.id)))
      .map((r) => {
        const isSelected = rows.find(({ row }) => row.id === Number(r.id))?.row.isSelected ?? false;

        return { ...convertToClientRow(r, this._columns), isSelected };
      });

    runInAction(() => {
      const rowsWithStatus = this.getRowsWithStatus(convertedRows, statuses);
      this._rows = this._rows.map((row) => rowsWithStatus.find((r) => r.id === row.id) ?? row);
      this._defaultRows = this._defaultRows.map((defaultRow) => {
        const updatedRow = rowsWithStatus.find((r) => r.id === defaultRow.id);

        return updatedRow ? { ...updatedRow, errors: [] } : defaultRow;
      });
    });
  };

  private fullyRefresh = async () => {
    const statuses = await this.getRefreshedRowsData([]);
    if (statuses === null) return;

    const loadedRows = await this.getLoadedRowsData();
    if (loadedRows === null) return;

    const rows = loadedRows.map((r) => convertToClientRow(r, this._columns));

    runInAction(() => {
      const updatedRows = this.getRowsWithStatus(rows, statuses);
      this._rows = updatedRows;
      this._defaultRows = updatedRows.map((r) => ({ ...r, errors: [] }));
    });
  };

  private applyFilters = async () => {
    if (this._filters === this._prevFilters) return;
    if (!this.isTableChanged) {
      this.loadRows();
      this._prevFilters = cloneDeep(this._filters);
      return;
    }
    this.deps.confirmDialogStore.show({
      accept: () => {
        this._prevFilters = cloneDeep(this._filters);
        this.loadRows();
      },
      reject: () => {
        this._filters = this._prevFilters;
      },
      header: `${localize('attention')}!`,
      message: `${localize('adfox.unsaved-changes-will-be-lost')}`,
      icon: 'pi pi-exclamation-triangle',
    });
  };

  private loadRows = async (): Promise<void> => {
    const loadedRows = await this.getLoadedRowsData();
    if (loadedRows === null) return;

    const rows = loadedRows.map((row) => convertToClientRow(row, this._columns));
    runInAction(() => {
      this._defaultRows = rows;
      this._rows = cloneDeep(rows);
    });
  };

  private resetRowContent = (row: ClientRow, rowIndex: number): ClientRow => ({
    ...this._defaultRows[rowIndex],
    isSelected: row.isSelected,
    errors: [],
  });

  private isPartialRowsSelectedConfirmed = async () =>
    showConfirmation({
      title: `${localize('attention')}! ${localize('adfox.operation-will-be-applied-only-to-selected-rows')}`,
    });

  private isUnsavedChangesConfirmed = async () =>
    showConfirmation({
      title: `${localize('attention')}! ${localize('adfox.unsaved-changes-will-be-lost')}`,
    });

  private areLimitsExceededConfirmed = async () =>
    showConfirmation({
      title: `${localize('attention')}! ${localize('adfox.limits-changed-more-than-10-times')}`,
    });
}
