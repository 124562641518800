import { ServerModels as SM } from 'types/server';
import { DataRow } from 'types/programmatic/excess';
import { isNotNull } from 'helpers/isNotNull';
import { convertExcessCabinetPositionToDataRow } from './convertExcessCabinetPositionToDataRow';
import { roundCurrency } from '../helpers';

type Args = {
  row: SM.ExcessMediaplanRow;
  getExcessCauseById: (id: number) => string | null;
};

export function convertExcessMediaplanRowToDataRow({ row, getExcessCauseById }: Args): DataRow {
  return {
    id: String(row.csId),
    active: row.active ?? null,
    total: row.total ?? null,
    rowNumber: row.rowNumber ?? null,
    mediaplanId: row.mediaplanId ?? null,
    csId: row.csId ?? null,
    checkStatus: row.checkStatus ?? null,
    excessCauses: row.causeIdList?.map(getExcessCauseById).filter(isNotNull) ?? [],
    cabinetStatus: row.cabinetStatus ?? null,
    startDate: row.startDate ?? null,
    endDate: row.endDate ?? null,
    planBudget: roundCurrency(row.planBudget ?? null),
    factBudget: roundCurrency(row.factBudget ?? null),
    factBudgetLimit: roundCurrency(row.factBudgetLimit ?? null),
    budgetPct: row.budgetPct ?? null,
    budgetPctToday: row.budgetPctToday ?? null,
    unitPrice: row.unitPrice ?? null,
    factUnitPrice: row.factUnitPrice ?? null,
    unitPricePct: row.unitPricePct ?? null,
    sellerOrStatisticsSource: row.seller ?? null,
    format: row.format ?? null,
    children: row?.positions?.map((x) => convertExcessCabinetPositionToDataRow({ row: x, getExcessCauseById })) ?? null,
    settingsStatus: row?.settingsStatus ?? null,
  };
}
