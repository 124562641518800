import React, { useCallback } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { Select } from 'shared/components/common/form/Select';
import { IOption } from 'types';
import classes from './AudienceSelector.module.scss';

type Props = {
  audience: IOption | null;
  campaignId: number;
  className?: string;
  onChange(value: IOption): void;
  options: IOption[];
};

export const AudienceSelector: React.VFC<Props> = (props) => {
  const { audience, campaignId, className, onChange, options } = props;

  const handleChange = useCallback(
    (value: number, option?: IOption) => {
      if (option) {
        onChange(option);
      }
    },
    [onChange],
  );

  return (
    <label className={cx(classes.root, className)}>
      <span className={classes.label}>{localize('campaign-view.mediaplan.select-audience')}</span>
      <Select
        className={classes.select}
        inputId={`audience_${campaignId}`}
        onChange={handleChange}
        options={options}
        size="large"
        tabular
        theme="grey"
        value={audience?.value}
      />
    </label>
  );
};

export type { Props as AudienceSelectorProps };
