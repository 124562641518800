import React from 'react';
import cx from 'classnames';
import { ToggleButton, ToggleButtonChangeParams } from 'primereact/togglebutton';
import classes from './FavoritesButton.module.scss';

type Props = {
  /** Additional className */
  className?: string;
  /** Specifies the on/off state of the button. */
  checked: boolean;
  /** Callback to invoke on value change. */
  onChange?(): void;
  /** Size of the component. Default: 'medium' */
  size?: 'medium' | 'large';
};

export const FavoritesButton: React.FC<Props> = (props) => {
  const { checked, size = 'medium', onChange, className } = props;

  const handleChange = async (e: ToggleButtonChangeParams) => {
    e.originalEvent.preventDefault();
    onChange?.();
  };

  return (
    <ToggleButton
      className={cx(classes.root, className, {
        [classes[`${size}Size`]]: !!size,
        [classes.checked]: checked,
      })}
      onIcon="icon icon-star"
      offIcon="icon icon-star"
      onLabel=""
      offLabel=""
      checked={checked}
      onChange={handleChange}
    />
  );
};

export type { Props as FavoritesButtonProps };
