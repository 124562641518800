import React, { FC } from 'react';
import cx from 'classnames';
import { Button } from 'shared/components/common/buttons/Button';

import classes from '../../Brands.module.scss';

interface IProps {
  years: number[];
  activeYear: number;
  onYearChange: (year: number) => void;
}

export const YearSelector: FC<IProps> = (props: IProps) => {
  const { years, onYearChange, activeYear } = props;

  return (
    <div className={classes.YearSelector}>
      {years.map((year, index) => {
        const isActive = year === activeYear;

        return (
          <div key={year} className={classes.ButtonWrapper}>
            <Button
              key={index}
              theme="transparent"
              className={cx(classes.YearButton, { [classes.YearButtonActive]: isActive })}
              onClick={() => onYearChange(year)}
              data-test={`year-button-${year}`}
            >
              {year}
            </Button>
          </div>
        );
      })}
    </div>
  );
};
