import { Column } from 'shared/components/other';
import { ServerModels as SM } from 'types/server';

const campaignLogColumns: Column<SM.AdfoxLogItemView>[] = [
  {
    key: 'userCreateInfo.timeCreate',
    name: 'Дата и время',
    minWidth: 120,
    width: 150,
    resizable: true,
    type: 'datetime',
    isSortable: true,
    valueGetter: (data) => data.userCreateInfo.timeCreate,
  },
  {
    key: 'campaignName',
    name: 'Кампания',
    minWidth: 120,
    resizable: true,
    isSortable: true,
    textAlign: 'left',
  },
  {
    key: 'userName',
    name: 'Пользователь',
    minWidth: 120,
    resizable: true,
    isSortable: true,
    textAlign: 'left',
  },
  {
    key: 'fieldName',
    name: 'Измененное поле',
    minWidth: 180,
    resizable: true,
    isSortable: true,
    textAlign: 'left',
  },
  {
    key: 'oldValue',
    name: 'Исходное значение',
    minWidth: 120,
    resizable: true,
    textAlign: 'left',
  },
  {
    key: 'newValue',
    name: 'Новое значение',
    minWidth: 120,
    resizable: true,
    textAlign: 'left',
  },
];

const bannerLogColumns = [...campaignLogColumns];
bannerLogColumns.splice(2, 0, {
  key: 'bannerName',
  name: 'Баннер',
  minWidth: 120,
  resizable: true,
  isSortable: true,
  textAlign: 'left',
});

export const columns = {
  campaigns: campaignLogColumns,
  banners: bannerLogColumns,
};
