import React, { FC, useMemo } from 'react';

import { ServerModels as SM } from 'types/server';
import { IAdvertiser } from 'types/advertiser';
import { DataGrid, GridRef, Row, DataGridFilters, DataGridSorting } from 'shared/components/other';
import { getColumns } from './getColumns';
import { defaultProps } from './LogTable.defaultProps';

type Props = {
  data: Row<SM.OperationLogEntry>[];
  gridRef: GridRef;
  dataGridFilters: DataGridFilters;
  advertisers: IAdvertiser[];
  onDataGridFilterChange: (dataGridFilters: DataGridFilters) => void;
  onDataGridSortingChange: (DataGridSorting: DataGridSorting) => void;
};

const LogTable: FC<Props> = ({
  data,
  gridRef,
  dataGridFilters,
  advertisers,
  onDataGridFilterChange,
  onDataGridSortingChange,
}) => {
  const columns = useMemo(() => {
    const advertisersOptions = advertisers.map((adv) => ({
      value: adv.id,
      label: adv.name,
    }));

    return getColumns(advertisersOptions);
  }, [advertisers]);

  return (
    <DataGrid
      {...defaultProps}
      columns={columns}
      rows={data}
      rowGap={0}
      gridRef={gridRef}
      initialFilters={dataGridFilters}
      onFilterChange={onDataGridFilterChange}
      onSortingChange={onDataGridSortingChange}
      enableVirtualization={false}
    />
  );
};

export { LogTable };
