import { action, computed, makeObservable, observable } from 'mobx';

import { BaseStore } from 'shared/stores';
import { IOption } from 'types';
import { IBrand } from 'types/brand';
import getLogoSrc from 'helpers/getLogoSrc';
import { BrandViewStore } from './BrandView.store';

const currentYear = new Date().getFullYear();

const getYearOption = (year: number): IOption => ({
  value: year,
  label: String(year),
});

const getBrandOption = (brand: IBrand) => ({
  value: brand.id,
  label: brand.name,
  logo: brand?.logoFileId ? getLogoSrc(brand.logoFileId) : null,
});

type Deps = {
  brandViewStore: BrandViewStore;
};

export class BrandViewViewStore extends BaseStore<Deps> {
  @observable
  isScrollbarHidden = true;

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
  }

  @computed
  get yearPeriodOptions(): IOption[] {
    const {
      yearPeriod: { minYear, maxYear },
    } = this.deps.brandViewStore;
    const latestYear = Math.max(maxYear, currentYear + 2);
    const yearsCount = latestYear - minYear + 1;

    return Array.from(Array(yearsCount), (item: number, i: number) => latestYear - i).map(getYearOption);
  }

  @computed
  get brandOptions(): IOption[] {
    return this.deps.brandViewStore.brands.map(getBrandOption);
  }

  @action
  handleFlowChartMouseEnter = (): void => {
    this.isScrollbarHidden = false;
  };

  @action
  handleFlowChartMouseLeave = (): void => {
    this.isScrollbarHidden = true;
  };

  onChangeBrand = (brandId: number): void => {
    const { activeYear } = this.deps.brandViewStore;
    this.deps.routingStore.history.replace(`/brands/${brandId}/?year=${activeYear}`);
  };

  onChangeYear = (year: number): void => {
    const { brandId } = this.deps.brandViewStore;
    this.deps.routingStore.history.replace(`/brands/${brandId}/?year=${year}`);
  };
}
