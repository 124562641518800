import React from 'react';
import classes from './InfoItem.module.scss';

type Props = {
  title?: string;
  tooltip?: string;
  value?: string;
};

export const InfoItem: React.FC<Props> = (props) => {
  const { title, tooltip, value } = props;
  return (
    <div className={classes.root}>
      {title && <span className={classes.title}>{title}:&nbsp;</span>}
      {value && (
        <span className={classes.value} data-global-tooltip data-pr-tooltip={tooltip}>
          {value}
        </span>
      )}
    </div>
  );
};
