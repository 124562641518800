import React, { forwardRef, RefObject } from 'react';
import cx from 'classnames';

import { useStores } from 'core';
import { LocalizedMessage, LocalizedTitle } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { Footer } from 'shared/components/layout/Footer';
import { ReactComponent as SkLogo } from 'assets/sk-logo-1.svg';
import { IAuthFormValues, useAuthForm } from './helpers/validation';

import classes from './Auth.module.scss';

interface IProps {
  isAuthorized: boolean;
  isAuthorizing: boolean;
  onSubmit: (values: IAuthFormValues) => void;
}

const AuthMarkup = forwardRef<HTMLFormElement, IProps>((props: IProps, formRef) => {
  const { isAuthorized, onSubmit, isAuthorizing } = props;

  const {
    readonly,
    onFieldChange,
    onFormSubmit,
    onFieldFocus,
    values: { username, password },
    errors,
  } = useAuthForm(isAuthorizing, formRef as RefObject<HTMLFormElement>, onSubmit);

  const { authStore } = useStores();
  if (!isAuthorized && authStore.getAccessToken()?.length) {
    return null;
  }

  return (
    <div className={classes.AuthBackground}>
      <LocalizedTitle id="site.title.auth" />
      <div className={classes.FormContainer}>
        <h3 className={classes.FormTitle}>
          <LocalizedMessage id="auth.form.title" />
        </h3>
        <form ref={formRef} method="post" onSubmit={onFormSubmit} autoComplete="on">
          <div>
            <label htmlFor="mail" className={classes.FormLabel}>
              <LocalizedMessage id="auth.form.email" />
            </label>
            <div
              className={cx('icon icon-user', classes.FormInputGroup, {
                [classes.FormInputGroupEmpty]: errors.username,
              })}
            >
              <input
                type="text"
                id="mail"
                name="username"
                value={username}
                onChange={onFieldChange}
                onFocus={onFieldFocus}
                readOnly={readonly}
                className={classes.FormInput}
                data-test="mail-input"
                maxLength={1000}
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className={classes.FormLabel}>
              <LocalizedMessage id="auth.form.password" />
            </label>
            <div
              className={cx('icon icon-lock', classes.FormInputGroup, {
                [classes.FormInputGroupEmpty]: errors.password,
              })}
            >
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={onFieldChange}
                onFocus={onFieldFocus}
                autoComplete="off"
                className={classes.FormInput}
                data-test="password-input"
                maxLength={1000}
              />
            </div>
          </div>
          <div className={classes.FormForgotPasswordLink}>
            <a
              href="mailto:support@aizek.io?subject=Password%20recovery%20request"
              target="_blank"
              rel="noopener noreferrer"
              data-test="password-recovery-link"
            >
              <LocalizedMessage id="auth.form.forgot-password" />
            </a>
          </div>
          <Button type="submit" theme="light" className={classes.Button} data-test="submit-button">
            <LocalizedMessage id="auth.form.submit-btn" />
          </Button>
        </form>
      </div>
      <div className={classes.Footer}>
        <Footer color="light" />
        <a className={classes.SkLink} href="https://sk.ru/" target="_blank" rel="noopener noreferrer">
          <SkLogo className={classes.SkLogo} />
        </a>
      </div>
    </div>
  );
});

export default AuthMarkup;
