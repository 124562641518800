import Adfox from './adfox';
import HttpActions from '../../HttpActions';

class TradingDesk {
  adfox: Adfox;

  constructor(actions: HttpActions) {
    this.adfox = new Adfox(actions);
  }
}

export default TradingDesk;
