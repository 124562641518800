import React, { ChangeEvent, FC, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { SaveButtons } from 'shared/components/common/buttons/SaveButtons';
import { localize } from 'shared/components/other';
import classes from './VideoUrlUploader.module.scss';

type Props = {
  onUpload(videoTag: string): void;
  onCancelUpload(): void;
};

const VideoUrlUploader: FC<Props> = ({ onUpload, onCancelUpload }) => {
  const [videoHtml, setVideoHtml] = useState('');
  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setVideoHtml(value);
  };

  const handleUpload = () => {
    if (!videoHtml) return;
    onUpload(videoHtml);
  };

  return (
    <div className={classes.root}>
      <InputTextarea
        className={classes.textarea}
        placeholder={localize('campaign-view.creative.insert-code-to-upload-video')}
        onChange={handleInputChange}
      />
      <SaveButtons className={classes.footer} onSave={handleUpload} onCancel={onCancelUpload} />
    </div>
  );
};

export { VideoUrlUploader };
