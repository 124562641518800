import {
  BasicRole,
  AdditionalRole,
  MainRole,
  TradingDeskRole,
  ProgrammaticRole,
  ExperimentalRole,
} from 'types/profile';

const mainRolesByPriority: MainRole[] = ['superadmin', 'admin', 'user', 'external_user', 'client'];
const tradingDeskRolesByPriority: TradingDeskRole[] = ['trading_desk_user'];
const experimentalRoles: ExperimentalRole[] = ['alpha_tester'];
const programmaticRoles: ProgrammaticRole[] = [
  'programmatic_department_head',
  'programmatic_group_head',
  'programmatic_manager',
];

export const baseRolesByPriority: [BasicRole[], BasicRole[]] = [mainRolesByPriority, tradingDeskRolesByPriority];

export const baseRoles: BasicRole[] = [...mainRolesByPriority, ...tradingDeskRolesByPriority];
export const additionalRoles: AdditionalRole[] = [...experimentalRoles, ...programmaticRoles];
