import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { LocalizedMessage, Scrollbar } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { checkChanges, getWeekDayNormalized, getWeekOfYear } from 'helpers/utils';
import { Locale } from 'types';
import { ICampaign, CampaignStatus } from 'types/campaign';
import CampaignTooltip from '../CampaignTooltip/CampaignTooltip';
import classes from './FlowChart.module.scss';

type IScale = 1 | 3 | 5;
interface IProps {
  year: number;
  campaigns: ICampaign[];
  locale: Locale;
  isScrollbarHidden: boolean;
}

interface IState {
  scale: IScale;
  weekWidth: number;
  chartWidth: number;
  months: IMonthData[];
  timelines: ICampaign[][];
  isScrollingActive: boolean;
  isTooltipVisible: boolean;
  hoveredCampaign: ICampaign | null;
  tooltipPosition: { top: number; left: number };
}

interface IMonthData {
  firstDay: Date;
  weeks: number[];
  weeksBefore: number;
}

interface ITimelinesSpot {
  row: number;
  index: number;
}

interface IScrollbarBehavior {
  isBackward?: boolean;
  isSmooth?: boolean;
  numberOfWeeks?: number;
  bias?: number;
  absolute?: boolean;
}

class FlowChart extends React.PureComponent<IProps, IState> {
  private scrollbar: Scrollbar | null = null;

  private scrolledByMouse = false;

  private isButtonPressed = false;

  private timers: number[] = [];

  private preventDefault = (event: Event) => event.preventDefault();

  state: IState = {
    scale: 3,
    weekWidth: 0,
    chartWidth: 0,
    months: [],
    timelines: [],
    isScrollingActive: false,
    isTooltipVisible: false,
    hoveredCampaign: null,
    tooltipPosition: {
      top: 0,
      left: 0,
    },
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { weekWidth, months, scale } = this.state;
    const shouldLoadData = checkChanges(prevProps, this.props, ['year', 'campaigns']);

    if (shouldLoadData) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        scale: 3,
      });
    }
    if (scale !== prevState.scale) {
      this.updateScale();
    }
    if (shouldLoadData || weekWidth !== prevState.weekWidth) {
      this.loadData();
    }

    if (months !== prevState.months || prevState.scale !== scale) {
      // to center current month

      this.scrolledByMouse = false;
      this.resetScrollingToMonth();
    }
  }

  loadData = (): void => {
    const { campaigns } = this.props;

    if (campaigns.length === 0) {
      return;
    }

    const months = this.generateMonths(this.findEarliestDate(campaigns), this.findLatestDate(campaigns));

    const timelines = this.scheduleCampaigns(campaigns);

    this.setState({
      months,
      timelines,
    });
  };

  setScrollbarRef = (ref: Scrollbar): void => {
    this.scrollbar = ref;
  };

  setChartRef = (ref: HTMLDivElement): void => {
    if (ref) {
      const { scale } = this.state;
      this.setState({
        chartWidth: ref.getBoundingClientRect().width,
        weekWidth: ref.getBoundingClientRect().width / (scale * 4),
      });
    }
  };

  updateScale = (): void => {
    this.setState((s) => {
      if (s.chartWidth) {
        return {
          weekWidth: s.chartWidth / (s.scale * 4),
        };
      }

      return {
        weekWidth: s.weekWidth,
      };
    });
  };

  generateMonths = (startDate: Date, endDate: Date): IMonthData[] => {
    const date = new Date(startDate);
    const months: IMonthData[] = [];

    // Generate at least 8 months after startDate to fill full chart width
    const maxDate = Math.max(endDate.valueOf(), new Date(startDate).setMonth(startDate.getMonth() + 8).valueOf());

    while (date.valueOf() <= maxDate) {
      const lastMonthDate = new Date(date);
      lastMonthDate.setMonth(lastMonthDate.getMonth() + 1, 0);

      months.push({
        weeks: this.getMonthWeeks(date, lastMonthDate),
        firstDay: new Date(date),
        weeksBefore: months.reduce((sumWeeks, item) => sumWeeks + item.weeks.length, 0),
      });

      date.setMonth(date.getMonth() + 1, 1);
    }

    return this.removeEmptyWeeks(months, endDate);
  };

  /**
   * removeEmptyWeeks() deletes extra weeks after endDate week,
   * or after minimum week count to fit full chart width
   */
  removeEmptyWeeks = (months: IMonthData[], endDate: Date): IMonthData[] => {
    const { weekWidth, chartWidth } = this.state;
    const endDateWeek = getWeekOfYear(endDate);
    const endMonth = this.findMonthByDate(months, endDate);

    let weekCount = chartWidth / weekWidth;

    if (endMonth !== undefined) {
      const { weeks, weeksBefore } = endMonth;

      weekCount = Math.max(weeksBefore + weeks.indexOf(endDateWeek) + 1, weekCount);
    }

    if (weekCount > 0) {
      const filledMonths = months.filter((item) => item.weeksBefore < weekCount);
      const lastMonth = filledMonths[filledMonths.length - 1];
      const { weeks, weeksBefore } = lastMonth;
      lastMonth.weeks = weeks.slice(0, weekCount - weeksBefore);

      return filledMonths;
    }

    return months;
  };

  getMonthWeeks = (firstMonthDate: Date, lastMonthDate: Date): number[] => {
    const firstDayWeek = getWeekOfYear(firstMonthDate);
    const lastDayWeek = getWeekOfYear(lastMonthDate);

    return Array.from(Array(lastDayWeek - firstDayWeek + 1), (item, index) => firstDayWeek + index);
  };

  findMonthByDate = (months: IMonthData[], date: Date): IMonthData | undefined =>
    months.find(
      (item) => item.firstDay.getMonth() === date.getMonth() && item.firstDay.getFullYear() === date.getFullYear(),
    );

  findEarliestDate = (campaigns: ICampaign[]): Date => {
    const { year } = this.props;

    const earliestCampaign = campaigns.reduce((prev, current) => {
      if (prev.dateStart < current.dateStart) {
        return prev;
      }

      return current;
    });

    const earliestDate = new Date(earliestCampaign.dateStart);
    const janFirst = new Date(year, 0, 1);

    return new Date(Math.min(earliestDate.valueOf(), janFirst.valueOf()));
  };

  findLatestDate = (campaigns: ICampaign[]): Date => {
    const latestCampaign = campaigns.reduce((prev, current) => {
      if (prev.dateEnd > current.dateEnd) {
        return prev;
      }

      return current;
    });

    return new Date(latestCampaign.dateEnd);
  };

  findTimelineSpot = (campaign: ICampaign, rows: ICampaign[][]): ITimelinesSpot => {
    const currentDateStart = Date.parse(campaign.dateStart);
    const currentDateEnd = Date.parse(campaign.dateEnd);

    let targetIndex = -1;

    const targetRowIndex = rows.findIndex((row: ICampaign[]) => {
      targetIndex = row.findIndex((current: ICampaign, i, campArray) => {
        const prevEndDate = Date.parse(current.dateEnd);

        const nextStartDate = i < campArray.length - 1 ? Date.parse(campArray[i + 1].dateStart) : null;

        return prevEndDate < currentDateStart && (nextStartDate === null || nextStartDate > currentDateEnd);
      });

      return row.length === 0 || targetIndex !== -1;
    });

    return {
      row: targetRowIndex,
      index: targetIndex,
    };
  };

  scheduleCampaigns = (campaigns: ICampaign[]): ICampaign[][] => {
    const sortedCampaigns = [...campaigns].sort((a, b) => Date.parse(a.dateStart) - Date.parse(b.dateStart));
    const resultTimelines: ICampaign[][] = [];

    sortedCampaigns.forEach((current: ICampaign) => {
      const result = this.findTimelineSpot(current, resultTimelines);
      const { row, index } = result;

      if (row === -1) {
        resultTimelines.push([current]);
      } else {
        resultTimelines[row].splice(index + 1, 0, current);
      }
    });

    return resultTimelines;
  };

  setInlinePosition = (
    startDate: Date,
    endDate: Date,
  ): [{ left: number; width: number }, { left: string; width: string }] => {
    const startDateShift = this.getDateShift(startDate);
    const endDateShift = this.getDateShift(endDate);

    return [
      {
        left: startDateShift,
        width: endDateShift - startDateShift,
      },
      {
        left: `${startDateShift}px`,
        width: `${endDateShift - startDateShift}px`,
      },
    ];
  };

  getDateShift = (date: Date): number => {
    const { weekWidth, months } = this.state;
    const dayWidth = weekWidth / 7;

    const month: IMonthData | undefined = this.findMonthByDate(months, date);

    if (month === undefined) {
      return 0;
    }

    const { firstDay, weeksBefore } = month;

    const firstWeekDay = getWeekDayNormalized(firstDay);
    const dayNumber = date.getDate() - firstDay.getDate();

    const monthShift = dayWidth * (firstWeekDay + dayNumber);
    const yearShift = weekWidth * weeksBefore;

    return monthShift + yearShift;
  };

  getLocalizedMonth = (monthIndex: number): string => {
    const { locale } = this.props;
    const currentYear = new Date().getFullYear();
    const date = new Date(currentYear, monthIndex, 1);

    return date.toLocaleString(locale, { month: 'long' });
  };

  handleDateRangeMouseEnter = (e: React.MouseEvent<HTMLDivElement>): void => {
    const { campaigns } = this.props;
    const target: HTMLDivElement = e.target as HTMLDivElement;
    const { top, height } = target.getBoundingClientRect();
    const topPosition = top + height / 2;

    const campaignId = Number(target.dataset.id);
    const hoveredCampaign = campaigns.find((current) => current.id === campaignId);

    if (hoveredCampaign) {
      this.setState({
        isTooltipVisible: true,
        hoveredCampaign,
        tooltipPosition: {
          top: topPosition,
          left: e.pageX,
        },
      });
    }
  };

  handleDateRangeMouseLeave = (): void => {
    this.setState({
      isTooltipVisible: false,
      hoveredCampaign: null,
    });
  };

  handleArrowButtonLeftMouseDown = (): void => {
    const timerId = window.setTimeout(() => {
      this.isButtonPressed = true;
      const intervalId = window.setInterval(this.scrollChart, 100, {
        isBackward: true,
      });
      this.timers.push(intervalId);
    }, 200);

    this.timers.push(timerId);
  };

  handleArrowButtonLeftClick = (): void => {
    this.doBlockScrolling(true);
  };

  handleArrowButtonRightMouseDown = (): void => {
    const timerId = window.setTimeout(() => {
      this.isButtonPressed = true;

      const intervalId = window.setInterval(this.scrollChart, 100);
      this.timers.push(intervalId);
    }, 200);

    this.timers.push(timerId);
  };

  private resetScrollingToMonth = (): void => {
    const { weekWidth } = this.state;

    const mostLeftMonth =
      this.state.months.find((it) => it.firstDay.getMonth() === new Date().getMonth()) || this.state.months[0];

    const offset =
      (mostLeftMonth.weeksBefore - ((mostLeftMonth.weeks.length + 0.5) / 3) * this.state.scale) * weekWidth;
    this.scrollChartPixel(false, offset, true, 'auto');
  };

  private doBlockScrolling = (backwards: boolean): void => {
    if (this.isButtonPressed) {
      this.isButtonPressed = false;
    }

    const scrollLeft = this.scrollbar?.scrollbars?.getScrollLeft();
    const { weekWidth } = this.state;

    let offset = 0;
    if (scrollLeft !== undefined) {
      const currentWeekMin = Math.round(scrollLeft / weekWidth);

      const mostLeftMonthIndex = this.state.months.findIndex((t) =>
        backwards ? t.weeksBefore >= currentWeekMin : t.weeksBefore > currentWeekMin,
      );

      if (mostLeftMonthIndex === -1) return;

      const mostLeftMonth = this.state.months[backwards ? Math.max(0, mostLeftMonthIndex - 1) : mostLeftMonthIndex];

      const monthOffset = mostLeftMonth.weeksBefore * weekWidth;

      offset = monthOffset - scrollLeft;
    }
    this.scrollChartPixel(backwards, offset);
  };

  handleArrowButtonRightClick = (): void => {
    this.doBlockScrolling(false);
  };

  handleArrowButtonMouseUp = (): void => {
    while (this.timers.length > 0) {
      window.clearTimeout(this.timers.pop());
    }
  };

  handleArrowButtonMouseLeave = (): void => {
    while (this.timers.length > 0) {
      window.clearTimeout(this.timers.pop());
    }
  };

  handleScrollbarScrollStart = (): void => {
    if (!this.scrolledByMouse) {
      this.scrolledByMouse = true;
    } else {
      this.setState({ isScrollingActive: true });
      document.addEventListener('mouseup', this.handleFlowChartMouseUp);
    }
  };

  handleFlowChartMouseUp = (): void => {
    const { isScrollingActive } = this.state;

    if (isScrollingActive) {
      this.setState({ isScrollingActive: false });
      document.removeEventListener('mouseup', this.handleFlowChartMouseUp);
    }
  };

  scrollChartPixel = (
    isBackward: boolean,
    scroll: number,
    absolute = false,
    behavior: 'auto' | 'smooth' = 'smooth',
  ): void => {
    if (this.scrollbar) {
      const scrollSettings: ScrollToOptions = {
        left: (absolute ? 0 : this.scrollbar.getScrollLeft()) + scroll,
        behavior,
      };

      this.scrolledByMouse = false;
      this.scrollbar.getView()?.scroll(scrollSettings);
    }
  };

  /**
   *
   * @param isBackward
   * @param isSmooth
   * @param numberOfWeeks - average number of weeks in months
   * @param bias - explicit offset(used to scroll precisely)
   */
  scrollChart = ({
    isBackward = false,
    isSmooth = false,
    numberOfWeeks = 4.35,
    bias = 0,
    absolute = false,
  }: IScrollbarBehavior = {}): void => {
    if (this.scrollbar) {
      const { weekWidth } = this.state;

      const step = isBackward ? weekWidth * -numberOfWeeks : weekWidth * numberOfWeeks;
      const scrollSettings: ScrollToOptions = {
        left: (absolute ? 0 : this.scrollbar.getScrollLeft()) + step + bias,
        behavior: isSmooth ? 'smooth' : 'auto',
      };

      this.scrolledByMouse = false;
      this.scrollbar.getView()?.scroll(scrollSettings);
    }
  };

  render() {
    const {
      weekWidth,
      months,
      timelines,
      isTooltipVisible,
      isScrollingActive,
      hoveredCampaign,
      tooltipPosition,
      scale,
    } = this.state;

    const { campaigns, isScrollbarHidden } = this.props;

    if (campaigns.length === 0) {
      return (
        <div className={classes.NoDataMessage}>
          <LocalizedMessage id="brand.flowchart.nodata" />
        </div>
      );
    }

    const weekCount = months.reduce((sumWeek, item) => sumWeek + item.weeks.length, 0);
    const chartWidth = weekWidth * weekCount;

    return (
      <div className={classes.FlowChart}>
        <div className={classes.FlowChartScale}>
          <span className={classes.FlowChartScaleLabel}>
            <LocalizedMessage id="brand.flowchart.scale" />
          </span>
          <div className={classes.Buttons}>
            <Button
              theme={scale === 1 ? 'success' : 'light'}
              className={classes.Button}
              onClick={() => this.setState({ scale: 1 })}
            >
              <LocalizedMessage id="brand.flowchart.scale-1-month" />
            </Button>
            <Button
              theme={scale === 3 ? 'success' : 'light'}
              className={classes.Button}
              onClick={() => this.setState({ scale: 3 })}
            >
              <LocalizedMessage id="brand.flowchart.scale-3-months" />
            </Button>
            <Button
              theme={scale === 5 ? 'success' : 'light'}
              className={classes.Button}
              onClick={() => this.setState({ scale: 5 })}
            >
              <LocalizedMessage id="brand.flowchart.scale-5-months" />
            </Button>
          </div>
        </div>
        <div className={classes.FlowChartContainer}>
          <i
            title="Scroll left"
            className={cx(classes.ArrowButton, 'icon icon-arrow-left')}
            onClick={this.handleArrowButtonLeftClick}
            onMouseDown={this.handleArrowButtonLeftMouseDown}
            onMouseUp={this.handleArrowButtonMouseUp}
            onMouseLeave={this.handleArrowButtonMouseLeave}
            data-test="scroll-left-button"
          />
          <Scrollbar
            ref={this.setScrollbarRef}
            hideVerticalScrollbar
            viewProps={
              /* hide 1px vertical line */
              { style: { overflow: 'hidden' } }
            }
            enableDragging
            onScrollStart={this.handleScrollbarScrollStart}
          >
            <div className={classes.Main} ref={this.setChartRef}>
              <div className={classes.Header}>
                {months.map((item, index) => (
                  <div key={index} className={classes.MonthContainer}>
                    <h3 className={classes.MonthTitle}>{this.getLocalizedMonth(item.firstDay.getMonth())}</h3>
                    <div className={classes.Weeks}>
                      {item.weeks.map((week, weekIndex) => (
                        <div className={classes.Week} key={weekIndex} style={{ width: `${weekWidth}px` }}>
                          {week}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className={classes.TimeLines} style={{ width: `${chartWidth}px` }}>
                {timelines.map((row: ICampaign[], rowIndex) => (
                  <div key={rowIndex} className={classes.Row}>
                    {row.map((campaign: ICampaign, campaignIndex) => {
                      const { id, dateStart, dateEnd, name, campaignStatus } = campaign;
                      const [, styledPos] = this.setInlinePosition(new Date(dateStart), new Date(dateEnd));

                      return (
                        <Link to={`/campaigns/${id}`} key={campaignIndex} data-test={`campaign-${id}-link`}>
                          <div
                            onMouseEnter={this.handleDateRangeMouseEnter}
                            onMouseLeave={this.handleDateRangeMouseLeave}
                            data-id={id}
                            className={cx(classes.DateRange, {
                              [classes.DateRangeStatusActive]: campaignStatus === CampaignStatus.ACTIVE,
                              [classes.DateRangeStatusPaused]: campaignStatus === CampaignStatus.PAUSED,
                              [classes.DateRangeStatusCompleted]: campaignStatus === CampaignStatus.COMPLETED,
                              [classes.DateRangeStatusArchived]: campaignStatus === CampaignStatus.ARCHIVED,
                              [classes.DateRangeStatusUnconfirmed]: campaignStatus === CampaignStatus.UNCONFIRMED,
                              [classes.DateRangeStatusConfirmed]: campaignStatus === CampaignStatus.CONFIRMED,
                            })}
                            style={styledPos}
                          >
                            <span className={classes.dataRangeLabel}>{name}</span>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
            {isTooltipVisible && hoveredCampaign && (
              <CampaignTooltip campaign={hoveredCampaign} position={tooltipPosition} />
            )}
          </Scrollbar>
          <div
            className={cx(classes.ScrollbarOverlay, {
              [classes.ScrollbarOverlayVisible]: isScrollbarHidden && !isScrollingActive,
            })}
          />
          <i
            title="Scroll right"
            className={cx(classes.ArrowButton, 'icon icon-arrow-right')}
            onClick={this.handleArrowButtonRightClick}
            onMouseDown={this.handleArrowButtonRightMouseDown}
            onMouseUp={this.handleArrowButtonMouseUp}
            onMouseLeave={this.handleArrowButtonMouseLeave}
            data-test="scroll-right-button"
          />
        </div>
      </div>
    );
  }
}

export default FlowChart;
