import { errorLocales } from 'locales';

export type EmptyObject = Record<string, never>;

export type Time = string; // ISO 8601
/** @format faker.date.between('2020-01-01', '2023-01-01') */
export type Date = string; // YYYY-MM-DD

export type Locale = 'ru';

export interface IOption<T = number, K extends string | JSX.Element = string> {
  value: T;
  label: K;
}

export interface IPagingServerResponse<T> {
  content: T;
  totalPages: number;
  page: number;
  totalElements: number;
}

export type PSR<T> = IPagingServerResponse<T>;

export enum ActionStatus {
  SUCCESS = 'SUCCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
}

export interface IColorTheme {
  background: string;
  mainText: string;
  additionalText: string;
}

export interface IUserErrorMessage {
  errorMessage: typeof errorLocales[number];
  errorParameters: Partial<Record<string, string>> | null;
}

export interface IError extends Error {
  message: string;
  description?: string | null;
  cause?: string | null;
  exception?: string | null;
  rootCause?: string | null;
  userErrorMessage?: IUserErrorMessage;
  userErrorMessageList?: IUserErrorMessage[] | null;
  responseObject?: { error: string; error_description: string };
  statusCode?: number;
}

export type SortingDirection = 'ASC' | 'DESC';

export type SortingOptions = {
  field: string;
  direction: SortingDirection;
} | null;

export type Nullable<T> = T | null;
