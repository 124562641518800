import React from 'react';
import cx from 'classnames';
import classes from './Icon.module.scss';

type Props = {
  /** Additional className */
  className?: string;
  /** Icon className (icon font) or url (src) */
  icon: string;
};

export const Icon: React.VFC<Props> = (props) => {
  const { icon, className } = props;

  return icon.split('/').length > 1 ? (
    <img className={cx(classes.root, className)} src={icon} alt="icon" />
  ) : (
    <span className={cx(classes.root, className, icon)} />
  );
};
