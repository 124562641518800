import HT from 'handsontable';

import { makeRenderer } from './renderers';
import { makeValidator } from './validators/makeValidator';
import { columnTypeToHTCellTypeMap } from './columnTypeMaps/columnTypeToHTCellTypeMap';
import { Column, isDictionaryColumn } from '../types';

export function getColSettings(columns: Column[]): HT.ColumnSettings[] {
  return columns.map((column) => ({
    data: column.name,
    type: columnTypeToHTCellTypeMap[column.type],
    readOnly: column.readOnly,
    disableVisualSelection: column.disableVisualSelection ?? false,
    renderer: makeRenderer(column),
    validator: makeValidator(column),
    ...getAdditionalColSettings(column),
  }));
}

function getAdditionalColSettings(column: Column): HT.ColumnSettings {
  const columnType = columnTypeToHTCellTypeMap[column.type];

  switch (columnType) {
    case 'numeric':
      return {
        numericFormat: {
          pattern: {
            thousandSeparated: true,
            optionalMantissa: true,
            trimMantissa: true,
            mantissa: 2,
          },
          culture: 'ru-RU',
        },
      };
    case 'dropdown':
      return {
        source: isDictionaryColumn(column) ? column.source.map((x) => x.value) : undefined,
      };
    default:
      return {};
  }
}
