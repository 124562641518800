import React, { FC } from 'react';
import cx from 'classnames';
import { ONE_MB } from 'helpers/constants';
import { localize, localizeMessage } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { ExcelFormats } from 'types/file';
import classes from './FileUploader.module.scss';

type Props = {
  /** Additional className */
  className?: string;
  /** Input ID. If it's not definite, input is hidden */
  inputId?: string;
  /** Label text */
  label?: string;
  /** On cancel button click event handler */
  onCancelUpload?: (() => void) | null;
  /** On upload event handler */
  onUpload(formData: FormData, name: string): void;
  /** Size of the component. Default: 'medium' */
  size?: 'small' | 'medium';
  /** Tooltip */
  tooltip?: string;
  /** File validation rules */
  fileValidationRules?: FileValidationRules;
};

type FileValidationRules = {
  /** Allowed file formats */
  formats: string[];
  /** Max file size (in MBs) */
  maxSize?: number;
};

export const FileUploader: FC<Props> = (props: Props) => {
  const {
    className,
    inputId,
    label = localize('campaign-view.mediaplan.add-file'),
    onCancelUpload,
    onUpload,
    size = 'medium',
    tooltip,
    fileValidationRules = { formats: Object.values(ExcelFormats) },
  } = props;
  const fileExtensions = fileValidationRules.formats.map((ext) => `.${ext}`).join(', ');

  const isHidden = Boolean(inputId);

  const uploaderRef = React.createRef<HTMLInputElement>();

  const handleUploaderFieldChange = (files: FileList | null) => {
    if (files?.length) {
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      const { isValid, messages } = validate(file);

      if (isValid) {
        onUpload(formData, file.name);
      } else {
        toast.error(messages.join('\n\n'));
      }
    }

    if (uploaderRef.current) uploaderRef.current.value = '';
  };

  const validate = (file: File) => {
    const { formats, maxSize } = fileValidationRules;
    const errorMessages = [];

    if (!formats.some((ext) => file.name.endsWith(`.${ext}`))) {
      errorMessages.push(localizeMessage({ id: 'errors.uploader.incorrect-file-format' }, { formats: fileExtensions }));
    }

    if (maxSize && file.size > maxSize * ONE_MB) {
      errorMessages.push(localizeMessage({ id: 'errors.uploader.incorrect-file-size' }, { fileSize: String(maxSize) }));
    }

    return { isValid: errorMessages.length === 0, messages: errorMessages };
  };

  const input = (
    <input
      accept={fileExtensions}
      className={classes.input}
      data-test="uploader-field"
      id={inputId}
      name="uploaded_file"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUploaderFieldChange(e.target.files)}
      ref={uploaderRef}
      title={tooltip}
      type="file"
    />
  );

  if (isHidden) {
    return input;
  }

  return (
    <div className={cx(classes.root, className)}>
      <div className={cx(classes.uploader, { [classes[`${size}Size`]]: !!size })}>
        <i className={cx(classes.downloadIcon, 'icon icon-download')} />
        {onCancelUpload && (
          <i
            className={cx(classes.closeIcon, 'icon icon-reset')}
            onClick={onCancelUpload}
            title={localize('file-uploader.cancel-upload-file')}
          />
        )}
        <div className={classes.label}>{label}</div>
      </div>
      {input}
    </div>
  );
};

export type { Props as FileUploaderProps, FileValidationRules };
