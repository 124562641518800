import React, { useMemo } from 'react';
import cx from 'classnames';
import { parseISO } from 'date-fns';
import { observer } from 'mobx-react';
import { DropdownChangeParams } from 'primereact/dropdown';

import { useStores } from 'core';
import { Dropdown } from 'shared/components/common/form/Dropdown';
import { DateFilter } from 'shared/components/common/form/DateFilter';
import { ClearButton } from 'shared/components/common/buttons/ClearButton';
import { SearchInput } from 'shared/components/common/form/SearchInput';
import { capitalizeFirstLetter } from 'helpers/utils';
import classes from './Filters.module.scss';
import { convertToServerDate } from '../../../../../helpers';

export const Filters = observer(() => {
  const {
    adFoxCampaignsStore: { filters, setFilters, clearAllFilters, rowStatusesDictionary },
  } = useStores();

  const applyCampaignNameFilter = (value: string | null) => setFilters({ idOrName: value });
  const handleCampaignNameClear = () => applyCampaignNameFilter(null);
  const handleCampaignStatusChange = (e: DropdownChangeParams) => setFilters({ status: e.value });
  const handleDateChange = (date: Date[]) => {
    setFilters({
      date:
        date.length === 2
          ? { type: 'period', periodStart: convertToServerDate(date[0]), periodEnd: convertToServerDate(date[1]) }
          : null,
    });
  };

  const campaignStatusOptions: Array<{ label: string; value: number }> = useMemo(() => {
    return rowStatusesDictionary.map((x) => ({
      label: capitalizeFirstLetter(x.value),
      value: Number(x.externalId),
    }));
  }, [rowStatusesDictionary]);

  const datePeriod = useMemo(
    () => (filters.date ? [parseISO(filters.date.periodStart), parseISO(filters.date.periodEnd)] : null),
    [filters.date],
  );

  return (
    <div className={classes.root}>
      <SearchInput
        defaultValue={filters.idOrName ?? undefined}
        onChange={applyCampaignNameFilter}
        onClear={handleCampaignNameClear}
        placeholder="Название/ID"
      />
      <Dropdown
        className={cx(classes.statusFilter, { [classes.isActive]: filters.status != null })}
        value={filters.status}
        options={campaignStatusOptions}
        onChange={handleCampaignStatusChange}
        placeholder="Статус"
        showClear
      />
      <DateFilter filterValue={datePeriod} onChange={handleDateChange} />
      <ClearButton
        disabled={Object.keys(filters).length === 1 && Object.keys(filters)[0] === 'apiAccountId'}
        onClick={clearAllFilters}
      />
    </div>
  );
});
