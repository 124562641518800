import React, { RefObject, useCallback, useState } from 'react';
import { localize } from 'shared/components/other';

export interface IAuthFormValues {
  username: string;
  password: string;
}

export type IAuthFormErrors<T = string> = {
  [key in keyof IAuthFormValues]?: T;
};

export function validateAuthForm(username: string, password: string): IAuthFormErrors {
  const newErrors: IAuthFormErrors = {};

  if (!username.length) {
    newErrors.username = localize('auth.errors.empty-mail-field');
  }

  if (!password.length) {
    newErrors.password = localize('auth.errors.empty-password-field');
  }

  return newErrors;
}

interface IAuthFormHook {
  onFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onFieldChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFieldFocus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly: boolean;
  errors: IAuthFormErrors;
  values: IAuthFormValues;
}
export const useAuthForm = (
  isAuthorizing: boolean,
  formRef: RefObject<HTMLFormElement>,
  onSubmit: (values: IAuthFormValues) => void,
): IAuthFormHook => {
  // fields
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [readonly, setReadonly] = useState(true);
  const [errors, setErrors] = useState<IAuthFormErrors>({});

  // callbacks
  const onFieldFocus = useCallback(() => {
    setReadonly(false);
  }, []);

  const onFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const value: string = e.target.value.trim();
    if (name === 'username') {
      setUsername(value);
    }
    if (name === 'password') {
      setPassword(value);
    }
  }, []);

  const onFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isAuthorizing) {
        return;
      }

      const newErrors = validateAuthForm(username, password);

      if (Object.keys(newErrors).length) {
        setErrors(newErrors);

        // This component doesn't support ref callbacks
        if (typeof formRef === 'function') return;

        if (!username.length) {
          formRef.current?.mail?.focus();
        } else {
          formRef.current?.password?.focus();
        }

        return;
      }

      onSubmit({
        username,
        password,
      });
    },
    [isAuthorizing, username, password, formRef, onSubmit],
  );

  return {
    onFormSubmit,
    onFieldChange,
    onFieldFocus,
    readonly,
    errors,
    values: {
      username,
      password,
    },
  };
};
