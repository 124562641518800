export interface IAccountProperties {
  propertyDescription: string;
  propertyName: string;
  propertyType: 'STRING' | 'NUMBER';
  required: boolean;
  validator: null;
}

export interface ITokenLink {
  token_link: string;
}

export interface IGetTokenOptions {
  url: string;
  accountId: number;
  cabinetId: number;
  isEditing?: boolean;
}

export type ErrorLocaleId = 'account.warning.not-empty';
export type IErrors = Record<string, ErrorLocaleId[] | undefined>;

export type ICustomPropertiesHolder = Record<string, string> & {
  customProperties?: Record<string, string>;
};

export enum AccountUserStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum AccountSystemStatus {
  ACTIVE = 'ACTIVE',
  NOT_READY = 'NOT_READY',
}

export interface IAccount {
  id: number;
  cabinetId: number;
  realmId: number;
  accountLogin: string;
  accountStatusReason: 'WAITING_FOR_TOKEN' | null;
  accountCreatedTime: string;
  accountUpdatedTime: string;
  userStatus: AccountUserStatus;
  systemStatus: AccountSystemStatus;
  isDeleted: boolean;
  tokenLink: string | null;
  customProperties: null | { applicationId: number };
}

export interface IUpdatedAccount extends IAccount {
  tokenLink: string;
}

export interface ISelectedAccount {
  accountId: number;
  cabinetId: number;
}

export interface ICabinet {
  id: number;
  description: string;
  displayName: string;
  canUserCreateAccount: boolean;
}

export enum CabinetStatus {
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
}
