import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { DropdownChangeParams, DropdownFilterParams } from 'primereact/dropdown';
import { IOption } from 'types';
import { localize } from 'shared/components/other';
import { Dropdown, DropdownProps } from '../Dropdown';
import classes from './Select.module.scss';

export type Option<T> = IOption<T> & { logo?: string };

type Props<T = number> = Omit<DropdownProps, 'options' | 'value' | 'onChange' | 'filterMatchMode'> & {
  /** Selection options */
  options: Option<T>[];
  /** Selected value */
  value?: T;
  /** Callback to invoke on value change. */
  onChange?(value: T, selectedOption?: Option<T>): void;
  /** Addition className of the Dropdown component */
  dropdownClassName?: string;
  /** Display mode. Default: "rounded" */
  mode?: 'rounded' | 'inline';
  /** Specifies the valid/invalid state. Default: false */
  invalid?: boolean;
};

export const Select = <T,>(props: Props<T>): JSX.Element => {
  const {
    options,
    value,
    onChange,
    className,
    dropdownClassName,
    invalid = false,
    placeholder = `${localize('select')}...`,
    tabular = false,
    theme = 'light',
    mode = 'rounded',
    ...restProps
  } = props;

  const [filter, setFilter] = useState<string>();
  const firstFilteredOption = useMemo(() => {
    if (!filter || !options?.length) return null;
    const filteredOptions = options.filter((x) => x.label.toLowerCase().includes(filter.toLowerCase()));
    if (!filteredOptions?.length) return null;
    return filteredOptions.find((x) => x.value === value) ?? filteredOptions[0];
  }, [filter, options, value]);

  const handleKeydown = useCallback(
    (e) => {
      if (e.key === 'Enter' && e.target.classList.contains('p-dropdown-filter') && firstFilteredOption) {
        onChange?.(firstFilteredOption.value, firstFilteredOption);
      }
    },
    [firstFilteredOption, onChange],
  );

  const handleFilter = useCallback((e: DropdownFilterParams) => {
    setFilter(e.filter);
  }, []);

  const handleChange = useCallback(
    (e: DropdownChangeParams) => {
      const selectedOption = options?.find((option) => option.value === e.value);
      onChange?.(e.value, selectedOption);
    },
    [options, onChange],
  );

  const withLogo = useMemo(() => options?.some((x) => !!x.logo), [options]);
  const itemWithLogoTemplate = useCallback((option: Option<T>) => {
    const { label, logo } = option ?? {};
    return (
      <div className={classes.logoItem}>
        {logo && <img className={classes.logo} src={logo} alt="" />}
        <span className={classes.label}>{label}</span>
      </div>
    );
  }, []);

  return (
    <div
      className={cx(classes.root, className, classes[`${theme}Theme`], classes[`${mode}Mode`], {
        [classes.isTabular]: tabular,
        [classes.isInvalid]: invalid,
      })}
      onKeyDown={handleKeydown}
    >
      <Dropdown
        options={options}
        value={value}
        onChange={handleChange}
        appendTo="self"
        className={dropdownClassName}
        filter
        onFilter={handleFilter}
        panelClassName={classes.panel}
        resetFilterOnHide
        tabular={tabular}
        theme={theme}
        placeholder={placeholder}
        valueTemplate={withLogo ? itemWithLogoTemplate : undefined}
        itemTemplate={withLogo ? itemWithLogoTemplate : undefined}
        {...restProps}
      />
    </div>
  );
};

export type { Props as SelectProps };
