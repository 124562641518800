import { LocaleIdType } from 'locales';
import { IOption } from 'types';

export type Item = {
  id: number;
  name: string;
};

export type AccountProps = {
  accountType: AccountType;
};

export type AdvertisementProps = {
  adFormat: number;
  allowedMediaTypes: Exclude<MediaTypes, MediaTypes.LOGO>[];
  requiredLogo: boolean;
};

export type AccountType = 'general' | 'agency';

export type AccountItem = Item & AccountProps;

export type AdvertisementItem = Item & AdvertisementProps;

type UserSettingsName = 'account' | 'client';

type AdSettingsName = 'campaign' | 'adGroup' | 'ad';

export type OptionName = 'cabinet' | UserSettingsName | AdSettingsName;

type OptionProps<T extends IOption = IOption> = {
  items: T[];
  value: number | null;
  disabled: boolean;
  updatable: boolean;
  requestCount: number;
  localeId: LocaleIdType;
};

export type TextFieldProps = {
  name: string;
  value: string;
  isOptional: boolean;
  invalid: boolean;
  validationType?: 'url'; // NOTE: other formats may be added
};

export type Options = {
  cabinet: OptionProps;
  account: OptionProps<IOption & AccountProps>;
  client: OptionProps;
  campaign: OptionProps;
  adGroup: OptionProps;
  ad: OptionProps<IOption & AdvertisementProps>;
};

export type OptionData = Partial<Record<OptionName, number>>;

export enum MediaTypes {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  LOGO = 'LOGO',
}

export type MediaData = {
  images?: string[];
  video?: string;
  logo?: string;
};

export enum FileTypes {
  EXCEL = 'EXCEL',
  MEDIA = 'MEDIA',
  LOGO = 'LOGO',
}

export type FileData = Record<FileTypes, File | null>;

export type TemplateData = {
  cabinetId: number;
  fileS3Id: string;
};

export type SettingMetadata<T = string> = {
  name: T;
  format: 'list' | 'url';
  isOptional: boolean;
};

export type SettingsMetadata = {
  cabinetId: number;
  metadata: {
    userSettings: SettingMetadata<UserSettingsName>[];
    adSettings: SettingMetadata<AdSettingsName>[];
  };
};
