import { IFilters, IStatisticFile } from 'types/mediafact';
import { FileS3Id } from 'types/file';
import showConfirmation from 'helpers/showConfirmation';
import { localize, localizeMessage } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { BaseStore } from 'shared/stores';
import { UploadStatisticsStore } from './UploadStatistics.store';

type Deps = {
  uploadStatisticsStore: UploadStatisticsStore;
};

export class UploadStatisticsViewStore extends BaseStore<Deps> {
  handleFileUpload = async (formData: FormData, fileName: string): Promise<void> => {
    const { loadFiles } = this.deps.uploadStatisticsStore;
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;

    if (advertiserId === null) return;

    const response = await this.services.api.mediafact.upload({ customerId: advertiserId, formData });
    if (response.error) return;

    try {
      await loadFiles();
    } catch {
      return;
    }

    toast.success(localizeMessage({ id: 'messages.file-added' }, { fileName }));
  };

  handleFiltersChange = (filters: Partial<IFilters>): void => {
    this.deps.uploadStatisticsStore.updateFilters(filters);
  };

  handleSortButtonClick = (e: React.MouseEvent<HTMLElement>): void => {
    const target = e.target as HTMLElement;
    const sortBy = target?.closest<HTMLElement>('[data-sort]')?.dataset.sort ?? null;
    this.deps.uploadStatisticsStore.updateSortData(sortBy as keyof IStatisticFile);
  };

  handleDownloadTemplateButtonClick = async (): Promise<void> => {
    const { templateFileS3Id, loadTemplateFileId } = this.deps.uploadStatisticsStore;
    if (templateFileS3Id === null) {
      await loadTemplateFileId();
    }
    this.handleDownloadFileButtonClick(this.deps.uploadStatisticsStore.templateFileS3Id);
  };

  handleDownloadFileButtonClick = async (fileId: FileS3Id): Promise<void> => {
    if (fileId === null) {
      toast.error(localize('errors.file-id-not-found'));
      return;
    }

    await this.services.api.file.download({ fileId, fileType: 'MEDIAFACT_WEB' });
  };

  handleDeleteFileButtonClick = async (fileId: IStatisticFile['mediafactManualFileId']): Promise<void> => {
    const { files, updateFiles } = this.deps.uploadStatisticsStore;
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;

    if (advertiserId === null) return;

    const localizedTitleId = 'upload-statistics.actions.delete-confirm';
    const localizedTextId = 'upload-statistics.actions.delete-description';
    const isConfirmed = await showConfirmation({
      title: `${localize(localizedTitleId)}?`,
      text: localize(localizedTextId),
      type: 'removal',
    });

    if (!isConfirmed) return;

    const removedFile = files.find((file) => file.mediafactManualFileId === fileId);

    if (removedFile === undefined) {
      toast.error(localize('errors.file-id-not-found'));
      return;
    }

    const response = await this.services.api.mediafact.delete({
      customerId: advertiserId,
      mediafactManualFileId: fileId,
    });
    if (response.error) return;

    updateFiles(fileId);
    toast.success(localizeMessage({ id: 'messages.file-removed' }, { fileName: removedFile.fileName }));
  };
}
