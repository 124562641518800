import React, { useCallback } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { IOption } from 'types';
import { TvTerritoryType } from 'types/mediaplan';
import { Select } from 'shared/components/common/form/Select';
import classes from './TvTerritoryTypeSelector.module.scss';

type Props = {
  campaignId: number;
  className?: string;
  onChange(value: IOption<TvTerritoryType>): void;
  value?: TvTerritoryType | null;
};

const options: IOption<TvTerritoryType>[] = [
  {
    value: TvTerritoryType.FEDERAL,
    label: localize('campaign-view.mediaplan.labels.national-tv'),
  },
  {
    value: TvTerritoryType.LOCAL,
    label: localize('campaign-view.mediaplan.labels.regional-tv'),
  },
];

export const TvTerritoryTypeSelector: React.VFC<Props> = (props) => {
  const { campaignId, className, onChange, value } = props;

  const handleChange = useCallback(
    (val: TvTerritoryType, option?: IOption<TvTerritoryType>) => {
      if (option) {
        onChange(option);
      }
    },
    [onChange],
  );

  return (
    <label className={cx(classes.root, className)}>
      <span className={classes.label}>{localize('campaign-view.mediaplan.select-tv-type')}</span>
      <Select
        className={classes.dropdown}
        inputId={`tv_type_${campaignId}`}
        onChange={handleChange}
        options={options}
        tabular
        theme="grey"
        value={value}
      />
    </label>
  );
};

export type { Props as TvTerritoryTypeSelectorProps };
