import { action, makeObservable, observable } from 'mobx';

import locales from 'locales';
import { Locale } from 'types';

const localeKey = 'lang';

const initDefaultLocale = (): Locale => {
  const ruLocale: Locale = 'ru';

  try {
    const lang = localStorage.getItem(localeKey) as Locale | undefined;
    if (lang && Object.keys(locales).includes(lang)) {
      return lang;
    }
    localStorage.setItem(localeKey, ruLocale);

    return ruLocale;
  } catch (error) {
    console.error('fail set to local storage');

    return ruLocale;
  }
};

const defaultLocale = initDefaultLocale();

export class LocaleStore {
  @observable
  lang: Locale = defaultLocale;

  @observable
  messages: Partial<Record<string, string>> = locales[defaultLocale];

  @action
  selectLocale(locale: Locale = defaultLocale): void {
    try {
      this.lang = locale;
      this.messages = locales[locale];
      localStorage.setItem(localeKey, locale);
    } catch {
      console.error('fail set to local storage');
    }
  }

  constructor() {
    makeObservable(this);
  }
}
