import { getStatusLocaleId } from 'helpers/utils';
import { ActionStatus } from 'types';
import { IFileStatus, LastFixationStatus } from 'types/mediaplan';

export const fileStatuses: IFileStatus[] = [IFileStatus.CURRENT, IFileStatus.DRAFT, IFileStatus.ARCHIVE];

export const fileStatusIds = fileStatuses.map((status) => getStatusLocaleId(status, 'campaign.file'));

export const fixationStatusToIndicatorStatusMap: Partial<Record<LastFixationStatus, ActionStatus>> = {
  [LastFixationStatus.FIXED]: ActionStatus.SUCCESS,
  [LastFixationStatus.FIX_IN_PROGRESS]: ActionStatus.IN_PROGRESS,
  [LastFixationStatus.UNFIX_IN_PROGRESS]: ActionStatus.IN_PROGRESS,
  [LastFixationStatus.ERROR]: ActionStatus.ERROR,
};

export const fixationStatusToLocalizedMap: Record<LastFixationStatus, string> = {
  [LastFixationStatus.FIXED]: 'fix-success',
  [LastFixationStatus.UNFIXED]: 'unfix-success',
  [LastFixationStatus.FIX_IN_PROGRESS]: 'fix-in-progress',
  [LastFixationStatus.UNFIX_IN_PROGRESS]: 'unfix-in-progress',
  [LastFixationStatus.ERROR]: 'fix-error',
};

export const clipPattern = /^[0-9]+$/;
