import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import { baseCellWrapper } from '../baseCellWrapper';
import classes from './BaseCell.module.scss';

type Props = {
  value: React.ReactNode;
};

const BaseCellComponent: React.VFC<Props> = ({ value }) => {
  const cellRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState<string | null>(null);

  useEffect(() => {
    const needTooltip =
      cellRef.current &&
      (cellRef.current.scrollHeight > cellRef.current.clientHeight ||
        cellRef.current.scrollWidth > cellRef.current.clientWidth);

    if (needTooltip && typeof value === 'string') {
      setTooltip(value);
    }
  }, [cellRef, value]);

  return (
    <div ref={cellRef} className={cx(classes.root, 'cell-with-tooltip')} data-pr-tooltip={tooltip}>
      {value}
    </div>
  );
};

export const BaseCell = baseCellWrapper(BaseCellComponent);
