import HT from 'handsontable';
import { range } from 'lodash';

export function afterGetColHeader(colIndex: number, TH: HTMLTableCellElement): void {
  const hot = this as HT;
  const columnName = hot.colToProp(colIndex);

  if (columnName === 'isSelected') {
    if (TH.querySelector('.select-all')) return;

    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.className = 'select-all';
    checkbox.checked = hot.getDataAtProp('isSelected').every(Boolean);
    checkbox.addEventListener('change', (event: Event | undefined) => {
      if (event?.target instanceof HTMLInputElement) {
        const { checked } = event.target;
        const data = range(this.countRows()).map((rowIndex) => [rowIndex, 'isSelected', checked]);
        this.setDataAtRowProp(data);
      }
    });

    // eslint-disable-next-line no-param-reassign
    TH.innerText = '';
    TH.appendChild(checkbox);
  }
}
