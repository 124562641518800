import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { TabPanel, TabView } from 'primereact/tabview';
import { localize } from 'shared/components/other';
import { TabSelect } from 'shared/components/common/form/TabSelect';
import { UploadedStatus } from 'types/campaign';
import { IFileStatus, ITvMediaplan, IVersion, TvTerritoryType } from 'types/mediaplan';
import { TemplateDownloadButtons } from './components/TemplateDownloadButtons';
import { TvMediaplanUploader, TvMediaplanUploaderProps } from './components/TvMediaplanUploader';
import { Creatives, CreativesProps } from './components/Creatives';
import { Editing, EditingProps } from './components/Editing';
import classes from './TvMediaplans.module.scss';

type Props = {
  className?: string;
  creativesTab: Omit<CreativesProps, 'isFederalProduct' | 'isCurrentStatus'>;
  currentTvMediaplan: ITvMediaplan | null;
  currentTvVersion?: IVersion;
  editingTab: EditingProps;
  onTemplateDownLoad(type: TvTerritoryType): void;
  uploadedStatus: UploadedStatus | null;
  uploader: Omit<TvMediaplanUploaderProps, 'uploadedStatus'>;
};

const tabs = ['creatives', 'editing'] as const;

type TvMediaplanTab = typeof tabs[number];

export const TvMediaplans: React.VFC<Props> = React.memo((props) => {
  const {
    className,
    creativesTab,
    currentTvMediaplan,
    currentTvVersion,
    editingTab,
    onTemplateDownLoad,
    uploadedStatus,
    uploader,
  } = props;

  const hasCurrentVersion = currentTvMediaplan?.versions.some((x) => x.status === IFileStatus.CURRENT);
  const isFederalProduct = currentTvMediaplan?.mediaType === TvTerritoryType.FEDERAL;
  const isCurrentStatus = currentTvVersion?.status === IFileStatus.CURRENT;

  const tabSelectOptions = useMemo(() => {
    const accessibleTabs: TvMediaplanTab[] = ['creatives'];
    if (hasCurrentVersion && isFederalProduct) accessibleTabs.push('editing');
    return accessibleTabs.map((tab) => ({
      label: localize(`tv-mediaplan.${tab}` as `tv-mediaplan.${TvMediaplanTab}`),
      value: tab,
      testId: `${tab}-tab`,
    }));
  }, [hasCurrentVersion, isFederalProduct]);

  const [activeTab, setActiveTab] = useState<TvMediaplanTab>('creatives');

  const activeTabIndex = tabs.indexOf(activeTab);

  const handleTabChange = useCallback(() => {}, []); // to enable TabView controlled behavior

  useEffect(() => {
    setActiveTab('creatives');
  }, [currentTvMediaplan]);

  if (uploadedStatus !== null) {
    return <TvMediaplanUploader uploadedStatus={uploadedStatus} {...uploader} />;
  }

  if (currentTvMediaplan === null || currentTvMediaplan.versions.length === 0 || creativesTab.creatives.length === 0) {
    return (
      <div className={cx(classes.root, className)}>
        <TemplateDownloadButtons onClick={onTemplateDownLoad} />
      </div>
    );
  }

  return (
    <div className={cx(classes.root, className)}>
      <TabSelect value={activeTab} onChange={setActiveTab} options={tabSelectOptions} />
      <TemplateDownloadButtons className={classes.templateDownloadButtons} onClick={onTemplateDownLoad} />
      <TabView activeIndex={activeTabIndex} onTabChange={handleTabChange}>
        <TabPanel className={classes.creatives}>
          <Creatives {...creativesTab} isCurrentStatus={isCurrentStatus} isFederalProduct={isFederalProduct} />
        </TabPanel>
        <TabPanel className={classes.editing}>
          <Editing {...editingTab} />
        </TabPanel>
      </TabView>
    </div>
  );
});

export type { Props as TvMediaplansProps };
