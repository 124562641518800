import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { toDatePeriod } from 'helpers';
import { getLocalizedMediaType, roundCampaignBudget } from 'helpers/utils';
import { IAdvertiser } from 'types/advertiser';
import { IBrand } from 'types/brand';
import { ICampaign } from 'types/campaign';
import { ISubbrand } from 'types/subbrand';
import { localize } from 'shared/components/other';
import { FavoritesButton } from 'shared/components/common/buttons/FavoritesButton';
import { CampaignStatusLabel } from 'shared/components/domain/CampaignStatusLabel';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import { InfoItem } from './components/InfoItem';
import classes from './CampaignInfo.module.scss';

type Props = {
  advertiser: IAdvertiser | null;
  brand: IBrand | null;
  campaign: ICampaign;
  className?: string;
  isEditButtonVisible: boolean;
  onFavoriteButtonClick(): void;
  subbrand: ISubbrand | null;
};

export const CampaignInfo: React.FC<Props> = React.memo((props) => {
  const { className, advertiser, campaign, brand, isEditButtonVisible, subbrand, onFavoriteButtonClick } = props;
  const { value: planValue, unitId: planUnitId } = roundCampaignBudget(campaign.totalBudgetPlan);
  const brandLink = brand && (
    <Link className={classes.brandLink} to={`/brands/${brand.id}`} data-test="brand-link">
      {brand.name}
    </Link>
  );
  const period = toDatePeriod(campaign);
  const budget = campaign.totalBudgetPlan ? `${planValue} ${localize(planUnitId)}` : '-';
  const type = campaign.mediaTypes.map(getLocalizedMediaType).join(', ');

  const handleFavoriteButtonChange = useCallback(() => {
    onFavoriteButtonClick?.();
  }, [onFavoriteButtonClick]);

  return (
    <div className={cx(classes.root, className)}>
      <header className={classes.header}>
        <h1 className={classes.title} data-global-tooltip data-pr-tooltip={campaign.name}>
          {campaign.name}
        </h1>
        {isEditButtonVisible && (
          <Link className={classes.editLink} to={`/campaigns/${campaign.id}/edit`} data-test="edit-campaign-button">
            <TextButton className={classes.editButton} icon="icon icon-edit" />
          </Link>
        )}
        <FavoritesButton
          className={classes.favoriteButton}
          checked={campaign.favorite}
          onChange={handleFavoriteButtonChange}
          size="large"
        />
      </header>
      <div className={classes.data}>
        <InfoItem title={localize('campaign.advertiser')} value={advertiser?.name} />
        <InfoItem title={localize('campaign.brand')} value={brandLink} />
        <InfoItem title={localize('campaign.subbrand')} value={subbrand?.name} />
        <InfoItem title={localize('campaign.status')} value={<CampaignStatusLabel value={campaign.campaignStatus} />} />
        <InfoItem title={localize('campaign.period')} value={period} />
        <InfoItem title={localize('campaign.budget')} value={budget} />
        <InfoItem title={localize('campaign.campaign-type')} value={type} />
      </div>
    </div>
  );
});

export type { Props as CampaignInfoProps };
