import React, { useCallback } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { EmptyObject } from 'types';
import { PaginationStore } from 'shared/stores';
import PaginationButtons from './PaginationButtons';

function ManagedPaginationButtons<
  Data,
  Deps extends Record<string, unknown> = EmptyObject,
  SharedStores extends Record<string, unknown> = EmptyObject
>({ pagination }: { pagination: PaginationStore<Data, Deps, SharedStores> }) {
  const setPage = useCallback(
    (page: number) => {
      runInAction(() => {
        // eslint-disable-next-line no-param-reassign
        pagination.page = page;
      });
    },
    [pagination],
  );

  return <PaginationButtons page={pagination.page} totalPages={pagination.totalPages} onUpdatePage={setPage} />;
}

export default observer(ManagedPaginationButtons);
