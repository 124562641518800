import { getUnixTime } from 'date-fns';
import { makeObservable, observable, action, runInAction } from 'mobx';
import * as R from 'ramda';

import showConfirmation from 'helpers/showConfirmation';
import { toast } from 'shared/components/common/misc/Toast';
import { localize } from 'shared/components/other';

import { PaginationStore } from 'shared/stores';
import { ReportData, ReportListFilters as Filters, ReportListSorting as Sorting } from 'types/reportConstructor';
import { ReportConstructorStore } from './ReportConstructor.store';

type InvalidationScope = [number, Sorting | null, Filters];

type Deps = {
  reportConstructorStore: ReportConstructorStore;
};

export class ReportListStore extends PaginationStore<ReportData[], Deps> {
  readonly fields: { name: keyof ReportData | 'statisticDate'; isSorting: boolean }[] = [
    { name: 'name', isSorting: true },
    { name: 'createdDate', isSorting: true },
    { name: 'statisticDate', isSorting: false },
    { name: 'userCreated', isSorting: true },
    { name: 'userModified', isSorting: true },
  ];

  private readonly initialSorting: Sorting = {
    sortingField: 'createdDate',
    sortingType: 'DESC',
  };

  @observable
  sorting: Sorting | null = this.initialSorting;

  @observable
  filters: Filters = {};

  pageSize = 20;

  protected _data: ReportData[] = [];

  protected invalidationScope = (): InvalidationScope => [this.page, this.sorting, this.filters];

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);

    this.setup();
  }

  @action
  cleanUp = (): void => {
    this.filters = {};
    this.sorting = this.initialSorting;
    this._data = [];
  };

  @action
  updateSorting = (sortingField: keyof ReportData): void => {
    if (this.sorting && this.sorting.sortingField === sortingField) {
      this.sorting = this.sorting.sortingType === 'ASC' ? null : { sortingField, sortingType: 'ASC' };
    } else {
      this.sorting = { sortingField, sortingType: 'DESC' };
    }
  };

  @action
  updateFilters = (filters: Filters): void => {
    this.filters = {
      ...this.filters,
      ...filters,
    };
  };

  @action
  clearFilters = (): void => {
    this.filters = {};
  };

  loadData = async (): Promise<void> => {
    const { createdDateStart, createdDateEnd, ...otherFilters } = this.filters;
    const data = {
      ...otherFilters,
      createdDateStart: createdDateStart instanceof Date ? getUnixTime(createdDateStart) * 1000 : undefined,
      createdDateEnd: createdDateEnd instanceof Date ? getUnixTime(createdDateEnd) * 1000 : undefined,
      sortingType: this.sorting?.sortingType,
      sortingField: this.sorting?.sortingField,
      page: this.page,
      size: this.pageSize,
    };

    const response = await this.services.api.reportConstructor.loadReportList(data);

    runInAction(() => {
      if (response.data) {
        const { items, totalPages, totalElements } = response.data;
        this._data = items ?? [];
        this._totalPages = totalPages;
        this._totalElements = totalElements;
      }
    });
  };

  open = async (reportId: number): Promise<void> => {
    this.deps.routingStore.history.push(`/report-constructor/${reportId}/view`);
  };

  edit = async (reportId: number): Promise<void> => {
    this.deps.routingStore.history.push(`/report-constructor/${reportId}/edit`);
  };

  copy = async (reportId: number, name: string): Promise<void> => {
    let reportName = name ? `${name}_copy` : '';

    const modalResult = await showConfirmation({
      title: localize('report-list.actions.enter-report-name'),
      input: {
        value: reportName,
        maxLength: 1000,
        onchange: (e) => {
          reportName = (e.target as HTMLInputElement)?.value;
        },
      },
      confirmTextId: 'button.save',
    });

    runInAction(async () => {
      if (modalResult === null) return;

      if (!reportName) {
        toast.error(localize('report-constructor.errors.report-copying-error.missing-report-name'));
        return;
      }

      const reportDataResponse = await this.services.api.reportConstructor.loadServerReport(reportId);

      runInAction(async () => {
        if (!reportDataResponse.data) {
          toast.error(localize('report-constructor.errors.report-copying-error'));
          return;
        }

        const response = await this.services.api.reportConstructor.copy({
          ...R.omit(['id'], reportDataResponse.data),
          name: reportName,
        });

        if (!response.error) {
          toast.success(localize('report-constructor.report-successfully-copied'));
          this.loadData();
        }
      });
    });
  };

  export = async (id: number): Promise<void> => {
    const {
      initReportData,
      cleanUp,
      childrenStores: { pivot },
    } = this.deps.reportConstructorStore;

    this.deps.globalLoaderStore.startLoading();

    try {
      await initReportData(id, 'view');
      await pivot.createPivot(null, 'table');
      await pivot.exportToExcel(this.deps.reportConstructorStore.reportName);
    } finally {
      cleanUp();
      this.deps.globalLoaderStore.stopLoading();
    }
  };

  delete = async (reportId: number): Promise<void> => {
    const isConfirmed = await showConfirmation({
      title: localize('report-list.actions.confirm-delete'),
    });

    if (!isConfirmed) return;

    const response = await this.services.api.reportConstructor.delete(reportId);

    runInAction(() => {
      if (!response.error) {
        toast.success(localize('report-constructor.report-successfully-deleted'));
        this.loadData();
      }
    });
  };

  protected revalidate = async ([page]: InvalidationScope, [prevPage]: InvalidationScope): Promise<void> => {
    if (page !== prevPage || prevPage === 0) {
      await this.loadData();
    } else {
      runInAction(() => {
        this.page = 0;
      });
    }
  };
}
