import HT from 'handsontable';

export function getChangedCellsInRow(instance: HT, rowIndex: number): string[] {
  const cellsMeta = instance.getCellMetaAtRow(instance.toPhysicalRow(rowIndex));
  const cellsValues = instance.getDataAtRow(rowIndex);

  return cellsMeta
    .filter((cell, colIndex) => {
      // eslint-disable-next-line eqeqeq
      return !['errors', 'isSelected'].includes(String(cell.prop)) && cell.defaultValue != cellsValues[colIndex];
    })
    .map((cell) => String(cell.prop));
}
