/* eslint-disable no-param-reassign */
import HT from 'handsontable';

export function applyCellStyleForErrorRows(TD: HTMLTableCellElement, value: HT.CellValue): void {
  if (!Array.isArray(value)) return;

  if (value.length) {
    HT.dom.addClass(TD, 'warning-cell');
    TD.title = value.map((x) => `● ${x}`).join('\n');
  } else {
    HT.dom.removeClass(TD, 'warning-cell');
    TD.title = '';
  }
}
