import React from 'react';
import { FormatterProps } from 'react-data-grid';
import { DataRow } from 'types/programmatic';
import { GridRow, isGridDataRow } from 'shared/components/other';
import { CheckSettingsCell, StartStopPlacementCell } from '../components';

export const settingsFormatter = ({ row }: FormatterProps<GridRow<DataRow>>): React.ReactElement | null => {
  if (!isGridDataRow(row)) {
    return null;
  }

  const isSecondLevelRow = row.depth === 1;
  if (isSecondLevelRow) {
    return <CheckSettingsCell status={row.data.settingsStatus} rowId={row.rowId} />;
  }

  const isThirdLevelRow = row.depth === 2;
  if (isThirdLevelRow) {
    return <StartStopPlacementCell rowData={row.data} />;
  }

  return null;
};
