import React from 'react';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const RadioButton: React.FC<Props> = (props: Props) => {
  const { name, checked, value, onChange, children, ...rest } = props;

  return (
    <label>
      <input type="radio" name={name} value={value} checked={checked} onChange={onChange} {...rest} />
      {children}
    </label>
  );
};
