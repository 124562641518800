import { flatTree } from './flatTree';

function findInTree<T extends Node<T>>(tree: T[], searchParams: SearchParam<T>[], mode: 'all'): T[];
function findInTree<T extends Node<T>>(tree: T[], searchParams: SearchParam<T>[], mode?: 'first'): T | null;
function findInTree<T extends Node<T>>(
  tree: T[],
  searchParams: SearchParam<T>[],
  mode: 'first' | 'all' = 'first',
): T | T[] | null {
  return (
    flatTree(tree)?.[mode === 'all' ? 'filter' : 'find']((item) =>
      searchParams.every(({ key, rule }) => rule(item[key])),
    ) ?? null
  );
}

type Node<T> = { children: T[] | null };
type SearchParam<T, K extends keyof T = keyof T> = {
  key: K;
  rule: (value: T[K]) => boolean;
};

export { findInTree };
