import { FieldData } from 'types/reportConstructor';

type ConfigRow = {
  header?: string;
  body?: { $subview: typeof pivot.views.JetView };
};

export const createCustomConfig = (): typeof pivot.views.config => {
  return class CustomConfig extends pivot.views.config {
    config() {
      const config = super.config();

      config.rows[1]?.body?.rows?.forEach((row: ConfigRow, rowIndex: number) => {
        if (row.header?.includes('Table')) {
          config.rows[1].body.rows.splice(rowIndex, 1);
        } else if (row.header?.includes('Filters')) {
          const filtersView = row.body?.$subview;

          if (filtersView) {
            const filterSuggest = filtersView.FilterSuggest;

            filtersView.FilterSuggest = (...args: unknown[]) => {
              const [, , field] = args;
              if (!(field as FieldData).usedInFilters) {
                return false;
              }
              return filterSuggest(...args);
            };
          }
        }
      });

      return config;
    }
  };
};
