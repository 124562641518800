import { ServerCabinet, Cabinet } from 'types/programmatic';

export function convertServerCabinets(cabinets: ServerCabinet[]): Cabinet[] {
  return cabinets
    .filter((cabinet) => cabinet.hasExcessControl)
    .map((cabinet) => ({
      id: cabinet.id,
      name: cabinet.name,
    }));
}
