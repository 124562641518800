import React from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { UploadedStatus } from 'types/campaign';
import { IFile, IWebMediaplan, WebMediaplanActions } from 'types/mediaplan';
import { AddFileButton } from '../../../../../AddFileButton';
import { MediaplanUploader, WebMediaplanUploaderProps } from '../MediaplanUploader';
import { WebMediaplanDataFixation, WebMediaplanDataFixationProps } from './components/WebMediaplanDataFixation';
import { WebMediaplanFilesTable } from './components/WebMediaplanFilesTable';
import { WebMediaplanFileHandlers } from './components/WebMediaplanFilesTable/components/WebMediaplanFile';
import classes from './WebMediaplans.module.scss';

type Props = {
  className?: string;
  fileHandlers: WebMediaplanFileHandlers;
  fixation: WebMediaplanDataFixationProps;
  mediaplan: IWebMediaplan | null;
  onMediaplanVersionAdd(value: UploadedStatus): void;
  uploadedStatus: UploadedStatus | null;
  uploader: Omit<WebMediaplanUploaderProps, 'uploadedStatus'>;
};

export const WebMediaplans: React.VFC<Props> = React.memo((props) => {
  const { className, fileHandlers, uploader, fixation, mediaplan, onMediaplanVersionAdd, uploadedStatus } = props;
  if (uploadedStatus !== null) {
    return <MediaplanUploader uploadedStatus={uploadedStatus} {...uploader} />;
  }
  if (!mediaplan || !fixation.startDate) {
    return null;
  }
  return (
    <div className={cx(classes.root, className)}>
      <header className={classes.header}>
        <h3 className={classes.title}>{localize('campaign.versions')}</h3>
        <AddFileButton onClick={onMediaplanVersionAdd} type="version" />
      </header>
      <WebMediaplanDataFixation className={classes.fixation} {...fixation} />
      <WebMediaplanFilesTable
        accessibleActions={Object.values(WebMediaplanActions)}
        className={classes.files}
        files={mediaplan.files as IFile[]}
        mediaplanId={mediaplan.mediaplanId}
        {...fileHandlers}
      />
    </div>
  );
});

export type { Props as WebMediaplansProps };
