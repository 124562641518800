import { CustomOptions, PageData } from 'api/types';
import { IPagingServerResponse } from 'types';
import { IAdvertiser } from 'types/advertiser';
import BaseApi from '../BaseApi';

class Advertisers extends BaseApi {
  list = ({ pageSize = 10000 }: PageData = {}, config: CustomOptions = {}) =>
    this.actions.get<IPagingServerResponse<IAdvertiser[]>>({
      url: '/customer/',
      data: { pageSize },
      config,
    });

  updateLogo = ({ customerId, formData }: UpdateLogoData, config: CustomOptions = {}) =>
    this.actions.post<IAdvertiser>({
      url: '/customer/update-logo',
      queryParams: { customerId },
      data: formData,
      config,
    });

  removeLogo = ({ customerId }: Pick<UpdateLogoData, 'customerId'>, config: CustomOptions = {}) =>
    this.actions.delete<IAdvertiser>({
      url: '/customer/remove-logo',
      queryParams: { customerId },
      config,
    });

  updateMainColor = ({ customerId, color }: UpdateColorData, config: CustomOptions = {}) =>
    this.actions.post<IAdvertiser>({
      url: '/customer/update-color',
      queryParams: { customerId, color },
      config,
    });

  updateBackgroundColor = ({ customerId, color }: UpdateColorData, config: CustomOptions = {}) =>
    this.actions.post<IAdvertiser>({
      url: '/customer/update-background-color',
      queryParams: { customerId, color },
      config,
    });
}

type UpdateLogoData = {
  customerId: number;
  formData: FormData;
};

type UpdateColorData = {
  customerId: number;
  color: string;
};

export default Advertisers;
