import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef } from 'react';

import { useStores } from 'core';
import { Button } from 'shared/components/common/buttons/Button';
import { ClearButton } from 'shared/components/common/buttons/ClearButton';
import { GridRef, localize, LocalizedMessage, ManagedPaginationButtons } from 'shared/components/other';

import { LogTable } from './components/LogTable';
import classes from './LogPanel.module.scss';

export const LogPanel = observer(() => {
  const { cabinetCopyingStore: store } = useStores();
  const { loadOptionItems } = store;
  const { totalElements, hasFilters, page, setPage, loadData, cleanUp } = store.childrenStores.log;
  const gridRef = useRef<GridRef['current']>(null);

  const handleClearButtonClick = useCallback(() => {
    gridRef.current?.clearFilters();
  }, []);

  const handleRefreshButtonClick = useCallback(() => {
    if (page === 0) {
      loadData();
    } else {
      setPage(0);
    }
  }, [page, setPage, loadData]);

  useEffect(() => {
    loadOptionItems('cabinet');
    loadData();

    return cleanUp;
  }, [loadOptionItems, loadData, cleanUp]);

  return (
    <div className={classes.root}>
      <div className={classes.buttonGroup}>
        <ClearButton disabled={!hasFilters} onClick={handleClearButtonClick} />
        <Button
          className="p-button-rounded p-button-icon-only"
          icon="pi pi-sync"
          tooltip={localize('cabinet-copying.log.refresh-data')}
          onClick={handleRefreshButtonClick}
        />
      </div>
      <LogTable gridRef={gridRef} className={classes.table} />
      <div className={classes.pagination}>
        <span className={classes.info}>
          <LocalizedMessage id="cabinet-copying.log.total-records" />: {totalElements}
        </span>
        <ManagedPaginationButtons pagination={store.childrenStores.log} />
      </div>
    </div>
  );
});
