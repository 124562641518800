import React from 'react';
import { Badge, BadgeSeverityType } from 'primereact/badge';
import { baseCellWrapper } from 'shared/components/other';
import { toPercent } from 'helpers';
import classes from './BudgetPctCell.module.scss';

type Props = {
  value: number | null;
};

const BudgetPctCellComponent: React.VFC<Props> = ({ value }) => {
  if (value === null) {
    return <span>-</span>;
  }

  let severity: BadgeSeverityType | undefined;
  if (value >= 0 && value <= 1) {
    severity = 'success';
  }
  if (value > 1) {
    severity = 'danger';
  }

  return (
    <div className={classes.root}>
      <Badge className={classes.badge} severity={severity} />
      <span>{toPercent(value)}</span>
    </div>
  );
};

export const BudgetPctCell = baseCellWrapper(BudgetPctCellComponent);
