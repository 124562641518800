import { IOption } from 'types';
import { TvTerritoryType } from 'types/mediaplan';
import { UploadedStatus } from 'types/campaign';

type Args = {
  audience: IOption | null;
  territoryType: IOption<TvTerritoryType> | null;
  retroperiod: Date[] | null;
  uploadedStatus: UploadedStatus | null;
};

export function areUploaderValuesInvalid({ audience, territoryType, uploadedStatus, retroperiod }: Args): boolean {
  return (
    isAudienceEmpty(audience) ||
    isTerritoryTypeEmpty(territoryType, uploadedStatus) ||
    isRetroperiodInvalid(retroperiod)
  );
}

export function isAudienceEmpty(audience: IOption | null): boolean {
  return !audience?.value;
}

export function isTerritoryTypeEmpty(
  territoryType: IOption<TvTerritoryType> | null,
  uploadedStatus: UploadedStatus | null,
): boolean {
  const isTvTypeRequred = uploadedStatus === 'mediaplan';
  return isTvTypeRequred && !territoryType?.value;
}

export function isRetroperiodInvalid(retroperiod: Date[] | null): boolean {
  return retroperiod === null || retroperiod.length !== 2;
}
