import React from 'react';
import cx from 'classnames';
import { FormatterProps } from 'react-data-grid';
import { Column, GridRow } from '../types';
import {
  dateFormatter,
  defaultFormatter,
  expandingFormatter,
  formatterWrapper,
  headerRenderer,
  currencyFormatter,
  selectionFormatter,
} from '../formatters';
import { isGridDataRow, isGridTitleRow } from '..';
import classes from '../DataGrid.module.scss';

export function pumpColumn<DR>(column: Column<DR>, isFirst: boolean, columnsCount: number): Column<DR> {
  const formatters: React.ComponentType<FormatterProps<GridRow<DR>>>[] = [];
  if (column.formatter) {
    formatters.push(column.formatter);
  }
  let pumpedColumn = { ...column, headerRenderer };
  if (column.type === 'currency') {
    formatters.unshift(currencyFormatter);
  }
  if (column.type === 'date' || column.type === 'datetime') {
    formatters.unshift(dateFormatter);
  }
  if (column.isExpandColumn) {
    formatters.unshift(expandingFormatter);
  }
  if (column.isSelectColumn) {
    formatters.unshift(selectionFormatter);
  }
  if (!formatters.length) {
    formatters.push(defaultFormatter);
  }
  const withTitleRow = Boolean(column?.titleRowTemplates?.length);
  const formatter = formatterWrapper(
    {
      withTitleRow,
      hasIndent: column.hasIndent,
      textAlign: column.textAlign,
    },
    ...formatters,
  );
  pumpedColumn = {
    ...pumpedColumn,
    formatter,
    colSpan: (args) => (args.type === 'ROW' && isGridTitleRow(args.row) && withTitleRow ? columnsCount : 1),
    cellClass: (row) =>
      cx(pumpedColumn.cellClass, {
        [classes.firstCell]: isFirst && isGridDataRow(row),
        [classes.titleCell]: isGridTitleRow(row),
      }),
  };

  return pumpedColumn;
}
