import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { mapValues, omitBy } from 'lodash';

import { PaginationStore } from 'shared/stores';
import { ServerModels as SM } from 'types/server';
import { Dictionary, DictionaryValueMap } from 'types/dictionary';
import { BANNER_LOG_DICTIONARIES_MAP, CAMPAIGN_LOG_DICTIONARIES_MAP } from '../helpers';

type LogType = 'campaigns' | 'banners';

type InvalidationScope = [number, SM.AdfoxLogFilter, SM.SortingField | null];

type Deps = {
  dictionaries: Record<LogType, () => Dictionary[]>;
};

export class AdFoxLogStore extends PaginationStore<SM.AdfoxLogItemView[], Deps> {
  protected _data: SM.AdfoxLogItemView[] = [];

  @observable
  private _campaignId: number | null = null;

  @observable
  private _totalItems = 0;

  @observable
  private _filters: SM.AdfoxLogFilter = {};

  private _defaultSorting: SM.SortingField | null = {
    field: 'userCreateInfo.timeCreate',
    direction: SM.SortingDirection.Desc,
  };

  @observable
  private _sorting: SM.SortingField | null = this._defaultSorting;

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
    this.setup();
  }

  @computed
  get totalItems(): number {
    return this._totalItems;
  }

  @computed
  get filters(): SM.AdfoxLogFilter {
    if (this._campaignId === null) {
      return this._filters;
    }
    return { ...this._filters, campaignId: this._campaignId };
  }

  @computed
  get hasActiveFilters(): boolean {
    return Object.keys(this._filters).length > 0;
  }

  @computed
  get defaultSorting(): SM.SortingField | null {
    return this._defaultSorting;
  }

  @computed
  get sorting(): SM.SortingField | null {
    return this._sorting;
  }

  @computed
  private get dictionaries(): Record<string, DictionaryValueMap> {
    const dictionariesMap = this.type === 'campaigns' ? CAMPAIGN_LOG_DICTIONARIES_MAP : BANNER_LOG_DICTIONARIES_MAP;
    return mapValues(dictionariesMap, ({ dictionaryId, replaceIf }) => {
      const dictionary =
        this.deps.dictionaries[this.type]().find((x) => x.dictionaryId === dictionaryId)?.dictionaryValues ?? [];
      return Object.fromEntries(dictionary.map((x) => [x.externalId, { value: x.value, replaceIf }]));
    });
  }

  @action
  setFilters = (value: SM.AdfoxLogFilter): void => {
    this._filters = { ...omitBy({ ...this.filters, ...value }, (x) => x == null) };
  };

  @action
  setSorting = (value: SM.SortingField | null): void => {
    this._sorting = value;
  };

  @action
  setCampaignId = (value: number | null): void => {
    this._campaignId = value;
  };

  @action
  resetFilters = (): void => {
    this._filters = {};
  };

  @action
  resetSorting = (): void => {
    this._sorting = this._defaultSorting;
  };

  @action
  resetData = (): void => {
    this._data = [];
    this._campaignId = null;
  };

  @action
  reset = (): void => {
    this.resetData();
    this.resetFilters();
    this.resetSorting();
  };

  @computed
  get type(): LogType {
    return this._campaignId ? 'banners' : 'campaigns';
  }

  protected invalidationScope = (): InvalidationScope => [this.page, this.filters, this.sorting];

  protected revalidate = async ([nextPage]: InvalidationScope, [prevPage]: InvalidationScope): Promise<void> => {
    if (prevPage === nextPage && nextPage > 0) {
      this.page = 0;
    }
    await this.loadData();
  };

  private loadData = async (): Promise<void> => {
    const response = await this.services.api.tradingDesk.adfox.log[this.type]({
      filter: this.filters,
      sorting: this.sorting ? [this.sorting] : null,
      page: { number: this.page, size: this.pageSize },
    });
    const adfoxLog = response?.data?.adfoxLog;
    if (adfoxLog) {
      runInAction(() => {
        this._data = this.mapByDictionary(adfoxLog?.data ?? []);
        this._totalPages = adfoxLog?.totalPages ?? 0;
        this._totalItems = adfoxLog?.total ?? 0;
      });
    }
  };

  private mapByDictionary = (data: SM.AdfoxLogItemView[]): SM.AdfoxLogItemView[] =>
    data.map((item) =>
      mapValues(item, (value, columnName) => {
        if (typeof value === 'string' || typeof value === 'number') {
          const replacementValue = this.dictionaries[columnName]?.[value];
          return replacementValue && replacementValue.replaceIf?.(item) !== false ? replacementValue.value : value;
        }
        return value;
      }),
    ) as SM.AdfoxLogItemView[];
}
