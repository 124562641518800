import React from 'react';
import { Column, GridRow, Row } from '../types';

type RowType<DR> = GridRow<DR> | Row<DR>;

export function getRowTitle<DR>(
  row: RowType<DR> | null,
  titleRowTemplates: Column<DR>['titleRowTemplates'],
): React.ReactNode[] | null {
  if (!row) return null;
  const parent = 'parent' in row ? row?.parent : null;
  if (parent) return getRowTitle(parent, titleRowTemplates);

  return titleRowTemplates?.map((x) => x(row)) ?? null;
}
