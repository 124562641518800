import React, { FC } from 'react';
import cx from 'classnames';
import { Button } from 'shared/components/common/buttons/Button';
import { LocalizedMessage } from 'shared/components/other';

import classes from './SaveButtons.module.scss';

type Props = {
  className?: string;
  onSave?(): void;
  onCancel?(): void;
};

export const SaveButtons: FC<Props> = ({ className, onSave, onCancel }) => (
  <div className={cx(classes.root, className)}>
    <Button theme="light" onClick={onCancel} data-test="cancel-button">
      <LocalizedMessage id="button.cancel" />
    </Button>
    <Button type={onSave ? 'button' : 'submit'} onClick={onSave} data-test="save-button">
      <LocalizedMessage id="button.save" />
    </Button>
  </div>
);
