import { action, computed, makeObservable, observable, runInAction, reaction, override } from 'mobx';

import hasAccess from 'helpers/hasAccess';
import showConfirmation from 'helpers/showConfirmation';
import { convertToDate, sortByFileExtension } from 'helpers/utils';
import { IOption, ActionStatus } from 'types';
import { IFactFixationInfo, IFileStatus, IWebMediaplan } from 'types/mediaplan';
import { FileS3Id } from 'types/file';
import { localize, localizeMessage } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { MediaplansStore } from './Mediaplans.store';
import { CampaignViewStore } from './CampaignView.store';
import { fixationStatusToIndicatorStatusMap, formatDateToStr, fixationStatusToLocalizedMap } from '../helpers';

type Deps = {
  campaignViewStore: CampaignViewStore;
};

export class WebMediaplansStore extends MediaplansStore<IWebMediaplan> {
  @observable
  private _fixationPeriod: Date | null = this.lastSuccessfulFixationPeriod;

  @computed
  get fixationPeriod(): Date | null {
    return this._fixationPeriod;
  }

  @computed
  get currentMediaplan(): IWebMediaplan | null {
    return this._mediaplans.find((mediaplan) => mediaplan.mediaplanId === this._currentMediaplanId) ?? null;
  }

  @computed
  get factFixationInfo(): IFactFixationInfo | null {
    return this.currentMediaplan?.factFixationInfo ?? null;
  }

  @computed
  get lastFixationPeriod(): Date | null {
    return convertToDate(this.factFixationInfo?.lastFixationPeriod ?? null);
  }

  @computed
  get lastSuccessfulFixationPeriod(): Date | null {
    return convertToDate(this.factFixationInfo?.lastSuccessfulFixationPeriod ?? null);
  }

  @computed
  get lastFixationStatus(): ActionStatus | null {
    if (this.factFixationInfo === null) {
      return null;
    }

    const { lastFixationStatus } = this.factFixationInfo;
    const status = lastFixationStatus && fixationStatusToIndicatorStatusMap[lastFixationStatus];

    return status ?? null;
  }

  @computed
  get lastFixationLocalizedStatus(): string | null {
    if (this.factFixationInfo === null) {
      return null;
    }

    const { lastFixationStatus } = this.factFixationInfo;

    return lastFixationStatus && fixationStatusToLocalizedMap[lastFixationStatus];
  }

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);

    reaction(
      () => this.factFixationInfo,
      () => {
        this._fixationPeriod = this.lastSuccessfulFixationPeriod;
      },
    );
  }

  @override
  cleanUp(): void {
    super.cleanUp();
    this._fixationPeriod = null;
  }

  loadMediaplansCount = async (): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null) return;

    const response = await this.services.api.webMediaplans.getCount({ campaignId });
    if (response.error) return;

    runInAction(() => {
      this._hasCurrentMediaplans = response.data.countCurrent > 0;
    });
  };

  loadMediaplans = async (): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;

    if (!hasAccess('webMediaplansTab', 'campaignPage') || campaignId === null) {
      return;
    }

    const response = await this.services.api.webMediaplans.list({ campaignId });
    if (response.error) return;

    const sortedWebMediaplans = response.data.map((webMediaplan) => ({
      ...webMediaplan,
      files: sortByFileExtension(webMediaplan.files),
    }));

    runInAction(() => {
      this._mediaplans = sortedWebMediaplans;

      if (this.currentMediaplanId === null && sortedWebMediaplans.length) {
        this._currentMediaplanId = sortedWebMediaplans[0].mediaplanId;
      }
    });
  };

  changeMediaplanStatus = async (newStatus: IOption<IFileStatus>, mediaplanVersionId: number): Promise<void> => {
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || this.currentMediaplan === null) return;

    const data = {
      mediaplanVersionId,
      newStatus: newStatus.value,
    };

    const response = await this.services.api.webMediaplans.changeStatus({
      campaignId,
      mediaplanId: this.currentMediaplan.mediaplanId,
      data,
    });
    if (response.error) return;

    toast.success(localize('campaign-view.mediaplan.status-changed'));

    this.loadMediaplans();
    this.loadMediaplansCount();
  };

  downloadMediaplan = async (fileS3Id: FileS3Id): Promise<void> => {
    if (fileS3Id === null) {
      toast.error(localize('errors.file-id-not-found'));
      return;
    }

    this.services.api.file.download({ fileId: fileS3Id, fileType: 'MEDIAPLAN_WEB' });
  };

  renameMediaplan = async (newName: string): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || mediaplanId === null) return;

    const response = await services.api.webMediaplans.rename({ campaignId, mediaplanId, newName });
    if (response.error) return;

    toast.success(localize('campaign-view.mediaplan.renamed'));
    this.loadMediaplans();
  };

  removeMediaplan = async (versionId: number | null): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || mediaplanId === null) return;

    const response =
      versionId !== null
        ? await services.api.webMediaplans.deleteVersion({
            campaignId,
            mediaplanId,
            mediaplanVersionId: versionId,
          })
        : await services.api.webMediaplans.delete({ campaignId, mediaplanId });
    if (response.error) return;

    toast.success(localize('campaign-view.mediaplan.removed'));

    runInAction(() => {
      if (versionId === null || this.currentMediaplan?.files.length === 1) {
        this._currentMediaplanId = null;
      }
    });

    this.loadMediaplans();
    this.loadMediaplansCount();
  };

  uploadMediaplan = async (formData: FormData, name: string): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, services } = this;
    const { campaignId, uploadedStatus } = this.deps.campaignViewStore;

    if (campaignId === null) return;

    const response =
      uploadedStatus === 'version'
        ? await services.api.webMediaplans.uploadVersion({
            campaignId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            mediaplanId: mediaplanId!,
            formData,
          })
        : await services.api.webMediaplans.upload({ campaignId, mediaplanName: name, formData });
    if (response.error) return;

    toast.success(localizeMessage({ id: 'messages.file-added' }, { fileName: name }));

    runInAction(() => {
      this._currentMediaplanId = response.data.mediaplanId;
      this.deps.campaignViewStore.setUploadedStatus(null);
    });

    this.loadMediaplans();
  };

  /* Fixation data */

  @action
  setFixationPeriod = (period: Date | null): void => {
    this._fixationPeriod = period;
  };

  handlePeriodFixate = async (): Promise<void> => {
    const { _currentMediaplanId: mediaplanId, factFixationInfo, services } = this;
    const { campaignId } = this.deps.campaignViewStore;

    if (campaignId === null || mediaplanId === null) return;

    const isConfirmed = await showConfirmation({
      title: localize('campaign-view.mediafact.modal.confirm-fix-data-title'),
      text: localize('campaign-view.mediafact.modal.confirm-fix-data-text'),
    });

    if (!isConfirmed) return;

    if (this.fixationPeriod !== null) {
      const period = formatDateToStr(this.fixationPeriod);
      await services.api.mediafact.fixFact({ fixationPeriod: period, mediaplanId, campaignId });
    } else if (factFixationInfo?.lastSuccessfulFixationPeriod) {
      await services.api.mediafact.unfixFact({ mediaplanId });
    }

    this.loadMediaplans();
  };
}
