import HT from 'handsontable';
import { getChangedCellsInRow } from './getChangedCellsInRow';

export function apllyCellStyleForChangedRows(
  instance: HT,
  TD: HTMLTableCellElement,
  rowIndex: number,
  columnName: string,
): void {
  const changedCellsInRow = getChangedCellsInRow(instance, rowIndex);
  if (changedCellsInRow.includes(columnName)) {
    HT.dom.addClass(TD, 'cell-changed');
  } else if (changedCellsInRow.length) {
    HT.dom.addClass(TD, 'row-changed');
  } else {
    HT.dom.removeClass(TD, ['cell-changed', 'row-changed']);
  }
}
