export function encodeUrlParams(url: string): string {
  let decodedUrl;

  try {
    decodedUrl = decodeURIComponent(url);
  } catch {
    decodedUrl = url;
  }

  const [baseUrl, queryString] = decodedUrl.split('?');

  if (!queryString) {
    return decodedUrl;
  }

  const params = new URLSearchParams(queryString);

  return `${baseUrl}?${params.toString()}`;
}
