import React from 'react';
import cx from 'classnames';
import { toCurrency } from 'helpers';
import { Badge, BadgeSeverityType } from 'primereact/badge';
import classes from './FactBudgetLimitCell.module.scss';

type Props = {
  value: number | null;
  severity: BadgeSeverityType | null;
};

export const FactBudgetLimitCell: React.VFC<Props> = ({ value, severity }) => {
  if (value === null) {
    return <div className={classes.dash}>—</div>;
  }

  return (
    <div className={cx(classes.root, { [classes.withBadge]: Boolean(severity) })}>
      {severity !== null && <Badge className={classes.badge} severity={severity} />}
      <span>{toCurrency(value)}</span>
    </div>
  );
};
