import { millisecondsInMinute } from 'date-fns';
import { CustomOptions } from 'api/types';
import { FileS3IdData } from 'types/file';
import {
  ITvMediaplan,
  IServerCreative,
  IUpdatedCreative,
  ICountMediaplan,
  IVersion,
  IFactFile,
  TvTerritoryType,
  DatePeriod,
} from 'types/mediaplan';
import BaseApi from '../BaseApi';

class TvMediaplans extends BaseApi {
  list = ({ campaignId }: Pick<MediaplanData, 'campaignId'>, config: CustomOptions = {}) =>
    this.actions.get<ITvMediaplan[]>({
      url: '/mediaplan/tv/campaign-mediaplan',
      data: { campaignId },
      config,
    });

  getCount = ({ campaignId }: Pick<MediaplanData, 'campaignId'>, config: CustomOptions = {}) =>
    this.actions.get<ICountMediaplan>({
      url: '/mediaplan/tv/get-number-of-mediaplans',
      data: { campaignId },
      config,
    });

  getCreatives = ({ campaignId, mediaplanVersionId }: MediaplanVersionData, config: CustomOptions = {}) =>
    this.actions.get<IServerCreative[]>({
      url: '/mediaplan/tv/mediaplan-creatives',
      data: { campaignId, mediaplanVersionId },
      config,
    });

  updateCreatives = (
    { campaignId, mediaplanId, mediaplanVersionId, data }: UpdateCreativesData,
    config: CustomOptions = {},
  ) =>
    this.actions.post<IServerCreative[]>({
      url: '/mediaplan/tv/update-creative',
      queryParams: { campaignId, mediaplanId, mediaplanVersionId },
      data,
      config,
    });

  delete = ({ campaignId, mediaplanId }: MediaplanData, config: CustomOptions = {}) =>
    this.actions.delete({
      url: '/mediaplan/tv/delete-mediaplan',
      queryParams: { campaignId, mediaplanId },
      config,
    });

  deleteVersion = ({ campaignId, mediaplanVersionId }: MediaplanVersionData, config: CustomOptions = {}) =>
    this.actions.delete({
      url: '/mediaplan/tv/delete-mediaplan-version',
      queryParams: { campaignId, mediaplanVersionId },
      config,
    });

  rename = ({ campaignId, mediaplanId, newName }: RenameMediaplanData, config: CustomOptions = {}) =>
    this.actions.post<ITvMediaplan>({
      url: '/mediaplan/tv/rename-mediaplan',
      queryParams: { campaignId, mediaplanId },
      data: newName,
      config,
    });

  changeStatus = (
    { campaignId, mediaplanId, mediaplanVersionId, newStatus }: ChangeStatusData,
    config: CustomOptions = {},
  ) =>
    this.actions.put<IServerCreative>({
      url: '/mediaplan/tv/set-mediaplan-status',
      queryParams: { campaignId, mediaplanId, mediaplanVersionId },
      data: newStatus,
      config,
    });

  changeAudience = (
    { campaignId, mediaplanId, mediaplanVersionId, productId, audienceId }: ChangeAudienceData,
    config: CustomOptions = {},
  ) =>
    this.actions.patch<IServerCreative>({
      url: '/mediaplan/tv/product-audience-version-set',
      queryParams: { campaignId, mediaplanId, mediaplanVersionId, productId, audienceId },
      config,
    });

  changeRetroperiod = (
    { campaignId, mediaplanId, mediaplanVersionId, productId, retroFrom, retroTo }: ChangeRetroperiodData,
    config: CustomOptions = {},
  ) =>
    this.actions.patch<IServerCreative>({
      url: '/mediaplan/tv/product-retro-set',
      queryParams: { campaignId, mediaplanId, mediaplanVersionId, productId, retroFrom, retroTo },
      config,
    });

  upload = (
    { audienceId, campaignId, retroFrom, retroTo, mediaType, file }: UploadMediaplanData,
    config: CustomOptions = {},
  ) =>
    this.actions.post<ITvMediaplan>({
      url: '/mediaplan/tv/upload-mediaplan',
      queryParams: { audienceId, campaignId, retroFrom, retroTo, mediaType },
      data: file,
      config,
    });

  uploadVersion = (
    { audienceId, campaignId, mediaplanId, retroFrom, retroTo, file }: UploadMediaplanVersionData,
    config: CustomOptions = {},
  ) =>
    this.actions.post<ITvMediaplan>({
      url: '/mediaplan/tv/upload-mediaplan-version',
      queryParams: { audienceId, campaignId, mediaplanId, retroFrom, retroTo },
      data: file,
      config,
    });

  getFactFiles = ({ campaignId }: Pick<MediaplanData, 'campaignId'>, config: CustomOptions = {}) =>
    this.actions.get<IFactFile[]>({
      url: '/mediaplan/tv/fact/version/byCampaign',
      data: { campaignId },
      config,
    });

  uploadFactFile = ({ campaignId, formData }: UploadFactFileData, config: CustomOptions = {}) =>
    this.actions.post<IFactFile>({
      url: '/mediaplan/tv/fact/upload-new-version',
      queryParams: { campaignId },
      data: formData,
      config,
    });

  downloadFactData = ({ campaignId }: Pick<MediaplanData, 'campaignId'>, config: CustomOptions = {}) =>
    this.actions.get<FileS3IdData>({
      url: '/mediaplan/tv/fact/download/raw-data',
      data: { campaignId },
      config: { timeout: 6 * millisecondsInMinute, ...config },
    });

  downloadUserChangedData = ({ campaignId }: Pick<MediaplanData, 'campaignId'>, config: CustomOptions = {}) =>
    this.actions.get<FileS3IdData>({
      url: '/mediaplan/tv/fact/download/user-changed-data',
      data: { campaignId },
      config,
    });

  deleteFactFile = ({ fileId }: DeleteFactFileData, config: CustomOptions = {}) =>
    this.actions.delete<IFactFile>({
      url: '/mediaplan/tv/fact/version',
      queryParams: { id: fileId },
      config,
    });

  changeStatusFactFile = ({ versionId, campaignId, newStatus }: ChangeStatusFactFileData, config: CustomOptions = {}) =>
    this.actions.patch<IFactFile>({
      url: '/mediaplan/tv/fact/version/set-status',
      queryParams: { campaignId, versionId },
      data: { newStatus },
      config,
    });

  getDefaultRetroperiod = ({ campaignId, territoryType }: GetDefaultRetroperiodData, config: CustomOptions = {}) =>
    this.actions.get<DatePeriod>({
      url: '/mediaplan/tv/retro/default',
      data: { campaignId, mediaType: territoryType },
      config,
    });

  getLastAvailableRetroDate = (
    { territoryType }: Pick<GetDefaultRetroperiodData, 'territoryType'>,
    config: CustomOptions = {},
  ) =>
    this.actions.get<string>({
      url: '/mediaplan/tv/retro/last-available-date',
      data: { mediaType: territoryType },
      config,
    });

  getPlanTemplate = ({ territoryType }: Pick<GetDefaultRetroperiodData, 'territoryType'>, config: CustomOptions = {}) =>
    this.actions.get<FileS3IdData>({
      url: `/tv/template/${territoryType.toLowerCase()}`,
      config,
    });

  uploadPlanTemplate = ({ territoryType, formData }: UploadPlanTemplateData, config: CustomOptions = {}) =>
    this.actions.post({
      url: `/tv/template/${territoryType.toLowerCase()}`,
      data: formData,
      config,
    });
}

type MediaplanData = {
  campaignId: number;
  mediaplanId: number;
};

type MediaplanVersionData = {
  campaignId: number;
  mediaplanVersionId: number;
};

type UpdateCreativesData = MediaplanData & {
  mediaplanVersionId: number;
  data: IUpdatedCreative[];
};

type RenameMediaplanData = MediaplanData & { newName: string };

type ChangeStatusData = MediaplanData & {
  mediaplanVersionId: number;
  newStatus: IVersion['status'];
};

type ChangeAudienceData = MediaplanData & {
  mediaplanVersionId: number;
  productId: number;
  audienceId: number;
};

type ChangeRetroperiodData = Omit<ChangeAudienceData, 'audienceId'> & {
  retroFrom: string;
  retroTo: string;
};

type UploadMediaplanData = {
  audienceId: number;
  campaignId: number;
  retroFrom: string;
  retroTo: string;
  mediaType: TvTerritoryType;
  file: FormData;
};

type UploadMediaplanVersionData = Omit<UploadMediaplanData, 'mediaType'> & {
  mediaplanId: number;
};

type UploadFactFileData = {
  campaignId: number;
  formData: FormData;
};

type DeleteFactFileData = { fileId: IFactFile['id'] };

type ChangeStatusFactFileData = {
  versionId: IFactFile['id'];
  campaignId: IFactFile['campaignId'];
  newStatus: IFactFile['status'];
};

type GetDefaultRetroperiodData = {
  territoryType: TvTerritoryType;
  campaignId: number;
};

type UploadPlanTemplateData = {
  territoryType: TvTerritoryType;
  formData: FormData;
};

export default TvMediaplans;
