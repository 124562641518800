import React from 'react';
import cx from 'classnames';
import { localizeCampaignStatus } from 'helpers';
import { CampaignStatus } from 'types/campaign';
import classes from './CampaignStatusLabel.module.scss';

type Props = {
  className?: string;
  value: CampaignStatus;
};

export const CampaignStatusLabel: React.FC<Props> = (props) => {
  const { className, value } = props;
  const hasCheckIcon = [CampaignStatus.COMPLETED, CampaignStatus.CONFIRMED].includes(value);
  return (
    <span className={cx(classes.root, className, { [classes[`${value.toLowerCase()}Status`]]: value })}>
      {localizeCampaignStatus(value)}
      {hasCheckIcon && <i className={cx(classes.icon, 'icon icon-check')} />}
    </span>
  );
};

export type { Props as StatusLabelProps };
