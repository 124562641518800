import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import hasAccess, { Page } from 'helpers/hasAccess';
import { IAuthFormValues } from './helpers/validation';
import AuthMarkup from './Auth';

export const Auth = observer(() => {
  const history = useHistory();
  const location = useLocation<{ from?: { pathname: string } }>();
  const form = useRef<HTMLFormElement>(null);

  const { authStore } = useStores();
  const { isAuthorized, isAuthorizing, logIn, checkAuth } = authStore;

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    const stateFrom = location.state?.from;

    const prevPagePath = stateFrom?.pathname;
    const prevPageName =
      prevPagePath?.match(/\/advertiser\/\d+\/(?<page>\w+)/)?.groups?.page ??
      prevPagePath?.match(/^\/(?<page>[^/]+)/)?.groups?.page;
    const featureName = prevPageName?.replace(/-./g, (x) => x.toUpperCase()[1]);

    const cameFromAnotherPage = prevPagePath && prevPagePath !== '/app/auth';
    const hasAccessToPreviousPage = featureName && hasAccess(featureName as Page, 'pages');

    const from =
      cameFromAnotherPage && hasAccessToPreviousPage
        ? stateFrom || ''
        : {
            pathname: '/app',
            search: '',
            hash: '',
          };

    if (isAuthorized) {
      history.replace(from);
    }
  }, [location, history, isAuthorized]);

  const onSubmit = useCallback(
    (values: IAuthFormValues) => {
      const { username, password } = values;
      logIn(username, password);
    },
    [logIn],
  );

  return <AuthMarkup ref={form} onSubmit={onSubmit} isAuthorizing={isAuthorizing} isAuthorized={isAuthorized} />;
});
