import React from 'react';
import { action } from 'mobx';

import { BaseStore } from 'shared/stores';
import { AccountStore } from './Account.store';

type Deps = {
  accountStore: AccountStore;
};

export class AccountViewStore extends BaseStore<Deps> {
  @action
  handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.target as HTMLInputElement;
    this.deps.accountStore.mapFieldToErrors[name] = undefined;
    this.deps.accountStore.mapFieldToValue[name] = value;
  };

  @action
  handleInputBlur = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.target as HTMLInputElement;
    this.deps.accountStore.mapFieldToValue[name] = value.trim();
  };

  @action
  handleSelectCabinet = (value: number): void => {
    this.deps.accountStore.cabinetId = value;
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<boolean> => {
    e.preventDefault();

    return this.deps.accountStore.submit();
  };

  handleGetToken = async (): Promise<void> => {
    const { accountId, getToken } = this.deps.accountStore;
    if (!accountId) return;

    await getToken(accountId);
  };
}
