import { useRef } from 'react';
import { Nullable } from 'types';

export const useDebounce = (delay = 300): [React.MutableRefObject<Nullable<NodeJS.Timeout>>, () => void] => {
  const canUpdate = useRef<Nullable<NodeJS.Timeout>>(null);

  const start = () => {
    canUpdate.current = setTimeout(() => {
      canUpdate.current = null;
    }, delay);
  };

  return [canUpdate, start];
};
