import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';

import { convertToOptions } from 'helpers/convertToOptions';
import hasAccess from 'helpers/hasAccess';
import showConfirmation from 'helpers/showConfirmation';
import { convertDateToStr, convertToDate } from 'helpers/utils';
import { localize, localizeMessage } from 'shared/components/other';
import { EmptyObject, IOption } from 'types';
import { CreationSource, ICampaign, ICampaignMetric, CampaignStatus, MediaOption, MediaType } from 'types/campaign';
import { FileS3Id } from 'types/file';
import { LocaleIdType } from 'locales';
import { toast } from 'shared/components/common/misc/Toast';
import { BaseStore } from 'shared/stores';
import { CampaignAutoCreateStore } from './CampaignAutoCreate.store';
import { convertMetrics, isWebOrTvMediaType } from '../helpers';

export type FormValues = {
  name: string | null;
  description: string | null;
  status: CampaignStatus | null;
  startDate: Date | null;
  endDate: Date | null;
  agency: number | null;
  brand: number | null;
  subbrand: number | null;
  category: number | null;
  advertiser: number | null;
  accessibleToClient: boolean;
  hideBudgetScale: boolean;
};

export type FormData = FormValues & {
  metrics: Record<string, number>;
  budget: null;
};

type SavedMediaplanData = {
  s3FileId: NonNullable<FileS3Id>;
  name: string;
};

type ChildrenStores = {
  autoCreate: CampaignAutoCreateStore;
};

export class CampaignEditStore extends BaseStore<EmptyObject, ChildrenStores> {
  @observable
  agencies: IOption[] = [];

  @observable
  brands: IOption[] = [];

  @observable
  subbrands: (IOption & { brandId: number })[] = [];

  @observable
  categories: IOption[] = [];

  @observable
  mediaTypes: MediaOption[] = [];

  @observable
  formValues: FormValues | null = null;

  @observable
  currentBrand: number | null = null;

  @observable
  metrics: MediaOption[] = [];

  @observable
  campaignId: number | null = null;

  @observable
  campaign: ICampaign | null = null;

  @observable
  isFavorite = false;

  @observable
  private _savedMediaplanData: SavedMediaplanData | null = null;

  @observable
  private _currentMediaplansCount = {
    [MediaType.WEB]: 0,
    [MediaType.TV]: 0,
  };

  @computed
  get remainingMediaTypes(): MediaOption[] {
    return this.mediaTypes.filter(
      ({ type, value }) => !this.metrics.find((metric) => metric.value === value) && !this.hasCurrentMediaplans(type),
    );
  }

  @computed
  get savedMediaplanName(): string | null {
    return this._savedMediaplanData?.name ?? null;
  }

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      autoCreate: new CampaignAutoCreateStore({ campaignEditStore: this }),
    };

    reaction(
      () => this.deps.advertiserStore.advertisers.length,
      (length) => {
        if (!length) return;
        this.loadData();
      },
    );

    reaction(() => this.currentBrand, this.loadSubbrandList);
  }

  @action
  cleanUp = (): void => {
    this.campaign = null;
    this.formValues = null;
    this._currentMediaplansCount = {
      [MediaType.WEB]: 0,
      [MediaType.TV]: 0,
    };
    this.setCurrentBrand(null);
    this.setMetrics([]);
    this.setSavedMediaplanData(null);
    this.childrenStores.autoCreate.cleanUp();
  };

  @action
  setCurrentBrand = (brandId: number | null): void => {
    this.currentBrand = brandId;
  };

  @action
  setSavedMediaplanData = (data: SavedMediaplanData | null): void => {
    this._savedMediaplanData = data;
  };

  @action
  setMetrics = (metrics: MediaOption[]): void => {
    this.metrics = metrics;
  };

  loadData = async (): Promise<void> => {
    await this.loadCurrentCampaign();
    const { campaign } = this;

    if (!this.checkCampaignAccess(campaign?.advertiser)) {
      return;
    }

    campaign?.mediaTypes.forEach((type) => {
      this.loadCurrentMediaplansCount(type);
    });

    await this.loadGeneralData();

    if (campaign === null) {
      return;
    }

    runInAction(() => {
      this.metrics = convertMetrics(this.mediaTypes, campaign.campaignMetrics);
      this.formValues = {
        name: campaign.name,
        description: campaign.description,
        status: campaign.campaignStatus,
        startDate: convertToDate(campaign.dateStart),
        endDate: convertToDate(campaign.dateEnd),
        agency: campaign.agency,
        brand: campaign.brands[0],
        subbrand: campaign.subbrands[0],
        category: campaign.categories[0],
        advertiser: campaign.advertiser,
        accessibleToClient: campaign.accessibleToClient,
        hideBudgetScale: campaign.hideBudgetScale,
      };
      this.isFavorite = campaign.favorite;
      [this.currentBrand] = campaign.brands;
    });
  };

  private async loadCurrentCampaign(): Promise<void> {
    const { campaignId } = this;
    if (campaignId === null) return;

    const response = await this.services.api.campaigns.get({ campaignId });
    runInAction(() => {
      this.campaign = response.error ? null : response.data;
    });

    if (response.error) {
      this.deps.routingStore.history.push('/campaigns');
    }
  }

  hasCurrentMediaplans = (mediaType?: MediaType): boolean => {
    if (!mediaType) {
      return Object.values(this._currentMediaplansCount).some((count) => count > 0);
    }
    return isWebOrTvMediaType(mediaType) ? this._currentMediaplansCount[mediaType] > 0 : false;
  };

  private getDefaultSubbrandId = async (): Promise<number | null> => {
    const { brands, subbrands, currentBrand } = this;

    const brand = brands.find((b) => b.value === currentBrand);

    if (brand === undefined) {
      return null;
    }

    const isConfirmed = await showConfirmation({
      title: `${localizeMessage({ id: 'campaign-edit.modal.save-default-subbrand' }, { subbrandName: brand.label })}?`,
      confirmTextId: 'button.save',
    });

    if (!isConfirmed) {
      return null;
    }

    const defaultSubbrand = subbrands.find((subbrand) => subbrand.label === brand.label);

    if (defaultSubbrand === undefined) {
      const response = await this.services.api.subbrands.save({ name: brand.label, brandId: brand.value });
      return response.error ? null : response.data.id;
    }

    return defaultSubbrand.value;
  };

  handleSubmit = async (data: FormData): Promise<number | null> => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    const subbrandId = data.subbrand || (await this.getDefaultSubbrandId());

    if (
      advertiserId === null ||
      subbrandId === null ||
      data.agency === null ||
      data.brand === null ||
      data.category === null ||
      data.status === null ||
      data.name === null
    ) {
      return null;
    }

    const campaignMetrics: Omit<ICampaignMetric, 'campaign' | 'id'>[] = this.metrics.map((metric) => ({
      value: Number(data.metrics?.[metric.label]) || 0,
      metricMediaType: metric.value,
    }));

    const args = {
      advertiser: advertiserId,
      agency: data.agency,
      brands: [data.brand],
      subbrands: [subbrandId],
      campaignMetrics,
      campaignStatus: data.status,
      categories: [data.category],
      dateStart: convertDateToStr(data.startDate),
      dateEnd: convertDateToStr(data.endDate),
      name: data.name,
      description: data.description || null,
      id: this.campaignId || null,
      accessibleToClient: data.accessibleToClient,
      hideBudgetScale: data.hideBudgetScale,
      creationSource: this._savedMediaplanData !== null ? CreationSource.WEB : CreationSource.PORTAL,
      favorite: this.isFavorite,
    };

    const response = await this.services.api.campaigns.save(args);
    if (response.error) {
      return null;
    }

    if (this._savedMediaplanData !== null) {
      await this.services.api.webMediaplans.uploadExistingMediaplan({
        campaignId: response.data.id,
        fileS3Id: this._savedMediaplanData.s3FileId,
        mediaplanName: this._savedMediaplanData.name,
      });
    }

    return response.data.id;
  };

  removeCampaign = async (): Promise<boolean> => {
    if (this.campaignId === null) {
      return false;
    }

    const response = await this.services.api.campaigns.remove({ campaignId: this.campaignId });
    if (response.error) {
      return false;
    }

    return true;
  };

  addSubbrand = async (value: string, brandId: number | null): Promise<number | null> => {
    if (!brandId) {
      toast.error(localize('campaign-edit.modal.failed-adding-subbrand'));
      return null;
    }

    const response = await this.services.api.subbrands.save({ name: value, brandId });
    if (response.error) {
      return null;
    }

    this.loadSubbrandList(brandId);
    return response.data.id;
  };

  addCategory = async (value: string): Promise<number | null> => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (advertiserId === null) {
      toast.error(localize('campaign-edit.modal.failed-adding-category'));
      return null;
    }

    const response = await this.services.api.categories.save({
      advertiser: advertiserId,
      name: value,
    });
    if (response.error) {
      return null;
    }

    this.loadCategoryList();
    return response.data.id;
  };

  async loadGeneralData(): Promise<void> {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (advertiserId === null) return;

    if (this.currentBrand) {
      this.loadSubbrandList(this.currentBrand);
    }

    const { api } = this.services;
    const [agencies, brands, categories, mediaTypes] = await Promise.all([
      api.agencies.list(), // do not depend on the advertiser
      api.brands.list({ customerId: advertiserId }),
      api.categories.list({ customerId: advertiserId }),
      api.mediaTypes.list(), // do not depend on the advertiser
    ]);
    if (agencies.error || brands.error || categories.error || mediaTypes.error) {
      return;
    }

    // added additional filtering by web and tv
    const convertedMediaTypes = mediaTypes.data.content
      .filter((m) => hasAccess(m.mediaType, 'medias') && [MediaType.WEB, MediaType.TV].includes(m.mediaType))
      .map((m) => ({
        value: m.id,
        label: localize(`mediaTypes.${m.mediaType.toLowerCase()}` as LocaleIdType),
        type: m.mediaType,
        budgetValue: null,
      }));

    runInAction(() => {
      this.agencies = convertToOptions(agencies.data.content);
      this.brands = convertToOptions(brands.data.content);
      this.categories = convertToOptions(categories.data.content);
      this.mediaTypes = convertedMediaTypes;
    });
  }

  loadSubbrandList = async (brandId: number | null): Promise<void> => {
    if (brandId === null) return;

    const response = await this.services.api.subbrands.list({ brandId });
    if (response.error) return;

    const subbrands = response.data.content.map((s) => ({
      value: s.id,
      label: s.name,
      brandId: s.brand,
    }));

    runInAction(() => {
      this.subbrands = subbrands;
    });
  };

  private loadCategoryList = async (): Promise<void> => {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (advertiserId === null) return;

    const response = await this.services.api.categories.list({ customerId: advertiserId });
    if (response.error) return;

    runInAction(() => {
      this.categories = convertToOptions(response.data.content);
    });
  };

  private checkCampaignAccess(campaignAdvertiserId?: number): boolean {
    const { currentAdvertiserId: advertiserId, advertisers, selectAdvertiser } = this.deps.advertiserStore;

    if (!campaignAdvertiserId) {
      return true;
    }

    if (advertiserId !== campaignAdvertiserId) {
      const foundAdvertiser = advertisers.find((a) => a.id === campaignAdvertiserId);

      if (!foundAdvertiser) {
        return false;
      }

      if (selectAdvertiser) {
        selectAdvertiser(foundAdvertiser.id, true);
      }
    }

    return true;
  }

  @action
  private async loadCurrentMediaplansCount(mediaType: MediaType): Promise<void> {
    if (this.campaignId === null || !isWebOrTvMediaType(mediaType)) return;

    const { api } = this.services;
    const getCount = mediaType === MediaType.WEB ? api.webMediaplans.getCount : api.tvMediaplans.getCount;
    const response = await getCount({ campaignId: this.campaignId });
    if (response.error) return;

    runInAction(() => {
      this._currentMediaplansCount[mediaType] = response.data.countCurrent;
    });
  }
}
