import { CustomOptions, PageData } from 'api/types';
import { IPagingServerResponse } from 'types';
import { ISubbrand } from 'types/subbrand';
import BaseApi from '../BaseApi';

class Subbrands extends BaseApi {
  list = ({ brandId, pageSize = 10000 }: GetSubbrandListData, config: CustomOptions = {}) =>
    this.actions.get<IPagingServerResponse<ISubbrand[]>>({
      url: '/subbrand/',
      data: { brandId, pageSize },
      config,
    });

  save = ({ name, brandId }: SaveSubbrandData, config: CustomOptions = {}) =>
    this.actions.post<ISubbrand>({
      url: '/subbrand/create-as-user',
      queryParams: { name, brandId },
      config,
    });

  updateName = ({ name, subbrandId }: UpdateNameData, config: CustomOptions = {}) =>
    this.actions.post<ISubbrand>({
      url: '/subbrand/update-name',
      queryParams: { name, subbrandId },
      config,
    });

  delete = ({ subbrandId }: DeleteSubbrandData, config: CustomOptions = {}) =>
    this.actions.delete({
      url: '/subbrand/delete',
      queryParams: { id: subbrandId },
      config,
    });
}

type GetSubbrandListData = PageData & { brandId: number };

type SaveSubbrandData = {
  name: string;
  brandId: number;
};

type UpdateNameData = {
  name: string;
  subbrandId: number;
};

type DeleteSubbrandData = { subbrandId: number };

export default Subbrands;
