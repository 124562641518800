import React from 'react';
import { Row, RowRendererProps } from 'react-data-grid';
import { GridRow, isGridTitleRow } from '..';
import classes from '../DataGrid.module.scss';

export function makeRowRenderer(gridGap: number, filterRowHeight: number) {
  return function rowRenderer<DR>(args: RowRendererProps<GridRow<DR>>): React.ReactElement {
    return (
      <Row
        {...args}
        row={args.row}
        selectCell={() => null}
        top={filterRowHeight + (isGridTitleRow(args.row) ? args.top : args.top - gridGap)}
        height={isGridTitleRow(args.row) ? args.height - gridGap : args.height}
        rowClass={(row) => {
          if (isGridTitleRow(row)) {
            return classes.titleRow;
          } else if (row.isLastExpandedChild) {
            return classes.lastRowInGroup;
          }

          return '';
        }}
      />
    );
  };
}
