import React, { useState } from 'react';
import { useBoolean, useMount, useUpdateEffect } from 'ahooks';
import { DataGridHandle } from 'react-data-grid';
import { DataGridContext } from './DataGridContext';
import { DataGridProps, DataGridFilters, DataGridSorting } from '../types';

type Props<DR> = Pick<
  DataGridProps<DR>,
  'gridRef' | 'initialFilters' | 'onFilterChange' | 'initialSorting' | 'onSortingChange' | 'applyFilters'
> &
  Required<Pick<DataGridProps<DR>, 'toggleRowSelection' | 'toggleRowExpanding'>> & {
    children: React.ReactNode;
    nativeGridRef: React.RefObject<DataGridHandle>;
  };

export const DataGridProvider = <DR,>(props: Props<DR>): React.ReactElement => {
  const {
    children,
    gridRef,
    nativeGridRef,
    initialFilters = {},
    initialSorting = null,
    onFilterChange,
    onSortingChange,
    toggleRowSelection,
    toggleRowExpanding,
    applyFilters,
  } = props;

  const [isModalVisible, { setTrue: openModalWindow, setFalse: closeModal }] = useBoolean(false);

  const [modalHeader, setModalHeader] = useState<React.ReactNode>();
  const [modalContent, setModalContent] = useState<React.ReactNode>();
  const openModal = (content: React.ReactNode, header?: React.ReactNode) => {
    setModalHeader(header);
    setModalContent(content);
    openModalWindow();
  };

  const [filters, setGridFilters] = useState<DataGridFilters>(initialFilters);
  const setFilters = (newFilters: DataGridFilters): void => {
    setGridFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };
  const clearFilters = (): void => {
    setGridFilters({});
  };

  const [sorting, setGridSorting] = useState<DataGridSorting>(initialSorting);
  const setSorting = (sortingOptions: DataGridSorting): void => {
    setGridSorting(sortingOptions);
  };

  useUpdateEffect(() => {
    onFilterChange?.(filters);
  }, [filters, onFilterChange]);

  useUpdateEffect(() => {
    onSortingChange?.(sorting);
  }, [sorting, onSortingChange]);

  useUpdateEffect(() => {
    if (!gridRef?.current) return;
    gridRef.current.filters = filters;
  }, [filters]);

  useMount(() => {
    if (!gridRef) return;
    gridRef.current = {
      clearFilters,
      filters,
      nativeGridRef,
    };
  });

  return (
    <DataGridContext.Provider
      value={{
        isModalVisible,
        modalHeader,
        modalContent,
        openModal,
        closeModal,
        toggleRowSelection,
        toggleRowExpanding,
        setFilters,
        setSorting,
        clearFilters,
        applyFilters,
        filters,
        sorting,
      }}
    >
      {children}
    </DataGridContext.Provider>
  );
};
