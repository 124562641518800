import { CustomOptions, PageData } from 'api/types';
import { IPagingServerResponse } from 'types';
import { IBrand, IServerBrandStatistics, IBrandMediaTypeBudget } from 'types/brand';
import { ICampaign } from 'types/campaign';
import BaseApi from '../BaseApi';

const currentYear = new Date().getFullYear();

class Brands extends BaseApi {
  list = ({ customerId, brandName, page = 0, pageSize = 10000 }: GetBrandListData, config: CustomOptions = {}) =>
    this.actions.get<IPagingServerResponse<IBrand[]>>({
      url: '/brand/',
      data: { customerId, brandName, page, pageSize },
      config,
    });

  get = ({ brandId }: BrandData, config: CustomOptions = {}) =>
    this.actions.get<IBrand>({
      url: '/brand/find-by-id',
      data: { brandId },
      config,
    });

  getBrandsStatistics = (
    { customerId, year = currentYear, brandName, pageSize = 10000 }: GetBrandsStatisticsData,
    config: CustomOptions = {},
  ) =>
    this.actions.get<IServerBrandStatistics[]>({
      url: '/brand/brands-statistics',
      data: { customerId, year, brandName, pageSize },
      config,
    });

  getBrandCampaigns = (
    { customerId, brandId, year = currentYear }: GetBrandCampaignsData,
    config: CustomOptions = {},
  ) =>
    this.actions.get<ICampaign[]>({
      url: '/brand/brand-flowchart',
      data: { customerId, brandId, year },
      config,
    });

  getMediaTypeBudgets = (
    { brandId, year = currentYear }: Omit<GetBrandCampaignsData, 'customerId'>,
    config: CustomOptions = {},
  ) =>
    this.actions.get<IBrandMediaTypeBudget[]>({
      url: '/brand/mediatypes-budget-data',
      data: { brandId, year },
      config,
    });

  updateLogo = ({ brandId, formData }: UpdateLogoData, config: CustomOptions = {}) =>
    this.actions.post<IBrand>({
      url: '/brand/update-logo',
      queryParams: { brandId },
      data: formData,
      config,
    });

  removeLogo = ({ brandId }: BrandData, config: CustomOptions = {}) =>
    this.actions.delete<IBrand>({
      url: '/brand/remove-logo',
      queryParams: { brandId },
      config,
    });

  updateColor = ({ brandId, color }: UpdateColorData, config: CustomOptions = {}) =>
    this.actions.post<IBrand>({
      url: '/brand/update-color',
      queryParams: { brandId, color },
      config,
    });
}

type BrandData = { brandId: number };

type GetBrandListData = PageData & {
  customerId: number;
  brandName?: string;
};

type GetBrandsStatisticsData = PageData & {
  customerId: number;
  year?: number;
  brandName?: string;
};

type GetBrandCampaignsData = {
  customerId: number;
  brandId: number;
  year?: number;
};

type UpdateLogoData = {
  brandId: number;
  formData: FormData;
};

type UpdateColorData = {
  brandId: number;
  color: string;
};

export default Brands;
