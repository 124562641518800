import { FormatterProps } from 'react-data-grid';
import React, { CSSProperties } from 'react';
import { Column, GridRow, isGridDataRow, isGridTitleRow } from '..';
import { TitleCell } from '../components';
import classes from '../DataGrid.module.scss';
import { getRowTitle } from '../helpers';

const INDENT = 15;

export function formatterWrapper<DR>(
  options: {
    withTitleRow?: boolean;
    hasIndent?: boolean;
    textAlign?: CSSProperties['textAlign'];
  },
  ...formatters: (React.ComponentType<FormatterProps<GridRow<DR>>> | undefined | null)[]
): React.ComponentType<FormatterProps<GridRow<DR>>> {
  const { withTitleRow, hasIndent, textAlign } = options;

  return (args: FormatterProps<GridRow<DR>>): React.ReactElement | null => {
    if (isGridDataRow(args.row)) {
      return (
        <div
          style={{
            paddingLeft: hasIndent && args.row.depth ? args.row.depth * INDENT : undefined,
            textAlign: hasIndent ? 'left' : textAlign,
          }}
          className={classes.cellWrapper}
        >
          {formatters.map((Formatter, i) => (Formatter ? <Formatter {...args} key={i} /> : null))}
        </div>
      );
    }
    if (withTitleRow && isGridTitleRow(args.row)) {
      const titleRowTemplates = (args.column as typeof args.column & Pick<Column<DR>, 'titleRowTemplates'>)
        ?.titleRowTemplates;
      const title = getRowTitle(args.row, titleRowTemplates);

      return <TitleCell title={title} />;
    }

    return null;
  };
}
