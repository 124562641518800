import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { LocaleAdministrationId } from 'locales';
import { LocalizedMessage } from 'shared/components/other';
import hasAccess from 'helpers/hasAccess';

import cx from 'classnames';
import { adminTabToAccessFeatureMap as accessFeatureMap } from '../..';
import classes from './Sidebar.module.scss';

const Sidebar: FC = () => (
  <>
    <input type="checkbox" id="sidebar-toggle" className={classes.Toggle} defaultChecked />
    <aside className={classes.Container}>
      <label htmlFor="sidebar-toggle" className={cx(classes.ToggleButton, 'icon icon-menu')} />
      <header className={classes.Header}>
        <LocalizedMessage id="header.settings.administration" />
      </header>

      <ul className={classes.Menu}>
        {Array.from(accessFeatureMap.entries()).map(([tabName, feature]) =>
          hasAccess(feature, 'pages') ? (
            <li className={classes.MenuItem} key={tabName}>
              <NavLink
                to={`/administration/${tabName}`}
                className={classes.MenuLink}
                activeClassName={classes.MenuLinkActive}
                data-test={`administration-item-${tabName}`}
              >
                {tabName.includes('powerbi') && (
                  <>
                    <LocalizedMessage id="administration.powerbi-links" />
                    :
                    <br />
                  </>
                )}
                <LocalizedMessage id={`administration.${tabName}` as LocaleAdministrationId} />
              </NavLink>
            </li>
          ) : null,
        )}
      </ul>
    </aside>
  </>
);

export default Sidebar;
