import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { init as initSentry } from '@sentry/react';

import App from 'core/App';
import config from 'config';
import { StoreContext, stores } from 'core';
import * as serviceWorker from './serviceWorker';
import browserHistory from './browserHistory';
import 'shared/components/common/localization';
import './styles/index.scss';

initSentry({ dsn: config.services.sentryDsn });

ReactDOM.render(
  <Router history={browserHistory}>
    <StoreContext.Provider value={stores}>
      <App />
    </StoreContext.Provider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
