import React, { FC } from 'react';
import { Controller, Control } from 'react-hook-form';
import { format } from 'date-fns';

import { Calendar } from 'shared/components/common/form/Calendar';
import { Input } from 'shared/components/other';
import { DisabledFieldIcon } from '../DisabledFieldIcon';

import classes from './ControlledDatePicker.module.scss';

type Props = {
  name: 'startDate' | 'endDate';
  defaultValue: Date | null;
  control: Control;
  required: boolean;
  invalid: boolean;
  disabled: boolean;
  minDate?: Date;
  maxDate?: Date;
};

const ControlledDatePicker: FC<Props> = ({
  name,
  defaultValue,
  control,
  required,
  invalid,
  disabled,
  minDate,
  maxDate,
}: Props) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={{ validate: (date) => required && date !== null }}
    render={({ value, onChange }) =>
      disabled ? (
        <Input
          value={value ? format(value, 'dd.LL.yyyy') : ''}
          hasError={invalid}
          disabled
          icon={<DisabledFieldIcon />}
          data-test={name}
        />
      ) : (
        <Calendar
          value={value}
          onChange={onChange}
          className={classes.calendar}
          theme="light"
          inputClassName={classes.calendarInput}
          inputId={name}
          clearButtonIcon="icon icon-reset"
          invalid={invalid}
          minDate={minDate}
          maxDate={maxDate}
        />
      )
    }
  />
);

export { ControlledDatePicker };
