import { parseISO } from 'date-fns';
import { camelToSnakeCase } from 'helpers/camelToSnakeCase';
import { IOption } from 'types';
import {
  AdditionalReportData,
  CellContent,
  Column,
  ColumnData,
  CustomExpression,
  DataRow,
  LoadReportDataArgs,
  PivotFilter,
  PivotValue,
  PreparedReportData,
  RangeDateFilter,
  ReportData,
  ReportListResponse,
  ResponseListData,
  ServerReportData,
} from 'types/reportConstructor';

export const REPORT_PAGE_SIZE = 10_000;

export function convertToOptions(items: Record<string, string>): IOption[] {
  return Object.entries(items).map(([id, name]) => ({ value: +id, label: name }));
}

export function convertReportData(data: CellContent[][], fieldNames: ColumnData[]) {
  return {
    data: data.slice(0, REPORT_PAGE_SIZE).map((row) =>
      row.reduce<DataRow>((acc, value, index) => {
        const { column, action } = fieldNames[index];
        const convertedValue = value === null ? '' : value;

        if (action) {
          const targetColumn = acc[column];
          const colValue = { [action.toLowerCase()]: convertedValue };

          return {
            ...acc,
            [column]:
              typeof targetColumn === 'object' && targetColumn !== null ? { ...targetColumn, ...colValue } : colValue,
          };
        }

        return { ...acc, [column]: convertedValue };
      }, {}),
    ),
    hasNextPage: data.length > REPORT_PAGE_SIZE,
  };
}

export function prepareReportData({
  structure,
  reportFilters: { statisticDateStart, statisticDateEnd, reportType, cabinetIds },
  reportLevel,
  selectedColumns,
  fieldName,
}: LoadReportDataArgs) {
  const rowsCols = [...structure.rows, ...structure.columns];

  const columns = [...rowsCols.map((name) => ({ name })), ...structure.filters]
    .map(({ name: colName }) => {
      const filter = structure.filters.find(({ name }) => name === colName)?.value;
      const isFilterActive = !!filter && checkIsFilterActive(filter);
      const isTargetFieldName = fieldName === colName;
      const column: ColumnData = { column: colName, isVisible: !fieldName || isTargetFieldName };

      if (filter && isFilterActive && !isTargetFieldName) {
        const colType = selectedColumns.find(({ value }) => value === colName)?.valueType;
        column.filters = [
          {
            filterAction: filter.includes ? 'EQUAL' : camelToSnakeCase(filter.condition.type).toUpperCase(),
            value: convertFilterValues(filter.includes ?? [filter.condition.filter], colType),
          },
        ];
      }

      return column;
    })
    .filter((col, index, arr) => {
      const isDuplicateColumn = arr.findIndex(({ column }) => column === col.column) !== index;
      const isEmptyFilterColumn = !rowsCols.includes(col.column) && !col.filters && fieldName !== col.column;

      return !isDuplicateColumn && !isEmptyFilterColumn;
    });

  const aggregationColumns: ColumnData[] = structure.values
    .filter(({ operation }) => operation !== 'complex')
    .map((column) => ({
      column: Array.isArray(column.name) ? column.name.join(',') : column.name,
      action: column.operation === 'any' ? 'ANY_VALUE' : column.operation.toUpperCase(),
      isVisible: !fieldName,
    }));

  const complexValues = structure.values.filter(({ operation }) => operation === 'complex') as Required<PivotValue>[];

  const result: PreparedReportData = {
    statisticDateStart,
    statisticDateEnd,
    reportType,
    cabinetIds,
    reportLevel,
    columns,
    aggregationColumns,
  };

  if (complexValues.length) {
    const customExpressions: CustomExpression[] = complexValues.map(({ math }, index) => ({
      sortingParams: {
        direction: 'ASC',
        priority: index + 1,
      },
      expression: math.replaceAll('any(', 'any_value('),
    }));
    result.customExpressions = customExpressions;
  }

  return result;
}

export const convertFilterValues = (values: (CellContent | RangeDateFilter)[], type?: Column['valueType']) =>
  values
    .map((value) => {
      if (value === null) {
        return value;
      }
      if (type === 'STRING') {
        return value.toString();
      }
      if (type === 'DATE') {
        if (isDateRange(value)) {
          return [new Date(value.start).getTime(), new Date(value.end).getTime()];
        }
        return new Date(+value).getTime();
      }
      return +value;
    })
    .filter((value) => typeof value !== 'number' || !Number.isNaN(value));

const isDateRange = (date: CellContent | RangeDateFilter): date is Record<keyof RangeDateFilter, Date> =>
  typeof date === 'object' &&
  date !== null &&
  'start' in date &&
  'end' in date &&
  date.start instanceof Date &&
  date.end instanceof Date;

export const checkIsFilterActive = (filter: NonNullable<PivotFilter['value']>) => {
  const isDateFilterValid =
    !['between', 'notBetween'].includes(filter.condition.type) || isDateRange(filter.condition.filter);
  return (filter.includes !== null || filter.condition.filter !== '') && isDateFilterValid;
};

export const convertReportList = (data: ReportListResponse): ResponseListData => ({
  items: data.content.map(convertReport),
  totalPages: data.totalPages,
  totalElements: data.totalElements,
});

export const convertReport = ({
  statisticDateStart,
  statisticDateEnd,
  createdDate,
  modifiedDate,
  jsonData,
  ...data
}: ServerReportData): ReportData => {
  const additionalData: AdditionalReportData | undefined | null = jsonData && JSON.parse(jsonData);

  const report = {
    ...data,
    statisticDateStart: statisticDateStart ? parseISO(statisticDateStart) : null,
    statisticDateEnd: statisticDateEnd ? parseISO(statisticDateEnd) : null,
    createdDate: createdDate ? new Date(createdDate) : null,
    modifiedDate: modifiedDate ? new Date(modifiedDate) : null,
  };

  if (additionalData?.structure) {
    additionalData.structure.values = additionalData.structure.values.map((value) =>
      value.operation !== 'complex' ? value : { ...value, math: value.math?.replaceAll('any_value(', 'any(') },
    );
  }

  if (typeof additionalData === 'object' && additionalData !== null) {
    const { reportType, cabinetIds, columnIds, structure } = additionalData;

    return {
      ...report,
      reportType,
      cabinetIds,
      columnIds,
      structure,
    };
  }

  return report;
};
