import { RetryLink } from '@apollo/client/link/retry';
import api from 'api';
import { sharedStores } from 'shared/stores';

export const retryLink = new RetryLink({
  attempts: async (count, operation, error) => {
    const { getRefreshToken, logInByRefreshToken } = sharedStores.authStore;
    const isOverdueTokenError = error.statusCode === 401 && getRefreshToken() !== null;
    if (isOverdueTokenError) {
      await api.auth.reLogIn(logInByRefreshToken);
    }
    return isOverdueTokenError && count <= 1;
  },
});
