const roundToHundredths = (number: number) => Math.round(number * 100) / 100;

export const getNumberFormat = (value: unknown): string | unknown => {
  if (value === null) return '';
  const webixFormat = (v: unknown) =>
    webix.i18n.numberFormat(v, {
      groupDelimiter: ' ',
      groupSize: 3,
      decimalDelimiter: ',',
      decimalSize: 2,
      minusPosition: 'before',
      minusSign: '-',
    });
  const isInteger = (v: number) => v % 1 === 0;

  if (typeof value === 'string') return value;

  if (Array.isArray(value)) return value.map((v) => getNumberFormat(v));

  const roundedValue = roundToHundredths(Number(value));

  if (isInteger(roundedValue)) return roundedValue.toLocaleString();

  return webixFormat(roundedValue);
};
