import { action, computed, makeObservable, observable } from 'mobx';
import * as R from 'ramda';

import { EmptyObject } from 'types';
import { IPowerBIFilters, IPowerBIResponse, PowerBIPageName, ICommonPowerBIProps } from 'types/powerBI';
import { BaseStore } from 'shared/stores';
import { PowerBiDefaultSettingsStore } from './PowerBiDefaultSettings.store';
import { PowerBiAdvertiserSettingsStore } from './PowerBiAdvertiserSettings.store';

type ChildrenStores = {
  defaultSettings: PowerBiDefaultSettingsStore;
  advertiserSettings: PowerBiAdvertiserSettingsStore;
};

export class PowerBiStore extends BaseStore<EmptyObject, ChildrenStores> {
  @computed
  get pageNames(): PowerBIPageName[] {
    return R.keys(R.filter(Boolean)(this.powerBIPageNameToResponseMap));
  }

  @observable
  private powerBIPageNameToResponseMap: Partial<Record<PowerBIPageName, IPowerBIResponse | null>> = {};

  @observable
  private isLoadingMap: Partial<Record<PowerBIPageName, boolean>> = {};

  @action
  private setPowerBI = (response: IPowerBIResponse | null, pageName: PowerBIPageName): void => {
    this.stopIsLoading(pageName);
    this.powerBIPageNameToResponseMap[pageName] = response ?? null;
  };

  @action
  startIsLoading = (pageName: PowerBIPageName): void => {
    this.isLoadingMap[pageName] = true;
  };

  @action
  stopIsLoading = (pageName: PowerBIPageName): void => {
    this.isLoadingMap[pageName] = false;
  };

  getIsLoading = (pageName: PowerBIPageName): boolean => {
    return !!this.isLoadingMap[pageName];
  };

  getPowerBIResponse = (pageName: PowerBIPageName): IPowerBIResponse | null => {
    return this.powerBIPageNameToResponseMap[pageName] ?? null;
  };

  @action
  loadPowerBIReport = async ({
    advertiserId,
    filters,
    onError,
    withLoader = true,
    ...request
  }: LoadPowerBIReportArgs): Promise<void> => {
    try {
      this.startIsLoading(request.pageName);
      const isCustomReport = request.pageName === 'BRAND_LIST' && request.positionName === 'ADVERTISER_CUSTOM';

      if (isCustomReport) {
        const response = await this.services.api.powerbi.getCustomReportUrls(
          {
            ...request,
            customerId: advertiserId ?? undefined,
          },
          {
            withLoader,
            withErrorNotification: false,
          },
        );
        if (response.error) {
          throw response.error;
        }

        this.setPowerBI(
          {
            powerBIURL: response.data.powerBIURLList[0]?.powerBIURL,
          },
          request.pageName,
        );

        return;
      }

      const response =
        request.pageName === 'CLIENT_DASHBOARD'
          ? await this.services.api.powerbi.getHyundaiReportUrl(
              { ...request, ...(filters || {}) },
              { withErrorNotification: false },
            )
          : await this.services.api.powerbi.getReportUrl(
              {
                ...request,
                customerId: advertiserId ?? undefined,
              },
              { withLoader, withErrorNotification: false },
            );
      if (response.error) {
        throw response.error;
      }

      this.setPowerBI(response.data, request.pageName);
    } catch {
      this.setPowerBI(null, request.pageName);
      if (onError) {
        onError();
      }
    }
  };

  @action
  cleanUp = (pageName: PowerBIPageName): void => {
    if (!['COMPETITOR_ANALYSIS', 'PRG_BENCHMARK', 'CABINET_STATUS'].includes(pageName)) {
      delete this.powerBIPageNameToResponseMap[pageName];
    }
  };

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      defaultSettings: new PowerBiDefaultSettingsStore({ powerBiStore: this }),
      advertiserSettings: new PowerBiAdvertiserSettingsStore({ powerBiStore: this }),
    };
  }
}

type LoadPowerBIReportArgs = {
  advertiserId: number | null;
  filters?: IPowerBIFilters;
  onError?: () => void;
  withLoader?: boolean;
} & ICommonPowerBIProps;
