import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from 'core';
import { Sidebar as PrimeSidebar } from 'primereact/sidebar';
import classes from './Sidebar.module.scss';

export const Sidebar = observer(() => {
  const {
    sidebarStore: { isVisible, hide, content },
  } = useStores();

  const location = useLocation();

  useEffect(hide, [hide, location.pathname]);

  return (
    <PrimeSidebar className={classes.root} visible={isVisible} position="right" dismissable={false} onHide={hide}>
      {content}
    </PrimeSidebar>
  );
});
