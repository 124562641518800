import React, { useState } from 'react';

import { Tooltip } from 'primereact/tooltip';
import classes from './TooltipProvider.module.scss';

export const TooltipProvider: React.FC = ({ children }) => {
  const [target, setTarget] = useState<HTMLElement>();

  const handleMouseOver = (e: React.MouseEvent) => {
    if (!(e.target instanceof HTMLElement)) return;
    const tooltipTarget = e.target.closest('[data-pr-tooltip]');
    if (!(tooltipTarget instanceof HTMLElement)) return;
    setTarget(tooltipTarget);
  };

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div className={classes.root} onMouseOver={handleMouseOver}>
      <Tooltip target={target} position="top" className={classes.tooltip} />
      {children}
    </div>
  );
};
