import { FileS3Id } from './file';

export interface IStatisticFile {
  fileS3Id: FileS3Id;
  fileName: string;
  cabinetId: number | null;
  statisticsSource: string;
  userCreateName: string;
  timeCreate: string;
  mediafactManualFileId: number;
  status: Status | null;
}

export interface ITemplateFile {
  fileS3Id: FileS3Id;
}

export enum Status {
  LOADED = 'LOADED',
  START_PROCESS = 'START_PROCESS',
  ERROR = 'ERROR',
}

export interface IFilters {
  cabinets: string[];
  name: string;
  selectDate: (Date | null)[];
}

export interface ISortSettings {
  sortBy: keyof IStatisticFile;
  orderIsAsc: boolean;
  count: number;
}

export interface IFileProp {
  name: keyof IStatisticFile;
  isSortable: boolean;
}
