import React, { useCallback } from 'react';
import cx from 'classnames';
import { Button } from 'shared/components/common/buttons/Button';
import { ColumnFilter } from '../../../types';
import { useDataGrid } from '../../../context';

import classes from './HeaderCell.module.scss';

type Props = {
  value: string | React.ReactElement;
  columnKey?: string;
  filter?: ColumnFilter;
  isSortable?: boolean;
};

export const HeaderCell: React.VFC<Props> = (props) => {
  const { value, columnKey, isSortable } = props;
  const { sorting, setSorting } = useDataGrid();
  const sortingName = columnKey ?? null;

  const handleSortingButtonClick = useCallback(() => {
    if (sortingName === null) {
      return;
    }

    if (sorting === null || sorting.field !== sortingName) {
      setSorting({ field: sortingName, direction: 'ASC' });
    } else if (sorting.direction === 'ASC') {
      setSorting({ field: sortingName, direction: 'DESC' });
    } else {
      setSorting(null);
    }
  }, [sortingName, sorting, setSorting]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>{value}</div>
        {isSortable && (
          <Button
            className={cx(classes.sortingButton, {
              [classes.sortingButtonActive]: sorting?.field === sortingName,
            })}
            icon={cx('pi', {
              'pi-sort-alt': sorting?.field !== sortingName,
              'pi-sort-amount-up-alt': sorting?.field === sortingName && sorting?.direction === 'ASC',
              'pi-sort-amount-down': sorting?.field === sortingName && sorting?.direction === 'DESC',
            })}
            onClick={handleSortingButtonClick}
          />
        )}
      </div>
    </div>
  );
};
