import React from 'react';
import { Badge, BadgeSeverityType } from 'primereact/badge';
import { baseCellWrapper } from 'shared/components/other';
import { toPercent } from 'helpers';

import classes from './UnitPriceCell.module.scss';

type Props = {
  value: number;
};

const UnitPriceCellComponent: React.VFC<Props> = ({ value }) => {
  let severity: BadgeSeverityType | undefined;
  if (value >= 0 && value < 1.2) {
    severity = 'success';
  }
  if (value >= 1.2 && value < 1.4) {
    severity = 'warning';
  }
  if (value >= 1.4) {
    severity = 'danger';
  }

  return (
    <div className={classes.root}>
      <Badge className={classes.badge} severity={severity} />
      <span>{toPercent(value)}</span>
    </div>
  );
};

export const UnitPriceCell = baseCellWrapper(UnitPriceCellComponent);
