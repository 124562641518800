import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { useStores } from 'core';
import { DataGrid, DataGridFilters, GridRef } from 'shared/components/other';
import { LogFilters } from 'types/cabinetCopying';

import { convertGridFiltersToApiFilters, getColumns, FILTER_ROW_HEIGHT, defaultSorting } from '../../../../helpers/log';

type Props = {
  gridRef: React.RefObject<GridRef['current']>;
  className?: string;
};

export const LogTable: React.FC<Props> = observer(({ gridRef, className }) => {
  const { cabinetCopyingStore: store } = useStores();
  const { data, setFilters, setSorting } = store.childrenStores.log;

  const handleFilterChange = useCallback(
    (filters: DataGridFilters<keyof LogFilters>) => {
      setFilters(convertGridFiltersToApiFilters(filters));
    },
    [setFilters],
  );

  return (
    <DataGrid
      className={className}
      gridRef={gridRef}
      filterRowHeight={FILTER_ROW_HEIGHT}
      rows={data}
      columns={getColumns(store.options.cabinet.items)}
      onFilterChange={handleFilterChange}
      onSortingChange={setSorting}
      enableVirtualization={false}
      initialSorting={defaultSorting}
    />
  );
});
