import React, { VFC } from 'react';
import cx from 'classnames';

import classes from './Input.module.scss';

interface IProps {
  type?: 'text' | 'number' | 'email';
  theme?: 'round' | '';
  minLength?: number;
  maxLength?: number;
  max?: number;
  value?: string | number;
  id?: string;
  name?: string;
  'data-test'?: string;
  hasError?: boolean;
  noBorder?: boolean;
  autoComplete?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  icon?: JSX.Element;
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: VFC<IProps> = (props) => {
  const {
    value,
    inputRef,
    hasError,
    noBorder,
    icon,
    theme = '',
    type = 'text',
    className,
    containerClassName,
    ...restProps
  } = props;

  return (
    <div className={cx(classes.root, containerClassName)}>
      <input
        ref={inputRef}
        type={type}
        value={value || ''}
        className={cx(classes.Input, className, {
          [classes.InputRound]: theme === 'round',
          [classes.WithError]: hasError,
          [classes.NoBorder]: noBorder,
          [classes.InputDisabled]: restProps.disabled,
        })}
        {...restProps}
      />
      {icon}
    </div>
  );
};

export default Input;
