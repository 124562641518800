import React, { ChangeEvent, useState, VFC } from 'react';
import cx from 'classnames';
import { useDebounceFn, useUpdateEffect } from 'ahooks';
import { InputText, InputTextProps } from 'primereact/inputtext';
import { defaultTooltipOptions } from '../../defaultProps';
import classes from './SearchInput.module.scss';

type Props = Omit<InputTextProps, 'size' | 'onChange'> & {
  /** Auto-apply params. Define automatic triggering of on change event handler */
  autoApplyParams?: AutoApplyParams;
  /** Default value */
  defaultValue?: string;
  /** On change event handler */
  onChange(value: string | null, e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>): void;
  /** On clear event handler */
  onClear?(e: React.MouseEvent<HTMLElement>): void;
  /** Search icon class name. Default: 'pi pi-search' */
  searchIcon?: string;
  /** Size of the component. Default: 'medium' */
  size?: 'xsmall' | 'small' | 'medium';
  /** Specifies if the component should stretch to full width. Default: false */
  tabular?: boolean;
  /** Component color theme */
  theme?: 'light' | 'dark' | 'grey';
};

type AutoApplyParams = {
  minLength?: number;
  wait: number;
};

export const SearchInput: VFC<Props> = (props) => {
  const {
    autoApplyParams,
    className,
    defaultValue,
    onClear,
    onChange,
    searchIcon = 'pi pi-search',
    size = 'medium',
    tabular = false,
    theme = 'light',
    tooltipOptions,
    ...inputTextProps
  } = props;

  const [value, setValue] = useState<string>(defaultValue ?? '');

  const isValueEqualToFilterValue = (defaultValue ?? '') === (value ?? '');

  const debounceSearch = useDebounceFn(() => onChange(value || null), { wait: autoApplyParams?.wait });

  useUpdateEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  useUpdateEffect(() => {
    const { wait, minLength = 0 } = autoApplyParams ?? {};
    if (!wait) return;
    if (value.length >= minLength || value.length === 0) {
      debounceSearch.run();
    } else {
      debounceSearch.cancel();
    }
  }, [value]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSearchIconClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isValueEqualToFilterValue) return;
    onChange(value || null, e);
  };

  const handleClearIconClick = (e: React.MouseEvent<HTMLElement>) => {
    if (defaultValue) {
      onClear?.(e);
    } else {
      setValue('');
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onChange(value || null, event);
    }
  };

  return (
    <div
      className={cx(classes.root, className, {
        [classes.isActive]: Boolean(defaultValue),
        [classes.isTabular]: tabular,
        [classes.isValueEqualToFilterValue]: isValueEqualToFilterValue,
        [classes[`${size}Size`]]: !!size,
        [classes[`${theme}Theme`]]: !!theme,
      })}
    >
      <div className={classes.icons}>
        {(value || defaultValue) && (
          <i className={cx(classes.clearIcon, 'pi pi-times')} onClick={handleClearIconClick} />
        )}
        <i
          className={cx(classes.searchIcon, `${searchIcon}`)}
          onClick={handleSearchIconClick}
          style={{
            fontWeight: searchIcon?.includes('filter') ? 'normal' : undefined,
          }}
        />
      </div>
      <InputText
        className={classes.input}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        tooltipOptions={{ ...defaultTooltipOptions, ...tooltipOptions }}
        value={value}
        {...inputTextProps}
      />
    </div>
  );
};

export type { Props as SearchInputProps };
