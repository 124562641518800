import { AggregatedHeader, TreeData, TreeDataItem } from 'types/reportConstructor';
import { ValueData } from './helpers';

export type AddToHeaderFuncParams = {
  header: AggregatedHeader;
  valuesData: ValueData[];
  columns?: string[];
  columnAmount?: number;
};

export const buildHeader = (params: AddToHeaderFuncParams): void => {
  const { columnAmount, columns, header, valuesData } = params;
  if (!columns || !columnAmount) {
    valuesData.forEach(({ title, operation, color }) => {
      header.nonEmpty.push(header.nonEmpty[header.nonEmpty.length - 1] + 1);
      header.data[header.data.length - 1].push(title);
      header.meta.push({ operation, color });
    });
    return;
  }

  if (!columns.length) return;

  const currentIndex = columnAmount - columns.length;

  valuesData.forEach(({ title, operation, color }) => {
    const originalColumn = header.data[currentIndex].find(
      (item) => typeof item === 'object' && item?.text === columns[0],
    );

    header.nonEmpty.push(header.nonEmpty[header.nonEmpty.length - 1] + 1);

    header.data[currentIndex].push({ text: columns[0], colspan: 1 });
    header.data[header.data.length - 1].push(title);

    header.meta.push({ operation, color });

    if (typeof originalColumn === 'object' && originalColumn?.colspan) {
      originalColumn.colspan += 1;
    }
  });

  columns.shift();

  buildHeader({ header, columns, valuesData, columnAmount });
};

export const buildTreeAndReturnData = (
  tree: TreeData,
  rows: string[],
  currentId: number,
): { link: TreeDataItem<TreeData>; lastId: number; length: number } => {
  const hasValue = rows.length === 1;
  const findFunc = (item: TreeDataItem<TreeData>) => item.values[0] === rows[0];

  let currentBrunch = tree.find(findFunc);
  const id = hasValue ? currentId + 1 : 0;
  if (!currentBrunch) {
    tree.push({ id, data: [], values: [rows[0]] });
    currentBrunch = tree.find(findFunc);
  }

  rows.shift();

  // there is now chance to have no currentBrunch
  if (!currentBrunch) return { link: { id: 0, data: [], values: [] }, length: 0, lastId: id };

  if (rows.length) {
    return buildTreeAndReturnData(currentBrunch.data, rows, currentId);
  } else {
    return { link: currentBrunch, lastId: id, length: currentBrunch.values.length };
  }
};
