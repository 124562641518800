import { action, makeObservable, observable, runInAction } from 'mobx';
import { EmptyObject } from 'types';
import { AccountUserStatus, CabinetStatus, IAccount, ICabinet, ISelectedAccount } from 'types/account';
import { BaseStore } from 'shared/stores';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { AccountsViewStore } from './AccountsView.store';

type ChildrenStores = {
  view: AccountsViewStore;
};

export class AccountsStore extends BaseStore<EmptyObject, ChildrenStores> {
  @observable
  dataList: IAccount[] = [];

  @observable
  selectedAccounts: ISelectedAccount[] = [];

  @observable
  cabinets: ICabinet[] | null = null;

  @observable
  cabinetStatus: CabinetStatus | null = null;

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      view: new AccountsViewStore({ accountsStore: this }),
    };
  }

  static isEqualSelectedAccounts = (account1: ISelectedAccount, account2: ISelectedAccount): boolean =>
    account1.accountId === account2.accountId && account1.cabinetId === account2.cabinetId;

  @action
  loadAllData = async (): Promise<void> => {
    await Promise.all([this.loadCabinetsData(), this.loadData()]);
  };

  @action
  private loadCabinetsData = async (): Promise<void> => {
    const response = await this.services.api.accounts.cabinetsList();
    if (response.error) return;

    runInAction(() => {
      this.cabinets = response.data;
    });
  };

  @action
  loadCabinetStatus = async (): Promise<void> => {
    const response = await this.services.api.accounts.cabinetStatus();
    if (response.error) return;

    runInAction(() => {
      this.cabinetStatus = response.data;
    });
  };

  @action
  loadData = async (): Promise<void> => {
    const response = await this.services.api.accounts.list();
    if (response.error) return;

    runInAction(() => {
      this.dataList = response.data.filter((account: IAccount) => !account.isDeleted);
    });
  };

  @action
  updateStatusAccount = async (account: IAccount, status: AccountUserStatus): Promise<void> => {
    const fields = { ...account, userStatus: status };
    const response = await this.services.api.accounts.updateAccount({
      accountId: account.id,
      cabinetId: account.cabinetId,
      data: fields,
    });
    if (response.error) return;

    runInAction(() => {
      this.dataList = [...this.dataList.filter((x) => x.id !== account.id), response.data];
    });
    toast.success(localize('account.notify.account-status-updated'));
  };
}
