import Cookies from 'js-cookie';

const rootDomain = window.location.hostname.split('.').slice(-2).join('.');

export type AuthCookieKey = 'clientspacetoken' | 'clientspacerefreshtoken';

export const setRootDomainCookies = (name: AuthCookieKey, value: string): void => {
  const currentYear = new Date().getFullYear();
  Cookies.set(name, value, {
    domain: rootDomain,
    expires: new Date(String(currentYear + 1)),
  });
};

export const removeRootDomainCookies = (name: AuthCookieKey): void => {
  Cookies.remove(name, { domain: rootDomain });
};
