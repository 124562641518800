import React, { useCallback } from 'react';
import cx from 'classnames';
import { Button } from 'shared/components/common/buttons/Button';
import { DateFilter, TextFilter, MultiSelectFilter } from '../../filters';
import { ColumnFilter } from '../../../types';
import { isDateFilter } from '../../../typeGuards';
import { useDataGrid } from '../../../context';

import classes from './FilterCell.module.scss';

type Props = {
  columnName: string | React.ReactElement;
  columnKey?: string;
  filter?: ColumnFilter;
};

export const FilterCell: React.VFC<Props> = React.memo((props) => {
  const { columnName, columnKey, filter } = props;
  const { filters, openModal, applyFilters } = useDataGrid();
  const filterName = filter?.name ?? columnKey;

  const isFilterActive = Boolean(filterName && filters[filterName]);

  const setFilters = useCallback(() => {
    if (isDateFilter(filter) && filterName) {
      openModal(<DateFilter name={filterName} hasTime={filter.hasTime} />, columnName);
    }
  }, [filter, filterName, openModal, columnName]);

  const handleFilterButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (applyFilters) {
        applyFilters(setFilters, e.currentTarget);
      } else {
        setFilters();
      }
    },
    [applyFilters, setFilters],
  );

  const renderContent = useCallback(() => {
    if (filterName === undefined || filter === undefined) {
      return null;
    }

    if (filter.type === 'text') {
      return <TextFilter name={filterName} />;
    }

    if (filter.type === 'multiselect') {
      return <MultiSelectFilter name={filterName} options={filter.options} />;
    }

    return (
      <div className={classes.filterButtonWrapper}>
        <Button
          className={cx('p-button-rounded p-button-text p-button-plain', classes.filterButton, {
            [classes.isActive]: isFilterActive,
          })}
          icon={`pi ${isFilterActive ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={handleFilterButtonClick}
        />
      </div>
    );
  }, [filter, filterName, handleFilterButtonClick, isFilterActive]);

  return <div className={classes.root}>{renderContent()}</div>;
});
