import React, { VFC } from 'react';
import { observer } from 'mobx-react';
import { isBefore, startOfToday } from 'date-fns';
import cx from 'classnames';
import { InputSwitch } from 'primereact/inputswitch';

import { useStores } from 'core';
import { LocalizedMessage } from 'shared/components/other';
import { DatePicker } from '../DatePicker';

import classes from './DialogContent.module.scss';

export const DialogContent: VFC = observer(() => {
  const { excessStore } = useStores();
  const { currentRowCheckSettings, changeRowCheckSetting: changeSetting } = excessStore.childrenStores.checkSettings;

  const makeChangeExpiresDate = (settingId: number) => (date: Date | null) => {
    changeSetting(settingId, 'expires', date);
  };

  return (
    <div className={classes.root}>
      <div className={cx(classes.setting, classes.settingHeader)}>
        <span>
          <LocalizedMessage id="programmatic.excess-table.settings.setting-name" />
        </span>
        <span>
          <LocalizedMessage id="programmatic.excess-table.settings.validity-period" />
        </span>
      </div>
      {currentRowCheckSettings.map(({ id, isActive, expires, name, readOnly, expirationLimit }) => {
        const isEditable = !readOnly && isActive;
        const isDateInvalid = expires ? isBefore(expires, startOfToday()) : false;

        return (
          <div key={id} className={cx(classes.setting, { [classes.settingInvalid]: isDateInvalid })}>
            <div className={classes.label}>
              <InputSwitch
                checked={isActive}
                disabled={readOnly}
                onChange={(e) => changeSetting(id, 'isActive', e.value)}
              />
              {name}
            </div>
            <DatePicker
              expires={expires}
              expirationLimit={expirationLimit}
              disabled={!isEditable}
              invalid={isDateInvalid}
              onChange={makeChangeExpiresDate(id)}
            />
          </div>
        );
      })}
    </div>
  );
});
