import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from 'core';
import { confirmPopup } from 'primereact/confirmpopup';
import { LocalizedMessage, localize, localizeMessage } from 'shared/components/other';
import { Button, buttonClasses } from 'shared/components/common/buttons/Button';
import hasAccess from 'helpers/hasAccess';
import { ActiveTab } from 'types/programmatic';
import { LocaleIdType } from 'locales';

import { ShrinkingButton } from 'shared/components/common/buttons/ShrinkingButton';
import { useResize } from 'shared/hooks';
import classes from './Actions.module.scss';

const SCREEN_SIZE_EDGE = 1340;

export const Actions = observer(() => {
  const {
    excessStore: {
      hasSelectedRows,
      hasPartiallySelectedMediaplanRows,
      dataIsOutdated,
      setActiveTab,
      selectPartiallySelectedMediaplanRows,
      startSelectedPlacements,
      stopSelectedPlacements,
    },
  } = useStores();

  const [isLargeScreen] = useResize(SCREEN_SIZE_EDGE);

  const handleLogButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    openSidebar(event, ActiveTab.LOG);
  };

  const handleStatisticsButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    openSidebar(event, ActiveTab.STATISTICS);
  };

  const openSidebar = (event: React.MouseEvent<HTMLElement>, tab: ActiveTab) => {
    if (hasPartiallySelectedMediaplanRows) {
      openConfirmPopup({
        target: event.currentTarget,
        message: localizeMessage(
          { id: 'programmatic.control-panel.all-placements-will-be-selected-automatically' },
          {
            tabName: localize(
              `programmatic.control-panel.tabs.${tab === ActiveTab.LOG ? 'log' : 'statistics'}` as LocaleIdType,
            ),
          },
        ),
        onAccept: () => {
          selectPartiallySelectedMediaplanRows();
          setActiveTab(tab);
        },
      });
    } else {
      setActiveTab(tab);
    }
  };

  const handleStartButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (hasSelectedRows) {
      openConfirmPopup({
        target: e.currentTarget,
        message: localize('programmatic.control-panel.all-stopped-placements-in-selected-rows-will-be-started'),
        onAccept: startSelectedPlacements,
      });
    } else {
      startSelectedPlacements();
    }
  };

  const handleStopButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (hasSelectedRows) {
      openConfirmPopup({
        target: e.currentTarget,
        message: localize('programmatic.control-panel.all-started-placements-in-selected-rows-will-be-stopped'),
        onAccept: stopSelectedPlacements,
      });
    } else {
      stopSelectedPlacements();
    }
  };

  return (
    <div className={classes.root}>
      {dataIsOutdated && (
        <section className={classes.reloadPageSection}>
          <p>
            <LocalizedMessage id="programmatic.control-panel.data-may-be-outdated" />
          </p>
          <Button className="p-button-secondary" label="Обновить" onClick={() => window.location.reload()} />
        </section>
      )}
      <div className={classes.actions}>
        {hasAccess('startStopCampaign', 'programmaticPage') && (
          <Button
            className={cx('p-button-rounded', 'p-button-success')}
            icon="pi pi-play"
            tooltip={localize('programmatic.control-panel.start-stopped-placements-in-selected-rows')}
            onClick={handleStartButtonClick}
            disabled={!hasSelectedRows}
          />
        )}
        {hasAccess('startStopCampaign', 'programmaticPage') && (
          <Button
            className={cx('p-button-rounded', 'p-button-danger')}
            icon="pi pi-stop"
            tooltip={localize('programmatic.control-panel.stop-started-placements-in-selected-rows')}
            onClick={handleStopButtonClick}
            disabled={!hasSelectedRows}
          />
        )}
        <ShrinkingButton
          shrunk={!isLargeScreen}
          className="p-button-secondary"
          icon="pi pi-list"
          onClick={handleLogButtonClick}
          label={localize('programmatic.control-panel.tabs.log')}
        />
        <ShrinkingButton
          shrunk={!isLargeScreen}
          className="p-button-secondary"
          icon="pi pi-chart-line"
          onClick={handleStatisticsButtonClick}
          label={localize('programmatic.control-panel.tabs.statistics')}
        />
      </div>
    </div>
  );
});

const openConfirmPopup = (args: { target: HTMLElement; message: string; onAccept(): void }) => {
  const { target, message, onAccept } = args;
  confirmPopup({
    target,
    message,
    className: classes.confirmPopup,
    icon: 'pi pi-info-circle',
    acceptLabel: localize('ok'),
    rejectLabel: localize('cancel'),
    acceptClassName: buttonClasses.root,
    rejectClassName: cx(buttonClasses.root, buttonClasses.lightTheme),
    accept: onAccept,
  });
};
