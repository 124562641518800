import React from 'react';
import cx from 'classnames';
import { useCountdown } from './helpers/useCountdown';
import { Props } from './Countdown.types';
import classes from './Countdown.module.scss';

export const Countdown: React.VFC<Props> = (props) => {
  const {
    children = ({ remainingTime }) => remainingTime,
    className,
    strokeLinecap,
    trailColor,
    trailStrokeWidth,
  } = props;
  const { path, pathLength, stroke, strokeDashoffset, remainingTime, elapsedTime, size, strokeWidth } = useCountdown({
    ...props,
  });

  return (
    <div className={cx(classes.root, className)} style={{ width: size, height: size }}>
      <svg width={size} height={size}>
        <path d={path} fill="none" stroke={trailColor ?? '#d9d9d9'} strokeWidth={trailStrokeWidth ?? strokeWidth} />
        <path
          d={path}
          fill="none"
          stroke={stroke}
          strokeDasharray={pathLength}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap={strokeLinecap ?? 'square'}
          strokeWidth={strokeWidth}
        />
      </svg>
      {typeof children === 'function' && (
        <div className={classes.time}>{children({ remainingTime, elapsedTime, color: stroke })}</div>
      )}
    </div>
  );
};
