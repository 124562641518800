import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { Select } from 'shared/components/common/form/Select';
import { IOption } from 'types';
import { IFileStatus } from 'types/mediaplan';
import classes from './FileStatusSelect.module.scss';

type Props = {
  /** Additional className */
  className?: string;
  id?: number | string;
  onChange(value: IOption<IFileStatus>): void;
  theme?: 'raised' | 'outlined';
  value?: IFileStatus;
  size?: 'small' | 'medium' | 'large';
};

export const FileStatusSelect: React.VFC<Props> = (props) => {
  const { className, id, onChange, size, theme = 'raised', value } = props;

  const statusOptions: IOption<IFileStatus>[] = useMemo(
    () =>
      Object.values(IFileStatus).map((x) => ({
        value: x,
        label: localize(`campaign.file.statuses.${x.toLowerCase()}` as `campaign.file.statuses.${Lowercase<typeof x>}`),
      })),
    [],
  );

  const handleChange = useCallback(
    (val: IFileStatus, option?: IOption<IFileStatus>) => {
      if (option) onChange(option);
    },
    [onChange],
  );

  return (
    <Select
      className={cx(classes.root, className, {
        [classes[`${value?.toLowerCase()}Status`]]: value,
        [classes[`${theme}Theme`]]: !!theme,
      })}
      id={`file_status_${id}`}
      onChange={handleChange}
      options={statusOptions}
      filter={false}
      size={size}
      tabular
      value={value}
    />
  );
};

export type { Props as FileStatusSelectProps };
