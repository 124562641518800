import { useRef } from 'react';
import { useElapsedTime } from 'shared/hooks';
import { UseCountdown } from '../Countdown.types';

type PathProps = { path: string; pathLength: number };

const getPathProps = (size: number, strokeWidth: number, rotation: 'clockwise' | 'counterclockwise'): PathProps => {
  const halfSize = size / 2;
  const halfStrokeWith = strokeWidth / 2;
  const arcRadius = halfSize - halfStrokeWith;
  const arcDiameter = 2 * arcRadius;
  const rotationIndicator = rotation === 'clockwise' ? '1,0' : '0,1';
  const pathLength = 2 * Math.PI * arcRadius;
  const path =
    `m ${halfSize},${halfStrokeWith} ` +
    `a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,${arcDiameter} ` +
    `a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,-${arcDiameter}`;
  return { path, pathLength };
};

const getStartAt = (duration: number, initialRemainingTime?: number): number => {
  if (duration === 0 || duration === initialRemainingTime) return 0;
  return typeof initialRemainingTime === 'number' ? duration - initialRemainingTime : 0;
};

const linearEase = (time: number, start: number, goal: number, duration: number) => {
  if (duration === 0) return start;
  const currentTime = time / duration;
  return start + goal * currentTime;
};

export const useCountdown: UseCountdown = (props) => {
  const {
    duration,
    initialRemainingTime,
    isPlaying = false,
    onComplete,
    onUpdate,
    rotation = 'clockwise',
    size = 40,
    strokeColor,
    strokeWidth = 2,
    trailStrokeWidth,
    updateInterval,
  } = props;
  const remainingTimeRef = useRef<number>();
  const maxStrokeWidth = Math.max(strokeWidth, trailStrokeWidth ?? 0);
  const { path, pathLength } = getPathProps(size, maxStrokeWidth, rotation);

  const { elapsedTime } = useElapsedTime({
    isPlaying,
    duration,
    startAt: getStartAt(duration, initialRemainingTime),
    updateInterval,
    onUpdate:
      typeof onUpdate === 'function'
        ? (elTime: number) => {
            const remainingTime = Math.ceil(duration - elTime);
            if (remainingTime !== remainingTimeRef.current) {
              remainingTimeRef.current = remainingTime;
              onUpdate(remainingTime);
            }
          }
        : undefined,
    onComplete:
      typeof onComplete === 'function'
        ? (totalElapsedTime: number) => {
            const { shouldRepeat, delay, newInitialRemainingTime } = onComplete(totalElapsedTime) ?? {};
            if (shouldRepeat) {
              return {
                shouldRepeat,
                delay,
                newStartAt: getStartAt(duration, newInitialRemainingTime),
              };
            }
            return undefined;
          }
        : undefined,
  });

  const remainingTimeRow = duration - elapsedTime;

  return {
    elapsedTime,
    path,
    pathLength,
    remainingTime: Math.ceil(remainingTimeRow),
    rotation,
    size,
    stroke: strokeColor ?? '#000',
    strokeDashoffset: linearEase(elapsedTime, 0, pathLength, duration),
    strokeWidth,
  };
};
