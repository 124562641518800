import React, { useRef, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';

import { useStores } from 'core';
import { LocalizedTitle } from 'shared/components/other';
import { Table, ControlPanel } from './components';
import classes from '../AdFox.module.scss';

const AdFoxBanners = observer(() => {
  const {
    adFoxBannersStore,
    adFoxBannersStore: { campaignId, loadData, columns, rows, defaultRows, updateRows, initTable },
  } = useStores();

  const params = useParams<{ campaignId: string }>();

  useEffect(() => {
    if (params.campaignId) {
      adFoxBannersStore.campaignId = Number(params.campaignId);
    }
  }, [adFoxBannersStore, params]);

  useEffect(() => {
    if (campaignId) {
      loadData();
    }
  }, [campaignId, loadData]);

  const tableRef = useRef<HTMLDivElement>(null);
  const headerTemplate = useCallback(
    (options: PanelHeaderTemplateOptions) =>
      tableRef.current ? ReactDOM.createPortal(options.togglerElement, tableRef.current) : null,
    [tableRef],
  );

  return (
    <div className={classes.root}>
      <LocalizedTitle id="site.title.tradingDesk" />
      <Panel
        className={classes.panel}
        collapseIcon="pi pi-chevron-up"
        expandIcon="pi pi-chevron-down"
        headerTemplate={headerTemplate}
      >
        <ControlPanel />
      </Panel>
      <div className={classes.table} ref={tableRef}>
        <Table
          banners={rows}
          defaultBanners={defaultRows}
          columns={columns}
          onChange={updateRows}
          initTable={initTable}
        />
      </div>
    </div>
  );
});

export { AdFoxBanners };
