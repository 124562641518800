import React, { useEffect, VFC } from 'react';
import { Switch, Route, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { History } from 'history';
import { useMount, useUpdateEffect } from 'ahooks';

import { useStores } from 'core';
import config from 'config';
import { PrivateRoute } from 'shared/components/domain/PrivateRoute';

import { Brands } from './Brands';
import { BrandView } from './BrandView';
import { Campaigns } from './Campaigns';
import { CampaignView } from './CampaignView';
import { CampaignEdit } from './CampaignEdit';
import { Accounts } from './Accounts';
import { Account } from './Account';
import { CabinetStatus } from './CabinetStatus';
import { Administration } from './Administration';
import { UploadStatistics } from './UploadStatistics';
import { PRGBenchmarks } from './PRGBenchmarks';
import { CompetitorAnalysis } from './CompetitorAnalysis';
import { Excess as Programmatic } from './Programmatic';
import { ReportConstructor, ReportList } from './reportConstructor';
import { AdFoxBanners, AdFoxCampaigns } from './tradingDesk';
import { CabinetCopying } from './CabinetCopying';
import { Hyundai } from './ClientsDashboards/Hyundai';
import { Auth } from './Auth';
import PageNotFound from './PageNotFound';
import { isTradingDeskUserOnly } from '../helpers/isTradingDeskUserOnly';

const pathnamesToAddAdvertiserId = ['brands', 'campaigns'].reduce<string[]>(
  (acc, x) => [...acc, `/${x}`, `/${x}/`],
  [],
);

function changeAdvertiserIdInPathname(history: History, id: number) {
  // regex lookbehind not supported in Safari
  const pathname = history.location.pathname.replace(/\/advertiser\/(\d+)\/\w+/, (match, foundId) =>
    foundId ? match.replace(foundId, id.toString()) : match,
  );
  history.push({ ...history.location, pathname });
}

function addAdvertiserIdToPathname(history: History, id: number, page: string) {
  history.replace({ ...history.location, pathname: `/advertiser/${id}/${page}` });
}

const intRegex = '(\\d+)';

const Routes: VFC = () => {
  const history = useHistory();
  const { search, pathname } = history.location;
  const {
    authStore: { profile, isAuthorized },
  } = useStores();
  const params = useRouteMatch<{ advertiserId: string }>('/advertiser/:advertiserId/:page')?.params;
  const advertiserId = Number.isNaN(Number(params?.advertiserId)) ? null : Number(params?.advertiserId);
  const {
    advertiserStore: { selectAdvertiser, currentAdvertiserId },
  } = useStores();

  useMount(() => {
    if (advertiserId && advertiserId > 0 && isAuthorized) {
      selectAdvertiser(advertiserId);
    } else if (typeof advertiserId === 'number' && advertiserId <= 0 && currentAdvertiserId) {
      changeAdvertiserIdInPathname(history, currentAdvertiserId);
    }
  });

  useEffect(() => {
    if (currentAdvertiserId && pathnamesToAddAdvertiserId.includes(pathname)) {
      const page = pathname.slice(1);
      addAdvertiserIdToPathname(history, currentAdvertiserId, page);
    }
  }, [pathname, currentAdvertiserId, history]);

  useUpdateEffect(() => {
    if (currentAdvertiserId && currentAdvertiserId !== advertiserId) {
      changeAdvertiserIdInPathname(history, currentAdvertiserId);
    }
  }, [currentAdvertiserId, history]);

  useUpdateEffect(() => {
    if (advertiserId && advertiserId !== currentAdvertiserId) {
      selectAdvertiser(advertiserId);
    }
  }, [advertiserId]);

  return (
    <Switch>
      <PrivateRoute exact path="/brands" component={Brands} checkableFeature="brands" />
      <PrivateRoute exact path={`/brands/:id${intRegex}/:year?`} component={BrandView} checkableFeature="brands" />
      <PrivateRoute exact path="/campaigns" component={Campaigns} checkableFeature="campaigns" />
      <PrivateRoute exact path="/campaigns/new" component={CampaignEdit} checkableFeature="editCampaign" />
      <PrivateRoute exact path="/campaigns/:id" component={CampaignView} checkableFeature="campaigns" />
      <PrivateRoute exact path="/campaigns/:id/edit" component={CampaignEdit} checkableFeature="editCampaign" />
      <PrivateRoute exact path="/advertiser/:advertiserId/brands" component={Brands} checkableFeature="brands" />
      <PrivateRoute
        exact
        path="/advertiser/:advertiserId/campaigns"
        component={Campaigns}
        checkableFeature="campaigns"
      />
      <Redirect from="/advertiser/:advertiserId/brands/:id" to="/brands/:id" />
      <Redirect from="/advertiser/:advertiserId/campaigns/:id" to="/campaigns/:id" />

      <PrivateRoute exact path="/accounts" component={Accounts} checkableFeature="accounts" />
      <PrivateRoute exact path="/account/new" component={Account} checkableFeature="account" />
      <PrivateRoute exact path="/account/edit/:id/:cabinetId" component={Account} checkableFeature="account" />

      <PrivateRoute path="/cabinet-status" component={CabinetStatus} checkableFeature="cabinetStatus" />

      <PrivateRoute path="/administration" component={Administration} checkableFeature="administration" />

      {config.isShowUploadStatistics && (
        <PrivateRoute path="/upload-statistics" component={UploadStatistics} checkableFeature="uploadStatistics" />
      )}

      <PrivateRoute path="/prg-benchmarks" component={PRGBenchmarks} checkableFeature="prgBenchmarks" />

      <PrivateRoute path="/competitor-analysis" component={CompetitorAnalysis} checkableFeature="competitorAnalysis" />

      <PrivateRoute exact path="/programmatic" component={Programmatic} checkableFeature="programmatic" />

      <PrivateRoute exact path="/report-constructor" component={ReportList} checkableFeature="reportConstructor" />
      <PrivateRoute
        exact
        path="/report-constructor/new"
        component={ReportConstructor}
        checkableFeature="reportConstructor"
      />
      <PrivateRoute
        exact
        path="/report-constructor/:id/:mode"
        component={ReportConstructor}
        checkableFeature="reportConstructor"
      />

      <PrivateRoute exact path="/trading-desk/adfox" component={AdFoxCampaigns} checkableFeature="tradingDesk" />

      <PrivateRoute
        exact
        path="/trading-desk/adfox/:campaignId/banners"
        component={AdFoxBanners}
        checkableFeature="tradingDesk"
      />

      <PrivateRoute exact path="/cabinet-copying" component={CabinetCopying} checkableFeature="cabinetCopying" />

      <Route exact path="/auth" component={Auth} />
      <Route exact path="/app">
        <Redirect to={isTradingDeskUserOnly(profile) ? '/trading-desk/adfox' : '/brands'} />
      </Route>
      <Route exact path="/dashboard/hyundai" component={Hyundai} />

      <Route exact path="/">
        <Redirect to={{ pathname: '/auth', search }} />
      </Route>

      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
