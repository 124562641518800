import { CustomField, Field, IFieldForSave, ISavedServerField } from 'types/powerBI';
import { normalizeUrl } from '..';

export function createInitialField(page: number): Field {
  return {
    url: '',
    isEnabled: false,
    page,
    serverId: null,
    isChanged: false,
  };
}

export function convertServerField(serverField: ISavedServerField): CustomField {
  return {
    page: serverField.biUrlPage.id,
    brand: serverField.brand || null,
    campaign: serverField.campaign || null,
    category: serverField.category || null,
    subbrand: serverField.subbrand || null,
    type: (() => {
      if (serverField.subbrand) {
        return 'subbrand';
      }
      if (serverField.category) {
        return 'category';
      }

      return serverField.campaign ? 'campaign' : 'brand';
    })(),
    url: serverField.url,
    isEnabled: serverField.isEnabled,
    serverId: serverField.id,
    userUrlTitle: serverField.userUrlTitle,
    isChanged: false,
    errors: [],
  };
}

export function convertFieldToServer(f: Field, advertiserId: number, type: 'internal' | 'client'): IFieldForSave {
  return {
    advertiserId,
    campaignId: null,
    brandId: null,
    categoryId: null,
    subbrandId: null,
    biUrlPageId: f.page,
    biUrlRoleName: type === 'client' ? 'client' : null,
    id: f.serverId,
    isEnabled: f.isEnabled,
    url: normalizeUrl(f.url),
    userUrlTitle: null, // todo
  };
}

export function convertCustomFieldToServer(
  f: CustomField,
  advertiserId: number,
  type: 'internal' | 'client',
): IFieldForSave {
  return {
    advertiserId,
    campaignId: f.campaign,
    brandId: f.brand,
    biUrlPageId: f.page as number,
    biUrlRoleName: type === 'client' ? 'client' : null,
    id: f.serverId,
    isEnabled: f.isEnabled,
    url: normalizeUrl(f.url),
    userUrlTitle: f.userUrlTitle,
    subbrandId: f.subbrand,
    categoryId: f.category,
  };
}
