import React, { useState } from 'react';
import { Countdown } from '../../misc/Countdown';
import { TextButton } from '../TextButton';
import classes from './CountdownButton.module.scss';

type Props = {
  /** Additional className */
  className?: string;
  /** Countdown duration in seconds. Default: 6 */
  duration?: number;
  /** On countdown complete event handler */
  onComplete?(): void;
};

export const CountdownButton: React.VFC<Props> = (props) => {
  const { duration = 6, onComplete, className } = props;
  const [isPlaying, setIsPlaying] = useState(true);
  return (
    <Countdown
      className={className}
      duration={duration}
      isPlaying={isPlaying}
      onComplete={onComplete}
      strokeColor="#fff"
      trailColor="rgba(0,0,0,0)"
    >
      {({ remainingTime }) => (
        <div className={classes.content}>
          <span className={classes.remainingTime}>{remainingTime}</span>
          <TextButton
            className={classes.playbackButton}
            icon={`pi ${isPlaying ? 'icon-pause' : 'icon-play'}`}
            onClick={() => setIsPlaying((playing) => !playing)}
          />
        </div>
      )}
    </Countdown>
  );
};
