import React, { VFC } from 'react';

import { localize, LocalizedTitle, PowerBIReport } from 'shared/components/other';

import classes from './CabinetStatus.module.scss';

export const CabinetStatus: VFC = () => {
  return (
    <>
      <LocalizedTitle id="site.title.cabinet-status" />
      <div className={classes.Dashboard}>
        <PowerBIReport pageName="CABINET_STATUS" isFullHeight fallbackMessage={localize('powerbi.nodata')} />
      </div>
    </>
  );
};
