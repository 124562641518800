import { action, computed, observable } from 'mobx';
import { toast } from 'shared/components/common/misc/Toast';
import { localize } from 'shared/components/other';
import { BaseStore } from 'shared/stores';
import { PivotMode, PivotStructure } from 'types/reportConstructor';

import { overridePivot } from './helpers/overridePivot';
import { ReportConstructorStore } from './ReportConstructor.store';
import { getNumberFormat } from './helpers';

type Deps = {
  reportConstructorStore: ReportConstructorStore;
};

export class PivotStore extends BaseStore<Deps> {
  @observable
  structure: PivotStructure | null = null;

  @observable
  pivotMode: PivotMode | null = null;

  @observable
  private pivotView: typeof pivot.views.JetView | null = null;

  private eventId: number | null = null;

  @computed
  get isStructureEmpty(): boolean {
    return this.structure === null || (this.structure.rows.length === 0 && this.structure.columns.length === 0);
  }

  @action
  createPivot = (container: string | null, pivotMode: 'tree' | 'table' = 'tree'): void => {
    const { reportStructure, mode, selectedColumns } = this.deps.reportConstructorStore;

    if (mode !== 'new' && (reportStructure === null || selectedColumns.length === 0)) {
      toast.info(localize('report-constructor.errors.missing-data'));
      return;
    }

    webix.ready(() => {
      this.cleanUp();

      webix.CustomScroll.init();
      webix.i18n.setLocale('ru-RU');

      this.pivotView = webix.ui({
        container,
        view: 'pivot',
        id: 'pivot',
        mode: pivotMode,
        freezeColumns: false,
        override: overridePivot(this.deps.reportConstructorStore),
        fields: selectedColumns.map(({ label, value, valueType, usedInAggregation }) => ({
          id: value,
          value: label,
          type: valueType === 'STRING' ? 'text' : valueType === 'NUMERIC' ? 'number' : 'date',
          predicate: valueType === 'DATE' ? 'date' : valueType === 'NUMERIC' ? 'number' : undefined,
          usedInAggregation,
          usedInFilters: valueType === 'STRING',
        })),
        predicates: {
          date: webix.Date.dateToStr('%d/%m/%Y'),
          number: (value: unknown) => getNumberFormat(value),
        },
        readonly: mode === 'view',
        config: mode !== 'view',
        datatable: {
          footer: false,
          totalColumn: false,
          columnWidth: 150,
        },
        externalProcessing: true,
        ...(reportStructure ? { structure: reportStructure } : {}),
      });

      $$('pivot').getState().$observe('structure', this.setStructure);
      $$('pivot').getState().$observe('mode', this.setPivotMode);

      this.eventId = webix.event(window, 'resize', () => {
        this.pivotView?.adjust();
      });
    });
  };

  exportToExcel = async (filename: string): Promise<void> => {
    const data = await $$('pivot').getService('local').getData(false);

    if (data.data?.length > 0) {
      await new Promise((res) => {
        setTimeout(() => {
          webix.toExcel($$('pivot'), { filename, styles: true, spans: true }).finally(res);
        });
      });
    } else {
      toast.info(localize('report-constructor.errors.missing-data'));
    }
  };

  cleanUp = (): void => {
    this.structure = null;
    this.pivotMode = null;
    this.pivotView?.destructor();

    if (this.eventId) {
      webix.eventRemove(this.eventId);
      this.eventId = null;
    }
  };

  @action
  private setStructure = (structure: PivotStructure): void => {
    this.structure = structure;
  };

  @action
  private setPivotMode = (pivotMode: PivotMode): void => {
    this.pivotMode = pivotMode;
  };
}
