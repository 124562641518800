import React from 'react';
import { FormatterProps } from 'react-data-grid';
import { GridRow, isGridDataRow } from '..';
import { BaseCell } from '../components';

export function defaultFormatter<DR>(args: FormatterProps<GridRow<DR>>): React.ReactElement | null {
  return isGridDataRow(args.row) ? (
    <BaseCell value={args.row.data[args.column.key as keyof typeof args.row.data] ?? null} />
  ) : null;
}
