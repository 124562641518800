import { convertDateToStr } from 'helpers/utils';
import { Nullable } from 'types';
import {
  CellContent,
  ColumnOption,
  PivotOperation,
  PivotStructure,
  ReportColumnValueType,
} from 'types/reportConstructor';

const makeArray = (data: number | string, length: number): Array<number | string> => Array.from({ length }, () => data);

const makeDateString = (date: Nullable<number>): string => {
  const brokenDate = '--/--/--';
  if (!date) return brokenDate;

  try {
    const dateInstance = new Date(date);
    return convertDateToStr(dateInstance);
  } catch {
    return brokenDate;
  }
};

const cachedColumns = [] as ColumnOption[];

const getColumnValueTypeOrLabel = (
  allColumns: ColumnOption[],
  columnName: string,
  needGetLabel = false,
): ReportColumnValueType | string => {
  const findFunc = ({ value }: ColumnOption) => value === columnName;
  const cachedColumn = cachedColumns.find(findFunc);

  if (cachedColumn) return needGetLabel ? cachedColumn.label : cachedColumn.valueType;

  const column = allColumns.find(findFunc);

  if (column) {
    cachedColumns.push(column);
    return needGetLabel ? column.label : column.valueType;
  }

  return 'NUMERIC' as ReportColumnValueType;
};

type StructureType = 'rows' | 'columns';
export const makeDataCategories = <T>(data: T[], amount: Record<StructureType, number>): Record<StructureType, T[]> => {
  return {
    rows: data.slice(0, amount.rows),
    columns: data.slice(amount.rows, amount.rows + amount.columns),
  };
};

type GetDataTypesAmdAmountsFunc = (
  cellData: CellContent[],
  structure: PivotStructure,
  allColumns: ColumnOption[],
) => Nullable<{
  amount: Record<StructureType, number>;
  types: Record<StructureType, ReportColumnValueType[]>;
}>;

const checkReportValueType = (valueType: string): ReportColumnValueType => {
  if (valueType === 'NUMERIC' || valueType === 'DATE' || valueType === 'STRING') {
    return valueType as ReportColumnValueType;
  }

  return 'NUMERIC';
};

export const getDataTypesAndAmounts: GetDataTypesAmdAmountsFunc = (cellData, structure, allColumns) => {
  if (!cellData.length) return null;

  const amount = {
    rows: structure.rows.length,
    columns: structure.columns.length,
  };

  if (!amount.rows) return null;

  const types = cellData.slice(0, amount.rows + amount.columns).map((_, index) => {
    if (index < amount.rows) {
      const valueType = getColumnValueTypeOrLabel(allColumns, structure.rows[index]);
      return checkReportValueType(valueType);
    }

    const valueType = getColumnValueTypeOrLabel(allColumns, structure.columns[index - amount.rows]);
    return checkReportValueType(valueType);
  });

  return {
    amount,
    types: makeDataCategories(types, amount),
  };
};

export const parseComplexMath = (math: string): string[] => {
  const pivotOperations: PivotOperation[] = ['sum', 'avg', 'count', 'max', 'min', 'any', 'wavg'];
  const mathOperations = ['+', '-', '*', '/'];
  const parsedStrings = math
    .split('(')
    .map((s) => s.split(')'))
    .flat()
    .map((s) => s.split(','))
    .flat()
    .map((s) => s.trim());

  const result = parsedStrings.filter(
    (str) => ![...pivotOperations, ...mathOperations].some((operation) => str.includes(operation) || !str),
  );

  return [...new Set(result)];
};

const cachedComplexTitles: Record<string, string> = {};

export type ValueData = { operation: PivotOperation; color: string; title: string };
export const getValuesRow = (structure: PivotStructure, allColumns: ColumnOption[]): ValueData[] => {
  const { values } = structure;
  if (!values.length) return [];

  return values.map(({ operation, name, math, color }) => {
    const title = Array.isArray(name) ? `${name.join(', ')} wavg` : name;

    const result = {
      color,
      operation: operation === 'wavg' ? 'complex' : operation,
      title,
    };

    if (operation !== 'complex' || !math) return result;

    if (cachedComplexTitles[math]) {
      result.title = cachedComplexTitles[math];
      return result;
    }

    const complexValues = parseComplexMath(math);

    const complexTitles = complexValues.map((value) => getColumnValueTypeOrLabel(allColumns, value, true));

    let complexTitle = math;

    complexValues.forEach((value, index) => {
      complexTitle = complexTitle.replace(new RegExp(value), complexTitles[index]);
    });

    result.title = complexTitle;
    cachedComplexTitles[math] = complexTitle;

    return result;
  });
};

export const getFormattedValueFunc = (types: ReportColumnValueType[]) => (item: CellContent, index: number) =>
  types[index] === 'DATE' && typeof item === 'number' ? makeDateString(item) : String(item) || '';

export const getNewValues = (
  currentValues: (number | string)[],
  newValues: (number | string)[],
  emptyShift: number,
) => {
  if (currentValues.length === emptyShift + 1) {
    return [...currentValues, ...newValues];
  }

  return [...currentValues, ...makeArray('', emptyShift - currentValues.length + 1), ...newValues];
};

export const getSortedValuesWithComplex = (
  { values }: PivotStructure,
  nonComplexValue: string[],
  complexValues: string[],
) =>
  values.reduce((acc, { operation }) => {
    if (operation !== 'complex') {
      acc.push(nonComplexValue[0]);
      nonComplexValue.shift();
    } else {
      acc.push(complexValues[0]);
      complexValues.shift();
    }
    return acc;
  }, [] as string[]);
