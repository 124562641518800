import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import { useStores } from 'core';
import { localize, LocalizedMessage } from 'shared/components/other';
import { SaveButtons } from 'shared/components/common/buttons/SaveButtons';
import { ColorPickButton } from 'shared/components/common/buttons/ColorPickButton';
import { BrandCard } from 'shared/components/domain/BrandCard';
import { CampaignCard } from 'shared/components/domain/CampaignCard';
import { toast } from 'shared/components/common/misc/Toast';
import { brand, statistics, labelColor, campaign, brands } from './data';
import classes from './Appearance.module.scss';

export const Appearance = observer(() => {
  const {
    localeStore: { lang },
    appearanceStore,
  } = useStores();
  const { brandTheme: defaultBrandTheme, campaignTheme: defaultCampaignTheme, updateAppearance } = appearanceStore;
  const {
    brandTheme,
    campaignTheme,
    initThemes,
    updateBrandTheme,
    updateCampaignTheme,
  } = appearanceStore.childrenStores.edit;

  const setDefaultThemes = useCallback(() => {
    initThemes(defaultBrandTheme, defaultCampaignTheme);
  }, [initThemes, defaultBrandTheme, defaultCampaignTheme]);

  useEffect(() => {
    setDefaultThemes();
  }, [setDefaultThemes]);

  const handleSaveButtonClick = useCallback(() => {
    if (!brandTheme || !campaignTheme) {
      return;
    }

    toast.success(localize('administration.appearance.saved-theme'));
    updateAppearance(brandTheme, campaignTheme);
  }, [brandTheme, campaignTheme, updateAppearance]);

  if (!brandTheme || !campaignTheme) {
    return null;
  }

  const { background: brandBgColor, mainText: brandMainTextColor, additionalText: brandAddTextColor } = brandTheme;

  const {
    background: campaignBgColor,
    mainText: campaignMainTextColor,
    additionalText: campaignAddTextColor,
  } = campaignTheme;

  return (
    <div className={classes.Layout}>
      <header className={classes.Header}>
        <h1 className={classes.Title}>
          <LocalizedMessage id="administration.appearance" />
        </h1>
        <SaveButtons onCancel={setDefaultThemes} onSave={handleSaveButtonClick} />
      </header>

      <div className={classes.Content}>
        <section className={classes.Settings}>
          <div className={classes.ColorSettings}>
            <header>
              <h2 className={classes.SettingsHeader}>
                <LocalizedMessage id="administration.appearance.brand-card" />
              </h2>
            </header>
            <ColorPickButton
              defaultColor={brandBgColor}
              title={localize('administration.appearance.background-color')}
              onSave={updateBrandTheme('background')}
            />
            <ColorPickButton
              defaultColor={brandMainTextColor}
              title={localize('administration.appearance.main-text-color')}
              onSave={updateBrandTheme('mainText')}
            />
            <ColorPickButton
              defaultColor={brandAddTextColor}
              title={localize('administration.appearance.additional-text-color')}
              onSave={updateBrandTheme('additionalText')}
            />
          </div>
          <div>
            <h3 className={cx(classes.SettingsHeader, classes.SettingsHeaderPreview)}>
              <LocalizedMessage id="administration.appearance.preview" />
            </h3>
            <div className={classes.CardWrapper}>
              <BrandCard brand={brand} statistics={statistics} labelColor={labelColor} theme={brandTheme} />
            </div>
          </div>
        </section>

        <section className={classes.Settings}>
          <div className={classes.ColorSettings}>
            <header>
              <h2 className={classes.SettingsHeader}>
                <LocalizedMessage id="administration.appearance.campaign-card" />
              </h2>
            </header>
            <ColorPickButton
              defaultColor={campaignBgColor}
              title={localize('administration.appearance.background-color')}
              onSave={updateCampaignTheme('background')}
            />
            <ColorPickButton
              defaultColor={campaignMainTextColor}
              title={localize('administration.appearance.main-text-color')}
              onSave={updateCampaignTheme('mainText')}
            />
            <ColorPickButton
              defaultColor={campaignAddTextColor}
              title={localize('administration.appearance.additional-text-color')}
              onSave={updateCampaignTheme('additionalText')}
            />
          </div>
          <div>
            <h3 className={cx(classes.SettingsHeader, classes.SettingsHeaderPreview)}>
              <LocalizedMessage id="administration.appearance.preview" />
            </h3>
            <div className={classes.CardWrapper}>
              <CampaignCard campaign={campaign} brands={brands} locale={lang} theme={campaignTheme} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
});
