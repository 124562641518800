import React from 'react';
import cx from 'classnames';
import { Footer, FooterProps } from 'shared/components/layout/Footer';
import { CampaignInfo, CampaignInfoProps } from '../CampaignInfo';
import { CampaignTabView, CampaignTabViewProps } from '../CampaignTabView';
import { CampaignDescription } from '../CampaignDescription';
import classes from './CampaignView.module.scss';

type Props = {
  className?: string;
  description: string;
  footer?: FooterProps;
  isTabViewVisible: boolean;
  info: CampaignInfoProps;
  tabView: CampaignTabViewProps;
};

export const CampaignView: React.VFC<Props> = React.memo((props) => {
  const { className, description, footer, isTabViewVisible, info, tabView } = props;
  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.top}>
        <CampaignInfo className={classes.info} {...info} />
        <CampaignDescription className={classes.description} content={description} />
      </div>
      <div className={classes.bottom}>
        {isTabViewVisible && <CampaignTabView {...tabView} />}
        <Footer className={classes.footer} {...footer} />
      </div>
    </div>
  );
});

export type { Props as CampaignViewProps };
