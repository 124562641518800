import React from 'react';
import { FormatterProps } from 'react-data-grid';
import { GridRow, isGridDataRow } from '..';
import { DateCell } from '../components';
import { Column } from '../types';

export function dateFormatter<DR>({ row, column: col }: FormatterProps<GridRow<DR>>): React.ReactElement | null {
  const column = col as Column<DR>;
  const key = column.key as keyof typeof row.data;
  const value = column.valueGetter ? column.valueGetter(row.data) : row.data[key];
  if (typeof value !== 'string') return null;
  return isGridDataRow(row) ? <DateCell value={value} type={column.type} /> : null;
}
