import { action, makeObservable, observable, runInAction } from 'mobx';

import { Field, FieldDescription, IFieldForSave, PowerBIPageName } from 'types/powerBI';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { BaseStore } from 'shared/stores';
import { PowerBiStore } from './PowerBi.store';
import { normalizeUrl } from '../helpers';
import { convertToFields } from '../helpers/converters/defaultSettings';

type Deps = {
  powerBiStore: PowerBiStore;
};

export class PowerBiDefaultSettingsStore extends BaseStore<Deps> {
  @observable
  fields: Record<number, Field> = {};

  @observable
  description: FieldDescription[] = [];

  loadFields = async (): Promise<void> => {
    const [description, fields] = await Promise.all([
      this.services.api.powerbi.getFieldDescriptions(),
      this.services.api.powerbi.getDefaultUrls(),
    ]);
    if (description.error || fields.error) return;

    if (!Array.isArray(fields.data)) return;

    runInAction(() => {
      this.description = description.data;
      this.fields = convertToFields(fields.data, description.data);
    });
  };

  @action
  changeField = (newField: Field): void => {
    this.fields = {
      ...this.fields,
      [newField.page]: newField,
    };
  };

  saveLinks = async (): Promise<void> => {
    const arr = Object.values(this.fields);
    const isAllValid = arr.every(({ isEnabled, url }) => !(isEnabled && url === ''));

    if (!isAllValid) {
      toast.error(localize('powerbi.error.validation-fail'));
      return;
    }

    const res: IFieldForSave[] = arr
      .filter((f) => f.isChanged)
      .map((f) => ({
        advertiserId: null,
        campaignId: null,
        brandId: null,
        categoryId: null,
        subbrandId: null,
        biUrlPageId: f.page,
        biUrlRoleName: null,
        id: f.serverId,
        isEnabled: f.isEnabled,
        url: normalizeUrl(f.url),
        userUrlTitle: null,
      }));

    const response = await this.services.api.powerbi.saveDefaultUrls({ fields: res });
    if (response.error) return;

    this.loadFields();
    this.updateSubStores(res.map((it) => it.biUrlPageId));
    toast.success(localize('powerbi.saved'));
  };

  private isStoreUpdated(pageName: PageName, updates: number[]): boolean {
    return updates.some((it: number) => it === this.description.find((desc) => desc.pageName === pageName)?.id);
  }

  private updateSubStores(updatedPageIds: number[]): void {
    const { currentAdvertiserId: advertiserId } = this.deps.advertiserStore;
    if (advertiserId) {
      const pageNames: PageName[] = ['COMPETITOR_ANALYSIS', 'PRG_BENCHMARK', 'CABINET_STATUS'];

      const toReFetch: Promise<unknown>[] = [];

      pageNames.forEach((pageName) => {
        if (this.isStoreUpdated(pageName, updatedPageIds)) {
          toReFetch.push(
            this.deps.powerBiStore.loadPowerBIReport({
              pageName,
              advertiserId,
            }),
          );
        }
      });

      Promise.all(toReFetch);
    }
  }

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
  }
}

type PageName = Extract<PowerBIPageName, 'COMPETITOR_ANALYSIS' | 'PRG_BENCHMARK' | 'CABINET_STATUS'>;
