import React from 'react';
import { ServerModels as SM } from 'types/server';
import { CampaignSummary } from 'types/programmatic';
import { SelectButtonItem } from './components/SelectButtonItem';

type CheckStatusOption = {
  label: string;
  value: SM.CheckStatus;
};

type CabinetStatusOption = {
  label: string;
  value: SM.CabinetStatus;
};

export const makeCheckStatusButtonTemplate = (summary: CampaignSummary) => (option: CheckStatusOption): JSX.Element => {
  const { checkStatusOkTotal = 0, checkStatusWarningTotal = 0, checkStatusErrorTotal = 0 } = summary;
  const { label, value } = option;
  let icon = '';
  let color: 'ok' | 'error' | 'warning' = 'ok';
  let count = 0;
  if (value === SM.CheckStatus.Error) {
    icon = 'pi-exclamation-circle';
    color = 'error';
    count = Number(checkStatusErrorTotal);
  } else if (value === SM.CheckStatus.Warning) {
    icon = 'pi-exclamation-triangle';
    color = 'warning';
    count = Number(checkStatusWarningTotal);
  } else if (value === SM.CheckStatus.Ok) {
    icon = 'pi-check-circle';
    color = 'ok';
    count = Number(checkStatusOkTotal);
  }

  return <SelectButtonItem count={count} name={label} icon={icon} iconColor={color} />;
};

export const makeCabinetStatusButtonTemplate = (summary: CampaignSummary) => (
  option: CabinetStatusOption,
): JSX.Element => {
  const { cabinetActiveTotal = 0, cabinetStoppedTotal = 0, campaignsTotal = 0 } = summary;
  const { label, value } = option;
  let icon = '';
  let color: 'ok' | 'error' | 'warning' = 'ok';
  let count = campaignsTotal;
  if (value === SM.CabinetStatus.Started) {
    icon = 'pi-play';
    color = 'ok';
    count = cabinetActiveTotal;
  } else if (value === SM.CabinetStatus.Stopped) {
    icon = 'pi-stop';
    color = 'error';
    count = cabinetStoppedTotal;
  }

  return (
    <SelectButtonItem
      count={Number(count)}
      name={label}
      icon={icon}
      iconColor={color}
      style={{ fontSize: icon === 'pi-stop' ? 14 : 16 }}
    />
  );
};
