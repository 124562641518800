import React from 'react';
import { toCurrency } from 'helpers';
import { baseCellWrapper } from '../baseCellWrapper';
import classes from './CurrencyCell.module.scss';

type Props = {
  value: React.ReactText;
};

const CurrencyCellComponent: React.VFC<Props> = ({ value }) => {
  return <div className={classes.root}>{toCurrency(value)}</div>;
};

export const CurrencyCell = baseCellWrapper(CurrencyCellComponent);
