import { isAfter } from 'date-fns';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';

export function showNotificationIfRetroDateIsExceeded(
  date: Date[] | null,
  lastAvailableRetroDate: string | null,
): void {
  if (date === null || lastAvailableRetroDate === null) return;
  const [, endDate] = date;
  if (isAfter(endDate, new Date(lastAvailableRetroDate))) {
    toast.warning(localize('campaign-view.mediaplan.retroperiod-affinity-warning'));
  }
}
