import React from 'react';
import { observer } from 'mobx-react';
import { TabView, TabPanel } from 'primereact/tabview';

import { useStores } from 'core';
import { LocalizedMessage } from 'shared/components/other';
import { Log, Statistics } from './components';

import classes from './Sidebar.module.scss';

const Sidebar: React.VFC = observer(() => {
  const { excessStore } = useStores();
  const { activeTab, setActiveTab } = excessStore;

  return (
    <LocalizedMessage id={['programmatic.tabs.statistics', 'programmatic.tabs.log']}>
      {(statistics: string, log: string) => (
        <TabView className={classes.tabs} activeIndex={activeTab ?? 0} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header={statistics}>
            <Statistics />
          </TabPanel>
          <TabPanel header={log}>
            <Log />
          </TabPanel>
        </TabView>
      )}
    </LocalizedMessage>
  );
});

export { Sidebar };
