import HT from 'handsontable';
import history from 'browserHistory';
import classes from '../AdfoxTable.module.scss';

export function linkRowRenderer(instance: HT, TD: HTMLTableCellElement, rowIndex: number): void {
  const link = document.createElement('span');
  link.innerText = instance.getDataAtRowProp(rowIndex, 'name');
  link.className = classes.link;
  link.addEventListener('click', (e) => {
    const campaignId = instance.getDataAtRowProp(rowIndex, 'id');
    const url = `/trading-desk/adfox/${campaignId}/banners`;
    if (e.ctrlKey) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  });
  TD.innerText = ''; // eslint-disable-line no-param-reassign,no-self-assign
  TD.appendChild(link);
}
