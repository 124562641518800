import cx from 'classnames';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useStores } from 'core';
import { LocaleIdType } from 'locales';
import { LocalizedMessage } from 'shared/components/other';
import { ReportData } from 'types/reportConstructor';

import classes from './ReportTable.module.scss';

const formatDate = (date: number | Date) => format(date, 'dd.MM.yyyy');

export const ReportTable: React.FC = observer(() => {
  const { reportListStore: store } = useStores();
  const { data, fields, sorting, loadData, cleanUp, updateSorting } = store;

  const actions = useMemo(
    () => [
      { name: 'edit', icon: 'pi-file-edit', handler: store.edit },
      { name: 'open', icon: 'pi-file', handler: store.open },
      { name: 'copy', icon: 'pi-copy', handler: store.copy },
      { name: 'export', icon: 'pi-file-export', handler: store.export },
      { name: 'delete', icon: 'pi-trash', handler: store.delete },
    ],
    [store],
  );

  const handleSortButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      const target = e.target as HTMLElement;
      const sortingField = target?.closest<HTMLElement>('[data-sort]')?.dataset.sort as keyof ReportData;

      if (sortingField) {
        updateSorting(sortingField);
      }
    },
    [updateSorting],
  );

  useEffect(() => {
    loadData();

    return () => {
      cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data.length === 0) {
    return null;
  }

  return (
    <table className={classes.root}>
      <thead>
        <tr onClick={handleSortButtonClick}>
          {fields.map(({ name, isSorting }) => {
            const isSortingField = sorting?.sortingField === name;

            return (
              <th key={name} data-sort={isSorting ? name : undefined} data-sort-active={isSortingField}>
                <LocalizedMessage id={`report-list.fields.${name}` as LocaleIdType} />
                {isSorting && (
                  <i
                    className={cx(classes.sortingIcon, 'icon icon-arrow-down', {
                      [classes.sortingIconReverse]: isSortingField && sorting?.sortingType === 'ASC',
                    })}
                  />
                )}
              </th>
            );
          })}
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          ({ id, name, createdDate, statisticDateStart, statisticDateEnd, userCreated, userModified }: ReportData) => (
            <tr key={id} className={classes.row}>
              <td>{name}</td>
              <td>{createdDate && formatDate(createdDate)}</td>
              <td>
                {statisticDateStart && formatDate(statisticDateStart)}
                {statisticDateEnd && ` - ${formatDate(statisticDateEnd)}`}
              </td>
              <td>{userCreated}</td>
              <td>{userModified}</td>
              <td>
                <div className={classes.actions}>
                  {actions.map(({ name: actionName, icon, handler }) => (
                    <LocalizedMessage key={actionName} id={`report-list.actions.${actionName}` as LocaleIdType}>
                      {(title: string) => (
                        <i
                          className={cx(`pi ${icon}`, classes.action)}
                          title={title}
                          data-test={`${actionName}-report-${id}`}
                          onClick={() => handler(id, name)}
                        />
                      )}
                    </LocalizedMessage>
                  ))}
                </div>
              </td>
            </tr>
          ),
        )}
      </tbody>
    </table>
  );
});
