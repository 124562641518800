import * as R from 'ramda';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';

import { PaginationStore } from 'shared/stores';
import { toast } from 'shared/components/common/misc/Toast';
import { EmptyObject } from 'types';
import {
  DataRow,
  ActiveTab,
  ExcessCausesDictionary,
  Cabinet,
  Client,
  CampaignSummary,
  CabinetStatus,
} from 'types/programmatic';
import { ServerModels as SM } from 'types/server';
import { format, parseISO } from 'date-fns';

import { LocaleIdType } from 'locales';
import { isNotNull } from 'helpers/isNotNull';
import { FIVE_MINUTES_TIMESTAMP } from 'helpers/constants';
import { localize, localizeMessage, Row } from 'shared/components/other';
import { ExcessLogStore } from './ExcessLog.store';
import { ExcessStatisticsStore } from './ExcessStatistics.store';
import { ExcessCheckSettingsStore } from './ExcessCheckSettings.store';
import { findInTree, mergeFilters } from '../helpers';
import {
  convertExcessCampaignToDataRow,
  convertExcessMediaplanRowToDataRow,
  convertServerRowsToDataTree,
} from '../converters';

type ChildrenStores = {
  log: ExcessLogStore;
  statistics: ExcessStatisticsStore;
  checkSettings: ExcessCheckSettingsStore;
};

export class ExcessStore extends PaginationStore<Row<DataRow>[], EmptyObject, ChildrenStores> {
  private _abortController: AbortController | null = null;

  protected _data: Row<DataRow>[] = [];

  @observable
  private _isLoading = false;

  @observable
  private _isLoadedAll = false;

  @observable
  private _clients: Client[] = [];

  @observable
  private _cabinets: Cabinet[] = [];

  @observable
  private _campaignStatuses: string[] = [];

  @observable
  private _campaignSummary: CampaignSummary = {};

  @observable
  private _campaignFilters: SM.CampaignFilters = {
    campaignStatus: [SM.CampaignStatus.Active],
  };

  @observable
  private _activeTab: ActiveTab | null = null;

  @observable
  private lastUpdateTimeValue = '';

  @observable
  private excessCausesDictionary: ExcessCausesDictionary = [];

  @observable
  private _dataIsOutdated = false;

  private _dataStatusUpdateTimerId: number | null = null;

  protected invalidationScope = (): unknown[] => [this.pageSize, this.campaignFilters];

  constructor() {
    super();
    makeObservable(this);
    this.setup();

    this.childrenStores = {
      log: new ExcessLogStore({ excessStore: this }),
      statistics: new ExcessStatisticsStore({ excessStore: this }),
      checkSettings: new ExcessCheckSettingsStore({ excessStore: this }),
    };

    reaction(
      () => this.page,
      () => {
        if (this.page > 0) {
          this.loadCampaignsData();
          this.loadLastUpdateTime();
        }
      },
    );
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isLoadedAll(): boolean {
    return this._isLoadedAll;
  }

  get clients(): Client[] {
    return this._clients;
  }

  get cabinets(): Cabinet[] {
    return this._cabinets;
  }

  get campaignStatuses(): string[] {
    return this._campaignStatuses;
  }

  get campaignSummary(): CampaignSummary {
    return this._campaignSummary;
  }

  get campaignFilters(): SM.CampaignFilters {
    return this._campaignFilters;
  }

  get activeTab(): ActiveTab | null {
    return this._activeTab;
  }

  @computed
  get campaignFiltersKeys(): string[] {
    return Object.keys(this.campaignFilters);
  }

  @computed
  get hasCampaignFilters(): boolean {
    return this.campaignFiltersKeys.length > 0;
  }

  // used to highlight rows
  @computed
  get selectedRowKeys(): ReadonlySet<string> {
    return new Set(
      findInTree(
        this.data,
        [
          { key: 'selectionState', rule: (x) => x !== false },
          { key: 'children', rule: (x) => !x },
        ],
        'all',
      ).map((x) => x.rowId),
    );
  }

  @computed
  get hasSelectedRows(): boolean {
    return this.selectedRowKeys.size > 0;
  }

  @computed
  get selectedRowIDs(): string[] {
    return findInTree(this.data, [{ key: 'selectionState', rule: (x) => x !== false }], 'all').map((x) =>
      String(x.data.id),
    );
  }

  @computed
  // First Level Row  IDs
  get selectedCampaignIDs(): number[] {
    return findInTree(
      this.data,
      [
        { key: 'depth', rule: (x) => x === 0 },
        { key: 'selectionState', rule: (x) => x === true },
        { key: 'children', rule: (x) => !x },
      ],
      'all',
    ).map((x) => Number(x.data.id));
  }

  @computed
  // Second Level Row IDs
  get selectedMediaplanRowIDs(): string[] {
    return findInTree(
      this.data,
      [
        { key: 'depth', rule: (x) => x === 1 },
        { key: 'selectionState', rule: (x) => x !== false },
      ],
      'all',
    ).map((x) => String(x.data.id));
  }

  @computed
  // Third Level Rows
  get selectedPositionRowsData(): DataRow[] {
    return findInTree(
      this.data,
      [
        { key: 'depth', rule: (x) => x === 2 },
        { key: 'selectionState', rule: (x) => x === true },
      ],
      'all',
    ).map((x) => x.data);
  }

  @computed
  get partiallySelectedMediaplanRows(): Row<DataRow>[] {
    return findInTree(
      this.data,
      [
        { key: 'depth', rule: (x) => x === 1 },
        { key: 'selectionState', rule: (x) => x === 'intermediate' },
      ],
      'all',
    );
  }

  @computed
  get hasPartiallySelectedMediaplanRows(): boolean {
    return this.partiallySelectedMediaplanRows.length > 0;
  }

  @computed
  get selectedRowsFilters(): Pick<SM.LogEntriesExcessFilters, 'campaignIds' | 'csId'> {
    const filters: Pick<SM.LogEntriesExcessFilters, 'campaignIds' | 'csId'> = {};
    if (this.selectedCampaignIDs.length) {
      filters.campaignIds = this.selectedCampaignIDs;
    }
    if (this.selectedMediaplanRowIDs.length) {
      filters.csId = { operator: SM.FilterOperator.In, value: this.selectedMediaplanRowIDs };
    }
    return filters;
  }

  @computed
  get mediaplanRowsFilters(): SM.MediaplanRowsFilters {
    const { cabinetIds, csId } = this.campaignFilters;

    return { cabinetIds, csId };
  }

  @computed
  get lastUpdateTime(): string {
    if (!this.lastUpdateTimeValue) return '';
    const date = parseISO(this.lastUpdateTimeValue);

    return format(date, 'dd.MM.yyyy HH:mm:ss');
  }

  @computed
  get dataIsOutdated(): boolean {
    return this._dataIsOutdated && !this._isLoading;
  }

  getRow = (rowId: string): Row<DataRow> | null => {
    return findInTree(this.data, [{ key: 'rowId', rule: (x) => x === rowId }]);
  };

  loadData = async (): Promise<void> => {
    await Promise.all([
      this.revalidate(),
      this.loadClients(),
      this.loadCabinets(),
      this.loadCampaignStatuses(),
      this.loadExcessCausesDictionary(),
      this.childrenStores.checkSettings.loadCheckSettingsDictionary(),
    ]);
  };

  @action
  forceUpdateData = (): void => {
    this._data = R.clone(this.data);
  };

  @action
  setCampaignFilters = (filters: SM.CampaignFilters): void => {
    if (this.isLoading) return;
    this._campaignFilters = mergeFilters(this.campaignFilters, filters);
  };

  @action
  clearCampaignFilters = (): void => {
    this._campaignFilters = {};
  };

  @action
  toggleRowExpanding = async (rowId: string): Promise<void> => {
    const dataRow = this.getRow(rowId);
    if (!dataRow) return;
    dataRow.isExpanded = !dataRow.isExpanded;
    if (!dataRow.children) {
      dataRow.isLoading = true;
      const children = await this.getMediaplanRows(Number(dataRow.data.id));
      dataRow.children = convertServerRowsToDataTree(children, dataRow);
      dataRow.isLoading = false;
    }
    this.forceUpdateData();
  };

  @action
  toggleRowSelection = (rowId: string): void => {
    const row = this.getRow(rowId);
    if (!row) return;
    row.selectionState = !row.selectionState;
    this.forceUpdateData();
  };

  @action
  setActiveTab = (tab: ActiveTab | null): void => {
    this._activeTab = tab;
  };

  @action
  setDataIsOutdated = (isOutdated: boolean): void => {
    this._dataIsOutdated = isOutdated;

    if (this._dataStatusUpdateTimerId !== null) {
      window.clearTimeout(this._dataStatusUpdateTimerId);
    }
  };

  @action
  selectPartiallySelectedMediaplanRows = (): void => {
    if (!this.hasPartiallySelectedMediaplanRows) return;
    for (const row of this.partiallySelectedMediaplanRows) {
      row.selectionState = true;
    }
    this.forceUpdateData();
  };

  startSelectedPlacements = (): void => {
    const rows = this.selectedPositionRowsData.filter((row) => row.cabinetStatus === CabinetStatus.STOPPED);
    this.startStopPlacements(rows, SM.CabinetStatus.Started);
  };

  stopSelectedPlacements = (): void => {
    const rows = this.selectedPositionRowsData.filter((row) => row.cabinetStatus === CabinetStatus.STARTED);
    this.startStopPlacements(rows, SM.CabinetStatus.Stopped);
  };

  startStopPlacements = async (rowsData: DataRow[], newStatus: SM.CabinetStatus): Promise<void> => {
    const statusRequests = rowsData
      .map((rowData) => {
        const { advertiserId, campaignId, mediaplanId, rowNumber, csId, positionId } = rowData;
        if (!advertiserId || !campaignId || !mediaplanId || !csId || !positionId || typeof rowNumber !== 'number') {
          return null;
        }
        return {
          newStatus,
          advertiserId,
          campaignId,
          mediaplanId,
          rowNumber,
          csId,
          positionTypeId: positionId.type,
          positionId: positionId.value,
        };
      })
      .filter(isNotNull);
    if (statusRequests.length === 0) {
      toast.error(
        localize(`programmatic.excess-table.no-${newStatus.toLowerCase()}-placements-in-selected-rows` as LocaleIdType),
      );
      return;
    }

    const response = await this.services.api.programmatic.excess.changeCabinetStatus({ statusRequests });
    if (!response.errors) {
      this.updateStatusOfPlacements(rowsData, newStatus);
    }
  };

  @action
  private updateStatusOfPlacements = (rowsData: DataRow[], newStatus: SM.CabinetStatus): void => {
    toast.success(
      localizeMessage(
        { id: 'programmatic.excess-table.request-to-update-placement-status-successfully-sent' },
        {
          newStatus: localize(
            `programmatic.excess-table.placement-statuses.${newStatus.toLowerCase()}` as LocaleIdType,
          ),
        },
      ),
    );
    rowsData.forEach(({ id }) => {
      const row = findInTree(this.data, [
        { key: 'depth', rule: (x) => x === 2 },
        { key: 'data', rule: (x) => !!x && typeof x === 'object' && 'id' in x && x.id === id },
      ]);
      if (row) {
        row.data.cabinetStatus = CabinetStatus.IN_PROGRESS;
      }
    });
    this.forceUpdateData();
  };

  private updateDataStatus = () => {
    this.setDataIsOutdated(false);
    this._dataStatusUpdateTimerId = window.setTimeout(() => {
      this.setDataIsOutdated(true);
    }, FIVE_MINUTES_TIMESTAMP);
  };

  private getExcessCauseById = (id: number): string | null => {
    const cause = this.excessCausesDictionary.find((x) => x.id === id);
    return cause?.displayMessageFullPure ?? cause?.displayMessageFull ?? null;
  };

  @action
  private loadCampaignsData = async (): Promise<void> => {
    if (this.isLoading) return;

    this._isLoading = true;
    this._abortController = new AbortController();

    const response = await this.services.api.programmatic.excess.campaignsData(
      {
        campaignFilters: this.campaignFilters,
        page: { number: this.page, size: this.pageSize },
      },
      this._abortController.signal,
    );

    runInAction(() => {
      this._isLoading = false;
    });

    if (response.data?.excessCampaigns) {
      const data = convertServerRowsToDataTree(
        response.data.excessCampaigns.map((x) => convertExcessCampaignToDataRow(x)),
      );
      this._data = this.page > 0 ? [...this.data, ...data] : data;
      this._isLoadedAll = response.data.excessCampaigns.length < this.pageSize;
      this._campaignSummary = response.data.excessCampaigns[0] ?? {};
    }

    this.updateDataStatus();
  };

  @action
  cancelDataLoading = (): void => {
    if (!this._isLoading) return;

    this._abortController?.abort();
    this._isLoading = false;
    this.deps.globalLoaderStore.stopLoading();
  };

  private getMediaplanRows = async (campaignId: number): Promise<DataRow[]> => {
    const filters: SM.MediaplanRowsFilters = { ...this.mediaplanRowsFilters, campaignIds: [campaignId] };
    const response = await this.services.api.programmatic.excess.rowsAndPositions({ filters });
    if (response.data?.excessRowsAndPositions) {
      return response.data.excessRowsAndPositions.map((x) =>
        convertExcessMediaplanRowToDataRow({
          row: x,
          getExcessCauseById: this.getExcessCauseById,
        }),
      );
    }
    return [];
  };

  @action
  private loadClients = async (): Promise<void> => {
    const response = await this.services.api.advertisers.list();
    if (response.error) return;

    runInAction(() => {
      this._clients = response.data.content.map((x) => R.pick(['id', 'name'], x));
    });
  };

  @action
  private loadCabinets = async (): Promise<void> => {
    const response = await this.services.api.programmatic.cabinets();
    if (response.error) return;

    runInAction(() => {
      this._cabinets = response.data;
    });
  };

  @action
  private loadCampaignStatuses = async (): Promise<void> => {
    const response = await this.services.api.campaigns.statuses();
    if (response.error) return;

    runInAction(() => {
      this._campaignStatuses = response.data.map(String);
    });
  };

  @action
  private loadLastUpdateTime = async (): Promise<void> => {
    const response = await this.services.api.programmatic.lastUpdateTime();
    if (response.error) return;

    runInAction(() => {
      this.lastUpdateTimeValue = response.data.lastUpdateTime;
    });
  };

  @action
  private loadExcessCausesDictionary = async (): Promise<void> => {
    const response = await this.services.api.programmatic.excessCausesDictionary();
    if (response.error) return;

    runInAction(() => {
      this.excessCausesDictionary = response.data;
    });
  };

  protected revalidate = async (): Promise<void> => {
    runInAction(() => {
      this.page = 0;
    });
    await Promise.all([this.loadCampaignsData(), this.loadLastUpdateTime()]);
  };
}
