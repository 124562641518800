import HT from 'handsontable';

export function numericValidator(...args: Parameters<typeof HT.validators.NumericValidator>): void {
  const [value, resolve] = args;

  let isValid = true;
  HT.validators.NumericValidator.call(this, value, (valid: boolean) => {
    isValid = valid;
  });

  const hot: HT = this.instance;
  const { columns } = hot.getSettings();
  const column = Array.isArray(columns) ? columns[this.col] : columns?.(this.col);
  if (column?.readOnly) {
    resolve(isValid);
    return;
  }

  const isPositive = Number(value) >= 0;
  const isInteger = !value || Number.isInteger(value);

  resolve(isValid && isPositive && isInteger);
}
