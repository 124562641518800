import { action, computed, makeObservable, observable } from 'mobx';
import cx from 'classnames';
import { ConfirmDialogProps } from 'primereact/confirmdialog';

import { defaultSettings } from './defaultSettings';

export class ConfirmDialogStore {
  @observable
  settings: ConfirmDialogProps = {};

  constructor() {
    makeObservable(this);
  }

  @computed
  get isVisible(): boolean {
    return Object.values(this.settings).length > 0;
  }

  @action
  show = (settings: ConfirmDialogProps): void => {
    this.settings = {
      ...defaultSettings,
      ...settings,
      acceptClassName: cx(defaultSettings.acceptClassName, settings.acceptClassName),
      rejectClassName: cx(defaultSettings.rejectClassName, settings.rejectClassName),
    };
  };

  @action
  hide = (): void => {
    this.settings = {};
  };
}
