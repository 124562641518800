import CampaignsData from './ExcessCampaignsData.graphql';
import RowsAndPositions from './ExcessRowsAndPositions.graphql';
import CampaignCsIds from './ExcessCampaignCsIds.graphql';
import LogEntries from './ExcessLogEntries.graphql';
import ExcessCheckSettingsMetadata from './ExcessCheckSettingsMetadata.graphql';
import ExcessCheckSettings from './ExcessCheckSettings.graphql';

export const queries = {
  CampaignsData,
  RowsAndPositions,
  CampaignCsIds,
  LogEntries,
  ExcessCheckSettingsMetadata,
  ExcessCheckSettings,
};
