import React, { VFC } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useStores } from 'core';
import { ServerModels as SM } from 'types/server';
import { CabinetStatus, DataRow } from 'types/programmatic';
import { localize } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';

import classes from './StartStopPlacementCell.module.scss';

type Props = {
  rowData: DataRow;
};

export const StartStopPlacementCell: VFC<Props> = observer(({ rowData }) => {
  const {
    excessStore: { startStopPlacements },
  } = useStores();

  const { cabinetStatus } = rowData;
  if (cabinetStatus === CabinetStatus.STARTED) {
    return (
      <Button
        className={cx(classes.root, 'p-button-danger')}
        icon="pi pi-stop"
        tooltip={localize('programmatic.excess-table.tooltips.stop-placement')}
        onClick={() => startStopPlacements([rowData], SM.CabinetStatus.Stopped)}
      />
    );
  }

  if (cabinetStatus === CabinetStatus.STOPPED) {
    return (
      <Button
        className={cx(classes.root, 'p-button-success')}
        icon="pi pi-play"
        tooltip={localize('programmatic.excess-table.tooltips.start-placement')}
        onClick={() => startStopPlacements([rowData], SM.CabinetStatus.Started)}
      />
    );
  }

  if (cabinetStatus === CabinetStatus.IN_PROGRESS) {
    return <Button className={cx(classes.root, 'p-button-text')} icon="pi pi-spinner pi-spin" disabled />;
  }

  return null;
});
