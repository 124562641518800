import { ReportConstructorStore } from '../../ReportConstructor.store';
import { createCustomBackend } from './createCustomBackend';
import { createCustomConfig } from './createCustomConfig';
import { createCustomLocalData } from './createCustomLocalData';
import { createCustomMode } from './createCustomMode';
import { createCustomTable } from './createCustomTable';
import { createCustomValuesProperty } from './createCustomValuesProperty';

export const overridePivot = (store: ReportConstructorStore): Map<unknown, unknown> => {
  const CustomBackend = createCustomBackend(store);
  const CustomLocalData = createCustomLocalData(store);
  const CustomConfig = createCustomConfig();
  const CustomValuesProperty = createCustomValuesProperty();
  const CustomMode = createCustomMode();
  const CustomTable = createCustomTable();

  return new Map<unknown, unknown>([
    [pivot.services.Backend, CustomBackend],
    [pivot.services.LocalData, CustomLocalData],
    [pivot.views.config, CustomConfig],
    [pivot.views['config/properties/values'], CustomValuesProperty],
    [pivot.views.mode, CustomMode],
    [pivot.views.table, CustomTable],
  ]);
};
