import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { Account } from 'routes/Account/Account';
import { useStores } from 'core';
import { useDidMount } from 'helpers';

export const AccountContainer = observer(() => {
  const { accountStore: store, advertiserStore } = useStores();
  const { view } = store.childrenStores;
  const { currentAdvertiserId: advertiserId } = advertiserStore;

  const history = useHistory();
  const params = useParams<{ id?: string; cabinetId?: string }>();

  useDidMount(() => {
    if (!Object.keys(params).length) {
      store.loadData();
    }
  });

  useEffect(() => {
    return store.cleanup;
  }, [store]);

  useEffect(() => {
    runInAction(() => {
      store.advertiserId = advertiserId;
      if (params.cabinetId) {
        store.cabinetId = Number(params.cabinetId);
      }
      if (params.id) {
        store.accountId = Number(params.id);
      }
    });
  }, [store, params, advertiserId]);

  const returnToPreviousPage = useCallback(() => {
    history.push('/accounts');
  }, [history]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      view.handleSubmit(e).then((success) => {
        if (success) {
          returnToPreviousPage();
        }
      });
    },
    [view, returnToPreviousPage],
  );

  return <Account handleSubmit={handleSubmit} returnToPreviousPage={returnToPreviousPage} />;
});
