import React, { useMemo, CSSProperties } from 'react';
import cx from 'classnames';
import getLogoSrc from 'helpers/getLogoSrc';
import { formatCampaignSum } from 'helpers/utils';
import { localize } from 'shared/components/other';
import { IColorTheme } from 'types';
import { IBrandStatistics, IBrand } from 'types/brand';
import { InfoItem } from './components/InfoItem';
import classes from './BrandCard.module.scss';

type Props = {
  brand: IBrand;
  labelColor: string;
  statistics: IBrandStatistics | null;
  theme: IColorTheme;
};

const DEFAULT_STATISTICS = {
  activeCampaigns: 0,
  totalCampaigns: 0,
  budgetPlan: 0,
};

export const BrandCard: React.FC<Props> = (props) => {
  const { brand, labelColor, statistics, theme } = props;
  const { activeCampaigns, totalCampaigns, budgetPlan } = statistics ?? DEFAULT_STATISTICS;
  const { value: budgetValue, unitId } = formatCampaignSum(budgetPlan);
  const disabled = !(totalCampaigns && activeCampaigns && budgetPlan);
  const isColorLabelVisible = !!totalCampaigns && !!budgetPlan;

  const styles = useMemo(
    () =>
      ({
        '--color-background': disabled ? 'var(--color-basic-white)' : theme.background,
        '--color-main-text': theme.mainText,
        '--color-additional-text': theme.additionalText,
        '--color-label': labelColor,
      } as CSSProperties),
    [disabled, labelColor, theme],
  );

  return (
    <div className={cx(classes.root, { [classes.disabled]: disabled })} style={styles}>
      {isColorLabelVisible && <div className={classes.colorLabel} />}
      <header className={classes.header}>
        {brand.logoFileId !== null ? (
          <img
            className={classes.logo}
            alt={brand.name}
            data-global-tooltip
            data-pr-tooltip={brand.name}
            src={getLogoSrc(brand.logoFileId)}
          />
        ) : (
          <span className={classes.name} data-global-tooltip data-pr-tooltip={brand.name}>
            {brand.name}
          </span>
        )}
      </header>
      <div className={classes.info}>
        <InfoItem title={localize('brands.budget')} value={`${budgetValue.toLocaleString()} ${localize(unitId)}`} />
        <InfoItem title={localize('brands.active-campaigns')} value={`${activeCampaigns}/${totalCampaigns}`} />
      </div>
    </div>
  );
};

export type { Props as BrandCardProps };
