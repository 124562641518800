export const serialize = (obj: { [key: string]: unknown }): string => {
  const str: string[] = [];

  Object.keys(obj).forEach((p: string) => {
    const val = obj[p];
    if (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(val)}`);
    }
  });

  return str.join('&');
};
