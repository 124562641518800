import { Creative, ServerCreative } from 'types/creative';

export function convertCreative(creative: ServerCreative): Creative {
  return {
    id: creative.id,
    name: creative.name,
    fileS3Id: creative.fileS3Id,
    externalResource: creative.externalResource,
    campaignCreativeType: creative.campaignCreativeType,
    uploadStatus: { type: 'inactive', progress: null },
  };
}

export function convertCreatives(creatives: ServerCreative[]): Creative[] {
  return creatives.map(convertCreative);
}

export function convertFileToUrl(file: Blob | null): string | null {
  return file ? URL.createObjectURL(file) : null;
}
