import React from 'react';
import cx from 'classnames';
import { FirstPageLink } from '../FirstPageLink';
import { PrevPageLink } from '../PrevPageLink';
import { CurrentPageReport } from '../CurrentPageReport';
import { NextPageLink } from '../NextPageLink';
import { LastPageLink } from '../LastPageLink';
import { usePaginator } from '../../Paginator.provider';
import classes from './PageLinksWithCurrentPageReport.module.scss';

type Props = {
  className?: string;
};

export const PageLinksWithCurrentPageReport: React.VFC<Props> = ({ className }) => {
  const { options } = usePaginator();

  if (options.currentPageReport.totalPages <= 1) {
    return null;
  }

  return (
    <div className={cx(classes.root, className)}>
      <FirstPageLink />
      <PrevPageLink />
      <CurrentPageReport />
      <NextPageLink />
      <LastPageLink />
    </div>
  );
};

export type { Props as PageLinksWithCurrentPageReportProps };
