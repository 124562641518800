import React, { VFC } from 'react';
import cx from 'classnames';
import { startOfToday, startOfTomorrow, addMilliseconds } from 'date-fns';

import { Calendar } from 'shared/components/common/form/Calendar';
import { localize } from 'shared/components/other';

import classes from './DatePicker.module.scss';

type Props = {
  expires: Date | null;
  expirationLimit: number | null;
  disabled: boolean;
  invalid: boolean;
  onChange: (date: Date | null) => void;
};

export const DatePicker: VFC<Props> = ({ expires, expirationLimit, disabled, invalid, onChange }) => {
  const handleCalendarChange = (date: Date | Date[] | null) => {
    if (!Array.isArray(date)) {
      onChange(date);
    }
  };

  return (
    <Calendar
      value={expires}
      onChange={handleCalendarChange}
      className={cx(classes.calendar, { [classes.calendarInvalid]: invalid })}
      inputClassName={classes.calendarInput}
      disabled={disabled}
      dateFormat={`${localize('until')} dd.mm.yy`}
      placeholder={localize('unlimited')}
      minDate={startOfTomorrow()}
      maxDate={expirationLimit ? addMilliseconds(startOfToday(), expirationLimit) : undefined}
      tooltip={invalid ? `${localize('programmatic.excess-table.settings.checkout-date-expired')}!` : undefined}
    />
  );
};
