import { useStores } from 'core';
import { observer } from 'mobx-react';
import React from 'react';

import { AdvancedFileUploader } from 'shared/components/common/form/AdvancedFileUploader';
import { localize } from 'shared/components/other';
import { FileTypes, MediaTypes } from 'types/cabinetCopying';
import { ExcelFormats } from 'types/file';

import { mediaTypeToFormatMap, mediaTypeToLabelMap } from '../../../../helpers/constants';
import { getStrFileExtensions } from '../../../../helpers/getStrFileExtensions';
import classes from './FileUploadSection.module.scss';

export const FileUploadSection = observer(() => {
  const { advertisement, needMediaFile, needLogoFile, setFileData } = useStores().cabinetCopyingStore;

  const mediaFormats = advertisement?.allowedMediaTypes.map((type) => mediaTypeToFormatMap[type]).join(', ');
  const mediaLabel = advertisement?.allowedMediaTypes.map((type) => mediaTypeToLabelMap[type]).join(' / ');

  const makeHandleSelect = (fileType: FileTypes) => (files: File[]) => {
    setFileData(files[0], fileType);
  };

  return (
    <div className={classes.root}>
      <AdvancedFileUploader
        accept={getStrFileExtensions(Object.values(ExcelFormats))}
        label={localize('cabinet-copying.uploader.upload-new-campaign-attributes-file')}
        onSelect={makeHandleSelect(FileTypes.EXCEL)}
      />
      <AdvancedFileUploader
        accept={mediaFormats}
        label={
          mediaLabel
            ? `${localize('cabinet-copying.uploader.upload')} ${mediaLabel}`
            : localize('cabinet-copying.uploader.upload-file')
        }
        disabled={!needMediaFile}
        onSelect={makeHandleSelect(FileTypes.MEDIA)}
      />
      <AdvancedFileUploader
        accept={mediaTypeToFormatMap[MediaTypes.IMAGE]}
        label={localize('cabinet-copying.uploader.upload-logo')}
        disabled={!needLogoFile}
        onSelect={makeHandleSelect(FileTypes.LOGO)}
      />
    </div>
  );
});
