import React, { useCallback } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import { UploadedStatus } from 'types/campaign';
import classes from './AddFileButton.module.scss';

type Props = {
  /** Additional className */
  className?: string;
  /** On click event handler */
  onClick(type: UploadedStatus): void;
  /** Add type. Default: 'mediaplan' */
  type?: UploadedStatus;
};

export const AddFileButton: React.VFC<Props> = (props) => {
  const { className, onClick, type = 'mediaplan' } = props;
  const titleMap = { mediaplan: 'upload', version: 'upload-version' } as const;
  type TitleId = `campaign-view.mediaplan.actions.${typeof titleMap[UploadedStatus]}`;
  const titleId = `campaign-view.mediaplan.actions.${titleMap[type]}` as TitleId;

  const handleClick = useCallback(() => onClick(type), [onClick, type]);

  return (
    <TextButton
      className={cx(classes.root, className)}
      data-test="add-file-button"
      icon="pi pi-plus"
      onClick={handleClick}
      tooltip={localize(titleId)}
    />
  );
};
