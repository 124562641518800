import chroma from 'chroma-js';

import { IBrand, IBrandStatistics } from 'types/brand';
import { IBrandData } from './Brands.store';

const COLORS = ['#d85d5b', '#57c5c8', '#4c5cf2', '#31b255', '#477fd3', '#d3a03b', '#d26948'];

export const defaultBrandColor = '#ccc';

interface IPagedBrandsArgs {
  brands: IBrand[];
  brandsStatistics: IBrandStatistics[];
}

export const getPagedBrandsData = ({ brands, brandsStatistics }: IPagedBrandsArgs): IBrandData[] =>
  brands.reduce((brandsData, brand) => {
    const statistics = brandsStatistics.find((s) => s.brandId === brand.id) || null;

    return [
      ...brandsData,
      {
        brand,
        statistics,
        color: brand.color ?? statistics?.brandColor ?? defaultBrandColor,
      },
    ];
  }, [] as IBrandData[]);

export const brandColorIterator = (): (() => string) => {
  let index = 0;
  let iterationIndex = 0;

  return () => {
    if (index >= COLORS.length) {
      iterationIndex += 1;
      index = 0;
    }
    const [hue, saturation, lightness] = chroma(COLORS[index]).hsl();

    const newHue = hue + hue * (90 + index) * iterationIndex;
    const boundHue = newHue - 360 * Math.floor(newHue / 360);
    const newColor = chroma.hsl(boundHue, saturation, lightness).hex();
    index += 1;

    return newColor;
  };
};
