import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'primereact/divider';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'shared/components/common/buttons/Button';
import { EditableListSelector } from 'shared/components/common/form/EditableListSelector';
import { localize } from 'shared/components/other';
import { Creative, CreativeMediaType, CreativeUploader } from 'types/creative';
import { CreativeView } from './components/CreativeView';
import { CreativeModal } from './components/CreativeModal';
import classes from './Creatives.module.scss';

type Props = {
  creatives: Creative[];
  currentCreative: Creative | null;
  isEditingAccessible: boolean;
  uploader: CreativeUploader;
  onSelect(creativeId: number): void;
  onLoad(): void;
  onCreate(mediaType: CreativeMediaType, name: string): void;
  onReplace(mediaType: CreativeMediaType): void;
  onRename(newName: string): void;
  onRemove(): void;
  cleanUp(): void;
};

type ModalProps = {
  isVisible: boolean;
  type: 'creating' | 'replacing' | null;
};

const Creatives: React.FC<Props> = ({
  creatives,
  currentCreative,
  isEditingAccessible,
  uploader,
  onSelect,
  onLoad,
  onCreate,
  onReplace,
  onRename,
  onRemove,
  cleanUp,
}) => {
  const [modal, setModal] = useState<ModalProps>({ isVisible: false, type: null });

  const openCreatingModal = useCallback(() => {
    uploader.start();
    setModal({ isVisible: true, type: 'creating' });
  }, [uploader]);

  const openReplacingModal = useCallback(() => {
    uploader.start();
    setModal({ isVisible: true, type: 'replacing' });
  }, [uploader]);

  const closeModal = useCallback(() => {
    setModal({ isVisible: false, type: null });
  }, []);

  useEffect(() => {
    onLoad();
    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <header className={classes.header}>
          <h3 className={classes.title}>{localize('campaign.creatives')}</h3>
          {isEditingAccessible && (
            <Button
              className={classes.addButton}
              icon="pi pi-plus"
              tooltip={localize('campaign-view.creative.actions.add')}
              data-test="add-file-button"
              onClick={openCreatingModal}
            />
          )}
        </header>
        <ScrollPanel className={classes.scrollbar}>
          <EditableListSelector
            className={classes.listSelector}
            options={creatives}
            value={currentCreative?.id}
            testPrefix="creatives"
            onSelect={onSelect}
            onRename={isEditingAccessible ? onRename : undefined}
            onRemove={isEditingAccessible ? onRemove : undefined}
          />
        </ScrollPanel>
      </div>
      <Divider className={classes.divider} layout="vertical" />
      <CreativeView
        creative={currentCreative}
        uploader={uploader}
        onReplace={isEditingAccessible ? openReplacingModal : undefined}
      />
      <CreativeModal
        isVisible={modal.isVisible}
        type={modal.type}
        defaultMediaType={currentCreative?.externalResource ? 'video' : 'image'}
        onSave={modal.type === 'creating' ? onCreate : onReplace}
        onClose={closeModal}
      />
    </div>
  );
};

export { Creatives };
export type { Props as CreativesProps };
