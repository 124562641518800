import { IBrand, IBrandStatistics } from 'types/brand';
import { ICampaign, CampaignStatus } from 'types/campaign';

export const brand: IBrand = {
  id: 1,
  ssoid: 1,
  name: 'Brand',
  logoFileId: null,
  color: null,
  advertisers: [],
  campaigns: [],
};

export const brands = [brand];

export const statistics: IBrandStatistics = {
  brandId: 1,
  brandName: 'Brand',
  brandColor: '#d85d5b',
  activeCampaigns: 2,
  totalCampaigns: 8,
  budgetPlan: 75000000,
  budgetFact: 75000000,
};

export const labelColor = 'rgb(153, 203, 77)';

export const campaign: ICampaign = {
  id: 1,
  name: 'test',
  description: 'test campaign',
  campaignStatus: CampaignStatus.ACTIVE,
  dateStart: '2020-01-01',
  dateEnd: '2020-01-23',
  agency: 1,
  brands: [1],
  subbrands: [],
  categories: [],
  advertiser: 1,
  campaignMetrics: [],
  mediaTypes: [],
  totalBudgetPlan: 2500000,
  totalBudgetFact: 1500000,
  accessibleToClient: true,
  hideBudgetScale: false,
  favorite: false,
};
