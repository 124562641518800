import { EmptyObject } from 'types';
import { ServicesStore } from './Services.store';
import { sharedStores } from './initSharedStores';

export class BaseStore<
  Deps extends Record<string, unknown> = EmptyObject,
  ChildrenStores extends Record<string, unknown> = EmptyObject
> extends ServicesStore {
  protected deps: typeof sharedStores & Deps;

  private _childrenStores = {} as ChildrenStores;

  constructor(deps = {} as Deps) {
    super();
    this.deps = Object.assign(sharedStores, deps);
  }

  get childrenStores(): ChildrenStores {
    return this._childrenStores;
  }

  set childrenStores(stores: ChildrenStores) {
    this._childrenStores = stores;
  }
}
