import cx from 'classnames';
import React, { FC } from 'react';
import classes from '../../Brands.module.scss';

interface IProps {
  onPageSizeChange: (pageSize: number) => void;
  pageSize: number;
}

export const pageSizeList = [10, 25, 50, 100];
export const PageSizeChanger: FC<IProps> = ({ onPageSizeChange, pageSize }: IProps) => (
  <ul className={classes.PageSizeList}>
    {pageSizeList.map((count) => (
      <li key={count}>
        <span
          className={cx(classes.PageSizeItem, {
            [classes.PageSizeItemActive]: count === pageSize,
          })}
          onClick={() => onPageSizeChange(count)}
        >
          {count}
        </span>
      </li>
    ))}
  </ul>
);
