import React, { FC, useRef } from 'react';
import cx from 'classnames';

import { KEY_CODES } from 'helpers/constants';
import { IOption } from 'types';
import { localize, LocalizedMessage } from 'shared/components/other';
import { MultiSelect, MultiSelectChangeParams } from 'shared/components/common/form/MultiSelect';
import { Calendar } from 'shared/components/common/form/Calendar';

import classes from './Filters.module.scss';

export interface IFilters {
  cabinets: string[];
  name: string;
  selectDate: (Date | null)[];
}

interface IProps {
  cabinetOptions: IOption[];
  filters: IFilters;
  onChange: (filters: Partial<IFilters>) => void;
}

const Filters: FC<IProps> = (props: IProps) => {
  const { cabinetOptions, filters, onChange } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSelectCabinet = (e: MultiSelectChangeParams): void => {
    onChange({ cabinets: Array.isArray(e.value) ? e.value : [] });
  };

  const handleSearchBtnClick = (): void => {
    onChange({ name: inputRef.current?.value || '' });
  };

  const handleSearchInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === KEY_CODES.ENTER) {
      onChange({ name: inputRef.current?.value || '' });
    }
  };

  const handleStartDateChange = (startDate: Date | null): void => {
    const [, endDate] = filters.selectDate;
    onChange({ selectDate: [startDate, endDate] });
  };

  const handleEndDateChange = (endDate: Date | null): void => {
    const [startDate] = filters.selectDate;
    onChange({ selectDate: [startDate, endDate] });
  };

  const [startDate, endDate] = filters.selectDate;

  return (
    <div className={classes.Root}>
      <div className={classes.Label}>
        <LocalizedMessage id="name" />
      </div>
      <div className={classes.SearchContainer}>
        <input
          ref={inputRef}
          className={classes.SearchName}
          onKeyDown={handleSearchInputKeyDown}
          data-test="search-name-input"
          tabIndex={0}
          maxLength={1000}
        />
        <i
          className={cx(classes.SearchButton, 'icon icon-search')}
          onClick={handleSearchBtnClick}
          data-test="search-name-button"
        />
      </div>
      <div className={classes.Label}>
        <LocalizedMessage id="cabinet" />
      </div>
      <MultiSelect
        className={classes.cabinetFilter}
        options={cabinetOptions}
        value={filters.cabinets}
        optionValue="label"
        onChange={handleSelectCabinet}
        placeholder={`+ ${localize('add')}`}
        maxSelectedLabels={Infinity}
        filter
        display="chip"
        theme="grey"
      />
      <div className={classes.Label}>
        <LocalizedMessage id="period" />
      </div>
      <LocalizedMessage id={['from', 'to']}>
        {(from: string, to: string) => (
          <div className={classes.period}>
            <label htmlFor="startDate" className={classes.periodLabel}>
              {from}
            </label>
            <Calendar
              value={startDate}
              onChange={handleStartDateChange}
              inputId="startDate"
              maxDate={endDate ?? undefined}
            />
            <label htmlFor="endDate" className={classes.periodLabel}>
              {to}
            </label>
            <Calendar
              value={endDate}
              onChange={handleEndDateChange}
              inputId="endDate"
              minDate={startDate ?? undefined}
            />
          </div>
        )}
      </LocalizedMessage>
    </div>
  );
};

export default Filters;
