import { useStores } from 'core';
import { observer } from 'mobx-react';
import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'shared/components/common/buttons/Button';
import { Footer } from 'shared/components/layout/Footer';
import { LocalizedMessage, LocalizedTitle, ManagedPaginationButtons } from 'shared/components/other';

import { ParamsModal } from '../components/ParamsModal';
import { Filters } from './components/Filters';
import { ReportTable } from './components/ReportTable';
import classes from './ReportList.module.scss';

export const ReportList = observer(() => {
  const { reportListStore, reportConstructorStore } = useStores();
  const { totalElements } = reportListStore;
  const { setModalState, cleanUp } = reportConstructorStore;
  const history = useHistory();
  const location = useLocation<{ keepState?: boolean }>();

  const handleButtonClick = () => {
    setModalState('step1');
  };

  useEffect(() => {
    return () => {
      setModalState(null);
    };
  }, [setModalState]);

  useEffect(() => {
    if (!location.state?.keepState) {
      cleanUp();
    }
  }, [location, cleanUp]);

  return (
    <div className={classes.root}>
      <LocalizedTitle id="site.title.report-constructor" />
      <div className={classes.content}>
        <h1 className={classes.title}>
          <LocalizedMessage id="site.title.report-constructor" />
        </h1>
        <div className={classes.panel}>
          <Filters className={classes.filters} />
          <Button onClick={handleButtonClick} data-test="add-new-report">
            <LocalizedMessage id="report-constructor.add-new-report" />
          </Button>
        </div>
        <ScrollPanel className={classes.tableContainer}>
          <ReportTable />
        </ScrollPanel>
      </div>
      <div className={classes.footer}>
        <span>
          <LocalizedMessage id="cabinet-copying.log.total-records" />: {totalElements}
        </span>
        <ManagedPaginationButtons pagination={reportListStore} />
      </div>
      <Footer />
      <ParamsModal onApply={() => history.push('/report-constructor/new')} onHide={cleanUp} />
    </div>
  );
});
