import { CustomOptions } from 'api/types';
import { AxiosResponse } from 'axios';

import { FileS3Id, FileType } from 'types/file';
import BaseApi from '../BaseApi';

class File extends BaseApi {
  download = async ({ fileId, fileType, fileName }: DownloadFileData, config: CustomOptions = {}) => {
    const response = await this.actions.get<AxiosResponse<Blob>>({
      url: '/file/download',
      data: { fileId, fileType },
      config: { responseType: 'blob', withErrorNotification: false, ...config },
    });

    this.downloadFile(response, fileName);
  };

  logo = ({ fileId }: LogoData, config: CustomOptions = {}) =>
    this.actions.get<ArrayBuffer>({
      url: '/file/download-logo/',
      data: { fileId },
      config: { responseType: 'arraybuffer', ...config },
    });
}

type DownloadFileData = {
  fileId: FileS3Id;
  fileType: FileType;
  fileName?: string;
};

type LogoData = {
  fileId: string;
};

export default File;
