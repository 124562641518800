import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { startOfToday, format } from 'date-fns';
import { Calendar, CalendarChangeParams, CalendarVisibleChangeParams } from 'primereact/calendar';
import { localeOption } from '../../localization';
import { Button } from '../../buttons/Button';
import { formatToPeriodString, monthNavigatorTemplate, yearNavigatorTemplate } from './DateFilter.helpers';
import classes from './DateFilter.module.scss';

type Props = {
  filterValue?: Date[] | null;
  onChange(date: Date[]): void;
  dateFormat?: string;
};

// TODO bug: selecting a month results a calendar close

export const DateFilter: React.VFC<Props> = ({ filterValue, onChange, dateFormat = 'dd.MM.yyyy' }) => {
  const campaignDatePeriod = useMemo(() => filterValue || [], [filterValue]);
  const [date, setDate] = useState<Date[] | undefined>(campaignDatePeriod);
  const [inputValue, setInputValue] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const formattedCampaignDate = filterValue
    ? formatToPeriodString(format(filterValue[0], dateFormat), format(filterValue[1], dateFormat))
    : '';

  useEffect(() => {
    setDate(campaignDatePeriod);
  }, [campaignDatePeriod]);

  const isPeriodPartiallySelected = date?.filter(Boolean)?.length === 1;
  const isDateEqualToCampaignDate = formattedCampaignDate === inputValue;

  const handleChange = useCallback(
    ({ value }: CalendarChangeParams) => {
      if (Array.isArray(value)) {
        setDate(value);
        setInputValue(formatToPeriodString(format(value[0], dateFormat), value[1] ? format(value[1], dateFormat) : ''));
      }
    },
    [dateFormat],
  );

  const handleHide = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = formattedCampaignDate;
      setInputValue(formattedCampaignDate);
      setDate([]);
    }
    if (filterValue) {
      setDate(campaignDatePeriod);
    }
  }, [filterValue, campaignDatePeriod, formattedCampaignDate]);

  const handleVisibleChange = (e: CalendarVisibleChangeParams) => {
    setIsVisible(e.type === 'dateselect' || !isVisible);
    e?.callback?.();
  };

  const handleApplyButtonClick = useCallback(() => {
    if (!Array.isArray(date)) return;
    onChange(date);
    setIsVisible(false);
  }, [date, onChange]);

  const handleCleanButtonClick = useCallback(() => {
    setDate([]);
    if (!inputRef.current) return;
    inputRef.current.value = '';
    setInputValue(inputRef.current.value);
  }, []);

  const footerTemplate = useCallback(() => {
    return (
      <div className={classes.buttons}>
        <Button
          className={cx('p-button-text', classes.button)}
          label={localeOption('clear')}
          onClick={handleCleanButtonClick}
          disabled={!date || !date.length}
        />
        <Button
          className={classes.button}
          label={localeOption('Apply')}
          onClick={handleApplyButtonClick}
          autoFocus
          disabled={isDateEqualToCampaignDate || isPeriodPartiallySelected}
        />
      </div>
    );
  }, [date, handleApplyButtonClick, handleCleanButtonClick, isDateEqualToCampaignDate, isPeriodPartiallySelected]);

  return (
    <Calendar
      value={date ?? startOfToday()}
      visible={isVisible}
      onChange={handleChange}
      onHide={handleHide}
      onVisibleChange={handleVisibleChange}
      placeholder={localeOption('Period')}
      selectionMode="range"
      dateFormat="dd.mm.yy"
      yearRange="2010:2030"
      readOnlyInput
      showMinMaxRange
      inputRef={inputRef}
      inputClassName={cx(classes.calendarInput, {
        [classes.isDateEqualToCampaignDate]: filterValue && isDateEqualToCampaignDate,
      })}
      panelClassName="p-datepicker-small"
      monthNavigatorTemplate={monthNavigatorTemplate}
      yearNavigatorTemplate={yearNavigatorTemplate}
      footerTemplate={footerTemplate}
      monthNavigator
      yearNavigator
      showIcon
    />
  );
};
