import { action, computed, makeObservable, observable } from 'mobx';
import decode from 'jwt-decode';
import Cookies from 'js-cookie';
import handleError from 'helpers/handleError';
import { Response } from 'api/types';
import { IError } from 'types';
import { BaseServerProfile, BasicRole, IProfile, KeyCloakResponse } from 'types/profile';
import { ServicesStore } from 'shared/stores/Services.store';
import { localize } from 'shared/components/other';
import { convertToProfile } from './helpers/converters';
import { baseRoles } from './helpers/roles';
import { AuthCookieKey, removeRootDomainCookies, setRootDomainCookies } from './helpers/cookies';

/* eslint-disable class-methods-use-this */
export class AuthStore extends ServicesStore {
  private _accessTokenCookieKey = 'clientspacetoken' as const;

  private _refreshTokenCookieKey = 'clientspacerefreshtoken' as const;

  @observable
  private _isAuthorizing = false;

  @observable
  private _profile: IProfile | null = null;

  @computed
  get isAuthorizing(): boolean {
    return this._isAuthorizing;
  }

  @computed
  get profile(): IProfile | null {
    return this._profile;
  }

  @computed
  get isAuthorized(): boolean {
    return this._profile !== null;
  }

  @action
  setProfile = (profile: IProfile | null): void => {
    this._profile = profile;
  };

  @action
  setIsAuthorizing = (isAuthorizing: boolean): void => {
    this._isAuthorizing = isAuthorizing;
  };

  @action
  checkAuth = async (): Promise<void> => {
    this.startAuth();

    try {
      const error = new Error('access denied');

      const accessToken = this.getAccessToken();
      const refreshToken = this.getRefreshToken();
      if (!accessToken && refreshToken) {
        await this.logInByRefreshToken();
        if (!this.getAccessToken()) {
          throw error;
        }
      }

      if (!refreshToken || this.isTokenExpired(refreshToken)) {
        throw error;
      }

      const profile = await this.services.api.auth.validateToken(this.getAccessToken(), {
        withErrorNotification: false,
      });
      if (!this.hasAccess(profile)) {
        throw error;
      }

      this.resolveAuth(profile);
    } catch {
      this.rejectAuth();
    }
  };

  @action
  logIn = async (username: string, password: string): Promise<void> => {
    this.startAuth();

    try {
      const response = await this.services.api.auth.logIn({ username, password });
      if (response.error) {
        throw response.error;
      }

      const authResponse = response.data;
      if (!this.hasAccess(authResponse)) {
        const error = new Error(localize('auth.errors.authorization-error'));
        handleError(error as IError);
        throw error;
      }

      this.setTokens(authResponse.access_token, authResponse.refresh_token);
      this.resolveAuth(authResponse);
    } catch (error) {
      this.rejectAuth();
    }
  };

  logInByRefreshToken = async (): Promise<Response<KeyCloakResponse>> => {
    const response = await this.services.api.auth.logInByRefreshToken(this.getRefreshToken());
    if (response.data) {
      this.setTokens(response.data.access_token, response.data.refresh_token);
    }
    return response;
  };

  @action
  logOut = async (): Promise<void> => {
    if (this.isAuthorized) {
      this.services.api.auth.logOut(this.getRefreshToken());
    }
    this.rejectAuth();
  };

  getAccessToken = (): string | null => {
    return Cookies.get(this._accessTokenCookieKey) ?? null;
  };

  getRefreshToken = (): string | null => {
    return Cookies.get(this._refreshTokenCookieKey) ?? null;
  };

  rewriteTokensInCookies = async (): Promise<void> => {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();
    if (accessToken && refreshToken) {
      this.setTokens(accessToken, refreshToken);
      return;
    }

    if (!accessToken && refreshToken) {
      this.logInByRefreshToken();
    }
  };

  private setAccessToken = (token: string): void => {
    setRootDomainCookies(this._accessTokenCookieKey, token);
  };

  private setRefreshToken = (token: string): void => {
    setRootDomainCookies(this._refreshTokenCookieKey, token);
  };

  private setTokens = (accessToken: string, refreshToken: string): void => {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  };

  private removeToken = (key: AuthCookieKey): void => {
    removeRootDomainCookies(key);
  };

  private removeTokens = (): void => {
    this.removeToken(this._accessTokenCookieKey);
    this.removeToken(this._refreshTokenCookieKey);
  };

  private startAuth = () => {
    this.setIsAuthorizing(true);
  };

  private resolveAuth = (profile: BaseServerProfile) => {
    this.setProfile(convertToProfile(profile));
    this.setIsAuthorizing(false);
  };

  private rejectAuth = (): void => {
    this.setProfile(null);
    this.setIsAuthorizing(false);
    this.removeTokens();
  };

  private isTokenExpired = (token: string): boolean => {
    try {
      const { exp } = decode(token);
      return Date.now() >= exp * 1000;
    } catch {
      return true;
    }
  };

  private hasAccess = (profile: BaseServerProfile | null): profile is BaseServerProfile =>
    !!(
      profile?.projects?.includes('CLIENTSPACE') &&
      profile?.roles?.some((role) => baseRoles.includes(role as BasicRole))
    );

  constructor() {
    super();
    makeObservable(this);
  }
}
