import React from 'react';
import { format } from 'date-fns';
import classes from './DateCell.module.scss';
import { baseCellWrapper } from '../baseCellWrapper';
import { ColumnType } from '../../../types';

type Props = {
  value: React.ReactText;
  type?: ColumnType;
};

const DateCellComponent: React.VFC<Props> = ({ value, type }) => {
  return (
    <div className={classes.root}>
      {type === 'datetime' ? (
        <>
          {format(new Date(value), 'dd.MM.yyyy')}
          <br />
          {format(new Date(value), 'HH:mm:ss')}
        </>
      ) : (
        format(new Date(value), 'dd.MM.yyyy')
      )}
    </div>
  );
};

export const DateCell = baseCellWrapper(DateCellComponent);
