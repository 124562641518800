import React, { useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import cx from 'classnames';
import { Icon } from 'shared/components/common/misc/Icon';
import { LocalizedMessage } from 'shared/components/other';
import { Creative } from 'types/creative';
import classes from './Preview.module.scss';

type Props = {
  creative: Creative;
};

const Preview: React.FC<Props> = ({ creative }) => {
  const imageRef = useRef<HTMLImageElement>(null);

  const toggleImageFullscreen = () => {
    if (!document.fullscreenElement) {
      imageRef.current?.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  if (creative.uploadStatus.type === 'inProgress') {
    const { progress } = creative.uploadStatus;
    return (
      <div className={cx(classes.root, classes.rootLoading)}>
        <LocalizedMessage
          id={
            progress === null
              ? 'campaign-view.creative.file-is-loading'
              : 'campaign-view.creative.new-file-is-uploading'
          }
        />
        ...
        <ProgressBar
          className={classes.progressbar}
          mode={progress === null ? 'indeterminate' : 'determinate'}
          value={progress}
          color="var(--color-white-blue)"
        />
      </div>
    );
  }

  if (creative.externalResource) {
    return (
      <div className={classes.root}>
        <iframe
          src={creative.externalResource}
          title={creative.name}
          allowFullScreen
          sandbox="allow-scripts allow-same-origin" // NOTE: video does not play without these attributes
        />
      </div>
    );
  }

  if (creative.imageSrc) {
    return (
      <div className={classes.root}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img ref={imageRef} src={creative.imageSrc} alt={creative.name} onClick={toggleImageFullscreen} />
      </div>
    );
  }

  return (
    <div className={cx(classes.root, classes.rootNoContent)}>
      <Icon icon="pi pi-ban" />
      <LocalizedMessage
        id={creative.fileS3Id ? 'campaign-view.creative.failed-to-display-file' : 'campaign-view.creative.missing-file'}
      />
    </div>
  );
};

export { Preview };
