import React from 'react';
import cx from 'classnames';
import classes from './SelectButtonItem.module.scss';

type Props = {
  name: string;
  count: number;
  icon?: string;
  iconColor?: 'ok' | 'error' | 'warning';
  style?: React.CSSProperties;
};

export const SelectButtonItem: React.VFC<Props> = (props) => {
  const { name, count, icon, iconColor, style } = props;

  return (
    <div className={classes.root}>
      <span>{name}</span>
      <span>{count}</span>
      <i className={cx(`pi ${icon}`, classes.icon, iconColor && classes[iconColor])} style={style} />
    </div>
  );
};
