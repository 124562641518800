import React from 'react';
import { CalendarMonthNavigatorTemplateParams, CalendarYearNavigatorTemplateParams } from 'primereact/calendar';
import cx from 'classnames';
import { isEqual, startOfDay } from 'date-fns';
import { DateType } from './Calendar';
import { Dropdown } from '../Dropdown';

import classes from './Calendar.module.scss';

const makeDropdownTemplate = (className: string) => (
  e: CalendarMonthNavigatorTemplateParams | CalendarYearNavigatorTemplateParams,
): React.ReactNode => {
  return (
    <Dropdown
      className={cx(classes.calendarDropdown, className)}
      value={e.value}
      options={e.options}
      onChange={(event) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.onChange(event.originalEvent, event.value);
      }}
    />
  );
};

export const monthNavigatorTemplate = makeDropdownTemplate(classes.monthDropdown);

export const yearNavigatorTemplate = makeDropdownTemplate(classes.yearDropdown);

const isEqualDate = (oldDate: Date, newDate: Date) => isEqual(startOfDay(oldDate), startOfDay(newDate));

export const isEqualDates = (oldDate: DateType, newDate: DateType): boolean => {
  if (oldDate === null && newDate === null) {
    return true;
  }
  if (oldDate instanceof Date && newDate instanceof Date) {
    return isEqualDate(oldDate, newDate);
  }
  if (Array.isArray(oldDate) && Array.isArray(newDate)) {
    return isEqualDate(oldDate[0], newDate[0]) && isEqualDate(oldDate[1], newDate[1]);
  }
  return false;
};
