import { MediaTypes } from 'types/cabinetCopying';

export const getFileType = (file: File): Exclude<MediaTypes, MediaTypes.LOGO> | null => {
  if (file.type.match('image.*')) {
    return MediaTypes.IMAGE;
  }

  if (file.type.match('video.*')) {
    return MediaTypes.VIDEO;
  }

  return null;
};
