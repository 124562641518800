import { Badge } from 'primereact/badge';
import React from 'react';

import { StatusOption } from 'types/cabinetCopying';

import classes from './CabinetStatusCell.module.scss';

export const CabinetStatusCell: React.FC<StatusOption> = ({ label, badge }) => {
  return (
    <div className={classes.root}>
      <Badge severity={badge} />
      <span>{label}</span>
    </div>
  );
};
