import React from 'react';
import { Badge, BadgeSeverityType } from 'primereact/badge';
import cx from 'classnames';
import { CabinetStatus } from 'types/programmatic/excess';
import classes from './CabinetStatusCell.module.scss';

type Props = {
  cabinetStatus: CabinetStatus | null;
  active: number | null;
  total: number | null;
};

export const CabinetStatusCell: React.VFC<Props> = (props) => {
  const { cabinetStatus, active, total } = props;
  const severity = getSeverity(cabinetStatus);

  return (
    <div className={cx(classes.root)}>
      <Badge className={classes.badge} severity={severity} />
      {active !== null && total !== null && (
        <span>
          {active}/{total}
        </span>
      )}
    </div>
  );
};

function getSeverity(cabinetStatus: CabinetStatus | null): BadgeSeverityType | undefined {
  if (cabinetStatus === CabinetStatus.STARTED) {
    return 'success';
  }
  if (cabinetStatus === CabinetStatus.STOPPED) {
    return 'danger';
  }
  if (cabinetStatus === CabinetStatus.IN_PROGRESS) {
    return 'warning';
  }
  return undefined;
}
