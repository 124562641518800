import { action, makeObservable, observable, runInAction } from 'mobx';
import { AccountSystemStatus, AccountUserStatus, IAccount, ISelectedAccount } from 'types/account';
import { openPopup } from 'helpers/utils';
import handleError from 'helpers/handleError';
import showConfirmation from 'helpers/showConfirmation';
import { BaseStore } from 'shared/stores';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { AccountsStore } from '..';

type Deps = {
  accountsStore: AccountsStore;
};

export class AccountsViewStore extends BaseStore<Deps> {
  @observable
  loadingTokenId: number | null = null;

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
  }

  @action
  handleAllCheckboxClick = (): void => {
    const { dataList, selectedAccounts } = this.deps.accountsStore;
    const allAccounts: ISelectedAccount[] = dataList.map(
      (account: IAccount): ISelectedAccount => ({
        accountId: account.id,
        cabinetId: account.cabinetId,
      }),
    );

    this.deps.accountsStore.selectedAccounts = allAccounts.length === selectedAccounts.length ? [] : allAccounts;
  };

  @action
  handleUpdateTokenButtonClick = async ({ accountId, cabinetId }: ISelectedAccount): Promise<void> => {
    this.loadingTokenId = accountId;
    const response = await this.services.api.accounts.getTokenLink(
      { accountId, cabinetId },
      { withErrorNotification: false },
    );
    if (response.error) {
      const error = new Error(localize('errors.account.token-not-received'));
      handleError(error);
    } else {
      openPopup(response.data.token_link, () => this.handleTokenWindowClose(accountId));
    }

    this.loadingTokenId = null;
  };

  @action
  private handleTokenWindowClose = async (accountId: number): Promise<void> => {
    await this.deps.accountsStore.loadData();

    const selectedAccount = this.deps.accountsStore.dataList.find((account: IAccount) => account.id === accountId);

    if (selectedAccount?.systemStatus === AccountSystemStatus.ACTIVE) {
      toast.success(localize('account.notify.get-token-success'));

      if (selectedAccount.userStatus === AccountUserStatus.DISABLED) {
        await this.deps.accountsStore.updateStatusAccount(selectedAccount, AccountUserStatus.ENABLED);
      }

      return;
    }

    toast.error(localize('errors.account.token-not-received'));
  };

  @action
  handleDeleteBtnClick = async (accounts: ISelectedAccount[]): Promise<void> => {
    const localizedId = accounts.length > 1 ? 'accounts.remove-accounts-confirm' : 'accounts.remove-account-confirm';
    const isConfirmed = await showConfirmation({
      title: `${localize(localizedId)}?`,
      type: 'removal',
    });

    if (isConfirmed) {
      this.deleteAccounts(accounts);
    }
  };

  private async deleteAccounts(accounts: ISelectedAccount[]): Promise<void> {
    const promises = accounts.map((account: ISelectedAccount) => this.services.api.accounts.delete(account));
    const responses = await Promise.all(promises);
    if (responses.some((response) => response.error)) return;

    toast.success(
      localize(accounts.length === 1 ? 'account.notify.account-deleted' : 'account.notify.accounts-deleted'),
    );

    await this.deps.accountsStore.loadData();

    runInAction(() => {
      this.deps.accountsStore.selectedAccounts = this.deps.accountsStore.selectedAccounts.filter(
        (item) => !accounts.find((account) => AccountsStore.isEqualSelectedAccounts(item, account)),
      );
    });
  }

  @action
  handleCheckboxClick = (account: ISelectedAccount): void => {
    const { isEqualSelectedAccounts } = AccountsStore;
    const selectedAccount = this.deps.accountsStore.selectedAccounts.find((item) =>
      isEqualSelectedAccounts(item, account),
    );
    this.deps.accountsStore.selectedAccounts = selectedAccount
      ? this.deps.accountsStore.selectedAccounts.filter((item) => !isEqualSelectedAccounts(item, account))
      : [...this.deps.accountsStore.selectedAccounts, account];
  };
}
