import { observable, action, makeObservable, computed } from 'mobx';
import { BaseStore } from 'shared/stores';
import { IOption } from 'types';
import { IFileStatus, ITvMediaplan, IWebMediaplan } from 'types/mediaplan';
import { FileS3Id } from 'types/file';
import { CampaignViewStore } from './CampaignView.store';

type Deps = {
  campaignViewStore: CampaignViewStore;
};

export abstract class MediaplansStore<T extends ITvMediaplan | IWebMediaplan> extends BaseStore<Deps> {
  @observable
  protected _mediaplans: T[] = [];

  @observable
  protected _hasCurrentMediaplans = false;

  @observable
  protected _currentMediaplanId: number | null = null;

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
  }

  @computed
  get mediaplans(): T[] {
    return this._mediaplans;
  }

  @computed
  get hasCurrentMediaplans(): boolean {
    return this._hasCurrentMediaplans;
  }

  @computed
  get currentMediaplanId(): number | null {
    return this._currentMediaplanId;
  }

  @action
  changeCurrentMediaplan(id: number): void {
    this._currentMediaplanId = id;
  }

  @action
  cleanUp(): void {
    this._mediaplans = [];
    this._hasCurrentMediaplans = false;
    this._currentMediaplanId = null;
  }

  abstract get currentMediaplan(): T | null;

  abstract loadMediaplansCount(): Promise<void>;

  abstract loadMediaplans(): Promise<void>;

  abstract uploadMediaplan(formData: FormData, name: string): Promise<void>;

  abstract downloadMediaplan(fileS3Id: FileS3Id): Promise<void>;

  abstract renameMediaplan(newName: string): Promise<void>;

  abstract removeMediaplan(versionId: number | null): Promise<void>;

  abstract changeMediaplanStatus(newStatus: IOption<IFileStatus>, mediaplanVersionId?: number): Promise<void>;
}
