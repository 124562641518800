import React from 'react';
import { CalendarMonthNavigatorTemplateParams, CalendarYearNavigatorTemplateParams } from 'primereact/calendar';
import cx from 'classnames';
import { Dropdown } from '../Dropdown';
import classes from './DateFilter.module.scss';

const makeDropdownTemplate = (className: string) => (
  e: CalendarMonthNavigatorTemplateParams | CalendarYearNavigatorTemplateParams,
): React.ReactNode => {
  return (
    <Dropdown
      className={cx(classes.calendarDropdown, className)}
      value={e.value}
      options={e.options}
      onChange={(event) => {
        // TODO разобраться почему в доке и здесь работает, но ругается
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.onChange(event.originalEvent, event.value);
      }}
    />
  );
};

export const monthNavigatorTemplate = makeDropdownTemplate(classes.monthDropdown);

export const yearNavigatorTemplate = makeDropdownTemplate(classes.yearDropdown);

export const formatToPeriodString = (start: string, end: string): string => `${start} - ${end}`;
