import { localize } from 'shared/components/other';
import { Column } from 'shared/components/common/misc/Table';

export const columns: Column[] = [
  {
    field: 'id',
  },
  {
    field: 'name',
    header: localize('tv-mediaplan.creatives.description'),
  },
  {
    field: 'duration',
    header: localize('tv-mediaplan.creatives.duration'),
  },
  {
    field: 'tnsid',
    header: localize('tv-mediaplan.creatives.tns'),
    editor: 'text',
  },
  {
    field: 'vimbid',
    header: localize('tv-mediaplan.creatives.vimb'),
    editor: 'text',
  },
  {
    field: 'clipDateStart',
    header: localize('tv-mediaplan.creatives.start-date'),
    template: 'date',
    editor: 'date',
  },
  {
    field: 'clipDateEnd',
    header: localize('tv-mediaplan.creatives.finish-date'),
    template: 'date',
    editor: 'date',
  },
];
