import { AxiosResponse } from 'axios';
import { IError } from 'types';

export async function convertError(error: AxiosResponse): Promise<IError> {
  const { status: statusCode, config } = error;
  const data = config?.responseType === 'blob' ? JSON.parse(await error.data.text()) : error.data;

  return {
    ...data,
    statusCode,
    message: data.responseObject?.error ?? data.responseObject?.message ?? data.message,
    description: data.responseObject?.error_description ?? data.description ?? null,
    userErrorMessage: data.responseObject?.userErrorMessage ?? data.userErrorMessage,
    userErrorMessageList: data.responseObject?.userErrorMessageList ?? data.userErrorMessageList,
  };
}
