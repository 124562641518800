import React from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { UploadedStatus } from 'types/campaign';
import { FileUploader, FileUploaderProps } from 'shared/components/common/form/FileUploader';
import classes from './MediaplanUploader.module.scss';

type Props = {
  /** Additional className */
  className?: string;
  /** Uploaded status */
  uploadedStatus: UploadedStatus | null;
} & Omit<FileUploaderProps, 'label'>;

export const MediaplanUploader: React.VFC<Props> = (props) => {
  const { className, uploadedStatus, ...fileUploaderProps } = props;
  const label = localize(
    uploadedStatus === 'mediaplan' ? 'campaign-view.mediaplan.add-campaign' : 'campaign-view.mediaplan.add-file',
  );
  return <FileUploader className={cx(classes.root, className)} label={label} {...fileUploaderProps} />;
};

export type { Props as WebMediaplanUploaderProps };
