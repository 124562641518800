import React from 'react';
import { PaginatorProps } from 'primereact/paginator';
import cx from 'classnames';
import { PaginatorProvider } from './Paginator.provider';
import { Pagination } from './contextDependentComponents';
import classes from './Paginator.module.scss';

type Props = Pick<PaginatorProps, 'className' | 'totalRecords' | 'rows' | 'rowsPerPageOptions' | 'onPageChange'> & {
  /** Current page */
  page?: number;
  children?: React.ReactNode;
};

export const Paginator: React.VFC<Props> = React.memo((props) => {
  const { children, className, page, rows, ...restProps } = props;
  const first = page && rows ? page * rows : undefined;
  return (
    <div className={cx(classes.root, className)}>
      <PaginatorProvider>
        <Pagination first={first} rows={rows} {...restProps} />
        {children}
      </PaginatorProvider>
    </div>
  );
});

export type { Props as PaginatorProps };
