import React, { useRef, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import { useStores } from 'core';
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { useMount } from 'ahooks';

import { LocalizedTitle } from 'shared/components/other';
import { ControlPanel, ExcessTable, Sidebar } from './components';

import classes from './Excess.module.scss';

const Excess = observer(() => {
  const {
    excessStore: { loadData, activeTab, setActiveTab, cancelDataLoading },
    sidebarStore: sidebar,
  } = useStores();

  useMount(() => {
    loadData();
  });

  useEffect(() => {
    return cancelDataLoading;
  }, [cancelDataLoading]);

  useEffect(() => {
    if (!sidebar.isVisible && activeTab !== null) {
      sidebar.show({
        content: <Sidebar />,
        onHide: () => setActiveTab(null),
      });
    }
  }, [activeTab, setActiveTab, sidebar, sidebar.isVisible]);

  const ref = useRef<HTMLDivElement>(null);
  const headerTemplate = useCallback(
    (options: PanelHeaderTemplateOptions) => {
      return ref.current ? ReactDOM.createPortal(options.togglerElement, ref.current) : null;
    },
    [ref],
  );

  return (
    <div className={classes.root}>
      <LocalizedTitle id="site.title.programmatic" />
      <Panel
        className={classes.panel}
        toggleable
        collapseIcon="pi pi-chevron-up"
        expandIcon="pi pi-chevron-down"
        headerTemplate={headerTemplate}
      >
        <ControlPanel />
      </Panel>
      <div className={classes.excessTable} ref={ref}>
        <ExcessTable />
      </div>
    </div>
  );
});

export { Excess };
