import React from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { getProgressBudget, getBudgetTooltip, getBudgetValue, getPercentValue } from '../../helpers';
import classes from './BudgetScale.module.scss';

type Props = {
  budgetFact: number;
  budgetPlan: number;
  className?: string;
  lang: string;
};

export const BudgetScale: React.FC<Props> = (props) => {
  const { budgetFact, budgetPlan, className, lang } = props;
  const progressBudget = getProgressBudget(budgetPlan, budgetFact);
  const currencyUnit = localize('currency.unit');

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.info}>
        <span title={getBudgetTooltip(budgetPlan, lang, currencyUnit)}>{getBudgetValue(budgetPlan)}</span>
        <span className={classes.percent}>{getPercentValue(progressBudget.percent)}</span>
        <span
          className={cx(classes.factValue, { [classes[progressBudget.exceeding ? 'warning' : 'success']]: budgetFact })}
          title={getBudgetTooltip(budgetFact, lang, currencyUnit)}
        >
          {getBudgetValue(budgetFact)}
        </span>
      </div>
      <div className={classes.bar}>
        <div className={cx(classes.scale, classes.success)} style={{ width: `${progressBudget.correctly}%` }} />
        <div className={cx(classes.scale, classes.warning)} style={{ width: `${progressBudget.exceeding}%` }} />
      </div>
    </div>
  );
};

export type { Props as BudgetScaleProps };
