import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import { LocaleIdType } from 'locales';
import { LocalizedMessage } from 'shared/components/other';
import { FileUploader } from 'shared/components/common/form/FileUploader';
import { Button } from 'shared/components/common/buttons/Button';
import { useStores } from 'core';
import { TemplateType } from './types';

import classes from './Templates.module.scss';

const templateTypeToLocalizedIdMap: Record<TemplateType, LocaleIdType> = {
  nationalTV: 'administration.templates.national-tv-template',
  regionalTV: 'administration.templates.regional-tv-template',
  uploadStatistics: 'administration.templates.upload-statistics-template',
};

export const Templates = observer(() => {
  const {
    templatesStore: { templateTypes, uploadTemplate, downloadTemplate },
  } = useStores();

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <h1 className={classes.title}>
          <LocalizedMessage id="administration.templates" />
        </h1>
      </header>

      <div className={classes.content}>
        {templateTypes.map((type) => (
          <Fragment key={type}>
            <span>
              <LocalizedMessage id={templateTypeToLocalizedIdMap[type]} />
            </span>
            <label htmlFor={`uploader_${type}`} className={classes.upload}>
              <Button size="big">
                <LocalizedMessage id="upload" />
              </Button>
              <FileUploader inputId={`uploader_${type}`} onUpload={(data: FormData) => uploadTemplate(type, data)} />
            </label>
            <Button size="big" onClick={() => downloadTemplate(type)}>
              <LocalizedMessage id="download" />
            </Button>
          </Fragment>
        ))}
      </div>
    </div>
  );
});
