import HT from 'handsontable';

export function makeAfterChange<R extends HT.RowObject>(onChange: (data: R[]) => void) {
  return function afterChange(tableChanges: HT.CellChange[] | null): void {
    if (!tableChanges) return;
    const hot = this as HT;
    const data = hot.getSourceData() as R[];
    onChange(data);
  };
}
