import wordWrap from 'word-wrap';
import { localize, ReactHotTable } from 'shared/components/other';
import { LocaleIdType } from 'locales';
import { isNotNull } from 'helpers/utils';
import { Dictionary } from 'types/dictionary';
import { ServerModels as SM } from 'types/server';
import { linkRowRenderer } from '../../AdFoxCampaigns/components/AdfoxTable/renderers';

export function convertServerDataToColumns(
  columnsMetadata: SM.ColumnMetadata[],
  attributes: SM.AbstractColumn[],
  metrics: SM.AbstractColumn[],
  dictionaries: Dictionary[],
  dataType: 'campaigns' | 'banners' = 'campaigns',
): ReactHotTable.Column[] {
  const convertedColumns = columnsMetadata
    .map((column) => convertServerDataToColumn(column, attributes, metrics, dictionaries, dataType))
    .filter(isNotNull);

  const firstColumns: ReactHotTable.Column[] = [
    {
      title: '',
      name: 'isSelected',
      type: 'BOOLEAN',
      readOnly: false,
      isRequired: false,
    },
    {
      title: '',
      name: 'errors',
      type: 'STRING',
      readOnly: true,
      disableVisualSelection: true,
      isRequired: false,
    },
  ];
  const lastColumn: ReactHotTable.Column = {
    title: '',
    name: 'resetRowContent',
    type: 'STRING',
    readOnly: true,
    disableVisualSelection: true,
    isRequired: false,
  };

  return [...firstColumns, ...convertedColumns, lastColumn];
}

function convertServerDataToColumn(
  column: SM.ColumnMetadata,
  attributes: SM.AbstractColumn[],
  metrics: SM.AbstractColumn[],
  dictionaries: Dictionary[],
  dataType: 'campaigns' | 'banners',
): ReactHotTable.Column | ReactHotTable.DictionaryColumn | null {
  const foundColumn =
    column.attributeId === null
      ? metrics.find((metric) => metric.id === column.metricId)
      : attributes.find((attribute) => attribute.id === column.attributeId);
  if (foundColumn === undefined) return null;

  return {
    title: wordWrap(localize(foundColumn.title as LocaleIdType), { width: 10, newline: '<br>' }),
    name: foundColumn.key,
    type: foundColumn.valueType,
    readOnly: !column.isManualUpdatable,
    isRequired: column.isRequiredToFill,
    source:
      foundColumn.valueType === 'DICTIONARY'
        ? getDictionaryValues(dictionaries, foundColumn.cabinetDictionaryId)
        : undefined,
    renderer: foundColumn.key === 'name' && dataType === 'campaigns' ? linkRowRenderer : undefined,
  };
}

function getDictionaryValues(dictionaries: Dictionary[], dictionaryId?: number) {
  const dictionary = dictionaries.find((dict) => dict.dictionaryId === dictionaryId);

  return dictionary?.dictionaryValues ?? [];
}
