import AdFoxCampaigns from './AdfoxCampaigns.graphql';
import AdFoxCampaignLog from './AdfoxCampaignLog.graphql';
import AdFoxBanners from './AdfoxBanners.graphql';
import AdFoxBannerLog from './AdfoxBannerLog.graphql';
import AdFoxSummary from './AdfoxSummary.graphql';

export const queries = {
  AdFoxCampaigns,
  AdFoxBanners,
  AdFoxCampaignLog,
  AdFoxBannerLog,
  AdFoxSummary,
};
