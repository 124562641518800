import React, { VFC } from 'react';

import { localize, LocalizedTitle, PowerBIReport } from 'shared/components/other';

import classes from './CompetitorAnalysis.module.scss';

export const CompetitorAnalysis: VFC = () => {
  return (
    <>
      <LocalizedTitle id="site.title.competitor-analysis" />
      <div className={classes.Dashboard}>
        <PowerBIReport pageName="COMPETITOR_ANALYSIS" isFullHeight fallbackMessage={localize('powerbi.nodata')} />
      </div>
    </>
  );
};
