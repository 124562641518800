import React, { FC } from 'react';
import { LocalizedMessage } from 'shared/components/other';
import classes from './FallbackComponent.module.scss';

const FallbackComponent: FC = () => (
  <div className={classes.FallbackComponent}>
    <div className={classes.FallbackComponentMessage}>
      <LocalizedMessage id="errors.unknown-error" />
    </div>
    <div className={classes.FallbackComponentMessage}>
      <LocalizedMessage id="errors.support" />
    </div>
  </div>
);

export default FallbackComponent;
