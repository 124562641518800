export const flatTree = <T extends { children: T[] | null }>(tree: T[]): T[] => {
  const array: T[] = [];
  for (const node of tree) {
    array.push(node);
    if (node.children?.length) {
      array.push(...flatTree(node.children));
    }
  }

  return array;
};
