import React, { useMemo, VFC } from 'react';
import cx from 'classnames';
import { useBoolean } from 'ahooks';
import { MultiSelect as PrimeMultiSelect, MultiSelectProps, MultiSelectChangeParams } from 'primereact/multiselect';
import { makeSelectedItemsLabel } from './MultiSelect.helpers';
import { defaultTooltipOptions } from '../../defaultProps';
import classes from './MultiSelect.module.scss';

type Props = Omit<MultiSelectProps, 'size'> & {
  /** Size of the component. Default: 'medium' */
  size?: 'small' | 'medium';
  /** Component color theme. Default: 'light' */
  theme?: 'light' | 'dark' | 'grey';
  /** Specifies the valid/invalid state. Default: false */
  invalid?: boolean;
};

export const MultiSelect: VFC<Props> = (props) => {
  const {
    className,
    tooltipOptions,
    options,
    value,
    size = 'medium',
    theme = 'light',
    showClear = true,
    invalid = false,
    ...restProps
  } = props;
  const [isMenuVisible, { setTrue: show, setFalse: hide }] = useBoolean(false);

  const panelFooterTemplate = useMemo(
    () => value?.length > 1 && <div className={classes.panelFooter}>Выбрано: {value?.length}</div>,
    [value?.length],
  );

  const selectedItemsLabel = useMemo(() => {
    return options ? makeSelectedItemsLabel(options, value) : undefined;
  }, [options, value]);

  return (
    <PrimeMultiSelect
      className={cx(classes.root, className, {
        [classes.isActive]: !!value,
        [classes.isMenuVisible]: isMenuVisible,
        [classes.isInvalid]: invalid,
        [classes[`${size}Size`]]: !!size,
        [classes[`${theme}Theme`]]: !!theme,
      })}
      dropdownIcon="pi icon-arrow-down"
      maxSelectedLabels={1}
      onHide={hide}
      onShow={show}
      options={options}
      panelFooterTemplate={panelFooterTemplate}
      resetFilterOnHide
      selectedItemsLabel={selectedItemsLabel}
      tooltipOptions={{ ...defaultTooltipOptions, ...tooltipOptions }}
      showClear={showClear}
      value={value}
      {...restProps}
    />
  );
};

export type { Props as MultiSelectProps, MultiSelectChangeParams };
