import HT from 'handsontable';
import { BaseValidator } from 'handsontable/validators';

import { columnTypeToHTCellTypeMap } from '../columnTypeMaps/columnTypeToHTCellTypeMap';
import { columnTypeToHTValidatorMap } from '../columnTypeMaps/columnTypeToHTValidatorMap';
import { Column } from '../../types';

export function makeValidator(column: Column): BaseValidator {
  return function validator(this: HT.CellProperties, value: HT.CellValue, resolve: (valid: boolean) => void): void {
    const columnType = columnTypeToHTCellTypeMap[column.type];
    const cellValidator = columnTypeToHTValidatorMap[columnType];

    let isValid = true;
    const getValidStateByHTValidator = (valid: boolean) => {
      isValid = valid;
    };
    if (cellValidator) {
      cellValidator.call(this, value, getValidStateByHTValidator);
    }

    const isNotEmpty = !column.isRequired || Boolean(value);

    resolve(isValid && isNotEmpty);
  };
}
