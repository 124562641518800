import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { PaginationStore } from 'shared/stores';
import { Row } from 'shared/components/other';
import { SortingOptions } from 'types';
import { LogFilters, LogItem } from 'types/cabinetCopying';

import { convertToTableRows, defaultSorting, PAGE_SIZE } from '../helpers/log';

type InvalidationScope = [number, LogFilters, SortingOptions];

export class CabinetCopyingLogStore extends PaginationStore<Row<LogItem>[]> {
  @observable
  filters: LogFilters = {};

  @observable
  sorting: SortingOptions = defaultSorting;

  protected _data: Row<LogItem>[] = [];

  protected invalidationScope = (): InvalidationScope => [this.page, this.filters, this.sorting];

  constructor() {
    super();
    makeObservable(this);

    this.setup();
    this.setPageSize(PAGE_SIZE);
  }

  @computed
  get hasFilters(): boolean {
    return Object.keys(this.filters).length > 0;
  }

  @action
  cleanUp = (): void => {
    this._data = [];
    this.filters = {};
    this.sorting = defaultSorting;
    this.setPage(0);
  };

  @action
  setFilters = (filters: LogFilters): void => {
    this.filters = filters;
  };

  @action
  setSorting = (sortingOptions: SortingOptions): void => {
    this.sorting = sortingOptions ?? defaultSorting;
  };

  loadData = async (): Promise<void> => {
    const response = await this.services.api.cabinetCopying.logEntries({
      filters: this.hasFilters ? this.filters : undefined,
      sorting: [this.sorting ?? defaultSorting],
      page: { number: this.page, size: this.pageSize },
    });

    if (response.data) {
      const { data, totalPages, total } = response.data;

      runInAction(() => {
        this._data = convertToTableRows(data);
        this._totalPages = totalPages;
        this._totalElements = total;
      });
    }
  };

  protected revalidate = async ([page]: InvalidationScope, [prevPage]: InvalidationScope): Promise<void> => {
    if (page !== prevPage || prevPage === 0) {
      await this.loadData();
    } else {
      this.setPage(0);
    }
  };
}
