import React, { FC, PureComponent } from 'react';

import { Field, FieldDescription } from 'types/powerBI';
import { getGroupOrder, convertLabel, getLevel, getRowLabel } from '../../helpers';
import RowComponent from '../RowComponent';
import classes from './SectionComponent.module.scss';

interface IProps {
  fields: Record<number, Field>;
  isAdvertiserSection: boolean;
  description: FieldDescription[];
  checkboxType: 'enable' | 'useInternal';
  onChange: (newField: Field) => void;
}

class SectionComponent extends PureComponent<IProps> {
  renderGroup: FC<FieldDescription[]> = (group: FieldDescription[]) => {
    const { fields, checkboxType, onChange } = this.props;
    const actualDescription = group.sort(getGroupOrder);
    const isNeedGroupLabel = !actualDescription.find((d) => !d.mediaType && !d.positionName);

    return (
      <div className={classes.Group}>
        {isNeedGroupLabel && <div className={classes.GroupLabel}>{convertLabel(actualDescription[0].pageName)}</div>}
        {actualDescription.map((d) => (
          <RowComponent
            key={d.id}
            label={convertLabel(getRowLabel(d))}
            level={getLevel(d)}
            checkboxType={checkboxType}
            field={fields[d.id]}
            onChange={onChange}
          />
        ))}
      </div>
    );
  };

  render() {
    const { description, checkboxType, fields, onChange, isAdvertiserSection } = this.props;
    const brandGroup = description.filter((d) => d.pageName === 'BRAND_LIST');
    const brandPage = description.filter((d) => d.pageName === 'BRAND');
    const campaignGroup = description.filter((d) => d.pageName === 'CAMPAIGN');
    const other = description.filter((d) => !['BRAND_LIST', 'BRAND', 'CAMPAIGN'].includes(d.pageName));

    return (
      <div>
        {this.renderGroup(brandGroup)}
        {this.renderGroup(brandPage)}
        {this.renderGroup(campaignGroup)}
        {other
          .filter((it) => !isAdvertiserSection || it.customizable)
          .map((d) => (
            <div className={classes.Page} key={d.id}>
              <RowComponent
                label={convertLabel(d.pageName)}
                checkboxType={checkboxType}
                field={fields[d.id]}
                onChange={onChange}
              />
            </div>
          ))}
      </div>
    );
  }
}

export default SectionComponent;
