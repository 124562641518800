/* eslint-disable class-methods-use-this */
import { PivotStructure } from 'types/reportConstructor';
import { getNumberFormat } from '../getNumberFormat';

export const createCustomTable = (): typeof pivot.views.table => {
  return class CustomTable extends pivot.views.table {
    private _oldStructure: PivotStructure | null = null;

    LoadData() {
      const needUpdate = this.needUpdate();

      return this.Local.getData(needUpdate).then((data: unknown) => {
        if (this.app) {
          this.UpdateTable(data);
        }
      });
    }

    CellFormat(value: unknown) {
      webix.i18n.setLocale('ru-RU');

      return getNumberFormat(value);
    }

    private needUpdate() {
      const structure: PivotStructure = $$('pivot').getStructure();
      const needUpdate =
        this._oldStructure !== null && JSON.stringify(this._oldStructure) !== JSON.stringify(structure);
      this._oldStructure = structure;

      return needUpdate;
    }
  };
};
