import React from 'react';
import cx from 'classnames';
import { ICampaign, UploadedStatus } from 'types/campaign';
import { ITvMediaplan } from 'types/mediaplan';
import { TvTerritoryTypeSelector, TvTerritoryTypeSelectorProps } from './components/TvTerritoryTypeSelector';
import { AudienceSelector, AudienceSelectorProps } from './components/AudienceSelector';
import { RetroperiodDatepicker, RetroperiodDatepickerProps } from './components/RetroperiodDatepicker';
import { MediaplanUploader, WebMediaplanUploaderProps } from '../../../MediaplanUploader';
import { isTerritoryTypeFilled, areSelectorsFilled } from './TvMediaplanUploader.helpers';
import classes from './TvMediaplanUploader.module.scss';

type Props = {
  audienceSelector: AudienceSelectorProps;
  campaign: ICampaign | null;
  className?: string;
  currentTvMediaplan: ITvMediaplan | null;
  uploader: Omit<WebMediaplanUploaderProps, 'uploadedStatus'>;
  retroperiodDatepicker: Omit<RetroperiodDatepickerProps, 'theme'>;
  tvTerritoryTypeSelector: TvTerritoryTypeSelectorProps;
  uploadedStatus: UploadedStatus;
};

export const TvMediaplanUploader: React.VFC<Props> = (props) => {
  const {
    audienceSelector,
    className,
    campaign,
    currentTvMediaplan,
    uploader,
    tvTerritoryTypeSelector,
    uploadedStatus,
    retroperiodDatepicker,
  } = props;

  const areSelectorsValid = areSelectorsFilled(
    audienceSelector.audience?.value,
    retroperiodDatepicker.retroPeriod,
    tvTerritoryTypeSelector.value,
    uploadedStatus,
  );

  if (campaign === null || (uploadedStatus === 'version' && !currentTvMediaplan)) {
    return null;
  }

  const isTerritoryTypeValid = isTerritoryTypeFilled(tvTerritoryTypeSelector.value, uploadedStatus);

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.selectors}>
        {uploadedStatus === 'mediaplan' && <TvTerritoryTypeSelector {...tvTerritoryTypeSelector} />}
        {isTerritoryTypeValid && (
          <>
            <AudienceSelector {...audienceSelector} />
            <RetroperiodDatepicker {...retroperiodDatepicker} />
          </>
        )}
      </div>
      <div className={classes.uploader}>
        {areSelectorsValid && <MediaplanUploader uploadedStatus={uploadedStatus} {...uploader} />}
      </div>
    </div>
  );
};

export type { Props as TvMediaplanUploaderProps };
