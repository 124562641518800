import { CustomOptions } from 'api/types';
import {
  IAccountProperties,
  IAccount,
  ICabinet,
  ITokenLink,
  IUpdatedAccount,
  ICustomPropertiesHolder,
  CabinetStatus,
} from 'types/account';
import BaseApi from '../BaseApi';

class Accounts extends BaseApi {
  getProperties = ({ cabinetId }: Pick<AccountData, 'cabinetId'>, config: CustomOptions = {}) =>
    this.actions.get<IAccountProperties[]>({
      url: '/account/extract-accounts-properties',
      data: { cabinetId },
      config,
    });

  createAccount = ({ data, customerId, cabinetId }: CreateAccountData, config: CustomOptions = {}) =>
    this.actions.post<IUpdatedAccount>({
      url: '/account/create-account',
      queryParams: { customerId, cabinetId },
      data,
      config,
    });

  updateAccount = ({ data, accountId, cabinetId }: UpdateAccountData, config: CustomOptions = {}) =>
    this.actions.post<IUpdatedAccount>({
      url: '/account/update-account',
      queryParams: { accountId, cabinetId },
      data,
      config,
    });

  loadAccount = ({ accountId, cabinetId }: AccountData, config: CustomOptions = {}) =>
    this.actions.get<ICustomPropertiesHolder>({
      url: '/account/extract-account',
      data: { accountId, cabinetId },
      config,
    });

  list = (config: CustomOptions = {}) =>
    this.actions.get<IAccount[]>({
      url: '/account/extract-compact-accounts',
      config,
    });

  delete = ({ accountId, cabinetId }: AccountData, config: CustomOptions = {}) =>
    this.actions.delete({
      url: '/account/remove-account',
      queryParams: { accountId, cabinetId },
      config,
    });

  cabinetsList = (config: CustomOptions = {}) =>
    this.actions.get<ICabinet[]>({
      url: '/account/extract-cabinets',
      config,
    });

  accountsCount = (config: CustomOptions = {}) =>
    this.actions.get<number>({
      url: '/account/count-accounts',
      config,
    });

  getTokenLink = ({ accountId, cabinetId }: AccountData, config: CustomOptions = {}) =>
    this.actions.get<ITokenLink>({
      url: '/account/oauth-token-link',
      data: { accountId, cabinetId },
      config,
    });

  cabinetStatus = (config: CustomOptions = {}) =>
    this.actions.get<CabinetStatus>({
      url: '/account/extract-cabinet-status',
      config: { withLoader: false, ...config },
    });
}

type AccountData = {
  accountId: number;
  cabinetId: number;
};

type CreateAccountData = {
  customerId: number;
  cabinetId: number;
  data: Record<string, unknown>;
};

type UpdateAccountData = AccountData & { data: Record<string, unknown> };

export default Accounts;
