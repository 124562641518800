import React from 'react';
import cx from 'classnames';
import { InfoIcon, localize } from 'shared/components/other';

import classes from './DisabledFieldIcon.module.scss';

export const DisabledFieldIcon: React.VFC = () => (
  <InfoIcon
    tooltip={localize('campaign-edit.warnings.field-editing-is-not-allowed')}
    className={classes.root}
    iconClassName={cx('pi pi-question-circle', classes.icon)}
  />
);
