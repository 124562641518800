import React, { useRef, useState, useCallback, useMemo } from 'react';
import { RGBColor } from 'react-color';
import cx from 'classnames';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { buttonClasses } from '../Button';
import { localeOption } from '../../localization';
import { ColorPicker } from '../../form/ColorPicker';
import { ColorButton } from '../ColorButton';
import { toHexColor, toRGBAColor } from './ColorPickButton.helpers';
import classes from './ColorPickButton.module.scss';

type Props = {
  /** Default color */
  defaultColor: string | null;
  /** Used to display the values of the locale object defined in the primereact Locale API */
  locale?: string;
  /** On save event handler */
  onSave?: (color: string) => void;
  /** Title of the component. Default: localeOption('ButtonColor') */
  title?: string | null;
};

export const ColorPickButton: React.VFC<Props> = (props) => {
  const { defaultColor, onSave, title = localeOption('ButtonColor') } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState<RGBColor | null>(defaultColor ? toRGBAColor(defaultColor) : null);
  const hexColor = color ? toHexColor(color) : null;

  const hideConfirmPopup = useCallback(() => {
    setVisible(false);
    setColor(defaultColor ? toRGBAColor(defaultColor) : null);
  }, [defaultColor]);

  const handleColorButtonClick = useCallback(() => setVisible(true), []);

  const handleConfirmPopupAccept = useCallback(() => {
    if (!hexColor) return;
    onSave?.(hexColor);
  }, [hexColor, onSave]);

  const popupMessage = useMemo(() => <ColorPicker color={color ?? undefined} onChange={setColor} />, [color]);

  return (
    <div className={classes.root}>
      {title && <h3 className={classes.title}>{title}</h3>}
      <div className={classes.content}>
        <div className={classes.colorButton} ref={ref}>
          <ColorButton color={defaultColor} onClick={handleColorButtonClick} />
        </div>
        <div className={classes.info}>
          <p className={classes.label} id="change-color-label">
            {defaultColor ? localeOption('ChangeColor') : localeOption('SelectColor')}
          </p>
          <p className={classes.hexColor}>{defaultColor ?? localeOption('NoColor')}</p>
        </div>
      </div>
      <ConfirmPopup
        accept={handleConfirmPopupAccept}
        acceptLabel={localeOption('Apply')}
        className={classes.confirmPopup}
        message={popupMessage}
        onHide={hideConfirmPopup}
        reject={hideConfirmPopup}
        rejectLabel={localeOption('cancel')}
        acceptClassName={buttonClasses.root}
        rejectClassName={cx(buttonClasses.root, buttonClasses.lightTheme)}
        target={ref.current ?? undefined}
        visible={visible}
      />
    </div>
  );
};

export type { Props as ColorButtonProps };
