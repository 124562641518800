import { FileData, OptionName, Options } from 'types/cabinetCopying';

export const optionNamesByPriority: OptionName[] = ['cabinet', 'account', 'client', 'campaign', 'adGroup', 'ad'];

export const initialOptionConfig: Options = {
  cabinet: {
    items: [],
    value: null,
    disabled: true,
    updatable: false,
    requestCount: 0,
    localeId: 'cabinet-copying.labels.ad-system',
  },
  account: {
    items: [],
    value: null,
    disabled: true,
    updatable: false,
    requestCount: 0,
    localeId: 'cabinet-copying.labels.account',
  },
  client: {
    items: [],
    value: null,
    disabled: true,
    updatable: false,
    requestCount: 0,
    localeId: 'cabinet-copying.labels.client',
  },
  campaign: {
    items: [],
    value: null,
    disabled: true,
    updatable: true,
    requestCount: 0,
    localeId: 'cabinet-copying.labels.copied-ad-campaign',
  },
  adGroup: {
    items: [],
    value: null,
    disabled: true,
    updatable: true,
    requestCount: 0,
    localeId: 'cabinet-copying.labels.copied-ad-group',
  },
  ad: {
    items: [],
    value: null,
    disabled: true,
    updatable: true,
    requestCount: 0,
    localeId: 'cabinet-copying.labels.copied-ad',
  },
};

export const initialFileData: FileData = {
  EXCEL: null,
  MEDIA: null,
  LOGO: null,
};
