import { CustomOptions } from 'api/types';
import { ServerModels as SM } from 'types/server';
import BaseApi from '../BaseApi';

class Dictionaries extends BaseApi {
  getColumnsMetadata = ({ pageId }: GetColumnsMetadataData, config: CustomOptions = {}) =>
    this.actions.get<SM.ColumnMetadata[]>({
      url: '/page-settings/metadata',
      data: { pageId },
      domainType: 'webApi',
      config,
    });

  getAttributes = ({ cabinetId }: CabinetData, config: CustomOptions = {}) =>
    this.actions.get<SM.AbstractColumn[]>({
      url: '/attribute/cabinet',
      data: { cabinetId },
      domainType: 'webApi',
      config,
    });

  getMetrics = ({ cabinetId }: CabinetData, config: CustomOptions = {}) =>
    this.actions.get<SM.AbstractColumn[]>({
      url: '/metric/cabinet',
      data: { cabinetId },
      domainType: 'webApi',
      config,
    });

  getDictionaries = ({ cabinetId }: CabinetData, config: CustomOptions = {}) =>
    this.actions.get<SM.Dictionary[]>({
      url: '/dictionaries/cabinet',
      data: { cabinetId },
      domainType: 'webApi',
      config,
    });
}

type GetColumnsMetadataData = { pageId: string };

type CabinetData = { cabinetId: number };

export default Dictionaries;
