import React, { VFC } from 'react';

import classes from './PageNotFound.module.scss';

const PageNotFound: VFC = () => (
  <div className={classes.root}>
    <div className={classes.header}>404</div>
    <h1 className={classes.title}>Page Not Found</h1>
    <p>
      Sorry, but the page you are looking for has not been found.
      <br />
      Try checking the URL for error, then hit the refresh button on
      <br />
      your browser or try found something else in our app.
    </p>
  </div>
);

export default PageNotFound;
