import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import * as R from 'ramda';
import { ToastContainer, ToastContainerProps, toast, Slide, ToastOptions, Id, CloseButtonProps } from 'react-toastify';
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import 'react-toastify/dist/ReactToastify.css';
import { Content } from './components/Content';
import { CloseButton } from '../../buttons/CloseButton';
import { ToastData } from './Toast.types';
import classes from './Toast.module.scss';

type Props = ToastContainerProps;

export const Toast: React.VFC<Props> = (props) => {
  const { notifications } = useNotificationCenter<ToastData>();
  const updatableNotifications = useMemo(
    () => notifications.filter(({ data }) => data?.custom && !data?._appearanceUpdated),
    [notifications],
  );

  useLayoutEffect(() => {
    updatableNotifications.forEach((x) => {
      toast.update(x.id, {
        data: { ...x.data, _appearanceUpdated: true },
        render: ({ toastProps }) => <Content {...toastProps} />,
      });
    });
  }, [updatableNotifications]);

  const closeButtonTemplate = useCallback(
    ({ closeToast }: CloseButtonProps) => (
      <CloseButton
        className={classes.closeButton}
        iconColor="var(--color-basic-white)"
        onClick={closeToast}
        size="xsmall"
        data-test="toast-close-button"
      />
    ),
    [],
  );

  return (
    <ToastContainer
      autoClose={false}
      className={classes.toastContainer}
      closeButton={closeButtonTemplate}
      closeOnClick={false}
      draggable={false}
      limit={4}
      newestOnTop
      position={toast.POSITION.BOTTOM_CENTER}
      theme="dark"
      transition={Slide}
      {...props}
    />
  );
};

const show = (message: string, options?: ToastOptions<ToastData>): Id => {
  const optionsWithoutAutoClose = R.omit(['autoClose'])(options);
  const toastOptions = R.mergeDeepRight({ toastId: message, data: { custom: true, countdown: true } })(
    optionsWithoutAutoClose,
  );
  return toast(message, toastOptions);
};

const info: typeof show = (message, options): Id => show(message, { type: 'info', ...options });
const success: typeof show = (message, options): Id => show(message, { type: 'success', ...options });
const warning: typeof show = (message, options): Id => show(message, { type: 'warning', ...options });
const error: typeof show = (message, options): Id => show(message, { type: 'error', ...options });

const toastApi = { ...toast, show, info, success, warning, error };

export { toastApi as toast };

export type { Props as ToastContainerProps };
