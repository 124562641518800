import React, { useEffect } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import { FileUploader } from 'shared/components/common/form/FileUploader';
import { AddFileButton } from '../../../../../../../AddFileButton';
import { FactFilesTable, FactFilesTableProps } from './components/FactFilesTable';
import classes from './Editing.module.scss';

type Props = {
  className?: string;
  factFilesTable: FactFilesTableProps;
  isUploadingFactFiles: boolean;
  onMount(): void;
  onFactDataDownload(): void;
  onFactFileAdd(): void;
  onFactFileAddCancel(): void;
  onFactFileUpload(formData: FormData, name: string): void;
  onUserChangedDataDownload(): void;
};

export const Editing: React.VFC<Props> = React.memo((props) => {
  const {
    className,
    factFilesTable,
    isUploadingFactFiles,
    onMount,
    onFactDataDownload,
    onFactFileAdd,
    onFactFileAddCancel,
    onFactFileUpload,
    onUserChangedDataDownload,
  } = props;

  useEffect(() => {
    onMount();
  }, [onMount]);

  return isUploadingFactFiles ? (
    <FileUploader onUpload={onFactFileUpload} onCancelUpload={onFactFileAddCancel} />
  ) : (
    <div className={cx(classes.root, className)}>
      <div className={classes.buttons}>
        <TextButton
          className={classes.button}
          icon="pi icon-download-bold"
          data-test="download-fact-data-button"
          tooltip={localize('campaign-view.mediaplan.actions.download-fact-data')}
          onClick={onFactDataDownload}
        />
        {factFilesTable.data.length > 0 && (
          <TextButton
            className={classes.button}
            data-test="download-user-changed-data-button"
            icon="pi pi-download"
            tooltip={localize('campaign-view.mediaplan.actions.download-user-changed-data')}
            onClick={onUserChangedDataDownload}
          />
        )}
        <AddFileButton onClick={onFactFileAdd} type="version" />
      </div>
      <FactFilesTable {...factFilesTable} />
    </div>
  );
});

export type { Props as EditingProps };
