/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import { localize } from 'shared/components/other';
import handleError from 'helpers/handleError';
import { downloadFile } from 'helpers/utils';
import { IError } from 'types';
import HttpActions from './HttpActions';
import { Converter, Response } from './types';

class BaseApi {
  protected actions: HttpActions;

  constructor(actions: HttpActions) {
    this.actions = actions;
  }

  protected convertData<Data, Result, Args extends unknown[] = unknown[]>(
    response: Response<Data>,
    converter: Converter<Data, Result, Args>,
    ...args: Args
  ): Response<Result> {
    if (response.error) {
      return response;
    }

    return { data: converter(response.data, ...args), error: response.error };
  }

  protected downloadFile(response: Response<AxiosResponse<Blob>>, fileName?: string) {
    if (response.error) {
      const error = new Error(localize('errors.file-download-failed'));
      handleError(error as IError);
    } else {
      downloadFile(response.data, fileName);
    }
  }
}

export default BaseApi;
