import React, { useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { useStores } from 'core';
import { LocalizedTitle } from 'shared/components/other';
import { useDidMount } from 'helpers';
import hasAccess from 'helpers/hasAccess';
import { showNotificationIfRetroDateIsExceeded } from './helpers';
import { CampaignView as CampaignViewComponent, CampaignViewProps, CampaignViewTab, MediaplanTab } from './components';

import classes from './CampaignView.module.scss';

const getTrueKeys: <T extends Record<string, boolean>>(value: T) => (keyof T)[] = R.pipe(R.filter(Boolean), R.keys);

const CampaignView = observer(() => {
  const params = useParams<{ id?: string }>();
  const campaignId = params.id ? Number(params.id) : null;
  const { advertiserStore, authStore, campaignViewStore: store } = useStores();
  const { web, tv, creatives: creativesStore } = store.childrenStores;

  useDidMount(() => {
    runInAction(() => {
      store.activate(campaignId);
    });
  });

  useEffect(() => {
    if (advertiserStore.advertisers.length) {
      store.loadData();
    }
  }, [store, advertiserStore.advertisers]);

  useEffect(() => store.cleanUp, [store.cleanUp]);

  const accessibleCampaignViewTabsMap: Record<CampaignViewTab, boolean> = useMemo(
    () => ({
      mediaplans: hasAccess('webMediaplansTab', 'campaignPage') || hasAccess('tvMediaplansTab', 'campaignPage'),
      tv: hasAccess('tvTab', 'campaignPage') && tv.hasCurrentMediaplans,
      web: hasAccess('webTab', 'campaignPage') && web.hasCurrentMediaplans,
      webReport: hasAccess('webReport', 'campaignPage') && web.hasCurrentMediaplans,
      creatives: hasAccess('creativesTab', 'campaignPage'),
    }),
    [tv.hasCurrentMediaplans, web.hasCurrentMediaplans],
  );

  const accessibleCampaignViewTabs = useMemo(() => getTrueKeys(accessibleCampaignViewTabsMap), [
    accessibleCampaignViewTabsMap,
  ]);

  const accessibleMediaplansTabsMap: Record<MediaplanTab, boolean> = useMemo(
    () => ({
      web: hasAccess('webMediaplansTab', 'campaignPage'),
      tv: hasAccess('tvMediaplansTab', 'campaignPage'),
    }),
    [],
  );

  const accessibleMediaplansTabs = useMemo(() => getTrueKeys(accessibleMediaplansTabsMap), [
    accessibleMediaplansTabsMap,
  ]);

  const handleWebMediaplanSelect = useCallback(
    (id: number) => {
      store.setUploadedStatus(null);
      web.changeCurrentMediaplan(id);
    },
    [store, web],
  );

  const handleTvMediaplanSelect = useCallback(
    (id: number) => {
      store.setUploadedStatus(null);
      tv.changeCurrentMediaplan(id);
    },
    [store, tv],
  );

  const handleTvMediaplanUploaderDatepickerChange = useCallback(
    (date: Date[] | null) => {
      tv.setRetroperiod(date);
      showNotificationIfRetroDateIsExceeded(date, tv.lastAvailableRetroDate);
    },
    [tv],
  );

  const handleTvMediaplanUploadCancel = useCallback(() => {
    store.setUploadedStatus(null);
    tv.resetUploaderValues();
  }, [store, tv]);

  const beforeLinkToActionOpen = useCallback(() => authStore.rewriteTokensInCookies(), [authStore]);

  const webMediaplanSelectorOptions = useMemo(
    () => web.mediaplans.map((x) => ({ id: x.mediaplanId, name: x.mediaplanName })),
    [web.mediaplans],
  );

  if (!store.campaign) return null;

  const info: CampaignViewProps['info'] = {
    advertiser: advertiserStore.advertiser,
    brand: store.brand,
    campaign: store.campaign,
    isEditButtonVisible: hasAccess('editCampaign', 'pages'),
    onFavoriteButtonClick: store.toggleCampaignFavorite,
    subbrand: store.subbrand,
  };

  const webMediaplanSelector: CampaignViewProps['tabView']['mediaplans']['webMediaplanSelector'] = {
    onRemove: () => store.removeMediaplan(null),
    onRename: store.renameMediaplan,
    onSelect: handleWebMediaplanSelect,
    options: webMediaplanSelectorOptions,
    value: web.currentMediaplanId ?? undefined,
  };

  const webMediaplans: CampaignViewProps['tabView']['mediaplans']['webMediaplans'] = {
    fileHandlers: {
      beforeLinkToActionOpen,
      onDownload: web.downloadMediaplan,
      onRemove: store.removeMediaplan,
      onStatusChange: web.changeMediaplanStatus,
    },
    fixation: {
      fixationPeriod: web.fixationPeriod,
      lastFixationLocalizedStatus: web.lastFixationLocalizedStatus,
      lastFixationPeriod: web.lastFixationPeriod,
      lastFixationStatus: web.lastFixationStatus,
      onDateChange: web.setFixationPeriod,
      onFixate: web.handlePeriodFixate,
      onStatusIndicatorsUpdate: web.loadMediaplans,
      startDate: store.campaign.dateStart,
    },
    mediaplan: web.currentMediaplan,
    onMediaplanVersionAdd: store.setUploadedStatus,
    uploadedStatus: store.uploadedStatus,
    uploader: {
      onUpload: web.uploadMediaplan,
      onCancelUpload: () => store.setUploadedStatus(null),
    },
  };

  const tvMediaplanSelector: CampaignViewProps['tabView']['mediaplans']['tvMediaplanSelector'] = {
    onRemove: () => store.removeMediaplan(null),
    onRename: store.renameMediaplan,
    onSelect: handleTvMediaplanSelect,
    options: tv.mediaplans,
    value: tv.currentMediaplanId ?? undefined,
  };

  const creativesTab: CampaignViewProps['tabView']['mediaplans']['tvMediaplans']['creativesTab'] = {
    creatives: tv.creatives,
    fileVersionSelector: {
      currentVersionId: tv.currentVersion?.id ?? undefined,
      onChange: tv.changeCurrentMediaplanVersion,
      versions: tv.currentMediaplan?.versions ?? [],
    },
    fileStatusSelector: {
      id: tv.currentMediaplan?.id,
      value: tv.currentVersion?.status,
      onChange: tv.changeMediaplanStatus,
    },
    audienceSelector: {
      options: tv.audienceOptions,
      creative: tv.creatives[0],
      onChange: tv.handleMediaplanAudienceChange,
    },
    retroperiodDatepicker: {
      creative: tv.creatives[0],
      onChange: tv.handleMediaplanRetroperiodChange,
    },
    tvMediaplansTable: {
      onChange: tv.handleCreativeChange,
    },
    onCreativesSave: tv.handleCreativesSave,
    onVersionAdd: store.setUploadedStatus,
    onVersionDownload: tv.downloadMediaplan,
    onVersionRemove: () => store.removeMediaplan(tv.currentVersionId),
  };

  const editingTab: CampaignViewProps['tabView']['mediaplans']['tvMediaplans']['editingTab'] = {
    factFilesTable: {
      data: tv.factFiles,
      onDownload: tv.handleFactFileDownload,
      onRemove: tv.handleFactFileRemove,
      onStatusChange: tv.handleFactFileStatusChange,
    },
    isUploadingFactFiles: tv.isUploadingFactFiles,
    onMount: tv.loadFactFiles,
    onFactFileAdd: () => tv.setIsUploadingFactFiles(true),
    onFactFileAddCancel: () => tv.setIsUploadingFactFiles(false),
    onFactFileUpload: tv.handleFactFileUpload,
    onFactDataDownload: () => tv.handleFactDataDownload(false),
    onUserChangedDataDownload: () => tv.handleFactDataDownload(true),
  };

  const tvMediaplans: CampaignViewProps['tabView']['mediaplans']['tvMediaplans'] = {
    creativesTab,
    editingTab,
    currentTvMediaplan: tv.currentMediaplan,
    currentTvVersion: tv.currentVersion,
    onTemplateDownLoad: tv.downloadTemplate,
    uploadedStatus: store.uploadedStatus,
    uploader: {
      audienceSelector: {
        audience: tv.audience,
        campaignId: store.campaign.id,
        onChange: tv.setAudience,
        options: tv.audienceOptions,
      },
      retroperiodDatepicker: {
        onChange: handleTvMediaplanUploaderDatepickerChange,
        defaultRetroperiod: tv.defaultRetroperiod,
        retroPeriod: tv.retroperiod,
        showResetButton: !tv.isRetroperiodDefault,
        showWarningIcon: tv.isRetroperiodDefault,
      },
      tvTerritoryTypeSelector: {
        campaignId: store.campaign.id,
        value: tv.territoryType?.value,
        onChange: tv.setTerritoryType,
      },
      campaign: store.campaign,
      currentTvMediaplan: tv.currentMediaplan,
      uploader: {
        onUpload: tv.uploadMediaplan,
        onCancelUpload: handleTvMediaplanUploadCancel,
      },
    },
  };

  const tabView: CampaignViewProps['tabView'] = {
    accessibleTabs: accessibleCampaignViewTabs,
    isPivotDownloadAccessible: hasAccess('downloadExcelPivot', 'campaignPage'),
    mediaplans: {
      accessibleTabs: accessibleMediaplansTabs,
      onMediaplanAdd: store.setUploadedStatus,
      onTabChange: store.setCurrentMediaplanTypeTab,
      tvMediaplanSelector,
      tvMediaplans,
      webMediaplanSelector,
      webMediaplans,
    },
    creatives: {
      creatives: toJS(creativesStore.creatives),
      currentCreative: creativesStore.currentCreative,
      isEditingAccessible: hasAccess('editCreatives', 'campaignPage'),
      uploader: creativesStore.uploader,
      onSelect: creativesStore.select,
      onLoad: creativesStore.load,
      onCreate: creativesStore.create,
      onReplace: creativesStore.replace,
      onRename: creativesStore.rename,
      onRemove: creativesStore.remove,
      cleanUp: creativesStore.cleanUp,
    },
    onPivotDownload: store.downloadExcelPivot,
    powerBIReport: {
      brandId: store.brand?.id,
      campaignId: store.campaign.id,
      categoryId: store.campaign.categories[0],
      endDate: store.campaign.dateEnd,
      startDate: store.campaign.dateStart,
      subbrandId: store.subbrand?.id,
    },
  };

  return (
    <div className={cx(classes.root)}>
      <LocalizedTitle id="site.title.view-campaign" />
      <CampaignViewComponent
        description={store.campaign.description}
        info={info}
        isTabViewVisible={store.areMediaplansLoaded}
        tabView={tabView}
      />
    </div>
  );
});

export { CampaignView };
