import { action, makeObservable, observable, runInAction } from 'mobx';

import getLogoSrc from 'helpers/getLogoSrc';
import { IAdvertiser } from 'types/advertiser';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { BaseStore } from 'shared/stores';

export class EditAdvertiserStore extends BaseStore {
  @observable
  logoPreviewPath: string | null = null;

  @observable
  logoFile: File | null = null;

  @action
  initAdvertiser = (advertiser: IAdvertiser): void => {
    const { logoFileId } = advertiser;
    this.logoPreviewPath = logoFileId !== null ? getLogoSrc(logoFileId) : null;
  };

  @action
  setLogo = (file: File | null, path: string | null): void => {
    this.logoFile = file;
    this.logoPreviewPath = path;
  };

  editAdvertiser = async (advertiser: IAdvertiser | null): Promise<void> => {
    if (advertiser === null) {
      return;
    }

    const { id, logoFileId } = advertiser;
    let updatedLogoAdvertiser: IAdvertiser | null = null;

    if (this.logoPreviewPath === null && logoFileId !== null) {
      const response = await this.services.api.advertisers.removeLogo({ customerId: id });
      if (response.error) return;

      updatedLogoAdvertiser = response.data;
      toast.success(localize('administration.advertiser.notify.logo-deleted'));
    } else if (this.logoFile !== null) {
      const formData = new FormData();
      formData.append('file', this.logoFile);
      const response = await this.services.api.advertisers.updateLogo({ customerId: id, formData });
      if (response.error) return;

      updatedLogoAdvertiser = response.data;
      toast.success(localize('administration.notify.logo-uploaded'));
    }

    if (updatedLogoAdvertiser === null) {
      return;
    }

    const updatedAdvertiser: IAdvertiser = {
      ...advertiser,
      logoFileId: updatedLogoAdvertiser.logoFileId,
    };

    runInAction(() => {
      this.logoFile = null;
      this.deps.advertiserStore.updateAdvertiser(updatedAdvertiser);
    });
  };

  constructor() {
    super();
    makeObservable(this);
  }
}
