import React, { useCallback, useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { Divider } from 'primereact/divider';
import { localize } from 'shared/components/other';
import { TabPanel, TabView } from 'primereact/tabview';
import { TabSelect } from 'shared/components/common/form/TabSelect';
import { EditableListSelector, EditableListSelectorProps } from 'shared/components/common/form/EditableListSelector';
import { UploadedStatus } from 'types/campaign';
import { AddFileButton } from '../../../AddFileButton';
import { WebMediaplans, WebMediaplansProps } from './components/WebMediaplans';
import { TvMediaplans, TvMediaplansProps } from './components/TvMediaplans';
import classes from './Mediaplans.module.scss';

type Props = {
  accessibleTabs: MediaplanTab[];
  className?: string;
  onMediaplanAdd(value: UploadedStatus): void;
  onTabChange(value: MediaplanTab): void;
  tvMediaplanSelector: EditableListSelectorProps;
  tvMediaplans: TvMediaplansProps;
  webMediaplanSelector: EditableListSelectorProps;
  webMediaplans: WebMediaplansProps;
};

export const Mediaplans: React.VFC<Props> = React.memo((props) => {
  const {
    accessibleTabs,
    className,
    onMediaplanAdd,
    onTabChange,
    tvMediaplanSelector,
    tvMediaplans,
    webMediaplanSelector,
    webMediaplans,
  } = props;

  const tabs = useMemo(
    () =>
      accessibleTabs.map((tab) => {
        return {
          label: localize(`campaign.${tab}` as `campaign.${MediaplanTab}`),
          value: tab,
        };
      }),
    [accessibleTabs],
  );

  const mediaplanSelectors = {
    web: webMediaplanSelector,
    tv: tvMediaplanSelector,
  };

  const [activeTab, setActiveTab] = useState(accessibleTabs[0]);

  const activeTabIndex = Object.keys(mediaplanSelectors).indexOf(activeTab);

  const mediaplanSelector = mediaplanSelectors[activeTab];

  useEffect(() => {
    onTabChange(activeTab);
  }, [activeTab, onTabChange]);

  const handleTabChange = useCallback(() => {}, []);

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.leftPanel}>
        <header className={classes.header}>
          <h3 className={classes.title}>{localize('campaign.mediaplans')}</h3>
          <AddFileButton onClick={onMediaplanAdd} />
        </header>
        <TabSelect value={activeTab} onChange={setActiveTab} options={tabs} />
        <EditableListSelector testPrefix="web-mediaplan" {...mediaplanSelector} />
      </div>
      <Divider className={classes.divider} layout="vertical" />
      <TabView className={classes.rightPanel} activeIndex={activeTabIndex} onTabChange={handleTabChange}>
        <TabPanel>
          <WebMediaplans {...webMediaplans} />
        </TabPanel>
        <TabPanel>
          <TvMediaplans {...tvMediaplans} />
        </TabPanel>
      </TabView>
    </div>
  );
});

export type MediaplanTab = 'web' | 'tv';

export type { Props as MediaplansProps };
