/* eslint-disable no-param-reassign */
import HT from 'handsontable';
import { localize } from 'shared/components/other';
import { getChangedCellsInRow } from '../getChangedCellsInRow';

export function resetRowRenderer(instance: HT, TD: HTMLTableCellElement, rowIndex: number): void {
  const changedCellsInRow = getChangedCellsInRow(instance, rowIndex);
  if (!changedCellsInRow.length) {
    TD.innerText = '';
    return;
  }

  const div = document.createElement('div');
  div.className = 'pi pi-undo';
  div.title = localize('undo-changes');
  div.addEventListener('click', () => {
    const cellsMeta = instance.getCellMetaAtRow(instance.toPhysicalRow(rowIndex));
    const data: [number, number, unknown][] = cellsMeta
      .filter((cell) => cell.prop !== 'isSelected')
      .map((cell) => [cell.visualRow, cell.col, cell.defaultValue]);
    instance.setDataAtCell(data);
    setTimeout(() => instance.validateRows([rowIndex]));
  });

  TD.innerText = '';
  TD.appendChild(div);
}
