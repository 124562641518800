import React, { useMemo } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem'; // eslint-disable-line import/no-unresolved
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../../common/misc/Icon';
import classes from './LinkIcons.module.scss';

type LinkIconItem = {
  /** Icon name (icon font), url (src) or ReactElement */
  icon: string | React.ReactElement;
  /** <a> target attribute */
  target?: string;
  /** Maybe absolute ('http(s):') or relative ('/') */
  url?: string;
};

type Props = {
  /** Additional className */
  className?: string;
  /** An array of menu items */
  model: LinkIconItem[];
};

export const LinkIcons: React.VFC<Props> = React.memo((props) => {
  const { model, className } = props;
  const menubarModel = useMemo(() => (model ? R.map(convertModel)(model) : model), [model]);
  return <Menubar className={cx(classes.root, className)} model={menubarModel} />;
});

const convertModel = (linkIconItem: LinkIconItem): MenuItem => {
  const { icon, url, target } = linkIconItem;
  const menuItem: MenuItem = {};
  const isAbsoluteLink = !!url?.match(/http(s)?:/);
  const isRelativeLink = !!url?.startsWith('/');
  const iconElement = typeof icon === 'string' ? <Icon icon={icon} /> : icon;

  if (url) {
    if (isAbsoluteLink) {
      menuItem.template = (
        <a className={classes.link} href={url} target={target}>
          {iconElement}
        </a>
      );
    } else if (isRelativeLink) {
      menuItem.template = (
        <NavLink className={classes.link} to={url}>
          {iconElement}
        </NavLink>
      );
    }
  } else {
    menuItem.template = iconElement;
  }

  return menuItem;
};

export type { Props as LinkIconsProps, LinkIconItem };
