import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import hasAccess from 'helpers/hasAccess';
import { useStores } from 'core';
import { Checkbox, localize, LocalizedMessage, LocalizedTitle } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { Footer } from 'shared/components/layout/Footer';
import { AccountSystemStatus, IAccount } from 'types/account';
import { formatDate } from 'helpers/utils';
import { useDidMount } from 'helpers/hooks';
import { AccountsStore } from '.';

import classes from './Accounts.module.scss';

export const Accounts = observer(() => {
  const { accountsStore: store } = useStores();

  useDidMount(() => {
    store.loadAllData();
  });

  const { dataList, selectedAccounts, cabinets } = store;
  const { view } = store.childrenStores;

  const isAllChecked = selectedAccounts.length !== 0 && selectedAccounts.length === dataList.length;

  return (
    <div className={classes.Layout}>
      <div className={classes.Content}>
        <LocalizedTitle id="site.title.accounts" />

        <div className={classes.Header}>
          <h1 className={classes.Title}>
            <LocalizedMessage id="site.title.accounts" />
          </h1>
          {hasAccess('account', 'pages') && (
            <Link to="/account/new" data-test="add-account-button" className={classes.Link}>
              <Button>
                + &nbsp;
                <LocalizedMessage id="accounts.add-account" />
              </Button>
            </Link>
          )}
        </div>

        <div className={classes.TableContainer}>
          <table className={classes.Table}>
            <thead>
              <tr>
                {hasAccess('account', 'pages') && (
                  <th className={cx(classes.TableCell, classes.TableCellHeader)}>
                    <Checkbox
                      checked={isAllChecked}
                      size="large"
                      onChange={view.handleAllCheckboxClick}
                      name="all-accounts-checkbox"
                      data-test="all-accounts-checkbox"
                    />
                  </th>
                )}
                <th className={cx(classes.TableCell, classes.TableCellHeader)}>
                  <LocalizedMessage id="accounts.table.login" />
                </th>
                <th className={cx(classes.TableCell, classes.TableCellHeader)}>
                  <LocalizedMessage id="accounts.table.cabinet" />
                </th>
                <th className={cx(classes.TableCell, classes.TableCellHeader)}>
                  <LocalizedMessage id="accounts.table.created-date" />
                </th>
                <th className={cx(classes.TableCell, classes.TableCellHeader, classes.TableCellCompact)}>
                  <LocalizedMessage id="accounts.table.status" />
                </th>
                {hasAccess('account', 'pages') && (
                  <th className={cx(classes.TableCell, classes.TableCellHeader)}>
                    {selectedAccounts.length !== 0 && (
                      <div className={classes.IconDelete}>
                        <i
                          className={cx(classes.IconDeleteButton, 'icon icon-delete')}
                          onClick={() => view.handleDeleteBtnClick(selectedAccounts)}
                          data-test="delete-accounts-button"
                        />
                      </div>
                    )}
                  </th>
                )}
              </tr>
            </thead>

            <tbody>
              {dataList.map((account: IAccount) => {
                const { id, cabinetId, accountLogin, accountCreatedTime, systemStatus } = account;
                const selected = {
                  cabinetId,
                  accountId: id,
                };
                const isSelected = selectedAccounts.some((item) =>
                  AccountsStore.isEqualSelectedAccounts(item, selected),
                );

                return (
                  <tr key={id} className={classes.TableRow}>
                    {hasAccess('account', 'pages') && (
                      <td className={classes.TableCell}>
                        <Checkbox
                          checked={isSelected}
                          size="large"
                          onChange={() => view.handleCheckboxClick(selected)}
                          name={`account-${id}-checkbox`}
                          data-test={`account-${id}-checkbox`}
                        />
                      </td>
                    )}
                    <td className={classes.TableCell}>{accountLogin}</td>
                    <td className={classes.TableCell}>
                      {cabinets?.find((cabinet) => cabinet.id === cabinetId)?.description}
                    </td>
                    <td className={classes.TableCell}>
                      {accountCreatedTime &&
                        formatDate({
                          date: accountCreatedTime,
                          isFullYear: true,
                          hasTime: true,
                        })}
                    </td>
                    <td className={classes.TableCell}>
                      <div className={classes.TokenInfo}>
                        <div
                          className={cx(classes.Status, {
                            [classes.StatusActive]: systemStatus === AccountSystemStatus.ACTIVE,
                            [classes.StatusNotReady]: systemStatus === AccountSystemStatus.NOT_READY,
                          })}
                        />
                        {systemStatus === AccountSystemStatus.ACTIVE ? (
                          <LocalizedMessage id="accounts.table.has-token" />
                        ) : (
                          <>
                            <span>
                              <LocalizedMessage id="accounts.table.no-token" />
                            </span>
                            {hasAccess('account', 'pages') && (
                              <i
                                className={cx(
                                  classes.UpdateTokenButton,
                                  {
                                    [classes.UpdateTokenButtonSpinning]: view.loadingTokenId === id,
                                  },
                                  'icon icon-spinner',
                                )}
                                onClick={() => view.handleUpdateTokenButtonClick(selected)}
                                data-test={`update-token-${id}`}
                                title={localize('accounts.update-token')}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </td>
                    {hasAccess('account', 'pages') && (
                      <td className={cx(classes.TableCell, classes.TableCellCentered)}>
                        <div className={classes.RowIcons}>
                          <Link to={`/account/edit/${id}/${cabinetId}`} data-test={`edit-account-${id}`}>
                            <i className="icon icon-edit" />
                          </Link>
                          <i
                            className="icon icon-delete"
                            onClick={() => view.handleDeleteBtnClick([selected])}
                            data-test={`delete-account-${id}`}
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
});
