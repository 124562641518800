import React, { useCallback } from 'react';
import cx from 'classnames';
import { WebMediaplanActions } from 'types/mediaplan';
import Config from 'config';
import { Button } from 'shared/components/common/buttons/Button';
import { localize } from 'shared/components/other';
import { actions, getWebMediaplanActionUrl } from './LinkToActionWithWebMediaplan.helpers';
import classes from './LinkToActionWithWebMediaplan.module.scss';

type Props = {
  action: WebMediaplanActions;
  beforeLinkOpen(): void;
  className?: string;
  disabled?: boolean;
  fileId: number;
  mediaplanId: number;
  mediaplanVersionId: number;
};

export const LinkToActionWithWebMediaplan: React.VFC<Props> = (props) => {
  const { action, className, disabled, fileId, mediaplanId, mediaplanVersionId, beforeLinkOpen } = props;
  const act = actions[action];
  const url = getWebMediaplanActionUrl({
    backendUrl: Config.api.cswFrontUrl,
    path: act.url.path,
    mediaplanId,
    mediaplanVersionId,
    fileId: act.url.hasFileIdParam ? fileId : null,
  });
  type Tooltip = `campaign-view.mediaplan.tooltips.${typeof act.title}`;
  const tooltip = localize(`campaign-view.mediaplan.tooltips.${act.title}` as Tooltip);
  type Label = `campaign-view.mediaplan.actions.${typeof act.title}`;
  const label = localize(`campaign-view.mediaplan.actions.${act.title}` as Label);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      (async () => {
        await beforeLinkOpen();
        window.open(url, '_blank', 'noopener,noreferrer');
      })();
    },
    [beforeLinkOpen, url],
  );

  return (
    <Button
      className={cx(classes.root, className)}
      data-test={`link_${act.title}_${fileId}`}
      disabled={disabled}
      icon={`icon icon-${act.icon}`}
      iconPos="right"
      label={label}
      onClick={handleClick}
      tooltip={tooltip}
    />
  );
};

export type { Props as LinkToActionWithWebMediaplanProps };
