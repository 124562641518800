import React from 'react';
import cx from 'classnames';
import { MenuItem } from 'primereact/menuitem'; // eslint-disable-line import/no-unresolved
import { NavLink, match as Match, useHistory } from 'react-router-dom';
import * as H from 'history';
import { MenuButton } from 'shared/components/common/buttons/MenuButton';
import classes from './NavMenu.module.scss';

type Props = {
  /** Additional className */
  className?: string;
  /** An array of menu items */
  model: NavMenuItem[];
};

type NavMenuItem = {
  className?: string;
  items?: NavMenuItem[];
  label?: string;
  path?: string;
};

export const NavMenu: React.VFC<Props> = ({ model, className }) => (
  <div className={cx(classes.root, className)}>
    {model.map((menuItem) =>
      menuItem.items ? (
        <NavbarMenuButton key={menuItem.label} {...menuItem} />
      ) : (
        <NavbarLink key={menuItem.label} {...menuItem} />
      ),
    )}
  </div>
);

const NavbarMenuButton: React.VFC<NavMenuItem> = ({ label, items = [] }) => {
  const { location } = useHistory();
  const makeItemTemplate = (item: NavMenuItem): MenuItem['template'] => (_, options) => (
    <div onClick={options.onClick}>
      <NavbarLink {...item} />
    </div>
  );

  return (
    <MenuButton
      className={cx(classes.toolsButton, {
        [classes.toolsButtonActive]: items.some(({ path }) => path && location.pathname.startsWith(path)),
      })}
      menuClassName={classes.toolsMenu}
      model={items.map((item) => ({ template: makeItemTemplate(item) }))}
      label={label}
      data-test={`menu-item-${label}`}
    />
  );
};

const NavbarLink: React.VFC<NavMenuItem> = ({ path = '#', className, label }) => (
  <NavLink
    to={path}
    className={cx(classes.navLink, className)}
    activeClassName={classes.navLinkActive}
    isActive={(match: Match | null, location: H.Location) => Boolean(match) || location.pathname.includes(path)}
    data-test={`menu-item-${label}`}
  >
    {label}
  </NavLink>
);

export type { Props as NavMenuProps, NavMenuItem };
