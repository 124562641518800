import { CustomOptions } from 'api/types';
import { ServerCabinet, LastUpdateTime, ExcessCausesDictionary } from 'types/programmatic/excess';
import BaseApi from '../BaseApi';
import { convertServerCabinets } from '../helpers/converters';

class Programmatic extends BaseApi {
  cabinets = async (config: CustomOptions = {}) => {
    const response = await this.actions.get<ServerCabinet[]>({
      url: '/cabinet/',
      domainType: 'webApi',
      config,
    });

    return this.convertData(response, convertServerCabinets);
  };

  lastUpdateTime = (config: CustomOptions = {}) =>
    this.actions.get<LastUpdateTime>({
      url: '/excess/data/last-update-time',
      domainType: 'webApi',
      config: { withLoader: false, ...config },
    });

  excessCausesDictionary = (config: CustomOptions = {}) =>
    this.actions.get<ExcessCausesDictionary>({
      url: '/excess/dictionary/cause',
      domainType: 'webApi',
      config,
    });
}

export default Programmatic;
