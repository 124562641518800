import React, { useCallback } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { Calendar } from 'shared/components/common/form/Calendar';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import classes from './RetroperiodDatepicker.module.scss';

type Props = {
  className?: string;
  onChange(value: Date[] | null): void;
  defaultRetroperiod: Date[] | null;
  retroPeriod: Date[] | null;
  showResetButton?: boolean;
  showWarningIcon?: boolean;
};

export const RetroperiodDatepicker: React.VFC<Props> = (props) => {
  const { className, onChange, defaultRetroperiod, retroPeriod, showResetButton, showWarningIcon } = props;

  const handleChange = useCallback(
    (dates: Date | Date[] | null) => {
      if (Array.isArray(dates)) {
        onChange(dates);
      }
    },
    [onChange],
  );

  return (
    <div className={cx(classes.root, className)}>
      <label htmlFor="retroperiod" className={classes.label}>
        {localize('campaign-view.mediaplan.select-retroperiod')}
        {showWarningIcon && (
          <TextButton
            className={classes.warningIcon}
            icon="pi pi-exclamation-triangle"
            iconColor="var(--color-yellow)"
            tooltip={localize('campaign-view.mediaplan.retroperiod-tooltip.default-values')}
            tooltipOptions={{ position: 'right' }}
          />
        )}
      </label>
      <Calendar
        className={classes.calendar}
        inputClassName={classes.calendarInput}
        showClearButton={showResetButton}
        clearButtonTooltip={localize('campaign-view.mediaplan.retroperiod-tooltip.reset-to-default-values')}
        clearButtonIcon="pi pi-sync"
        onChange={handleChange}
        mode="range"
        inputId="retroperiod"
        value={retroPeriod}
        defaultDate={defaultRetroperiod}
      />
    </div>
  );
};

export type { Props as RetroperiodDatepickerProps };
