import React from 'react';
import { LocalizedMessage } from 'shared/components/other';
import classes from './FailedFetchNotification.module.scss';

let ref!: FailedFetchNotification;

export function showModal(callback: (isYes: boolean) => void): void {
  if (ref) {
    ref.showNotification();
    ref.onAcceptCallbacks.push(callback);
  }
}

interface IState {
  isShown: boolean;
}

export default class FailedFetchNotification extends React.PureComponent<Record<string, unknown>, IState> {
  state = {
    isShown: false,
  };

  onAcceptCallbacks: ((isYes: boolean) => void)[] = [];

  componentDidMount() {
    ref = this;
  }

  closeNotification = (): void => {
    this.setState({ isShown: false });
    this.onAcceptCallbacks.forEach((cb) => cb(false));
    this.onAcceptCallbacks = [];
  };

  retryRequests = (): void => {
    this.setState({ isShown: false });
    this.onAcceptCallbacks.forEach((cb) => cb(true));
    this.onAcceptCallbacks = [];
  };

  showNotification(): void {
    this.setState({ isShown: true });
  }

  render() {
    const { isShown } = this.state;

    return isShown ? (
      <div className={classes.Overlay}>
        <div className={classes.Notification}>
          <LocalizedMessage id="messages.error.retry-request" />
          <div className={classes.Buttons}>
            <button type="button" className={classes.Button} onClick={this.retryRequests}>
              <LocalizedMessage id="yes" />
            </button>
            <button type="button" className={classes.Button} onClick={this.closeNotification}>
              <LocalizedMessage id="no" />
            </button>
            <button type="button" className={classes.Button} onClick={() => window.location.reload()}>
              <LocalizedMessage id="button.reload-page" />
            </button>
          </div>
        </div>
      </div>
    ) : null;
  }
}
