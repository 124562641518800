import { Date as DateType, IOption, IUserErrorMessage } from 'types';
import { FileS3Id } from './file';

export enum IFileStatus {
  CURRENT = 'CURRENT',
  DRAFT = 'DRAFT',
  ARCHIVE = 'ARCHIVE',
}

export enum WebMediaplanActions {
  EXTRACT_DATA = 'extractData',
  LINK_CABINETS = 'linkCabinets',
  EXPORT_CABINET = 'exportCabinet',
  EDIT_DATA = 'editData',
  EXPORT_AMP = 'exportAMP',
  EXPORT_FACT_AMP = 'exportAMPFact',
  DOWNLOAD_FACT_TEMPLATE = 'downloadMediafactManualGeneratedTemplate',
}

export enum FileActions {
  UPLOAD = 'uploadNewVerion',
  DELETE = 'delete',
  SET_STATUS = 'setStatus',
  DOWNLOAD = 'download',
}

export interface IAction {
  key: string;
  name: string;
}

export interface IFile {
  status: IFileStatus;
  mediaplanVersionId: number;
  fileId: number;
  fileName: string;
  fileS3Id: FileS3Id;
  dateCreated: DateType;
  dateModified: DateType | null;
  authorId: number;
  authorName: string | null;
  actions: IAction[];
}

export enum LastFixationStatus {
  FIXED = 'FIXED',
  UNFIXED = 'UNFIXED',
  FIX_IN_PROGRESS = 'FIXATION_IN_PROGRESS',
  UNFIX_IN_PROGRESS = 'UNDO_FIXATION_IN_PROGRESS',
  ERROR = 'ERROR',
}

export interface IFactFixationInfo {
  isLastOperationSucceed: boolean | null;
  lastFixationStatus: LastFixationStatus | null;
  lastFixationPeriod: string | null;
  lastSuccessfulFixationPeriod: string | null;
}

export interface IWebMediaplan {
  mediaplanId: number;
  mediaplanName: string;
  actions: IAction[];
  files: IFile[];
  factFixationInfo: IFactFixationInfo;
}

export interface ICountMediaplan {
  countCurrent: number;
  countTotal: number;
}

export interface IVersion {
  id: number;
  fileName: string;
  fileS3Id: FileS3Id;
  status: IFileStatus;
  creationTime: string;
  modifyTime: string;
  creationUserId: number | null;
  modifyUserId: number | null;
}

export interface IFactFile {
  campaignId: number;
  createUsername: string;
  creationDate: DateType;
  fileName: string;
  id: number;
  isDeleted: boolean;
  lastModifiedUsername: string;
  lastModifyDate: DateType;
  s3FileId: FileS3Id;
  status: IFileStatus;
}

export interface ITvMediaplan {
  id: number;
  campaignId: number;
  name: string;
  isDeleted: boolean;
  /** @maxLength 10 */
  versions: IVersion[];
  mediaType: TvTerritoryType;
}

type MediascopeClip = {
  mediascopeClipId: number;
};

type VimbClip = {
  vimbClipId: number;
};

export interface IServerClip {
  id: number;
  name: string;
  duration: number;
  /** @format faker.date.between('2022-01-01', '2022-01-31') */
  clipDateStart: string | Date | null;
  /** @format faker.date.between('2022-02-01', '2022-02-28') */
  clipDateEnd: string | Date | null;
  mediascopeClips: MediascopeClip[];
  vimbClips: VimbClip[];
}

export interface IServerCreative {
  id: number;
  name: string;
  audienceId: number;
  clips: IServerClip[];
  region: {
    id: number;
    /** @format faker.address.city() */
    name: string;
    ename: string;
  };
  /** @format faker.date.between('2022-01-01', '2022-01-31') */
  retroFrom: string;
  /** @format faker.date.between('2022-02-01', '2022-02-28') */
  retroTo: string;
}

export interface ICreativeForSaving {
  id: number;
  clips: Omit<IServerClip, 'name' | 'duration'>[];
}

export interface IClip extends Omit<IServerClip, 'mediascopeClips' | 'vimbClips'> {
  invalidFields: ('tnsid' | 'vimbid' | 'date')[];
  /** @format faker.database.mongodbObjectId() */
  tnsid: string;
  /** @format faker.database.mongodbObjectId() */
  vimbid: string;
}

export interface ICreative extends Omit<IServerCreative, 'clips'> {
  /** @maxLength 10 */
  clips: IClip[];
}

export interface IUpdatedCreative {
  id: number;
  clips: {
    id: number;
    clipDateStart: string | Date | null;
    clipDateEnd: string | Date | null;
    mediascopeClips: MediascopeClip[];
    vimbClips: VimbClip[];
  }[];
}

export interface IUploadedFile {
  fileId: number;
  mediaplanId: number;
  mediaplanVersionId: number;
}

export type Sheet = {
  sheetId: number;
  sheetName: string;
};

export type Sheets = Record<string, Record<'sheets', Sheet[]>>;

export type CampaignInfo = {
  agency: string | null;
  agencyId: string | null;
  brand: string | null;
  brandId: string | null;
  subBrand: string | null;
  subBrandId: string | null;
  category: string | null;
  categoryId: string | null;
  campaign: string | null;
  dateEnd: string | null;
  dateStart: string | null;
  totalMediaBudget: number | null;
  userErrorMessageList: IUserErrorMessage[] | null;
};

export enum TvTerritoryType {
  FEDERAL = 'FEDERAL',
  LOCAL = 'LOCAL',
}

export type MediaplanType = 'web' | 'tv';

export type DatePeriod = {
  startDate: string;
  endDate: string;
};

export type DefaultCommonData = {
  retroperiod: Date[] | null;
  lastAvailableDate: string;
  audienceOptions: IOption[];
};
