import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { ErrorBoundary } from '@sentry/react';
import { getFeedbackDialogOptions } from 'helpers/withSentryErrorBoundary';
import { useBoolean } from 'ahooks';
import { CountdownButton } from '../../../../buttons/CountdownButton';
import { ToastData } from '../../Toast.types';
import { TextButton } from '../../../../buttons/TextButton';
import { localeOption } from '../../../../localization';
import classes from './Content.module.scss';

type Props = ToastOptions<ToastData>;

export const Content: React.VFC<Props> = (props) => {
  const { autoClose, toastId, data } = props;
  const { countdown = false, errorForSentry } = data ?? {};
  const countdownDuration = autoClose ? autoClose / 1000 : 5;
  const [isSentryDialogVisible, { setTrue: showSentryDialog }] = useBoolean(false);
  const handleCountdownComplete = () => toast.dismiss(toastId);

  return (
    <div className={classes.root}>
      <span className={classes.message}>{toastId}</span>
      {!!errorForSentry && (
        <TextButton
          className={classes.reportErrorButton}
          label={localeOption('ReportAnError')}
          onClick={showSentryDialog}
        />
      )}
      {countdown && (
        <CountdownButton
          className={classes.countdownButton}
          duration={countdownDuration}
          onComplete={handleCountdownComplete}
        />
      )}
      {/* Error was passed to connect unknown error with user's feedback in Sentry */}
      <ErrorBoundary showDialog dialogOptions={getFeedbackDialogOptions()}>
        <div hidden>{isSentryDialogVisible ? errorForSentry : null}</div>
      </ErrorBoundary>
    </div>
  );
};
