import React from 'react';

import { IOption } from 'types';
import { LogItem } from 'types/cabinetCopying';
import { Column } from 'shared/components/other';

import { statusOptions } from './statusOptions';
import { CabinetStatusCell } from '../../components/LogPanel/components/CabinetStatusCell';

export const getColumns = (cabinetOptions: IOption[]): Column<LogItem>[] => [
  {
    key: 'timeCreate',
    name: 'Время',
    type: 'datetime',
    width: 100,
    resizable: true,
    isSortable: true,
  },
  {
    key: 'cabinetName',
    name: 'Рекламная система',
    width: 180,
    resizable: true,
    isSortable: true,
    filter: {
      name: 'cabinetId',
      type: 'multiselect',
      options: cabinetOptions,
    },
    textAlign: 'left',
  },
  {
    key: 'accountName',
    name: 'Аккаунт',
    minWidth: 140,
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    textAlign: 'left',
  },
  {
    key: 'clientName',
    name: 'Клиент',
    minWidth: 140,
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    textAlign: 'left',
  },
  {
    key: 'campaignName',
    name: 'Кампания',
    minWidth: 140,
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    textAlign: 'left',
  },
  {
    key: 'adName',
    name: 'Объявление / Группа',
    minWidth: 140,
    resizable: true,
    isSortable: true,
    filter: { type: 'text' },
    textAlign: 'left',
  },
  {
    key: 'userName',
    name: 'Пользователь',
    minWidth: 140,
    resizable: true,
    isSortable: true,
    filter: { name: 'user', type: 'text' },
    textAlign: 'left',
  },
  {
    key: 'status',
    name: 'Статус',
    minWidth: 140,
    resizable: true,
    filter: {
      name: 'logStatus',
      type: 'multiselect',
      options: statusOptions,
    },
    formatter: ({ row }) => {
      const option = statusOptions.find(({ value }) => value === row.data.status);
      return option ? <CabinetStatusCell {...option} /> : null;
    },
  },
  {
    key: 'errorMessage',
    name: 'Текст ошибки',
    width: 220,
    resizable: true,
    filter: { type: 'text' },
    textAlign: 'left',
  },
];
