import React, { memo, VFC } from 'react';
import { Button, ButtonProps } from 'shared/components/common/buttons/Button';
import cx from 'classnames';
import classes from './ShrinkingButton.module.scss';

export const ShrinkingButton: VFC<ButtonProps & { shrunk: boolean }> = memo(
  ({ shrunk, label, className, ...props }) => {
    if (shrunk) {
      return <Button tooltip={label} className={cx(className, classes.shrunk)} {...props} />;
    }

    return <Button label={label} className={className} {...props} />;
  },
);
