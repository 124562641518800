import { cloneDeep } from 'lodash';
import * as R from 'ramda';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { isNotNull } from 'helpers/utils';
import { BaseStore } from 'shared/stores';
import { localize } from 'shared/components/other';
import { toast } from 'shared/components/common/misc/Toast';
import { CheckSetting, CheckSettingsDictionary, ExcessSettingsStatus } from 'types/programmatic';
import { ExcessStore } from './Excess.store';

type Deps = {
  excessStore: ExcessStore;
};

export class ExcessCheckSettingsStore extends BaseStore<Deps> {
  @observable
  private _checkSettingsDictionary: CheckSettingsDictionary | null = null;

  @observable
  private _defaultRowCheckSettings: CheckSetting[] = [];

  @observable
  private _currentRowCheckSettings: CheckSetting[] = [];

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);
  }

  get currentRowCheckSettings(): CheckSetting[] {
    return this._currentRowCheckSettings;
  }

  @computed
  get changedRowCheckSettings(): CheckSetting[] {
    return this._currentRowCheckSettings.filter(
      (setting, i) => !setting.readOnly && !R.whereEq(this._defaultRowCheckSettings[i])(setting),
    );
  }

  loadCheckSettingsDictionary = async (): Promise<void> => {
    const rowSettingsDictionary = await this.services.api.programmatic.excess.checkSettingsMetadata();
    runInAction(() => {
      this._checkSettingsDictionary = rowSettingsDictionary;
    });
  };

  loadRowCheckSettings = async (rowId: string): Promise<void> => {
    const row = this.deps.excessStore.getRow(rowId);
    if (!row) return;
    const {
      data: { campaignId, mediaplanId, rowNumber },
    } = row;
    if (!campaignId || !mediaplanId || !rowNumber) return;

    const { settings, settingsStatus } = await this.services.api.programmatic.excess.checkSettings({
      campaignId,
      mediaplanId,
      rowNumber,
    });

    runInAction(() => {
      const rowSettings = settings
        .map((setting) => {
          const dictSetting = this._checkSettingsDictionary?.[setting.id];
          return dictSetting ? { ...setting, ...dictSetting } : null;
        })
        .filter(isNotNull);
      this._defaultRowCheckSettings = rowSettings;
      this._currentRowCheckSettings = cloneDeep(rowSettings);
      this.updateRowSettingsStatus(rowId, settingsStatus);
    });
  };

  saveRowCheckSettings = async (rowId: string): Promise<void> => {
    const row = this.deps.excessStore.getRow(rowId);
    if (!row) return;
    const {
      data: { campaignId, mediaplanId, rowNumber, id },
    } = row;
    const settings = this.changedRowCheckSettings;
    if (!settings.length || !campaignId || !mediaplanId || !rowNumber) return;
    const args = {
      campaignId,
      mediaplanId,
      rowNumber,
      csId: String(id),
      settings,
    };

    const settingsStatus = await this.services.api.programmatic.excess.setCheckSettings(args);
    runInAction(() => {
      if (settingsStatus) {
        toast.success(localize('programmatic.excess-table.settings.changed-successfully'));
        this.updateRowSettingsStatus(rowId, settingsStatus);
      }
    });
  };

  @action
  changeRowCheckSetting = <T extends CheckSetting>(settingId: number, field: keyof T, value: T[keyof T]): void => {
    this._currentRowCheckSettings = this._currentRowCheckSettings.map((setting) =>
      setting.id === settingId
        ? { ...setting, expires: field === 'isActive' && !value ? null : setting.expires, [field]: value }
        : setting,
    );
  };

  private updateRowSettingsStatus = async (rowId: string, settingsStatus: ExcessSettingsStatus): Promise<void> => {
    const row = this.deps.excessStore.getRow(rowId);
    if (!row) return;

    row.data = { ...row.data, settingsStatus };
    this.deps.excessStore.forceUpdateData();
  };
}
