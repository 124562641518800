import classes from 'routes/Account/Account.module.scss';
import { LocalizedMessage, LocalizedTitle } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { SaveButtons } from 'shared/components/common/buttons/SaveButtons';
import { Select } from 'shared/components/common/form/Select';
import { Footer } from 'shared/components/layout/Footer';
import { useStores } from 'core';
import React from 'react';
import { observer } from 'mobx-react';
import { AccountField } from 'routes/Account/components/Field';
import { AccountStore } from '.';

interface IProps {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  returnToPreviousPage: () => void;
}

export const Account = observer(({ returnToPreviousPage, handleSubmit }: IProps) => {
  const { accountStore } = useStores();
  const { isEditing, loadedFields, isActivatedToken, cabinetOptions, selectedCabinet } = accountStore;
  const { view } = accountStore.childrenStores;

  if (!selectedCabinet) {
    return null;
  }

  return (
    <div className={classes.Layout}>
      <LocalizedTitle id={isEditing ? 'account.form.edit-account' : 'account.form.create-account'} />

      <div className={classes.Content}>
        <h1 className={classes.Title}>
          <LocalizedMessage id={isEditing ? 'account.form.edit-account' : 'account.form.create-account'} />
        </h1>
        <div className={classes.CabinetSelect}>
          {isEditing ? (
            <>
              <span className={classes.Label}>
                <LocalizedMessage id="account.form.selected-cabinet" />
              </span>
              <div className={classes.FieldInput}>{selectedCabinet.description}</div>
            </>
          ) : (
            <label>
              <span className={classes.Label}>
                <LocalizedMessage id="account.form.select-cabinet" />
              </span>
              <div className={classes.FieldInput}>
                <Select
                  options={cabinetOptions}
                  value={AccountStore.cabinetToOption(selectedCabinet).value}
                  onChange={view.handleSelectCabinet}
                  mode="inline"
                  appendTo={document.body}
                />
              </div>
            </label>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          {loadedFields.length && (
            <div className={classes.Form}>
              {loadedFields.map((field) => (
                <AccountField {...field} key={field.propertyName} />
              ))}
            </div>
          )}

          <div className={classes.ButtonGroup}>
            <SaveButtons onCancel={returnToPreviousPage} />
            {isEditing && !isActivatedToken && (
              <Button className={classes.TokenButton} onClick={view.handleGetToken} data-test="get-token-button">
                <LocalizedMessage id="account.button.get-token" />
              </Button>
            )}
          </div>
        </form>
      </div>
      <Footer className={classes.Footer} />
    </div>
  );
});
