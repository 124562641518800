import { action, makeObservable, observable } from 'mobx';

import { EmptyObject, IColorTheme } from 'types';
import { BaseStore } from 'shared/stores';
import { EditAppearanceStore } from './EditAppearance.store';

const defaultBackgroundColor = '#ffffff';
const defaultMainColor = '#1c1c1c';
const defaultAddColor = '#8d8d8d';

type ChildrenStores = {
  edit: EditAppearanceStore;
};

export class AppearanceStore extends BaseStore<EmptyObject, ChildrenStores> {
  @observable
  brandTheme: IColorTheme = initBrandTheme();

  @observable
  campaignTheme: IColorTheme = initCampaignTheme();

  @action
  setDefaultBrandTheme = (): void => {
    this.brandTheme = initBrandTheme();
  };

  @action
  setDefaultCampaignTheme = (): void => {
    this.campaignTheme = initCampaignTheme();
  };

  @action
  updateAppearance = (brandTheme: IColorTheme, campaignTheme: IColorTheme): void => {
    this.brandTheme = {
      ...this.brandTheme,
      ...brandTheme,
    };
    this.campaignTheme = {
      ...this.campaignTheme,
      ...campaignTheme,
    };
    try {
      localStorage.setItem('brandBgColor', brandTheme.background);
      localStorage.setItem('brandMainTextColor', brandTheme.mainText);
      localStorage.setItem('brandAddTextColor', brandTheme.additionalText);

      localStorage.setItem('campaignBgColor', campaignTheme.background);
      localStorage.setItem('campaignMainTextColor', campaignTheme.mainText);
      localStorage.setItem('campaignAddTextColor', campaignTheme.additionalText);
    } catch {
      console.error('fail set to local storage');
    }
  };

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      edit: new EditAppearanceStore(),
    };
  }
}

function initBrandTheme() {
  return {
    background: localStorage.getItem('brandBgColor') || defaultBackgroundColor,
    mainText: localStorage.getItem('brandMainTextColor') || defaultMainColor,
    additionalText: localStorage.getItem('brandAddTextColor') || defaultAddColor,
  };
}

function initCampaignTheme() {
  return {
    background: localStorage.getItem('campaignBgColor') || defaultBackgroundColor,
    mainText: localStorage.getItem('campaignMainTextColor') || defaultMainColor,
    additionalText: localStorage.getItem('campaignAddTextColor') || defaultAddColor,
  };
}
