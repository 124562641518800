import React, { ReactElement, useCallback } from 'react';
import cx from 'classnames';
import { SelectButton, SelectButtonChangeParams } from 'primereact/selectbutton';
import { IOption } from 'types';
import classes from './TabSelect.module.scss';

type Props<T> = {
  className?: string;
  onChange(value: T): void;
  options: (IOption<T> & { testId?: string })[];
  value: T;
};

export const TabSelect = <T,>(props: Props<T>): ReactElement => {
  const { className, onChange, options, value } = props;

  const itemTemplate = useCallback((option) => <span data-test={option.testId}>{option.label}</span>, []);

  const handleChange = useCallback((e: SelectButtonChangeParams) => onChange(e.value), [onChange]);

  return (
    <SelectButton
      className={cx(classes.root, className)}
      itemTemplate={itemTemplate}
      onChange={handleChange}
      options={options}
      value={value}
      unselectable={false}
    />
  );
};
