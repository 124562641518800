import { RowHeightArgs } from 'react-data-grid';
import { GridRow, isGridTitleRow } from '..';

export function getRowHeight<DR>(
  args: RowHeightArgs<GridRow<DR>>,
  rowHeight: number,
  titleRowHeight: number,
  rowGap = 0,
): number {
  return args.type === 'ROW' && isGridTitleRow(args.row) ? titleRowHeight + rowGap : rowHeight;
}
