export const defaultProps = {
  id: 'hotTable',
  licenseKey: 'non-commercial-and-evaluation',
  columnHeaderHeight: 50,
  rowHeights: 32,
  width: '100%',
  height: '100%',
  stretchH: 'all',
  selectionMode: 'range',
  rowHeaders: true,
  dateFormat: 'YYYY-MM-DD',
} as const;
