import { FileS3Id } from './file';
import { Date, IOption } from './index';

export enum CampaignStatus {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export enum CampaignStatusNumeric {
  ACTIVE,
  PAUSED,
  COMPLETED,
}

export enum MediaType {
  WEB = 'WEB',
  TV = 'TV',
  OUTDOOR = 'OUTDOOR',
  RADIO = 'RADIO',
  PRESS = 'PRESS',
}

export enum CreationSource {
  PORTAL = 'PORTAL',
  WEB = 'WEB',
  TV = 'TV',
}

export interface IYearPeriod {
  minYear: number;
  maxYear: number;
}

export interface ICampaignMetric {
  campaign: number;
  id: number;
  value: number;
  metricMediaType: number;
}

export interface ICampaign {
  id: number;
  name: string;
  description: string;
  campaignStatus: CampaignStatus;
  dateStart: Date;
  dateEnd: Date;
  agency: number;
  brands: number[];
  subbrands: number[];
  categories: number[];
  advertiser: number;
  campaignMetrics: ICampaignMetric[];
  mediaTypes: MediaType[];
  totalBudgetPlan: number;
  totalBudgetFact: number;
  accessibleToClient: boolean;
  hideBudgetScale: boolean;
  favorite: boolean;
}

export interface IShortCampaign {
  id: number;
  name: string;
}

export interface ISavedCampaign {
  id: number | null;
  name: string;
  description: string | null;
  campaignStatus: string;
  dateStart: Date;
  dateEnd: Date;
  agency: number;
  brands: number[];
  subbrands: number[];
  categories: number[];
  advertiser: number;
  campaignMetrics: Omit<ICampaignMetric, 'campaign' | 'id'>[];
  accessibleToClient: boolean;
  hideBudgetScale: boolean;
  creationSource: CreationSource;
  favorite: boolean;
}

export type MediaOption = IOption & {
  type: MediaType;
  budgetValue: number | null;
};

export type UploadedStatus = 'mediaplan' | 'version';

export interface IServerFilters {
  advertiserId: number;
  brandIds: number[];
  endDate: string;
  sortingField: 'NAME' | 'DATE_START';
  sortingType: 'ASC' | 'DESC';
  startDate: string;
  statuses: string[];
  subName: string;
  mediaTypes: MediaType[] | null;
  onlyFavorite: boolean;
}

export type MediaplanData = {
  s3FileId: FileS3Id;
  name: string | null;
  sheetOptions: IOption[];
  sheetValue: number | null;
};
