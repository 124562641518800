import React from 'react';
import { Creative, CreativeUploader } from 'types/creative';
import { localize } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { FileUploader } from 'shared/components/common/form/FileUploader';
import { VideoUrlUploader } from './components/VideoUrlUploader';
import { Preview } from './components/Preview';
import classes from './CreativeView.module.scss';

type Props = {
  creative: Creative | null;
  uploader: CreativeUploader;
  onReplace?(): void;
};

const CreativeView: React.FC<Props> = ({ creative, uploader, onReplace }) => {
  if (creative === null) {
    return null;
  }

  if (creative.uploadStatus.type === 'uploadImage') {
    return (
      <FileUploader
        fileValidationRules={uploader.fileValidationRules}
        onUpload={uploader.uploadImage}
        onCancelUpload={uploader.cancel}
      />
    );
  }

  if (creative.uploadStatus.type === 'uploadVideo') {
    return <VideoUrlUploader onUpload={uploader.uploadVideo} onCancelUpload={uploader.cancel} />;
  }

  return (
    <div className={classes.root}>
      <Preview creative={creative} />
      {onReplace && (
        <Button
          className={classes.replaceButton}
          icon="icon icon-replace"
          iconColor="var(--color-basic-dark)"
          theme="transparent"
          tooltip={localize('campaign-view.creative.actions.replace-file')}
          onClick={onReplace}
        />
      )}
    </div>
  );
};

export { CreativeView };
