import React from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { PaginatorFirstPageLinkOptions } from 'primereact/paginator';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import classes from './PageLinkButton.module.scss';

type Props = Partial<PaginatorFirstPageLinkOptions>;

export const PageLinkButton: React.VFC<Props> = (props) => {
  const { className, iconClassName, ...restProps } = R.omit(['element', 'props'])(props);
  return <TextButton className={cx(classes.root, className)} icon={iconClassName} padding {...restProps} />;
};

export type { Props as PageLinkButtonProps };
