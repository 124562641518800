import React, { useCallback, useEffect } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { observer } from 'mobx-react';
import cx from 'classnames';

import { LocalizedMessage } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { useStores } from 'core';
import getLogoSrc from 'helpers/getLogoSrc';
import { IAdvertiser } from 'types/advertiser';
import CustomLinks from '../components/CustomLinks';
import SectionComponent from '../components/SectionComponent';
import CustomSettings from '../components/CustomSettings';

import classes from './PowerBiAdvertiserSettings.module.scss';

export const PowerBiAdvertiserSettings = observer(() => {
  const { advertiserStore, powerBiStore } = useStores();
  const { currentAdvertiserId, advertiser } = advertiserStore;
  const {
    initFields,
    description,
    logoStatus,
    internalFields,
    clientFields,
    internalCustomUrlFields,
    campaigns,
    brands,
    clientCustomUrlFields,
    clientCustomFields,
    internalCustomFields,
    categories,
    subbrandsByBrandId,
    setClientCustomUrls,
    setInternalCustomUrls,
    setLogoStatus,
    changeInternalFields,
    changeClientFields,
    setInternalCustomSettings,
    setClientCustomSettings,
    addRemovableFields,
    prepareSaveSettings,
    loadSubbrands,
  } = powerBiStore.childrenStores.advertiserSettings;

  const handleCustomSettingsDelete = useCallback(
    (id: number, callback: () => void) => {
      addRemovableFields(id);
      callback();
    },
    [addRemovableFields],
  );

  const handleSaveSettings = useCallback(() => {
    prepareSaveSettings(currentAdvertiserId);
  }, [prepareSaveSettings, currentAdvertiserId]);

  useEffect(() => {
    initFields(currentAdvertiserId);
  }, [currentAdvertiserId, initFields]);

  const handleLogoLoad = useCallback(() => {
    setLogoStatus('loaded');
  }, [setLogoStatus]);

  const handleLogoError = useCallback(() => {
    setLogoStatus('error');
  }, [setLogoStatus]);

  const renderAdvertiserLabel = ({ name, logoFileId }: IAdvertiser) => {
    return logoFileId !== null && logoStatus !== 'error' ? (
      <img
        src={getLogoSrc(logoFileId)}
        className={cx(classes.Logo, {
          [classes.LogoHide]: logoStatus === 'loading',
        })}
        onLoad={handleLogoLoad}
        onError={handleLogoError}
        alt={name}
      />
    ) : (
      name
    );
  };

  if (!description.length || !advertiser) {
    return null;
  }

  const descriptionForGroups = description.filter((it) => !it.allowMultipleUrls);
  const customUrlFieldPage = description.find(
    (it) => it.pageName === 'BRAND_LIST' && it.positionName === 'ADVERTISER_CUSTOM',
  );

  return (
    <div className={classes.Layout}>
      <div className={classes.Content}>
        <div className={classes.Header}>
          <h1 className={classes.Title}>
            <LocalizedMessage id="site.title.powerbi-advertiser-settings" />
          </h1>
        </div>

        <div className={classes.Form}>
          <h2 className={classes.Advertiser}>{renderAdvertiserLabel(advertiser)}</h2>
          <div className={classes.Tabs}>
            <TabView renderActiveOnly={false}>
              <TabPanel header={<LocalizedMessage id="powerbi.sections.internal" />}>
                <div className={classes.TabContent}>
                  <SectionComponent
                    isAdvertiserSection
                    checkboxType="enable"
                    fields={internalFields}
                    description={descriptionForGroups}
                    onChange={changeInternalFields}
                  />
                  <LocalizedMessage id="powerbi.sections.custom-settings" />
                  <div className={classes.CustomSettings}>
                    <CustomSettings
                      loadSubbrands={loadSubbrands}
                      type="internal"
                      customFields={internalCustomFields}
                      description={description}
                      brands={brands}
                      campaigns={campaigns}
                      categories={categories}
                      subbrandsByBrandId={subbrandsByBrandId}
                      onChange={setInternalCustomSettings}
                      onDelete={handleCustomSettingsDelete}
                    />
                  </div>
                  {customUrlFieldPage && (
                    <div className={classes.CustomSettings}>
                      <LocalizedMessage id="powerbi.sections.custom-advertiser-dashboards" />
                      <CustomLinks
                        pageId={customUrlFieldPage.id}
                        links={internalCustomUrlFields}
                        onDelete={handleCustomSettingsDelete}
                        onChange={setInternalCustomUrls}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel header={<LocalizedMessage id="powerbi.sections.client" />}>
                <div className={classes.TabContent}>
                  <SectionComponent
                    isAdvertiserSection
                    checkboxType="useInternal"
                    fields={clientFields}
                    description={descriptionForGroups}
                    onChange={changeClientFields}
                  />
                  <LocalizedMessage id="powerbi.sections.custom-settings" />
                  <div className={classes.CustomSettings}>
                    <CustomSettings
                      loadSubbrands={loadSubbrands}
                      type="client"
                      customFields={clientCustomFields}
                      description={description}
                      brands={brands}
                      campaigns={campaigns}
                      categories={categories}
                      subbrandsByBrandId={subbrandsByBrandId}
                      onChange={setClientCustomSettings}
                      onDelete={handleCustomSettingsDelete}
                    />
                  </div>
                  {customUrlFieldPage && (
                    <div className={classes.CustomSettings}>
                      <LocalizedMessage id="powerbi.sections.custom-advertiser-dashboards" />
                      <CustomLinks
                        onDelete={handleCustomSettingsDelete}
                        onChange={setClientCustomUrls}
                        pageId={customUrlFieldPage.id}
                        links={clientCustomUrlFields}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
            </TabView>
          </div>

          <div className={classes.Save}>
            <Button onClick={handleSaveSettings}>
              <LocalizedMessage id="button.save" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
