import { normalizeStr } from 'helpers/utils';
import { DictionaryValue } from 'types/dictionary';

export function convertDictionaryValue(dictionaryValues: DictionaryValue[], idOrValue: string): number | null;
export function convertDictionaryValue(dictionaryValues: DictionaryValue[], idOrValue: number): string | null;
export function convertDictionaryValue(
  dictionaryValues: DictionaryValue[],
  idOrValue: string | number,
): number | string | null {
  if (typeof idOrValue === 'string') {
    const externalId = dictionaryValues.find((x) => normalizeStr(x.value) === normalizeStr(idOrValue))?.externalId;

    return Number(externalId) ?? null;
  }

  return dictionaryValues.find((x) => Number(x.externalId) === idOrValue)?.value ?? null;
}
