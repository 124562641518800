import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import cx from 'classnames';

import { formatCampaignSum } from 'helpers/utils';
import classes from 'routes/Brands/Brands.module.scss';
import YearSelector from 'routes/Brands/components/YearSelector';
import PageSizeChanger from 'routes/Brands/components/PageSizeChanger';
import BrandCardContainer from 'routes/Brands/components/BrandCardContainer';
import {
  LocalizedMessage,
  LocalizedTitle,
  ManagedPaginationButtons,
  NoDataFallback,
  PowerBIReport,
  Scrollbar,
} from 'shared/components/other';
import { Footer } from 'shared/components/layout/Footer';
import { Loader } from 'shared/components/common/misc/Loader';
import { Button } from 'shared/components/common/buttons/Button';
import { CloseButton } from 'shared/components/common/buttons/CloseButton';
import { SearchInput } from 'shared/components/common/form/SearchInput';
import { useStores } from 'core';
import { useSearchParams } from 'helpers';
import { PowerBIUrl } from 'types/powerBI';
import BudgetChart from './components/BudgetChart/BudgetChart';

// to preserve global state when user returns to a page

export const Brands = observer(() => {
  const { brandsStore: store, appearanceStore, localeStore, globalLoaderStore } = useStores();
  const { brandTheme } = appearanceStore;

  const {
    isShowDashboard,
    brands,
    pageSize,
    totalElements,
    yearList,
    activeYear,
    powerBIResponse,
    isPowerBILoading,
    brandsStatistics,
    selectedDashboardUrl,
    customPowerBIUrls,
  } = store;
  const { lang } = localeStore;

  const { searchParams, addSearchParams, removeSearchParam } = useSearchParams();
  const brandNameParam = searchParams.get('brandName') ?? undefined;

  useEffect(() => {
    store.addFilters({ brandName: brandNameParam });
    store.activate();
    return store.cleanUp;
  }, [store]); // eslint-disable-line react-hooks/exhaustive-deps

  const totalBudget = brandsStatistics.reduce((sum, current) => sum + current.budgetPlan, 0);
  const { value: budgetValue, unitId } = formatCampaignSum(totalBudget);
  const totalActiveCampaigns = brandsStatistics.reduce((sum, current) => sum + current.activeCampaigns, 0);
  const totalCampaigns = brandsStatistics.reduce((sum, current) => sum + current.totalCampaigns, 0);

  const hasCustomDashboards = customPowerBIUrls && customPowerBIUrls.powerBIURLList?.length > 0;

  const history = useHistory<{ fromBrands?: boolean } | undefined>();
  const dashboardTitle = searchParams.get('dashboard');
  const dashboardTitleToUrlMap = customPowerBIUrls?.powerBIURLList
    ? customPowerBIUrls?.powerBIURLList?.reduce<Record<string, string | null>>(
        (acc, { powerBIURL, powerBIURLTitle }) => ({
          ...acc,
          [powerBIURLTitle]: powerBIURL,
        }),
        {},
      )
    : {
        default: powerBIResponse?.powerBIURL ?? null,
      };
  const dashboardUrl = dashboardTitle ? dashboardTitleToUrlMap?.[dashboardTitle] ?? null : null;

  useEffect(() => {
    if (dashboardUrl !== null && selectedDashboardUrl !== dashboardUrl) {
      store.openDashboard(dashboardUrl);
    }
  }, [dashboardUrl, selectedDashboardUrl, store]);

  useEffect(() => {
    if (isShowDashboard && dashboardUrl === null) {
      store.closeDashboard();
    }
  }, [dashboardUrl, isShowDashboard, store]);

  useEffect(() => {
    const shouldClearQueries = customPowerBIUrls !== undefined && dashboardUrl === null && dashboardTitle !== null;
    if (shouldClearQueries) {
      removeSearchParam('dashboard', 'replace');
    }
  }, [customPowerBIUrls, dashboardUrl, dashboardTitle, history, removeSearchParam]);

  useEffect(() => {
    store.addFilters({ brandName: brandNameParam });
  }, [brandNameParam, store]);

  const handleCloseButtonClick = () => {
    if (history.location.state?.fromBrands) {
      history.goBack();
    } else {
      history.push('/brands');
    }
  };

  const makeDashboardButtonClickHandler = (powerBIUrl: PowerBIUrl | null) => () => {
    const url = powerBIUrl?.powerBIURL ?? null;
    const title = powerBIUrl?.powerBIURLTitle ?? 'default';
    store.openDashboard(url);
    const location = {
      pathname: '/brands/',
      state: { fromBrands: true },
    };
    history.push(location);
    addSearchParams({ dashboard: title }, 'replace');
  };

  const handleFilterByBrandNameSearch = useCallback(
    (brandName: string) => {
      store.addFilters({ brandName });
      if (brandName) {
        addSearchParams({ brandName });
      } else {
        removeSearchParam('brandName');
      }
    },
    [addSearchParams, removeSearchParam, store],
  );

  const handleFilterByBrandNameClear = useCallback(() => {
    store.removeFilters(['brandName']);
  }, [store]);

  if (isShowDashboard) {
    return (
      <div className={classes.Dashboard}>
        <CloseButton className={classes.CloseBtn} onClick={handleCloseButtonClick} />
        <PowerBIReport
          pageName="BRAND_LIST"
          positionName={customPowerBIUrls ? 'ADVERTISER_CUSTOM' : 'ADVERTISER'}
          url={selectedDashboardUrl ?? undefined}
          isFullHeight
          loaderProps={{ visible: true, className: classes.DashboardLoader }}
          withGlobalLoader={false}
        />
      </div>
    );
  }

  return (
    <div className={classes.Brands}>
      <LocalizedTitle id="site.title.brands" />

      <div className={classes.Main}>
        <div className={classes.Header}>
          <h1 className={classes.Title}>
            <LocalizedMessage id="site.title.brands" />
          </h1>
          <YearSelector activeYear={activeYear} years={yearList} onYearChange={store.changeYear} />
          <SearchInput
            className={classes.FilterByBrandName}
            defaultValue={store.filters.brandName}
            onChange={handleFilterByBrandNameSearch}
            onClear={handleFilterByBrandNameClear}
            autoApplyParams={{ wait: 300, minLength: 1 }}
            readOnly={globalLoaderStore.isLoading}
          />
        </div>
        <div className={classes.AdditionalInfo}>
          <div>
            <div>
              <LocalizedMessage id="brands.brands-total" />:<span className={classes.ShowValue}>{totalElements}</span>
            </div>
            <div className={classes.PageSize}>
              <span>
                <LocalizedMessage id="brands.page-size" />:
              </span>
              <PageSizeChanger onPageSizeChange={store.setPageSize} pageSize={pageSize} />
            </div>
          </div>
          <div className={classes.PaginationButtons}>
            <ManagedPaginationButtons pagination={store} />
          </div>
        </div>
        <div className={classes.MainContent}>
          <Scrollbar>
            <div className={classes.BrandsList}>
              {store.data.length > 0 ? (
                store.data.map(({ brand, statistics, color }) => (
                  <BrandCardContainer
                    key={brand.id}
                    brand={brand}
                    statistics={statistics}
                    activeYear={activeYear}
                    brandTheme={brandTheme}
                    color={color}
                  />
                ))
              ) : (
                <NoDataFallback />
              )}
            </div>
          </Scrollbar>
        </div>
        <Footer />
      </div>

      <div className={classes.Summary}>
        <header className={classes.SummaryHeader}>
          <LocalizedMessage id="brands.summary.title" />
        </header>
        <div className={classes.SummaryChart}>
          {brands.length === 0 ? (
            <div className={cx(classes.SummaryLabel, classes.SummaryLabelIsEmpty)}>
              <LocalizedMessage id="brands.summary.nodata" />
            </div>
          ) : (
            <BudgetChart size={320} year={activeYear} locale={lang} brandsStatistics={brandsStatistics} />
          )}
        </div>
        <div className={classes.SummaryInfo}>
          <div className={classes.SummaryLabel}>
            <LocalizedMessage id="brands.budget" />
          </div>
          <div className={classes.SummaryValue}>
            {budgetValue.toLocaleString()}
            &nbsp;
            <LocalizedMessage id={unitId} />
          </div>
          <div className={classes.SummaryLabel}>
            <LocalizedMessage id="brands.active-campaigns" />
          </div>
          <div className={classes.SummaryValue}>
            {totalActiveCampaigns}/{totalCampaigns}
          </div>
        </div>
        {/* Dashboard button not for all advertisers */}
        {isPowerBILoading ? (
          <Loader size="small" />
        ) : (
          powerBIResponse &&
          !hasCustomDashboards && (
            <Button
              className={classes.Button}
              size="big"
              data-test="dashboard-button"
              onClick={makeDashboardButtonClickHandler(null)}
            >
              <LocalizedMessage id="brands.summary.button" />
            </Button>
          )
        )}

        <div className={classes.SummaryCustomButtonsContainer}>
          {customPowerBIUrls?.powerBIURLList?.map((customUrl) => (
            <Button
              key={customUrl.powerBIURLTitle}
              className={classes.Button}
              size="big"
              data-test="dashboard-button"
              onClick={makeDashboardButtonClickHandler(customUrl)}
            >
              <div
                className={classes.ButtonText}
                data-global-tooltip
                data-pr-tooltip={customUrl.powerBIURLTitle}
                data-pr-mouse-track-static={false}
                data-pr-position="left"
              >
                {customUrl.powerBIURLTitle}
              </div>
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
});
