import HT from 'handsontable';
import { BaseRenderer } from 'handsontable/renderers';
import { numericRenderer } from '../renderers';

export const columnTypeToHTRendererMap: Partial<Record<HT.CellType, BaseRenderer>> = {
  checkbox: HT.renderers.CheckboxRenderer,
  date: HT.renderers.AutocompleteRenderer,
  dropdown: HT.renderers.AutocompleteRenderer,
  numeric: numericRenderer,
  text: HT.renderers.TextRenderer,
};
