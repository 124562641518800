import { ServerModels as SM } from 'types/server';
import { DataRow } from 'types/programmatic/excess';
import { roundCurrency } from '../helpers';

export function convertExcessCampaignToDataRow(row: SM.ExcessCampaign): DataRow {
  return {
    id: row.campaignId,
    advertiserId: row.advertiserId ?? null,
    advertiserName: row.advertiserName ?? null,
    campaignId: row.campaignId,
    campaignName: row.campaignName ?? null,
    active: row.active ?? null,
    total: row.total ?? null,
    checkStatus: row.checkStatus ?? null,
    cabinetStatus: row.cabinetStatus ?? null,
    startDate: row.startDate ?? null,
    endDate: row.endDate ?? null,
    planBudget: roundCurrency(row.planBudget ?? null),
    factBudget: roundCurrency(row.factBudget ?? null),
    factBudgetLimit: roundCurrency(row.factBudgetLimit ?? null),
    budgetPct: row.budgetPct ?? null,
    budgetPctToday: row.budgetPctToday ?? null,
    unitPrice: null,
    factUnitPrice: null,
    unitPricePct: null,
    sellerOrStatisticsSource: null,
    format: null,
    settingsStatus: null,
    excessCauses: [],
  };
}
