import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { useStores } from 'core';
import { DataGrid, DataGridSorting, ManagedPaginationButtons } from 'shared/components/other';
import { toServerSorting } from 'helpers';
import { columns } from './LogTable.columns';
import { convertToTableRows } from './LogTable.helpers';
import classes from './LogTable.module.scss';

export const LogTable: React.VFC = observer(() => {
  const { adFoxLogStore: log } = useStores();

  const rows = convertToTableRows(log.data);

  const handleSortingChange = useCallback(
    (value: DataGridSorting) => {
      log.setSorting(toServerSorting(value));
    },
    [log],
  );

  return (
    <div className={classes.root}>
      <DataGrid
        columns={columns[log.type]}
        rows={rows}
        headerRowHeight={50}
        initialSorting={log.defaultSorting}
        onSortingChange={handleSortingChange}
      />
      <div className={classes.pagination}>
        <span className={classes.info}>Общее количество записей: {log.totalItems}</span>
        <ManagedPaginationButtons pagination={log} />
      </div>
    </div>
  );
});
