import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { IBrand } from 'types/brand';
import { ISubbrand } from 'types/subbrand';
import { localize, localizeMessage, LocalizedMessage, NoDataFallback } from 'shared/components/other';
import { SaveButtons } from 'shared/components/common/buttons/SaveButtons';
import { ColorPickButton } from 'shared/components/common/buttons/ColorPickButton';
import { ColorPalette } from 'shared/components/common/form/ColorPalette';
import { Select } from 'shared/components/common/form/Select';
import { useStores } from 'core';
import showConfirmation from 'helpers/showConfirmation';
import { useEffectOnce } from 'helpers';
import LogoPreview from '../components/LogoPreview';
import { SubbrandsList } from './components';

import classes from './EditBrands.module.scss';

type Params = { id?: string };

function getBrandIdFromPath(params: Params): number | null {
  return params !== undefined && params.id !== undefined ? Number(params.id) : null;
}

function getBrandOption(brand: IBrand) {
  return {
    value: brand.id,
    label: brand.name,
  };
}

export const EditBrands = observer(() => {
  const { advertiserStore, brandViewStore, editBrandsStore: store } = useStores();
  const { currentAdvertiserId, advertisers } = advertiserStore;
  const { hasAccessToBrand, maybeChangeAdvertiserToSuitable } = brandViewStore;
  const {
    resetBrand,
    saveBrand,
    deleteSubbrand,
    renameSubbrand,
    loadPalette,
    setLogo,
    pickedColor,
    selectedBrand,
    subbrands,
    logoPreviewPath,
    colorPalette,
    brands,
    initBrands,
    selectBrand,
    getBrandById,
    setPickedColor,
  } = store;

  const history = useHistory();
  const params = useParams<Params>();
  const brandIdFromUrl = getBrandIdFromPath(params);
  const brandsSelectOptions = brands.map(getBrandOption);

  const shouldAccessToBrandBeChecked = useRef(!!brandIdFromUrl);
  const [isAccessToBrandChecked, setIsAccessToBrandChecked] = useState(false);

  useEffect(() => {
    initBrands(currentAdvertiserId);
    return store.cleanUp;
  }, [currentAdvertiserId, store, initBrands]);

  useEffect(() => {
    if ((!shouldAccessToBrandBeChecked.current || isAccessToBrandChecked) && brandIdFromUrl !== selectedBrand?.id) {
      selectBrand(brandIdFromUrl, history.replace);
    }
  }, [brandIdFromUrl, brands, isAccessToBrandChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffectOnce(
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const hasAccess = await hasAccessToBrand(brandIdFromUrl!);
      if (hasAccess) {
        await maybeChangeAdvertiserToSuitable();
        await selectBrand(brandIdFromUrl, history.replace);
      } else {
        history.push('/administration/brands');
      }
      brandViewStore.cleanUp();
      setIsAccessToBrandChecked(true);
    },
    [brandIdFromUrl, selectedBrand, advertisers, shouldAccessToBrandBeChecked.current],
    () =>
      !!brandIdFromUrl &&
      brandIdFromUrl !== selectedBrand?.id &&
      !!advertisers.length &&
      shouldAccessToBrandBeChecked.current,
  );

  const handleBrandChange = useCallback(
    (value: number) => {
      const brand = getBrandById(value);
      if (brand !== null) {
        history.replace(`/administration/brands/${brand.id}`);
      }
    },
    [getBrandById, history],
  );

  const handleSaveButtonsSave = useCallback(() => {
    saveBrand(currentAdvertiserId);
  }, [currentAdvertiserId, saveBrand]);

  const handleEditSubbrandIconCLick = useCallback(
    async (subbrand: ISubbrand) => {
      const { id: subbrandId, name } = subbrand;
      const value = await showConfirmation({
        title: localize('administration.brands.rename-subbrand'),
        input: {
          value: name,
          maxLength: 1000,
        },
        confirmTextId: 'button.save',
      });

      if (value) {
        renameSubbrand(value, subbrandId);
      }
    },
    [renameSubbrand],
  );

  const handleDeleteSubbrandIconClick = useCallback(
    async (subbrand: ISubbrand) => {
      const isConfirmed = await showConfirmation({
        title: localizeMessage({ id: 'administration.brands.delete-subbrand' }, { subbrandName: subbrand.name }),
        type: 'removal',
      });

      if (isConfirmed) {
        deleteSubbrand(subbrand.id);
      }
    },
    [deleteSubbrand],
  );

  return (
    <div className={classes.Brands}>
      <header className={classes.Header}>
        <h1 className={classes.Title}>
          <LocalizedMessage id="administration.brands.title" />
        </h1>
        <div className={classes.BrandSelector} data-test="brand-select">
          <Select
            options={brandsSelectOptions}
            value={brandsSelectOptions.find((option) => selectedBrand && option.value === selectedBrand.id)?.value}
            onChange={handleBrandChange}
          />
        </div>
      </header>

      <div className={classes.Content}>
        {selectedBrand === null ? (
          <NoDataFallback />
        ) : (
          <>
            <div className={classes.LogoSettings}>
              <LogoPreview
                titleId="administration.brands.title"
                imageAltTextId="administration.brands.logo-alt-text"
                path={logoPreviewPath}
                onChange={setLogo}
                onLoad={loadPalette}
              >
                <ColorPalette
                  colors={colorPalette}
                  label={localize('administration.brands.select-palette-color')}
                  onPick={setPickedColor}
                />

                <div className={classes.ColorSection}>
                  <ColorPickButton
                    defaultColor={pickedColor}
                    title={localize('administration.brands.brand-color-label')}
                    onSave={setPickedColor}
                  />
                </div>
              </LogoPreview>
              <SaveButtons className={classes.SaveButtons} onCancel={resetBrand} onSave={handleSaveButtonsSave} />
            </div>
            <SubbrandsList
              subbrands={subbrands}
              onDeleteSubbrand={handleDeleteSubbrandIconClick}
              onEditSubbrand={handleEditSubbrandIconCLick}
            />
          </>
        )}
      </div>
    </div>
  );
});
