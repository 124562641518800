import React from 'react';
import cx from 'classnames';
import { ScrollPanel } from 'primereact/scrollpanel';
import { localize } from 'shared/components/other';
import classes from './CampaignDescription.module.scss';

type Props = {
  className?: string;
  content: string;
};

export const CampaignDescription: React.VFC<Props> = React.memo((props) => {
  const { className, content } = props;
  return content ? (
    <div className={cx(classes.root, className)}>
      <header className={classes.header}>
        <h1 className={classes.title}>{localize('campaign.about-campaign')}</h1>
      </header>
      <ScrollPanel className={classes.content}>{content}</ScrollPanel>
    </div>
  ) : null;
});
