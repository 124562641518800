import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useMemo } from 'react';

import { useStores } from 'core';
import { Button } from 'shared/components/common/buttons/Button';
import { Dropdown } from 'shared/components/common/form/Dropdown';
import { Input, LocalizedMessage } from 'shared/components/other';
import { OptionName } from 'types/cabinetCopying';

import { LocaleIdType } from 'locales';
import { initialOptionConfig } from '../../helpers/config';
import { FileUploadSection } from './components/FileUploadSection';
import classes from './CopyPanel.module.scss';

export const CopyPanel = observer(() => {
  const { cabinetCopyingStore: store } = useStores();
  const {
    options,
    optionNames,
    textFields,
    isValid,
    templateFileId,
    setOption,
    setTextField,
    loadOptionItems,
    loadTemplateData,
    downloadTemplate,
    save,
    cleanUp,
  } = store;

  const items = useMemo(() => optionNames.map((name) => ({ name, ...initialOptionConfig[name] })), [optionNames]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTextField(e.target.name, e.target.value);
  };

  const handleRefreshIconClick = (optionName: OptionName) => {
    setOption(optionName, null);
    loadOptionItems(optionName);
  };

  useEffect(() => {
    loadOptionItems('cabinet');
    loadTemplateData();

    return cleanUp;
  }, [loadOptionItems, loadTemplateData, cleanUp]);

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Button className={classes.downloadTemplateButton} disabled={!templateFileId} onClick={downloadTemplate}>
          <LocalizedMessage id="cabinet-copying.buttons.download-template" />
        </Button>
        <div className={classes.options}>
          {items.map(({ name, localeId, updatable }) => {
            const option = options[name];
            const isOptionLoading = option.requestCount > 0;
            return (
              <label
                key={name}
                className={cx(classes.label, {
                  [classes.labelDisabled]: options[name].disabled,
                })}
              >
                <LocalizedMessage id={localeId} />
                <Dropdown
                  className={classes.option}
                  theme="grey"
                  options={option.items}
                  value={option.value}
                  disabled={option.disabled}
                  filter
                  dropdownIcon={isOptionLoading ? 'pi pi-spin pi-spinner' : 'pi icon-arrow-down'}
                  onChange={(e) => setOption(name, e.value)}
                />
                {updatable && (
                  <i
                    className={cx('pi pi-refresh', classes.refreshIcon, {
                      [classes.refreshIconDisabled]: options[name].disabled,
                    })}
                    onClick={() => handleRefreshIconClick(name)}
                  />
                )}
              </label>
            );
          })}
          {textFields.map((field) => (
            <label key={field.name} className={classes.label}>
              <LocalizedMessage id={`cabinet-copying.labels.${field.name}` as LocaleIdType} />
              <Input
                className={classes.input}
                theme="round"
                name={field.name}
                value={field.value}
                hasError={field.invalid}
                onChange={handleInputChange}
              />
              {field.invalid && (
                <span className={classes.error}>
                  <LocalizedMessage id="cabinet-copying.errors.invalid-url" />
                </span>
              )}
            </label>
          ))}
        </div>
      </div>
      <FileUploadSection />
      <Button className={classes.saveButton} disabled={!isValid} onClick={save}>
        <LocalizedMessage id="cabinet-copying.buttons.create-ad-copies" />
      </Button>
    </div>
  );
});
