import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import queryString from 'query-string';

import { MediaType } from 'types/campaign';
import { LocalizedMessage, LocalizedTitle, PowerBIReport } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { Select } from 'shared/components/common/form/Select';
import { Footer } from 'shared/components/layout/Footer';
import { useStores } from 'core';
import hasAccess from 'helpers/hasAccess';
import { useEffectOnce } from 'helpers';
import FlowChart from './FlowChart/FlowChart';
import MediaCard from './MediaCard/MediaCard';
import classes from './BrandView.module.scss';

const mediaTypes: MediaType[] = [MediaType.TV, MediaType.WEB, MediaType.OUTDOOR, MediaType.PRESS, MediaType.RADIO];

export const BrandView = observer(() => {
  const {
    localeStore: { lang },
    advertiserStore,
    brandViewStore: store,
  } = useStores();

  const location = useLocation();
  const params = useParams<{ id?: string; year?: string }>();

  const { isLoading } = advertiserStore;

  useEffectOnce(
    () => {
      const searchParams = queryString.parse(location.search);
      const id = params.id ? Number(params.id) : null;

      runInAction(() => {
        store.search = searchParams;
        store.brandId = id;
        store.initialLoad();
      });
    },
    [isLoading],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ([isLoading]) => !isLoading,
  );

  useEffect(() => {
    return store.cleanUp;
  }, [store]);

  const { brandId, activeYear, brands, brandMediaTypeBudgets, brandCampaigns } = store;
  const {
    view,
    view: { yearPeriodOptions, isScrollbarHidden, brandOptions },
  } = store.childrenStores;

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const id = params.id ? Number(params.id) : null;
    runInAction(() => {
      store.search = searchParams;
      store.brandId = id;
    });
  }, [store, params, location.search]);

  if (!brands.length) {
    return null;
  }

  const hasCampaigns = brandCampaigns.length > 0;

  const requiredMediaTypes: MediaType[] = [MediaType.TV, MediaType.WEB];

  return (
    <div className={classes.BrandView}>
      <LocalizedTitle id="site.title.view-brand" />

      <div className={classes.Main}>
        <div className={classes.BrandSection}>
          <header className={classes.BudgetsHeader}>
            <h2 className={classes.BrandTitle}>
              <LocalizedMessage id="site.title.brand" />
            </h2>
            <div className={classes.BrandSelector} data-test="brand-select">
              <Select
                options={brandOptions}
                value={brandOptions.find((option) => option.value === brandId)?.value}
                onChange={view.onChangeBrand}
                theme="grey"
              />
            </div>
            <div className={classes.YearSelector} data-test="year-select">
              <Select
                options={yearPeriodOptions}
                value={yearPeriodOptions.find((option) => option.value === activeYear)?.value}
                onChange={view.onChangeYear}
              />
            </div>
          </header>

          <div className={classes.MediaCards}>
            {brandMediaTypeBudgets &&
              mediaTypes.map((mediaType) => {
                const mediaTypeBudget = brandMediaTypeBudgets.find((brand) => brand.mediaType === mediaType);

                if (!mediaTypeBudget && !requiredMediaTypes.includes(mediaType)) {
                  return null;
                }

                return (
                  <div key={mediaType} className={classes.MediaCardWrapper}>
                    <MediaCard type={mediaType} mediaTypeBudget={mediaTypeBudget} />
                  </div>
                );
              })}
          </div>
        </div>

        <div
          className={classes.FlowChartContainer}
          onMouseEnter={hasCampaigns ? view.handleFlowChartMouseEnter : undefined}
          onMouseLeave={hasCampaigns ? view.handleFlowChartMouseLeave : undefined}
        >
          <header className={classes.FlowChartHeader}>
            <h2 className={classes.FlowChartTitle}>
              <LocalizedMessage id="brand.flowchart.title" values={{ year: String(activeYear) }} />
            </h2>
            {hasAccess('addCampaignToBrand', 'brandPage') && (
              <Link className={classes.AddCampaignButton} to="/campaigns/new" data-test="add-campaign-button">
                <Button>
                  + &nbsp;
                  <LocalizedMessage id="brand.button.add-campaign" />
                </Button>
              </Link>
            )}
          </header>
          <div className={classes.FlowChartWrapper}>
            <FlowChart
              year={activeYear}
              campaigns={brandCampaigns}
              locale={lang}
              isScrollbarHidden={isScrollbarHidden}
            />
          </div>
        </div>
        {brandId && (
          <PowerBIReport
            pageName="BRAND"
            brandId={brandId}
            isFullHeight
            withGlobalLoader={false}
            loaderProps={{ visible: true, className: classes.PowerBILoader }}
          />
        )}
      </div>
      <Footer />
    </div>
  );
});
