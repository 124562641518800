import { IOption } from 'types';
import {
  AccountItem,
  AdvertisementItem,
  Item,
  OptionName,
  SettingMetadata,
  SettingsMetadata,
} from 'types/cabinetCopying';

export function convertItemsToOptions(items: Item[]): IOption[] {
  return items.map((item) => ({
    value: item.id,
    label: item.name,
    accountType: isAccount(item) ? item.accountType : undefined,
    adFormat: isAdvertisement(item) ? item.adFormat : undefined,
    allowedMediaTypes: isAdvertisement(item) ? item.allowedMediaTypes ?? [] : undefined,
    requiredLogo: isAdvertisement(item) ? item.requiredLogo : undefined,
  }));
}

function isAccount(item: Item): item is AccountItem {
  return 'accountType' in item;
}

function isAdvertisement(item: Item): item is AdvertisementItem {
  return 'adFormat' in item && 'allowedMediaTypes' in item && 'requiredLogo' in item;
}

export function convertSettingsMetadata(data: SettingsMetadata): SettingMetadata<OptionName>[] {
  const cabinetOption: SettingMetadata<OptionName> = {
    name: 'cabinet',
    format: 'list',
    isOptional: false,
  };
  return [cabinetOption, ...data.metadata.userSettings, ...data.metadata.adSettings];
}
