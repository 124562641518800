import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import cx from 'classnames';
import { Calendar as PrimeCalendar, CalendarChangeParams, CalendarProps } from 'primereact/calendar';
import { format, startOfMonth } from 'date-fns';
import { isEqualDates, monthNavigatorTemplate, yearNavigatorTemplate } from './Calendar.helpers';
import { localeOption } from '../../localization';
import { CloseButton } from '../../buttons/CloseButton';
import { defaultTooltipOptions } from '../../defaultProps';
import { toast } from '../../misc/Toast';

import classes from './Calendar.module.scss';

export type DateType = Date | Date[] | string | null;

type CalendarValue = {
  value: DateType;
  isValid: boolean;
};

type Props = {
  value: DateType;
  onChange(date: DateType): void;
  mode?: 'single' | 'range';
  invalid?: boolean;
  showClearButton?: boolean;
  clearButtonIcon?: string;
  clearButtonTooltip?: string;
  defaultDate?: DateType;
  theme?: 'light' | 'grey';
} & Omit<CalendarProps, 'value' | 'onChange' | 'selectionMode' | 'clearButtonClassName'>;

const YEAR_RANGE = ['2010', `${new Date().getFullYear() + 6}`];

export const Calendar: React.VFC<Props> = (props) => {
  const {
    value,
    onChange: onDateChange,
    onHide,
    className,
    inputClassName,
    panelClassName,
    minDate,
    maxDate,
    theme = 'grey',
    clearButtonIcon = 'pi pi-times',
    clearButtonTooltip,
    defaultDate = null,
    disabled = false,
    invalid = false,
    mode = 'single',
    placeholder = `${localeOption(mode === 'single' ? 'Select' : 'Period')}...`,
    dateFormat = 'dd.mm.yy',
    showClearButton = true,
    tooltipOptions,
    ...primeCalendarProps
  } = props;
  const calendarRef = useRef<PrimeCalendar>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [date, setDate] = useState<CalendarValue>({ value, isValid: true });
  useUpdateEffect(() => {
    setDate({ value, isValid: true });
  }, [value]);

  const showButton = useMemo(() => showClearButton && date.value !== null && !disabled, [
    showClearButton,
    date.value,
    disabled,
  ]);

  const tooltip = useMemo(() => {
    const dateLabels = [
      minDate && `минимальная дата - ${format(minDate, 'dd.MM.yyyy')}`,
      maxDate && `максимальная дата - ${format(maxDate, 'dd.MM.yyyy')}`,
    ].filter(Boolean);
    return dateLabels.length ? dateLabels.join(', ') : undefined;
  }, [minDate, maxDate]);

  const onChange = useCallback(
    (newDate: CalendarValue) => {
      if (newDate.isValid && !isEqualDates(value, newDate.value)) {
        onDateChange(newDate.value);
      }

      if (mode === 'range' && calendarRef.current) {
        calendarRef.current.hide();
      }
    },
    [value, mode, onDateChange],
  );

  const handleChange = useCallback(
    (e: CalendarChangeParams) => {
      const newDate = e.value ?? null;
      const isPeriodPartiallySelected = mode === 'range' && Array.isArray(newDate) && newDate[1] === null;
      const isValid = !isPeriodPartiallySelected;

      setDate({ value: newDate, isValid });

      if (isValid) {
        onChange({ value: newDate, isValid });
      }
    },
    [mode, onChange],
  );

  const handleHide = useCallback(() => {
    onHide?.();
    inputRef.current?.blur();

    if (!date.isValid) {
      toast.warning(`Выберите корректную дату${tooltip ? ` (${tooltip})` : ''}`);
      setDate({ value, isValid: true }); // revert to previous date
    }
  }, [date, value, tooltip, onHide]);

  const handleClear = useCallback(() => {
    const newDate = { value: defaultDate, isValid: true };
    setDate(newDate);
    onChange(newDate);
  }, [defaultDate, onChange]);

  const yearRange = useMemo(() => {
    if (!minDate && !maxDate) return YEAR_RANGE.join(':');

    if (minDate && maxDate) return `${minDate.getFullYear()}:${maxDate.getFullYear()}`;

    return minDate ? `${minDate.getFullYear()}:${YEAR_RANGE[1]}` : `${YEAR_RANGE[0]}:${maxDate?.getFullYear()}`;
  }, [minDate, maxDate]);

  return (
    <div
      className={cx(className, classes.root, {
        [classes[`${theme}Theme`]]: theme,
        [classes.rootInvalid]: invalid,
      })}
    >
      <PrimeCalendar
        {...primeCalendarProps}
        ref={calendarRef}
        inputRef={inputRef}
        value={date.value ?? undefined}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleChange}
        onHide={handleHide}
        disabled={disabled}
        placeholder={placeholder}
        selectionMode={mode}
        dateFormat={dateFormat}
        viewDate={startOfMonth(new Date())}
        readOnlyInput
        showMinMaxRange
        tooltip={tooltip}
        tooltipOptions={{ ...defaultTooltipOptions, ...tooltipOptions }}
        inputClassName={cx(inputClassName, classes.calendarInput)}
        panelClassName={cx('p-datepicker-small', panelClassName)}
        locale="ru"
        monthNavigator
        monthNavigatorTemplate={monthNavigatorTemplate}
        yearNavigator
        yearNavigatorTemplate={yearNavigatorTemplate}
        yearRange={yearRange}
      />
      {showButton && (
        <CloseButton
          className={classes.clearButton}
          icon={clearButtonIcon}
          iconColor={theme === 'grey' ? undefined : 'var(--color-red)'}
          onClick={handleClear}
          size="xsmall"
          tooltip={clearButtonTooltip}
          tooltipOptions={defaultTooltipOptions}
        />
      )}
    </div>
  );
};
