import React from 'react';
import cx from 'classnames';
import { Badge, BadgeSeverityType } from 'primereact/badge';
import classes from './CheckStatusCell.module.scss';

type Props = {
  badge?: BadgeSeverityType;
  label?: string;
  tooltip?: string;
};

export const CheckStatusCell: React.VFC<Props> = ({ badge, label, tooltip }) => (
  <div className={cx(classes.root)} data-pr-tooltip={tooltip} data-pr-showdelay="300">
    <Badge className={classes.badge} severity={badge} />
    {label}
  </div>
);
