import React, { useCallback, useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import { Tooltip as PrimeTooltip, TooltipProps } from 'primereact/tooltip';
import { TooltipEventParams } from 'primereact/tooltip/tooltipoptions';
import { isOverflowed, getAvailablePosition } from './GlobalTooltip.helpers';
import classes from './GlobalTooltip.module.scss';

/** To enable a tooltip on an element, it must have the "data-global-tooltip" attribute */

type Props = Omit<TooltipProps, 'target'> & {
  /** Whether to show tooltip only for overflowed elements */
  adjustable?: boolean;
  /** Show next to cursor, don't follow cursor */
  mouseTrackStatic?: boolean;
};

export const GlobalTooltip: React.FC<Props> = (props) => {
  const { adjustable, className, mouseTrackLeft = 5, mouseTrackStatic, mouseTrackTop = 5, ...restProps } = props;
  const [isMouseTrackStatic, setIsMouseTrackStatic] = useState(mouseTrackStatic);

  const tooltipRef = useRef<PrimeTooltip>(null);

  useEffect(() => {
    const listener: EventListener = (e) => {
      if (e.target instanceof HTMLElement && e.target.dataset.globalTooltip && e.target.dataset.prTooltip) {
        tooltipRef.current?.updateTargetEvents(e.target);
      }
    };
    document.addEventListener('mouseover', listener);
    return () => document.removeEventListener('mouseover', listener);
  }, []);

  const handleBeforeShow = useCallback(
    (e: TooltipEventParams) => {
      const { dataset } = e.target;
      const getIsMouseTrackStatic = (): boolean => {
        if (dataset.prMouseTrackStatic === undefined) return !!mouseTrackStatic;
        if (dataset.prPosition) return false;
        return dataset.prMouseTrackStatic !== 'false';
      };
      setIsMouseTrackStatic(getIsMouseTrackStatic());
      if (isMouseTrackStatic && e.originalEvent instanceof MouseEvent) {
        document.body.style.setProperty('--pr-tooltip-top', `${e.originalEvent.y + mouseTrackTop}px`);
        document.body.style.setProperty('--pr-tooltip-left', `${e.originalEvent.x + mouseTrackLeft}px`);
      }

      const isAdjustable = dataset.prAdjustable !== 'false' ?? adjustable;
      if (isAdjustable && !isOverflowed(e.target)) {
        e.target.dataset.prDisabled = 'true';
        const handleMouseleave = () => {
          e.target.removeAttribute('data-pr-disabled');
          e.target.removeEventListener('mouseleave', handleMouseleave);
        };
        e.target.addEventListener('mouseleave', handleMouseleave);
      }

      if (dataset.prAt || dataset.prMy) return;

      const position = getAvailablePosition(e.target);
      if (position) {
        if (!dataset.savedPosition) {
          e.target.dataset.savedPosition = dataset.prPosition;
        }
        e.target.dataset.prPosition = position;
        ['pr-at', 'pr-my'].forEach((x) => e.target.removeAttribute(`data-${x}`));
      } else {
        e.target.removeAttribute('data-pr-position');
        e.target.dataset.prAt = 'center';
        e.target.dataset.prMy = 'center';
      }
    },
    [adjustable, isMouseTrackStatic, mouseTrackLeft, mouseTrackStatic, mouseTrackTop],
  );

  return (
    <PrimeTooltip
      autoHide={false}
      className={cx(classes.root, className, { [classes.static]: isMouseTrackStatic })}
      mouseTrack={isMouseTrackStatic}
      mouseTrackLeft={mouseTrackLeft}
      mouseTrackTop={mouseTrackTop}
      onBeforeShow={handleBeforeShow}
      ref={tooltipRef}
      showDelay={200}
      {...restProps}
    />
  );
};

export type { Props as TooltipProps };
