import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LocalizedMessage } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { useStores } from 'core';
import SectionComponent from '../components/SectionComponent';

import classes from './PowerBiDefaultSettings.module.scss';

export const PowerBiDefaultSettings = observer(() => {
  const { powerBiStore } = useStores();
  const { description, fields, loadFields, changeField, saveLinks } = powerBiStore.childrenStores.defaultSettings;

  useEffect(() => {
    loadFields();
  }, [loadFields]);

  if (description.length === 0) {
    return null;
  }

  const descriptionForGroups = description.filter((it) => !it.allowMultipleUrls);

  return (
    <div className={classes.Layout}>
      <div className={classes.Content}>
        <div className={classes.Header}>
          <h1 className={classes.Title}>
            <LocalizedMessage id="site.title.powerbi-default-settings" />
          </h1>
        </div>

        <div className={classes.Form}>
          <form>
            <SectionComponent
              isAdvertiserSection={false}
              checkboxType="enable"
              fields={fields}
              description={descriptionForGroups}
              onChange={changeField}
            />
            <div className={classes.Save}>
              <Button onClick={saveLinks}>
                <LocalizedMessage id="button.save" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
