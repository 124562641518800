import { CheckSettingsDictionary } from 'types/programmatic';
import { ServerModels as SM } from 'types/server';

import { queries } from './queries';
import { mutations } from './mutations';
import {
  toClientCheckSettingsMetadata,
  toClientCheckSettings,
  toClientSetCheckSettingsResult,
  toServerCheckSettings,
  ExcessCheckSettingsRequest,
  ExcessCheckSettingsResponse,
} from './converters';
import { QueryResult, MutationResult } from '../../types';
import { getClient } from '../../../client';

type Api = {
  campaignsData(
    variables?: SM.ExcessCampaignsDataQueryVariables,
    abortSignal?: AbortSignal,
  ): QueryResult<SM.ExcessCampaignsDataQuery>;
  rowsAndPositions(variables?: SM.ExcessRowsAndPositionsQueryVariables): QueryResult<SM.ExcessRowsAndPositionsQuery>;
  campaignCsIds(variables?: SM.ExcessCampaignCsIdsQueryVariables): QueryResult<SM.ExcessCampaignCsIdsQuery>;
  logEntries(variables?: SM.ExcessLogEntriesQueryVariables): QueryResult<SM.ExcessLogEntriesQuery>;
  checkSettingsMetadata(): Promise<CheckSettingsDictionary | null>;
  checkSettings(variables?: SM.QueryExcessCheckSettingsArgs): Promise<ExcessCheckSettingsResponse>;
  setCheckSettings(variables: ExcessCheckSettingsRequest): Promise<SM.ExcessSettingsStatus | null>;
  changeCabinetStatus(
    variables: SM.ExcessChangeCabinetStatusRequest,
  ): MutationResult<SM.ExcessChangeCabinetStatusResponse>;
};

export const excess: Api = {
  campaignsData: (variables, signal) =>
    getClient().query({
      query: queries.CampaignsData,
      variables,
      context: signal && { fetchOptions: { signal } },
    }),
  rowsAndPositions: (variables) => getClient().query({ query: queries.RowsAndPositions, variables }),
  campaignCsIds: (variables) => getClient().query({ query: queries.CampaignCsIds, variables }),
  logEntries: (variables) => getClient().query({ query: queries.LogEntries, variables }),
  checkSettingsMetadata: () =>
    getClient().query({ query: queries.ExcessCheckSettingsMetadata }).then(toClientCheckSettingsMetadata),
  checkSettings: (variables) =>
    getClient().query({ query: queries.ExcessCheckSettings, variables }).then(toClientCheckSettings),
  setCheckSettings: (variables) =>
    getClient()
      .mutate({
        mutation: mutations.ExcessSetCheckSettings,
        variables: toServerCheckSettings(variables),
      })
      .then(toClientSetCheckSettingsResult),
  changeCabinetStatus: (variables) =>
    getClient().mutate({
      mutation: mutations.ExcessChangeCabinetStatus,
      variables: { request: variables },
    }),
};
