import { TvTerritoryType } from 'types/mediaplan';
import { UploadedStatus } from 'types/campaign';

type Nullable<T> = T | null | undefined;

export function isTerritoryTypeFilled(
  territoryType: Nullable<TvTerritoryType>,
  uploadedStatus: Nullable<UploadedStatus>,
): boolean {
  const isTvTypeRequired = uploadedStatus === 'mediaplan';
  return !!territoryType || !isTvTypeRequired;
}

export function areSelectorsFilled(
  audienceId: Nullable<number>,
  retroperiod: Date[] | null,
  territoryType: Nullable<TvTerritoryType>,
  uploadedStatus: Nullable<UploadedStatus>,
): boolean {
  return (
    !!audienceId && retroperiod?.filter(Boolean).length === 2 && isTerritoryTypeFilled(territoryType, uploadedStatus)
  );
}
