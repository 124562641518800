import React, { useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useMount } from 'ahooks';
import { observer } from 'mobx-react';
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';

import { useStores } from 'core';
import { LocalizedTitle } from 'shared/components/other';
import { ControlPanel, AdfoxTable } from './components';
import classes from '../AdFox.module.scss';

const AdFoxCampaigns = observer(() => {
  const {
    adFoxCampaignsStore: { loadData, columns, rows, defaultRows, updateRows, initTable },
  } = useStores();

  useMount(() => {
    loadData();
  });

  const tableRef = useRef<HTMLDivElement>(null);
  const headerTemplate = useCallback(
    (options: PanelHeaderTemplateOptions) =>
      tableRef.current ? ReactDOM.createPortal(options.togglerElement, tableRef.current) : null,
    [tableRef],
  );

  return (
    <div className={classes.root}>
      <LocalizedTitle id="site.title.tradingDesk" />
      <Panel
        className={classes.panel}
        collapseIcon="pi pi-chevron-up"
        expandIcon="pi pi-chevron-down"
        headerTemplate={headerTemplate}
      >
        <ControlPanel />
      </Panel>
      <div className={classes.table} ref={tableRef}>
        <AdfoxTable
          campaigns={rows}
          defaultCampaigns={defaultRows}
          columns={columns}
          onChange={updateRows}
          initTable={initTable}
        />
      </div>
    </div>
  );
});

export { AdFoxCampaigns };
