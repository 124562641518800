import { FieldDescription } from 'types/powerBI';
import { LocaleIdType } from 'locales';
import { localize } from 'shared/components/other';

export const getLevel = (d: FieldDescription): 1 | 2 | 3 => {
  if (d.positionName) return 3;
  if (d.mediaType) return 2;

  return 1;
};

export const getOrderByField = (a: FieldDescription, b: FieldDescription, key: keyof FieldDescription): number => {
  const val1 = a[key] || 0;
  const val2 = b[key] || 0;
  if (val1 > val2) return 1;
  if (val1 < val2) return -1;

  return 0;
};

export const getGroupOrder = (a: FieldDescription, b: FieldDescription): number => {
  const pageOrder = getOrderByField(a, b, 'pageName');
  if (pageOrder !== 0) return pageOrder;
  const mediaOrder = getOrderByField(a, b, 'mediaType');
  if (mediaOrder !== 0) return mediaOrder;

  return getOrderByField(a, b, 'positionName');
};

export const convertLabel = (label: string): string =>
  localize(`powerbi.labels.${label.toLowerCase().replace(/_/g, '-')}` as LocaleIdType) ||
  label.toLowerCase().replace(/_/g, ' ');

export const getRowLabel = (d: FieldDescription): string => {
  const level = getLevel(d);
  if (level === 1) return d.pageName;

  return (level === 2 ? d.mediaType : d.positionName) as string;
};

export function normalizeUrl(url: string): string {
  return url.replace(/\s+/g, '');
}
