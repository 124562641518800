import { GridRow, Row } from './types';

export const defaultProps = {
  isLoading: false,
  isLoadedAll: true,
  selectedRowKeys: new Set(''),
  toggleRowSelection: (): void => {},
  toggleRowExpanding: (): void => {},
  scrollThreshold: 100,
  headerRowHeight: 50,
  filterRowHeight: 0,
  dataRowHeight: 50,
  titleRowHeight: 35,
  rowGap: 0,
  rowKeyGetter: <DR>(row: GridRow<DR> | Row<DR>): (GridRow<DR> | Row<DR>)['rowId'] => {
    return row.rowId;
  },
};
