import React, { useCallback } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { localize, localizeMessage } from 'shared/components/other';
import { DAY_TIMESTAMP } from 'helpers/constants';
import { formatDate, getEndDate, getStartDate } from 'helpers/utils';
import { LocaleIdType } from 'locales';
import { Button } from 'shared/components/common/buttons/Button';
import { Calendar } from 'shared/components/common/form/Calendar';
import { StatusIndicators } from 'shared/components/domain/StatusIndicators';
import { ActionStatus } from 'types';
import classes from './WebMediaplanDataFixation.module.scss';

type Props = {
  className?: string;
  fixationPeriod: Date | null;
  lastFixationLocalizedStatus: string | null;
  lastFixationPeriod: Date | null;
  lastFixationStatus: ActionStatus | null;
  onDateChange(value: Date | null): void;
  onFixate(): void;
  onStatusIndicatorsUpdate(): void;
  startDate: string;
};

export const WebMediaplanDataFixation: React.VFC<Props> = (props) => {
  const {
    className,
    fixationPeriod,
    lastFixationLocalizedStatus,
    lastFixationPeriod,
    lastFixationStatus,
    onDateChange,
    onFixate,
    onStatusIndicatorsUpdate,
    startDate,
  } = props;

  const label = `${localize('campaign-view.mediafact.data-verified-by')}:`;
  const buttonLabel = localize('confirm');
  const isButtonDisabled =
    lastFixationStatus === ActionStatus.IN_PROGRESS || R.equals(fixationPeriod, lastFixationPeriod);
  const isCalendarDisabled = lastFixationStatus === ActionStatus.IN_PROGRESS;
  const minDate = getStartDate(new Date(startDate));
  const maxDate = getEndDate(new Date(Date.now() - DAY_TIMESTAMP));
  const tooltip = lastFixationLocalizedStatus
    ? localizeMessage(
        { id: `campaign-view.mediafact.statuses.${lastFixationLocalizedStatus}` as LocaleIdType },
        { date: lastFixationPeriod ? formatDate({ date: lastFixationPeriod, isFullYear: true }) : '' },
      )
    : undefined;

  const handleDateChange = useCallback(
    (value: Date | Date[] | null) => {
      if (!Array.isArray(value)) {
        onDateChange(value);
      }
    },
    [onDateChange],
  );

  return (
    <div className={cx(classes.root, className)}>
      <label className={classes.label}>{label}</label>
      <Calendar
        disabled={isCalendarDisabled}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDateChange}
        value={fixationPeriod}
      />
      <StatusIndicators status={lastFixationStatus} tooltip={tooltip} onUpdate={onStatusIndicatorsUpdate} />
      <Button disabled={isButtonDisabled} onClick={onFixate} label={buttonLabel} />
    </div>
  );
};

export type { Props as WebMediaplanDataFixationProps };
