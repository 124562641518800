import { ServerModels as SM } from 'types/server';
import { queries } from './queries';
import { QueryResult as Result } from '../../types';
import { getClient } from '../../../client';

type Api = {
  campaigns(variables?: SM.AdFoxCampaignsQueryVariables): Result<SM.AdFoxCampaignsQuery>;
  banners(variables?: SM.AdFoxBannersQueryVariables): Result<SM.AdFoxBannersQuery>;
  summary(variables?: SM.AdFoxSummaryQueryVariables): Result<SM.AdFoxSummaryQuery>;
  log: {
    campaigns(variables?: SM.AdFoxCampaignLogQueryVariables): Result<SM.AdFoxCampaignLogQuery>;
    banners(variables?: SM.AdFoxBannerLogQueryVariables): Result<SM.AdFoxBannerLogQuery>;
  };
};

export const adfox: Api = {
  campaigns: (variables) => getClient().query({ query: queries.AdFoxCampaigns, variables }),
  banners: (variables) => getClient().query({ query: queries.AdFoxBanners, variables }),
  summary: (variables) => getClient().query({ query: queries.AdFoxSummary, variables }),
  log: {
    campaigns: (variables) => getClient().query({ query: queries.AdFoxCampaignLog, variables }),
    banners: (variables) => getClient().query({ query: queries.AdFoxBannerLog, variables }),
  },
};
