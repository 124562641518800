import { ServerModels as SM } from 'types/server';
import { CabinetStatus, DataRow } from 'types/programmatic/excess';
import { roundCurrency } from '../helpers';

type Args = {
  row: SM.ExcessCabinetPosition;
  getExcessCauseById: (id: number) => string | null;
};

export function convertExcessCabinetPositionToDataRow({ row, getExcessCauseById }: Args): DataRow {
  return {
    id: String(row.positionId),
    active: null,
    total: null,
    positionId: row.positionId && row.positionTypeId ? { value: row.positionId, type: row.positionTypeId } : null,
    checkStatus: row.checkStatus ?? null,
    excessCauses: row.causeIdList?.map(getExcessCauseById).filter(Boolean) as string[],
    cabinetStatus:
      row.changeCabinetStatusState === 'IN_PROGRESS' ? CabinetStatus.IN_PROGRESS : row.cabinetStatus ?? null,
    startDate: null,
    endDate: null,
    planBudget: null,
    factBudget: roundCurrency(row.factBudget ?? null),
    factBudgetLimit: roundCurrency(row.factBudgetLimit ?? null),
    budgetPct: null,
    budgetPctToday: null,
    unitPrice: row.unitPrice ?? null,
    factUnitPrice: row.factUnitPrice ?? null,
    unitPricePct: row.unitPricePct ?? null,
    sellerOrStatisticsSource: row.statisticsSource ?? null,
    format: null,
    settingsStatus: null,
  };
}
