import React from 'react';
import classes from './ControlPanel.module.scss';
import { Actions, Filters, Summary } from './components';

export const ControlPanel: React.VFC = () => {
  return (
    <div className={classes.root}>
      <div className={classes.summary}>
        <Summary />
      </div>
      <div className={classes.filters}>
        <Filters />
      </div>
      <div className={classes.actions}>
        <Actions />
      </div>
    </div>
  );
};
