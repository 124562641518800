import cx from 'classnames';
import { useStores } from 'core';
import { observer } from 'mobx-react';
import { Dialog } from 'primereact/dialog';
import React from 'react';

import { Button } from 'shared/components/common/buttons/Button';
import { LocalizedMessage } from 'shared/components/other';

import { Columns } from './components/Columns';
import { Filters } from './components/Filters';
import classes from './ParamsModal.module.scss';

type Props = {
  onApply(): void;
  onHide?(): void;
};

export const ParamsModal: React.FC<Props> = observer(({ onApply, onHide }) => {
  const { reportConstructorStore } = useStores();
  const {
    columns,
    isFirstStepValid,
    isSecondStepValid,
    modalState,
    mode,
    setModalState,
    loadColumns,
  } = reportConstructorStore;

  const handleHide = () => {
    setModalState(null);
    onHide?.();
  };

  const handlePrevButtonClick = () => {
    setModalState('step1');
  };

  const handleNextButtonClick = () => {
    setModalState('step2');
    if (columns.attributes.length === 0 && columns.metrics.length === 0) {
      loadColumns();
    }
  };

  return (
    <Dialog
      className={cx(classes.root, { [classes.rootFirstStep]: modalState === 'step1' })}
      visible={modalState !== null}
      draggable={false}
      closable={false}
      footer={
        <div className={classes.footer}>
          <Button theme="light" onClick={handleHide} data-test="dialog-cancel-button">
            <LocalizedMessage id="button.cancel" />
          </Button>
          {modalState === 'step1' && (
            <Button
              disabled={mode === 'view' ? false : !isFirstStepValid}
              onClick={handleNextButtonClick}
              data-test="dialog-next-button"
            >
              <LocalizedMessage id="button.next" />
            </Button>
          )}
          {modalState === 'step2' && (
            <Button onClick={handlePrevButtonClick} data-test="dialog-prev-button">
              <LocalizedMessage id="button.prev" />
            </Button>
          )}
          {modalState === 'step2' && mode !== 'view' && (
            <Button disabled={!isSecondStepValid} onClick={onApply} data-test="dialog-apply-button">
              <LocalizedMessage id="report-constructor.modal.open-constructor" />
            </Button>
          )}
        </div>
      }
      onHide={handleHide}
    >
      <div className={classes.header}>
        <LocalizedMessage id="report-constructor.modal.report-parameters" />
      </div>
      {modalState === 'step1' ? <Filters /> : <Columns />}
    </Dialog>
  );
});
