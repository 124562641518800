import { convertItemsToOptions, convertSettingsMetadata } from 'api/helpers/converters';
import { convertFileToFormData } from 'api/helpers/converters/convertFileToFormData';
import { RequestConfig } from 'api/types';
import { SortingOptions } from 'types';
import {
  AccountItem,
  Item,
  LogFilters,
  LogItem,
  LogPageSettings,
  MediaData,
  OptionData,
  SettingsMetadata,
  TemplateData,
} from 'types/cabinetCopying';

import BaseApi from '../BaseApi';

class CabinetCopying extends BaseApi {
  loadMetadata = async (cabinetId: number, config: RequestConfig = {}) => {
    const response = await this.actions.get<SettingsMetadata>({
      domainType: 'webApi',
      url: '/account-copying/cabinet/metadata',
      data: { cabinetId },
      config,
    });

    return this.convertData(response, convertSettingsMetadata);
  };

  loadCabinets = async (_: OptionData, config: RequestConfig = {}) => {
    const response = await this.actions.post<Item[]>({
      url: '/account/account-copying/cabinets',
      data: null,
      config,
    });

    return this.convertData(response, convertItemsToOptions);
  };

  loadAccounts = async ({ cabinet }: OptionData, config: RequestConfig = {}) => {
    const response = await this.actions.post<AccountItem[]>({
      url: `/account/cabinet/accounts?cabinetId=${cabinet}`,
      data: null,
      config,
    });

    return this.convertData(response, convertItemsToOptions);
  };

  loadClients = async ({ cabinet, account }: OptionData, config: RequestConfig = {}) => {
    const response = await this.actions.post<Item[]>({
      url: `/account/cabinet/clients?cabinetId=${cabinet}${account ? `&accountId=${account}` : ''}`,
      data: null,
      config,
    });

    return this.convertData(response, convertItemsToOptions);
  };

  loadCampaigns = async ({ cabinet, account, client }: OptionData, config: RequestConfig = {}) => {
    const response = await this.actions.get<Item[]>({
      domainType: 'webApi',
      url: '/account-copying/campaign',
      data: { cabinetId: cabinet, accountId: account, clientId: client },
      config,
    });

    return this.convertData(response, convertItemsToOptions);
  };

  loadAdGroups = async ({ cabinet, account, client, campaign }: OptionData, config: RequestConfig = {}) => {
    const response = await this.actions.get<Item[]>({
      domainType: 'webApi',
      url: '/account-copying/groups',
      data: { cabinetId: cabinet, accountId: account, clientId: client, campaignId: campaign },
      config,
    });

    return this.convertData(response, convertItemsToOptions);
  };

  loadAds = async ({ cabinet, account, client, campaign, adGroup }: OptionData, config: RequestConfig = {}) => {
    const response = await this.actions.get<Item[]>({
      domainType: 'webApi',
      url: '/account-copying/ads',
      data: { cabinetId: cabinet, accountId: account, clientId: client, campaignId: campaign, groupId: adGroup },
      config,
    });

    return this.convertData(response, convertItemsToOptions);
  };

  loadTemplateData = async (config: RequestConfig = {}) =>
    this.actions.get<TemplateData[]>({
      domainType: 'webApi',
      url: '/account-copying/xlsx/template/all',
      config,
    });

  uploadExcel = async (
    file: File,
    data: Record<string, number | string | MediaData | undefined>,
    config: RequestConfig = {},
  ) => {
    const formData = convertFileToFormData(file);
    formData.append('params', JSON.stringify(data));

    return this.actions.post({
      domainType: 'webApi',
      url: '/account-copying/load-excel-and-start-processing',
      data: formData,
      config,
    });
  };

  uploadPhoto = async (file: File, adFormat: number | undefined, config: RequestConfig = {}) =>
    this.actions.post<{ url: string }>({
      url: `/vk-video/upload-photo${adFormat ? `?adFormat=${adFormat}` : ''}`,
      data: convertFileToFormData(file),
      config,
    });

  uploadVideo = async (file: File, config: RequestConfig = {}) =>
    this.actions.post<{ url: string }>({
      url: '/vk-video/upload-video',
      data: convertFileToFormData(file),
      config,
    });

  uploadLogo = async (file: File, config: RequestConfig = {}) =>
    this.actions.post<{ url: string }>({
      url: '/vk-video/upload-logo',
      data: convertFileToFormData(file),
      config,
    });

  logEntries = async (data: LogEntriesData, config: RequestConfig = {}) =>
    this.actions.post<LogEntriesResponse>({
      domainType: 'webApi',
      url: '/account-copying/log/log-entries',
      data,
      config,
    });
}

type LogEntriesData = {
  sorting: SortingOptions[];
  page: LogPageSettings;
  filters?: LogFilters;
};

type LogEntriesResponse = {
  data: LogItem[];
  total: number;
  totalPages: number;
};

export default CabinetCopying;
