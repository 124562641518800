import React from 'react';
import cx from 'classnames';
import { Checkbox, CheckboxProps, CheckboxChangeParams, CheckboxChangeTargetOptions } from 'primereact/checkbox';
import { CampaignFiltersType } from '../../CampaignFilters';
import classes from './CheckboxLabel.module.scss';

type Props = Omit<CheckboxProps, 'name'> & {
  label: string;
  name?: keyof CampaignFiltersType;
  'data-test'?: string;
};

type CheckboxLabelChangeParams = Omit<CheckboxChangeParams, 'target'> & {
  target: Omit<CheckboxChangeTargetOptions, 'name'> & {
    name: keyof CampaignFiltersType;
  };
};

export const CheckboxLabel: React.VFC<Props> = (props) => {
  const { className, label, 'data-test': testId, ...checkboxProps } = props;
  return (
    <label className={cx(classes.root, className)} data-test={testId}>
      <Checkbox tabIndex={0} {...checkboxProps} />
      <span>{label}</span>
    </label>
  );
};

export type { Props as CheckboxLabelProps, CheckboxLabelChangeParams };
