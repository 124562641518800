import { CustomOptions, PageData } from 'api/types';
import { IPagingServerResponse } from 'types';
import { IMediaType } from 'types/mediaType';
import BaseApi from '../BaseApi';

class MediaTypes extends BaseApi {
  list = ({ pageSize }: PageData = {}, config: CustomOptions = {}) =>
    this.actions.get<IPagingServerResponse<IMediaType[]>>({
      url: '/metric-media-type/',
      data: { pageSize },
      config,
    });
}

export default MediaTypes;
