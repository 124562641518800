/* eslint-disable @typescript-eslint/lines-between-class-members */
import HttpActions from './HttpActions';
import {
  Accounts,
  Advertisers,
  Agencies,
  Auth,
  Brands,
  Campaigns,
  Categories,
  Mediafact,
  MediaTypes,
  Powerbi,
  Programmatic,
  ReportConstructor,
  Subbrands,
  TargetAudiences,
  TvMediaplans,
  WebMediaplans,
  Creatives,
  Dictionaries,
  TradingDesk,
  CabinetCopying,
  File,
} from './release';
import { Interceptors } from './types';

class Api {
  accounts: Accounts;
  advertisers: Advertisers;
  agencies: Agencies;
  auth: Auth;
  brands: Brands;
  campaigns: Campaigns;
  categories: Categories;
  mediafact: Mediafact;
  mediaTypes: MediaTypes;
  powerbi: Powerbi;
  programmatic: Programmatic;
  reportConstructor: ReportConstructor;
  subbrands: Subbrands;
  targetAudiences: TargetAudiences;
  tvMediaplans: TvMediaplans;
  webMediaplans: WebMediaplans;
  creatives: Creatives;
  dictionaries: Dictionaries;
  tradingDesk: TradingDesk;
  cabinetCopying: CabinetCopying;
  file: File;

  static instance: Api | null = null;

  static getInstance(): Api {
    if (!Api.instance) {
      const instance = new Api();
      Api.instance = instance;
    }

    return Api.instance;
  }

  private _httpActions: HttpActions | null = null;

  constructor() {
    this._httpActions = new HttpActions();
    this.accounts = new Accounts(this._httpActions);
    this.advertisers = new Advertisers(this._httpActions);
    this.agencies = new Agencies(this._httpActions);
    this.auth = new Auth(this._httpActions);
    this.brands = new Brands(this._httpActions);
    this.campaigns = new Campaigns(this._httpActions);
    this.categories = new Categories(this._httpActions);
    this.mediafact = new Mediafact(this._httpActions);
    this.mediaTypes = new MediaTypes(this._httpActions);
    this.powerbi = new Powerbi(this._httpActions);
    this.programmatic = new Programmatic(this._httpActions);
    this.reportConstructor = new ReportConstructor(this._httpActions);
    this.subbrands = new Subbrands(this._httpActions);
    this.targetAudiences = new TargetAudiences(this._httpActions);
    this.tvMediaplans = new TvMediaplans(this._httpActions);
    this.webMediaplans = new WebMediaplans(this._httpActions);
    this.creatives = new Creatives(this._httpActions);
    this.dictionaries = new Dictionaries(this._httpActions);
    this.tradingDesk = new TradingDesk(this._httpActions);
    this.cabinetCopying = new CabinetCopying(this._httpActions);
    this.file = new File(this._httpActions);
  }

  setErrorInterceptors = (interceptors: Interceptors): void => {
    if (this._httpActions) {
      this._httpActions.interceptors = interceptors;
    }
  };
}

export default Api;
