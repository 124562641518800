import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'core';
import { SelectButton, SelectButtonChangeParams } from 'primereact/selectbutton';
import { ServerModels as SM } from 'types/server';
import { confirmFilters } from '../../../../helpers';
import { cabinetStatusOptions, checkStatusOptions } from '../../../../helpers/selectionOptions';
import { makeCabinetStatusButtonTemplate, makeCheckStatusButtonTemplate } from './Summary.helpers';
import classes from './Summary.module.scss';

export const Summary = observer(() => {
  const {
    excessStore: {
      campaignFilters: { cabinetStatus, checkStatus },
      campaignSummary: summary,
      hasSelectedRows,
      setCampaignFilters,
    },
  } = useStores();

  const activeCabinetStatus = useMemo(() => cabinetStatusOptions.find((x) => cabinetStatus === x.value), [
    cabinetStatus,
  ]);

  const activeCheckStatus = useMemo(() => checkStatusOptions.find((x) => checkStatus === x.value), [checkStatus]);

  const applyFilters = (value: SM.CampaignFilters, target: HTMLElement) => {
    if (hasSelectedRows) {
      confirmFilters({
        target,
        onAccept: () => setCampaignFilters(value),
      });
    } else {
      setCampaignFilters(value);
    }
  };

  const handleCabinetStatusChange = (e: SelectButtonChangeParams) => {
    applyFilters({ cabinetStatus: e.value }, e.originalEvent.currentTarget as HTMLElement);
  };

  const handleCheckStatusChange = (e: SelectButtonChangeParams) => {
    applyFilters({ checkStatus: e.value }, e.originalEvent.currentTarget as HTMLElement);
  };

  const cabinetStatusSelectButtonItemTemplate = useMemo(() => makeCabinetStatusButtonTemplate(summary), [summary]);
  const checkStatusSelectButtonItemTemplate = useMemo(() => makeCheckStatusButtonTemplate(summary), [summary]);

  return (
    <div className={classes.root}>
      <SelectButton
        className={classes.selectionButton}
        value={activeCabinetStatus?.value}
        options={cabinetStatusOptions}
        onChange={handleCabinetStatusChange}
        itemTemplate={cabinetStatusSelectButtonItemTemplate}
      />
      <SelectButton
        className={classes.selectionButton}
        value={activeCheckStatus?.value}
        options={checkStatusOptions}
        onChange={handleCheckStatusChange}
        itemTemplate={checkStatusSelectButtonItemTemplate}
      />
    </div>
  );
});
