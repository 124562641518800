import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import { LocaleIdType } from 'locales';
import { formatDate } from 'helpers/utils';
import { useDidMount } from 'helpers/hooks';
import { ActionStatus } from 'types';
import { IStatisticFile, Status } from 'types/mediafact';
import { LocalizedMessage, LocalizedTitle } from 'shared/components/other';
import { FileUploader } from 'shared/components/common/form/FileUploader';
import { Button } from 'shared/components/common/buttons/Button';
import { Footer } from 'shared/components/layout/Footer';
import { StatusIndicators } from 'shared/components/domain/StatusIndicators';
import { useStores } from 'core';
import Filters from './components/Filters';

import classes from './UploadStatistics.module.scss';

const statusToActionStatusMap = {
  [Status.LOADED]: ActionStatus.SUCCESS,
  [Status.START_PROCESS]: ActionStatus.IN_PROGRESS,
  [Status.ERROR]: ActionStatus.ERROR,
};

export const UploadStatistics = observer(() => {
  const { uploadStatisticsStore: store } = useStores();

  useDidMount(() => {
    store.loadData();
  });

  useEffect(() => {
    return store.cleanUp;
  }, [store.cleanUp]);

  const {
    cabinetOptions,
    filters,
    filteredFiles,
    fileProps,
    sortSettings: { sortBy, orderIsAsc },
  } = store;
  const {
    handleFileUpload,
    handleFiltersChange,
    handleSortButtonClick,
    handleDownloadTemplateButtonClick,
    handleDownloadFileButtonClick,
    handleDeleteFileButtonClick,
  } = store.childrenStores.view;

  return (
    <div className={classes.UploadStatistics}>
      <LocalizedTitle id="site.title.upload-statistics" />

      <section className={classes.Uploader}>
        <LocalizedMessage id="upload-statistics.upload-new-file">
          {(localizedLabel: string) => (
            <FileUploader label={localizedLabel} onUpload={handleFileUpload} onCancelUpload={null} />
          )}
        </LocalizedMessage>
      </section>

      <section className={classes.Files}>
        <div className={classes.FiltersColumn}>
          <Filters cabinetOptions={cabinetOptions} filters={filters} onChange={handleFiltersChange} />
        </div>
        <div className={classes.FilesColumn}>
          <header className={classes.FilesHeader}>
            <h3 className={classes.FilesHead}>
              <LocalizedMessage id="upload-statistics.uploaded-files" />
            </h3>
            <Button size="small" data-test="download-template" onClick={handleDownloadTemplateButtonClick}>
              <LocalizedMessage id="upload-statistics.actions.download-template" />
            </Button>
          </header>
          <table className={classes.FilesTable}>
            <thead>
              <tr onClick={handleSortButtonClick}>
                {fileProps.map(({ name, isSortable }) => (
                  <th
                    key={name}
                    className={cx(classes.TableHeader, {
                      [classes.TableHeaderActive]: sortBy === name,
                    })}
                    data-sort={isSortable ? name : undefined}
                  >
                    <span>
                      <LocalizedMessage id={`upload-statistics.table.${name}` as LocaleIdType} />
                    </span>
                    {isSortable && (
                      <i
                        className={cx(classes.SortingIcon, 'icon icon-arrow-down', {
                          [classes.SortingIconReverse]: sortBy === name && orderIsAsc,
                        })}
                      />
                    )}
                  </th>
                ))}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {filteredFiles.map(
                ({
                  statisticsSource,
                  fileName,
                  fileS3Id,
                  timeCreate,
                  userCreateName,
                  mediafactManualFileId,
                  status,
                }: IStatisticFile) => (
                  <tr key={mediafactManualFileId} className={classes.FilesTableRow}>
                    <td>{statisticsSource}</td>
                    <td>{fileName}</td>
                    <td>
                      {formatDate({
                        date: timeCreate,
                        isFullYear: true,
                        hasTime: true,
                      })}
                    </td>
                    <td>{userCreateName}</td>
                    <td>
                      <LocalizedMessage
                        id={[
                          'upload-statistics.file-statuses.loaded',
                          'upload-statistics.file-statuses.in-progress',
                          'upload-statistics.file-statuses.error',
                        ]}
                      >
                        {(loadedMsg: string, inProgressMsg: string, errorMsg: string) => {
                          const statusToTooltipMap = {
                            [Status.LOADED]: loadedMsg,
                            [Status.START_PROCESS]: inProgressMsg,
                            [Status.ERROR]: errorMsg,
                          };

                          return (
                            <StatusIndicators
                              className={classes.StatusIndicators}
                              status={status && statusToActionStatusMap[status]}
                              tooltip={(status && statusToTooltipMap[status]) || undefined}
                            />
                          );
                        }}
                      </LocalizedMessage>
                    </td>
                    <td>
                      <LocalizedMessage
                        id={['upload-statistics.actions.download-file', 'upload-statistics.actions.delete-file']}
                      >
                        {(localizedDownload: string, localizedDelete: string) => (
                          <div className={classes.FileActions}>
                            <i
                              title={localizedDownload}
                              className={cx('icon icon-download', classes.FileAction, classes.FileActionDownload)}
                              data-test={`download-file-${mediafactManualFileId}`}
                              onClick={() => handleDownloadFileButtonClick(fileS3Id)}
                            />
                            <i
                              title={localizedDelete}
                              className={cx(classes.FileAction, 'icon icon-cross')}
                              data-test={`delete-file-${mediafactManualFileId}`}
                              onClick={() => handleDeleteFileButtonClick(mediafactManualFileId)}
                            />
                          </div>
                        )}
                      </LocalizedMessage>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      </section>

      <Footer />
    </div>
  );
});
