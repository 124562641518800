import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import classes from './AdFoxLog.module.scss';
import { Filters, LogTable } from './components';

type Props = {
  campaignId?: number;
};

export const AdFoxLog: React.VFC<Props> = observer(({ campaignId }) => {
  const { adFoxLogStore } = useStores();

  useEffect(() => {
    runInAction(() => {
      adFoxLogStore.reset();
      if (campaignId) {
        adFoxLogStore.setCampaignId(campaignId);
      }
    });
  }, [adFoxLogStore, campaignId]);

  return (
    <div className={classes.root}>
      <Filters />
      <LogTable />
    </div>
  );
});
