import * as R from 'ramda';
import { action, makeObservable, observable, runInAction, reaction, computed } from 'mobx';

import { PaginationStore } from 'shared/stores';
import { Row, DataGridFilters } from 'shared/components/other';
import { ActiveTab } from 'types/programmatic';
import { ServerModels as SM } from 'types/server';
import { ExcessStore } from './Excess.store';
import { convertLogData, convertGridFiltersToApiFilters, mergeFilters } from '../helpers';

type Deps = {
  excessStore: ExcessStore;
};

type InvalidationScope = [number, SM.LogEntriesFilters, SM.SortingField | SM.SortingField[], boolean, boolean];

export class ExcessLogStore extends PaginationStore<Row<SM.OperationLogEntry>[], Deps> {
  protected _data: Row<SM.OperationLogEntry>[] = [];

  pageSize = 20;

  @observable
  isExcessCampaignFiltersEnabled = this.hasExcessCampaignFilters;

  @observable
  isExcessSelectedRowsFiltersEnabled = this.hasExcessSelectedRowsFilters;

  @observable
  defaultSorting: SM.SortingField | SM.SortingField[] = [];

  @observable
  private _dataGridFilters: DataGridFilters = {};

  @observable
  private _totalItems = 0;

  @observable
  private _filters: SM.LogEntriesFilters = {};

  @observable
  private sorting: SM.SortingField | SM.SortingField[] = this.defaultSorting;

  private needToLoadData = true;

  protected invalidationScope = (): InvalidationScope => [
    this.page,
    this.filters,
    this.sorting,
    this.isExcessCampaignFiltersEnabled,
    this.isExcessSelectedRowsFiltersEnabled,
  ];

  constructor(deps: Deps) {
    super(deps);
    makeObservable(this);

    this.setup();
    reaction(() => this.deps.excessStore.activeTab, this.init);
  }

  get filters(): SM.LogEntriesFilters {
    return this._filters;
  }

  get dataGridFilters(): DataGridFilters {
    return this._dataGridFilters;
  }

  get totalItems(): number {
    return this._totalItems;
  }

  @computed
  get filtersKeys(): string[] {
    return Object.keys(this.filters);
  }

  @computed
  get hasFilters(): boolean {
    return this.filtersKeys.length > 0;
  }

  @computed
  get hasExcessCampaignFilters(): boolean {
    return this.deps.excessStore.hasCampaignFilters;
  }

  @computed
  get hasExcessSelectedRowsFilters(): boolean {
    return Object.keys(this.deps.excessStore.selectedRowsFilters).length > 0;
  }

  @action
  setFilters = (dataGridFilters: DataGridFilters): void => {
    this._filters = mergeFilters(this.filters, convertGridFiltersToApiFilters(dataGridFilters));
    this._dataGridFilters = dataGridFilters;
  };

  @action
  clearAllFilters = (): void => {
    this._filters = {};
    this._dataGridFilters = {};
  };

  @action
  setSorting = (sortingOptions: SM.SortingField | SM.SortingField[] | null): void => {
    this.sorting = sortingOptions ?? [];
  };

  @action
  toggleExcessCampaignFilters = (): void => {
    this.isExcessCampaignFiltersEnabled = !this.isExcessCampaignFiltersEnabled;
  };

  @action
  toggleExcessSelectedRowsFilters = (): void => {
    this.isExcessSelectedRowsFiltersEnabled = !this.isExcessSelectedRowsFiltersEnabled;
  };

  @computed
  private get logEntriesExcessFilters(): SM.LogEntriesExcessFilters {
    const campaignFilters: SM.CampaignFilters = this.isExcessCampaignFiltersEnabled
      ? this.deps.excessStore.campaignFilters
      : {};
    const selectedRowsFilters = this.isExcessSelectedRowsFiltersEnabled
      ? this.deps.excessStore.selectedRowsFilters
      : {};

    return R.mergeAll([
      R.omit(['cabinetStatus', 'csId'], campaignFilters),
      campaignFilters.csId ? { csId: { operator: SM.FilterOperator.Like, value: [campaignFilters.csId] } } : {},
      selectedRowsFilters,
    ]);
  }

  private loadData = async (): Promise<void> => {
    const response = await this.services.api.programmatic.excess.logEntries({
      filters: this.filters,
      filtersExcessData: this.logEntriesExcessFilters,
      page: { number: this.page, size: this.pageSize },
      sorting: this.sorting,
    });
    if (response.data?.excessLogEntries?.data) {
      const { data, totalPages, total } = response.data.excessLogEntries;
      runInAction(() => {
        this._data = convertLogData(data);
        this._totalPages = totalPages;
        this._totalItems = total;
      });
    }
  };

  private init = (): void => {
    const { activeTab } = this.deps.excessStore;
    if (activeTab === ActiveTab.LOG && this.needToLoadData) {
      this.setInitialValues();
      this.needToLoadData = false;
    } else if (activeTab === null) {
      this.needToLoadData = true;
    }
  };

  @action
  private setInitialValues = (): void => {
    this._data = [];
    this.page = 0;
    this._filters = {};
    this._dataGridFilters = {};
    this.sorting = this.defaultSorting;
    this.isExcessCampaignFiltersEnabled = this.hasExcessCampaignFilters;
    this.isExcessSelectedRowsFiltersEnabled = this.hasExcessSelectedRowsFilters;
  };

  protected revalidate = async ([page]: InvalidationScope, [prevPage]: InvalidationScope): Promise<void> => {
    if (page !== prevPage || prevPage === 0) {
      await this.loadData();
    } else {
      runInAction(() => {
        this.page = 0;
      });
    }
  };
}
