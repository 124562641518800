import cx from 'classnames';
import { endOfToday } from 'date-fns';
import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'core';
import { ClearButton } from 'shared/components/common/buttons/ClearButton';
import { SearchInput } from 'shared/components/common/form/SearchInput';
import { localize } from 'shared/components/other';
import { Calendar } from 'shared/components/common/form/Calendar';
import { ReportListFilters } from 'types/reportConstructor';
import classes from './Filters.module.scss';

type Props = {
  className?: string;
};

export const Filters: React.FC<Props> = observer(({ className }) => {
  const {
    reportListStore: { filters, updateFilters, clearFilters },
  } = useStores();

  const makeUpdateTextFilter = (fieldName: Exclude<keyof ReportListFilters, 'createdDateStart' | 'createdDateEnd'>) => (
    value: string | null,
  ) => updateFilters({ [fieldName]: value ?? undefined });

  const makeUpdateDateFilter = (fieldName: 'createdDateStart' | 'createdDateEnd') => (date: Date | null) =>
    updateFilters({ [fieldName]: date ?? undefined });

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.filters}>
        <Calendar
          className={classes.calendar}
          theme="light"
          value={filters.createdDateStart ?? null}
          placeholder={localize('report-list.fields.createdDate.start')}
          onChange={makeUpdateDateFilter('createdDateStart')}
          maxDate={filters.createdDateEnd ?? undefined}
        />
        <Calendar
          className={classes.calendar}
          theme="light"
          value={filters.createdDateEnd ?? null}
          placeholder={localize('report-list.fields.createdDate.finish')}
          onChange={makeUpdateDateFilter('createdDateEnd')}
          minDate={filters.createdDateStart ?? undefined}
          maxDate={endOfToday()}
        />
        <ClearButton
          className={classes.clearButton}
          disabled={Object.keys(filters).length === 0}
          onClick={clearFilters}
        />
        <SearchInput
          className={classes.input}
          defaultValue={filters.name}
          placeholder={localize('report-list.fields.name')}
          onChange={makeUpdateTextFilter('name')}
          onClear={() => makeUpdateTextFilter('name')(null)}
        />
        <SearchInput
          className={classes.input}
          defaultValue={filters.userCreated}
          placeholder={localize('report-list.fields.userCreated')}
          onChange={makeUpdateTextFilter('userCreated')}
          onClear={() => makeUpdateTextFilter('userCreated')(null)}
        />
        <SearchInput
          className={classes.input}
          defaultValue={filters.userModified}
          placeholder={localize('report-list.fields.userModified')}
          onChange={makeUpdateTextFilter('userModified')}
          onClear={() => makeUpdateTextFilter('userModified')(null)}
        />
      </div>
    </div>
  );
});
