import { action, makeObservable, observable, reaction, runInAction } from 'mobx';
import { ParsedQuery } from 'query-string';

import { EmptyObject } from 'types';
import { IBrand, IBrandMediaTypeBudget } from 'types/brand';
import { ICampaign, IYearPeriod } from 'types/campaign';
import { BaseStore } from 'shared/stores';
import { BrandViewViewStore } from './BrandViewView.store';

const currentYear = new Date().getFullYear();

type ChildrenStores = {
  view: BrandViewViewStore;
};

export class BrandViewStore extends BaseStore<EmptyObject, ChildrenStores> {
  @observable
  search?: ParsedQuery = undefined;

  @observable
  brandId: number | null = null;

  @observable
  activeYear: number = currentYear;

  @observable
  brands: IBrand[] = [];

  @observable
  brandMediaTypeBudgets: IBrandMediaTypeBudget[] | null = null;

  @observable
  brandCampaigns: ICampaign[] = [];

  @observable
  yearPeriod: IYearPeriod = { minYear: currentYear, maxYear: currentYear };

  @observable
  private _brand: IBrand | null = null;

  constructor() {
    super();
    makeObservable(this);

    this.childrenStores = {
      view: new BrandViewViewStore({ brandViewStore: this }),
    };

    reaction(() => this.search?.year, this.loadData);
    reaction(
      () => this.brandId,
      () => {
        this.loadData();
        if (!this.brandId) return;
        this.loadBrand(this.brandId);
      },
    );
    reaction(
      () => [this.deps.advertiserStore.currentAdvertiserId],
      async () => {
        if (!this.deps.advertiserStore.currentAdvertiserId || !this._brand) return;
        if (!this._brand.advertisers.includes(this.deps.advertiserStore.currentAdvertiserId)) {
          this.deps.routingStore.history.push('/brands');
          return;
        }
        this.loadBrandList();
        this.loadData();
      },
    );
  }

  @action
  cleanUp = (): void => {
    this.search = undefined;
    this.brandId = null;
    this._brand = null;
    this.activeYear = currentYear;
    this.brands = [];
    this.brandMediaTypeBudgets = null;
    this.yearPeriod = { minYear: currentYear, maxYear: currentYear };
    this.brandCampaigns = [];
  };

  async initialLoad(): Promise<void> {
    if (!this.brandId) return;
    const hasAccess = await this.hasAccessToBrand(this.brandId);
    if (hasAccess) {
      await Promise.all([this.loadBrandList(), this.loadData()]);
      this.maybeChangeAdvertiserToSuitable();
    } else {
      this.deps.routingStore.history.push('/brands');
    }
  }

  hasAccessToBrand = async (brandId: number): Promise<boolean> => {
    await this.loadBrand(brandId);
    return !!this._brand?.advertisers.some((id) => this.deps.advertiserStore.hasAdvertiser(id));
  };

  maybeChangeAdvertiserToSuitable = (): void => {
    const { currentAdvertiserId, advertisers, selectAdvertiser } = this.deps.advertiserStore;
    if (!this._brand || !currentAdvertiserId) return;
    if (!this._brand.advertisers.includes(currentAdvertiserId)) {
      const foundAdvertiser = advertisers.find((x) => x.id === this._brand?.advertisers[0]);
      if (!foundAdvertiser) return;
      selectAdvertiser(foundAdvertiser.id, true);
    }
  };

  @action
  private loadData = async (): Promise<void> => {
    const { brandId } = this;
    const advertiserId = this.deps.advertiserStore.currentAdvertiserId;

    if (advertiserId === null || brandId === null) {
      return;
    }

    const searchParams = this.search;
    const activeYear =
      searchParams !== undefined && searchParams.year !== undefined ? Number(searchParams.year) : currentYear;
    const API = this.services.api;
    const [brandMediaTypeBudgets, brandCampaigns, yearPeriod] = await Promise.all([
      API.brands.getMediaTypeBudgets({ brandId, year: activeYear }),
      API.brands.getBrandCampaigns({ customerId: advertiserId, brandId, year: activeYear }),
      API.campaigns.getYearPeriod({ customerId: advertiserId }),
    ]);
    if (brandMediaTypeBudgets.error || brandCampaigns.error || yearPeriod.error) {
      return;
    }

    runInAction(() => {
      this.activeYear = activeYear;
      this.brandMediaTypeBudgets = brandMediaTypeBudgets.data;
      this.yearPeriod = yearPeriod.data;
      this.brandCampaigns = brandCampaigns.data;
    });
  };

  private loadBrandList = async (): Promise<void> => {
    const advertiserId = this.deps.advertiserStore.currentAdvertiserId;
    if (advertiserId === null) return;
    const response = await this.services.api.brands.list({ customerId: advertiserId });
    if (response.error) return;
    runInAction(() => {
      this.brands = response.data.content;
    });
  };

  private loadBrand = async (brandId: number): Promise<void> => {
    const response = await this.services.api.brands.get({ brandId });
    runInAction(() => {
      this._brand = response.error ? null : response.data;
    });
  };
}
