import { useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';

const DEBOUNCE_RESIZE_WINDOW_TIMEOUT = 100;

type UseResize = (resolution: number, debounce?: number) => [boolean];

export const useResize: UseResize = (resolution, debounce = DEBOUNCE_RESIZE_WINDOW_TIMEOUT) => {
  const [isBigger, setFlag] = useState(window.innerWidth > resolution);
  const [canUpdate, startWaiting] = useDebounce(debounce);

  useEffect(() => {
    const handleResize = () => {
      if (!canUpdate) return;
      startWaiting();

      const isBig = window.innerWidth > resolution;
      if (isBig === isBigger) return;

      setFlag(isBig);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBigger]);

  return [isBigger];
};
