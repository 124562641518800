import { BadgeSeverityType } from 'primereact/badge';
import { CheckStatus } from 'types/programmatic';
import { ServerModels as SM } from 'types/server';

export const cabinetStatusOptions = [
  {
    label: 'Всего кампаний',
    value: 'all',
    disabled: true,
  },
  {
    label: 'Запущено',
    value: SM.CabinetStatus.Started,
  },
  {
    label: 'Остановлено',
    value: SM.CabinetStatus.Stopped,
  },
];

export const checkStatusOptions: StatusOption[] = [
  {
    label: 'OK',
    value: SM.CheckStatus.Ok,
    id: CheckStatus.OK,
    badge: 'success',
  },
  {
    label: 'Warning',
    value: SM.CheckStatus.Warning,
    id: CheckStatus.WARNING,
    badge: 'warning',
  },
  {
    label: 'Error',
    value: SM.CheckStatus.Error,
    id: CheckStatus.ERROR,
    badge: 'danger',
  },
];

export const recordTypeOptions: StatusOption[] = [
  {
    label: 'Info',
    value: SM.CheckStatus.Ok,
    id: CheckStatus.OK,
    badge: 'success',
  },
  {
    label: 'Warning',
    value: SM.CheckStatus.Warning,
    id: CheckStatus.WARNING,
    badge: 'warning',
  },
  {
    label: 'Error',
    value: SM.CheckStatus.Error,
    id: CheckStatus.ERROR,
    badge: 'danger',
  },
];

type StatusOption = {
  label: string;
  value: SM.CheckStatus;
  id: CheckStatus;
  badge: BadgeSeverityType;
};
