import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { Select } from 'shared/components/common/form/Select';
import { IOption } from 'types';
import { IVersion } from 'types/mediaplan';
import classes from './FileVersionSelector.module.scss';

type Props = {
  className?: string;
  currentVersionId?: number;
  onChange(value: IOption): void;
  versions: IVersion[];
};

export const FileVersionSelector: React.VFC<Props> = (props) => {
  const { className, currentVersionId, versions, onChange } = props;

  const disabled = versions.length <= 1;

  const options = useMemo(() => versions.map(({ id, fileName }) => ({ value: id, label: fileName })), [versions]);

  const handleChange = useCallback(
    (value: number, option?: IOption) => {
      if (option) {
        onChange(option);
      }
    },
    [onChange],
  );

  return (
    <div
      className={cx(classes.root, className)}
      data-global-tooltip={versions.length === 1}
      data-pr-tooltip={versions.length === 1 ? versions[0]?.fileName : undefined}
      data-pr-adjustable={false}
    >
      <label className={classes.label}>{localize('campaign-view.mediaplan.version-name')}</label>
      <Select
        disabled={disabled}
        dropdownClassName={classes.dropdown}
        onChange={handleChange}
        options={options}
        size="large"
        tabular
        theme="grey"
        value={currentVersionId}
        valueTemplate={(option) => (
          <span className={classes.value} data-global-tooltip={!!option?.label} data-pr-tooltip={option?.label}>
            {option?.label}
          </span>
        )}
      />
    </div>
  );
};

export type { Props as FileVersionSelectorProps };
