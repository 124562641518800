import cx from 'classnames';
import { useStores } from 'core';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { LocaleIdType } from 'locales';
import { Calendar } from 'shared/components/common/form/Calendar';
import { Dropdown } from 'shared/components/common/form/Dropdown';
import { MultiSelect } from 'shared/components/common/form/MultiSelect';
import { Checkbox, LocalizedMessage } from 'shared/components/other';
import { LevelFilters } from 'types/reportConstructor';

import { useBoolean } from 'ahooks';
import { ReportNameInput } from '../ReportNameInput';
import classes from './Filters.module.scss';
import { useGetMemoizedDateBoundaries, useGetMemoizedDateValue } from './helpers';

type LevelItem = {
  name: keyof LevelFilters;
  localeId: LocaleIdType;
};

const levelItems: LevelItem[] = [
  { name: 'advertisers', localeId: 'report-constructor.modal.advertisers' },
  { name: 'brands', localeId: 'report-constructor.modal.brands' },
  { name: 'campaigns', localeId: 'report-constructor.modal.campaigns' },
  { name: 'categories', localeId: 'report-constructor.modal.categories' },
  { name: 'subbrands', localeId: 'report-constructor.modal.subbrands' },
];

const FARTHEST_DATE_END = new Date('2300-01-01');

export const Filters: React.FC = observer(() => {
  const { reportConstructorStore } = useStores();
  const {
    reportName,
    isReportNameValid,
    filters,
    filterOptions,
    isCabinetsFilterActive,
    mode,
    isLimitedAccess,
    setReportName,
    setCabinetsFilterActive,
    setOptionFilter,
    setReportTypeFilter,
    setDateFilter,
  } = reportConstructorStore;
  const readOnly = useMemo(() => mode === 'view', [mode]);

  const [endDateRangeDisabled, { toggle: toggleEndDateRange }] = useBoolean(
    filters.statisticDateEnd === null ||
      new Date(filters.statisticDateEnd).toLocaleDateString() === FARTHEST_DATE_END.toLocaleDateString(),
  );

  const dateBoundaries = useGetMemoizedDateBoundaries(filters, endDateRangeDisabled);

  const dateFromValue = useGetMemoizedDateValue(filters.statisticDateStart);

  const dateToValue = useGetMemoizedDateValue(filters.statisticDateEnd, endDateRangeDisabled);

  const getCalendarChangeHandler = useCallback(
    (isStart: boolean) => (date: Date) => {
      if (isStart) {
        setDateFilter([date, endDateRangeDisabled ? FARTHEST_DATE_END : filters.statisticDateEnd]);
      } else {
        setDateFilter([filters.statisticDateStart, date]);
      }
    },
    [endDateRangeDisabled, filters.statisticDateStart, filters.statisticDateEnd, setDateFilter],
  );

  const endDateCheckboxChangeHandler = useCallback(() => {
    setDateFilter([filters.statisticDateStart, endDateRangeDisabled ? null : FARTHEST_DATE_END]);
    toggleEndDateRange();
  }, [filters.statisticDateStart, setDateFilter, endDateRangeDisabled, toggleEndDateRange]);

  return (
    <div className={classes.root}>
      <ReportNameInput
        value={reportName}
        disabled={readOnly}
        invalid={!!reportName && !isReportNameValid}
        onChange={setReportName}
      />
      <div className={classes.field}>
        <label
          className={cx(classes.label, classes.centeredContainer, {
            [classes.labelDisabled]: readOnly || isLimitedAccess,
          })}
        >
          <LocalizedMessage id="period" />
          <div className={cx(classes.centeredContainer, classes.shiftedContainer)}>
            <label
              className={cx(classes.label, classes.centeredContainer, {
                [classes.labelDisabled]: readOnly || isLimitedAccess,
              })}
            >
              <LocalizedMessage id="from" />
              <Calendar
                className={classes.calendar}
                theme="light"
                mode="single"
                value={dateFromValue}
                maxDate={dateBoundaries.maxDateForStart}
                placeholder=""
                disabled={readOnly || isLimitedAccess}
                showClearButton={false}
                onChange={getCalendarChangeHandler(true)}
              />
            </label>
          </div>
        </label>
        <div className={classes.centeredContainer}>
          <span className={classes.checkbox}>
            <Checkbox
              name="period-calendar-end"
              checked={!endDateRangeDisabled}
              onChange={endDateCheckboxChangeHandler}
              disabled={isLimitedAccess}
            />
          </span>
          <label
            className={cx(classes.label, classes.centeredContainer, {
              [classes.labelDisabled]: readOnly || isLimitedAccess,
            })}
          >
            <LocalizedMessage id="to" />
            <Calendar
              className={classes.calendar}
              theme="light"
              mode="single"
              value={endDateRangeDisabled ? dateToValue : dateToValue || dateBoundaries.minDateForEnd || null}
              minDate={dateBoundaries.minDateForEnd}
              placeholder=""
              disabled={readOnly || isLimitedAccess || endDateRangeDisabled}
              showClearButton={false}
              onChange={getCalendarChangeHandler(false)}
            />
          </label>
        </div>
      </div>
      <div className={classes.field}>
        <label
          htmlFor="level-dropdown"
          className={cx(classes.label, { [classes.labelDisabled]: filterOptions.reportLevelType?.disabled })}
        >
          <LocalizedMessage id="report-constructor.modal.select-level" />
        </label>
        <Dropdown
          id="level-dropdown"
          className={classes.select}
          appendTo="self"
          value={filters.reportLevelType}
          options={filterOptions.reportLevelType?.options}
          disabled={filterOptions.reportLevelType?.disabled}
          onChange={(e) => setOptionFilter('reportLevelType', e.value)}
        />
      </div>
      <div className={classes.levels}>
        {levelItems.map(({ name, localeId }) => (
          <div key={name} className={classes.field}>
            <label
              htmlFor={`${name}-multi-select`}
              className={cx(classes.label, { [classes.labelDisabled]: filterOptions[name]?.disabled })}
            >
              <LocalizedMessage id={localeId} />
            </label>
            <MultiSelect
              inputId={`${name}-multi-select`}
              className={classes.select}
              appendTo="self"
              value={filters[name]}
              options={filterOptions[name]?.options}
              disabled={filterOptions[name]?.disabled}
              filter
              onChange={(e) => setOptionFilter(name, e.value)}
            />
          </div>
        ))}
      </div>
      <div className={classes.field}>
        <label
          htmlFor="report-type-dropdown"
          className={cx(classes.label, { [classes.labelDisabled]: filterOptions.reportType?.disabled })}
        >
          <LocalizedMessage id="report-constructor.modal.select-report-type" />
        </label>
        <Dropdown
          id="report-type-dropdown"
          className={classes.select}
          panelClassName={classes.bottomPanel}
          appendTo="self"
          value={filters.reportType}
          options={filterOptions.reportType?.options}
          disabled={filterOptions.reportType?.disabled}
          onChange={(e) => setReportTypeFilter(e.value)}
        />
      </div>
      {filters.reportType !== 'PLAN' && (
        <div className={classes.cabinetsFilter}>
          <div className={classes.field}>
            <Checkbox
              name="isCabinetsActive"
              checked={isCabinetsFilterActive}
              disabled={filterOptions.reportType.disabled}
              onChange={() => setCabinetsFilterActive(!isCabinetsFilterActive)}
            />
            <label className={cx(classes.label, { [classes.labelDisabled]: filterOptions.reportType.disabled })}>
              <LocalizedMessage id="report-constructor.modal.limit-sources-of-fact-data" />
            </label>
          </div>
          <div className={classes.field}>
            <label
              htmlFor="cabinets-multi-select"
              className={cx(classes.label, { [classes.labelDisabled]: filterOptions.cabinetIds?.disabled })}
            >
              <LocalizedMessage id="report-constructor.modal.cabinets" />
            </label>
            <MultiSelect
              inputId="cabinets-multi-select"
              className={classes.select}
              panelClassName={classes.bottomPanel}
              appendTo="self"
              value={filters.cabinetIds}
              options={filterOptions.cabinetIds?.options}
              disabled={filterOptions.cabinetIds?.disabled}
              filter
              onChange={(e) => setOptionFilter('cabinetIds', e.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
});
