import { CampaignStatus } from 'types/campaign';

export const statuses: CampaignStatus[] = [
  CampaignStatus.UNCONFIRMED,
  CampaignStatus.CONFIRMED,
  CampaignStatus.ACTIVE,
  CampaignStatus.PAUSED,
  CampaignStatus.COMPLETED,
  CampaignStatus.ARCHIVED,
];

export const requiredFields = ['name', 'agency', 'brand', 'category', 'status', 'startDate', 'endDate', 'budget'];

export const autoCreatedRequiredFields = ['name', 'agency', 'brand', 'category', 'date', 'budget'];

export const maxBudgetValue = 4294967296;
