import { onError } from '@apollo/client/link/error';

import { sharedStores } from 'shared/stores';
import handleError from 'helpers/handleError';
import { IError } from 'types';
import { toast } from 'shared/components/common/misc/Toast';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors?.length || networkError) {
    sharedStores.globalLoaderStore.stopLoading();
  }
  graphQLErrors?.forEach((graphQLError) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { userErrorMessage, userErrorMessageList, message } = (graphQLError.extensions?.debugInfo as IError) ?? {};
    const error: IError = new Error(graphQLError.message ?? message ?? 'GraphQL error');
    error.userErrorMessage = userErrorMessage;
    error.userErrorMessageList = userErrorMessageList;
    handleError(error);
  });
  if (networkError) {
    toast.error(networkError.message, {
      data: {
        errorForSentry: networkError,
      },
    });
  }
});
