import { ApolloQueryResult } from '@apollo/client';
import { formatISO } from 'date-fns';
import { CheckSetting, CheckSettingsDictionaryValues } from 'types/programmatic';
import { ServerModels as SM } from 'types/server';

export function toClientCheckSettings(
  response: ApolloQueryResult<{ excessCheckSettings: SM.ExcessCheckSettingsResponse }>,
): ExcessCheckSettingsResponse {
  const settings =
    response.data?.excessCheckSettings?.settings?.map((row) => ({
      id: row.id,
      isActive: row.value === 'true',
      expires: row.expires ? new Date(row.expires) : null,
    })) ?? [];

  return {
    settings,
    settingsStatus: response.data?.excessCheckSettings?.status ?? null,
  };
}

export function toServerCheckSettings(
  args: ExcessCheckSettingsRequest,
): SM.MutationExcessSetCheckSettingsArgs | undefined {
  return {
    request: {
      ...args,
      settings: args.settings.map((row) => ({
        id: row.id,
        value: String(row.isActive),
        expires: row.expires ? formatISO(row.expires, { representation: 'date' }) : null,
      })),
    },
  };
}

export type ExcessCheckSettingsRequest = Omit<SM.ExcessCheckSettingsRequest, 'settings'> & {
  settings: CheckSetting[];
};

export type ExcessCheckSettingsResponse = {
  settings: Omit<CheckSetting, keyof CheckSettingsDictionaryValues>[];
  settingsStatus: SM.ExcessSettingsStatus | null;
};
