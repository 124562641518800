import React, { useMemo } from 'react';
import { useScroll } from 'ahooks';
import { TitleCell } from '../../cells';
import { getPinnedRowData } from '../../../helpers';
import { Column, GridRow } from '../../../types';
import classes from './PinnedRow.module.scss';

type Props<DR> = {
  gridRows: GridRow<DR>[];
  dataRowHeight: number;
  titleRowHeight: number;
  rowGap: number;
  titleRowTemplates: Column<DR>['titleRowTemplates'];
  scrollContainer?: HTMLDivElement | null;
};

export function PinnedRow<DR>(props: Props<DR>): React.ReactElement | null {
  const { gridRows, dataRowHeight, titleRowHeight, rowGap, titleRowTemplates, scrollContainer } = props;

  const scrollTop = useScroll(scrollContainer).top;

  const pinnedRowData = useMemo(
    () =>
      getPinnedRowData({
        scrollTop,
        gridRows,
        dataRowHeight,
        titleRowHeight,
        rowGap,
        titleRowTemplates,
      }),
    [dataRowHeight, gridRows, rowGap, scrollTop, titleRowHeight, titleRowTemplates],
  );

  if (!pinnedRowData) return null;

  const { title, stickyDistance } = pinnedRowData;

  return (
    <div className={classes.root} style={{ transform: `translateY(${stickyDistance}px)` }}>
      <TitleCell title={title} />
    </div>
  );
}
