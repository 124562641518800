import React, { VFC } from 'react';
import cx from 'classnames';
import { SplitButton, SplitButtonProps } from 'primereact/splitbutton';
import classes from './MenuButton.module.scss';

type Props = Omit<SplitButtonProps, 'label'> & {
  /** Label element */
  label: React.ReactNode;
  /** Label additional className */
  labelClassName?: string;
  /** SplitButton additional className */
  splitButtonClassName?: string;
};

export const MenuButton: VFC<Props> = (props) => {
  const { className, label, labelClassName, splitButtonClassName, ...restProps } = props;

  return (
    <div className={cx(classes.root, className)}>
      {!!label && <span className={cx(classes.label, labelClassName)}>{label}</span>}
      <SplitButton
        className={cx(classes.button, 'p-button-text', splitButtonClassName)}
        dropdownIcon="icon-arrow-down"
        menuButtonClassName={classes.menuButton}
        {...restProps}
      />
    </div>
  );
};

export type { Props as MenuButtonProps };
