import React from 'react';
import { withErrorBoundary } from '@sentry/react';

import { sharedStores } from 'shared/stores';
import { FallbackComponent } from 'shared/components/other';

export const getFeedbackDialogOptions = (): Record<string, string | undefined> => {
  const locals = sharedStores.localeStore.messages;

  return {
    title: locals['feedback-dialog.title'],
    subtitle: locals['feedback-dialog.subtitle'],
    subtitle2: locals['feedback-dialog.subtitle2'],
    labelName: locals['feedback-dialog.labelName'],
    labelEmail: locals['feedback-dialog.labelEmail'],
    labelComments: locals['feedback-dialog.labelComments'],
    labelClose: locals['feedback-dialog.labelClose'],
    labelSubmit: locals['feedback-dialog.labelSubmit'],
    errorGeneric: locals['feedback-dialog.errorGeneric'],
    errorFormEntry: locals['feedback-dialog.errorFormEntry'],
    successMessage: locals['feedback-dialog.successMessage'],
  };
};

const withSentryErrorBoundary = <T = unknown>(component: React.ComponentType<T>): React.ComponentType<T> =>
  withErrorBoundary(component, {
    fallback: FallbackComponent,
    showDialog: true,
    dialogOptions: getFeedbackDialogOptions(),
  });

export default withSentryErrorBoundary;
