import * as R from 'ramda';
import { localize } from 'shared/components/other';
import { NavMenuItem } from 'shared/components/layout/Header/components/NavMenu';

export function toNavMenuItems<T extends Record<string, boolean | T>>(value: T): NavMenuItem[] {
  return R.toPairs(value).reduce<NavMenuItem[]>((acc, [key, val]) => {
    const id = camelToKebabCase(key);
    if (val && typeof val === 'boolean') {
      return [...acc, { label: localize(`site.title.${id}` as never), path: `/${id}` }];
    } else if (val && typeof val === 'object') {
      return [...acc, { label: localize(`site.title.${id}` as never), items: toNavMenuItems(val as T) }];
    }
    return acc;
  }, []);
}

function camelToKebabCase(value: string) {
  return value.replace(/([a-z])([A-Z])/, (substring, ...args) => {
    return `${args[0]}-${args[1].toLowerCase()}`;
  });
}
