import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Config from 'config';
import { sharedStores } from 'shared/stores';

const httpLink = createHttpLink({
  uri: `${Config.api.cswUrl}/graphql`,
});

export const authLink = setContext((_, { headers }) => {
  const accessToken = sharedStores.authStore.getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: accessToken?.length ? `Bearer ${accessToken}` : '',
      ...(Config.api.authType === 'keycloak' ? { Realm: 'ADV' } : {}),
    },
  };
}).concat(httpLink);
