import { CustomOptions } from 'api/types';
import { IError } from 'types';
import { ServerModels as SM } from 'types/server';
import BaseApi from '../../BaseApi';
import { ADFOX_ID } from '../../helpers/constants';

class Adfox extends BaseApi {
  updateCampaigns = ({ campaigns }: UpdateCampaignsData, config: CustomOptions = {}) =>
    this.actions.post<SM.CampaignUpdateStatuses | IError>({
      url: `/tradingDesk/${ADFOX_ID}/update/campaigns`,
      domainType: 'webApi',
      data: { paramUpdates: campaigns },
      config,
    });

  refreshCampaigns = ({ campaigns }: RefreshCampaignsData, config: CustomOptions = {}) =>
    this.actions.post<SM.CampaignUpdateStatuses>({
      url: `/tradingDesk/${ADFOX_ID}/refresh/campaigns`,
      domainType: 'webApi',
      data: { campaigns },
      config,
    });

  updateBanners = ({ banners }: UpdateBannersData, config: CustomOptions = {}) =>
    this.actions.post<SM.BannerUpdateStatuses | IError>({
      url: `/tradingDesk/${ADFOX_ID}/update/banners`,
      domainType: 'webApi',
      data: { paramUpdates: banners },
      config,
    });

  refreshCampaignWithBanners = (
    { campaignId, bannerIds }: RefreshCampaignWithBannersData,
    config: CustomOptions = {},
  ) =>
    this.actions.post<SM.CampaignWithBannersRefreshStatuses>({
      url: `/tradingDesk/${ADFOX_ID}/refresh/campaign`,
      domainType: 'webApi',
      data: { campaignId, bannerIds },
      config,
    });
}

type UpdateCampaignsData = { campaigns: Partial<SM.AdfoxCampaignView>[] };

type RefreshCampaignsData = {
  campaigns: { id: number }[];
};

type UpdateBannersData = {
  banners: Partial<SM.AdfoxBannerView>[];
};

type RefreshCampaignWithBannersData = {
  campaignId: number;
  bannerIds: number[];
};

export default Adfox;
