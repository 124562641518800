import { action, makeObservable, observable } from 'mobx';
import { IColorTheme } from 'types';
import { BaseStore } from 'shared/stores';

export class EditAppearanceStore extends BaseStore {
  @observable
  brandTheme: IColorTheme | null = null;

  @observable
  campaignTheme: IColorTheme | null = null;

  @action
  initThemes = (brandTheme: IColorTheme, campaignTheme: IColorTheme): void => {
    this.brandTheme = brandTheme;
    this.campaignTheme = campaignTheme;
  };

  @action
  updateCampaignTheme = <T extends IColorTheme, K extends keyof IColorTheme>(key: K): ((value: T[K]) => void) => {
    return (value: T[K]) => {
      if (this.campaignTheme) {
        this.campaignTheme = {
          ...this.campaignTheme,
          [key]: value,
        };
      }
    };
  };

  @action
  updateBrandTheme = <T extends IColorTheme, K extends keyof IColorTheme>(key: K): ((value: T[K]) => void) => {
    return (value: T[K]) => {
      if (this.brandTheme) {
        this.brandTheme = {
          ...this.brandTheme,
          [key]: value,
        };
      }
    };
  };

  constructor() {
    super();
    makeObservable(this);
  }
}
