import React, { useCallback } from 'react';
import cx from 'classnames';
import { useBoolean } from 'ahooks';
import { Dropdown as PrimeDropdown, DropdownChangeParams, DropdownProps } from 'primereact/dropdown';
import classes from './Dropdown.module.scss';

type Props = Omit<DropdownProps, 'size'> & {
  /** Size of the component. Default: 'medium' */
  size?: 'small' | 'medium' | 'large';
  /** Specifies if the component should stretch to full width. Default: false */
  tabular?: boolean;
  /** Component color theme. Default: 'light' */
  theme?: 'light' | 'grey';
};

export const Dropdown: React.VFC<Props> = (props) => {
  const { className, size = 'medium', tabular = false, theme = 'light', onChange, ...restProps } = props;
  const [isMenuVisible, { setTrue: show, setFalse: hide }] = useBoolean(false);

  const handleChange = useCallback(
    (e: DropdownChangeParams) => {
      if (e.originalEvent.type !== 'keydown') {
        onChange?.(e);
      }
    },
    [onChange],
  );

  return (
    <PrimeDropdown
      appendTo="self"
      className={cx(classes.root, className, {
        [classes.isMenuVisible]: isMenuVisible,
        [classes.isTabular]: tabular,
        [classes[`${size}Size`]]: !!size,
        [classes[`${theme}Theme`]]: !!theme,
      })}
      dropdownIcon="pi icon-arrow-down"
      onHide={hide}
      onShow={show}
      onChange={handleChange}
      {...restProps}
    />
  );
};

export type { Props as DropdownProps };
