import { nbsp } from './constants';

export function toNumberString(value: unknown, options?: Intl.NumberFormatOptions): string {
  if (value === null || value === undefined || value === '') {
    return '';
  }

  const numericValue = Number(value);

  return Number.isNaN(numericValue)
    ? String(value)
    : numericValue
        .toLocaleString('en-US', {
          maximumFractionDigits: 2,
          ...options,
        })
        .replace(/,/g, nbsp)
        .replace(/\./g, ',');
}
