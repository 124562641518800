import { CustomOptions, PageData } from 'api/types';
import { IPagingServerResponse } from 'types';
import { TvTerritoryType } from 'types/mediaplan';
import { ITargetAudience } from 'types/targetAudience';
import BaseApi from '../BaseApi';

class TargetAudiences extends BaseApi {
  list = ({ customerId, territoryType, pageSize = 10000 }: GetTargetAudienceListData, config: CustomOptions = {}) => {
    const audienceType = territoryType === TvTerritoryType.FEDERAL ? territoryType : undefined;
    return this.actions.get<IPagingServerResponse<ITargetAudience[]>>({
      url: '/audience/',
      data: { customerId, audienceType, pageSize },
      config,
    });
  };
}

type GetTargetAudienceListData = PageData & {
  customerId: number;
  territoryType?: TvTerritoryType;
};

export default TargetAudiences;
