import React, { useCallback } from 'react';
import cx from 'classnames';
import { Panel, PanelHeaderTemplateType } from 'primereact/panel';
import { localize } from 'shared/components/other';
import { ICreative } from 'types/mediaplan';
import { TextButton } from 'shared/components/common/buttons/TextButton';
import { TvMediaplansTable, TvMediaplansTableProps } from '../TvMediaplansTable';
import { AudienceSelector, AudienceSelectorProps } from '../AudienceSelector';
import { RetroperiodDatepicker, RetroperiodDatepickerProps } from '../RetroperiodDatepicker';
import classes from './RegionalCreatives.module.scss';

type Props = {
  audienceSelector: Omit<AudienceSelectorProps, 'creative'>;
  className?: string;
  product: ICreative;
  retroperiodDatepicker: Omit<RetroperiodDatepickerProps, 'creative'>;
  tvMediaplansTable: TvMediaplansTableProps;
};

export const RegionalCreatives: React.VFC<Props> = React.memo((props) => {
  const { audienceSelector, className, product, retroperiodDatepicker, tvMediaplansTable } = props;

  const headerTemplate: PanelHeaderTemplateType = useCallback(
    (options) => (
      <div className={classes.header}>
        <label className={classes.label}>
          <TextButton
            className={classes.toggleButton}
            icon={options.collapsed ? 'pi pi-plus-circle' : 'pi pi-minus-circle'}
            iconColor="var(--color-green)"
            onClick={options.onTogglerClick}
          />
          <span className={classes.cityLabel}>{localize('campaign-view.mediaplan.labels.city')}:</span>
          <span className={classes.cityValue}>{product.region.name}</span>
        </label>
        <div className={classes.controls}>
          <AudienceSelector {...audienceSelector} creative={product} />
          <RetroperiodDatepicker {...retroperiodDatepicker} creative={product} />
        </div>
      </div>
    ),
    [audienceSelector, product, retroperiodDatepicker],
  );

  return (
    <div className={cx(classes.root, className)}>
      <Panel headerTemplate={headerTemplate} toggleable>
        <TvMediaplansTable {...tvMediaplansTable} />
      </Panel>
    </div>
  );
});
