import React, { useCallback, useMemo, useRef, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { useMount, useUpdateEffect } from 'ahooks';
import ReactDataGrid, { DataGridHandle, RowHeightArgs } from 'react-data-grid';
import { NoDataFallback } from 'shared/components/other';
import classes from './DataGrid.module.scss';
import { defaultProps } from './DataGrid.defaultProps';
import { Modal, PinnedRow, FilterRow, TooltipProvider } from './components';
import { DataGridProvider } from './context';
import { makeRowRenderer } from './formatters';
import { convertDataTreeToGridRows, getRowHeight, isAtBottom, pumpColumn } from './helpers';
import { Column, GridRow, DataGridProps } from './types';
import './style.scss';

function DataGridComponent<DR>(props: DataGridProps<DR>): JSX.Element {
  const properties = { ...defaultProps, ...props };
  const {
    isLoading,
    isLoadedAll,
    loadMore,
    selectedRowKeys,
    rows,
    columns,
    scrollThreshold,
    headerRowHeight,
    filterRowHeight,
    dataRowHeight,
    titleRowHeight,
    rowKeyGetter,
    gridRef,
    className,
    rowGap = 0,
    enableVirtualization = true,
    autoScrollTop = true,
  } = properties;

  const nativeGridRef = useRef<DataGridHandle>(null);

  const filterRowContainerRef = useRef<HTMLDivElement>(document.createElement('div'));
  const pinnedRowContainerRef = useRef<HTMLDivElement>(document.createElement('div'));

  useMount(() => {
    if (!gridRef) return;
    filterRowContainerRef.current.classList.add(classes.filterRowContainer);
    pinnedRowContainerRef.current.classList.add(classes.pinnedRowContainer);
    const headerRow = nativeGridRef.current?.element?.querySelector('.rdg-header-row');
    headerRow?.insertAdjacentElement('afterend', filterRowContainerRef.current);
    headerRow?.insertAdjacentElement('afterend', pinnedRowContainerRef.current);
  });

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (isLoading || isLoadedAll || !isAtBottom(event, scrollThreshold) || loadMore === undefined) {
        return;
      }
      loadMore();
    },
    [isLoadedAll, isLoading, loadMore, scrollThreshold],
  );

  const noRowsFallback = useMemo(() => (isLoading ? null : <NoDataFallback />), [isLoading]);

  const gridRows = useMemo(() => convertDataTreeToGridRows(rows), [rows]);

  const gridColumns: Column<DR>[] = useMemo(() => {
    return columns.map((column, i) => pumpColumn(column, !i, columns.length));
  }, [columns]);

  const getRowsHeight = useCallback(
    (args: RowHeightArgs<GridRow<DR>>) => getRowHeight(args, dataRowHeight, titleRowHeight, rowGap),
    [dataRowHeight, titleRowHeight, rowGap],
  );

  const titleRowTemplates = useMemo(() => columns.find((column) => 'titleRowTemplates' in column)?.titleRowTemplates, [
    columns,
  ]);

  const rowRenderer = useMemo(() => makeRowRenderer(rowGap, filterRowHeight), [filterRowHeight, rowGap]);

  const cssVariables = useMemo(
    () =>
      ({
        '--filter-row-height': `${filterRowHeight}px`,
        '--header-row-height': `${headerRowHeight}px`,
        '--title-row-height': `${titleRowHeight}px`,
      } as CSSProperties),
    [filterRowHeight, headerRowHeight, titleRowHeight],
  );

  useUpdateEffect(() => {
    if (autoScrollTop && gridRows.length) {
      nativeGridRef.current?.scrollToRow?.(0);
    }
  }, [gridRows]);

  return (
    <div className={cx(classes.root, className)} style={cssVariables}>
      <DataGridProvider {...properties} nativeGridRef={nativeGridRef}>
        <TooltipProvider>
          {ReactDOM.createPortal(<FilterRow gridColumns={gridColumns} />, filterRowContainerRef.current)}
          {titleRowTemplates &&
            rows.length > 0 &&
            ReactDOM.createPortal(
              <PinnedRow
                gridRows={gridRows}
                titleRowHeight={titleRowHeight}
                dataRowHeight={dataRowHeight}
                titleRowTemplates={titleRowTemplates}
                rowGap={rowGap}
                scrollContainer={gridRef?.current?.nativeGridRef.current?.element}
              />,
              pinnedRowContainerRef.current,
            )}
          <ReactDataGrid
            className="rdg-light"
            columns={gridColumns}
            rows={gridRows}
            headerRowHeight={headerRowHeight}
            rowHeight={getRowsHeight}
            rowKeyGetter={rowKeyGetter}
            onScroll={handleScroll}
            selectedRows={selectedRowKeys}
            rowRenderer={rowRenderer}
            noRowsFallback={noRowsFallback}
            ref={nativeGridRef}
            enableVirtualization={enableVirtualization}
          />
          <Modal />
        </TooltipProvider>
      </DataGridProvider>
    </div>
  );
}

const DataGrid = React.memo(DataGridComponent) as typeof DataGridComponent;

export { DataGrid };
export { baseCellWrapper, BaseCell } from './components';
export * from './types';
export * from './typeGuards';
