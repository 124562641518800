import React, { useCallback } from 'react';
import cx from 'classnames';
import { localize } from 'shared/components/other';
import { ICreative } from 'types/mediaplan';
import { Select } from 'shared/components/common/form/Select';
import { IOption } from 'types';
import classes from './AudienceSelector.module.scss';

type Props = {
  className?: string;
  creative: ICreative;
  onChange(value: IOption, productId: number): void;
  options: IOption[];
};

export const AudienceSelector: React.VFC<Props> = (props) => {
  const { options, className, creative, onChange } = props;

  const value = options.find((x) => x.value === creative.audienceId)?.value;

  const handleChange = useCallback(
    (val: number, option?: IOption) => {
      if (option) {
        onChange(option, creative.id);
      }
    },
    [creative.id, onChange],
  );

  return (
    <label className={cx(classes.root, className)}>
      <span className={classes.label}>{localize('campaign-view.mediaplan.version-ta')}</span>
      <Select
        className={classes.select}
        onChange={handleChange}
        options={options}
        size="large"
        tabular
        theme="grey"
        value={value}
      />
    </label>
  );
};

export type { Props as AudienceSelectorProps };
