import React from 'react';
import { ColumnType } from '../../../types';
import classes from './BaseCellWrapper.module.scss';

export function baseCellWrapper(
  // eslint-disable-next-line
  WrappedComponent: React.ComponentType<{ value: any; type?: ColumnType }>,
): React.ComponentType<{ value: React.ReactNode; type?: ColumnType }> {
  return (props) => {
    const { value, type } = props;
    if (value != null) {
      return <WrappedComponent value={value} type={type} />;
    }

    return <div className={classes.dash}>—</div>;
  };
}
