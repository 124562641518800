import { nbsp } from './constants';

export function toCurrency(value: number | string, fractionDigits = 2): string {
  return Number(value)
    .toLocaleString('en-US', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    })
    .replace(/,/g, nbsp)
    .replace(/\./g, ',');
}
