import React, { FC, useCallback } from 'react';
import { Checkbox, Input } from 'shared/components/other';
import { Button } from 'shared/components/common/buttons/Button';
import { CustomUrlField } from 'types/powerBI';
import classes from './CustomLinks.module.scss';

interface IProps {
  links: CustomUrlField[];
  pageId: number;
  onChange: (links: CustomUrlField[]) => void;
  onDelete: (serverId: number, callback: () => void) => void;
}

const CustomLinks: FC<IProps> = React.memo((props: IProps) => {
  const { links, onDelete, onChange, pageId } = props;

  const mutateRow = useCallback(
    (index: number, newRow: CustomUrlField) => {
      const linksCopy = [...links];
      linksCopy[index] = { ...newRow, isChanged: true };
      onChange(linksCopy);
    },
    [onChange, links],
  );

  const deleteRow = useCallback(
    (index: number) => {
      const ex = links[index];
      if (ex.serverId == null) {
        // just easy splice
        const copy = [...links];
        copy.splice(index, 1);
        onChange(copy);
      } else {
        // if it's already saved on server,
        // todo delete inline too or out managed?
        onDelete(ex.serverId, () => {
          onChange(links.filter((f, i) => i !== index));
        });
      }
    },
    [links, onChange, onDelete],
  );

  const addLink = useCallback(() => {
    if (links.length >= 6) return;
    const linksCopy = [...links];
    linksCopy.push({
      page: pageId,
      brand: null,
      campaign: null,
      category: null,
      subbrand: null,
      type: 'brand',
      serverId: null,
      isChanged: true,
      errors: [],
      userUrlTitle: '',
      url: '',
      isEnabled: true,
    });
    onChange(linksCopy);
  }, [onChange, links, pageId]);

  return (
    <div>
      <table className={classes.Table}>
        <tbody>
          <tr>
            <th>Название</th>
            <th>Ссылка</th>
            <th>Включить</th>
            <th> </th>
          </tr>
          {links.map((f, index) => (
            <tr key={index}>
              <td>
                <Input
                  value={f.userUrlTitle || ''}
                  placeholder="Название"
                  disabled={!f.isEnabled}
                  onChange={(e) => mutateRow(index, { ...f, userUrlTitle: e.target.value })}
                  hasError={f.errors.includes('name')}
                  maxLength={22}
                />
              </td>
              <td>
                <Input
                  value={f.url}
                  placeholder="Ссылка"
                  disabled={!f.isEnabled}
                  onChange={(e) => mutateRow(index, { ...f, url: e.target.value })}
                  hasError={f.errors.includes('url')}
                  maxLength={1000}
                />
              </td>
              <td>
                <label className={classes.Check}>
                  <Checkbox
                    checked={f.isEnabled}
                    name="isEnabled"
                    onChange={() => mutateRow(index, { ...f, isEnabled: !f.isEnabled })}
                  />
                </label>
              </td>
              <td>
                <Button
                  theme="light"
                  className={classes.RemoveButton}
                  icon="icon-delete"
                  iconColor="var(--color-red)"
                  onClick={() => deleteRow(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {links.length < 6 && <Button onClick={addLink}>+</Button>}
    </div>
  );
});

export default CustomLinks;
