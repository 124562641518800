import React, { useCallback } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { IClip } from 'types/mediaplan';
import { Table } from 'shared/components/common/misc/Table';
import { columns } from './TvMediaplansTable.columns';
import classes from './TvMediaplansTable.module.scss';

type Props = {
  className?: string;
  creativeId: number;
  data: IClip[];
  onChange(creativeId: number, data: IClip[]): void;
  readOnly?: boolean;
};

export const TvMediaplansTable: React.VFC<Props> = React.memo((props) => {
  const { className, creativeId, data, onChange, readOnly } = props;
  const handleCellEditComplete = useCallback(
    (e) => {
      if (R.equals(e.value, e.newValue)) return;
      const nextRowData = R.modify(
        'invalidFields',
        R.reject((x) => x === e.field || (!!e.field.match(/date/i) && x === 'date')),
        e.newRowData as IClip,
      );
      const nextData = R.update(e.rowIndex, nextRowData)(data);
      onChange(creativeId, nextData);
    },
    [creativeId, data, onChange],
  );

  return (
    <Table
      className={cx(classes.root, className)}
      columns={columns}
      data={data}
      equalColumnWidths
      onCellEditComplete={handleCellEditComplete}
      readOnly={readOnly}
    />
  );
});

export type { Props as TvMediaplansTableProps };
