import React, { PureComponent } from 'react';
import cx from 'classnames';

import { Field } from 'types/powerBI';
import { Checkbox, Input, LocalizedMessage } from 'shared/components/other';

import classes from './RowComponent.module.scss';

interface IProps {
  label: string;
  checkboxType: 'enable' | 'useInternal';
  field: Field;
  level?: 1 | 2 | 3;
  onChange: (newField: Field) => void;
}

class RowComponent extends PureComponent<IProps> {
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const { onChange, field } = this.props;
    onChange({
      ...field,
      url: value,
      isChanged: true,
    });
  };

  handleCheckboxChange = (name: string, checked: boolean): void => {
    const { onChange, field, checkboxType } = this.props;
    onChange({
      ...field,
      isEnabled: checkboxType === 'enable' ? !checked : checked,
      isChanged: true,
    });
  };

  render() {
    const { label, field, checkboxType, level } = this.props;
    const { isEnabled, page, url } = field;

    return (
      <div className={classes.Row}>
        <div
          className={cx(classes.Label, {
            [classes.LabelLevel1]: level === 1,
            [classes.LabelLevel2]: level === 2,
            [classes.LabelLevel3]: level === 3,
          })}
        >
          {label}
        </div>
        <div className={classes.Input}>
          <Input
            value={url}
            name={String(page)}
            disabled={!isEnabled}
            hasError={isEnabled && url === ''}
            onChange={this.handleInputChange}
            maxLength={1000}
          />
        </div>
        <label className={classes.Check}>
          <Checkbox
            checked={checkboxType === 'enable' ? isEnabled : !isEnabled}
            name={String(page)}
            onChange={this.handleCheckboxChange}
            theme="blue"
          />
          <LocalizedMessage id={['powerbi.headers.use-internal', 'powerbi.headers.enable']}>
            {(useInternalLabel: string, enableLabel: string) => (
              <div className={classes.CheckText}>{checkboxType === 'enable' ? enableLabel : useInternalLabel}</div>
            )}
          </LocalizedMessage>
        </label>
      </div>
    );
  }
}

export default RowComponent;
