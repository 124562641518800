import { observer } from 'mobx-react';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect } from 'react';

import { useStores } from 'core';
import { Footer } from 'shared/components/layout/Footer';
import { localize, LocalizedTitle } from 'shared/components/other';

import { CopyPanel } from './components/CopyPanel';
import { LogPanel } from './components/LogPanel';
import classes from './CabinetCopying.module.scss';

export const CabinetCopying = observer(() => {
  const { isPanelActive, setIsPanelActive } = useStores().cabinetCopyingStore;

  useEffect(() => {
    return () => {
      setIsPanelActive(true);
    };
  }, [setIsPanelActive]);

  return (
    <div className={classes.root}>
      <LocalizedTitle id="site.title.cabinet-copying" />
      <TabView activeIndex={isPanelActive ? 0 : 1} onTabChange={() => setIsPanelActive(!isPanelActive)}>
        <TabPanel header={localize('cabinet-copying.tabs.copying')}>
          <CopyPanel />
        </TabPanel>
        <TabPanel header={localize('cabinet-copying.tabs.log')}>
          <LogPanel />
        </TabPanel>
      </TabView>
      <Footer />
    </div>
  );
});
